import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { tokens } from '@opengov/capital-style';

import connectData from '../../../containers/ConnectData';
import {
    billingAddressFields,
    businessTypeOptions,
    faxPhoneFields,
    fieldNames,
    form,
    languageOptions,
    privateOrgInfoHelpText,
    vendorFieldNames,
} from './constants';
import { validate } from './validate';
import { getOrganization, getInitialValues } from './selectors';
import { InputText, Label } from '../../InputText';
import { Main } from '../../Main';
import { AvatarEditor } from '../../AvatarEditor';
import { AddressFormSection, PhoneNumberInput } from '../../Forms';
import { Checkbox } from '../../Checkbox/Checkbox';
import { SearchSelect } from '../../SearchSelect/SearchSelect';
import { Button } from '../../Button';
import { uploadLogo, updateOrganization } from '../../../actions/admin';
import { MaskedInputText } from '../../../hocs';
import { noOp } from '../../../Forms/maskers';
import { integerString } from '../../../Forms/normalizers';
import { stateOptions } from '../../../Forms/options';
import { requiredVendorDatumFieldNames } from '../../../constants/requiredVendorDatum';
import { loadVendor } from '../../../actions/adminVendor';
import Contacts from '../../VendorProfile/Contacts';

const { colors } = tokens;
const stylesForIcon = {
    color: colors.colorGray800,
    width: 16,
    height: 16,
    marginLeft: 8,
    marginBottom: -3,
};

const { DESCRIPTION, NAME, USE_DIFFERENT_BILLING_ADDRESS, VENDOR, WEBSITE } = fieldNames;

const {
    BUSINESS_TYPE,
    DOING_BUSINESS_AS,
    EIN,
    DUNS,
    STATE_OF_INCORPORATION,
    LANGUAGES,
    UNIQUE_ENTITY_ID,
} = vendorFieldNames;

const {
    ADDRESS_REQUIRED,
    BUSINESS_TYPE_REQUIRED,
    DUNS_REQUIRED,
    EIN_REQUIRED,
    FAX_PHONE_REQUIRED,
    STATE_OF_INCORPORATION_REQUIRED,
    UNIQUE_ENTITY_ID_REQUIRED,
    WEBSITE_REQUIRED,
} = requiredVendorDatumFieldNames;

const stateOfIncorporationOptions = [{ label: 'Not Applicable', value: 'N/A' }, ...stateOptions];

function fetchData(getState, dispatch, location, params) {
    if (!params.vendorId) return;
    const vendorId = +params.vendorId;
    return dispatch(loadVendor(vendorId));
}

const mapStateToProps = (state) => {
    return {
        initialValues: getInitialValues(state),
        organization: getOrganization(state),
        useDifferentBillingAddress: formValueSelector(form)(state, USE_DIFFERENT_BILLING_ADDRESS),
        updateOrgError: state.admin.get('updateOrgError'),
        updatingOrg: state.admin.get('updatingOrg'),
        uploadLogoError: state.admin.get('uploadLogoError'),
        vendor: state.adminVendor.get('vendor')?.toJS(),
    };
};

const mapDispatchToProps = {
    uploadLogo,
    updateOrganization,
};

const formConfig = {
    form,
    validate,
};

// @connect
// @reduxForm
class ConnectedOrganizationInfoForm extends Component {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        incompleteRequiredVendorDatumKeys: PropTypes.array,
        initialize: PropTypes.func.isRequired,
        initialValues: PropTypes.object.isRequired,
        isModal: PropTypes.bool,
        organization: PropTypes.object.isRequired,
        onComplete: PropTypes.func,
        requiredVendorDatum: PropTypes.object,
        updateOrganization: PropTypes.func.isRequired,
        updateOrgError: PropTypes.string,
        updatingOrg: PropTypes.bool.isRequired,
        uploadLogo: PropTypes.func.isRequired,
        uploadLogoError: PropTypes.string,
        useDifferentBillingAddress: PropTypes.bool,
        vendor: PropTypes.object,
    };

    MaskedNumberInput = MaskedInputText(InputText);

    reinitializeForm = () => {
        const { initialize, initialValues } = this.props;
        return initialize(initialValues);
    };

    componentDidMount() {
        // Fixes bug where form would not initialize with `initialValues` in some scenarios
        // Suspect this is the culprit: https://github.com/redux-form/redux-form/issues/4069#issuecomment-397070053
        this.reinitializeForm();
    }

    submitHandler = (data) => {
        const { organization, updateOrganization: update } = this.props;

        const updatedData = {
            ...data,
            vendor: {
                ...data.vendor,
                languages: data.vendor.languages.map((language) => language.value),
            },
        };

        update(organization.id, updatedData).then(() => {
            const { updateOrgError, onComplete } = this.props;
            if (!updateOrgError) {
                // Needed specifically to reset the billing address values to `null` after form submit with `useDifferentBillingAddress`
                // box unchecked after previously having billing address values
                this.reinitializeForm();
                if (onComplete) {
                    onComplete();
                }
            }
        });
    };

    showValidation(fieldKey) {
        const { incompleteRequiredVendorDatumKeys } = this.props;
        return (
            incompleteRequiredVendorDatumKeys &&
            incompleteRequiredVendorDatumKeys.some((key) => key === fieldKey)
        );
    }

    render() {
        const {
            handleSubmit,
            isModal,
            organization: { isVendor, logo },
            requiredVendorDatum,
            updateOrgError,
            updatingOrg,
            uploadLogoError,
            useDifferentBillingAddress,
        } = this.props;

        const styles = require('./index.scss');
        const lftColSize = isModal ? 'col-sm-10 col-sm-offset-1' : 'col-md-offset-1 col-md-5';
        const rgtColSize = isModal ? 'col-sm-10 col-sm-offset-1' : 'col-md-5';
        const faxPhoneRequired = !!get(requiredVendorDatum, FAX_PHONE_REQUIRED);

        return (
            <form onSubmit={handleSubmit(this.submitHandler)}>
                <Main className={`row ${styles.container}`}>
                    <div className="col-xs-12">
                        <div className={styles.logoContainer}>
                            <AvatarEditor
                                imageSrc={logo || undefined}
                                onUpload={this.props.uploadLogo}
                                width={130}
                            />
                            {uploadLogoError && (
                                <div className="text-danger">Error Uploading Image</div>
                            )}
                        </div>
                    </div>
                </Main>
                <div className="row">
                    <div className={lftColSize}>
                        <p className={styles.requiredText}>
                            All fields marked with an * are required.
                        </p>
                        <Field
                            component={InputText}
                            disabled={updatingOrg}
                            hasFeedback={false}
                            label={`Legal ${isVendor ? 'Vendor' : ''} Name*`}
                            name={NAME}
                            placeholder="Required"
                            type="text"
                        />
                        <Field
                            component={InputText}
                            disabled={updatingOrg}
                            hasFeedback={false}
                            label="Doing Business As (DBA) Name"
                            name={`${VENDOR}.${DOING_BUSINESS_AS}`}
                            type="text"
                        />
                        <PhoneNumberInput
                            disabled={updatingOrg}
                            formName={form}
                            hasRequiredStar
                            phoneFieldLabel="Company Phone Number"
                            showRequiredFieldsText={false}
                        />
                        <AddressFormSection
                            disabled={updatingOrg}
                            form={form}
                            hasRequiredStar
                            labelPrefixAddress="Company"
                            showRequiredFieldsText={false}
                            showValidation={this.showValidation(ADDRESS_REQUIRED)}
                        />
                        {isVendor && (
                            <Field
                                className={styles.showBillingAddressCheckbox}
                                component={Checkbox}
                                name={USE_DIFFERENT_BILLING_ADDRESS}
                                text="Use a Different Billing Address?"
                            />
                        )}
                        {isVendor && useDifferentBillingAddress && (
                            <AddressFormSection
                                disabled={updatingOrg}
                                fieldNames={billingAddressFields}
                                form={form}
                                hasRequiredStar
                                labelPrefix="Billing"
                                showRequiredFieldsText={false}
                            />
                        )}
                        <Field
                            component={InputText}
                            disabled={updatingOrg}
                            hasFeedback={false}
                            label="Company Website"
                            name={WEBSITE}
                            placeholder={
                                get(requiredVendorDatum, WEBSITE_REQUIRED) ? 'Required' : 'Optional'
                            }
                            showValidation={this.showValidation(WEBSITE_REQUIRED)}
                            type="text"
                        />
                        {this.props?.vendor && (
                            <div>
                                <p className={styles.title}>Contacts</p>
                                <Contacts
                                    colorGrey
                                    contacts={this.props.vendor?.organization?.users.filter(
                                        (user) => user.status === 'active'
                                    )}
                                    showCopyEmail={false}
                                />
                            </div>
                        )}
                    </div>
                    <div className={rgtColSize}>
                        <Field
                            component={SearchSelect}
                            hasFeedback={false}
                            isClearable
                            isMulti
                            label="Additional Languages"
                            name={`${VENDOR}.${LANGUAGES}`}
                            options={languageOptions}
                        />
                        <Field
                            component={InputText}
                            disabled={updatingOrg}
                            hasFeedback={false}
                            label="Company Description"
                            minRows={3}
                            name={DESCRIPTION}
                            placeholder="Optional"
                            type="textarea"
                        />
                        <Field
                            component={SearchSelect}
                            disabled={updatingOrg}
                            isClearable
                            label="Business Type"
                            name={`${VENDOR}.${BUSINESS_TYPE}`}
                            options={businessTypeOptions}
                            placeholder={
                                get(requiredVendorDatum, BUSINESS_TYPE_REQUIRED)
                                    ? 'Required'
                                    : 'Optional'
                            }
                            showValidation={this.showValidation(BUSINESS_TYPE_REQUIRED)}
                        />
                        {isVendor && (
                            <PhoneNumberInput
                                disabled={updatingOrg}
                                fieldNames={faxPhoneFields}
                                formName={form}
                                hasRequiredStar={faxPhoneRequired}
                                phoneFieldLabel="Company Fax Phone Number"
                                placeholder={faxPhoneRequired ? 'Required' : 'Optional'}
                                showRequiredFieldsText={false}
                                showValidation={this.showValidation(FAX_PHONE_REQUIRED)}
                            />
                        )}
                        <Field
                            component={SearchSelect}
                            disabled={updatingOrg}
                            label="State of Incorporation*"
                            name={`${VENDOR}.${STATE_OF_INCORPORATION}`}
                            options={stateOfIncorporationOptions}
                            placeholder="Required"
                            showValidation={this.showValidation(STATE_OF_INCORPORATION_REQUIRED)}
                        />
                        <Field
                            component={this.MaskedNumberInput}
                            disabled={updatingOrg}
                            hasFeedback={false}
                            help="Enter 9 digit EIN number"
                            label={
                                <Label
                                    helpIcon
                                    helpIconNode={privateOrgInfoHelpText}
                                    label="Employer Identification Number (EIN)"
                                    stylesForIcon={stylesForIcon}
                                    tooltipPlacement="right"
                                    useOpenGovStyleForHelpIcon
                                />
                            }
                            mask={noOp}
                            name={`${VENDOR}.${EIN}`}
                            normalizer={integerString}
                            onChange={this.numberChangeHandler}
                            placeholder={
                                get(requiredVendorDatum, EIN_REQUIRED) ? 'Required' : 'Optional'
                            }
                            showValidation={this.showValidation(EIN_REQUIRED)}
                            type="text"
                        />
                        <Field
                            component={InputText}
                            disabled={updatingOrg}
                            hasFeedback={false}
                            help="Enter 12 character UEI"
                            label={
                                <Label
                                    helpIcon
                                    helpIconNode={privateOrgInfoHelpText}
                                    label="Unique Entity Identifier (UEI)"
                                    stylesForIcon={stylesForIcon}
                                    tooltipPlacement="right"
                                    useOpenGovStyleForHelpIcon
                                />
                            }
                            name={`${VENDOR}.${UNIQUE_ENTITY_ID}`}
                            placeholder={
                                get(requiredVendorDatum, UNIQUE_ENTITY_ID_REQUIRED)
                                    ? 'Required'
                                    : 'Optional'
                            }
                            showValidation={this.showValidation(UNIQUE_ENTITY_ID_REQUIRED)}
                            type="text"
                        />
                        <Field
                            component={this.MaskedNumberInput}
                            disabled={updatingOrg}
                            hasFeedback={false}
                            help="Enter 7 to 13 digit DUNS number"
                            label={
                                <Label
                                    helpIcon
                                    helpIconNode={privateOrgInfoHelpText}
                                    label="Dun & Bradstreet Number (DUNS)"
                                    stylesForIcon={stylesForIcon}
                                    tooltipPlacement="right"
                                    useOpenGovStyleForHelpIcon
                                />
                            }
                            mask={noOp}
                            name={`${VENDOR}.${DUNS}`}
                            normalizer={integerString}
                            onChange={this.numberChangeHandler}
                            placeholder={
                                get(requiredVendorDatum, DUNS_REQUIRED) ? 'Required' : 'Optional'
                            }
                            showValidation={this.showValidation(DUNS_REQUIRED)}
                            type="text"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 text-center">
                        <div className={styles.saveButton}>
                            <Button
                                bsSize="large"
                                bsStyle="primary"
                                disabled={updatingOrg}
                                type="submit"
                            >
                                {updatingOrg ? 'Saving...' : 'Save'}
                            </Button>
                        </div>
                        {updateOrgError && (
                            <div className="text-danger text-center">{updateOrgError}</div>
                        )}
                    </div>
                </div>
            </form>
        );
    }
}

/*
 * WARNING: User must be logged in to use this component. If user is not present this component
 * will crash.
 */
export const OrganizationInfoForm = compose(
    connectData(fetchData),
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig)
)(ConnectedOrganizationInfoForm);
