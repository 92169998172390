import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Button } from '../Button';
import { UserProfilePicture } from '../UserProfilePicture/UserProfilePicture';

export class UserProfileList extends PureComponent {
    static propTypes = {
        addHandler: PropTypes.func,
        className: PropTypes.string,
        listText: PropTypes.string,
        maxDisplayableUsers: PropTypes.number,
        users: PropTypes.array.isRequired,
    };

    static defaultProps = {
        maxDisplayableUsers: 4,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const { className, listText, maxDisplayableUsers, addHandler, users } = this.props;

        const displayFollowers = users.slice(0, maxDisplayableUsers);

        const userList = displayFollowers.map((user) => (
            <UserProfilePicture
                className={this.styles.profilePicture}
                horizontal
                key={user.id}
                showTooltip
                user={user}
            />
        ));

        return (
            <div className={className}>
                {listText && (
                    <span className={`text-muted ${this.styles.listText}`}>{listText}</span>
                )}
                {userList}
                {addHandler && (
                    <Button
                        aria-label="Add Button"
                        bsSize="sm"
                        className={this.styles.addButton}
                        onClick={addHandler}
                        qaTag="userProfileList-addButton"
                    >
                        <i className="fa fa-plus" />
                    </Button>
                )}
                {users.length > maxDisplayableUsers && (
                    <span
                        className={classnames('text-muted', addHandler && this.styles.extraUsers)}
                        onClick={addHandler}
                    >
                        +{users.length - maxDisplayableUsers} More
                    </span>
                )}
            </div>
        );
    }
}
