import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@og-pro/ui';

import { fieldTypes } from '@og-pro/shared-config/signatures';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';

import { SignatureDocx } from './SignatureDocx';
import { signaturesBlocksToColumns } from '../../helpers/signatures';
import { HelpIcon } from '../InputText';

const FieldValue = ({ field }) => {
    if (!field.value) {
        return null;
    }

    switch (field.type) {
        case fieldTypes.DATE:
            return new Date(field.value).toLocaleDateString();
        case fieldTypes.STAMP:
            return <Box height={field.value}>&nbsp;</Box>;
        default:
            return field.value;
    }
};

FieldValue.propTypes = {
    field: PropTypes.shape({
        type: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    }).isRequired,
};

const Field = ({ label, value, tooltip }) => (
    <Box key={label} mt={3}>
        <Box borderBottom="1px solid #333" flex={4} minHeight="28px" pb={0.5}>
            {value}
        </Box>
        <Box alignItems="center" display="flex" fontSize="12px" gap={0.5} mr={1}>
            <b>{label.toUpperCase()}</b>
            {tooltip && (
                <HelpIcon
                    placement="right"
                    style={{
                        width: '16px',
                        height: '16px',
                        marginTop: '-2px',
                    }}
                    tooltip={tooltip}
                    useOpenGovStyle
                />
            )}
        </Box>
    </Box>
);

Field.propTypes = {
    label: PropTypes.string,
    value: PropTypes.node || PropTypes.string,
    tooltip: PropTypes.string,
};

const SignatureBlock = ({ block, esignatureEnabled }) => {
    if (block.styling === 'header') {
        return (
            <Box
                dangerouslySetInnerHTML={{
                    __html: block.fields[0].value,
                }}
            />
        );
    }

    const {
        foundations: {
            colors: { gray100, gray400 },
        },
    } = capitalDesignTokens;

    const aditionalFieldsTooltip =
        'This information is for eSignature purposes only. It will not be visible when you export the contract document.';

    return (
        <Box>
            {block.fields?.map((field) => {
                return <Field label={field.label} value={<FieldValue field={field} />} />;
            })}
            {esignatureEnabled && (
                <Box
                    sx={{
                        backgroundColor: gray100,
                        border: `1px solid ${gray400}`,
                        borderRadius: 1,
                        padding: 1,
                        mt: 3,
                    }}
                >
                    <>
                        <Field label="Email" tooltip={aditionalFieldsTooltip} value={block.email} />
                        <Field
                            label="Signing Order"
                            tooltip={aditionalFieldsTooltip}
                            value={block.signingOrder}
                        />
                    </>
                </Box>
            )}
        </Box>
    );
};

SignatureBlock.propTypes = {
    block: PropTypes.shape({
        email: PropTypes.string,
        fields: PropTypes.array.isRequired,
        styling: PropTypes.oneOf(['header', 'default']).isRequired,
        signingOrder: PropTypes.number,
    }).isRequired,
    esignatureEnabled: PropTypes.bool,
};

export const Signature = (props) => {
    if (props.isDocx) {
        return <SignatureDocx {...props} />;
    }

    const { signatures } = props;

    return (
        <Box px={2}>
            {signatures
                .filter((s) => !s.isHiddenByLogic)
                .map((rawSignature) => {
                    const signature = signaturesBlocksToColumns(rawSignature);
                    const { esignatureEnabled } = signature;
                    const left = signature.signaturesBlocks[0]?.filter((b) => !b.isHiddenByLogic);
                    const right = signature.signaturesBlocks[1]?.filter((b) => !b.isHiddenByLogic);

                    return (
                        <>
                            {!!signature.introduction && (
                                <Box
                                    dangerouslySetInnerHTML={{ __html: signature.introduction }}
                                    mb={3}
                                />
                            )}

                            <Box>
                                <Box display="flex" pb={6}>
                                    <Box flex={1} pr={4}>
                                        {(left || []).map((block, i) => {
                                            return (
                                                <Box key={i} pb={6}>
                                                    <SignatureBlock
                                                        block={block}
                                                        esignatureEnabled={esignatureEnabled}
                                                    />
                                                </Box>
                                            );
                                        })}
                                    </Box>
                                    <Box flex={1} pl={4}>
                                        {(right || []).map((block, i) => {
                                            return (
                                                <Box key={i} pb={6}>
                                                    <SignatureBlock
                                                        block={block}
                                                        esignatureEnabled={esignatureEnabled}
                                                    />
                                                </Box>
                                            );
                                        })}
                                    </Box>
                                </Box>
                            </Box>
                            {signature.blankBlocks.map((block, i) => {
                                return (
                                    <Box
                                        fontStyle="italic"
                                        fontWeight="bold"
                                        height={block.value}
                                        key={i}
                                    >
                                        Notary
                                    </Box>
                                );
                            })}
                            {!!signature.footer && (
                                <Box dangerouslySetInnerHTML={{ __html: signature.footer }} />
                            )}
                        </>
                    );
                })}
        </Box>
    );
};

Signature.propTypes = {
    isDocx: PropTypes.bool,
    signatures: PropTypes.array.isRequired,
};
