import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { CategorySelect as CategorySelectContainer } from '../../../CategorySelect';

export class CategorySelect extends PureComponent {
    static propTypes = {
        categories: PropTypes.array.isRequired,
        clickHandler: PropTypes.func.isRequired,
        isBuilder: PropTypes.bool,
        isContracts: PropTypes.bool,
    };

    static defaultProps = {
        isBuilder: false,
        isContracts: false,
    };

    get styles() {
        return require('./shared.scss');
    }

    render() {
        const { categories, clickHandler, isBuilder, isContracts } = this.props;

        let titleText = 'What Would You Like to Purchase?';
        if (isContracts) {
            titleText = 'What Are You Contracting For?';
        }

        return (
            <div className={this.styles.projectCreateIcons}>
                <div className={this.styles.titleText}>
                    {titleText}
                    {isBuilder && (
                        <div className={this.styles.categoriesInfoText}>
                            This let&#39;s us give you better content suggestions
                        </div>
                    )}
                </div>
                <CategorySelectContainer
                    categories={categories}
                    className="text-left"
                    clickHandler={clickHandler}
                    isRequired
                    showNextArrow
                    useSingleCodeSet
                />
            </div>
        );
    }
}
