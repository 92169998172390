import { get } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SplitButton } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, getFormValues } from 'redux-form';

import { form } from './constants';
import { Button } from '../../../../../Button';
import { MenuItem } from '../../../../../MenuItem/MenuItem';
import { QuestionnaireDisplay, QuestionnaireFormBuilder } from '../../../../../Questionnaire';
import { Well } from '../../../../../Well/Well';
import { questionnaire as validate } from '../../../../../../Forms/validation';

const mapStateToProps = (state) => {
    return {
        questionnaire: getFormValues(form)(state),
    };
};

const formConfig = {
    form,
    validate,
    destroyOnUnmount: false,
    enableReinitialize: true,
};

// @connect
// @reduxForm
class ConnectedQuestionnaireForm extends Component {
    static propTypes = {
        addToBottomOnly: PropTypes.bool,
        allowDefaultValue: PropTypes.bool,
        backHandler: PropTypes.func.isRequired,
        disabled: PropTypes.bool.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        includeContainsPricingOption: PropTypes.bool,
        insertAfterQuestionId: PropTypes.number,
        isEditForm: PropTypes.bool,
        onSubmit: PropTypes.func.isRequired,
        questionnaire: PropTypes.shape({
            id: PropTypes.number,
            prompt: PropTypes.string,
            title: PropTypes.string,
        }),
        questionType: PropTypes.shape({
            title: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
        }).isRequired,
        tagOptions: PropTypes.array,
        templateVariableOptions: PropTypes.array,
        useRawPrompt: PropTypes.bool,
        validateBidBond: PropTypes.bool, // Passed through to `validate`
    };

    static defaultProps = {
        isEditForm: false,
        questionnaire: undefined,
        tagOptions: undefined,
        templateVariableOptions: undefined,
        useRawPrompt: false,
    };

    backHandler = () => {
        const { backHandler, questionnaire } = this.props;
        backHandler(questionnaire);
    };

    addToTopHandler = (data) => {
        const { onSubmit } = this.props;

        onSubmit(data, { addToTop: true });
    };

    renderAddButton() {
        const { addToBottomOnly, disabled, insertAfterQuestionId, handleSubmit, isEditForm } =
            this.props;

        if (isEditForm) {
            return (
                <Button bsStyle="primary" disabled={disabled} onClick={handleSubmit}>
                    <i className="fa fa-pencil" /> Update Question
                </Button>
            );
        }

        const buttonTitle = (
            <span>
                <i className="fa fa-plus" />
                {insertAfterQuestionId ? ' Insert ' : ' Add '}Question
            </span>
        );
        if (addToBottomOnly || insertAfterQuestionId) {
            return (
                <Button bsStyle="success" disabled={disabled} onClick={handleSubmit}>
                    {buttonTitle}
                </Button>
            );
        }

        return (
            <SplitButton
                bsStyle="success"
                disabled={disabled}
                id="add-questionnaire-button"
                onClick={handleSubmit}
                pullRight
                title={buttonTitle}
            >
                <MenuItem eventKey="1" onClick={handleSubmit} qaTag="questionnaires-addToBottom">
                    Add to Bottom
                </MenuItem>
                <MenuItem
                    eventKey="2"
                    onClick={handleSubmit(this.addToTopHandler)}
                    qaTag="questionnaires-addToTop"
                >
                    Add to Top
                </MenuItem>
            </SplitButton>
        );
    }

    render() {
        const {
            allowDefaultValue,
            disabled,
            includeContainsPricingOption,
            questionnaire,
            questionType,
            tagOptions,
            templateVariableOptions,
            useRawPrompt,
        } = this.props;

        const styles = require('./index.scss');

        return (
            <div>
                <div className="row">
                    <div className="col-md-7">
                        <div className={styles.titleText}>{questionType.title} Question Design</div>
                        <QuestionnaireFormBuilder
                            allowDefaultValue={allowDefaultValue}
                            disabled={disabled}
                            form={form}
                            includeContainsPricingOption={includeContainsPricingOption}
                            questionnaireId={get(questionnaire, 'id')}
                            tagOptions={tagOptions}
                            templateVariableOptions={templateVariableOptions}
                            type={questionType.type}
                            useRawPrompt={useRawPrompt}
                        />
                    </div>
                    <div className="col-md-5">
                        <div className={styles.previewTitle}>Preview</div>
                        <Well className="questionnaire-item">
                            <QuestionnaireDisplay
                                {...questionnaire}
                                type={questionType.type}
                                useRawPrompt={useRawPrompt}
                            />
                        </Well>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-7">
                        <div className="text-center">{this.renderAddButton()}</div>
                        <div>
                            <Button
                                bsSize="sm"
                                bsStyle="link"
                                className={styles.backBtn}
                                disabled={disabled}
                                onClick={this.backHandler}
                            >
                                <i className="fa fa-arrow-left" /> Back
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export const QuestionnaireForm = compose(
    connect(mapStateToProps),
    reduxForm(formConfig)
)(ConnectedQuestionnaireForm);
