import { matchPath, useLocation } from 'react-router-dom';

// From MUI Docs https://mui.com/material-ui/integrations/routing/#tabs
export const useRouteMatch = (patterns) => {
    const { pathname } = useLocation();
    for (let i = 0; i < patterns.length; i += 1) {
        const pattern = patterns[i];
        const possibleMatch = matchPath(pattern, pathname);
        if (possibleMatch !== null) {
            return possibleMatch;
        }
    }

    return null;
};
