import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Button } from '../../../../../Button';
import { DragIcon } from '../../../../../DragIcon';

export const ControlButtons = ({
    disabled,
    draggableProvided,
    index,
    insertQuestion,
    questionnaire: { conditionalSubQuestionIds, isConditionalSubQuestion, orderById },
    questionLogicIcon,
    remove,
}) => {
    const styles = require('./index.scss');

    const deleteItem = (e) => {
        e.stopPropagation();
        if (disabled) {
            return;
        }
        remove(index);
    };

    const insertItem = (e) => {
        e.stopPropagation();
        if (disabled) {
            return;
        }
        insertQuestion(orderById);
    };

    return (
        <div className={styles.controlButtons}>
            <div className={styles.editingControls}>
                <div>
                    <Button
                        aria-label="Remove Button"
                        bsStyle="link"
                        className={classnames(styles.removeBtn, disabled && styles.disabled)}
                        onClick={deleteItem}
                        qaTag="questionnaireList-remove"
                    >
                        <i className="fa fa-times fa-2x text-danger" />
                    </Button>
                </div>
                <DragIcon
                    containerClassName={styles.dragIcon}
                    disabled={disabled}
                    dragHandleProps={draggableProvided.dragHandleProps}
                />
                {!isConditionalSubQuestion && (conditionalSubQuestionIds || []).length === 0 && (
                    <div>
                        <Button
                            aria-label="Insert Button"
                            bsStyle="link"
                            className={classnames(styles.addBtn, disabled && styles.disabled)}
                            onClick={insertItem}
                            qaTag="questionnaireList-insert"
                            tooltip="Insert after"
                            tooltipPlacement="right"
                        >
                            <i className="fa fa-plus fa-2x" />
                        </Button>
                    </div>
                )}
            </div>
            {!!questionLogicIcon && !isConditionalSubQuestion && (
                <div className={styles.questionLogicIcon}>{questionLogicIcon}</div>
            )}
        </div>
    );
};

ControlButtons.propTypes = {
    disabled: PropTypes.bool,
    draggableProvided: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    insertQuestion: PropTypes.func.isRequired,
    questionLogicIcon: PropTypes.node,
    questionnaire: PropTypes.shape({
        conditionalSubQuestionIds: PropTypes.array,
        isConditionalSubQuestion: PropTypes.bool,
        orderById: PropTypes.number,
        type: PropTypes.string.isRequired,
    }).isRequired,
    remove: PropTypes.func.isRequired,
};
