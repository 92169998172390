import { Switch, Typography, FormControlLabel } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React from 'react';

import { qaTagName } from '../../../constants';

export const SwitchWithLabel = ({ disabled, input, invertSwitch, label, meta }) => {
    let onChange = input.onChange;
    let onBlur = input.onBlur;
    let value = input.value;

    // Use `invertSwitch` when the underlying field is the opposite boolean value of the label.
    // For example, if the field input is called `hideAttachments` but the switch is labeled
    // "Show Attachments" this would be used to make the UI display the opposite of the field value.
    if (invertSwitch) {
        onChange = (e) => input.onChange(!e.target.checked);
        onBlur = (e) => input.onChange(!e.target.checked);
        value = !value;
    }

    const switchProps = {
        checked: !!value,
        disabled,
        qaTag: `${qaTagName}-${input.name}`,
        onBlur,
        onChange,
        value,
    };

    return (
        <FormControlLabel
            control={<Switch {...meta} {...input} {...switchProps} />}
            label={<Typography>{label}</Typography>}
            labelPlacement="start"
            sx={{
                justifyContent: 'space-between',
                width: '100%',
                marginLeft: 0,
            }}
        />
    );
};

SwitchWithLabel.propTypes = {
    disabled: PropTypes.bool,
    input: PropTypes.object.isRequired,
    invertSwitch: PropTypes.bool,
    label: PropTypes.string.isRequired,
    meta: PropTypes.object.isRequired,
};
