import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { ControlLabel, FormControl } from 'react-bootstrap';
import { v4 as UUIDv4 } from 'uuid';

import { CopyToClipboardIcon } from '../../../../CopyToClipboardIcon';
import { QuestionPrompt } from '../../SharedDisplay';

export const BidBondDisplay = ({ data, isDocx, ...props }) => {
    if (isDocx) {
        return <QuestionPrompt {...props} />;
    }
    const bidBondProjectId = get(data, 'bidBondProjectId');
    const bidBondSecurity = get(data, 'bidBondSecurity');
    const bidBondSecurityIsPercentage = get(data, 'bidBondSecurityIsPercentage');

    const bidBondId = `bidBondDisplay-${UUIDv4()}`;
    const vendorId = `vendorIdDisplay-${UUIDv4()}`;

    return (
        <QuestionPrompt {...props}>
            <ControlLabel htmlFor={bidBondId}>Bond ID</ControlLabel>
            <FormControl disabled id={bidBondId} placeholder="Enter information" type="text" />
            <br />
            <ControlLabel htmlFor={vendorId}>Vendor ID</ControlLabel>
            <FormControl disabled id={vendorId} placeholder="Enter information" type="text" />
            <div style={{ marginTop: 15 }}>
                <i>
                    Don&apos;t have a bid bond yet? Login or register at&nbsp;
                    <a
                        href="https://dashboard.surety2000.com/#/register?refid=opengov"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        Surety2000
                    </a>
                    &nbsp;to create one using the following information for this project:
                </i>
                <br />
                <strong>Project ID: </strong>
                {bidBondProjectId}
                &nbsp;&nbsp;
                {bidBondProjectId && <CopyToClipboardIcon value={bidBondProjectId} />}
                <br />
                <strong>Bid Security: </strong>
                {`${bidBondSecurityIsPercentage ? '' : '$'}${bidBondSecurity || ''}${
                    bidBondSecurityIsPercentage ? '%' : ''
                }`}
                &nbsp;&nbsp;
                {bidBondSecurity && <CopyToClipboardIcon value={bidBondSecurity} />}
            </div>
        </QuestionPrompt>
    );
};

BidBondDisplay.propTypes = {
    data: PropTypes.shape({
        bidBondProjectId: PropTypes.string,
        bidBondSecurity: PropTypes.string,
        bidBondSecurityIsPercentage: PropTypes.bool,
    }),
    isDocx: PropTypes.bool,
};
