import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { Outlet } from 'react-router-dom';

import { ProposalCreateModal } from './components';
import { ProjectToolbarSwitch } from './ProjectToolbarSwitch';
import { shouldShowToolbar, getProjectToolbar, isBuilderUsed } from './selectors';
import { CommentsModal } from '../CommentsModal/CommentsModal';
import { InstructionsModal } from '../InstructionsModal/InstructionsModal';
import {
    getProjectJS,
    hasAutoAddendaSubscription,
    hasContractingSubscription,
    hasEvaluationSubscription,
    hasSourcingSubscription,
    isContractAdminForProject,
} from '../selectors';
import { showConfirmationSimpleModal } from '../../../actions/confirmation';
import { menuActionHandler } from '../../../actions/govProjects';
import { ActionToolbar } from '../../../components';

const mapStateToProps = (state, props) => {
    return {
        hasAutoAddenda: hasAutoAddendaSubscription(state),
        hasContracting: hasContractingSubscription(state),
        hasEvaluation: hasEvaluationSubscription(state),
        hasSourcing: hasSourcingSubscription(state),
        isBuilderUsed: isBuilderUsed(state),
        isContractAdmin: isContractAdminForProject(state),
        project: getProjectJS(state),
        show: shouldShowToolbar(state, props),
        toolbarType: getProjectToolbar(state, props),
    };
};

const mapDispatchToProps = {
    menuHandler: menuActionHandler,
    showConfirmationSimpleModal,
};

// @connect
class ConnectedProjectActionToolbar extends Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        hasAutoAddenda: PropTypes.bool.isRequired,
        hasContracting: PropTypes.bool.isRequired,
        hasEvaluation: PropTypes.bool.isRequired,
        hasSourcing: PropTypes.bool.isRequired,
        isBuilderUsed: PropTypes.bool.isRequired,
        isContractAdmin: PropTypes.bool.isRequired,
        location: PropTypes.object.isRequired,
        menuHandler: PropTypes.func.isRequired,
        params: PropTypes.object.isRequired,
        project: PropTypes.object,
        show: PropTypes.bool.isRequired,
        showConfirmationSimpleModal: PropTypes.func.isRequired,
        toolbarType: PropTypes.string.isRequired,
        useSDv2Layout: PropTypes.bool,
    };

    get styles() {
        return require('./ProjectActionToolbar.scss');
    }

    menuHandler = (type) => {
        return this.props.menuHandler(type, this.props.project);
    };

    render() {
        const { children, menuHandler, show, useSDv2Layout, ...props } = this.props;

        return (
            <>
                {show && !useSDv2Layout && (
                    <div className={this.styles.navbarContainer}>
                        <ActionToolbar className={this.styles.projectActionToolbar}>
                            <ProjectToolbarSwitch {...props} menuActionHandler={this.menuHandler} />
                        </ActionToolbar>
                    </div>
                )}
                <Outlet />
                <ProposalCreateModal />
                <CommentsModal />
                <InstructionsModal project={this.props.project} />
            </>
        );
    }
}

export const ProjectActionToolbar = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedProjectActionToolbar);

export const ProjectActionToolbarV2 = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)((props) => <ConnectedProjectActionToolbar {...props} useSDv2Layout />);
