import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Box, Chip, Grid, Typography } from '@og-pro/ui';

import { capitalDesignTokens } from '@opengov/capital-mui-theme';

import { getReviewGeneralInformationData } from './selectors';
import { getFiscalYearSelectOptions } from '../../../../../selectors';
import { UserProfilePicture } from '../../../../../../components';
import {
    getRequisitionEndsInPurchaseOrder,
    getRequisitionJS,
} from '../../../../../../selectors/govApp';
import { CategoryCodesChipList } from '../../FormComponents/GeneralInformation/CategoryCodesChipList';

export const ReviewGeneralInformation = () => {
    const { creator, reviewGroup, requestor: requestorData } = useSelector(getRequisitionJS);
    const data = useSelector(getReviewGeneralInformationData);
    const endsInPurchaseOrder = useSelector(getRequisitionEndsInPurchaseOrder);

    const fiscalYearSelectOptions = useSelector(getFiscalYearSelectOptions);

    const labelStyles = {
        fontWeight: capitalDesignTokens.foundations.typography.fontWeight.medium,
    };

    const fieldStyles = { display: 'flex', flexDirection: 'column', gap: 0.5 };
    const requestorContainerStyles = {
        alignItems: 'center',
        display: 'flex',
        gap: 1,
    };

    return (
        <>
            <Box sx={{ marginBottom: 2 }}>
                <Grid container spacing={2}>
                    <Grid item sm={8} sx={fieldStyles} xs={6}>
                        <Typography sx={labelStyles}>Name of Request</Typography>
                        <Typography>{data.descriptionOfRequest || 'None'}</Typography>
                    </Grid>

                    <Grid item sm={3} sx={fieldStyles} xs={6}>
                        <Typography sx={labelStyles}>Created On</Typography>
                        <Typography>{moment(data.createdAt).format('MM/DD/YYYY')}</Typography>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ marginBottom: 2 }}>
                <Grid container spacing={2}>
                    <Grid item sx={fieldStyles} xs={12}>
                        <Typography sx={labelStyles}>Summary of Request</Typography>
                        <Typography>{data.summary || 'None'}</Typography>
                    </Grid>
                </Grid>
            </Box>
            {data.background && (
                <Box sx={{ marginBottom: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item sx={fieldStyles} xs={12}>
                            <Typography sx={labelStyles}>Background</Typography>
                            <Typography>{data.background}</Typography>
                        </Grid>
                    </Grid>
                </Box>
            )}
            {!data.background && (!data.categories || data.categories.length === 0) ? (
                <Box sx={{ marginBottom: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item sx={fieldStyles} xs={12}>
                            <Typography sx={labelStyles}>
                                No Background or Category Codes
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            ) : (
                <>
                    {!data.background && (
                        <Box sx={{ marginBottom: 2 }}>
                            <Grid container spacing={2}>
                                <Grid item sx={fieldStyles} xs={12}>
                                    <Typography sx={labelStyles}>No Background</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    )}

                    {!data.categories ||
                        (data.categories.length === 0 && (
                            <Box sx={{ marginBottom: 2 }}>
                                <Grid container spacing={2}>
                                    <Grid item sx={fieldStyles} xs={12}>
                                        <Typography sx={labelStyles}>No Category Codes</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        ))}
                </>
            )}
            {data.categories && data.categories.length > 0 && (
                <Box sx={{ marginBottom: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item sx={fieldStyles} xs={12}>
                            <Typography
                                sx={{
                                    ...labelStyles,
                                    alignItems: 'baseline',
                                    display: 'flex',
                                    gap: 0.5,
                                }}
                            >
                                Category Codes
                                <Chip
                                    label={data.categories.length}
                                    size="extraSmall"
                                    variant="filled"
                                />
                            </Typography>
                            <CategoryCodesChipList codes={data.categories} />
                        </Grid>
                    </Grid>
                </Box>
            )}
            <Box sx={{ marginBottom: 2 }}>
                <Grid container spacing={2}>
                    <Grid item md={4} sx={fieldStyles} xs={6}>
                        <Typography sx={labelStyles}>Requestor</Typography>
                        <Box sx={requestorContainerStyles}>
                            <UserProfilePicture user={requestorData} />
                            <Typography>{requestorData?.displayName}</Typography>
                        </Box>
                    </Grid>

                    <Grid item md={4} sx={fieldStyles} xs={6}>
                        <Typography sx={labelStyles}>Approval Group</Typography>
                        <Typography>{reviewGroup.name}</Typography>
                    </Grid>

                    <Grid item md={4} sx={fieldStyles} xs={6}>
                        <Typography sx={labelStyles}>Creator</Typography>
                        <Box sx={requestorContainerStyles}>
                            <UserProfilePicture user={creator} />
                            <Typography>{creator.displayName}</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Grid container spacing={2}>
                {endsInPurchaseOrder && (
                    <Grid item md={4} sx={fieldStyles} xs={6}>
                        <Typography sx={labelStyles}>Expected Purchase Order Date</Typography>
                        <Typography>
                            {moment(data.expectedPurchaseOrderDate).format('MM/DD/YYYY')}
                        </Typography>
                    </Grid>
                )}

                {data.desiredDeliveryDate && (
                    <Grid item md={4} sx={fieldStyles} xs={6}>
                        <Typography sx={labelStyles}>Desired Delivery Date</Typography>
                        <Typography>
                            {moment(data.desiredDeliveryDate).format('MM/DD/YYYY')}
                        </Typography>
                    </Grid>
                )}

                <Grid item md={4} sx={fieldStyles} xs={12}>
                    <Typography sx={labelStyles}>Fiscal Year</Typography>
                    <Typography>
                        {endsInPurchaseOrder
                            ? data.fiscalPeriod
                            : fiscalYearSelectOptions.find(
                                  (option) => option.value === data.fiscalPeriodTagId
                              )?.label}
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
};

ReviewGeneralInformation.propTypes = {
    data: PropTypes.shape({
        requestor: PropTypes.number,
        expectedPurchaseOrderDate: PropTypes.instanceOf(Date),
        desiredDeliveryDate: PropTypes.instanceOf(Date),
        descriptionOfRequest: PropTypes.string,
    }),
};
