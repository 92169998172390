import PropTypes from 'prop-types';
import React from 'react';
import { Button, Dialog, DialogContent, Box } from '@og-pro/ui';
import { AutoFixHigh as AIIcon, NoteAdd as CreateIcon } from '@mui/icons-material';

export const TemplateCreateDialog = ({ onClose, onCreate, open }) => (
    <Dialog
        dialogTitle="Create Template Options"
        fullWidth
        maxWidth="xs"
        onClose={onClose}
        open={open}
    >
        <DialogContent>
            <Box display="flex" flexDirection="column" gap={2}>
                <Button
                    fullWidth
                    onClick={() => onCreate(false)}
                    qaTag="templateAdmin-createFromScratch"
                    startIcon={<CreateIcon />}
                    variant="outlined"
                >
                    Create from Scratch
                </Button>
                <Button
                    color="primary"
                    fullWidth
                    onClick={() => onCreate(true)}
                    qaTag="templateAdmin-createWithAI"
                    startIcon={<AIIcon />}
                    variant="contained"
                >
                    Create with AI
                </Button>
            </Box>
        </DialogContent>
    </Dialog>
);

TemplateCreateDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};
