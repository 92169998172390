import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Box } from '@og-pro/ui';

import { CDSButton } from '../../../SDv2/CDSButtons/CDSButton';
import { InputText } from '../../../InputText';
import { evaluationPhaseFieldNames, EVALUATION_CRITERIA_LIST_PROPTYPES } from './constants';

const { TITLE } = evaluationPhaseFieldNames;

const Header = ({
    disabled,
    evaluationTitle,
    evaluationPhaseArrayName,
    removePhase,
    showFormErrors,
}) => {
    const [editing, setEditing] = useState(false);
    const styles = require('./EvaluationCriteriaSDv2List.scss');

    if (editing) {
        return (
            <Box className={styles.headerEditContainer}>
                <Field
                    component={InputText}
                    disabled={disabled}
                    label="Name of Phase"
                    name={`${evaluationPhaseArrayName}.${TITLE}`}
                    qaTag="evaluationCriteria-phaseName"
                    showValidation={showFormErrors}
                    type="text"
                    useOpenGovStyle
                />
                <Box alignItems="center" display="flex">
                    <Box flex={1}>
                        <CDSButton
                            className={styles.deleteButton}
                            disabled={disabled}
                            onClick={removePhase}
                            qaTag="evaluationCriteria-removeEvaluation"
                            size="small"
                            variant="text"
                        >
                            <i className="fa fa-trash" /> Delete Phase
                        </CDSButton>
                    </Box>
                    <Box flex={1} textAlign="right">
                        <CDSButton
                            disabled={!evaluationTitle || disabled}
                            onClick={() => setEditing(false)}
                            qaTag="evaluationCriteria-doneEditing"
                            variant="secondary"
                        >
                            Done Editing
                        </CDSButton>
                    </Box>
                </Box>
            </Box>
        );
    }

    return (
        <Box alignItems="center" display="flex">
            <Box flex={4}>
                <h3 className={styles.title}>{evaluationTitle}</h3>
            </Box>
            <Box flex={1} textAlign="right">
                <CDSButton onClick={() => setEditing(true)} size="small" variant="text">
                    <i className="fa fa-edit" /> Edit Phase
                </CDSButton>
            </Box>
        </Box>
    );
};
Header.propTypes = {
    ...EVALUATION_CRITERIA_LIST_PROPTYPES,
    removePhase: PropTypes.func.isRequired,
};

export const EvaluationCriteriaSDv2List = (props) => {
    const { children } = props;

    const styles = require('./EvaluationCriteriaSDv2List.scss');

    return (
        <Box className={styles.container}>
            <Header {...props} />
            <Box className={styles.body}>{children}</Box>
        </Box>
    );
};

EvaluationCriteriaSDv2List.propTypes = {
    ...EVALUATION_CRITERIA_LIST_PROPTYPES,
    children: PropTypes.node.isRequired,
    handleDragEnd: PropTypes.func.isRequired,
    removePhase: PropTypes.func.isRequired,
};
