import { get, isNil } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { OverlayTrigger, Panel, Popover } from 'react-bootstrap';

import {
    customColumnsData,
    defaultColumnData,
    COMMENT,
    CUSTOM_1,
    CUSTOM_2,
    CUSTOM_3,
    CUSTOM_4,
    CUSTOM_5,
    DESCRIPTION,
    DISCOUNT,
    LINE_ITEM,
    NO_BID,
    QUANTITY,
    TAXABLE,
    TOTAL_COST,
    UNIT_PRICE,
    UNIT_TO_MEASURE,
} from '@og-pro/shared-config/priceTables';

import { AgGridReactLegacy } from '../AgGridReactLegacy';
import { AgGridReactPanelHeading } from '../AgGridReactPanelHeading';
import { Button } from '../Button';
import { Tooltip } from '../Tooltip';
import { numberParser } from '../AgGridReactLegacy/utils';
import {
    calculatePriceItemTotalCost,
    calculatePriceTableTotals,
    currencyFormatter,
    getPriceItemValue,
    percentFormatter,
} from '../../helpers';
import { suppressKeyboardEvent } from '../../helpers/agGrid';
import { denoteIfTaxable } from './helper';
import { QUANTITY_REQUESTED } from '../../containers/GovApp/constants';

/**
 * @typedef ColDef
 * @property {string} row.colDef.field The `field` value of the column for an individual cell
 */

/**
 * @typedef BasePriceItem
 * @property {boolean} isHeaderRow Whether the row is a section header
 * @property {string} lineItem The value for the line item number, if any
 * @property {string} description The value for the description, if any
 * @property {number} discount The value for the percentage discount, if any
 * @property {number} quantity The value for the quantity, if any
 * @property {number} unitPrice The value for the unit price, if any
 * @property {string} unitToMeasure The value for the unit to measure, if any
 */

/**
 * @typedef {BasePriceItem} GovernmentValues
 * @property {boolean} discountOnly Whether the row is only a discount value (cannot have a unit cost if `true`)
 * @property {number} orderById Where the row should be placed in the table
 * @property {boolean} taxable Whether the row should have sales tax applied to it
 */

/**
 * @typedef {BasePriceItem} VendorResponse
 * @property {string} comment The value for the comment, if any
 */

/**
 * @typedef {BasePriceItem} PriceItem
 * @property {VendorResponse} [vendorResponse] The vendor responses for the PriceItem, if any
 */

/**
 * @typedef {PriceItem} GridPriceItem
 * @property {GovernmentValues} [governmentValues] A copy of the original PriceItem's government
 *                                                 supplied values. Used strictly for being able to
 *                                                 check if a cell is editable
 */

/**
 * @typedef PriceTable
 * @property {string} [description] The description of the price table, if any
 * @property {PriceItem[]} priceItems The price items within the price table
 * @property {string} [title] The title of the price table, if any
 */

// We only want local overrides which is why this is done here instead of via bsStyle/className
// TODO: Would love to get custom-styles working: https://react-bootstrap.github.io/utilities/custom-styles/
const PANEL_STYLES = {
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
};

const PANEL_BODY_STYLES = {
    padding: 0,
};

const REQUIRED_COLUMNS = [DESCRIPTION, DISCOUNT, LINE_ITEM, QUANTITY, UNIT_TO_MEASURE, UNIT_PRICE];
const NUMERIC_COLUMNS = [DISCOUNT, QUANTITY, QUANTITY_REQUESTED, UNIT_PRICE];

export class PriceTable extends PureComponent {
    static propTypes = {
        additionalColumns: PropTypes.arrayOf(
            PropTypes.shape({
                data: PropTypes.object.isRequired, // Column definition object
                orderIndex: PropTypes.number,
            })
        ),
        auctionMaxFractionDigits: PropTypes.number,
        change: PropTypes.func,
        columnVisibility: PropTypes.shape({
            [LINE_ITEM]: PropTypes.bool,
            [QUANTITY]: PropTypes.bool,
            [TOTAL_COST]: PropTypes.bool,
            [NO_BID]: PropTypes.bool,
        }),
        containerStyle: PropTypes.object,
        descriptionCellRenderer: PropTypes.func,
        disableAutoHeight: PropTypes.bool,
        exportFileName: PropTypes.string,
        fields: PropTypes.shape({
            name: PropTypes.string.isRequired,
        }),
        headerClassName: PropTypes.string,
        hideHeaderButtons: PropTypes.bool,
        hideSideBar: PropTypes.bool,
        isPrinterView: PropTypes.bool,
        isReverseAuction: PropTypes.bool,
        onCellValueChanged: PropTypes.func,
        priceTable: PropTypes.shape({
            columnOrder: PropTypes.array.isRequired,
            description: PropTypes.string,
            hasDiscount: PropTypes.bool,
            hasNoBid: PropTypes.bool,
            hasPercentage: PropTypes.bool,
            hasQuantity: PropTypes.bool,
            hasSalesTaxRow: PropTypes.bool,
            hasTotalRow: PropTypes.bool,
            omitLineItem: PropTypes.bool,
            priceItems: PropTypes.arrayOf(
                PropTypes.shape({
                    availableQuantity: PropTypes.number,
                    description: PropTypes.string,
                    discount: PropTypes.number,
                    discountOnly: PropTypes.bool,
                    id: PropTypes.number.isRequired,
                    isHeaderRow: PropTypes.bool,
                    lineItem: PropTypes.string,
                    quantity: PropTypes.number,
                    quantityRequested: PropTypes.number,
                    totalQuantityPurchased: PropTypes.number,
                    unitPrice: PropTypes.number,
                    unitToMeasure: PropTypes.string,
                    vendorResponse: PropTypes.shape({
                        comment: PropTypes.string,
                        description: PropTypes.string,
                        quantity: PropTypes.number,
                        unitPrice: PropTypes.number,
                        unitToMeasure: PropTypes.string,
                    }),
                })
            ).isRequired,
            specifyUnitPrice: PropTypes.bool.isRequired,
            title: PropTypes.string,
        }).isRequired,
        purchaseOrderCellsEditable: PropTypes.bool,
        readOnly: PropTypes.bool,
        salesTax: PropTypes.number,
        showValidation: PropTypes.bool,
    };

    static defaultProps = {
        additionalColumns: [],
        columnVisibility: {},
        fields: undefined,
        hideSideBar: false,
        readOnly: false,
    };

    constructor(props) {
        super(props);

        const {
            additionalColumns,
            auctionMaxFractionDigits,
            columnVisibility: colVis,
            descriptionCellRenderer,
            isReverseAuction,
            priceTable,
            purchaseOrderCellsEditable,
            readOnly,
        } = props;

        const columnDefinitions = {};

        [
            {
                field: LINE_ITEM,
                hide: isNil(colVis[LINE_ITEM]) ? priceTable.omitLineItem : !colVis[LINE_ITEM],
                cellClassRules: {
                    // Used exclusively for Excel export styles
                    headerRow: (params) => params.data.isHeaderRow,
                },
                suppressKeyboardEvent,
                width: 100,
                colSpan: (params) => {
                    if (params.data.isHeaderRow) {
                        return Infinity;
                    }
                    return 1;
                },
            },
            {
                autoHeight: true,
                cellClass: ['wrapText'], // Used exclusively for Excel export styles
                cellClassRules: {
                    // Used exclusively for Excel export styles
                    headerRow: (params) => params.data.isHeaderRow,
                },
                cellEditor: 'textEditor',
                field: DESCRIPTION,
                flex: 1,
                minWidth: 200,
                suppressKeyboardEvent,
                cellRenderer: descriptionCellRenderer,
            },
            {
                cellEditor: 'numericEditor',
                cellClass: ['text-right'],
                cellClassRules: {
                    // Used exclusively for Excel export styles
                    headerRow: (params) => params.data.isHeaderRow,
                },
                field: QUANTITY,
                hide: isNil(colVis[QUANTITY]) ? !priceTable.hasQuantity : !colVis[QUANTITY],
                suppressKeyboardEvent,
                suppressColumnsToolPanel: !priceTable.hasQuantity,
                width: 100,
            },
            {
                cellEditor: 'textEditor',
                cellEditorParams: {
                    maxLength: 64,
                },
                cellClassRules: {
                    // Used exclusively for Excel export styles
                    headerRow: (params) => params.data.isHeaderRow,
                },
                field: UNIT_TO_MEASURE,
                suppressKeyboardEvent,
                width: 150,
            },
            {
                cellEditor: 'numericEditor',
                cellEditorParams: {
                    auctionMaxFractionDigits,
                    isReverseAuction,
                },
                cellClass: [
                    priceTable.hasPercentage ? 'percent' : '4FractionCurrency',
                    'text-right',
                ], // Used exclusively for Excel export styles
                cellClassRules: {
                    // Used exclusively for Excel export styles
                    headerRow: (params) => params.data.isHeaderRow,
                },
                field: UNIT_PRICE,
                hide: isNil(colVis[UNIT_PRICE]) ? false : !colVis[UNIT_PRICE],
                suppressKeyboardEvent,
                valueFormatter: priceTable.hasPercentage
                    ? this.unitPricePercentageFormatter
                    : this.unitPriceCurrencyFormatter,
                tooltipValueGetter: (params) => {
                    if (priceTable.hasNoBid && params.value === 0) {
                        return '$0.00 is not the same as no bid. Please use the No Bid checkbox if you are not bidding on a line item.';
                    }
                },
                width: 160,
            },
            {
                cellEditor: 'numericEditor',
                cellClass: ['percent'], // Used exclusively for Excel export styles
                cellClassRules: {
                    // Used exclusively for Excel export styles
                    headerRow: (params) => params.data.isHeaderRow,
                },
                cellEditorParams: {
                    preventEmptyCell: purchaseOrderCellsEditable,
                },
                field: DISCOUNT,
                hide: !priceTable.hasDiscount,
                valueFormatter: this.unitPricePercentageFormatter,
                width: 135,
            },
            {
                cellClass: ['4FractionCurrency', 'text-right'], // Used exclusively for Excel export styles
                cellClassRules: {
                    // Used exclusively for Excel export styles
                    headerRow: (params) => params.data.isHeaderRow,
                },
                field: TOTAL_COST,
                hide: isNil(colVis[TOTAL_COST]) ? !priceTable.hasQuantity : !colVis[TOTAL_COST],
                valueFormatter: this.unitPriceCurrencyFormatter,
                width: 160,
            },
            priceTable.hasNoBid
                ? {
                      autoHeight: true,
                      cellClass: ['selection'], // Used exclusively for Excel export styles
                      cellClassRules: {
                          // Used exclusively for Excel export styles
                          headerRow: (params) => params.data.isHeaderRow,
                      },
                      cellRendererSelector: (params) => {
                          return {
                              component: 'checkboxRenderer',
                              params: {
                                  readOnly: readOnly || params.data.isSummaryRow,
                              },
                          };
                      },
                      field: NO_BID,
                      hide: isNil(colVis[NO_BID]) ? !priceTable.hasNoBid : !colVis[NO_BID],
                      suppressKeyboardEvent,
                      width: 85,
                  }
                : {},

            {
                autoHeight: true,
                cellEditor: 'textEditor',
                cellClass: ['wrapText'], // Used exclusively for Excel export styles
                cellClassRules: {
                    // Used exclusively for Excel export styles
                    headerRow: (params) => params.data.isHeaderRow,
                },
                field: COMMENT,
                suppressKeyboardEvent,
                width: 120,
            },
            {
                field: TAXABLE,
                hide: true,
                cellClassRules: {
                    // Used exclusively for Excel export styles
                    headerRow: (params) => params.data.isHeaderRow,
                },
                cellRendererSelector: (params) => {
                    return {
                        component: 'checkboxRenderer',
                        params: {
                            readOnly: readOnly || params.data.isSummaryRow,
                        },
                    };
                },
            },
        ].forEach((colDefData) => {
            const defaultData = defaultColumnData[colDefData.field];

            columnDefinitions[colDefData.field] = {
                ...colDefData,
                headerName: defaultData
                    ? priceTable[defaultData.headerField]
                    : colDefData.headerField,
            };
        });

        customColumnsData.forEach((columnData) => {
            if (priceTable[columnData.includedField]) {
                columnDefinitions[columnData.priceItemField] = {
                    cellEditor: 'textEditor',
                    cellEditorParams: {
                        maxLength: 510,
                    },
                    cellClassRules: {
                        // Used exclusively for Excel export styles
                        headerRow: (params) => params.data.isHeaderRow,
                    },
                    field: columnData.priceItemField,
                    headerName: priceTable[columnData.headerField],
                    hide: !priceTable[columnData.includedField],
                    suppressKeyboardEvent,
                    width: 110,
                };
            }
        });

        const columnDefs = priceTable.columnOrder
            .filter((columnIdentifier) => {
                return columnDefinitions[columnIdentifier];
            })
            .map((columnIdentifier) => {
                return columnDefinitions[columnIdentifier];
            });

        additionalColumns.forEach(({ data, orderIndex }) => {
            if (orderIndex === undefined) {
                columnDefs.push(data);
            } else {
                columnDefs.splice(orderIndex, 0, data);
            }
        });

        this.state = {
            columnDefs,
            defaultColDef: {
                cellClassRules: {
                    // Used exclusively for Excel export styles
                    isEditable: this.isEditable,
                },
                cellStyle: this.getCellStyle,
                editable: this.isEditable,
                sortable: false,
                suppressMenu: true,
            },
        };
    }

    get styles() {
        return require('./index.scss');
    }

    unitPriceCurrencyFormatter = (data) => {
        const { auctionMaxFractionDigits, isReverseAuction } = this.props;

        return currencyFormatter(data, {
            maximumFractionDigits: isReverseAuction ? auctionMaxFractionDigits : 4,
            useSameMinAndMax: !!isReverseAuction,
        });
    };

    unitPricePercentageFormatter = (data) => percentFormatter(data);

    isEmptyValue = (value) => value === null || value === undefined || value === '';

    isNumeric = (value) => {
        const parsedNumber = Number.parseFloat(value);
        if (Number.isNaN(parsedNumber)) {
            return false;
        }

        return parsedNumber < 100000000;
    };

    /**
     * Generate summary row grouping for the table showing the total sales tax (if applicable) and cost of all price items in the table.
     *
     * @return {GridPriceItem[]} The price items in the proper format
     */
    generateSummaryRow() {
        const { priceTable, salesTax } = this.props;

        const { salesTaxCost, totalCost } = calculatePriceTableTotals(priceTable, salesTax);

        const totalsRow = {
            [DESCRIPTION]: 'Total',
            isSummaryRow: true,
            [TOTAL_COST]: totalCost,
        };

        const summaryRows = [totalsRow];

        if (salesTaxCost !== null) {
            summaryRows.unshift({
                [DESCRIPTION]: `Sales Tax (@ ${salesTax}%)`,
                isSummaryRow: true,
                [TOTAL_COST]: salesTaxCost,
            });
        }

        return summaryRows;
    }

    /**
     * Gets the style for an individual cell in the PriceTable.
     *
     * @param {object} params The callback params from underlying AgGridReact
     * @param {ColDef} params.colDef The column definition for the cell being checked
     * @param {GridPriceItem} params.data The price item data from `getFormattedPriceTableDataForGrid`
     * @return {object} The style object for the cell
     */
    getCellStyle = (params) => {
        const {
            priceTable: { hasNoBid },
            purchaseOrderCellsEditable,
            showValidation,
        } = this.props;

        const { colDef, data } = params;

        // Base styles for all cells
        const styles = {
            backgroundColor: 'transparent',
            lineHeight: '18px',
            paddingBottom: '4px',
            paddingTop: '4px',
            whiteSpace: 'normal',
        };

        if (colDef.field === DESCRIPTION) {
            styles.whiteSpace = 'pre-wrap';
        }

        if (data.isHeaderRow) {
            return {
                ...styles,
                backgroundColor: '#eee',
                fontWeight: 'bold',
            };
        }

        let isEditable;
        switch (typeof colDef.editable) {
            case 'undefined':
                isEditable = false;
                break;
            case 'boolean':
                isEditable = colDef.editable;
                break;
            case 'function':
                isEditable = colDef.editable({ colDef, data });
                break;
            default:
                isEditable = false;
        }

        if (isEditable) {
            const emptyValue = this.isEmptyValue(params.value);
            const invalidValue =
                !emptyValue &&
                NUMERIC_COLUMNS.includes(colDef.field) &&
                !this.isNumeric(params.value);
            const isRequired = !data.isHeaderRow && REQUIRED_COLUMNS.includes(colDef.field);
            const zeroAmount = hasNoBid && colDef.field === UNIT_PRICE && params.value === 0;

            let backgroundColor = '#eff7ff';
            if (invalidValue || (isRequired && showValidation && emptyValue)) {
                backgroundColor = '#f2dede';
            } else if (zeroAmount) {
                backgroundColor = '#F0AD4E';
            } else if (isRequired && !purchaseOrderCellsEditable) {
                backgroundColor = '#FDFDD8';
            }

            styles.backgroundColor = backgroundColor;
        }

        if (!isEditable && data.discountOnly && colDef.field === UNIT_PRICE) {
            styles.backgroundColor = '#edefed';
            styles.opacity = '0.65';
            styles.cursor = 'not-allowed';
            styles.background =
                'repeating-linear-gradient(-45deg, #edefed, #edefed 5px, #dce0e0 5px, #dce0e0 10px)';
        }

        if (params.node.rowPinned && params.node.data.description === 'Total') {
            styles.fontWeight = 'bold';
        }

        return styles;
    };

    /**
     * Get price items from the price table in the correct format for AgGridReact.
     *
     * @return {GridPriceItem[]} The price items in the proper format
     */
    getFormattedPriceTableDataForGrid = () => {
        const { priceTable } = this.props;

        return priceTable.priceItems.map((priceItem) => {
            const {
                availableQuantity,
                custom1,
                custom2,
                custom3,
                custom4,
                custom5,
                description,
                discountOnly,
                id,
                isHeaderRow,
                lineItem,
                quantity,
                taxable,
                totalQuantityPurchased,
                quantityRequested,
                unitPrice,
                unitToMeasure,
                vendorResponse,
            } = priceItem;

            return {
                availableQuantity,
                comment: getPriceItemValue(COMMENT, priceItem),
                custom1: getPriceItemValue(CUSTOM_1, priceItem),
                custom2: getPriceItemValue(CUSTOM_2, priceItem),
                custom3: getPriceItemValue(CUSTOM_3, priceItem),
                custom4: getPriceItemValue(CUSTOM_4, priceItem),
                custom5: getPriceItemValue(CUSTOM_5, priceItem),
                description: denoteIfTaxable(getPriceItemValue(DESCRIPTION, priceItem), priceItem),
                discount: getPriceItemValue(DISCOUNT, priceItem),
                discountOnly,
                // Save the values initially provided so that we know what is editable in the grid
                governmentValues: {
                    availableQuantity,
                    custom1,
                    custom2,
                    custom3,
                    custom4,
                    custom5,
                    description,
                    isHeaderRow,
                    lineItem,
                    quantity,
                    quantityRequested,
                    taxable,
                    unitPrice,
                    unitToMeasure,
                },
                isHeaderRow,
                lineItem: getPriceItemValue(LINE_ITEM, priceItem),
                noBid: getPriceItemValue(NO_BID, priceItem),
                priceItemId: id,
                quantity: getPriceItemValue(QUANTITY, priceItem),
                taxable,
                totalCost: calculatePriceItemTotalCost(priceItem),
                totalQuantityPurchased,
                quantityRequested,
                unitPrice: getPriceItemValue(UNIT_PRICE, priceItem),
                unitToMeasure: getPriceItemValue(UNIT_TO_MEASURE, priceItem),
                vendorResponse,
            };
        });
    };

    /**
     * Given a row of data from the grid, get a unique ID for it.
     *
     * @param {GridPriceItem} data The raw data in this row from `getFormattedPriceTableDataForGrid`
     * @return {number} The unique id of the row in the price table
     */
    getRowNodeId = (data) => {
        if (data.isSummaryRow) {
            return 'summaryRow';
        }

        return data.priceItemId;
    };

    /**
     * Given an `onCellValueChanged` event from the underlying AgGridReact, inputs the new value
     * into the appropriate price item vendor response form field.
     *
     * @param {object} event The `onCellValueChanged` event from the underlying AgGridReact
     * @return {func} if a change handler is passed in, calls that function
     */
    handleCellValueChanged = (event) => {
        const { change, fields, onCellValueChanged, priceTable } = this.props;

        if (onCellValueChanged) {
            return onCellValueChanged(event);
        }

        const priceItemIndex = priceTable.priceItems.findIndex((priceItem) => {
            return priceItem.id === event.data.priceItemId;
        });

        if (priceItemIndex !== -1) {
            const columnFieldOfUpdatedCell = event.column.colDef.field;
            change(
                `${fields.name}[${priceItemIndex}].vendorResponse.${columnFieldOfUpdatedCell}`,
                event.newValue
            );

            // When no bid is toggled, clear out the vendor response to quantity, unit price, and discount
            if (columnFieldOfUpdatedCell === NO_BID) {
                change(`${fields.name}[${priceItemIndex}].vendorResponse.${QUANTITY}`, null);
                change(`${fields.name}[${priceItemIndex}].vendorResponse.${UNIT_PRICE}`, null);
                change(`${fields.name}[${priceItemIndex}].vendorResponse.${DISCOUNT}`, null);

                // Need to make sure value changes so row is re-rendered
                if (event.data[QUANTITY] === null) {
                    event.node.setDataValue(QUANTITY, 0);
                }
                if (event.data[UNIT_PRICE] === null) {
                    event.node.setDataValue(UNIT_PRICE, 0);
                }

                if (event.data[DISCOUNT] === null) {
                    event.node.setDataValue(DISCOUNT, 0);
                }

                // Need to re-render grid when no bid changes to display cell styling changes
                event.node.setDataValue(QUANTITY, null);
                event.node.setDataValue(UNIT_PRICE, null);
                event.node.setDataValue(DISCOUNT, null);
            }
        }
    };

    /**
     * Callback for saving a reference to the underlying AgReactGrid's API once it is ready. We need
     * access to the API to do things such as export data to a CSV.
     *
     * @param {object} params The `onGridReady` callback params
     * @param {object} params.api The underlying AgReactGrid's API
     */
    handleGridReady = (params) => {
        this.setState({ gridApi: params.api });
    };

    /**
     * Determines if an individual cell within the PriceTable is editable.
     *
     * @param {object} row The row the cell is in
     * @param {GridPriceItem} row.data The raw data in this row from
     *                                 `getFormattedPriceTableDataForGrid`
     * @param {ColDef} row.colDef The column definition for the cell being checked
     * @return {boolean} `true` if the cell can be edited, `false` otherwise
     */
    isEditable = (row) => {
        const { purchaseOrderCellsEditable, readOnly } = this.props;

        const { colDef, data } = row;

        if (readOnly) {
            return false;
        }

        if (
            purchaseOrderCellsEditable &&
            (colDef.field === DISCOUNT ||
                colDef.field === UNIT_PRICE ||
                colDef.field === QUANTITY_REQUESTED)
        ) {
            return true;
        }

        if (purchaseOrderCellsEditable) {
            return false;
        }

        if (data.isSummaryRow || colDef.field === TOTAL_COST || data.isHeaderRow) {
            return false;
        }

        // No bid uses a cell renderer instead of an editor, so ensure it is always uneditable
        if (colDef.field === NO_BID) {
            return false;
        }

        if (colDef.field === COMMENT) {
            return true;
        }

        if (data.discountOnly && colDef.field === UNIT_PRICE) {
            return false;
        }

        if (!this.isEmptyValue(data.governmentValues[colDef.field])) {
            return false;
        }

        return !(
            data.noBid &&
            (colDef.field === QUANTITY || colDef.field === UNIT_PRICE || colDef.field === DISCOUNT)
        );
    };

    // Prevents strings from being pasted into cells
    processCellFromClipboard = (params) => {
        const { cellEditorParams, field } = params.column.colDef;

        // Do not allow pasting into summary row
        if (params.node.data.isSummaryRow) {
            return null;
        }

        if (field === QUANTITY || field === UNIT_PRICE || field === DISCOUNT) {
            return numberParser(params, 'value');
        }

        // Prevent pasted value from exceeding max length if specified
        const maxLength = get(cellEditorParams, 'maxLength');
        if (maxLength && typeof params.value === 'string' && params.value.length > maxLength) {
            return params.value.slice(0, maxLength);
        }

        return params.value;
    };

    renderInstructions() {
        const {
            priceTable: { hasNoBid, hasSalesTaxRow },
            salesTax,
            showValidation,
        } = this.props;

        const popover = (
            <Popover
                id="price-table-legend"
                title={<div className="text-center">Price Table Instructions</div>}
            >
                <div className={this.styles.legendContainer}>
                    <div
                        className={this.styles.legendColor}
                        style={{ backgroundColor: '#FDFDD8' }}
                    />
                    <div className={this.styles.legendText}>Required Cell</div>
                </div>
                <div className={this.styles.legendContainer}>
                    <div
                        className={this.styles.legendColor}
                        style={{ backgroundColor: '#eff7ff' }}
                    />
                    <div className={this.styles.legendText}>Optional Cell</div>
                </div>
                {!!salesTax && hasSalesTaxRow && (
                    <div className={this.styles.legendContainer}>
                        <div className={this.styles.legendColor} style={{ border: 'none' }}>
                            <p>[*]</p>
                        </div>
                        <div className={this.styles.legendText}>Denotes item is taxable</div>
                    </div>
                )}
                {showValidation && (
                    <div className={this.styles.legendContainer}>
                        <div
                            className={this.styles.legendColor}
                            style={{ backgroundColor: '#f2dede' }}
                        />
                        <div className={this.styles.legendText}>Invalid Cell (must be updated)</div>
                    </div>
                )}
                {hasNoBid && (
                    <div className={this.styles.legendContainer}>
                        <div
                            className={this.styles.legendColor}
                            style={{ backgroundColor: '#F0AD4E' }}
                        />
                        <div className={this.styles.legendText}>Zero Amount (use No Bid cell)</div>
                    </div>
                )}
                <small>
                    <em>
                        Please complete all the required cells. You may also enter any relevant
                        information in the optional cells.
                    </em>
                </small>
            </Popover>
        );

        return (
            <OverlayTrigger key="instructions" overlay={popover} placement="bottom">
                <Button bsStyle="link">
                    <i className="fa fa-info-circle" /> Instructions
                </Button>
            </OverlayTrigger>
        );
    }

    render() {
        const {
            containerStyle,
            disableAutoHeight,
            exportFileName,
            fields,
            headerClassName,
            hideHeaderButtons,
            hideSideBar,
            isPrinterView,
            isReverseAuction,
            priceTable: { description, hasSalesTaxRow, hasTotalRow, specifyUnitPrice, title },
            readOnly,
            salesTax,
        } = this.props;

        const { columnDefs, defaultColDef, gridApi } = this.state;

        const getTableTitle = () => {
            return (
                <>
                    {title}
                    {isReverseAuction && specifyUnitPrice && (
                        <Tooltip
                            placement="right"
                            tooltip="The agency has set the initial bid price."
                        >
                            <i
                                className={`fa fa-exclamation-triangle ${this.styles.specifyUnitPriceIcon}`}
                            />
                        </Tooltip>
                    )}
                </>
            );
        };

        return (
            <Panel defaultExpanded style={PANEL_STYLES}>
                <AgGridReactPanelHeading
                    buttons={readOnly ? undefined : [this.renderInstructions()]}
                    exportFileName={exportFileName}
                    gridApi={gridApi}
                    headerClassName={headerClassName}
                    hideButtons={hideHeaderButtons}
                    title={getTableTitle()}
                />
                <Panel.Body style={PANEL_BODY_STYLES}>
                    {description ? <span>{description}</span> : null}
                    <AgGridReactLegacy
                        autoHeightMaxRows={30}
                        columns={columnDefs}
                        containerStyle={containerStyle}
                        defaultColDef={defaultColDef}
                        disableAutoHeight={disableAutoHeight}
                        enableCellChangeFlash
                        getRowNodeId={this.getRowNodeId}
                        hideSideBar={hideSideBar}
                        isPrinterView={isPrinterView}
                        onCellValueChanged={fields ? this.handleCellValueChanged : undefined}
                        onGridReady={this.handleGridReady}
                        pinnedBottomRow={hasTotalRow ? this.generateSummaryRow() : undefined}
                        processCellFromClipboard={this.processCellFromClipboard}
                        rows={this.getFormattedPriceTableDataForGrid()}
                    />
                    {!!salesTax && hasSalesTaxRow && (
                        <div className={this.styles.tableFooter}>
                            <span>[*]</span>
                            <p> Denotes item is taxable</p>
                        </div>
                    )}
                </Panel.Body>
            </Panel>
        );
    }
}
