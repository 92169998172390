import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Button } from '../Button';

export class ActionToolbarNavbarButton extends PureComponent {
    static propTypes = {
        active: PropTypes.node,
        activeHref: PropTypes.string,
        activeKey: PropTypes.string,
        bsStyle: PropTypes.string,
        children: PropTypes.node.isRequired,
        className: PropTypes.string,
        editOnly: PropTypes.bool,
        isEditor: PropTypes.bool,
        qaTag: PropTypes.string.isRequired,
        tooltip: PropTypes.string,
    };

    static defaultProps = {
        className: '',
    };

    get styles() {
        return require('./ActionToolbar.scss');
    }

    render() {
        const { active, activeHref, activeKey, className, editOnly, isEditor, tooltip, ...props } =
            this.props;

        if (editOnly && !isEditor) return null;

        return (
            <li>
                <Button
                    bsSize={this.props.bsStyle === 'link' ? undefined : 'sm'}
                    className={`navbar-btn ${this.styles.navBtn} ${className}`}
                    qaTag="actionToolbarNavbar-action"
                    tooltip={tooltip}
                    tooltipPlacement="bottom"
                    {...props}
                />
            </li>
        );
    }
}
