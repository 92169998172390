import PropTypes from 'prop-types';
import { truncate } from 'lodash';
import React, { PureComponent } from 'react';
import { Field } from 'redux-form';
import { Box, Typography } from '@og-pro/ui';

import { attachmentFieldsDict } from './constants';
import { getFileExtensionIcon } from '../../../helpers';
import { DragIcon } from '../../DragIcon';
import { CDSButton } from '../../SDv2/CDSButtons/CDSButton';
import { SearchSelect } from '../../SearchSelect/SearchSelect';
import { InputText } from '../../InputText';
import { Button } from '../../Button';

const { TITLE, APPENDIX_ID } = attachmentFieldsDict;

export class AttachmentListItemForm extends PureComponent {
    static propTypes = {
        appendixOptions: PropTypes.array.isRequired,
        arrayName: PropTypes.string.isRequired,
        closeForm: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        dragHandleProps: PropTypes.any,
        extension: PropTypes.string,
        hideAppendixLetter: PropTypes.bool,
        isOGThemeEnabledForComponents: PropTypes.bool,
        name: PropTypes.string,
        remove: PropTypes.func.isRequired,
        url: PropTypes.string,
    };

    render() {
        const {
            arrayName,
            appendixOptions,
            closeForm,
            disabled,
            dragHandleProps,
            extension,
            hideAppendixLetter,
            isOGThemeEnabledForComponents,
            name,
            remove,
            url,
        } = this.props;

        const styles = require('./AttachmentListItem.scss');

        if (isOGThemeEnabledForComponents) {
            const fileIcon = getFileExtensionIcon(extension);

            return (
                <Box className={styles.attachmentFormContainer}>
                    <Box alignItems="center" display="flex">
                        {dragHandleProps && (
                            <Box>
                                <DragIcon
                                    className={styles.dragIcon}
                                    disabled={disabled}
                                    dragHandleProps={dragHandleProps}
                                    useSpan
                                />
                            </Box>
                        )}
                        <Box flex={1}>
                            <Typography className={styles.link} component="a" href={url}>
                                <i className={`fa fa-${fileIcon}`} />{' '}
                                {truncate(name, { length: 100 })}
                            </Typography>
                        </Box>
                        <Box textAlign="right">
                            <CDSButton
                                className={styles.closeButton}
                                disabled={disabled}
                                onClick={closeForm}
                                qaTag="attachmentListItemForm-close"
                                variant="text"
                            >
                                <i className="fa fa-times" />
                            </CDSButton>
                        </Box>
                    </Box>
                    <Box alignItems="center" display="flex" mt={2}>
                        {!hideAppendixLetter && (
                            <Box flex={3} mr={1}>
                                <Field
                                    component={SearchSelect}
                                    disabled={disabled}
                                    hideDisabledMenuOptions
                                    isOptionDisabled={(option) => option.disabled}
                                    isSearchable={false}
                                    label="Attachment ID"
                                    name={`${arrayName}.${APPENDIX_ID}`}
                                    options={appendixOptions}
                                    useOpenGovStyle
                                />
                            </Box>
                        )}
                        <Box flex={8} mr={1}>
                            <Field
                                component={InputText}
                                disabled={disabled}
                                hasFeedback={false}
                                label="Title"
                                name={`${arrayName}.${TITLE}`}
                                showValidation
                                type="text"
                                useOpenGovStyle
                            />
                        </Box>
                        <Box flex={1} pt={1.4} textAlign="right">
                            <CDSButton
                                disabled={disabled}
                                onClick={closeForm}
                                qaTag="attachmentListItemForm-save"
                                variant="primary"
                            >
                                Save
                            </CDSButton>
                        </Box>
                    </Box>
                    <Box pt={1}>
                        <CDSButton
                            className={styles.removeButton}
                            disabled={disabled}
                            onClick={remove}
                            qaTag="attachmentListItemForm-remove"
                            size="small"
                            variant="text"
                        >
                            <i className="fa fa-trash" /> Remove Attachment
                        </CDSButton>
                    </Box>
                </Box>
            );
        }

        return (
            <div className="row">
                <div className={`col-xs-2 text-left ${styles.actionIconFormCol}`}>
                    {dragHandleProps && (
                        <DragIcon
                            className={styles.dragIcon}
                            disabled={disabled}
                            dragHandleProps={dragHandleProps}
                            useSpan
                        />
                    )}
                    <Button
                        bsStyle="link"
                        className={styles.removeIcon}
                        disabled={disabled}
                        onClick={remove}
                    >
                        <i className="fa fa-lg fa-times text-danger" />
                    </Button>
                    <Button
                        bsStyle="link"
                        className={styles.editIcon}
                        disabled={disabled}
                        onClick={closeForm}
                    >
                        <i className="fa fa-lg fa-pencil" />
                    </Button>
                </div>
                {!hideAppendixLetter && (
                    <div className={`col-xs-2 ${styles.titleCol}`}>
                        <Field
                            component={SearchSelect}
                            disabled={disabled}
                            hideDisabledMenuOptions
                            isOptionDisabled={(option) => option.disabled}
                            isSearchable={false}
                            name={`${arrayName}.${APPENDIX_ID}`}
                            options={appendixOptions}
                        />
                    </div>
                )}
                <div className={hideAppendixLetter ? 'col-xs-8' : 'col-xs-6'}>
                    <Field
                        component={InputText}
                        disabled={disabled}
                        hasFeedback={false}
                        name={`${arrayName}.${TITLE}`}
                        type="text"
                    />
                </div>
                <div className="col-xs-2">
                    <Button bsStyle="primary" disabled={disabled} onClick={closeForm}>
                        Done
                    </Button>
                </div>
            </div>
        );
    }
}
