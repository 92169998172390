import React, { useMemo } from 'react';
import { Field } from 'redux-form';

import { Box, ReduxFormSelect } from '@og-pro/ui';

import { RadioButtons } from '../../../../../../components';
import { useQueryParam } from '../../../../../../hooks';
import { fieldNames } from '../../constants';
import { getQuickFilters } from '../../helpers';

export const RequisitionListQuickFilters = () => {
    const [tab] = useQueryParam('tab');
    const quickFilters = useMemo(() => getQuickFilters(tab), [tab]);

    const { QUICK_FILTER } = fieldNames;

    const [, , setQueryParams] = useQueryParam('quickFilter', quickFilters[0].value);

    const handleOptionChange = (value) => {
        setQueryParams({
            quickFilter: value,
            page: 1,
        });
    };

    return (
        <Box>
            {/* Desktop View */}
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <Field
                    component={RadioButtons}
                    legend="Request Quick Filters"
                    name={QUICK_FILTER}
                    onChange={handleOptionChange}
                    options={quickFilters}
                />
            </Box>
            {/* Mobile View */}
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <Field
                    component={ReduxFormSelect}
                    fullWidth
                    name={QUICK_FILTER}
                    onChange={handleOptionChange}
                    options={quickFilters}
                    sx={{ margin: 0 }}
                />
            </Box>
        </Box>
    );
};
