import PropTypes from 'prop-types';
import classnames from 'classnames';
import React, { PureComponent } from 'react';
import { FormGroup } from 'react-bootstrap';
import { isString } from 'lodash';

import { HelpBlock } from '../HelpBlock/HelpBlock';
import { Label } from '../InputText/Label';
import { RadioItems } from './RadioItems';

export class RadioGroup extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        defaultChecked: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        groupLabel: PropTypes.string.isRequired,
        help: PropTypes.string,
        inline: PropTypes.bool,
        input: PropTypes.object.isRequired,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        meta: PropTypes.object.isRequired,
        normalizeOption: PropTypes.func,
        options: PropTypes.array.isRequired,
        useOpenGovStyle: PropTypes.bool,
        showValidation: PropTypes.bool,
        sublabel: PropTypes.string,
    };

    get styles() {
        if (this.props.useOpenGovStyle) {
            return require('./RadioGroupOpenGovStyle.scss');
        }

        return require('./RadioGroup.scss');
    }

    render() {
        const {
            className,
            help,
            inline,
            label,
            meta: { touched, error },
            useOpenGovStyle,
            showValidation,
            sublabel,
        } = this.props;

        const displayError = (touched || showValidation) && !!error;
        const validationState = displayError ? 'error' : null;
        const helpText = displayError ? error : help;

        if (useOpenGovStyle) {
            return (
                <FormGroup
                    className={classnames(this.styles.formGroup, {
                        [this.styles.formGroupInline]: inline,
                    })}
                    validationState={validationState}
                >
                    {label && (
                        <Label
                            className={classnames(this.styles.label, {
                                [this.styles.noMarginBottom]: !!help,
                            })}
                            label={label}
                        />
                    )}
                    {help && <HelpBlock useOpenGovStyle={useOpenGovStyle}>{help}</HelpBlock>}
                    <RadioItems
                        {...this.props}
                        className={classnames(this.styles.input, {
                            [this.styles.errorInput]: displayError,
                        })}
                    />
                    {displayError && isString(error) && (
                        <HelpBlock
                            className={this.styles.helpErrorBlock}
                            useOpenGovStyle={useOpenGovStyle}
                        >
                            {useOpenGovStyle ? (
                                <>
                                    <i className="fa fa-exclamation-triangle" />
                                    &nbsp;
                                    {error}
                                </>
                            ) : (
                                error
                            )}
                        </HelpBlock>
                    )}
                </FormGroup>
            );
        }

        return (
            <FormGroup className={className} validationState={validationState}>
                {label && (
                    <div>
                        <b>{label}</b>
                    </div>
                )}
                {sublabel && <div className={this.styles.muted}>{sublabel}</div>}
                <RadioItems {...this.props} />
                {helpText && <HelpBlock>{helpText}</HelpBlock>}
            </FormGroup>
        );
    }
}
