import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { subsectionTypeNames } from '@og-pro/shared-config/subsections';

import { getSectionNumberingString } from '@og-pro/shared-config/helpers';

import { ProjectDetailTitle } from '../Title';
import { SectionIntro } from '../SectionIntro';
import { CriteriaItemForm } from '../CriteriaItemForm';
import { form } from '../CriteriaItemForm/constants';
import { Button } from '../../../Button';
import { CriteriaItem } from '../../../CriteriaItem/CriteriaItem';

const { BODY } = subsectionTypeNames;

export class ProjectCriteriaItems extends Component {
    static propTypes = {
        criteria: PropTypes.array.isRequired,
        criteriaActions: PropTypes.object,
        description: PropTypes.object.isRequired,
        isDocx: PropTypes.bool,
        isEditable: PropTypes.bool,
        isReadOnly: PropTypes.bool,
        isTextArea: PropTypes.bool,
        projectId: PropTypes.number.isRequired,
        projectSection: PropTypes.object.isRequired,
        projectSubsectionsMap: PropTypes.object.isRequired,
        section: PropTypes.string.isRequired,
        sectionDescActions: PropTypes.object,
        showComments: PropTypes.bool,
        showConfirmationSimpleModal: PropTypes.func,
        tagOptions: PropTypes.array,
        templateVariableOptions: PropTypes.array,
        title: PropTypes.string,
        useManualNumbering: PropTypes.bool,
        useSectionDividers: PropTypes.bool,
        writingFormRoute: PropTypes.string,
    };

    static defaultProps = {
        criteriaActions: undefined,
        isDocx: false,
        isEditable: false,
        isReadOnly: false,
        sectionDescActions: undefined,
        showComments: false,
        showConfirmationSimpleModal: undefined,
        tagOptions: undefined,
        templateVariableOptions: undefined,
        title: undefined,
        writingFormRoute: undefined,
    };

    constructor(props) {
        super(props);
        this.state = {
            creating: false,
            showCreateForm: false,
        };
    }

    get projectSubsectionId() {
        return this.props.projectSubsectionsMap[BODY].id;
    }

    closeCreateCriteriaForm = () => this.setState({ showCreateForm: false });

    openCreateCriteriaForm = () => this.setState({ showCreateForm: true });

    createCriteriaItem = (data) => {
        const {
            criteriaActions: { criteriaCreate },
            projectId,
            projectSection,
            section,
        } = this.props;

        const formData = {
            ...data,
            project_section_id: projectSection.id,
            project_subsection_id: this.projectSubsectionId,
            section_type: section,
            subsection_type: BODY,
        };

        const onLoading = () => this.setState({ creating: true, createError: null });
        const onSuccess = () => this.setState({ creating: false, showCreateForm: false });
        const onError = (error) => {
            const createError = (error && error.message) || 'Error creating item';
            this.setState({ creating: false, createError });
        };

        criteriaCreate(projectId, formData, {
            onLoading,
            onSuccess,
            onError,
        });
    };

    renderCreateItem() {
        const {
            isEditable,
            isDocx,
            isTextArea,
            projectSection,
            tagOptions,
            templateVariableOptions,
            useManualNumbering,
        } = this.props;

        if (!isEditable || isDocx || isTextArea || useManualNumbering) {
            return null;
        }

        const { creating, createError, showCreateForm } = this.state;

        if (showCreateForm) {
            return (
                <div className="criteria-create-container">
                    <CriteriaItemForm
                        closeForm={this.closeCreateCriteriaForm}
                        form={`${form}-${projectSection.id}`}
                        onSubmit={this.createCriteriaItem}
                        submitServerError={createError}
                        tagOptions={tagOptions}
                        templateVariableOptions={templateVariableOptions}
                        updating={creating}
                    />
                </div>
            );
        }
        return (
            <div className="text-center criteria-create-container">
                <Button
                    aria-label="Add Item"
                    bsSize="sm"
                    onClick={this.openCreateCriteriaForm}
                    qaTag="projectCriteriaItems-addItem"
                >
                    <i className="fa fa-plus" /> Add Item
                </Button>
            </div>
        );
    }

    render() {
        const {
            criteria,
            criteriaActions,
            description,
            isDocx,
            isEditable,
            isReadOnly,
            isTextArea,
            projectSection: { sectionNumber, subsectionNumber },
            projectSection,
            sectionDescActions,
            showComments,
            showConfirmationSimpleModal,
            tagOptions,
            templateVariableOptions,
            title,
            useManualNumbering,
            useSectionDividers,
            writingFormRoute,
        } = this.props;

        const usedCriteria = criteria.filter((crit) => !crit.isUnused);

        let itemNumber = 0;
        let subitemNumber = 0;
        const CriteriaListItems = usedCriteria.map((item) => {
            if (item.isHiddenByLogic) {
                return null;
            }

            if (!item.nestLevel) {
                itemNumber++;
                subitemNumber = 0;
            } else {
                subitemNumber++;
            }
            return (
                <CriteriaItem
                    commentType={item.commentType}
                    criteria={item}
                    isDocx={isDocx}
                    isEditable={isEditable}
                    isReadOnly={isReadOnly}
                    isTextArea={isTextArea}
                    itemNumber={getSectionNumberingString({
                        sectionNumber,
                        subsectionNumber,
                        subSectionItemNumber: itemNumber,
                    })}
                    key={item.id}
                    projectSection={projectSection}
                    showComment={showComments}
                    showConfirmationSimpleModal={showConfirmationSimpleModal}
                    subitemNumber={subitemNumber}
                    tagOptions={tagOptions}
                    templateVariableOptions={templateVariableOptions}
                    useManualNumbering={useManualNumbering}
                    useSectionDividers={useSectionDividers}
                    writingFormRoute={writingFormRoute}
                    {...criteriaActions}
                />
            );
        });

        return (
            <div className="col-xs-12 col-md-offset-1 col-md-10 list-item-section">
                <ProjectDetailTitle
                    isDocx={isDocx}
                    projectSection={projectSection}
                    projectSubsectionId={this.projectSubsectionId}
                    showComments={showComments}
                    title={title}
                    useManualNumbering={useManualNumbering}
                    useSectionDividers={useSectionDividers}
                />
                <SectionIntro
                    {...sectionDescActions}
                    isDocx={isDocx}
                    isEditable={isEditable}
                    projectSectionId={projectSection.id}
                    projectSubsectionId={this.projectSubsectionId}
                    sectionDescription={description[this.projectSubsectionId]}
                    tagOptions={tagOptions}
                    templateVariableOptions={templateVariableOptions}
                />
                {CriteriaListItems}
                {this.renderCreateItem()}
            </div>
        );
    }
}
