import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Button } from '../../Button';
import { commentDateFormatter } from '../../../utils';

export class CommentReopen extends PureComponent {
    static propTypes = {
        canComment: PropTypes.bool.isRequired,
        threadId: PropTypes.number.isRequired,
        resolvedAt: PropTypes.string,
        resolvedBy: PropTypes.string,
        resolving: PropTypes.bool,
        resolveError: PropTypes.string,
        reopenClickHandler: PropTypes.func.isRequired,
    };

    handleReopenClick = () => {
        const { reopenClickHandler, threadId } = this.props;

        reopenClickHandler(threadId);
    };

    render() {
        const { canComment, resolvedAt, resolvedBy, resolving, resolveError } = this.props;

        const styles = require('./CommentReopen.scss');
        const replyStyles = require('./CommentReply.scss');

        return (
            <div className={`${replyStyles.replyContainer} text-center`}>
                <Button
                    bsStyle="info"
                    disabled={!canComment || resolving}
                    onClick={this.handleReopenClick}
                >
                    Reopen
                </Button>
                <div className={styles.resolvedAtDisplay}>
                    Resolved {commentDateFormatter(resolvedAt)} by {resolvedBy}
                </div>
                <div className={styles.resolveError}>{resolveError || null}</div>
            </div>
        );
    }
}
