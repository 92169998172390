import classnames from 'classnames';
import { pick } from 'lodash';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import {
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
    getFilteredRowModel,
    getPaginationRowModel,
} from '@tanstack/react-table';

import { ButtonGroup } from '../../ButtonGroup/ButtonGroup';
import { Collapse } from '../../Collapse';
import { LoadingButton } from '../../LoadingButton';
import { exportArrayToCSV } from '../../../utils';
import { Template } from './Template';
import { migrationTools } from './migrationTools';
import { propTypes } from '../propTypes';

const Title = ({ collapsible, title, showTable, className, toggleShowTable }) => {
    const styles = require('../index.scss');

    if (!collapsible && !title) {
        return null;
    }

    return (
        <div
            className={classnames(
                styles.titleContainer,
                collapsible && styles.collapsible,
                className
            )}
            onClick={collapsible ? toggleShowTable : undefined}
        >
            {collapsible && <i className={`fa fa-fw fa-angle-${showTable ? 'down' : 'right'}`} />}
            {title}
        </div>
    );
};

const Table = (props) => {
    const { collapsible, showTable } = props;

    const TableTemplate = <Template {...props} />;

    if (collapsible) {
        return <Collapse in={showTable}>{TableTemplate}</Collapse>;
    }

    return TableTemplate;
};

export const DataTable = forwardRef((props, ref) => {
    const styles = require('../index.scss');
    const {
        buttons,
        columns,
        csvExportIsLoading,
        data,
        componentsInlineWithButtons,
        componentsAfterButtons,
        showCSVExport,
    } = props;
    const safeProps = pick(props, [
        'initialState',
        'getPageOptions',
        'manualPagination',
        'manualSorting',
        'onPaginationChange',
        'rowCount',
        'usePagination',
        'getPaginationRowModel',
        'getSortedRowModel',
        'onSortedChange',
        'state',
    ]);

    const [showTable, setShowTable] = useState(false);

    // Yes, we must manually handle sorting because if a custom onSortingChange handler is passed to useReactTable and
    // overrides the default handler.
    const [sorting, setSorting] = useState(props.sorted || props.defaultSorted || []);

    useEffect(() => {
        setSorting(props.sorted);
    }, [props.sorted]);

    const columnResizeMode = 'onChange';
    const table = useReactTable({
        data,
        columns: migrationTools.columnAdapter(columns),
        enableColumnResizing: true,
        columnResizeMode,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting,
        },
        initialState: {
            pagination: {
                pageSize: props.pageSize || props.defaultPageSize || 20,
            },
        },
        usePagination: props.showPagination,
        onSortingChange: (sortFn) => {
            const newSorted = sortFn();
            setSorting(newSorted);
            props.onSortedChange?.(newSorted);
        },
        ...safeProps,
    });

    const toggleShowTable = () => setShowTable((p) => !p);

    const handleExportCSVClick = useCallback(
        () => async () => {
            const { csvExportOptions } = props;

            let csvData;
            let fileName;
            let headers;
            const sortedData = migrationTools.formatForExport(table.getRowModel().rows);
            const columnsForExport = table.getAllColumns();

            if (csvExportOptions) {
                fileName = csvExportOptions.fileName;
                headers = csvExportOptions.headers;
            }

            if (csvExportOptions && csvExportOptions.getFormattedCSVData) {
                csvData = await csvExportOptions.getFormattedCSVData(sortedData);
            } else {
                csvData = sortedData.map((dataRow) => {
                    return columnsForExport.map((column) => {
                        if (column.id) {
                            return dataRow[column.id];
                        }

                        return dataRow[column.accessor];
                    });
                });
            }

            exportArrayToCSV(csvData, { fileName, headers });
        },
        [props, table]
    );

    useImperativeHandle(
        ref,
        () => ({
            handleExportCSVClick: handleExportCSVClick(),
        }),
        [handleExportCSVClick]
    );

    let buttonGroup = buttons || [];

    if (showCSVExport) {
        buttonGroup = buttonGroup.concat(
            <LoadingButton
                className="no-print"
                icon="fa-download"
                key="csvExportButton"
                loading={csvExportIsLoading}
                onClick={handleExportCSVClick()}
                qaTag="dataTable-exportToCsv"
                text="Export to CSV"
            />
        );
    }

    if (buttonGroup.length > 0) {
        return (
            <>
                <div className={`text-right ${styles.buttonGroup}`}>
                    <Title
                        {...props}
                        className="pull-left"
                        showTable={showTable}
                        toggleShowTable={toggleShowTable}
                    />
                    <div className={styles.buttonsContainer}>
                        {componentsInlineWithButtons}
                        <ButtonGroup
                            bsSize="sm"
                            className={classnames(componentsInlineWithButtons && 'pull-right')}
                        >
                            {buttonGroup}
                        </ButtonGroup>
                    </div>
                </div>
                <div className="clearfix" />
                {componentsAfterButtons}
                <Table {...props} showTable={showTable} table={table} />
            </>
        );
    }

    return (
        <>
            <Title {...props} showTable={showTable} toggleShowTable={toggleShowTable} />
            <Table {...props} showTable={showTable} table={table} />
        </>
    );
});

Title.propTypes = {
    collapsible: propTypes.collapsible,
    title: propTypes.title,
    showTable: PropTypes.bool,
    className: PropTypes.string,
    toggleShowTable: PropTypes.func,
};

Table.propTypes = {
    className: PropTypes.string,
    collapsible: propTypes.collapsible,
    showTable: PropTypes.bool,
    table: PropTypes.object,
};

DataTable.propTypes = propTypes;
