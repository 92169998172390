import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { CriteriaScore } from './CriteriaScore';
import { HtmlContent } from '../HtmlContent/HtmlContent';
import { roundNumber } from '../../helpers';

export class CriteriaScoreCell extends PureComponent {
    static propTypes = {
        comment: PropTypes.string,
        criteriaScoreClassName: PropTypes.string,
        isTotal: PropTypes.bool,
        isUnsubmittedScore: PropTypes.bool,
        scoringCriteria: PropTypes.shape({
            maxScore: PropTypes.number.isRequired,
            scoringMethod: PropTypes.number,
        }),
        showComments: PropTypes.bool,
        showPercentageTotals: PropTypes.bool,
        showTitle: PropTypes.bool,
        titleText: PropTypes.string,
        totalScorePercentage: PropTypes.number,
        value: PropTypes.number,
    };

    TOTAL_CELL_SCORING_CRITERIA = {
        maxScore: 100,
        title: 'Total Score',
    };

    get styles() {
        return require('./index.scss');
    }

    renderTotalCell() {
        const {
            comment,
            criteriaScoreClassName,
            showComments,
            showPercentageTotals,
            showTitle,
            titleText,
            totalScorePercentage,
            value,
        } = this.props;

        const { commentContainer, totalRow } = this.styles;

        const criteriaScore = (
            <CriteriaScore
                className={classnames('text-center', totalRow, criteriaScoreClassName)}
                criteriaScore={roundNumber(value, 1)}
                isTotal
                scoringCriteria={this.TOTAL_CELL_SCORING_CRITERIA}
                showPercentageTotals={showPercentageTotals}
                showTitle={showTitle}
                titleText={titleText}
                totalScorePercentage={totalScorePercentage}
            />
        );
        if (showComments) {
            return (
                <div className="data-table-text-wrap">
                    {criteriaScore}
                    {comment && <HtmlContent className={commentContainer} content={comment} />}
                </div>
            );
        }
        return criteriaScore;
    }

    render() {
        const {
            comment,
            criteriaScoreClassName,
            isTotal,
            isUnsubmittedScore,
            scoringCriteria,
            showComments,
            showTitle,
            titleText,
            value,
        } = this.props;

        if (isTotal) {
            return this.renderTotalCell();
        }

        const criteriaScore = (
            <CriteriaScore
                className={classnames('text-center', criteriaScoreClassName)}
                criteriaScore={roundNumber(value, 2)}
                isUnsubmittedScore={isUnsubmittedScore}
                scoringCriteria={scoringCriteria}
                showTitle={showTitle}
                titleText={titleText}
            />
        );

        // Expanded display with comments included
        if (showComments) {
            return (
                <div className="data-table-text-wrap">
                    {criteriaScore}
                    {comment && <div className={this.styles.commentContainer}>{comment}</div>}
                </div>
            );
        }

        // Compact display (scores only)
        return criteriaScore;
    }
}
