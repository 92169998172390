import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { HtmlBlurb } from '../../HtmlBlurb';
import { HtmlContent } from '../../HtmlContent/HtmlContent';
import { shouldHtmlBeTruncated } from '../../../utils';

export class ItemDescription extends PureComponent {
    static propTypes = {
        description: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = { showAll: false };
    }

    MAX_LENGTH = 220;

    handleShowLessClick = () => {
        this.setState({ showAll: false });
    };

    handleShowMoreClick = () => {
        this.setState({ showAll: true });
    };

    renderBlurb() {
        const { description } = this.props;

        const useBlurb = shouldHtmlBeTruncated(description, this.MAX_LENGTH);
        if (!useBlurb) return this.renderAll(true);

        const blurb = (
            <HtmlBlurb content={description} determineHtml maxLength={this.MAX_LENGTH - 20} />
        );

        const styles = require('./ItemDescription.scss');
        return (
            <div>
                {blurb}
                <span
                    className={`pseudoLink ${styles.showToggle}`}
                    onClick={this.handleShowMoreClick}
                >
                    Show more <i className="fa fa-caret-down" />
                </span>
            </div>
        );
    }

    renderAll(hideLessLink) {
        const { description } = this.props;

        const Content = <HtmlContent content={description} determineHtml />;
        if (hideLessLink) return Content;

        const styles = require('./ItemDescription.scss');
        return (
            <div>
                {Content}
                <span
                    className={`pseudoLink ${styles.showToggle}`}
                    onClick={this.handleShowLessClick}
                >
                    Show less <i className="fa fa-caret-up" />
                </span>
            </div>
        );
    }

    render() {
        const styles = require('./ItemDescription.scss');
        return (
            <div className="well">
                <article className={styles.articleContainer}>
                    {this.state.showAll ? this.renderAll() : this.renderBlurb()}
                </article>
            </div>
        );
    }
}
