import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { proposalStatusesDict } from '@og-pro/shared-config/proposals';

import { DataTable } from '../DataTable/DataTable';
import { ZeroState } from '../ZeroState';

const { PUBLISHED } = proposalStatusesDict;

export class AddendumsConfirmMatrix extends PureComponent {
    static propTypes = {
        addendums: PropTypes.arrayOf(
            PropTypes.shape({
                titleDisplay: PropTypes.string.isRequired,
            })
        ).isRequired,
        proposals: PropTypes.arrayOf(
            PropTypes.shape({
                addendumConfirms: PropTypes.array.isRequired,
                companyName: PropTypes.string,
                status: PropTypes.string.isRequired,
            })
        ).isRequired,
        title: PropTypes.string,
    };

    get styles() {
        return require('./index.scss');
    }

    formatDataForCSVExport = (data) => {
        const { addendums } = this.props;

        const headers = [
            'Response',
            ...addendums.map((addendum) => {
                return addendum.titleDisplay;
            }),
        ];

        const rows = data.map((dataRow) => {
            return [
                dataRow.companyName,
                ...addendums.map((addendum, idx) => {
                    return dataRow[`addendumConfirms[${idx}]`] ? 'X' : '';
                }),
            ];
        });

        return [headers].concat(rows);
    };

    render() {
        const { addendums, proposals, title } = this.props;

        if (addendums.length === 0) {
            return <ZeroState title="No addenda have been issued yet" />;
        }

        if (proposals.length === 0) {
            return <ZeroState title="No responses have been started yet" />;
        }

        const columns = [
            {
                Header: 'Response',
                accessor: 'companyName',
                Cell: (props) => {
                    const isSubmitted = props.original.status === PUBLISHED;
                    return (
                        <div>
                            {props.value || 'Unnamed Company'}
                            {!isSubmitted && (
                                <div className={this.styles.notPublished}>Not Submitted Yet</div>
                            )}
                        </div>
                    );
                },
            },
            ...addendums.map((addendum, idx) => {
                return {
                    Header: addendum.titleDisplay,
                    accessor: `addendumConfirms[${idx}]`,
                    className: 'text-center',
                    maxWidth: 150,
                    Cell: (props) => {
                        if (props.value) {
                            return <i className="fa fa-check" />;
                        }
                        return null;
                    },
                };
            }),
        ];

        return (
            <DataTable
                className="-highlight -striped"
                columns={columns}
                csvExportOptions={{
                    fileName: 'Addenda Receipt Acknowledgement',
                    getFormattedCSVData: this.formatDataForCSVExport,
                    headers: true,
                }}
                data={proposals}
                pageSize={proposals.length}
                showCSVExport
                showPagination={false}
                title={title}
            />
        );
    }
}
