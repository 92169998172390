import { statusTypes } from '@og-pro/shared-config/requestTypes';
import { NoSsr } from '@mui/material';
import React from 'react';
import { reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';

import { form } from './constants';
import { validate } from './validate';
import { useRequestTypeActions } from '../RequestTypeActionsContext';
import { getRequestType } from '../../../../../selectors/govApp';

const formConfig = {
    form,
    enableReinitialize: true,
    validate,
};

// This is necessary for props that the `reduxForm` HOC needs for its form config and validation
const withFormConfig = (ReduxFormWrappedComponent) => {
    return (props) => {
        const requestType = useSelector(getRequestType);
        const { publishRequestType, saveRequestType } = useRequestTypeActions();

        // `onSubmit` will perform a form validation before calling the appropriate action.
        // That is why its specified differently based on the request type status as different
        // statuses will want to have different validation enforcements.
        const onSubmit =
            requestType.status === statusTypes.DRAFT ? publishRequestType : saveRequestType;

        const reduxFormProps = {
            // When a section is unselected or the user closes the section settings, we don't want to destroy since that will clear validation errors
            destroyOnUnmount: false,
            initialValues: requestType,
            onSubmit,
        };

        return (
            <NoSsr>
                <ReduxFormWrappedComponent {...props} {...reduxFormProps} />
            </NoSsr>
        );
    };
};

// Wraps a component with the redux-form decorator configured for the `requestTypeForm` form
export const withRequestTypeForm = () => (WrappedComponent) =>
    withFormConfig(reduxForm(formConfig)(WrappedComponent));
