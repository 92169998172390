import { Field } from 'redux-form';

import React from 'react';

import { FILE_MANAGEMENT_SECTION, Section } from './ScanningControlsPanel';
import { InputText } from '../../InputText';
import { fieldNames } from '../constants';

const { FILE_NAME } = fieldNames;
export const FileManagementSection = () => {
    return (
        <Section id={FILE_MANAGEMENT_SECTION} title="File Management">
            <Field
                component={InputText}
                hasFeedback={false}
                inputGroupSuffix=".pdf"
                label="File Name"
                name={FILE_NAME}
                qaTag="scanning-fileName"
                showValidation={false}
                useOpenGovStyle
            />
        </Section>
    );
};
