import React from 'react';
import { Button } from '@og-pro/ui';
import { useDispatch, useSelector } from 'react-redux';
import { projectTypesDict } from '@og-pro/shared-config/projects';
import { Add as AddIcon } from '@mui/icons-material';

import { getContractJS } from '../../../selectors';
import { showProjectCreateModal } from '../../../../actions/project/create/projectCreate';

const { CONTRACT } = projectTypesDict;

export const CreateContractDocumentButton = () => {
    const dispatch = useDispatch();
    const contract = useSelector(getContractJS);

    const onClick = (e) => {
        e.stopPropagation();

        dispatch(
            showProjectCreateModal({
                createData: {
                    contact_id: contract.contact_id,
                    contractId: contract.id,
                    department_id: contract.department_id,
                    procurement_contact_id: contract.procurement_contact_id,
                },
                isDocBuilder: true,
                steps: [5],
                type: CONTRACT,
            })
        );
    };

    return (
        <Button
            color="primary"
            onClick={onClick}
            qaTag="assembledDocuments-create"
            variant="contained"
        >
            <AddIcon /> Create Document
        </Button>
    );
};
