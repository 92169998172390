import { round, isNumber } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { HtmlContent } from '../../../HtmlContent/HtmlContent';
import { DOCX_TABLE_PORTRAIT_WIDTH } from '../../../../constants';

export class EvaluationPhaseDisplayDocx extends PureComponent {
    static propTypes = {
        isMultiPhase: PropTypes.bool.isRequired,
        scoringCriteria: PropTypes.array.isRequired,
        title: PropTypes.string,
        totalWeight: PropTypes.number.isRequired,
    };

    renderEvaluationCriteriaTable() {
        const { scoringCriteria, totalWeight } = this.props;

        const EvaluationCriteriaItems = scoringCriteria
            .filter((criteria) => !criteria.isHiddenByLogic)
            .map(({ description, id, isPubliclyHidden, scoringMethodText, title, weight }, idx) => (
                <tr key={id}>
                    <td>
                        <p style={{ textAlign: 'center' }}>{idx + 1}.</p>
                    </td>
                    <td>
                        <div>
                            <strong>{title}</strong>
                        </div>
                        <HtmlContent content={description} />
                    </td>
                    <td>
                        <p style={{ textAlign: 'center' }}>
                            {isPubliclyHidden ? 'N/A' : scoringMethodText}
                        </p>
                    </td>
                    <td>
                        {isNumber(weight) && (
                            <p style={{ textAlign: 'center' }}>
                                {isPubliclyHidden ? (
                                    'N/A'
                                ) : (
                                    <>
                                        {weight}
                                        <br />
                                        <i>({round((weight / totalWeight) * 100, 1)}% of Total)</i>
                                    </>
                                )}
                            </p>
                        )}
                    </td>
                </tr>
            ));

        return (
            <table style={{ fontSize: 12 }} width={DOCX_TABLE_PORTRAIT_WIDTH}>
                <thead>
                    <tr>
                        <th style={{ backgroundColor: '#003c81' }} width={60}>
                            <p style={{ textAlign: 'center', color: '#FFFFFF' }}>
                                <strong>No.</strong>
                            </p>
                        </th>
                        <th style={{ backgroundColor: '#003c81' }} width={240}>
                            <p style={{ textAlign: 'center', color: '#FFFFFF' }}>
                                <strong>Evaluation Criteria</strong>
                            </p>
                        </th>
                        <th style={{ backgroundColor: '#003c81' }} width={160}>
                            <p style={{ textAlign: 'center', color: '#FFFFFF' }}>
                                <strong>Scoring Method</strong>
                            </p>
                        </th>
                        <th style={{ backgroundColor: '#003c81' }} width={160}>
                            <p style={{ textAlign: 'center', color: '#FFFFFF' }}>
                                <strong>Weight (Points)</strong>
                            </p>
                        </th>
                    </tr>
                </thead>
                <tbody>{EvaluationCriteriaItems}</tbody>
            </table>
        );
    }

    render() {
        const { isMultiPhase, title } = this.props;

        return (
            <div>
                {isMultiPhase && <h4>{title}</h4>}
                {this.renderEvaluationCriteriaTable()}
            </div>
        );
    }
}
