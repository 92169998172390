import classnames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Button } from '../../../Button';
import { AddendumItem } from '../../../AddendumsList/AddendumItem';

export class AddendumConfirm extends PureComponent {
    static propTypes = {
        addendum: PropTypes.shape({
            addendumConfirms: PropTypes.array.isRequired,
            id: PropTypes.number.isRequired,
            number: PropTypes.number.isRequired,
        }).isRequired,
        change: PropTypes.func.isRequired,
        confirmAddendum: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        fieldName: PropTypes.string.isRequired,
        meta: PropTypes.shape({
            invalid: PropTypes.bool.isRequired,
        }).isRequired,
        showValidation: PropTypes.bool,
    };

    state = {
        updateError: null,
        updating: false,
    };

    get styles() {
        return require('./index.scss');
    }

    confirmAddendum = () => {
        const { addendum, change, confirmAddendum, fieldName } = this.props;

        this.setState({
            updateError: null,
            updating: true,
        });

        return confirmAddendum(addendum.id)
            .then((updatedAddendum) => {
                this.setState({
                    updating: false,
                });
                change(`${fieldName}.addendumConfirms`, updatedAddendum.addendumConfirms);
            })
            .catch((error) => {
                this.setState({
                    updateError: error.message,
                    updating: false,
                });
            });
    };

    renderConfirmation() {
        const {
            addendum,
            disabled,
            meta: { invalid },
            showValidation,
        } = this.props;

        const { updateError, updating } = this.state;

        const [addendumConfirm] = addendum.addendumConfirms;

        if (addendumConfirm) {
            return (
                <div className={this.styles.confirmContainer}>
                    <Button
                        active
                        block
                        bsSize="sm"
                        bsStyle="success"
                        className={`${this.styles.confirmButton} ${this.styles.active}`}
                    >
                        <i
                            className={`fa fa-fw fa-lg fa-check-circle ${this.styles.checkBoxIcon}`}
                        />
                        Confirmed!
                    </Button>
                    <div className={this.styles.info}>
                        {moment(addendumConfirm.created_at).format('lll')}
                        <br />
                        by {addendumConfirm.user.displayName}
                    </div>
                </div>
            );
        }

        const showError = showValidation && invalid;

        return (
            <div className={this.styles.confirmContainer}>
                <Button
                    block
                    bsSize="sm"
                    className={this.styles.confirmButton}
                    disabled={updating || disabled}
                    onClick={this.confirmAddendum}
                >
                    <i
                        className={`fa fa-fw fa-lg fa-check-circle text-success ${this.styles.checkBoxChecked} ${this.styles.checkBoxIcon}`}
                    />
                    <i
                        className={`fa fa-fw fa-lg fa-circle-thin ${this.styles.checkBoxEmpty} ${this.styles.checkBoxIcon}`}
                    />
                    Confirm Receipt
                </Button>
                <div className={classnames(this.styles.info, showError && 'text-danger')}>
                    {showError && (
                        <span>
                            <i className="fa fa-exclamation-triangle" />
                            &nbsp;
                        </span>
                    )}
                    Please confirm you have read
                    <br />
                    Addendum #{addendum.number}
                </div>
                {updateError && <div className="error-block">{updateError}</div>}
            </div>
        );
    }

    render() {
        const { addendum } = this.props;

        return (
            <div className="row">
                <div className="col-md-3">{this.renderConfirmation()}</div>
                <div className="col-md-9">
                    <AddendumItem addendum={addendum} classNameSelector="addendum-number" />
                </div>
            </div>
        );
    }
}
