import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { reduxForm, Field } from 'redux-form';

import { SearchSelect } from '../../SearchSelect/SearchSelect';
import { Tooltip } from '../../Tooltip';
import { UserProfilePicture } from '../../UserProfilePicture/UserProfilePicture';

// @reduxForm
const ConnectedAssignUserForm = ({
    assignedUser,
    assignedUserDisplay,
    assigningUser,
    assignUser,
    assignUserError,
    cannotEdit,
    fieldName,
    showInviteUserHelp,
    unassignedClassName,
    unassignedText,
    userProfileClassName,
    users,
}) => {
    const [showForm, setShowForm] = useState(false);

    const showUserForm = () => {
        if (!cannotEdit) {
            setShowForm(true);
        }
    };

    const selectUser = (user) => {
        // `null` value is used to unassign
        if (user !== undefined) {
            return assignUser({ [fieldName]: user }).then(() => setShowForm(false));
        }
    };

    const styles = require('./index.scss');

    if (showForm) {
        return (
            <>
                <Field
                    autoFocus
                    component={SearchSelect}
                    disabled={assigningUser}
                    isClearable
                    name={fieldName}
                    onChange={selectUser}
                    openMenuOnFocus
                    options={users}
                    overrideFeedback
                    placeholder="Select user or start typing a name"
                    showInviteUserHelp={showInviteUserHelp}
                />
                {assignUserError && <p className="text-danger">{assignUserError}</p>}
            </>
        );
    }

    if (!assignedUser) {
        return (
            <Tooltip
                placement="bottom"
                tooltip={
                    cannotEdit
                        ? 'You have not received permission to assign users'
                        : 'Click to assign user'
                }
            >
                <div
                    className={classnames(
                        styles.unselectedUser,
                        cannotEdit && styles.disabledAssign,
                        unassignedClassName
                    )}
                    onClick={showUserForm}
                >
                    <i className="fa fa-user-circle-o" /> {unassignedText}
                </div>
            </Tooltip>
        );
    }

    return (
        <div
            className={classnames(styles.selectedUser, cannotEdit && styles.disabledAssign)}
            onClick={showUserForm}
        >
            <UserProfilePicture
                className={classnames(styles.userPicture, userProfileClassName)}
                user={assignedUser}
            />
            {assignedUserDisplay || <span>{assignedUser.displayName}</span>}
        </div>
    );
};

ConnectedAssignUserForm.propTypes = {
    assignedUser: PropTypes.object,
    assignedUserDisplay: PropTypes.node,
    assigningUser: PropTypes.bool,
    assignUser: PropTypes.func.isRequired,
    assignUserError: PropTypes.string,
    cannotEdit: PropTypes.bool,
    fieldName: PropTypes.string.isRequired,
    showInviteUserHelp: PropTypes.bool,
    unassignedClassName: PropTypes.string,
    unassignedText: PropTypes.string,
    userProfileClassName: PropTypes.string,
    users: PropTypes.array.isRequired,
};

ConnectedAssignUserForm.defaultProps = {
    unassignedText: 'Unassigned',
};

export const AssignUserForm = reduxForm()(ConnectedAssignUserForm);
