import { includes } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Field } from 'redux-form';

import { criteriaInstructionsDict } from '@og-pro/shared-config/criteria';

import { ReviewContent, ReviewActionSidebar, ReviewError } from './components';
import { fieldNames } from '../constants';
import { Well } from '../../../Well/Well';

const { IS_REVIEWED } = fieldNames;
const { EDIT, TOGGLE_EDIT } = criteriaInstructionsDict;
const EDIT_REQUIRED = [EDIT, TOGGLE_EDIT];

export class TermsReview extends PureComponent {
    static propTypes = {
        arrayName: PropTypes.string.isRequired,
        change: PropTypes.func.isRequired,
        completeHandler: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        formName: PropTypes.string.isRequired,
        input: PropTypes.object.isRequired,
        instructionData: PropTypes.string,
        instructions: PropTypes.string,
        instructionType: PropTypes.string,
        isReviewed: PropTypes.bool.isRequired,
        isUnused: PropTypes.bool,
        meta: PropTypes.object.isRequired,
        projectId: PropTypes.number.isRequired,
        showValidation: PropTypes.bool,
        tagOptions: PropTypes.array,
        templateVariableOptions: PropTypes.array,
    };

    constructor(props) {
        super(props);

        // Used to check if the item was completed while the form was open
        this.state = { isCompleted: false };
    }

    // Silenly checks for item completion.
    componentWillUnmount() {
        const {
            arrayName,
            change,
            instructionType,
            isReviewed,
            meta: { dirty },
        } = this.props;

        // Mark reviewed as long as edits were made
        const editRequired = includes(EDIT_REQUIRED, instructionType);
        if (editRequired && dirty && !isReviewed) {
            return change(`${arrayName}.${IS_REVIEWED}`, true);
        }
    }

    get styles() {
        return require('./index.scss');
    }

    optionSelected = () => {
        this.setState({ isCompleted: true });
    };

    render() {
        const {
            arrayName,
            change,
            completeHandler,
            disabled,
            formName,
            input: descriptionInput,
            instructionData,
            instructions,
            instructionType,
            isReviewed,
            isUnused,
            meta: descriptionMeta,
            projectId,
            showValidation,
            tagOptions,
            templateVariableOptions,
        } = this.props;

        const { isCompleted } = this.state;

        return (
            <div className="row">
                <Field
                    component={ReviewError}
                    name={`${arrayName}.${IS_REVIEWED}`}
                    showValidation={showValidation}
                />
                <Well className={`col-xs-offset-2 col-xs-8 ${this.styles.instructions}`}>
                    <p className="text-center">
                        <strong>Instructions for Completing:</strong>
                    </p>
                    <p>{instructions}</p>
                </Well>
                <div className="clearfix" />
                <div className="col-xs-4">
                    <ReviewActionSidebar
                        arrayName={arrayName}
                        change={change}
                        completeHandler={completeHandler}
                        descriptionPristine={descriptionMeta.pristine}
                        disabled={disabled}
                        instructionType={instructionType}
                        isCompleted={isCompleted}
                        isReviewed={isReviewed}
                        isUnused={isUnused}
                    />
                </div>
                <div className="col-xs-8">
                    <ReviewContent
                        arrayName={arrayName}
                        change={change}
                        descriptionInput={descriptionInput}
                        descriptionMeta={descriptionMeta}
                        disabled={disabled}
                        formName={formName}
                        instructionData={instructionData}
                        instructionType={instructionType}
                        isReviewed={isReviewed}
                        onOptionSelect={this.optionSelected}
                        projectId={projectId}
                        showValidation={showValidation}
                        tagOptions={tagOptions}
                        templateVariableOptions={templateVariableOptions}
                    />
                </div>
            </div>
        );
    }
}
