import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { AgGridReact as AgGrid } from '@ag-grid-community/react';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { SideBarModule } from '@ag-grid-enterprise/side-bar';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';

import { NumericEditor, TextEditor } from './editors';
import { PriceTableHeader, ProposalEvaluationsCompareTableHeader, RequiredHeader } from './headers';
import { CheckboxRenderer } from './renderers';

const MODULES = [
    // Community Modules
    ClientSideRowModelModule,
    CsvExportModule,
    // Enterprise Modules
    ClipboardModule,
    ColumnsToolPanelModule,
    ExcelExportModule,
    FiltersToolPanelModule,
    MenuModule,
    RangeSelectionModule,
    SideBarModule,
    StatusBarModule,
    ServerSideRowModelModule,
    SetFilterModule,
];

const GLOBAL_DEFAULT_COL_DEF = {
    menuTabs: ['filterMenuTab', 'generalMenuTab'],
    resizable: true,
    sortable: true,
};

const DEFAULT_FRAMEWORK_COMPONENTS = {
    checkboxRenderer: CheckboxRenderer,
    numericEditor: NumericEditor,
    priceTableHeader: PriceTableHeader,
    proposalEvaluationsCompareTableHeader: ProposalEvaluationsCompareTableHeader,
    requiredHeader: RequiredHeader,
    textEditor: TextEditor,
};

const DEFAULT_CONTAINER_STYLE = {
    height: 'calc(100vh - 100px)',
    width: '100%',
};

const defaultBorderStyling = {
    borderBottom: {
        color: '#A9A9A9',
        lineStyle: 'Continuous',
        weight: 1,
    },
    borderLeft: {
        color: '#A9A9A9',
        lineStyle: 'Continuous',
        weight: 1,
    },
    borderRight: {
        color: '#A9A9A9',
        lineStyle: 'Continuous',
        weight: 1,
    },
    borderTop: {
        color: '#A9A9A9',
        lineStyle: 'Continuous',
        weight: 1,
    },
};

export class AgGridReactLegacy extends PureComponent {
    static propTypes = {
        accentedSort: PropTypes.bool,
        applyColumnDefOrder: PropTypes.bool,
        // Custom prop used to set grid to autoHeight when minimum threshold of rows not met.
        // `autoHeight` sizes the grid to the number of rows.
        autoHeightMaxRows: PropTypes.number,
        containerStyle: PropTypes.object,
        columns: PropTypes.array.isRequired,
        defaultColDef: PropTypes.object,
        disableAutoHeight: PropTypes.bool,
        doesExternalFilterPass: PropTypes.func,
        domLayout: PropTypes.oneOf(['autoHeight', 'print']),
        enableCellChangeFlash: PropTypes.bool,
        excelStyles: PropTypes.arrayOf(
            PropTypes.shape({
                // https://www.ag-grid.com/javascript-grid-excel/#excel-style-definition
                id: PropTypes.string.isRequired,
            })
        ),
        frameworkComponents: PropTypes.object,
        getContextMenuItems: PropTypes.func,
        getRowHeight: PropTypes.func,
        getRowNodeId: PropTypes.func.isRequired,
        hideSideBar: PropTypes.bool,
        isExternalFilterPresent: PropTypes.func,
        isPrinterView: PropTypes.bool,
        onCellValueChanged: PropTypes.func,
        onColumnMoved: PropTypes.func,
        onDragStopped: PropTypes.func,
        onGridReady: PropTypes.func,
        onPasteEnd: PropTypes.func,
        onPasteStart: PropTypes.func,
        onRowClicked: PropTypes.func,
        onRowDragEnd: PropTypes.func,
        onRowDragEnter: PropTypes.func,
        onSelectionChanged: PropTypes.func,
        overlayNoRowsTemplate: PropTypes.string,
        pagination: PropTypes.bool,
        paginationPageSize: PropTypes.number,
        pinnedBottomRow: PropTypes.arrayOf(PropTypes.object),
        processCellFromClipboard: PropTypes.func,
        rowBuffer: PropTypes.number,
        rowDragManaged: PropTypes.bool,
        rowHeight: PropTypes.number,
        rows: PropTypes.arrayOf(PropTypes.object),
        rowStyle: PropTypes.object,
        showToolPanelColumnExpandAll: PropTypes.bool,
        showToolPanelColumnFilter: PropTypes.bool,
        showToolPanelColumnSelectAll: PropTypes.bool,
        showSideBarFilters: PropTypes.bool,
        suppressContextMenu: PropTypes.bool,
        suppressMovableColumns: PropTypes.bool,
        rowModelType: PropTypes.string,
        cacheBlockSize: PropTypes.number,
        isRowSelectable: PropTypes.func,
        useOpenGovStyle: PropTypes.bool,
    };

    static defaultProps = {
        accentedSort: false, // case insensitive sort (but takes longer to sort)
        applyColumnDefOrder: false,
        containerStyle: DEFAULT_CONTAINER_STYLE,
        defaultColDef: undefined,
        domLayout: undefined,
        enableCellChangeFlash: false,
        excelStyles: [
            {
                id: 'bold',
                color: '#000000',
                font: {
                    bold: true,
                },
            },
            {
                id: 'boolean',
                dataType: 'boolean',
            },
            {
                id: 'currency',
                // https://www.exceltactics.com/definitive-guide-custom-number-formats-excel/
                numberFormat: { format: '$#,##.00;[Red]($#,##.00);$0.00' },
            },
            {
                id: '4FractionCurrency',
                // https://www.exceltactics.com/definitive-guide-custom-number-formats-excel/
                numberFormat: { format: '$#,##.00##;[Red]($#,##.00);$0.00##' },
            },
            {
                id: 'percent',
                // https://www.exceltactics.com/definitive-guide-custom-number-formats-excel/
                numberFormat: { format: '0.####\\%' },
            },
            {
                id: 'date',
                dataType: 'dateTime',
            },
            {
                id: 'header',
                borders: defaultBorderStyling,
                interior: {
                    color: '#003C81',
                    pattern: 'Solid',
                },
                font: {
                    bold: true,
                    color: '#FFFFFF',
                },
            },
            {
                id: 'headerRow',
                borders: defaultBorderStyling,
                interior: {
                    color: '#EEEEEE',
                    pattern: 'Solid',
                },
                font: {
                    bold: true,
                },
            },
            {
                id: 'isEditable',
                borders: defaultBorderStyling,
                interior: {
                    color: '#FDFDD8',
                    pattern: 'Solid',
                },
            },
            {
                id: 'primaryAward',
                borders: defaultBorderStyling,
                interior: {
                    color: '#228b22',
                    pattern: 'Solid',
                },
                font: {
                    color: '#FFFFFF',
                },
            },
            {
                id: 'backupAward',
                borders: defaultBorderStyling,
                interior: {
                    color: '#808080',
                    pattern: 'Solid',
                },
                font: {
                    color: '#FFFFFF',
                },
            },
            {
                id: 'selection',
                dataType: 'boolean',
            },
            {
                id: 'summaryRow',
                borders: defaultBorderStyling,
                interior: {
                    color: '#C0C0C0',
                    pattern: 'Solid',
                },
            },
            {
                id: 'wrapText',
                alignment: {
                    wrapText: true,
                },
            },
        ],
        frameworkComponents: undefined,
        getContextMenuItems: undefined,
        hideSideBar: false,
        onCellValueChanged: undefined,
        onGridReady: undefined,
        onPasteEnd: undefined,
        onPasteStart: undefined,
        onRowClicked: undefined,
        onSelectionChanged: undefined,
        pagination: false,
        pinnedBottomRow: undefined,
        rowStyle: undefined,
        showSideBarFilters: false,
        suppressMovableColumns: true,
        useOpenGovStyle: false,
    };

    get styles() {
        return require('./index.scss');
    }

    constructor(props) {
        super(props);

        const {
            defaultColDef,
            isPrinterView,
            showToolPanelColumnExpandAll,
            showToolPanelColumnFilter,
            showToolPanelColumnSelectAll,
            showSideBarFilters,
        } = props;

        const toolPanels = [
            {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
                toolPanelParams: {
                    suppressRowGroups: true,
                    suppressValues: true,
                    suppressPivots: true,
                    suppressPivotMode: true,
                    suppressSideButtons: true,
                    suppressColumnFilter: showToolPanelColumnFilter !== true,
                    suppressColumnSelectAll: showToolPanelColumnSelectAll !== true,
                    suppressColumnExpandAll: showToolPanelColumnExpandAll !== true,
                },
            },
        ];

        if (showSideBarFilters) {
            toolPanels.unshift({
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
            });
        }

        this.state = {
            defaultColDef: {
                ...GLOBAL_DEFAULT_COL_DEF,
                ...defaultColDef,
            },
            frameworkComponents: {
                ...DEFAULT_FRAMEWORK_COMPONENTS,
                ...props.frameworkComponents,
            },
            sideBar: {
                toolPanels: isPrinterView ? [] : toolPanels,
            },
        };
    }

    /**
     * BUG: When navigating to this component using the back button in the browser, setState gets
     * called twice. To prevent this we manually keep track of whether the component has mounted.
     */
    componentDidMount() {
        this.isComponentMounted = true;
    }

    componentWillUnmount() {
        this.isComponentMounted = false;
    }

    handleCellValueChanged = (event) => {
        if (event.oldValue === event.newValue) {
            return;
        }

        if (this.props.onCellValueChanged) {
            this.props.onCellValueChanged(event);
        }
    };

    handleClearGridClick = () => {
        this.gridApi.deselectAll();
    };

    handleColumnResize = (event) => {
        if (event.finished) {
            this.gridApi.resetRowHeights();
        }
    };

    // This allows the parent to hook into the internal API of the grid. Ideally most things the
    // parent would want to do can be communicated via props, however, that is not always possible.
    handleGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        if (this.props.onGridReady && this.isComponentMounted) {
            this.props.onGridReady(params);
        }
    };

    render() {
        const {
            accentedSort,
            applyColumnDefOrder,
            autoHeightMaxRows,
            columns,
            containerStyle,
            disableAutoHeight,
            doesExternalFilterPass,
            domLayout,
            enableCellChangeFlash,
            excelStyles,
            getContextMenuItems,
            getRowHeight,
            getRowNodeId,
            hideSideBar,
            isExternalFilterPresent,
            isPrinterView,
            onColumnMoved,
            onDragStopped,
            onPasteEnd,
            onPasteStart,
            onRowClicked,
            onRowDragEnd,
            onRowDragEnter,
            onSelectionChanged,
            overlayNoRowsTemplate,
            pagination,
            paginationPageSize,
            pinnedBottomRow,
            processCellFromClipboard,
            rowBuffer,
            rowDragManaged,
            rowHeight,
            rows,
            rowStyle,
            suppressContextMenu,
            suppressMovableColumns,
            rowModelType,
            cacheBlockSize,
            isRowSelectable,
            useOpenGovStyle,
        } = this.props;

        const { defaultColDef, frameworkComponents, sideBar } = this.state;

        let computedDomLayout = domLayout;
        if (isPrinterView) {
            computedDomLayout = 'print';
        } else if (
            autoHeightMaxRows &&
            (rows || []).length <= autoHeightMaxRows &&
            !computedDomLayout &&
            !disableAutoHeight
        ) {
            computedDomLayout = 'autoHeight';
        }

        // Height should not be set when `autoHeight` is used
        const computedStyle =
            computedDomLayout === 'autoHeight' || isPrinterView ? undefined : containerStyle;

        return (
            <div
                className={classnames(
                    'ag-theme-alpine',
                    useOpenGovStyle && this.styles.openGovStyle
                )}
                style={computedStyle}
            >
                <AgGrid
                    accentedSort={accentedSort}
                    animateRows
                    applyColumnDefOrder={applyColumnDefOrder}
                    cacheBlockSize={cacheBlockSize}
                    columnDefs={columns}
                    defaultColDef={defaultColDef}
                    doesExternalFilterPass={doesExternalFilterPass}
                    domLayout={computedDomLayout}
                    enableCellChangeFlash={enableCellChangeFlash}
                    enableRangeSelection
                    enterMovesDownAfterEdit
                    excelStyles={excelStyles}
                    frameworkComponents={frameworkComponents}
                    getContextMenuItems={getContextMenuItems}
                    getRowHeight={getRowHeight}
                    getRowNodeId={getRowNodeId}
                    immutableData
                    isExternalFilterPresent={isExternalFilterPresent}
                    isRowSelectable={isRowSelectable}
                    modules={MODULES}
                    onCellValueChanged={this.handleCellValueChanged}
                    onColumnMoved={onColumnMoved}
                    onColumnResized={this.handleColumnResize}
                    onDragStopped={onDragStopped}
                    onGridReady={this.handleGridReady}
                    onPasteEnd={onPasteEnd}
                    onPasteStart={onPasteStart}
                    onRowClicked={onRowClicked}
                    onRowDragEnd={onRowDragEnd}
                    onRowDragEnter={onRowDragEnter}
                    onSelectionChanged={onSelectionChanged}
                    overlayNoRowsTemplate={overlayNoRowsTemplate}
                    pagination={pagination}
                    paginationPageSize={paginationPageSize}
                    pinnedBottomRowData={pinnedBottomRow}
                    processCellFromClipboard={processCellFromClipboard}
                    rowBuffer={rowBuffer}
                    rowData={rows}
                    rowDeselection
                    rowDragManaged={rowDragManaged}
                    rowHeight={rowHeight}
                    rowModelType={rowModelType}
                    rowSelection="multiple"
                    rowStyle={{ backgroundColor: 'white', ...rowStyle }}
                    serverSideSortingAlwaysResets
                    sideBar={!hideSideBar && sideBar}
                    stopEditingWhenGridLosesFocus
                    suppressContextMenu={suppressContextMenu}
                    suppressDragLeaveHidesColumns
                    suppressMovableColumns={suppressMovableColumns}
                    suppressRowClickSelection
                    toolPanel="columns"
                />
            </div>
        );
    }
}
