import { get, partition } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { HtmlContent } from '../HtmlContent/HtmlContent';
import { DOCX_TABLE_LANDSCAPE_WIDTH, DOCX_TABLE_PORTRAIT_WIDTH } from '../../constants';

export class ReportDocxTable extends PureComponent {
    static propTypes = {
        columns: PropTypes.arrayOf(
            PropTypes.shape({
                accessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
                docxHtml: PropTypes.func,
                DocxCell: PropTypes.func,
                formatValue: PropTypes.func,
                Header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
                id: PropTypes.string,
                tdStyle: PropTypes.object,
            })
        ).isRequired,
        data: PropTypes.arrayOf(PropTypes.object).isRequired,
        tableWidth: PropTypes.number,
        useLandscape: PropTypes.bool,
        columnsToDisplayOutside: PropTypes.arrayOf(PropTypes.string),
    };

    static defaultProps = {
        columnsToDisplayOutside: [],
    };

    get docxTableWidth() {
        const { tableWidth, useLandscape } = this.props;

        if (tableWidth) {
            return tableWidth;
        }

        if (useLandscape) {
            return DOCX_TABLE_LANDSCAPE_WIDTH;
        }

        return DOCX_TABLE_PORTRAIT_WIDTH;
    }

    getTableColData(columnsData) {
        const { useLandscape } = this.props;
        const maxColCount = useLandscape ? 10 : 5;
        let currTableIndex = 0;
        let colCount = 1;

        return columnsData.reduce((tables, colData, i) => {
            if (i !== 0) {
                if (colCount >= maxColCount) {
                    currTableIndex += 1;
                    colCount = 1;
                }

                if (!tables[currTableIndex]) {
                    tables.push([{ ...columnsData[0] }]);
                }

                tables[currTableIndex].push({ ...colData });

                colCount += 1;
            }

            return tables;
        }, []);
    }

    renderDocxTableHeader(columns, columnNum) {
        const thStyle = { backgroundColor: '#003c81' };
        const pStyle = { textAlign: 'center', color: '#FFFFFF', fontWeight: 'bold' };

        const columnHeaders = columns.map((columnData) => {
            const { accessor, id, Header } = columnData;

            const width = this.docxTableWidth / columnNum;

            return (
                <th key={id || accessor} scope="col" style={thStyle} width={width}>
                    <div className="no-trailing-space" style={pStyle}>
                        {Header}
                    </div>
                </th>
            );
        });

        return <tr className="repeat-header-row">{columnHeaders}</tr>;
    }

    renderDocxTableRow(columns, datum) {
        return (
            <tr>
                {columns.map((column, j) => {
                    const {
                        accessor,
                        DocxCell,
                        docxHtml,
                        formatValue,
                        id: columnId,
                        tdStyle,
                    } = column;

                    const useAccessorFn = typeof accessor === 'function';
                    let value = useAccessorFn ? accessor(datum) : get(datum, accessor);

                    if (DocxCell) {
                        return <DocxCell datum={datum} key={j} value={value} />;
                    }

                    const tableDataStyle = {
                        textAlign: 'center',
                        ...tdStyle,
                    };

                    if (formatValue) {
                        value = formatValue({
                            datum,
                            value,
                        });
                    }

                    if (docxHtml) {
                        value = docxHtml({
                            datum,
                            value,
                        });
                    }

                    return (
                        <td
                            key={`${useAccessorFn ? columnId : accessor} ${j}`}
                            style={tableDataStyle}
                        >
                            {value}
                        </td>
                    );
                })}
            </tr>
        );
    }

    renderDocxTableRows(columns, data) {
        return data.map((datum, i) => {
            return (
                <React.Fragment key={i}>{this.renderDocxTableRow(columns, datum)}</React.Fragment>
            );
        });
    }

    renderDocxTable(columns, data) {
        const { useLandscape } = this.props;
        const maxColCount = useLandscape ? 10 : 5;

        const tableColData = this.getTableColData(columns || this.props.columns);

        return tableColData.map((colData, i) => {
            const columnNum = colData.length;

            return (
                <table
                    key={`table ${i}`}
                    style={{ fontSize: columnNum > maxColCount ? '8pt' : '10pt', marginBottom: 12 }}
                    width={this.docxTableWidth}
                >
                    <thead>{this.renderDocxTableHeader(colData, columnNum)}</thead>
                    <tbody>{this.renderDocxTableRows(colData, data || this.props.data)}</tbody>
                </table>
            );
        });
    }

    /**
     * It renders a normal table but selected columns are removed from the columns and displayed just below the table
     * @returns any
     */
    renderDocxTableWithColumnsOutside() {
        const { columns, columnsToDisplayOutside, data } = this.props;

        const [outsideColumns, insideColumns] = partition(columns, (column) =>
            columnsToDisplayOutside.includes(column.accessor)
        );

        return (
            <>
                {data.map((dataItem) => {
                    return (
                        <React.Fragment key={`dataItem-${dataItem.id}`}>
                            {this.renderDocxTable(insideColumns, [dataItem])}
                            {outsideColumns.map((outsideCol) => (
                                <React.Fragment key={outsideCol.accessor}>
                                    <div>
                                        <span style={{ fontSize: '10pt' }}>
                                            {outsideCol.Header}:
                                        </span>
                                        <br />
                                        <span style={{ fontSize: '10pt' }}>
                                            <HtmlContent content={dataItem.description} />
                                        </span>
                                    </div>
                                    <br />
                                    <br />
                                </React.Fragment>
                            ))}
                        </React.Fragment>
                    );
                })}
            </>
        );
    }

    render() {
        const { columnsToDisplayOutside } = this.props;

        const hasOutsideColumns = columnsToDisplayOutside.length > 0;

        if (hasOutsideColumns) {
            return this.renderDocxTableWithColumnsOutside();
        }

        return this.renderDocxTable();
    }
}
