import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { projectStatusesDict } from '@og-pro/shared-config/projects';
import { Box, Button, CircularProgress } from '@og-pro/ui';
import { useQuery } from '@tanstack/react-query';
import { statuses } from '@og-pro/shared-config/electronicSignatures';
import Icon from '@mdi/react';
import { mdiDrawPen } from '@mdi/js';

import { showSnackbar } from '../../../../actions/notification';
import {
    cancelElectronicSignature,
    createElectronicSignature,
} from '../../../../actions/project/electronicSignatures';
import { showConfirmationSimpleModal } from '../../../../actions/confirmation';

export const DocusignActions = ({ contractDocument, reloadData }) => {
    const dispatch = useDispatch();
    const {
        error: createError,
        isFetching: isCreating,
        refetch,
    } = useQuery({
        enabled: false,
        queryKey: ['CreateElectronicSignature', contractDocument.id],
        queryFn: () => dispatch(createElectronicSignature({ projectId: contractDocument.id })),
        retry: false,
        refetchOnWindowFocus: false,
    });
    const {
        error: cancelError,
        isFetching: isCancelling,
        refetch: cancelESignature,
    } = useQuery({
        enabled: false,
        // eslint-disable-next-line
        queryKey: [
            'CancelElectronicSignature',
            contractDocument.id,
            contractDocument.electronicSignature?.id,
        ],
        queryFn: () =>
            dispatch(
                cancelElectronicSignature({
                    projectId: contractDocument.id,
                    electronicSignatureId: contractDocument.electronicSignature.id,
                })
            ),
        retry: false,
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (createError) {
            dispatch(
                showSnackbar(createError?.message || 'Failed to create electronic signature', {
                    isError: true,
                })
            );
        }

        if (cancelError) {
            dispatch(
                showSnackbar(cancelError?.message || 'Failed to cancel electronic signature', {
                    isError: true,
                })
            );
        }
    }, [dispatch, createError, cancelError]);

    const onSendForSignaturesClick = useCallback(async () => {
        await refetch();
        dispatch(
            showSnackbar('Your packet is being routed for electronic signature', {
                isSuccess: true,
            })
        );

        return reloadData();
    }, []);

    const onCancelSignatureClick = useCallback(() => {
        dispatch(
            showConfirmationSimpleModal(
                async () => {
                    await cancelESignature();

                    dispatch(showSnackbar('The signatures have been withdrawn'));
                    return reloadData();
                },
                {
                    title: 'Cancel Signatures',
                    text: 'If you cancel the signatures, all existing signatures will be invalidated. The contract packet will need to be signed again from the beginning.\n\nAre you sure you want to cancel signatures?',
                    useOpenGovStyle: true,
                }
            )
        );
    }, []);

    // if the esignature is NOT enabled, meaning it's wet signature, we won't show the docusign buttons
    if (
        contractDocument.status !== projectStatusesDict.FINAL ||
        !contractDocument.esignatureEnabled
    ) {
        return null;
    }

    if (contractDocument.electronicSignature) {
        // if the signatures are complete, there's no cancelling and nothing else to do
        if (contractDocument.electronicSignature.status === statuses.COMPLETED) {
            return null;
        }

        // if its pending or processing, we show the cancel button
        // but pending means that the envelop still hasnt been created, so we cant cancel yet
        // that's why the button is disabled
        if (
            [statuses.PENDING, statuses.PROCESSING].includes(
                contractDocument.electronicSignature.status
            )
        ) {
            return (
                <Button
                    color="error"
                    disabled={isCancelling}
                    onClick={(e) => {
                        e.stopPropagation();
                        onCancelSignatureClick();
                    }}
                    qaTag="assembledDocuments-withdrawSignatures"
                    variant="text"
                >
                    Withdraw Signatures{' '}
                    {isCancelling && <CircularProgress color="error" size="small" sx={{ ml: 2 }} />}
                </Button>
            );
        }

        // if we are here it means we have a cancelled or errored status, so its fine to show the button to
        // create esignature again
    }

    return (
        <Box>
            <Button
                color="primary"
                disabled={isCreating}
                onClick={(e) => {
                    e.stopPropagation();
                    onSendForSignaturesClick();
                }}
                qaTag="assembledDocuments-sendForSignature"
                variant="text"
            >
                <Box alignItems="center" display="flex">
                    <Box mr={0.5}>
                        <Icon path={mdiDrawPen} size={0.8} />
                    </Box>
                    <Box>
                        Send for Signatures{' '}
                        {isCreating && <CircularProgress size="small" sx={{ ml: 2 }} />}
                    </Box>
                </Box>
            </Button>
        </Box>
    );
};

DocusignActions.propTypes = {
    contractDocument: PropTypes.shape({
        electronicSignature: PropTypes.shape({
            currentSigners: PropTypes.number,
            id: PropTypes.number.isRequired,
            provider: PropTypes.string,
            providerId: PropTypes.string,
            status: PropTypes.string.isRequired,
            totalSigners: PropTypes.number,
        }),
        id: PropTypes.number.isRequired,
        esignatureEnabled: PropTypes.bool.isRequired,
        status: PropTypes.string.isRequired,
    }).isRequired,
    reloadData: PropTypes.func.isRequired,
};
