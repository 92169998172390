import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { sectionTypeNames } from '@og-pro/shared-config/sections';

import { Tooltip } from '../Tooltip';
import { DocxHeading } from '../DocxHeading';
import { HtmlContent } from '../HtmlContent/HtmlContent';
import { EditIcon } from '../EditIcon';
import { form } from '../GovApp/ProjectDetail/CriteriaItemForm/constants';
import { CriteriaItemForm } from '../GovApp/ProjectDetail/CriteriaItemForm';
import { CommentIcon } from '../../containers/GovApp/CommentIcon/CommentIcon';

export class CriteriaItem extends PureComponent {
    static propTypes = {
        commentType: PropTypes.string,
        criteria: PropTypes.shape({
            deleteError: PropTypes.string,
            deleting: PropTypes.bool,
            description: PropTypes.string,
            id: PropTypes.number.isRequired,
            manualNumber: PropTypes.string,
            needsReview: PropTypes.bool.isRequired,
            rawDescription: PropTypes.string,
            showForm: PropTypes.bool,
            title: PropTypes.string,
            updateError: PropTypes.string,
            updating: PropTypes.bool,
        }).isRequired,
        criteriaDelete: PropTypes.func,
        criteriaToggleForm: PropTypes.func,
        criteriaUpdate: PropTypes.func,
        isDocx: PropTypes.bool,
        isEditable: PropTypes.bool,
        isReadOnly: PropTypes.bool,
        isTextArea: PropTypes.bool,
        itemNumber: PropTypes.string.isRequired,
        projectSection: PropTypes.shape({
            id: PropTypes.number.isRequired,
            isWritingForm: PropTypes.bool.isRequired,
            section_type: PropTypes.string,
            disableNumbering: PropTypes.bool,
        }).isRequired,
        showComment: PropTypes.bool,
        showConfirmationSimpleModal: PropTypes.func,
        subitemNumber: PropTypes.number,
        tagOptions: PropTypes.array,
        templateVariableOptions: PropTypes.array,
        useManualNumbering: PropTypes.bool,
        useSectionDividers: PropTypes.bool,
    };

    static defaultProps = {
        commentType: undefined,
        criteriaDelete: undefined,
        criteriaToggleForm: undefined,
        criteriaUpdate: undefined,
        isDocx: false,
        isEditable: false,
        isReadOnly: false,
        showComment: false,
        subitemNumber: undefined,
        tagOptions: undefined,
        templateVariableOptions: undefined,
    };

    componentWillUnmount() {
        // Hide form if it is open before navigating away
        if (this.props.criteria.showForm) {
            this.toggleFormHandler(false);
        }
    }

    get styles() {
        return require('./CriteriaItem.scss');
    }

    get isProtected() {
        const {
            criteria: { needsReview },
            projectSection: { isWritingForm },
        } = this.props;

        return !isWritingForm && !needsReview;
    }

    handleDelete = () => {
        const { criteria, criteriaDelete } = this.props;

        criteriaDelete(criteria);
    };

    handleToggleFormClose = () => {
        this.toggleFormHandler(false);
    };

    handleToggleFormOpen = () => {
        const { showConfirmationSimpleModal } = this.props;

        const openForm = () => this.toggleFormHandler(true);

        if (!this.isProtected) {
            return openForm();
        }

        showConfirmationSimpleModal(openForm, {
            bsStyle: 'warning',
            btnText: 'Continue Editing',
            icon: 'pencil',
            text:
                'This is a standard item provided by your organization. It is rare that this ' +
                'language changes, please only update if you are sure you need to edit the item.',
        });
    };

    toggleFormHandler = (showForm) => {
        const { criteria, criteriaToggleForm } = this.props;

        return criteriaToggleForm(showForm, criteria);
    };

    renderLockIcon() {
        const { isReadOnly, showComment } = this.props;

        // Show lock icon if a protected item and comments are on or it's a revision diff
        if (!this.isProtected || (!showComment && !isReadOnly)) {
            return null;
        }

        const LockIcon = (
            <div className="criteria-item-lock-icon-container">
                <i className="fa fa-lock lock-icon" />
            </div>
        );

        // Do not display tooltip when a revision diff
        if (isReadOnly) {
            return LockIcon;
        }

        return (
            <Tooltip placement="top" tooltip="This item is not typically edited">
                {LockIcon}
            </Tooltip>
        );
    }

    renderDocx() {
        const {
            criteria: { title, manualNumber, description },
            isTextArea,
            itemNumber,
            subitemNumber,
            projectSection,
            useManualNumbering,
            useSectionDividers,
        } = this.props;

        const itemContent = <HtmlContent className="article" content={description} />;

        if (isTextArea) {
            return itemContent;
        }

        const getNumbering = (forceNumberSubitems = false) => {
            if (useManualNumbering && manualNumber) {
                return manualNumber;
            }

            if (useSectionDividers || forceNumberSubitems) {
                if (projectSection.section_type === sectionTypeNames.TERMS && itemNumber) {
                    return itemNumber;
                }

                const subItemText = subitemNumber ? `${subitemNumber}.` : '';

                return `${itemNumber}${subItemText}`;
            }

            return '';
        };

        // `<t />` is a custom tag that is replaced with a tabstop in the docx template
        const numberAndTitle = (
            <>
                {getNumbering()}
                {(useManualNumbering || useSectionDividers) && <t />}
                {title}
            </>
        );

        return (
            <div>
                <DocxHeading
                    domElement={subitemNumber ? 'h5' : 'h4'}
                    legacy={subitemNumber ? <h5>{numberAndTitle}</h5> : <h4>{numberAndTitle}</h4>}
                    numbering={getNumbering(true)}
                    title={title}
                    useSectionDividers={useSectionDividers}
                />
                {itemContent}
            </div>
        );
    }

    renderForm() {
        const {
            criteria,
            criteria: { deleteError, deleting, id, rawDescription, title, updateError, updating },
            criteriaUpdate,
            isTextArea,
            tagOptions,
            templateVariableOptions,
        } = this.props;

        return (
            <CriteriaItemForm
                autoFocus
                className={classnames(this.styles.editForm, !isTextArea && this.styles.termsForm)}
                closeForm={this.handleToggleFormClose}
                form={`${form}${id}`}
                handleDelete={this.handleDelete}
                initialValues={{ title, rawDescription }}
                isTextArea={isTextArea}
                onSubmit={criteriaUpdate(criteria)}
                submitServerError={updateError || deleteError}
                tagOptions={tagOptions}
                templateVariableOptions={templateVariableOptions}
                updating={updating || deleting}
            />
        );
    }

    render() {
        const {
            commentType,
            criteria: { description, id, manualNumber, showForm, title },
            isDocx,
            isEditable,
            isTextArea,
            itemNumber,
            projectSection,
            showComment,
            subitemNumber,
            useManualNumbering,
        } = this.props;

        if (isDocx) {
            return this.renderDocx();
        }

        const criteriaClass = subitemNumber ? 'criteria-sub-item' : 'criteria-item';

        if (showForm) {
            return <div className={criteriaClass}>{this.renderForm()}</div>;
        }

        let commentIcon;
        if (showComment) {
            commentIcon = (
                <CommentIcon
                    commentType={commentType}
                    criterionId={id}
                    projectSection={projectSection}
                    pullRight={!isTextArea}
                    show={showComment}
                />
            );
        }

        // Defines the element ID that can be used to locate the item
        const field = commentType ? `criterion_id.${id}.${commentType}` : `criterion_id.${id}`;
        const subItemText = subitemNumber ? `${subitemNumber}.` : '';
        let number = useManualNumbering ? manualNumber : `${itemNumber} ${subItemText}`;
        number = projectSection.disableNumbering ? '' : number;

        return (
            <>
                {isTextArea && (
                    <div className="criteria-text-area-comment">
                        {commentIcon}
                        {this.renderLockIcon()}
                    </div>
                )}
                <div className={criteriaClass} id={`project-field-${field}`}>
                    {!isTextArea && (
                        <h4 className="criteria-item-header">
                            {commentIcon}
                            {this.renderLockIcon()}
                            {number} {title}
                        </h4>
                    )}
                    <div className="criteria-item-description">
                        <HtmlContent className="article" content={description} />
                        <EditIcon onClick={this.handleToggleFormOpen} show={isEditable} />
                    </div>
                </div>
            </>
        );
    }
}
