import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import { fieldNames, form } from './constants';
import { Button } from '../../Button';
import { SearchSelect } from '../../SearchSelect/SearchSelect';
import { SearchSelectUserOption } from '../../SearchSelectUserOption';
import { UserProfilePicture } from '../../UserProfilePicture/UserProfilePicture';
import {
    getActiveUsersSelectOptions,
    getDepartmentsSelectOptions,
} from '../../../containers/selectors';

const { CONTACT_ID, DEPARTMENT_ID, PROCUREMENT_CONTACT_ID, USER_ID } = fieldNames;

const mapStateToProps = (state) => {
    return {
        departmentSelectOptions: getDepartmentsSelectOptions(state),
        formValues: getFormValues(form)(state) || {},
        userSelectOptions: getActiveUsersSelectOptions(state),
    };
};

const formConfig = {
    form,
};

// @connect
// @reduxForm
class ConnectedOwnershipForm extends Component {
    static propTypes = {
        departmentSelectOptions: PropTypes.array.isRequired,
        formValues: PropTypes.object.isRequired,
        showDepartment: PropTypes.bool,
        showProcurementContact: PropTypes.bool,
        showProjectContact: PropTypes.bool,
        user: PropTypes.object.isRequired,
        userSelectOptions: PropTypes.array.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            [CONTACT_ID]: false,
            [DEPARTMENT_ID]: false,
            [PROCUREMENT_CONTACT_ID]: false,
            [USER_ID]: false,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    renderFormDisplay = ({ isUserSelect, name }) => {
        const { departmentSelectOptions, formValues, userSelectOptions, user } = this.props;

        if (isUserSelect) {
            const userId = get(formValues, name);
            const selectedUser = userSelectOptions.find(
                (userOption) => userOption.value === userId
            );

            if (!selectedUser) {
                return 'None';
            }

            return (
                <span>
                    <UserProfilePicture horizontal user={selectedUser.user} />
                    &nbsp;&nbsp;
                    {selectedUser.label}
                </span>
            );
        }

        const departmentId = get(formValues, name) || user.department_id;
        return departmentSelectOptions.find((option) => option.value === departmentId).label;
    };

    renderFormField = ({ help, isUserSelect, name, optional = false, ariaLabel }) => {
        const { departmentSelectOptions, userSelectOptions } = this.props;

        if (!this.state[name]) {
            return (
                <>
                    {this.renderFormDisplay({ isUserSelect, name })}
                    <Button
                        bsSize="sm"
                        bsStyle="link"
                        className={this.styles.editButton}
                        onClick={() => this.setState({ [name]: true })}
                    >
                        <i className="fa fa-pencil" /> Edit
                    </Button>
                </>
            );
        }

        return (
            <Field
                aria-label={ariaLabel}
                backspaceRemovesValue={optional}
                component={SearchSelect}
                components={isUserSelect ? { Option: SearchSelectUserOption } : undefined}
                help={help}
                isClearable={optional}
                name={name}
                onChange={() => this.setState({ [name]: false })}
                options={isUserSelect ? userSelectOptions : departmentSelectOptions}
                placeholder={`Select ${
                    isUserSelect ? 'user' : 'department'
                } or start typing a name`}
            />
        );
    };

    render() {
        const { showDepartment, showProcurementContact, showProjectContact } = this.props;

        return (
            <>
                <dt>Creator:</dt>
                <dd>
                    {this.renderFormField({
                        isUserSelect: true,
                        name: USER_ID,
                        ariaLabel: 'Creator',
                    })}
                </dd>
                {showProjectContact && (
                    <>
                        <dt>Project Contact:</dt>
                        <dd>
                            {this.renderFormField({
                                isUserSelect: true,
                                name: CONTACT_ID,
                                help: 'Contact can be changed later',
                                ariaLabel: 'Project Contact',
                            })}
                        </dd>
                    </>
                )}
                {showProcurementContact && (
                    <>
                        <dt>Procurement Contact:</dt>
                        <dd>
                            {this.renderFormField({
                                isUserSelect: true,
                                name: PROCUREMENT_CONTACT_ID,
                                help: 'Contact can be changed later',
                                optional: true,
                                ariaLabel: 'Procurement Contact',
                            })}
                        </dd>
                    </>
                )}
                {showDepartment && (
                    <>
                        <dt>Department:</dt>
                        <dd>
                            {this.renderFormField({
                                name: DEPARTMENT_ID,
                                help: 'Department can be changed later',
                                ariaLabel: 'Department',
                            })}
                        </dd>
                    </>
                )}
            </>
        );
    }
}

export const OwnershipForm = compose(
    connect(mapStateToProps),
    reduxForm(formConfig)
)(ConnectedOwnershipForm);
