import classnames from 'classnames';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '../Button';
import { hideSaveModal } from '../../actions/app';

export const SaveModal = () => {
    const dispatch = useDispatch();
    const hideModal = () => dispatch(hideSaveModal());

    const showModal = useSelector((state) => state.app.get('showSaveModal'));
    const showSaveError = useSelector((state) => state.app.get('showSaveError'));
    const showSaveSuccess = useSelector((state) => state.app.get('showSaveSuccess'));

    const styles = require('./index.scss');

    const renderBody = () => {
        // To prevent flicker when modal closes
        if (!showModal) {
            return null;
        }

        if (showSaveSuccess) {
            return (
                <h4 className={`text-success ${styles.text}`}>
                    <i className="fa fa-check-circle fa-lg" /> Saved Changes!
                </h4>
            );
        }

        if (showSaveError) {
            return (
                <div className="text-center">
                    <h4 className={`text-danger ${styles.text}`}>
                        <i className="fa fa-check-circle fa-warning" /> Error Saving Changes
                    </h4>
                    <p>{showSaveError}</p>
                    <p>Please try saving again.</p>
                    <div>
                        <Button onClick={hideModal}>Close</Button>
                    </div>
                </div>
            );
        }

        return (
            <h4 className={styles.text}>
                <i className="fa fa-spinner fa-spin" /> Saving Changes...
            </h4>
        );
    };

    return (
        <Modal bsSize="sm" onHide={hideModal} show={showModal}>
            <Modal.Body className={classnames(styles.modalBody, showSaveSuccess && 'bg-success')}>
                {renderBody()}
            </Modal.Body>
        </Modal>
    );
};
