export const LOAD = 'gov/revisions/LOAD';
export const LOAD_SUCCESS = 'gov/revisions/LOAD_SUCCESS';
export const LOAD_FAIL = 'gov/revisions/LOAD_FAIL';
export const LOAD_DIFF = 'gov/revision/LOAD_DIFF';
export const LOAD_DIFF_SUCCESS = 'gov/revisions/LOAD_DIFF_SUCCESS';
export const LOAD_DIFF_FAIL = 'gov/revisions/LOAD_DIFF_FAIL';
export const SET_ACTIVE = 'gov/revision/SET_ACTIVE';
export const RESET_REFRESH_DIFF = 'gov/revisions/RESET_REFRESH_DIFF';
export const RESET = 'gov/revisions/RESET';
export const RESET_REVISION_DIFF = 'gov/revisions/RESET_REVISION_DIFF';

export const LOAD_MORE = 'gov/revisions/LOAD_MORE';
export const LOAD_MORE_SUCCESS = 'gov/revisions/LOAD_MORE_SUCCESS';
export const LOAD_MORE_FAIL = 'gov/revisions/LOAD_MORE_FAIL';

export const CREATE_VERSION = 'gov/revisions/CREATE_VERSION';
export const CREATE_VERSION_SUCCESS = 'gov/revisions/CREATE_VERSION_SUCCESS';
export const CREATE_VERSION_FAIL = 'gov/revisions/CREATE_VERSION_FAIL';

export const SEARCH = 'gov/revisions/SEARCH';
export const SEARCH_SUCCESS = 'gov/revisions/SEARCH_SUCCESS';
export const SEARCH_FAIL = 'gov/revisions/SEARCH_FAIL';

export const SET_CURRENT_VERSION = 'gov/revisions/SET_CURRENT_VERSION';
