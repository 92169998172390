import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Button, CircularProgress, Typography } from '@og-pro/ui';
import { Add as AddIcon } from '@mui/icons-material';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';

import { VendorManagementHeader } from '../VendorManagementHeader';
import { VendorListsTable } from './components';
import { VendorListDialog } from '../VendorListDialog';
import { getPortalUrl, getVendorLists, getVendorListsLoading } from '../../selectors';
import { loadVendorLists } from '../../../../actions/vendorList';
import { getUserJS } from '../../../selectors';

export const VendorListsManagerV2 = () => {
    const dispatch = useDispatch();
    const { governmentId: govIdParam } = useParams();
    const governmentId = Number.parseInt(govIdParam, 10);

    useEffect(() => {
        dispatch(loadVendorLists(governmentId));
    }, []);

    const { government } = useSelector(getUserJS);
    const loadingVendorLists = useSelector(getVendorListsLoading);
    const portalUrl = useSelector(getPortalUrl);
    const vendorLists = useSelector(getVendorLists);

    const [showVendorListDialog, setShowVendorListDialog] = useState(false);

    return (
        <>
            <VendorManagementHeader governmentId={governmentId} portalUrl={portalUrl} />
            <Box
                pt={3}
                px={7}
                sx={{
                    backgroundColor: capitalDesignTokens.semanticColors.background.primary,
                    height: '100%',
                }}
            >
                {loadingVendorLists && (
                    <Box display="flex" justifyContent="center">
                        <CircularProgress size="large" />
                    </Box>
                )}
                {!loadingVendorLists && (
                    <>
                        <Box display="flex" justifyContent="space-between">
                            <Typography variant="h2">Vendor Lists</Typography>
                            <Box>
                                <Button
                                    onClick={() => setShowVendorListDialog(true)}
                                    qaTag="vendorListsManagerV2-newVendorList"
                                    variant="outlined"
                                >
                                    <AddIcon fontSize="small" /> New Vendor List
                                </Button>
                            </Box>
                        </Box>
                        <VendorListDialog
                            displayPublicVendorInformation={
                                government.displayPublicVendorInformation
                            }
                            governmentId={governmentId}
                            hideDialog={() => setShowVendorListDialog(false)}
                            open={showVendorListDialog}
                        />
                        <VendorListsTable
                            displayPublicVendorInformation={
                                government.displayPublicVendorInformation
                            }
                            governmentId={governmentId}
                            vendorLists={vendorLists}
                        />
                    </>
                )}
            </Box>
        </>
    );
};
