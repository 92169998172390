import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classnames from 'classnames';
import { Outlet, useMatch, useParams } from 'react-router-dom';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { Box } from '@og-pro/ui';

import { FLAGS, withFlags } from '@og-pro/launch-darkly/client';

import { AutomatedAddendumModal } from './AutomatedAddendumModal';
import { ContractPermissionsModal } from '../ContractPermissionsModal';
import { ProjectCreateModal } from '../ProjectCreateModal/ProjectCreateModal';
import { ProjectLibraryModal } from '../ProjectLibrary';
import { ProjectExportModal } from '../ProjectExportModal/ProjectExportModal';
import { ProjectPermissionsModal } from '../ProjectPermissionsModal/ProjectPermissionsModal';
import { ProjectShareLinkModal } from '../ProjectShareLinkModal/ProjectShareLinkModal';
import { SuppliersNetworkModal } from '../SuppliersNetworkModal';

import { hasAutoAddendaSubscription } from '../selectors';
import connectData from '../../ConnectData';
import { getShowConnectionAlert, getUserJS } from '../../selectors';
import { getShowExpandedSupplierNetworkModal } from '../../../selectors/govApp';
import { loadGovernmentData } from '../../../actions/adminGovernment';
import { isCustomCategoriesLoaded, loadCustomCategories } from '../../../actions/category';
import { reloadProject } from '../../../actions/govProjects';
import { hideConnectionAlert, reconnectionAlert } from '../../../actions/notification';
import { reloadTemplate } from '../../../actions/templatesAdmin';
import { loadTags } from '../../../actions/tags';
import { LoadingError } from '../../../components/LoadingError/LoadingError';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { VendorProfileModal } from '../../../components/VendorProfileModal';
import { OGThemeProvider } from '../ogThemeProvider';
import { VendorDiscoveryModal } from '../VendorDiscoveryModal';
import { getSFChatConfig } from '../../../constants/salesForceEmbeddedService';
import { getAgencyChat } from '../../../helpers/salesForceChatHelper';

function fetchData(getState, dispatch, location, params) {
    const governmentId = Number.parseInt(params.governmentId, 10);
    const state = getState();
    const promises = [];

    const isCustomCategoriesReady = isCustomCategoriesLoaded(state);
    if (!isCustomCategoriesReady) {
        promises.push(dispatch(loadCustomCategories(governmentId)));
    }

    if (state.tags.get('governmentIdOfTags') !== governmentId) {
        promises.push(dispatch(loadTags({ governmentId })));
    }

    if (!state.adminGovernment.get('loadedGovernmentData')) {
        promises.push(dispatch(loadGovernmentData(governmentId)));
    }

    return Promise.all(promises);
}

const mapStateToProps = (state) => {
    return {
        loadCustomCategoriesError: state.category.get('loadingCustomCategoriesError'),
        loadGovernmentDataError: state.adminGovernment.get('loadGovernmentDataError'),
        loadingCustomCategories: state.category.get('loadingCustomCategories'),
        loadingGovernmentData: state.adminGovernment.get('loadingGovernmentData'),
        loadingTags: state.tags.get('loadingTags'),
        loadTagsError: state.tags.get('loadTagsError'),
        shouldShowConnectionAlert: getShowConnectionAlert(state),
        showAutomatedAddendumModal:
            hasAutoAddendaSubscription(state) &&
            state.projectCreate.get('showAutomatedAddendumModal'),
        showVendorDiscoveryModal: state.govProjects.get('showVendorDiscoveryModal'),
        showVendorInviteModal: getShowExpandedSupplierNetworkModal(state),
        user: getUserJS(state),
    };
};

const mapDispatchToProps = {
    hideConnectionAlert,
    reconnectionAlert,
    reloadProject,
    reloadTemplate,
};

class ConnectedApp extends Component {
    static propTypes = {
        getFlag: PropTypes.func.isRequired,
        hideConnectionAlert: PropTypes.func.isRequired,
        loadCustomCategoriesError: PropTypes.string,
        loadGovernmentDataError: PropTypes.string,
        loadingCustomCategories: PropTypes.bool,
        loadingGovernmentData: PropTypes.bool,
        loadingTags: PropTypes.bool,
        loadTagsError: PropTypes.string,
        params: PropTypes.shape({
            checklistId: PropTypes.string,
            projectId: PropTypes.string,
            requisitionId: PropTypes.string,
            templateChecklistId: PropTypes.string,
            templateId: PropTypes.string,
        }).isRequired,
        reconnectionAlert: PropTypes.func.isRequired,
        reloadProject: PropTypes.func.isRequired,
        reloadTemplate: PropTypes.func.isRequired,
        shouldShowConnectionAlert: PropTypes.bool,
        showAutomatedAddendumModal: PropTypes.bool.isRequired,
        showVendorDiscoveryModal: PropTypes.bool,
        showVendorInviteModal: PropTypes.bool,
        user: PropTypes.shape({
            address1: PropTypes.string,
            city: PropTypes.string,
            created_at: PropTypes.string,
            department_id: PropTypes.number,
            email: PropTypes.string,
            firstName: PropTypes.string,
            fullName: PropTypes.string,
            government: PropTypes.shape({
                hasContracting: PropTypes.bool.isRequired,
                hasEvaluation: PropTypes.bool.isRequired,
                hasIntake: PropTypes.bool.isRequired,
                hasSourcing: PropTypes.bool.isRequired,
            }),
            government_id: PropTypes.number,
            id: PropTypes.number,
            intercomUserHash: PropTypes.string,
            lastLogin: PropTypes.string,
            lastName: PropTypes.string,
            organization: PropTypes.shape({
                address1: PropTypes.string,
                city: PropTypes.string,
                created_at: PropTypes.string,
                id: PropTypes.number,
                isActive: PropTypes.bool,
                isVendor: PropTypes.bool,
                name: PropTypes.string,
                salesforceId: PropTypes.string,
                state: PropTypes.string,
                timezone: PropTypes.string,
                website: PropTypes.string,
            }),
            organization_id: PropTypes.number,
            phone: PropTypes.string,
            platformUuid: PropTypes.string,
            role: PropTypes.string,
            state: PropTypes.string,
            title: PropTypes.string,
            userPermissions: PropTypes.object,
        }).isRequired,
        isOGThemeEnabled: PropTypes.bool,
        disableContainerFluid: PropTypes.bool,
    };

    static defaultProps = {
        loadCustomCategoriesError: undefined,
        loadGovernmentDataError: undefined,
        loadingCustomCategories: false,
        loadingGovernmentData: false,
        loadingTags: false,
        loadTagsError: undefined,
        shouldShowConnectionAlert: false,
        showVendorInviteModal: false,
        isOGThemeEnabled: false,
    };

    componentDidMount() {
        const { user } = this.props;

        const enableServiceCloud = this.props.getFlag(FLAGS.SHOW_SERVICE_CLOUD_CHAT_BOT);

        if (enableServiceCloud && user && (__PROD__ || __STAGING__ || __INT__)) {
            const script = document.createElement('script');
            const chatConfig = getSFChatConfig();
            script.src = chatConfig.AGENCY_BOOTSTRAP_URL;

            script.onload = getAgencyChat(user);
            document.body.appendChild(script);
        }

        if (window.analytics && user) {
            const {
                user: { government, organization, userPermissions },
            } = this.props;

            const identityProps = pick(user, [
                'city',
                'email',
                'firstName',
                'id',
                'lastLogin',
                'lastName',
                'phone',
                'platformUuid',
                'role',
                'state',
                'title',
            ]);

            const userInfo = {
                address: user.address1,
                createdAt: user.created_at,
                departmentId: user.department_id,
                governmentId: user.government_id,
                name: user.fullName,
                organizationId: user.organization_id,
                salesforceId: organization.salesforceId,
            };

            if (enableServiceCloud) {
                window.analytics.identify(user.platformUuid || `pro-${user.id}`, {
                    ...identityProps,
                    ...userPermissions,
                    ...userInfo,
                });
            } else {
                window.analytics.identify(
                    user.platformUuid || `pro-${user.id}`,
                    {
                        ...identityProps,
                        ...userPermissions,
                        ...userInfo,
                    },
                    {
                        Intercom: {
                            user_hash: user.intercomUserHash,
                        },
                    }
                );
            }

            const orgGroupProps = pick(organization, [
                'city',
                'id',
                'isInternal',
                'isActive',
                'isVendor',
                'name',
                'ogEntityId',
                'ogEntityUuid',
                'salesforceId',
                'state',
                'timezone',
                'website',
            ]);
            const govGroupProps = pick(government, [
                'hasContracting',
                'hasEvaluation',
                'hasIntake',
                'hasSourcing',
            ]);
            window.analytics.group(organization.salesforceId || `pro-${organization.id}`, {
                ...orgGroupProps,
                ...govGroupProps,
                address: organization.address1,
                createdAt: organization.created_at,
                governmentId: user.government_id,
            });

            // Track user identified event with app version
            window.analytics.track('User Identified', {
                appVersion: process.env.DIST_VERSION,
            });
        }
    }

    componentWillUnmount() {
        const { shouldShowConnectionAlert } = this.props;

        if (shouldShowConnectionAlert) {
            this.props.hideConnectionAlert();
        }
    }

    render() {
        const {
            loadCustomCategoriesError,
            loadGovernmentDataError,
            loadingCustomCategories,
            loadingGovernmentData,
            loadingTags,
            loadTagsError,
            showAutomatedAddendumModal,
            showVendorDiscoveryModal,
            showVendorInviteModal,
            isOGThemeEnabled,
            disableContainerFluid,
            user,
        } = this.props;

        if (loadingCustomCategories || loadingGovernmentData || loadingTags) {
            return (
                <div
                    className={classnames({
                        'container-fluid': !disableContainerFluid,
                    })}
                >
                    <LoadingSpinner text="Loading your organization's data..." />
                </div>
            );
        }

        // On logout, the underlying component can get upset when no user is
        // specified, so prevent the component from rendering instead.
        if (!user) {
            return null;
        }

        const loadError = loadCustomCategoriesError || loadGovernmentDataError || loadTagsError;

        if (loadError) {
            return (
                <div
                    className={classnames({
                        'container-fluid': !disableContainerFluid,
                    })}
                >
                    <LoadingError error={loadError} />
                </div>
            );
        }

        return (
            <OGThemeProvider isOGThemeEnabled={isOGThemeEnabled}>
                <Box
                    className={classnames({
                        'container-fluid': !disableContainerFluid,
                    })}
                    {...(disableContainerFluid && {
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                    })}
                    // props only for SDv2 layout
                    {...(isOGThemeEnabled && {
                        id: 'SDv2App',
                    })}
                >
                    <Outlet />
                    <ContractPermissionsModal />
                    <ProjectCreateModal />
                    <ProjectLibraryModal />
                    <ProjectExportModal />
                    <ProjectPermissionsModal />
                    <ProjectShareLinkModal />
                    <VendorProfileModal />
                    {showAutomatedAddendumModal && <AutomatedAddendumModal />}
                    {showVendorInviteModal && <SuppliersNetworkModal />}
                    {showVendorDiscoveryModal && <VendorDiscoveryModal />}
                </Box>
            </OGThemeProvider>
        );
    }
}

const AppRoot = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withFlags()
)(ConnectedApp);

export const App = connectData(fetchData)(() => {
    const { templateId } = useParams();
    /*
     *   Use the useMatch hook to determine if the SDv2 base layout is enabled
     */
    const isProjectBuilderRoute = !!useMatch(
        '/governments/:governmentId/projects/:projectId/builder/create/*'
    );
    const isIntakeBuilderRoute = !!useMatch(
        '/governments/:governmentId/projects/:projectId/intake/create/*'
    );

    const isTemplateBuilderRoute =
        !!useMatch('/governments/:governmentId/templates-admin/:templateId/*') && !!templateId;

    const isRequisitionAdminRoute = !!useMatch(
        '/governments/:governmentId/requisitions-admin/:tabId?'
    );

    const isRequisitionDashboardRoute = !!useMatch({
        path: '/governments/:governmentId/requisitions',
        end: true,
    });

    const isCustomFieldArchiveRoute = !!useMatch(
        '/governments/:governmentId/requisitions-admin/custom-fields/archive'
    );

    const isVendorManagementRoute = !!useMatch(
        '/governments/:governmentId/vendors/:tabId?/:vendorId?'
    );

    const isOGThemeEnabled =
        isProjectBuilderRoute ||
        isIntakeBuilderRoute ||
        isTemplateBuilderRoute ||
        isVendorManagementRoute;
    const disableContainerFluid =
        isOGThemeEnabled ||
        isRequisitionAdminRoute ||
        isCustomFieldArchiveRoute ||
        isRequisitionDashboardRoute;

    const props = {};
    props.isOGThemeEnabled = isOGThemeEnabled;
    props.disableContainerFluid = disableContainerFluid;

    return <AppRoot {...props} />;
});
