import classnames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Box } from '@og-pro/ui';

import { getFileExtensionIcon } from '../../helpers';
import { InputText } from '../InputText';
import { Link } from '../Link/Link';
import { Button } from '../Button';
import { CDSButton } from '../SDv2/CDSButtons/CDSButton';

export class FileUploadAttachment extends Component {
    static propTypes = {
        attachment: PropTypes.shape({
            created_at: PropTypes.string,
            fileExtension: PropTypes.string, // Only not available when attachment created from form without being saved
            filename: PropTypes.string.isRequired,
            url: PropTypes.string, // Only not available when attachment created from form without being saved
        }).isRequired,
        className: PropTypes.string,
        deleteHandler: PropTypes.func,
        downloadAttachments: PropTypes.func,
        disabled: PropTypes.bool,
        isGovernmentView: PropTypes.bool,
        isLast: PropTypes.bool,
        isSubscribed: PropTypes.bool,
        updateHandler: PropTypes.func,
        project: PropTypes.shape({
            id: PropTypes.number.isRequired,
        }),
        requireConfirmation: PropTypes.bool,
        showDate: PropTypes.bool,
        useListItemStyle: PropTypes.bool,
        useOpenGovStyle: PropTypes.bool,
    };

    static defaultProps = {
        className: '',
        deleteHandler: undefined,
        disabled: false,
        useListItemStyle: false,
        updateHandler: undefined,
    };

    get styles() {
        return require('./index.scss');
    }

    constructor(props) {
        super(props);

        this.state = {
            deleting: false,
            deleteError: null,
            isEditing: false,
            filename: props.attachment.filename,
        };
    }

    deleteHandler = () => {
        const { requireConfirmation } = this.props;

        // eslint-disable-next-line no-alert
        if (!requireConfirmation || window.confirm('Are you sure?')) {
            return this.deleteAttachment();
        }
    };

    deleteAttachment = () => {
        const { attachment, deleteHandler } = this.props;

        this.setState({
            deleting: true,
            deleteError: null,
        });

        return Promise.resolve(deleteHandler(attachment)).catch((error) => {
            this.setState({
                deleting: false,
                deleteError: error.message || 'Unknown error',
            });
        });
    };

    downloadHandler = () => {
        const { attachment, isSubscribed, project } = this.props;

        this.props.downloadAttachments(project, isSubscribed, [attachment]);
    };

    toggleEdit = () => {
        this.setState((prevState) => ({ isEditing: !prevState.isEditing }));
    };

    handleFilenameChange = (e) => {
        this.setState({ filename: e.target.value });
    };

    handleEditClick = (e) => {
        e.stopPropagation();
        this.toggleEdit();
    };

    handleSaveClick = () => {
        const { updateHandler, attachment } = this.props;
        const { filename } = this.state;

        if (updateHandler) {
            const updatedAttachment = { ...attachment, filename };
            updateHandler(updatedAttachment);
        }

        this.toggleEdit();
    };

    renderLink = () => {
        const {
            attachment: { fileExtension, url },
            downloadAttachments,
            isGovernmentView,
        } = this.props;

        const { isEditing, filename } = this.state;

        const fileIcon = getFileExtensionIcon(fileExtension);

        if (isEditing) {
            return (
                <div className={this.styles.buttonContainer}>
                    <div className={this.styles.inputContainer}>
                        <InputText
                            input={{
                                value: filename,
                                onChange: this.handleFilenameChange,
                                onBlur: () => {},
                            }}
                            inputGroupPrefix={
                                <i className={`fa fa-${fileIcon} ${this.styles.fileIcon}`} />
                            }
                            meta={{}}
                            placeholder="File Name"
                            type="text"
                        />
                    </div>
                </div>
            );
        }

        if (!url && downloadAttachments === undefined) {
            return (
                <div>
                    <i className={`fa fa-${fileIcon} ${this.styles.fileIcon}`} />
                    {filename}
                </div>
            );
        }

        if (isGovernmentView || downloadAttachments === undefined) {
            return (
                <Link
                    className={this.styles.attachment}
                    href={url}
                    qaTag={`fileUpload-${filename}`}
                >
                    <i className={`fa fa-${fileIcon} ${this.styles.fileIcon}`} />
                    {filename}
                </Link>
            );
        }

        return (
            <Link
                aria-label={`Download ${filename}`}
                className={this.styles.attachment}
                onClick={this.downloadHandler}
                qaTag={`fileUpload-${filename}`}
            >
                <span
                    aria-hidden="true"
                    className={`fa fa-${fileIcon} ${this.styles.fileIcon}`}
                    role="img"
                >
                    <span className="sr-only">File Icon</span>
                </span>
                {filename}
            </Link>
        );
    };

    render() {
        const {
            attachment: { created_at: createdAt },
            className,
            deleteHandler,
            disabled,
            isLast,
            showDate,
            useListItemStyle,
            useOpenGovStyle,
            updateHandler,
        } = this.props;

        const { deleteError, deleting, isEditing } = this.state;
        if (useOpenGovStyle) {
            return (
                <Box
                    alignItems="center"
                    className={classnames(this.styles.useOpenGovStyle, {
                        [this.styles.last]: isLast,
                    })}
                    display="flex"
                >
                    <Box
                        className={classnames(this.styles.linkContainer, this.styles.link)}
                        flex={1}
                    >
                        {this.renderLink()}
                    </Box>
                    {updateHandler &&
                        (isEditing ? (
                            <Button
                                aria-label="Save"
                                bsSize="sm"
                                bsStyle="link"
                                disabled={disabled || deleting}
                                onClick={this.handleSaveClick}
                                onMouseDown={(e) => e.stopPropagation()}
                                qaTag="connectedContractForm-save"
                                zeroPadding
                            >
                                <i className="fa fa-save" /> <span className="sr-only">Save</span>
                            </Button>
                        ) : (
                            <Button
                                aria-label="Edit"
                                bsSize="sm"
                                bsStyle="link"
                                disabled={disabled || deleting}
                                onClick={this.handleEditClick}
                                onMouseDown={(e) => e.stopPropagation()}
                                qaTag="connectedContractForm-edit"
                                zeroPadding
                            >
                                <i className="fa fa-pencil" /> <span className="sr-only">Edit</span>
                            </Button>
                        ))}
                    {deleteHandler && (
                        <CDSButton
                            noPadding
                            onClick={this.deleteHandler}
                            qaTag="attachment-delete"
                            variant="text"
                        >
                            <i className="fa fa-times" />
                        </CDSButton>
                    )}
                </Box>
            );
        }

        return (
            <div
                className={classnames(
                    this.styles.container,
                    useListItemStyle && this.styles.listItemStyle,
                    className
                )}
            >
                <div className={this.styles.linkContainer}>{this.renderLink()}</div>
                <div className={this.styles.buttonContainer}>
                    {updateHandler &&
                        (isEditing ? (
                            <Button
                                aria-label="Save"
                                bsSize="sm"
                                bsStyle="link"
                                disabled={disabled || deleting}
                                onClick={this.handleSaveClick}
                                onMouseDown={(e) => e.stopPropagation()}
                                qaTag="connectedContractForm-save"
                                zeroPadding
                            >
                                <i className="fa fa-save" /> save
                                <span className="sr-only">save</span>
                            </Button>
                        ) : (
                            <Button
                                aria-label="Edit"
                                bsSize="sm"
                                bsStyle="link"
                                disabled={disabled || deleting}
                                onClick={this.handleEditClick}
                                onMouseDown={(e) => e.stopPropagation()}
                                qaTag="connectedContractForm-edit"
                                zeroPadding
                            >
                                <i className="fa fa-pencil" /> edit
                                <span className="sr-only">edit</span>
                            </Button>
                        ))}

                    {deleteHandler && (
                        <Button
                            bsSize="xs"
                            bsStyle="link"
                            className={this.styles.deleteButton}
                            disabled={disabled || deleting}
                            onClick={this.deleteHandler}
                            qaTag="uploadAttachmentForm-delete"
                        >
                            <i className="fa fa-lg fa-trash-o text-danger" />
                            <span className="sr-only">delete</span>
                        </Button>
                    )}
                </div>
                {showDate && !!createdAt && (
                    <div className={classnames('text-muted', this.styles.date)}>
                        {moment(createdAt).format('lll')}
                    </div>
                )}
                {deleteError && (
                    <div className={classnames('error-block', this.styles.deleteError)}>
                        {deleteError}
                    </div>
                )}
            </div>
        );
    }
}
