import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Outlet } from 'react-router-dom';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { projectSocket } from '../../../lib/sockets';
import { ProjectPublicDisplayOptionsModal } from './ProjectPublicDisplayOptionsModal';
import { ProjectEditTimelinesModal } from './ProjectEditTimelinesModal';
import { getProjectJS } from '../selectors';
import { getShowConnectionAlert } from '../../selectors';
import connectData from '../../ConnectData';
import {
    govLoadProject,
    reloadProject,
    resetForceReloadProject,
    shouldLoadProject,
} from '../../../actions/govProjects';
import { reset } from '../../../actions/project/create/projectCreate';
import { joinProject, leaveProject } from '../../../actions/projectSocket';
import {
    hideConnectionAlert,
    reconnectionAlert,
    showConnectionAlert,
} from '../../../actions/notification';
import { LoadingSpinner, LoadingError } from '../../../components';
import { RichTextEditorContext } from '../../../components/contexts';

function fetchData(getState, dispatch, location, params) {
    const projectId = +params.projectId;
    if (shouldLoadProject(getState(), projectId)) {
        dispatch(reset());
        return dispatch(govLoadProject(projectId));
    }
    return Promise.resolve();
}

const mapStateToProps = (state) => {
    return {
        loading: state.govProjects.get('loadingSelected'),
        loadError: state.govProjects.get('loadSelectedError'),
        project: getProjectJS(state),
        shouldForceReloadProject: state.govProjects.get('shouldForceReload'),
        shouldShowConnectionAlert: getShowConnectionAlert(state),
        showPublicDisplayOptionsModal: state.govProjects.get('showPublicDisplayOptionsModal'),
        showEditTimelinesModal: state.govProjects.get('showEditTimelinesModal'),
    };
};

const mapDispatchToProps = {
    govLoadProject,
    hideConnectionAlert,
    joinProject,
    leaveProject,
    reconnectionAlert,
    reloadProject,
    resetForceReloadProject,
    showConnectionAlert,
};

// @connectData
// @connect
class ConnectedProjectContainer extends Component {
    static propTypes = {
        govLoadProject: PropTypes.func.isRequired,
        hideConnectionAlert: PropTypes.func.isRequired,
        joinProject: PropTypes.func.isRequired,
        leaveProject: PropTypes.func.isRequired,
        loadError: PropTypes.string,
        loading: PropTypes.bool.isRequired,
        params: PropTypes.shape({
            projectId: PropTypes.string.isRequired,
        }).isRequired,
        project: PropTypes.object,
        reconnectionAlert: PropTypes.func.isRequired,
        reloadProject: PropTypes.func.isRequired,
        resetForceReloadProject: PropTypes.func.isRequired,
        shouldForceReloadProject: PropTypes.bool.isRequired,
        shouldShowConnectionAlert: PropTypes.bool.isRequired,
        showConnectionAlert: PropTypes.func.isRequired,
        showPublicDisplayOptionsModal: PropTypes.bool.isRequired,
        showEditTimelinesModal: PropTypes.bool.isRequired,
    };

    get projectIdFromParams() {
        // Use the param project ID in case project has not been loaded yet
        return Number.parseInt(this.props.params.projectId, 10);
    }

    reconnectHandler = () => {
        this.props.reconnectionAlert();
        this.props.joinProject(this.projectIdFromParams);
        this.props.reloadProject(this.projectIdFromParams);
    };

    connectErrorHandler = () => {
        this.props.showConnectionAlert();
    };

    componentDidMount() {
        window.scroll(0, 0);

        projectSocket.connect();
        projectSocket.io.on('reconnect', this.reconnectHandler);
        projectSocket.on('connect_error', this.connectErrorHandler);
        this.props.joinProject(this.projectIdFromParams);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { shouldForceReloadProject } = this.props;

        // Allows project to be force reloaded from a socket broadcast event
        if (nextProps.shouldForceReloadProject && !shouldForceReloadProject) {
            this.props.govLoadProject(this.projectIdFromParams);
            this.props.resetForceReloadProject();
        }
    }

    componentWillUnmount() {
        if (this.props.shouldShowConnectionAlert) {
            this.props.hideConnectionAlert();
        }

        projectSocket.io.off('reconnect', this.reconnectHandler);
        projectSocket.off('connect_error', this.connectErrorHandler);
        this.props.leaveProject(this.projectIdFromParams);
        projectSocket.disconnect();
    }

    render() {
        const {
            loadError,
            loading,
            project,
            showPublicDisplayOptionsModal,
            showEditTimelinesModal,
        } = this.props;

        if (loading) {
            return <LoadingSpinner text="Loading Your Project..." />;
        }

        if (loadError || !project) {
            return <LoadingError error={loadError} />;
        }

        return (
            <RichTextEditorContext project={project}>
                <Outlet />
                {showPublicDisplayOptionsModal && (
                    <ProjectPublicDisplayOptionsModal project={project} />
                )}
                {showEditTimelinesModal && <ProjectEditTimelinesModal />}
            </RichTextEditorContext>
        );
    }
}

export const ProjectContainer = compose(
    connectData(fetchData),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedProjectContainer);
