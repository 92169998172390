import { useCustomFormService } from './useCustomFormService';
import { getCustomForm } from './queries';

/**
 * Load custom form if customFormId is provided
 * @param {number | null} customFormId
 * @returns {object} react-query usequery return value
 */
export const useLoadCustomForm = (customFormId) => {
    return useCustomFormService({
        queryKey: 'getCustomForm',
        query: getCustomForm,
        params: {
            customFormId,
        },
        enabled: customFormId !== null,
        placeholderData: {
            getCustomForm: {
                fieldsOrder: [],
                requiredFields: [],
                conditionalStatements: [],
            },
        },
    });
};
