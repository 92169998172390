import React from 'react';
import { Link } from 'react-router-dom';

import { Box, Chip, Typography } from '@og-pro/ui';

import {
    createCustomFieldFormName,
    customFieldFields,
    fieldStatusesDict,
} from '@og-pro/shared-config/customFormService/customField';

import { useSelector } from 'react-redux';

import { formValueSelector } from 'redux-form';

import PropTypes from 'prop-types';

import { capitalDesignTokens } from '@opengov/capital-mui-theme';

import { getRequestTypes } from '../../../../../selectors/govApp';

import { LoadingSpinner } from '../../../../../components';
import { getRequestTypesPath } from '../../selectors';
import { useLoadCustomField } from '../../../../../lib/customFormService/useLoadCustomField';

const UsedListContainer = ({ showChip, chipLabel, children }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 1,
                }}
            >
                <Typography variant="h4">Currently In Use</Typography>
                {showChip && <Chip label={chipLabel} size="extraSmall" variant="filled" />}
            </Box>
            {children}
        </Box>
    );
};

UsedListContainer.propTypes = {
    children: PropTypes.node,
    showChip: PropTypes.bool,
    chipLabel: PropTypes.number,
};

const { ID, STATUS } = customFieldFields;

export const CustomFieldUsedList = () => {
    const requestTypes = useSelector(getRequestTypes);
    const isLoading = useSelector((state) => state.requestTypes.get('loadingList'));
    const customFieldId = useSelector((state) =>
        formValueSelector(createCustomFieldFormName)(state, ID)
    );
    const status = useSelector((state) =>
        formValueSelector(createCustomFieldFormName)(state, STATUS)
    );
    const {
        data: { getCustomFieldClientForms: clientForms },
    } = useLoadCustomField(customFieldId);
    const requestTypePath = useSelector(getRequestTypesPath);

    if (isLoading) {
        return (
            <UsedListContainer>
                <LoadingSpinner noPadding size="medium" useOpenGovStyle />
            </UsedListContainer>
        );
    }

    const usedRequestTypes = clientForms
        .map((clientForm) => {
            const requestType = requestTypes.find((elem) => elem.id === clientForm.clientFormId);
            return requestType;
        })
        .filter((elem) => elem !== undefined);

    if (usedRequestTypes.length === 0) {
        return (
            <UsedListContainer chipLabel={0} showChip>
                <Typography
                    sx={(theme) => ({
                        color: theme.palette.text.secondary,
                        fontWeight: theme.typography.fontWeightRegular,
                    })}
                    variant="bodySmall"
                >
                    {status !== fieldStatusesDict.PUBLISHED
                        ? 'No Request Types are using this field. Publish this field in order to add it to a Request Type.'
                        : 'No Request Types are using this field.'}
                </Typography>
            </UsedListContainer>
        );
    }

    return (
        <UsedListContainer chipLabel={usedRequestTypes.length} showChip>
            <ul
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: capitalDesignTokens.foundations.units.unitHalf,
                    'padding-inline-start': capitalDesignTokens.foundations.units.unit3,
                    margin: 0, // override ul styling
                }}
            >
                {usedRequestTypes.map((requestType) => {
                    return (
                        <li key={requestType.id}>
                            <Typography
                                component={Link}
                                to={`${requestTypePath}/${requestType.id}`}
                            >
                                {requestType.name || 'Untitled'}
                            </Typography>
                        </li>
                    );
                })}
            </ul>
        </UsedListContainer>
    );
};

CustomFieldUsedList.propTypes = {};
