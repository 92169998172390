import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { debounce } from 'lodash';

import { getLineItemAwardsJS, getIsViewOnly, getLineItemAwardProposalsDataJS } from './selectors';
import { getProjectJS, isEvaluationEditor } from '../../selectors';
import connectData from '../../../ConnectData';
import { menuActionHandler, showInstructionsModal } from '../../../../actions/govProjects';
import {
    awardLowestLineItemAwards,
    deleteLineItemAwards,
    loadLineItemAwards,
    showProposalSelect,
} from '../../../../actions/proposalEvaluations';
import {
    LineItemAwardTable,
    Button,
    ButtonGroup,
    LoadingError,
    LoadingSpinner,
    SectionTitle,
    ZeroState,
} from '../../../../components';
import { ReportsModalButton } from '../../ReportsModal/ReportsModalButton';
import { UNSEAL_BIDS } from '../../../../constants/menuActions';
import { showConfirmationSimpleModal } from '../../../../actions/confirmation';

function fetchData(getState, dispatch, location, params) {
    const projectId = Number.parseInt(params.projectId, 10);
    return dispatch(loadLineItemAwards(projectId));
}

const mapStateToProps = (state) => {
    return {
        lineItemAwards: getLineItemAwardsJS(state),
        error: state.proposalEvaluations.get('loadLineItemAwardsError'),
        isEditor: isEvaluationEditor(state),
        isPricingSealed: state.proposalEvaluations.get('lineItemAwardsPricingSealed'),
        isViewOnly: getIsViewOnly(state),
        loading: state.proposalEvaluations.get('loadingLineItemAwards'),
        project: getProjectJS(state),
        proposalsData: getLineItemAwardProposalsDataJS(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        menuActionHandler: (unseal, project) => dispatch(menuActionHandler(unseal, project)),
        showInstructionsModal: () =>
            dispatch(showInstructionsModal('evaluationLineItemAwardCreated')),
        showProposalSelect: () => dispatch(showProposalSelect()),
        deleteLineItemAwards: (projectId) => {
            dispatch(
                showConfirmationSimpleModal(() => dispatch(deleteLineItemAwards(projectId)), {
                    bsStyle: 'danger',
                    btnText: 'Remove Awards',
                    text: 'This action will remove ALL line item awards',
                    title: 'Confirm Line Item Award Removal',
                })
            );
        },
        awardLowestLineItemAwards: (projectId) => {
            dispatch(
                showConfirmationSimpleModal(() => dispatch(awardLowestLineItemAwards(projectId)), {
                    bsStyle: 'success',
                    btnText: 'Award',
                    icon: 'check',
                    text: "This action will award 'primary' to lowest unit cost items",
                    title: 'Award Lowest Unit Cost Items',
                })
            );
        },
    };
};

// @connectData
// @connect
class ConnectedLineItemAward extends Component {
    static propTypes = {
        lineItemAwards: PropTypes.array,
        error: PropTypes.string,
        isEditor: PropTypes.bool,
        isPricingSealed: PropTypes.bool,
        isViewOnly: PropTypes.bool,
        loading: PropTypes.bool,
        menuActionHandler: PropTypes.func.isRequired,
        project: PropTypes.shape({
            auctionMaxFractionDigits: PropTypes.number,
            id: PropTypes.number.isRequired,
            showBids: PropTypes.bool.isRequired,
            template: PropTypes.shape({
                isReverseAuction: PropTypes.bool.isRequired,
            }).isRequired,
        }).isRequired,
        proposalsData: PropTypes.arrayOf(
            PropTypes.shape({
                proposalId: PropTypes.number.isRequired,
                vendorName: PropTypes.string.isRequired,
            })
        ),
        showInstructionsModal: PropTypes.func.isRequired,
        showProposalSelect: PropTypes.func.isRequired,
        deleteLineItemAwards: PropTypes.func.isRequired,
        awardLowestLineItemAwards: PropTypes.func.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    handleCompleteEvaluation = () => {
        this.props.showProposalSelect();
    };

    handleShowInstructionsClick = () => {
        this.props.showInstructionsModal();
    };

    handleReset = debounce(() => this.props.deleteLineItemAwards(this.props.project.id), 300);

    handleAwarding = debounce(
        () => this.props.awardLowestLineItemAwards(this.props.project.id),
        300
    );

    renderLineItemAwardsTable = (lineItemAward, i) => {
        const {
            isViewOnly,
            project: {
                auctionMaxFractionDigits,
                template: { isReverseAuction },
            },
            proposalsData,
        } = this.props;

        return (
            <LineItemAwardTable
                auctionMaxFractionDigits={isReverseAuction ? auctionMaxFractionDigits : null}
                defaultTitle={`Table ${i + 1}`}
                isViewOnly={isViewOnly}
                key={i}
                lineItemAward={lineItemAward}
                proposalsData={proposalsData}
                showCustomColumns
            />
        );
    };

    renderSealedBids() {
        const { isEditor, project } = this.props;

        const title = project.showBids ? 'Bid Pricing is Sealed' : 'Bids are Sealed';
        const buttonText = (
            <span>
                <i className="fa fa-envelope-open" /> Unseal {project.showBids ? 'Pricing' : 'Bids'}
            </span>
        );

        return (
            <ZeroState
                buttonClickHandler={() => this.props.menuActionHandler(UNSEAL_BIDS, project)}
                buttonProps={{
                    disabled: !isEditor,
                    tooltip: isEditor
                        ? undefined
                        : 'Only evaluation editors and admins can unseal bids',
                }}
                buttonText={buttonText}
                info="Click the button above to unseal the pricing information and start bid tabulation"
                title={title}
            />
        );
    }

    renderZeroState() {
        const { noProposals, noProposalsHeader } = this.styles;

        return (
            <>
                <SectionTitle title="Line Item Awarding" />
                <div className={`row text-muted ${noProposals}`}>
                    <div className={`col-sm-8 col-sm-offset-2 ${noProposalsHeader}`}>
                        No responses have been submitted yet.
                        <br />
                        <br />
                        Once there are submitted responses they will appear on this page in bid
                        tabulation tables. You can manually add responses using the &quot;Responses
                        &quot; tab.
                    </div>
                </div>
            </>
        );
    }

    render() {
        const { lineItemAwards, error, isPricingSealed, isViewOnly, loading, proposalsData } =
            this.props;
        // NOTE: there is a loading edge case where if we come to this page via a route change then
        // it's possible that we render before the redux action has been completed digested by our
        // store. This puts us in a state where all expected store values are `undefined`, and can
        // cause rendering bugs where children of this component attempt to set state but have
        // unmounted due to this component re-rendering when the redux action finally catches up.
        if ((loading || !lineItemAwards) && !error) {
            return <LoadingSpinner />;
        }

        if (error) {
            return <LoadingError error={error} />;
        }

        if (proposalsData.length === 0) {
            return this.renderZeroState();
        }

        if (isPricingSealed) {
            return this.renderSealedBids();
        }

        const awardKeyInfo = (
            <span>
                <span style={{ backgroundColor: 'forestgreen', color: 'white' }}>
                    Primary award{' '}
                </span>{' '}
                cells are green and&nbsp;
                <span style={{ backgroundColor: 'gray', color: 'white' }}>Backup award </span> cells
                are gray.
            </span>
        );

        const instructions = (
            <p>
                Vendors with the lowest unit cost will initially be displayed in{' '}
                <span style={{ color: 'green' }}>green</span>.<br />
                {!isViewOnly &&
                    'To make an award, click any cell under the unit cost column for a vendor to select an award type\n'}
                {awardKeyInfo}
                {!isViewOnly &&
                    ' To remove an award, select "No Award".\n' +
                        'Once you are ready to finalize awarding, click "Award Project" which allows you to notify vendors and display results publicly.'}
            </p>
        );
        return (
            <>
                <SectionTitle
                    info={instructions}
                    onHelpClick={this.handleShowInstructionsClick}
                    title="Line Item Awarding"
                />
                <div className="text-right" style={{ marginBottom: '10px' }}>
                    <ReportsModalButton type="evaluationTabulationReport" />
                </div>
                {!isViewOnly && (
                    <div className="text-right">
                        Bulk Actions:&nbsp;&nbsp;
                        <ButtonGroup bsSize="sm">
                            <Button
                                onClick={this.handleAwarding}
                                qaTag="connectedLineItemAward-autoAwardLowest"
                                tooltip="Automatically awards each line item to the vendor with the lowest unit cost"
                            >
                                <span className="text-info">
                                    <i className="fa fa-magic" /> Auto Award Lowest
                                </span>
                            </Button>
                            <Button
                                onClick={this.handleReset}
                                qaTag="connectedLineItemAward-removeAllAwards"
                                tooltip="Removes all current awards so you can start from scratch"
                            >
                                <span className="text-danger">
                                    <i className="fa fa-refresh" /> Remove All Awards
                                </span>
                            </Button>
                        </ButtonGroup>
                    </div>
                )}
                <div className={this.styles.lineItemAwards}>
                    {lineItemAwards.map(this.renderLineItemAwardsTable)}
                </div>
                {!isViewOnly && (
                    <div className={this.styles.completeEvaluationButtonWrapper}>
                        <Button
                            bsSize="lg"
                            bsStyle="success"
                            onClick={this.handleCompleteEvaluation}
                            qaTag="connectedLineItemAward-awardProject"
                        >
                            <i className="fa fa-trophy" /> Award Project
                        </Button>
                    </div>
                )}
            </>
        );
    }
}

export const LineItemAward = compose(
    connectData(fetchData),
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedLineItemAward);
