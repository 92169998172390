import { createSelector } from 'reselect';

import { getUserJS } from '../../containers/selectors';

const getAdmin = (state) => state.admin;

export const getOutlineNumberingJS = createSelector([getUserJS, getAdmin], (user, admin) => {
    if (user && user.government) {
        return user.government.outlineNumbering;
    }

    if (admin.get('outlineNumbering')) {
        return admin.get('outlineNumbering').toJS();
    }
});
