import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { InputText } from '../../../../InputText';

export class CustomVariableResponseForm extends PureComponent {
    static propTypes = {
        disabled: PropTypes.bool,
        input: PropTypes.object.isRequired,
        isOGThemeEnabledForComponents: PropTypes.bool,
        meta: PropTypes.object.isRequired,
        showValidation: PropTypes.bool,
    };

    render() {
        const { disabled, input, isOGThemeEnabledForComponents, meta, showValidation } = this.props;
        return (
            <InputText
                disabled={disabled}
                id={input.name}
                input={input}
                meta={meta}
                placeholder={isOGThemeEnabledForComponents ? null : 'Enter variable value'}
                qaTag="customVariableResponseForm-variableValue"
                showValidation={showValidation}
                type="text"
                useOpenGovStyle={isOGThemeEnabledForComponents}
            />
        );
    }
}
