import PropTypes from 'prop-types';
import React, { useState } from 'react';
import copy from 'copy-to-clipboard';

import { Tooltip } from '../Tooltip';

const CLICK_TO_COPY = 'Click to copy';
const COPIED = 'Copied!';

export const CopyToClipboardIcon = ({ icon, style, value }) => {
    const [tooltip, setTooltip] = useState(CLICK_TO_COPY);
    const Icon = icon || 'i';
    const onClickHandler = () => {
        copy(value.trim());
        setTooltip(COPIED);
        setTimeout(() => setTooltip(CLICK_TO_COPY), 2000);
    };

    return (
        <Tooltip placement="right" tooltip={tooltip}>
            <Icon
                className="fa fa-clone pseudoLink"
                onClick={onClickHandler}
                style={style ?? undefined}
            />
        </Tooltip>
    );
};

CopyToClipboardIcon.propTypes = {
    icon: PropTypes.element,
    style: PropTypes.object,
    value: PropTypes.string.isRequired,
};
