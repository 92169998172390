import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Box } from '@og-pro/ui';

import { SectionTitle } from './SectionTitle';
import { ReviewChecklistSectionStatus } from './Status';

export const ReviewChecklistSection = ({
    children,
    containerProps = {},
    customStatus = null,
    hideStatus,
    incomplete,
    incompleteFields,
    isChild,
    last,
    onClick,
    section,
}) => {
    const styles = require('./index.scss');

    return (
        <Box
            className={classnames(styles.section, { [styles.borderBottom]: !last })}
            onClick={onClick}
        >
            <Box
                alignItems="center"
                display="flex"
                justifyContent="space-between"
                pl={isChild ? 5 : 0}
                pr={isChild ? 3 : 0}
                {...containerProps}
            >
                <Box>
                    <SectionTitle isChild={isChild} section={section} />
                    {children}
                </Box>
                {!hideStatus ? (
                    <ReviewChecklistSectionStatus
                        incomplete={incomplete || section.showValidationError}
                        incompleteFields={incompleteFields}
                    />
                ) : null}
                {customStatus}
            </Box>
        </Box>
    );
};

ReviewChecklistSection.propTypes = {
    children: PropTypes.node,
    containerProps: PropTypes.object,
    customStatus: PropTypes.node,
    hideStatus: PropTypes.bool,
    incomplete: PropTypes.bool,
    incompleteFields: PropTypes.number,
    isChild: PropTypes.bool,
    last: PropTypes.bool,
    onClick: PropTypes.func,
    section: PropTypes.shape({
        details: PropTypes.string,
        disableNumbering: PropTypes.bool,
        disableTitle: PropTypes.bool,
        title: PropTypes.string,
        numbering: PropTypes.string,
        manualNumber: PropTypes.string,
        showValidationError: PropTypes.bool,
    }).isRequired,
};
