import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

export class NoDeletionHistoryModal extends PureComponent {
    static propTypes = {
        onHide: PropTypes.func.isRequired,
        show: PropTypes.bool,
    };

    render() {
        const { onHide, show } = this.props;

        if (!show) return null;

        return (
            <Modal onHide={onHide} show>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">No Deletion History</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    No history to export yet. Once a record is deleted, the history will be
                    available for export.
                </Modal.Body>
            </Modal>
        );
    }
}
