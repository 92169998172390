import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { LoadingButton } from '../../../LoadingButton';
import { downloadVendorActivity } from '../../../../actions/govProposals';

export const VendorActivityButton = ({ project, proposal }) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const onClick = async () => {
        setLoading(true);

        dispatch(downloadVendorActivity(project.id, proposal.vendor_id)).finally(() => {
            setLoading(false);
        });
    };

    return (
        <LoadingButton
            disabled={loading}
            icon="fa-list"
            loading={loading}
            onClick={onClick}
            qaTab="proposalDetail-vendorActivity"
            text="Vendor activity"
        />
    );
};

VendorActivityButton.propTypes = {
    project: PropTypes.object.isRequired,
    proposal: PropTypes.object.isRequired,
};
