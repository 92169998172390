import { Delete as DeleteIcon } from '@mui/icons-material';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field } from 'redux-form';
import { Box, Button, Typography } from '@og-pro/ui';

import WarningIcon from '@mui/icons-material/Warning';

import { vendorSelectionStates } from '@og-pro/shared-config/requisitions';

import { AssociatedLineItems } from './AssociatedLineItems';

import { DeliveryCodesAndPaymentTerms } from './DeliveryCodesAndPaymentTerms';
import { ForPurchaseOrder } from './ForPurchaseOrder';
import { dividerStyle, vendorCardStyle } from './styles';
import { VendorContact } from './VendorContact';
import { VendorContractInput } from './VendorContractInput';
import { updatePriceItemVendors } from '../helpers';
import { fieldNames } from '../../../constants';
import { hasInvalidVendorAddress } from '../../../helpers';
import { requisitionsCreateFormValueSelector } from '../../../selectors';
import { qaTagPageName } from '../../../../constants';
import { hasContractingSubscription } from '../../../../../../GovApp/selectors';
import { InputText, VendorInfo } from '../../../../../../../components';
import { limitTextLength } from '../../../../../../../Forms/normalizers';

const { CONTRACT_ID, PRICE_TABLE, VENDOR_ASSIGNED_NO, VENDOR_SELECTION_STATE } = fieldNames;

export const VendorCard = ({
    associatedContractData,
    associatedVendorData,
    change,
    disabled,
    endsInPurchaseOrder,
    fieldName,
    fields,
    index,
    loading,
    showAddVendorInputHandler,
    showFormValidation,
    showMismatchModal,
    showVendorSelection,
    vendorSelectionFormDisplay,
}) => {
    const dispatch = useDispatch();

    const hasContracting = useSelector(hasContractingSubscription);
    const priceTable = useSelector((state) =>
        requisitionsCreateFormValueSelector(state, PRICE_TABLE)
    );
    const vendor = fields.get(index);

    const onRemoveVendor = (skipVendorSelectionStateUpdate) => {
        updatePriceItemVendors(dispatch, change, priceTable.priceItems, [], vendor);

        if (fields.length === 1 && !skipVendorSelectionStateUpdate) {
            dispatch(change(VENDOR_SELECTION_STATE, vendorSelectionStates.NO_VENDORS));
        }

        fields.remove(index);
    };

    const getContractOptions = () => {
        if (endsInPurchaseOrder) {
            return associatedContractData;
        }
        return associatedVendorData?.contractParties.map(({ contract, ...contractParty }) => ({
            ...contract,
            contractParty,
        }));
    };

    const selectNewVendor = () => {
        onRemoveVendor(true);
        showAddVendorInputHandler();
    };

    const vendorUsers = associatedVendorData?.organization.users || [];

    const sharedFormProps = {
        change,
        disabled,
        fieldName,
        showFormValidation,
    };

    const hasInvalidAddress = hasInvalidVendorAddress(vendor);

    return (
        <Box className="col-xs-12" {...vendorCardStyle}>
            <Box paddingBottom={2}>
                <div className="pull-right">
                    <Button
                        color="error"
                        disabled={disabled}
                        onClick={() => onRemoveVendor()}
                        qaTag={`${qaTagPageName}-removeVendor`}
                        startIcon={<DeleteIcon />}
                        variant="text"
                    >
                        Remove Vendor
                    </Button>
                </div>
                <div>
                    <Typography variant="h4">Vendor {index + 1}</Typography>
                </div>
            </Box>
            <div className="row">
                <div className="col-xs-12 col-md-6">
                    <div className="InputText--Label__ogLabel control-label">Vendor</div>
                    <VendorInfo
                        associatedVendorData={associatedVendorData}
                        hasInvalidAddress={hasInvalidAddress}
                        loading={loading}
                        vendor={vendor}
                    />
                </div>
                {hasContracting && !loading && (
                    <div className="col-xs-12 col-md-6">
                        <VendorContractInput
                            {...sharedFormProps}
                            contracts={getContractOptions() || []}
                            endsInPurchaseOrder={endsInPurchaseOrder}
                            selectedContractId={vendor[CONTRACT_ID]}
                            showMismatchModal={(data) =>
                                showMismatchModal({ ...data, selectNewVendor })
                            }
                            vendor={vendor}
                            vendorUsers={vendorUsers}
                        />
                    </div>
                )}
            </div>
            <Box component="hr" {...dividerStyle} />
            <Box className="row" marginBottom={2}>
                <div className="col-xs-12 col-md-6">
                    <VendorContact
                        {...sharedFormProps}
                        endsInPurchaseOrder={endsInPurchaseOrder}
                        vendor={vendor}
                        vendorUsers={vendorUsers}
                    />
                </div>
                <div className="col-xs-12 col-md-6">
                    {endsInPurchaseOrder ? (
                        <>
                            <div className="InputText--Label__ogLabel control-label">
                                Vendor Number
                            </div>
                            <Typography variant="bodyDefault">{vendor.vendorId}</Typography>
                        </>
                    ) : (
                        <Field
                            component={InputText}
                            label="Vendor Number"
                            name={`${fieldName}.${VENDOR_ASSIGNED_NO}`}
                            normalize={limitTextLength(128)}
                            qaTag={`${qaTagPageName}-vendorNumber`}
                            type="text"
                            useOpenGovStyle
                        />
                    )}
                </div>
            </Box>
            <DeliveryCodesAndPaymentTerms
                {...sharedFormProps}
                vendorSelectionFormDisplay={vendorSelectionFormDisplay}
            />
            {endsInPurchaseOrder ? (
                <>
                    <Box component="hr" {...dividerStyle} />
                    <ForPurchaseOrder
                        {...sharedFormProps}
                        fields={fields}
                        index={index}
                        showVendorSelection={showVendorSelection}
                    />
                    {hasInvalidAddress && (
                        <Box alignItems="center" display="flex">
                            <WarningIcon color="warning" fontSize="inherit" />
                            <Typography color="warning.main" variant="bodySmall">
                                <b>Vendor Address Required: </b> You may proceed at this point, but
                                an address must be added to this vendor’s profile in your accounting
                                system to create a Purchase Order later.
                            </Typography>
                        </Box>
                    )}
                </>
            ) : (
                priceTable?.priceItems?.length >= 2 && (
                    <AssociatedLineItems {...sharedFormProps} fields={fields} index={index} />
                )
            )}
        </Box>
    );
};

VendorCard.propTypes = {
    associatedContractData: PropTypes.array,
    associatedVendorData: PropTypes.shape({
        contractParties: PropTypes.array.isRequired,
        organization: PropTypes.shape({
            users: PropTypes.array.isRequired,
        }).isRequired,
    }),
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    endsInPurchaseOrder: PropTypes.bool.isRequired,
    fields: PropTypes.object.isRequired,
    fieldName: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    loading: PropTypes.bool,
    showAddVendorInputHandler: PropTypes.func.isRequired,
    showFormValidation: PropTypes.bool,
    showMismatchModal: PropTypes.func.isRequired,
    showVendorSelection: PropTypes.bool,
    vendorSelectionFormDisplay: PropTypes.shape({
        deliveryCodeSelectOptions: PropTypes.array.isRequired,
        paymentTermsSelectOptions: PropTypes.array.isRequired,
    }).isRequired,
};
