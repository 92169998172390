import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { NavDropdown } from 'react-bootstrap';

import { projectTypesPathsDict } from '@og-pro/shared-config/projects';

import { LinkContainer } from '../../LinkContainer/LinkContainer';
import { MenuItem } from '../../MenuItem/MenuItem';

const { PROJECTS, CONTRACTS } = projectTypesPathsDict;

export const DashboardNav = ({
    governmentId,
    pathname,
    user: {
        government: {
            hasContracting,
            hasDocBuilder,
            hasEvaluation,
            hasRequisitionOnly,
            hasSourcing,
        },
        userPermissions: {
            isContractAdmin,
            isDepartmentContractAdmin,
            isDepartmentEditor,
            isGlobalEditor,
        },
    },
}) => {
    // Do not include dashboard if user does not have certain permissions or modules
    if (
        hasRequisitionOnly ||
        (!isGlobalEditor && !isDepartmentEditor && !isContractAdmin && !isDepartmentContractAdmin)
    ) {
        return null;
    }

    const dropdownTitle = <span>Dashboards</span>;
    const urlRegex = new RegExp(`/dashboard/+(${CONTRACTS})|/dashboard/+(${PROJECTS})`, 'g');
    const isActive = pathname.match(urlRegex);

    return (
        <NavDropdown
            className={classnames('dashboards-nav', isActive && 'active')}
            id="dashboards-nav-dropdown"
            title={dropdownTitle}
        >
            {(hasDocBuilder || hasSourcing || hasEvaluation) &&
                (isGlobalEditor || isDepartmentEditor) && (
                    <LinkContainer
                        key="projects-dashboard"
                        to={`/governments/${governmentId}/dashboard/${PROJECTS}`}
                    >
                        <MenuItem qaTag="govNavbar-projects">
                            <i className="fa fa-folder-open" /> Projects
                        </MenuItem>
                    </LinkContainer>
                )}
            {hasContracting && (isContractAdmin || isDepartmentContractAdmin) && (
                <LinkContainer
                    key="contracts-dashboard"
                    to={`/governments/${governmentId}/dashboard/${CONTRACTS}`}
                >
                    <MenuItem qaTag="govNavbar-contracts">
                        <i className="fa fa-file-text" /> Contracts
                    </MenuItem>
                </LinkContainer>
            )}
        </NavDropdown>
    );
};

DashboardNav.propTypes = {
    governmentId: PropTypes.number.isRequired,
    pathname: PropTypes.string.isRequired,
    user: PropTypes.shape({
        government: PropTypes.shape({
            hasContracting: PropTypes.bool.isRequired,
            hasDocBuilder: PropTypes.bool.isRequired,
            hasEvaluation: PropTypes.bool.isRequired,
            hasRequisitionOnly: PropTypes.bool.isRequired,
            hasSourcing: PropTypes.bool.isRequired,
        }).isRequired,
        userPermissions: PropTypes.shape({
            isContractAdmin: PropTypes.bool.isRequired,
            isDepartmentContractAdmin: PropTypes.bool.isRequired,
            isDepartmentEditor: PropTypes.bool.isRequired,
            isGlobalEditor: PropTypes.bool.isRequired,
        }).isRequired,
    }).isRequired,
};
