import { get } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { proposalStatusesDict } from '@og-pro/shared-config/proposals';

import { DataTable } from '../../../DataTable/DataTable';

const { PUBLISHED } = proposalStatusesDict;

export class AddendumConfirmTable extends PureComponent {
    static propTypes = {
        proposals: PropTypes.arrayOf(
            PropTypes.shape({
                addendumConfirms: PropTypes.arrayOf(
                    PropTypes.shape({
                        created_at: PropTypes.string.isRequired,
                        user: PropTypes.shape({
                            displayName: PropTypes.string.isRequired,
                        }).isRequired,
                    })
                ).isRequired,
                companyName: PropTypes.string,
                status: PropTypes.string.isRequired,
            })
        ).isRequired,
        title: PropTypes.string,
    };

    get styles() {
        return require('./index.scss');
    }

    formatDataForCSVExport = (data) => {
        const headers = ['Response', 'Confirmed', 'Confirmed At', 'Confirmed By'];

        const rows = data.map((dataRow) => {
            return [
                dataRow.companyName,
                dataRow.addendumConfirm ? 'X' : '',
                dataRow['confirm-date-column'] || '',
                get(dataRow, 'addendumConfirm.user.displayName') || '',
            ];
        });

        return [headers].concat(rows);
    };

    render() {
        const { proposals, title } = this.props;

        if (proposals.length === 0) {
            return (
                <div>
                    <em>No responses have been started yet</em>
                </div>
            );
        }

        const columns = [
            {
                Header: 'Response',
                accessor: 'companyName',
                Cell: (props) => {
                    const isSubmitted = props.original.status === PUBLISHED;
                    return (
                        <div>
                            {props.value || 'Unnamed Company'}
                            {!isSubmitted && (
                                <div className={this.styles.notPublished}>Not Submitted Yet</div>
                            )}
                        </div>
                    );
                },
            },
            {
                Header: 'Confirmed',
                accessor: 'addendumConfirm',
                className: 'text-center',
                maxWidth: 100,
                Cell: (props) => {
                    if (props.value) {
                        return <i className="fa fa-check" />;
                    }
                    return null;
                },
            },
            {
                Header: 'Confirmed At',
                id: 'confirm-date-column',
                accessor: (row) => {
                    const confirmedAt = get(row, 'addendumConfirm.created_at');
                    if (confirmedAt) {
                        return moment(confirmedAt).format('lll');
                    }
                    return null;
                },
            },
            {
                Header: 'Confirmed By',
                accessor: 'addendumConfirm.user.displayName',
            },
        ];

        return (
            <DataTable
                className="-highlight -striped"
                columns={columns}
                csvExportOptions={{
                    fileName: title,
                    getFormattedCSVData: this.formatDataForCSVExport,
                    headers: true,
                }}
                data={proposals}
                pageSize={proposals.length}
                showCSVExport
                showPagination={false}
                title={title}
            />
        );
    }
}
