import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { questionStatusTypes } from '@og-pro/shared-config/questions';

import { Tooltip } from '../../../Tooltip';
import { UserProfilePicture } from '../../../UserProfilePicture/UserProfilePicture';

const { DRAFT, RELEASED } = questionStatusTypes;

export class GovQuestionItem extends PureComponent {
    static propTypes = {
        active: PropTypes.bool,
        question: PropTypes.shape({
            assignedUser: PropTypes.object,
            id: PropTypes.number.isRequired,
            isApproved: PropTypes.bool.isRequired,
            number: PropTypes.number,
            status: PropTypes.string.isRequired,
            subject: PropTypes.string.isRequired,
        }).isRequired,
        selectedNumber: PropTypes.number,
    };

    get styles() {
        return require('./shared.scss');
    }

    get questionData() {
        const { question } = this.props;

        switch (question.status) {
            case DRAFT:
                return ['pencil-square-o', 'Response needs to be reviewed and released'];
            case RELEASED:
                return ['check-square text-success', 'Response has been publicly released'];
            default:
                if (question.isApproved) {
                    return ['square-o', 'No response has been provided'];
                }
                return ['square-o', 'Question is awaiting approval'];
        }
    }

    render() {
        const {
            active, // Just to prevent from being passed into <li>
            question,
            selectedNumber,
            ...props
        } = this.props;

        const [icon, tooltip] = this.questionData;

        return (
            <li
                className={classnames(
                    this.styles.questionLink,
                    selectedNumber &&
                        (selectedNumber === question.number || selectedNumber === question.id) &&
                        this.styles.questionActive
                )}
                {...props}
            >
                {question.assignedUser && (
                    <div className={this.styles.questionPicture}>
                        <UserProfilePicture showTooltip user={question.assignedUser} />
                    </div>
                )}
                <Tooltip delayShow={500} placement="bottom" tooltip={tooltip}>
                    <div className={this.styles.questionText}>
                        <i className={`fa fa-lg fa-fw fa-${icon}`} />
                        &nbsp;
                        {question.isApproved ? (
                            `${question.number}.`
                        ) : (
                            <i className="fa fa-exclamation-triangle text-danger" />
                        )}
                        &nbsp;
                        {question.subject}
                    </div>
                </Tooltip>
            </li>
        );
    }
}
