const { listToDict } = require('../helpers');

const statuses = listToDict(['pending', 'processing', 'completed', 'errored', 'cancelled']);
const providers = listToDict(['docusign']);

const getAnchorString = (key) => `**signature_${key}**`;
const getDateSignedAnchorString = (key) => `**date_signed_${key}**`;

const EVENT_TYPES = {
    CREATED: 'created',
    CANCELLED: 'cancelled',
    READY: 'ready',
    ERRORED: 'errored',
};

const DOCUSIGN_EVENT_TYPES = {
    RECIPIENT_SENT: 'recipient-sent',
    ENVELOPE_SENT: 'envelope-sent',
    RECIPIENT_DELIVERED: 'recipient-delivered',
    ENVELOPE_DELIVERED: 'envelope-delivered',
    RECIPIENT_COMPLETED: 'recipient-completed',
    ENVELOPE_COMPLETED: 'envelope-completed',
};

// https://developers.docusign.com/docs/esign-rest-api/reference/envelopes/enveloperecipients/list/#schema_200_enveloperecipients_enveloperecipients_signers_status
const DOCUSIGN_SIGNER_STATUSES = {
    CREATED: 'created',
    SENT: 'sent',
    DELIVERED: 'delivered',
    SIGNED: 'signed',
    DECLINED: 'declined',
    COMPLETED: 'completed',
};

module.exports = {
    DOCUSIGN_EVENT_TYPES,
    DOCUSIGN_SIGNER_STATUSES,
    EVENT_TYPES,
    getAnchorString,
    getDateSignedAnchorString,
    providers,
    statuses,
};
