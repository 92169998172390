import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { truncate } from 'lodash';

import { Button } from '../../Button';

export class DocumentViewerControls extends PureComponent {
    static propTypes = {
        downloadUrl: PropTypes.string.isRequired,
        prevRoute: PropTypes.string,
        nextRoute: PropTypes.string,
        prevRouteName: PropTypes.string,
        nextRouteName: PropTypes.string,
    };

    get styles() {
        return require('./DocumentViewerControls.scss');
    }

    renderPrevButton() {
        const { prevRoute, prevRouteName } = this.props;

        if (prevRoute) {
            return (
                <Button bsStyle="link" to={prevRoute}>
                    <i className="fa fa-angle-double-left fa-lg" />
                    &nbsp;
                    {truncate(prevRouteName, { length: 34 })}
                </Button>
            );
        }
    }

    renderNextButton() {
        const { nextRoute, nextRouteName } = this.props;

        if (nextRoute) {
            return (
                <Button bsStyle="link" to={nextRoute}>
                    {truncate(nextRouteName, { length: 34 })}
                    &nbsp;
                    <i className="fa fa-angle-double-right fa-lg" />
                </Button>
            );
        }
    }

    render() {
        const { downloadUrl } = this.props;
        return (
            <div className={`row ${this.styles.container}`}>
                <div className="col-sm-5">{this.renderPrevButton()}</div>
                <div className="col-sm-2 text-center">
                    <Button bsSize="sm" href={downloadUrl}>
                        <i className="fa fa-download" /> Download
                    </Button>
                </div>
                <div className="col-sm-5 text-right">{this.renderNextButton()}</div>
            </div>
        );
    }
}
