import React, { useCallback } from 'react';
import { Dialog, Box } from '@og-pro/ui';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { InputText, LoadingSpinner, SearchSelect } from '../../../../components';
import { getContractAttachmentTagSelectOptions } from '../../../selectors';
import { noTagsMessage } from '../../../../components/Forms/ContractForm/constants';
import { fieldNames } from '../constants';

const { FILENAME, TAGS, NOTES } = fieldNames;

const ModalAttachmentComponent = ({
    actions,
    open,
    onClose,
    handleSubmit,
    invalid,
    reset,
    submitting,
    title,
}) => {
    const attachmentTags = useSelector(getContractAttachmentTagSelectOptions);

    const handleClose = useCallback(() => {
        reset();
        onClose();
    }, []);

    if (!open) {
        return null;
    }

    return (
        <Dialog
            allowOverflowedContent
            dialogTitle={title}
            fullWidth
            maxWidth="sm"
            onClose={handleClose}
            open
            scroll="body"
        >
            <Box component="form" onSubmit={handleSubmit}>
                <Field
                    aria-label="Edit File Name"
                    component={InputText}
                    disabled={submitting}
                    hasFeedback={false}
                    label="Edit File Name"
                    minRows={1}
                    name={FILENAME}
                    placeholder="Enter Text"
                    qaTag="modalAttachments-filename"
                    type="textarea"
                    useOpenGovStyle
                />
                <Field
                    aria-label="Contract Document Type"
                    component={SearchSelect}
                    disabled={submitting}
                    isClearable
                    label="Select Document Type"
                    menuPortalTarget={document?.body}
                    name={TAGS}
                    noOptionsMessage={noTagsMessage}
                    options={attachmentTags}
                    placeholder="Document Type"
                    qaTag="modalAttachments-type"
                    useOpenGovStyle
                />
                <Field
                    aria-label="Edit Notes"
                    component={InputText}
                    disabled={submitting}
                    hasFeedback={false}
                    label="Notes"
                    minRows={3}
                    name={NOTES}
                    placeholder="Enter Text"
                    qaTag="modalAttachments-notes"
                    type="textarea"
                    useOpenGovStyle
                />
                {!!actions && React.createElement(actions, { handleClose, invalid, submitting })}
            </Box>
            {submitting && (
                <Box mt={2}>
                    <LoadingSpinner noPadding size="small" useOpenGovStyle />
                </Box>
            )}
        </Dialog>
    );
};

ModalAttachmentComponent.propTypes = {
    actions: PropTypes.node.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool,
    title: PropTypes.string,
    reset: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
};

const formConfig = {
    form: 'editContractAttachment',
    validate: (values) => {
        const errors = {};
        if (!values[FILENAME]) {
            errors[FILENAME] = 'File Name is required';
        }
        return errors;
    },
    destroyOnUnmount: true,
};

export const ModalAttachment = reduxForm(formConfig)(ModalAttachmentComponent);

ModalAttachment.propTypes = {
    actions: PropTypes.node.isRequired,
    initialValues: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    open: PropTypes.bool,
    title: PropTypes.string,
};
