import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { QuestionnaireResponseDisplay } from '../Questionnaire/QuestionnaireResponseDisplay';

export class QuestionnaireResponseRenderer extends Component {
    static propTypes = {
        isPricingSealed: PropTypes.bool.isRequired,
        params: PropTypes.shape({
            value: PropTypes.object.isRequired,
        }).isRequired,
    };

    render() {
        const { isPricingSealed, params } = this.props;

        return (
            <QuestionnaireResponseDisplay
                {...params.value}
                isPricingSealed={isPricingSealed}
                responseOnly
            />
        );
    }
}
