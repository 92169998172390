import { ReduxFormSelect } from '@og-pro/ui';
import React from 'react';
import { Field } from 'redux-form';

import {
    customFieldFields,
    instructionsModes,
} from '@og-pro/shared-config/customFormService/customField';
import PropTypes from 'prop-types';

const { NAME, INSTRUCTIONS_MODE, INSTRUCTIONS_TEXT } = customFieldFields;
const { TOOLTIP, DESCRIPTION } = instructionsModes;
export const DropdownPreview = ({ customFieldDefinition }) => {
    const instructionsText = customFieldDefinition[INSTRUCTIONS_TEXT] || '';

    return (
        <Field
            component={ReduxFormSelect}
            description={
                customFieldDefinition[INSTRUCTIONS_MODE] === DESCRIPTION
                    ? instructionsText
                    : undefined
            }
            label={customFieldDefinition[NAME] || 'Your title will go here'}
            name="dropdown_PREVIEW"
            placeholder="Select"
            readOnly
            sx={{
                width: '100%',
            }}
            tooltip={
                customFieldDefinition[INSTRUCTIONS_MODE] === TOOLTIP ? instructionsText : undefined
            }
        />
    );
};

DropdownPreview.propTypes = {
    customFieldDefinition: PropTypes.object,
};
