import { TabsNavigation, Typography } from '@og-pro/ui';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { UnderlineNav } from '../../UnderlineNav';

const Title = ({ title, useOpenGovStyle }) => {
    if (useOpenGovStyle) {
        return <Typography variant="h2">{title}</Typography>;
    }
    return <h2>{title}</h2>;
};

Title.propTypes = {
    title: PropTypes.string.isRequired,
    useOpenGovStyle: PropTypes.bool,
};

const Nav = ({
    location: { pathname, query },
    navItems,
    selectedNavItem,
    title,
    useOpenGovStyle,
}) => {
    const navigate = useNavigate();
    const styles = require('./index.scss');

    if (useOpenGovStyle) {
        const tabs = navItems.map(({ counter, name, status }) => {
            return {
                count: counter,
                label: name,
                qaTag: `${title}-${name}`,
                status,
                route: {
                    pathname,
                    search: createSearchParams({ ...query, status }).toString(),
                },
            };
        });
        const activeTab = tabs.find(({ status }) => status === selectedNavItem.status) || tabs[0];
        return (
            <TabsNavigation
                defaultTab={activeTab}
                redirectFn={navigate}
                sx={{ marginBottom: 2.5 }}
                tabs={tabs}
            />
        );
    }

    return (
        <UnderlineNav className={styles.navItems}>
            {navItems.map((item) => {
                const { counter, name, status } = item;
                return (
                    <UnderlineNav.NavItem
                        className={classnames({
                            active: status === selectedNavItem.status,
                        })}
                        key={status}
                        qaTag={`${title}-${name}`}
                        to={{
                            pathname,
                            search: createSearchParams({ ...query, status }).toString(),
                        }}
                    >
                        {name}&nbsp;
                        <span className={styles.counter}>({counter})</span>
                    </UnderlineNav.NavItem>
                );
            })}
        </UnderlineNav>
    );
};

const navItemType = PropTypes.shape({
    counter: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
}).isRequired;

Nav.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
        query: PropTypes.object.isRequired,
    }).isRequired,
    navItems: PropTypes.arrayOf(navItemType).isRequired,
    selectedNavItem: navItemType,
    title: PropTypes.string.isRequired,
    useOpenGovStyle: PropTypes.bool,
};

export const TemplateListNav = ({
    createError,
    location,
    navItems,
    selectedNavItem,
    SelectFilter,
    TemplateCreateButton,
    title,
    useOpenGovStyle,
}) => {
    const styles = require('./index.scss');
    return (
        <>
            <div className={classnames('row', styles.titleContainer)}>
                <div className="col-sm-6">
                    <Title title={title} useOpenGovStyle={useOpenGovStyle} />
                </div>
                <div className={`col-sm-6 ${styles.buttonContainer}`}>
                    <TemplateCreateButton />
                    {createError && <div className="error-block">{createError}</div>}
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12 col-sm-8">
                    <Nav
                        location={location}
                        navItems={navItems}
                        selectedNavItem={selectedNavItem}
                        title={title}
                        useOpenGovStyle={useOpenGovStyle}
                    />
                </div>
                <div className="col-xs-12 col-sm-4 text-right">{SelectFilter}</div>
            </div>
        </>
    );
};

TemplateListNav.propTypes = {
    createError: PropTypes.string,
    location: PropTypes.object.isRequired,
    navItems: PropTypes.arrayOf(navItemType).isRequired,
    selectedNavItem: navItemType,
    SelectFilter: PropTypes.node,
    TemplateCreateButton: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    useOpenGovStyle: PropTypes.bool,
};
