import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { get } from 'lodash';
import { Box } from '@og-pro/ui';

import { ATTACHMENTS, DATA } from '@og-pro/shared-config/questionnaires';

import { FileUploadAttachment } from '../../../../FileUploadAttachment';
import { StyledDropzone } from '../../../../Dropzone/StyledDropzone';
import { UploadProgress } from '../../../../UploadProgress';

export class QuestionnaireFileUploadForm extends Component {
    static propTypes = {
        accept: PropTypes.string,
        disabled: PropTypes.bool,
        dropzoneIcons: PropTypes.array,
        dropzoneText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        input: PropTypes.shape({
            onChange: PropTypes.func.isRequired,
            value: PropTypes.oneOfType([
                PropTypes.shape({
                    attachments: PropTypes.array,
                    data: PropTypes.object,
                }),
                PropTypes.string, // redux-form defaults no value to empty string
            ]),
        }).isRequired,
        isOGThemeEnabledForComponents: PropTypes.bool,
        isTemplateReadonlyView: PropTypes.bool,
        label: PropTypes.string,
        meta: PropTypes.shape({
            error: PropTypes.string,
            touched: PropTypes.bool.isRequired,
        }).isRequired,
        showValidation: PropTypes.bool,
        updateNestedData: PropTypes.bool,
        uploadHandler: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            uploading: false,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    get attachments() {
        return get(this.props.input, `value.${ATTACHMENTS}`) || [];
    }

    uploadHandler = (fileData) => {
        if (!fileData || !fileData[0]) {
            return null;
        }

        const [file] = fileData;

        const {
            input: { onChange, value },
            uploadHandler,
        } = this.props;

        const onProgress = (progress) => this.setState({ uploadProgress: progress });

        // Set initial state for starting upload
        this.setState({
            uploading: true,
            uploadError: null,
            uploadFilename: file.name,
            uploadProgress: 0,
        });

        return uploadHandler(file, onProgress, value)
            .then((result) => {
                // Update the form data with the saved questionnaireResponse and attachments data
                onChange(result);

                // Reset the upload data
                this.setState({
                    uploading: false,
                    uploadFilename: null,
                    uploadProgress: null,
                });
            })
            .catch((error) => {
                this.setState({
                    uploading: false,
                    uploadError: error.message || 'Unknown error occurred',
                });
            });
    };

    deleteHandler = (attachmentToDelete) => {
        const {
            input: { onChange, value },
            updateNestedData,
        } = this.props;

        const remainingAttachments = this.attachments.filter((attachment) => {
            return attachment.path !== attachmentToDelete.path;
        });

        // Only change the attachments` fields on deletion. Wiping the `id` field will
        // cause a new item to be created (API will create new items when there are no IDs)
        const updatedQuestionnaireData = {
            ...value,
            attachments: remainingAttachments,
        };

        if (updateNestedData) {
            // Also changes the nested `data` field if option is specified
            updatedQuestionnaireData[DATA] = {
                [ATTACHMENTS]: remainingAttachments,
            };
        }

        onChange(updatedQuestionnaireData);
    };

    renderUploadList() {
        const { uploadFilename, uploadProgress, uploadError } = this.state;

        if (!uploadFilename) {
            return null;
        }

        return (
            <div>
                <UploadProgress
                    error={uploadError}
                    filename={uploadFilename}
                    progress={uploadProgress}
                />
            </div>
        );
    }

    renderUploadBlock() {
        const {
            accept,
            disabled,
            dropzoneIcons,
            dropzoneText,
            meta: { error, touched },
            showValidation,
        } = this.props;
        const { uploading } = this.state;
        const showError = (touched || showValidation) && !!error;

        return (
            <div>
                {this.renderUploadList()}
                {!uploading && (
                    <StyledDropzone
                        accept={accept}
                        disabled={disabled}
                        icons={dropzoneIcons}
                        label="File"
                        multiple={false}
                        onDrop={this.uploadHandler}
                        text={dropzoneText}
                    />
                )}
                {showError && <div className="error-block">{error}</div>}
            </div>
        );
    }

    renderUploadedAttachments() {
        const { disabled } = this.props;

        return this.attachments.map((attachment) => (
            <FileUploadAttachment
                attachment={attachment}
                deleteHandler={this.deleteHandler}
                disabled={disabled}
                key={attachment.path}
                useListItemStyle
            />
        ));
    }

    render() {
        const { disabled, label, isOGThemeEnabledForComponents, isTemplateReadonlyView } =
            this.props;

        if (isOGThemeEnabledForComponents) {
            if (disabled && isTemplateReadonlyView) {
                return (
                    <Box className={this.styles.readOnly}>
                        <Box className={this.styles.label}>Upload Here:</Box>
                        <Box className={this.styles.prompt}>
                            Vendors will be able to upload documents here.
                        </Box>
                    </Box>
                );
            }
        }

        return (
            <div>
                {label && (
                    <label>
                        <small>{label}</small>
                    </label>
                )}
                {this.renderUploadedAttachments()}
                {this.renderUploadBlock()}
            </div>
        );
    }
}
