import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import { getVizColors } from '@opengov/capital-style';
import { Box, Typography } from '@og-pro/ui';
import { InfoOutlined } from '@mui/icons-material';

import { formatPercent } from '../../helpers/utils';
import { CDSButton } from '../../SDv2';
import { Tooltip } from '../../Tooltip';

export const Legend = ({ mobile, vendorCertificationsTotalsChartData }) => {
    const styles = require('./index.scss');

    const [showMore, setShowMore] = useState(false);

    const colors = getVizColors(vendorCertificationsTotalsChartData.length).map(
        (color) => color.base
    );

    const renderShowMoreButton = () => (
        <CDSButton
            className={styles.showMoreButton}
            onClick={() => {
                setShowMore(!showMore);
            }}
            qaTag="subsribedVendorCertificationsChart-showMore"
            size="small"
            variant="text"
        >
            {showMore ? (
                <>
                    Show Less <i className="fa fa-angle-up" />
                </>
            ) : (
                <>
                    Show More <i className="fa fa-angle-down" />
                </>
            )}
        </CDSButton>
    );

    return mobile ? (
        <Box className={styles.legend}>
            {vendorCertificationsTotalsChartData
                .filter((certification, index) => (showMore ? true : index < 3))
                .map((certification, index) => (
                    <Box
                        className={styles.mobileLegendContainer}
                        display="flex"
                        key={certification.title}
                    >
                        <i className="fa fa-circle" style={{ color: colors[index] }} />
                        <Box
                            className={styles.mobileLegendDetails}
                            display="flex"
                            flexDirection="column"
                        >
                            <Typography variant="h5">{certification.name}</Typography>
                            <Typography>{`${certification.y} Vendors (${formatPercent(
                                Math.round(certification.percent * 100)
                            )} of Total Vendors)`}</Typography>
                        </Box>
                    </Box>
                ))}
            {vendorCertificationsTotalsChartData.length > 3 && renderShowMoreButton()}
        </Box>
    ) : (
        <Box className={styles.legend}>
            <Table className={styles.table}>
                <thead>
                    <tr className={styles.headerRow}>
                        <th className={styles.typeColumn}>
                            <Typography variant="h5">
                                <i className="fa fa-circle" style={{ color: '#fff' }} />
                                Certification Type
                            </Typography>
                        </th>
                        <th className={styles.numberColumn}>
                            <Typography variant="h5">Number of Vendors</Typography>
                        </th>
                        <th className={styles.percentColumn}>
                            <Typography variant="h5">% of Total Vendors</Typography>
                            <Tooltip
                                placement="bottom"
                                tooltip="This shows the certification's percentage across all vendors subscribed to your organization."
                            >
                                <InfoOutlined fontSize="small" />
                            </Tooltip>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {vendorCertificationsTotalsChartData
                        .filter((certification, index) => (showMore ? true : index < 10))
                        .map((certification, index) => (
                            <tr className={styles.legendRow} key={certification.title}>
                                <td className={styles.typeColumn}>
                                    <i className="fa fa-circle" style={{ color: colors[index] }} />
                                    {certification.name}
                                </td>
                                <td className={styles.numberColumn}>{certification.y}</td>
                                <td className={styles.percentColumn}>
                                    {formatPercent(Math.round(certification.percent * 100))}
                                </td>
                            </tr>
                        ))}
                </tbody>
            </Table>
            {vendorCertificationsTotalsChartData.length > 10 && renderShowMoreButton()}
        </Box>
    );
};

Legend.propTypes = {
    mobile: PropTypes.bool.isRequired,
    vendorCertificationsTotalsChartData: PropTypes.arrayOf(
        PropTypes.shape({
            color: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            percent: PropTypes.number.isRequired,
        })
    ).isRequired,
};
