/* global Dynamsoft */
import { change, Field, getFormValues } from 'redux-form';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { SCANNER_CONTROL_SECTION, Section } from './ScanningControlsPanel';
import { Checkbox } from '../../Checkbox/Checkbox';
import { SearchSelect } from '../../SearchSelect/SearchSelect';
import { fieldNames, NO_SOURCES_FOUND_MESSAGE, RETRY_TIMEOUT } from '../constants';
import { formConfig } from '../form';

const { SCAN_SOURCE, USE_ADF, DUPLEX, BLANK_PAGE_DETECTION, COLOR_MODE, RESOLUTION } = fieldNames;

export const ScannerControlsSection = ({ DWObject, onReadinessChange }) => {
    const styles = require('../index.scss');

    const dispatch = useDispatch();
    const loadingSourcesRetry = useRef(null);

    const { [SCAN_SOURCE]: selectedSource } = useSelector(
        (state) => getFormValues(formConfig.form)(state) || {}
    );

    const [selectedResolution, setSelectedResolution] = useState(null);
    const [availableResolutions, setAvailableResolutions] = useState([]);
    const [availableScanningSources, setAvailableScanningSources] = useState([]);
    const [sourceListLoadError, setSourceListLoadError] = useState(null);

    const [loadingResolutions, setLoadingResolutions] = useState(false);

    const [isDWTBusy, setIsDWTBusy] = useState(false);

    useEffect(() => {
        onReadinessChange(isDWTBusy, sourceListLoadError);
    }, [isDWTBusy, onReadinessChange, sourceListLoadError]);

    const loadScanSourcesSelectOptions = useCallback(async () => {
        try {
            setSourceListLoadError(null);
            return await DWObject.GetSourceNamesAsync().then((sources) => {
                const formattedSources = sources.map((source, index) => {
                    return {
                        label: source,
                        value: index,
                    };
                });
                setAvailableScanningSources(formattedSources);
                if (formattedSources.length === 1) {
                    dispatch(change(formConfig.form, SCAN_SOURCE, formattedSources[0].value));
                }
                if (formattedSources.length === 0) {
                    setSourceListLoadError(NO_SOURCES_FOUND_MESSAGE);
                    loadingSourcesRetry.current = setTimeout(() => {
                        loadScanSourcesSelectOptions();
                    }, RETRY_TIMEOUT);
                }
            });
        } catch (e) {
            setSourceListLoadError(' - Error loading sources');
            return [];
        }
    }, [DWObject, dispatch]);

    useEffect(() => {
        if (DWObject) {
            Dynamsoft.DWT.OnWebTwainPreExecute = function () {
                setIsDWTBusy(true);
            };
            Dynamsoft.DWT.OnWebTwainPostExecute = function () {
                if (!loadingResolutions) {
                    setIsDWTBusy(false);
                }
            };

            loadScanSourcesSelectOptions();
        }
    }, [DWObject, loadScanSourcesSelectOptions, loadingResolutions]);

    useEffect(() => {
        if (Number.isInteger(selectedSource)) {
            if (!DWObject || !Number.isInteger(selectedSource)) {
                setAvailableResolutions([]);
            }
            setLoadingResolutions(true);
            setIsDWTBusy(true);
            try {
                DWObject.getCapabilities(function (result) {
                    const resolutionCapability = result.find(
                        (capability) =>
                            capability.capability.value ===
                            Dynamsoft.DWT.EnumDWT_Cap.ICAP_XRESOLUTION
                    ); // The scanner capability that holds the available resolutions

                    // TWON_ENUMERATION: The capability has a list of possible values. The values are
                    // mapped to the list of possible values.
                    if (resolutionCapability.conType.label === 'TWON_ENUMERATION') {
                        const resolutions = resolutionCapability.values.map((value) => ({
                            label: `${value.toString()}dpi`,
                            value,
                        }));
                        setSelectedResolution(resolutions[0]);
                        dispatch(change(formConfig.form, RESOLUTION, resolutions[0].value));
                        setAvailableResolutions(resolutions);
                        // TWON_RANGE: The capability has a range of possible values, plus a step size.
                        // The values are mapped to the range of possible values.
                    } else if (resolutionCapability.conType.label === 'TWON_RANGE') {
                        const resolutions = [];
                        for (
                            let i = resolutionCapability.minValue;
                            i <= resolutionCapability.maxValue;
                            i += resolutionCapability.stepSize
                        ) {
                            resolutions.push({
                                label: `${i.toString()}dpi`,
                                value: i,
                            });
                        }
                        setSelectedResolution(resolutions[0]);
                        setAvailableResolutions(resolutions);
                        dispatch(change(formConfig.form, RESOLUTION, resolutions[0].value));
                        // if no range or enumeration is found, set the available resolutions to an empty array
                    } else {
                        setAvailableResolutions([]);
                    }

                    setLoadingResolutions(false);
                    setIsDWTBusy(false);
                });
            } catch (e) {
                setAvailableResolutions([]);
            }
        }
    }, [selectedSource]);

    useEffect(() => {
        return () => {
            clearTimeout(loadingSourcesRetry.current);
        };
    }, []);

    return (
        <Section id={SCANNER_CONTROL_SECTION} title="Scanner Controls">
            <Field
                component={SearchSelect}
                disabled={!DWObject}
                isClearable={false}
                label={`Scanner${sourceListLoadError || ''}`}
                name={SCAN_SOURCE}
                options={availableScanningSources}
                placeholder="Search sources"
                qaTag="scanning-source"
                useOpenGovStyle
            />
            <div className={styles.checkboxGroup}>
                <Field
                    component={Checkbox}
                    disabled={!DWObject}
                    formGroupClass={styles.checkbox}
                    inline
                    label="Auto Feed"
                    name={USE_ADF}
                    qaTag="scanning-useAdf"
                    useOpenGovStyle
                />
                <Field
                    component={Checkbox}
                    disabled={!DWObject}
                    formGroupClass={styles.checkbox}
                    inline
                    label="2-Sided Scan"
                    name={DUPLEX}
                    qaTag="scanning-duplex"
                    useOpenGovStyle
                />
            </div>
            <Field
                component={Checkbox}
                disabled={!DWObject}
                formGroupClass={styles.checkbox}
                inline
                label="Auto Remove Blank Pages"
                name={BLANK_PAGE_DETECTION}
                qaTag="scanning-blankPageDetection"
                useOpenGovStyle
            />

            <Field
                component={SearchSelect}
                disabled={!DWObject}
                label="Color Mode"
                name={COLOR_MODE}
                options={[
                    {
                        label: 'Color',
                        value: Dynamsoft.DWT.EnumDWT_PixelType.TWPT_RGB,
                    },
                    {
                        label: 'Gray',
                        value: Dynamsoft.DWT.EnumDWT_PixelType.TWPT_GRAY,
                    },
                    {
                        label: 'Black and White',
                        value: Dynamsoft.DWT.EnumDWT_PixelType.TWPT_BW,
                    },
                ]}
                placeholder="Select color mode"
                qaTag="scanning-color"
                useOpenGovStyle
            />
            <Field
                component={SearchSelect}
                disabled={!DWObject}
                isClearable={false}
                label="Resolution"
                name={RESOLUTION}
                options={availableResolutions}
                placeholder={loadingResolutions ? 'Loading...' : 'Select resolution'}
                qaTag="scanning-resolution"
                selectedOption={selectedResolution}
                useOpenGovStyle
            />
        </Section>
    );
};

ScannerControlsSection.propTypes = {
    DWObject: PropTypes.object,
    onReadinessChange: PropTypes.func.isRequired,
};
