import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ListGroup, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import { EvaluationPhaseSelectItem } from '../../Evaluations/EvaluationPhaseSelectItem';
import { Button } from '../../../Button';
import { getProjectJS } from '../../../../containers/GovApp/selectors';

const mapStateToProps = (state) => {
    const project = getProjectJS(state);
    return {
        evaluationPhases: get(project, 'evaluationPhases') || [],
    };
};

const ConnectedEvaluationPhaseSelectModal = ({ evaluationPhases, hideModal, onSelect }) => {
    const [selectedEvaluationPhase, setSelectedEvaluationPhase] = useState();
    const hasSelection = selectedEvaluationPhase !== undefined;
    const styles = require('./EvaluationPhaseSelectModal.scss');

    return (
        <Modal onHide={hideModal} show>
            <Modal.Header closeButton>
                <Modal.Title className="text-center">
                    Add Criteria From Evaluation Phase
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4 className={styles.header}>
                    Which pre-defined phase would you like to add criteria from?
                </h4>
                <ListGroup>
                    {evaluationPhases.map((evaluationPhase) => (
                        <EvaluationPhaseSelectItem
                            clickHandler={() => setSelectedEvaluationPhase(evaluationPhase)}
                            evaluationPhase={evaluationPhase}
                            isSelected={get(selectedEvaluationPhase, 'id') === evaluationPhase.id}
                            key={evaluationPhase.id}
                        />
                    ))}
                </ListGroup>
                <div className="text-center">
                    <Button
                        bsStyle="primary"
                        disabled={!hasSelection}
                        onClick={() => {
                            onSelect(selectedEvaluationPhase);
                            hideModal();
                        }}
                        qaTag="evaluationPhaseSelectModal-add"
                    >
                        <i className="fa fa-plus" /> Add{' '}
                        {selectedEvaluationPhase &&
                            `${selectedEvaluationPhase.scoringCriteria.length} `}
                        Criteria from Evaluation Phase
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

ConnectedEvaluationPhaseSelectModal.propTypes = {
    evaluationPhases: PropTypes.array.isRequired,
    hideModal: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
};

export const EvaluationPhaseSelectModal = connect(mapStateToProps)(
    ConnectedEvaluationPhaseSelectModal
);
