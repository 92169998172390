import { round, isNumber } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { HtmlContent } from '../../HtmlContent/HtmlContent';

export class EvaluationCriteriaListDisplay extends PureComponent {
    static propTypes = {
        criteria: PropTypes.arrayOf(
            PropTypes.shape({
                isHiddenByLogic: PropTypes.bool,
                isPubliclyHidden: PropTypes.bool.isRequired,
            })
        ).isRequired,
        totalWeight: PropTypes.number.isRequired,
    };

    render() {
        const { criteria: scoringCriteria, totalWeight } = this.props;
        const EvaluationCriteria = scoringCriteria
            .filter((scoringCriterium) => !scoringCriterium.isHiddenByLogic)
            .map(({ description, id, isPubliclyHidden, scoringMethodText, title, weight }, idx) => (
                <li className="evaluation-criteria-list-item" key={id}>
                    <div>
                        <h4 className="evaluation-criteria-title">
                            {idx + 1}. {title}
                        </h4>
                        <HtmlContent content={description} />
                    </div>
                    {!isPubliclyHidden && (
                        <dl className="dl-horizontal evaluation-criteria-scoring-method">
                            <dt>Scoring Method:</dt>
                            <dd>{scoringMethodText}</dd>
                            <dt>Weight (Points):</dt>
                            <dd>
                                {isNumber(weight) && (
                                    <span>
                                        {weight}
                                        &nbsp;&nbsp;
                                        <small>
                                            (
                                            <i>
                                                {round((weight / totalWeight) * 100, 1)}% of Total
                                            </i>
                                            )
                                        </small>
                                    </span>
                                )}
                            </dd>
                        </dl>
                    )}
                </li>
            ));

        return <ul className="list-unstyled">{EvaluationCriteria}</ul>;
    }
}
