import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AlternateEmail } from '@mui/icons-material';
import { Box, Button, Tab, Tabs, Typography } from '@og-pro/ui';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';

import { VendorPortalInviteModal } from '../VendorPortalInviteModal';
import { useRouteMatch } from '../../../hooks/routerHooks';

export const VendorManagementHeader = ({ governmentId, portalUrl, showNewVendorTable }) => {
    const [showInviteModal, setShowInviteModal] = useState(false);

    const basePath = `/governments/${governmentId}/vendors`;
    const navItems = [
        {
            label: 'All Vendors',
            link: basePath,
            type: 'all-vendors-menu-item',
        },
        {
            label: 'Vendor Lists',
            link: `${basePath}/lists`,
            type: 'vendor-lists-menu-item',
        },
        {
            label: 'Vendor Certifications',
            link: `${basePath}/certifications`,
            type: 'vendor-certifications-menu-item',
        },
        {
            label: 'Manage Invites',
            link: `${basePath}/invited`,
            type: 'vendor-invited-menu-item',
        },
    ];

    const routeMatch = useRouteMatch([
        `${basePath}/lists/*`,
        `${basePath}/certifications/*`,
        `${basePath}/invited/*`,
        `${basePath}`,
    ]);
    const currentTab = routeMatch?.pathnameBase;

    const renderVendorInstructions = (
        <p>
            {showNewVendorTable
                ? 'List of vendor contacts subscribed to sourcing opportunities.'
                : 'List of vendors subscribed to sourcing opportunities.'}
            <br />
            {showNewVendorTable
                ? 'Vendor contacts can subscribe by visiting '
                : 'Vendors can subscribe by visiting '}
            <a
                href={portalUrl}
                rel="noreferrer noopener"
                style={{
                    color: capitalDesignTokens.semanticColors.foreground.secondary,
                }}
                target="_blank"
            >
                {portalUrl}
            </a>
            &nbsp;and clicking the &quot;Subscribe&quot; button.
        </p>
    );

    return (
        <Box
            pt={3}
            px={7}
            sx={{
                borderBottom: `1px solid ${capitalDesignTokens.semanticColors.border.secondary}`,
            }}
        >
            <Box display="flex">
                <Box flexGrow={1}>
                    <Typography variant="h1">Vendor Management</Typography>
                    <Typography color={capitalDesignTokens.semanticColors.foreground.secondary}>
                        {renderVendorInstructions}
                    </Typography>
                </Box>
                <Box minWidth="130px">
                    <Button
                        onClick={() => setShowInviteModal(true)}
                        qaTag="vendorManagementHeader-inviteVendors"
                        sx={{ height: '32px' }}
                        variant="contained"
                    >
                        <Box alignItems="center" display="flex" gap={0.5}>
                            <AlternateEmail fontSize="14px" />
                            Invite Vendors
                        </Box>
                    </Button>
                </Box>
            </Box>
            <Tabs value={currentTab}>
                {navItems.map(({ label, link, type }) => (
                    <Tab
                        component={Link}
                        key={type}
                        label={label}
                        style={{
                            color: capitalDesignTokens.semanticColors.foreground.action,
                            outline: 'none',
                            textDecoration: 'none',
                        }}
                        to={link}
                        value={link}
                    />
                ))}
            </Tabs>
            {showInviteModal && (
                <VendorPortalInviteModal hideModal={() => setShowInviteModal(false)} />
            )}
        </Box>
    );
};

VendorManagementHeader.propTypes = {
    governmentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    pathname: PropTypes.shape({
        includes: PropTypes.func,
    }).isRequired,
    portalUrl: PropTypes.string.isRequired,
    showNewVendorTable: PropTypes.bool,
};
