import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { v4 as UUIDv4 } from 'uuid';

import { TITLE } from './constants';
import { validate } from './validate';
import { Button } from '../../Button';
import { InputText } from '../../InputText';
import { getFileExtensionIcon } from '../../../helpers';

const formConfig = {
    validate,
};

// @reduxForm
class ConnectedAttachmentUploaderForm extends Component {
    static propTypes = {
        disabled: PropTypes.bool,
        fileExtension: PropTypes.string.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        removeHandler: PropTypes.func.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    uuid = UUIDv4();

    render() {
        const { disabled, fileExtension, handleSubmit, removeHandler } = this.props;

        const fileIcon = getFileExtensionIcon(fileExtension);

        return (
            <form className="row" onSubmit={handleSubmit}>
                <div className="col-xs-12">
                    <div className={this.styles.iconContainer}>
                        <Button
                            bsStyle="link"
                            className={this.styles.removeBtn}
                            disabled={disabled}
                            onClick={removeHandler}
                        >
                            <i className="fa fa-lg fa-times" />
                        </Button>
                        <i className={`fa fa-fw fa-lg fa-${fileIcon}`} />
                    </div>
                    <div className={this.styles.uploadButtonContainer}>
                        <Button
                            block
                            bsSize="sm"
                            bsStyle="primary"
                            disabled={disabled}
                            type="submit"
                        >
                            <i className="fa fa-upload" /> Upload
                        </Button>
                    </div>
                    <div className={this.styles.inputContainer}>
                        <Field
                            component={InputText}
                            disabled={disabled}
                            hasFeedback={false}
                            id={`${TITLE}-${this.uuid}`}
                            name={TITLE}
                            type="text"
                        />
                    </div>
                </div>
            </form>
        );
    }
}

export const AttachmentUploaderForm = reduxForm(formConfig)(ConnectedAttachmentUploaderForm);
