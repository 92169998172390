import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Button } from '../../Button';

export const SectionTitleHeading = ({
    info,
    isOpen,
    number,
    showCheckMark,
    showInfo,
    title,
    toggleHandler,
}) => {
    const styles = require('./index.scss');

    const ClosedTitle = (
        <div className="row">
            <h4
                className={classnames(
                    styles.title,
                    showInfo ? 'col-sm-6 col-md-5 col-lg-4' : 'col-xs-12'
                )}
            >
                <span
                    className={classnames(
                        'fa-stack',
                        styles.icon,
                        showCheckMark && styles.completeIcon
                    )}
                >
                    <i className="fa fa-circle fa-stack-2x" />
                    {showCheckMark ? (
                        <i className={`fa fa-check fa-stack-1x ${styles.iconText}`} />
                    ) : (
                        <strong className={`fa-stack-1x ${styles.iconText}`}>{number}</strong>
                    )}
                </span>
                {title}
            </h4>
            {showInfo && (
                <div className="hidden-xs col-sm-6 col-md-7 col-lg-8 text-muted">{info}</div>
            )}
        </div>
    );

    if (!isOpen) {
        return ClosedTitle;
    }

    return (
        <div className="row">
            <div className="col-xs-9 col-sm-10 col-lg-11">{ClosedTitle}</div>
            <div className="col-xs-3 col-sm-2 col-lg-1 text-right">
                <Button
                    bsSize="sm"
                    className={styles.doneButton}
                    onClick={toggleHandler}
                    qaTag="templateSectionHeading-done"
                >
                    Done
                </Button>
            </div>
        </div>
    );
};

SectionTitleHeading.propTypes = {
    info: PropTypes.node,
    isOpen: PropTypes.bool.isRequired,
    number: PropTypes.number.isRequired,
    showCheckMark: PropTypes.bool.isRequired,
    showInfo: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    toggleHandler: PropTypes.func.isRequired,
};
