import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import { UserName } from './UserName';
import { UserProfilePicture } from '../UserProfilePicture/UserProfilePicture';

export class UserListPopover extends PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        popoverId: PropTypes.string.isRequired,
        showOrganization: PropTypes.bool,
        title: PropTypes.node,
        users: PropTypes.array.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    renderUserList() {
        const { popoverId, showOrganization, title, users } = this.props;

        return (
            <Popover id={popoverId} title={title}>
                <ul className={`list-unstyled ${this.styles.userList}`}>
                    {users.map((user, idx) => (
                        <li className={this.styles.user} key={idx}>
                            <UserProfilePicture
                                className={classnames(
                                    this.styles.profilePicture,
                                    showOrganization && this.styles.showOrganization
                                )}
                                user={user}
                            />
                            <UserName showOrganization={showOrganization} user={user} />
                        </li>
                    ))}
                </ul>
            </Popover>
        );
    }

    render() {
        const { children } = this.props;

        return (
            <OverlayTrigger overlay={this.renderUserList()} placement="bottom">
                {children}
            </OverlayTrigger>
        );
    }
}
