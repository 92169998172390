import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { USE_PROCURATED } from '@og-pro/shared-config/procurated';

import { Link } from '../../Link/Link';
import { ProcuratedStarsDisplay } from '../ProcuratedStarsDisplay';
import { PROCURATED_LOGO_URL } from '../constants';
import { loadProcuratedRating } from '../../../actions/ratings';
import { getProcuratedVendorJS } from '../../../containers/selectors';

export const HorizontalProcuratedBox = ({ supplierId }) => {
    const dispatch = useDispatch();
    const procuratedVendor = useSelector(getProcuratedVendorJS);
    const vendorFound = procuratedVendor && procuratedVendor.matched;
    const hasRating = vendorFound && procuratedVendor.rating;

    const styles = require('./horizontal.scss');

    const ratingText = hasRating
        ? `${procuratedVendor.rating} out of 5`
        : 'No public-sector reviews';
    const ratingTextStyle = hasRating ? styles.ratingText : styles.mutedRatingText;
    const starSizeMultiplier = 4;

    useEffect(() => {
        if (USE_PROCURATED && supplierId) {
            dispatch(loadProcuratedRating({ supplierId }));
        }
    }, [supplierId]);

    if (!USE_PROCURATED || !procuratedVendor) {
        return null;
    }

    const renderReadWriteButtons = () => {
        return (
            <div className={styles.buttonBox}>
                <Link
                    className={`btn btn-primary column ${styles.buttonLeft}`}
                    href={procuratedVendor.profileUrl}
                    qaTag="procuratedBox-readReviews"
                    referrerPolicy="strict-origin"
                    target="_blank"
                >
                    {procuratedVendor && procuratedVendor.reviewCount > 0
                        ? 'Read Reviews '
                        : 'View Profile '}
                    <i className="fa fa-external-link" />
                </Link>
                <div className={styles.column}>
                    <Link
                        className={`btn btn-primary-outline ${styles.buttonRight}`}
                        href={procuratedVendor.writeReviewUrl}
                        qaTag="procuratedBox-writeAReviewSecondary"
                        referrerPolicy="strict-origin"
                        target="_blank"
                    >
                        Write a Review&nbsp;
                        <i className="fa fa-external-link" />
                    </Link>
                    <div className={styles.logoContainer}>
                        <span>Powered by </span>
                        <img
                            alt="Procurated Logo"
                            className={`${styles.logo}`}
                            src={PROCURATED_LOGO_URL}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const renderWriteAReview = () => {
        return (
            <div>
                <Link
                    className={`btn btn-primary ${styles.writeButton}`}
                    href={procuratedVendor.writeReviewUrl}
                    qaTag="procuratedBox-writeAReview"
                    referrerPolicy="strict-origin"
                    target="_blank"
                >
                    Write a Review
                </Link>
            </div>
        );
    };

    return (
        <div className={styles.procuratedBox}>
            <div>
                <div className={styles.starsDisplayContainer}>
                    <ProcuratedStarsDisplay
                        starSizeMultiplier={starSizeMultiplier}
                        value={procuratedVendor.rating}
                    />
                    <div className={ratingTextStyle}>{ratingText}</div>
                </div>
                {!!vendorFound && (
                    <div className={styles.reviews}>
                        {procuratedVendor && procuratedVendor.reviewCount} ratings & reviews from
                        the public-sector
                    </div>
                )}
            </div>
            <div>{vendorFound ? renderReadWriteButtons() : renderWriteAReview()}</div>
        </div>
    );
};

HorizontalProcuratedBox.propTypes = {
    supplierId: PropTypes.number.isRequired,
};
