import React, { useState } from 'react';
import { FLAGS, useFlags } from '@og-pro/launch-darkly/client';

import { TemplateCreateDialog } from './TemplateCreateDialog';

export const useTemplateCreate = (createTemplate) => {
    const [showDialog, setShowDialog] = useState(false);
    const [selectedType, setSelectedType] = useState(null);
    const isTemplateIngestionAiEnabled = useFlags(FLAGS.ENABLE_TEMPLATE_INGESTION_AI);

    const handleCloseDialog = () => {
        setShowDialog(false);
        setSelectedType(null);
    };

    const selectHandler = (type) => {
        if (!isTemplateIngestionAiEnabled) {
            return createTemplate(type);
        }
        setShowDialog(true);
        setSelectedType(type);
    };

    const createFromModal = (routeToAi = false) => {
        handleCloseDialog();
        createTemplate(selectedType, routeToAi);
    };

    const CreateDialog = () => (
        <TemplateCreateDialog
            onClose={handleCloseDialog}
            onCreate={createFromModal}
            open={showDialog}
        />
    );

    return {
        CreateDialog,
        selectHandler,
    };
};
