import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ListGroup, ListGroupItem, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import { getInitiallySelectedQuestionIdsJS, getQuestionsAwaitingReview } from './selectors';
import { Button } from '../../../../Button';
import { LoadingButton } from '../../../../LoadingButton';
import { hideReleaseResponsesModal, releaseResponses } from '../../../../../actions/questions';

const mapStateToProps = (state, props) => {
    return {
        draftQuestions: getQuestionsAwaitingReview(props),
        releaseError: state.questions.get('releaseResponsesError'),
        releasing: state.questions.get('releasingResponses'),
        selectedQuestionIds: getInitiallySelectedQuestionIdsJS(state),
    };
};

const mapDispatchToProps = {
    hideModal: hideReleaseResponsesModal,
    releaseResponses,
};

// @connect
class ConnectedReleaseQuestionResponsesModal extends Component {
    static propTypes = {
        draftQuestions: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
            })
        ).isRequired,
        hideModal: PropTypes.func.isRequired,
        releaseError: PropTypes.string,
        releaseResponses: PropTypes.func.isRequired,
        releasing: PropTypes.bool,
        selectedQuestionIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            selectedQuestionIds: (props.selectedQuestionIds || []).reduce((map, questionId) => {
                map[questionId] = true;
                return map;
            }, {}),
            showConfirmationPage: false,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    cancelConfirmationPage = () => this.setState({ showConfirmationPage: false });

    getSelectedQuestionIds = () => {
        const { selectedQuestionIds } = this.state;

        return Object.keys(selectedQuestionIds)
            .filter((id) => selectedQuestionIds[id])
            .map((id) => Number.parseInt(id, 10));
    };

    releaseResponses = () => {
        this.props.releaseResponses(this.getSelectedQuestionIds());
    };

    selectAll = () => {
        const { draftQuestions } = this.props;
        const selectedQuestionIds = draftQuestions.reduce((map, question) => {
            map[question.id] = true;
            return map;
        }, {});
        this.setState({ selectedQuestionIds });
    };

    showConfirmationPage = () => this.setState({ showConfirmationPage: true });

    toggleSelected = (question) => () => {
        this.setState((prevState) => {
            return {
                selectedQuestionIds: {
                    ...prevState.selectedQuestionIds,
                    [question.id]: !prevState.selectedQuestionIds[question.id],
                },
            };
        });
    };

    unselectAll = () => this.setState({ selectedQuestionIds: {} });

    renderBody() {
        const { draftQuestions, releaseError, releasing } = this.props;

        const { selectedQuestionIds, showConfirmationPage } = this.state;

        const selectedQuestionCount = this.getSelectedQuestionIds().length;

        if (showConfirmationPage) {
            return (
                <div className="text-center">
                    <p className={`text-muted ${this.styles.warningText}`}>
                        Releasing these <strong>{selectedQuestionCount}</strong> responses will send
                        an email notification to all followers of the project and make the responses
                        publicly viewable.
                        <br />
                        <br />
                        Are you sure you want to release these responses?
                    </p>
                    <Button bsSize="lg" onClick={this.cancelConfirmationPage}>
                        <i className="fa fa-angle-left" /> Back
                    </Button>
                    &nbsp;&nbsp;
                    <LoadingButton
                        bsSize="lg"
                        bsStyle="success"
                        disabled={releasing}
                        icon="fa-envelope"
                        loading={releasing}
                        loadingText="Releasing Responses..."
                        onClick={this.releaseResponses}
                        text="Release Responses"
                    />
                    {releaseError && <div className="text-center error-block">{releaseError}</div>}
                </div>
            );
        }

        const noSelectedQuestions = selectedQuestionCount === 0;

        return (
            <div>
                <div>
                    <Button
                        bsSize="sm"
                        bsStyle="link"
                        className={this.styles.selectAllButton}
                        onClick={noSelectedQuestions ? this.selectAll : this.unselectAll}
                    >
                        <i
                            className={`fa fa-lg fa-${
                                noSelectedQuestions ? 'square-o' : 'minus-square-o'
                            }`}
                        />
                        {noSelectedQuestions ? 'Select' : 'Unselect'} All
                    </Button>
                </div>
                <ListGroup>
                    {draftQuestions.map((question) => {
                        const selected = selectedQuestionIds[question.id];
                        const icon = selected ? 'fa-check-square text-success' : 'fa-square-o';
                        return (
                            <ListGroupItem
                                disabled={releasing}
                                key={question.id}
                                onClick={this.toggleSelected(question)}
                            >
                                <i className={`fa fa-lg fa-fw ${icon}`} /> Question #
                                {question.number}: {question.subject}
                            </ListGroupItem>
                        );
                    })}
                </ListGroup>
                <div className="text-center">
                    <Button
                        bsSize="lg"
                        bsStyle="success"
                        disabled={noSelectedQuestions}
                        onClick={this.showConfirmationPage}
                    >
                        Next <i className="fa fa-angle-double-right" />
                    </Button>
                </div>
                {noSelectedQuestions && (
                    <div className="text-center error-block">
                        Please select at least one question to release
                    </div>
                )}
            </div>
        );
    }

    render() {
        const { hideModal } = this.props;

        return (
            <Modal onHide={hideModal} show>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">
                        Select Questions to Publicly Release
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.renderBody()}</Modal.Body>
            </Modal>
        );
    }
}

export const ReleaseQuestionResponsesModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedReleaseQuestionResponsesModal);
