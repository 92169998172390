import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getContractSelectOptions } from './selectors';
import { SearchSelect } from '../SearchSelect/SearchSelect';
import { loadContracts } from '../../actions/contracts';

export const ContractSearchInput = (props) => {
    const { getOptions, searchData, ...propsForSearchSelect } = props;

    const loading = useSelector((state) => state.contracts.get('loadingContracts'));
    const contractSelectOptions = useSelector(getContractSelectOptions);

    const options = getOptions ? getOptions(contractSelectOptions) : contractSelectOptions;

    const dispatch = useDispatch();

    const searchContracts = (data) =>
        dispatch(
            loadContracts({
                quickSearchQuery: data,
                stopNavigation: true,
                limit: 20,
                page: 1,
                ...searchData,
            })
        );

    const debouncedSearchContracts = debounce(searchContracts, 500);

    useEffect(() => {
        searchContracts();
    }, []);

    const handleContractSearchInputChange = (data, { action }) => {
        // Only load contracts when input is changed (skips blur and selection changes)
        if (action === 'input-change') {
            debouncedSearchContracts(data);
        }

        return data;
    };

    const loadContractsOnClear = (data) => {
        // Data will be null when form has been cleared
        if (data === null) {
            debouncedSearchContracts(data);
        }
    };

    return (
        <SearchSelect
            isClearable
            isLoading={loading}
            noOptionsMessage={() => (loading ? 'Loading...' : 'No results found')}
            onChangeWithOption={loadContractsOnClear}
            onInputChange={handleContractSearchInputChange}
            options={loading ? [] : options}
            overrideFeedback
            placeholder="Type to search by title, ID or vendor..."
            {...propsForSearchSelect}
        />
    );
};

ContractSearchInput.propTypes = {
    getOptions: PropTypes.func,
    searchData: PropTypes.object,
};
