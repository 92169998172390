import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Tooltip } from '../Tooltip';

export class DragIcon extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        containerClassName: PropTypes.string,
        disabled: PropTypes.bool,
        dragHandleProps: PropTypes.object,
        useSpan: PropTypes.bool,
    };

    get styles() {
        return require('./index.scss');
    }

    renderDragIcon() {
        const { className, disabled } = this.props;

        return (
            <Tooltip delayShow={1000} tooltip="Drag to reorder">
                <div
                    className={classnames(
                        this.styles.container,
                        disabled && this.styles.disabled,
                        className
                    )}
                >
                    <i className="fa fa-ellipsis-v" />
                    <i className="fa fa-ellipsis-v" />
                </div>
            </Tooltip>
        );
    }

    render() {
        const { containerClassName, disabled, dragHandleProps, useSpan } = this.props;

        if (!dragHandleProps) {
            return this.renderDragIcon();
        }

        const ContainerEl = useSpan ? 'span' : 'div';

        return (
            <ContainerEl
                aria-label="Drag Icon"
                {...(disabled ? {} : dragHandleProps)}
                className={containerClassName}
            >
                {disabled ? (
                    <div className={this.styles.hiddenDragIcon} {...dragHandleProps} />
                ) : (
                    this.renderDragIcon()
                )}
            </ContainerEl>
        );
    }
}
