import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { UserProfileNavItem } from '../UserProfileNavItem';
import { LinkContainer } from '../../LinkContainer/LinkContainer';
import { MenuItem } from '../../MenuItem/MenuItem';

export const UserNav = ({
    governmentId,
    logout,
    pathname,
    user,
    user: {
        government: { hasContracting, hasDocBuilder, hasIntake, hasRequisition, hasSourcing },
        userPermissions: {
            isRequisitionGroupAdmin,
            isRetentionAdmin,
            isSystemAdmin,
            isTemplateAdmin,
            isVendorAdmin,
        },
    },
}) => {
    const adminItems = [];

    if (hasSourcing && isVendorAdmin) {
        adminItems.push(
            <LinkContainer key="vendors" to={`/governments/${governmentId}/vendors`}>
                <MenuItem qaTag="govNavbar-vendors">
                    <i className="fa fa-fw fa-user" /> Vendors
                </MenuItem>
            </LinkContainer>
        );
    }

    if (isSystemAdmin) {
        adminItems.push(
            <LinkContainer key="admin" to={`/governments/${governmentId}/admin`}>
                <MenuItem qaTag="govNavbar-admin">
                    <i className="fa fa-fw fa-star" /> Admin
                </MenuItem>
            </LinkContainer>
        );
    }

    if (isSystemAdmin && hasRequisition) {
        adminItems.push(
            <LinkContainer
                key="requisitions-admin"
                to={`/governments/${governmentId}/requisitions-admin`}
            >
                <MenuItem qaTag="govNavbar-requisitions-admin">
                    <i className="fa fa-fw fa-shopping-cart" /> Request Management
                </MenuItem>
            </LinkContainer>
        );
    }

    if (isRequisitionGroupAdmin && hasRequisition) {
        adminItems.push(
            <LinkContainer key="review-groups" to={`/governments/${governmentId}/review-groups`}>
                <MenuItem qaTag="govNavbar-review-groups">
                    <i className="fa fa-fw fa-sitemap" /> Request Groups
                </MenuItem>
            </LinkContainer>
        );
    }

    if (isTemplateAdmin && hasDocBuilder) {
        adminItems.push(
            <LinkContainer key="templates" to={`/governments/${governmentId}/templates-admin`}>
                <MenuItem qaTag="govNavbar-templates">
                    <i className="fa fa-fw fa-pencil" /> Templates
                </MenuItem>
            </LinkContainer>
        );
    }

    if (isRetentionAdmin && (hasDocBuilder || hasIntake || hasContracting)) {
        adminItems.push(
            <LinkContainer key="retention" to={`/governments/${governmentId}/retention-admin/`}>
                <MenuItem qaTag="govNavbar-retention">
                    <i className="fa fa-fw fa-archive" /> Retention
                </MenuItem>
            </LinkContainer>
        );
    }

    if (isTemplateAdmin && hasContracting) {
        adminItems.push(
            <LinkContainer key="checklists" to={`/governments/${governmentId}/checklists-admin`}>
                <MenuItem qaTag="govNavbar-checklistsAdmin">
                    <i className="fa fa-fw fa-check-square-o" /> Checklists
                </MenuItem>
            </LinkContainer>
        );
    }

    const isActive = pathname.match(/\/account|\/admin/);
    return (
        <UserProfileNavItem className={classnames('user-nav', isActive && 'active')} user={user}>
            <LinkContainer to={`/governments/${governmentId}/account`}>
                <MenuItem qaTag="govNavbar-settings">
                    <i className="fa fa-gear fa-fw" /> Settings
                </MenuItem>
            </LinkContainer>
            {adminItems}
            <MenuItem divider qaTag="govNavbar-divider" />
            <MenuItem onClick={logout} qaTag="govNavbar-logout">
                <i className="fa fa-sign-out fa-fw" /> Log out
            </MenuItem>
        </UserProfileNavItem>
    );
};

UserNav.propTypes = {
    governmentId: PropTypes.number.isRequired,
    logout: PropTypes.func.isRequired,
    pathname: PropTypes.string.isRequired,
    user: PropTypes.shape({
        government: PropTypes.shape({
            hasContracting: PropTypes.bool.isRequired,
            hasDocBuilder: PropTypes.bool.isRequired,
            hasIntake: PropTypes.bool.isRequired,
            hasRequisition: PropTypes.bool.isRequired,
            hasSourcing: PropTypes.bool.isRequired,
        }).isRequired,
        userPermissions: PropTypes.shape({
            isRequisitionGroupAdmin: PropTypes.bool.isRequired,
            isRetentionAdmin: PropTypes.bool.isRequired,
            isSystemAdmin: PropTypes.bool.isRequired,
            isTemplateAdmin: PropTypes.bool.isRequired,
            isVendorAdmin: PropTypes.bool.isRequired,
        }).isRequired,
    }).isRequired,
};
