import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { sectionNames, sections } from './constants';
import { sectionsMap } from '../../../../../constants/requisitionsCreate';
import { getRequisitionJS } from '../../../../../selectors/govApp';

export const useSectionNumbering = (showCustomForm) => {
    const requisition = useSelector(getRequisitionJS);

    const visibleSections = useMemo(() => {
        return sections
            .filter((s) => s !== sectionNames.FORM_SETTINGS) // Exclude FORM_SETTINGS
            .filter((s) => !requisition.requestType[sectionsMap[s]?.sectionHideKey]) // Exclude hidden sections
            .filter((s) => s !== sectionNames.CUSTOM_FORM || showCustomForm); // Exclude CUSTOM_FORM if hidden
    }, [
        showCustomForm,
        requisition.requestType.hideSectionPurchase,
        requisition.requestType.hideSectionVendor,
        requisition.requestType.hideSectionAttachment,
    ]);

    const getSectionNumber = useCallback(
        /**
         * Callback to get a one-indexed section number
         * @param {string} sectionKey section name from `sectionNames` constant
         * @returns {number} one-indexed section number, taking into account which sections are hidden or shown.
         * Returns -1 if section is not visible
         */
        (sectionKey) => {
            const sectionIndex = visibleSections.findIndex((s) => s === sectionKey);

            if (sectionIndex === -1) {
                return -1;
            }

            return sectionIndex + 1;
        },
        [visibleSections]
    );

    return { getSectionNumber };
};
