import { sortBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Panel } from 'react-bootstrap';

import { AgGridReactLegacy } from '../AgGridReactLegacy';
import { AgGridReactPanelHeading } from '../AgGridReactPanelHeading';

// We only want local overrides which is why this is done here instead of via bsStyle/className
// TODO: Would love to get custom-styles working: https://react-bootstrap.github.io/utilities/custom-styles/
const PANEL_STYLES = {
    border: 'none',
    boxShadow: 'none',
    maxWidth: 630,
};

const CENTERED_PANEL_STYLES = {
    ...PANEL_STYLES,
    margin: '0px auto',
};

const PANEL_BODY_STYLES = {
    padding: 0,
};

export class VendorClassificationTable extends PureComponent {
    static propTypes = {
        isCentered: PropTypes.bool,
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        vendors: PropTypes.arrayOf(
            PropTypes.shape({
                vendorCertifications: PropTypes.arrayOf(
                    PropTypes.shape({
                        id: PropTypes.number.isRequired,
                        vendorCertification: PropTypes.shape({
                            certification_id: PropTypes.number.isRequired,
                        }),
                    })
                ),
            })
        ),
        vendorClassificationTotals: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string,
                total: PropTypes.number,
                percent: PropTypes.number,
            })
        ),
    };

    static defaultProps = {
        isCentered: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            columnDefs: [
                {
                    autoHeight: true,
                    cellClass: ['wrapText'], // Used exclusively for Excel export styles
                    field: 'title',
                    headerName: 'Classification',
                    width: 300,
                },
                {
                    field: 'total',
                    headerName: 'Number of Vendors',
                    width: 155,
                },
                {
                    field: 'percent',
                    headerName: '% of Total Vendors',
                    width: 150,
                },
            ],
            defaultColDef: {
                editable: false,
            },
        };
    }

    /**
     * Given a list of vendors, generate a classification mapping from them for use in the grid.
     *
     * @return {object[]} The classification rows
     */
    getFormattedCertificationDataForGrid = () => {
        const { vendors, vendorClassificationTotals } = this.props;

        if (vendorClassificationTotals) {
            return vendorClassificationTotals.map((item) => {
                return {
                    percent: `${((item.percent || 0) * 100).toFixed(2)}%`,
                    title: item.title,
                    total: item.total,
                };
            });
        }

        const vendorCertificationMap = {};

        vendors.forEach((vendor) => {
            if (vendor.vendorCertifications) {
                vendor.vendorCertifications.forEach((vendorCertification) => {
                    if (vendorCertificationMap[vendorCertification.id] === undefined) {
                        vendorCertificationMap[vendorCertification.id] = {
                            title: vendorCertification.title,
                            total: 0,
                        };
                    }

                    const vendorCertificationResponse =
                        vendorCertification.vendorCertifications?.[0];
                    if (vendorCertificationResponse) {
                        const certificationId = vendorCertificationResponse.certification_id;
                        vendorCertificationMap[certificationId].total++;
                    }
                });
            }
        });

        const sortedVendorCertifications = sortBy(Object.values(vendorCertificationMap), 'title');
        return sortedVendorCertifications.map((certification) => {
            const percent = (certification.total / vendors.length) * 100;

            return {
                percent: `${percent.toFixed(2)}%`,
                title: certification.title,
                total: certification.total,
            };
        });
    };

    /**
     * Given a row of data from the grid, get a unique ID for it.
     *
     * @param {object} data The raw data in this row from `getFormattedCertificationDataForGrid`
     * @return {string} The unique title of the row
     */
    getRowNodeId = (data) => {
        return data.title;
    };

    /**
     * Callback for saving a reference to the underlying AgReactGrid's API once it is ready. We need
     * access to the API to do things such as export data to a CSV.
     *
     * @param {object} params The `onGridReady` callback params
     * @param {object} params.api The underlying AgReactGrid's API
     */
    handleGridReady = (params) => {
        this.setState({ gridApi: params.api });
    };

    render() {
        const { isCentered, title } = this.props;

        const { columnDefs, defaultColDef, gridApi } = this.state;

        return (
            <Panel
                defaultExpanded
                id="skip"
                style={isCentered ? CENTERED_PANEL_STYLES : PANEL_STYLES}
            >
                <AgGridReactPanelHeading
                    gridApi={gridApi}
                    title={title || 'Vendor Classifications'}
                />
                <Panel.Body style={PANEL_BODY_STYLES}>
                    <AgGridReactLegacy
                        columns={columnDefs}
                        defaultColDef={defaultColDef}
                        domLayout="autoHeight"
                        getRowNodeId={this.getRowNodeId}
                        onGridReady={this.handleGridReady}
                        rows={this.getFormattedCertificationDataForGrid()}
                    />
                </Panel.Body>
            </Panel>
        );
    }
}
