import {
    fieldTypes,
    requiredValueFieldTypes,
    requiredEsignatureFieldTypes,
} from '@og-pro/shared-config/signatures';
import { isEmail } from 'validator';

import {
    fieldsEditableFieldNames as signatureFieldsFieldNames,
    fieldNames as signatureFieldNames,
    blockFieldNames as signaturesBlockFieldNames,
} from '../../components/Forms/SignatureForm/constants';
import { REQUIRED_TEXT } from './constants';
import { hasFormErrors } from '../../helpers/formErrors';

export const signatureValidate = (signature, validateValues = false) => {
    const validateBlockArray = (blocks) => {
        return blocks.reduce((blockErrors, block, blockIndex) => {
            // don't validate hidden signature blocks
            if (block.isHiddenByLogic) {
                return blockErrors;
            }

            const innerErrors = block[signaturesBlockFieldNames.FIELDS].reduce(
                (fieldErrors, field, index) => {
                    const indexErrors = {};
                    if (!field[signatureFieldsFieldNames.LABEL]) {
                        indexErrors[signatureFieldsFieldNames.LABEL] = REQUIRED_TEXT;
                    }

                    if (signature[signatureFieldNames.ESIGNATURE_ENABLED]) {
                        // e-signature enabled: All fields are required.
                        if (
                            validateValues &&
                            field.type !== fieldTypes.DATE_SIGNED &&
                            field.type !== fieldTypes.SIGNATURE
                        ) {
                            if (!field[signatureFieldsFieldNames.VALUE]) {
                                indexErrors[signatureFieldsFieldNames.VALUE] = REQUIRED_TEXT;
                            }
                        }
                    }

                    // e-signature disabled (wet signature): if we want to validate for filled values only do so for required types
                    if (!signature[signatureFieldNames.ESIGNATURE_ENABLED]) {
                        if (validateValues && requiredValueFieldTypes.includes(field.type)) {
                            if (!field[signatureFieldsFieldNames.VALUE]) {
                                indexErrors[signatureFieldsFieldNames.VALUE] = REQUIRED_TEXT;
                            }
                        }
                    }
                    return {
                        ...fieldErrors,
                        [index]: indexErrors,
                    };
                },
                {}
            );
            const email = block[signaturesBlockFieldNames.EMAIL];
            const styling = block[signaturesBlockFieldNames.STYLING];
            let emailError = null;
            // styling default means it's a signature block, not a header block
            if (validateValues && styling === 'default') {
                if (!email) {
                    emailError = 'Required';
                } else if (!isEmail(email)) {
                    emailError = 'Invalid email address';
                }
            }

            let blockLevelError = null;

            if (signature.esignatureEnabled && block.styling === 'default') {
                const selectedTypes = block[signaturesBlockFieldNames.FIELDS].map(
                    (field) => field.type
                );
                if (!requiredEsignatureFieldTypes.some((type) => selectedTypes.includes(type))) {
                    blockLevelError =
                        'For eSignature, the fields Signature, Full Name and Date Signed are required';
                }
            } else if (block[signaturesBlockFieldNames.FIELDS].length === 0) {
                blockLevelError = 'At least one field is required';
            }

            return {
                ...blockErrors,
                [blockIndex]: {
                    _error: blockLevelError,
                    hasError: hasFormErrors(innerErrors) || !!blockLevelError || !!emailError,
                    [signaturesBlockFieldNames.FIELDS]: innerErrors,
                    [signaturesBlockFieldNames.EMAIL]: emailError,
                },
            };
        }, {});
    };

    if (!signature) {
        return null;
    }

    return {
        [signatureFieldNames.SIGNATURES_BLOCKS]: signature[
            signatureFieldNames.SIGNATURES_BLOCKS
        ].reduce((errors, blocks, index) => {
            const blocksErrors = validateBlockArray(blocks);

            return {
                ...errors,
                [index]: blocksErrors,
            };
        }, {}),
    };
};
