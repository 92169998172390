import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Button } from '../../../../Button';

export class PublishButton extends PureComponent {
    static propTypes = {
        builderDisplayName: PropTypes.string.isRequired,
        className: PropTypes.string,
        disabled: PropTypes.bool,
        onClick: PropTypes.func,
        showContractCompiler: PropTypes.bool,
    };

    render() {
        const { builderDisplayName, className, disabled, onClick, showContractCompiler } =
            this.props;

        const styles = require('./index.scss');

        const buttonStyles = className ? `${styles.publishBtn} ${className}` : styles.publishBtn;

        return (
            <Button
                bsStyle="success"
                className={buttonStyles}
                disabled={disabled}
                onClick={onClick}
                qaTag="publishButton-finalize"
            >
                <i className="fa fa-check-square-o" /> Finalize
                {showContractCompiler ? ' and Lock' : ''} {builderDisplayName}
            </Button>
        );
    }
}
