import moment from 'moment-timezone';
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import { proposalStatusesDict } from '@og-pro/shared-config/proposals';
import { projectStatusesDict } from '@og-pro/shared-config/projects';

import { RouterLink } from '../../../RouterLink/RouterLink';
import { Well } from '../../../Well/Well';
import { isAuctionComplete } from './helpers';
import { timezoneAbbreviations } from '../../../../constants';

const { NO_BID } = proposalStatusesDict;

const { AUCTION_PENDING, OPEN, POST_PENDING, REVERSE_AUCTION } = projectStatusesDict;

export const ReverseAuctionWell = ({
    isProposalDetailsPage,
    project: {
        auctionStartDate,
        auctionEndDate,
        government: {
            organization: { timezone },
        },
        id: projectId,
        status: projectStatus,
    },
    proposal: {
        id: proposalId,
        isDisqualified,
        isExcluded,
        status: proposalStatus,
        vendor_id: vendorId,
    },
}) => {
    if (isDisqualified || isExcluded || proposalStatus === NO_BID) {
        return null;
    }

    const styles = require('./shared.scss');
    const auctionStart = moment.tz(auctionStartDate, timezone);
    const auctionEnd = moment.tz(auctionEndDate, timezone);
    const auctionNotStarted =
        projectStatus === POST_PENDING ||
        projectStatus === OPEN ||
        projectStatus === AUCTION_PENDING;
    const auctionInProgress = projectStatus === REVERSE_AUCTION;
    const auctionUrl = `/vendors/${vendorId}/proposals/${proposalId}/projects/${projectId}/reverse-auction`;

    return (
        <Well
            bsSize="small"
            className={classNames(
                styles.auctionWell,
                isProposalDetailsPage && auctionNotStarted && styles.auctionNotStarted,
                auctionInProgress && styles.auctionInProgress
            )}
        >
            <span>
                <i className="fa fa-gavel" />
                &nbsp;&nbsp;
                <span className={styles.bolded}>Reverse Auction: </span>
                {auctionStart.format('lll')} - {auctionEnd.format('lll')}
                &nbsp;({timezoneAbbreviations[timezone]})
            </span>
            {isProposalDetailsPage && (
                <span className={`pull-right text-muted ${styles.italicized}`}>
                    {auctionNotStarted && 'Not Started'}
                    {auctionInProgress && (
                        <RouterLink
                            className="pull-right"
                            qaTag="vendorProposalDetails-viewAuction"
                            to={auctionUrl}
                        >
                            <span className={styles.bolded}>View Auction</span>
                        </RouterLink>
                    )}
                    {isAuctionComplete(projectStatus) && 'Ended'}
                </span>
            )}
        </Well>
    );
};

ReverseAuctionWell.propTypes = {
    isProposalDetailsPage: PropTypes.bool,
    project: PropTypes.shape({
        auctionEndDate: PropTypes.string,
        auctionStartDate: PropTypes.string,
        government: PropTypes.shape({
            organization: PropTypes.shape({
                timezone: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        id: PropTypes.number.isRequired,
        status: PropTypes.string.isRequired,
        template: PropTypes.shape({
            isReverseAuction: PropTypes.bool.isRequired,
        }).isRequired,
    }).isRequired,
    proposal: PropTypes.shape({
        id: PropTypes.number.isRequired,
        isDisqualified: PropTypes.bool.isRequired,
        isExcluded: PropTypes.bool.isRequired,
        status: PropTypes.string.isRequired,
        vendor_id: PropTypes.number.isRequired,
    }).isRequired,
};
