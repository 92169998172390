import React from 'react';
import PropTypes from 'prop-types';
import { projectStatusesDict } from '@og-pro/shared-config/projects';
import { statuses } from '@og-pro/shared-config/electronicSignatures';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import { Box, Typography } from '@og-pro/ui';
import { Warning as WarningIcon } from '@mui/icons-material';

import { SignaturesPopover } from './SignaturesPopover';

const { colors } = capitalDesignTokens.foundations;

const Content = ({ color, electronicSignature, text, showPopover = false }) => {
    return (
        <Box alignItems="center" component="span" display="flex" flex={1}>
            <Box>
                <Typography component="span" sx={{ color, fontWeight: 600 }}>
                    {text}
                </Typography>
            </Box>
            {showPopover && (
                <Box alignItems="center" display="flex" justifyContent="center" ml={1}>
                    <SignaturesPopover electronicSignature={electronicSignature} />
                </Box>
            )}
        </Box>
    );
};

Content.propTypes = {
    color: PropTypes.string.isRequired,
    electronicSignature: PropTypes.shape({
        currentSigners: PropTypes.number.isRequired,
        totalSigners: PropTypes.number.isRequired,
        status: PropTypes.string.isRequired,
        events: PropTypes.arrayOf(PropTypes.object),
    }),
    showPopover: PropTypes.bool,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export const LastAction = ({ contractDocument }) => {
    if (contractDocument.status !== projectStatusesDict.FINAL) {
        return null;
    }

    const { electronicSignature } = contractDocument;

    if (!electronicSignature) {
        return (
            <Content
                color={colors.pear700}
                electronicSignature={electronicSignature}
                text="Ready to be Signed"
            />
        );
    }

    if (electronicSignature.status === statuses.PENDING) {
        return <Content color={colors.yellow1000} text="Waiting for Docusign" />;
    }

    if (electronicSignature.status === statuses.ERRORED) {
        return (
            <Content
                color={colors.ruby700}
                text={
                    <Box alignItems="center" component="span" display="flex">
                        <WarningIcon fontSize="small" sx={{ mr: 1 }} /> Failed to Send for Signature
                    </Box>
                }
            />
        );
    }

    if (electronicSignature.status === statuses.COMPLETED) {
        return (
            <Content
                color={colors.cerulean800}
                electronicSignature={electronicSignature}
                showPopover
                text="Fully Signed"
            />
        );
    }

    if (electronicSignature.status === statuses.CANCELLED) {
        return (
            <Content
                color={colors.ruby700}
                electronicSignature={electronicSignature}
                text="Withdrawn"
            />
        );
    }

    if (electronicSignature.currentSigners === 0) {
        return (
            <Content
                color={colors.purple700}
                electronicSignature={electronicSignature}
                showPopover
                text="Sent for Signature"
            />
        );
    }

    return (
        <Content
            color={colors.purple700}
            electronicSignature={electronicSignature}
            showPopover
            text={`${electronicSignature.currentSigners}/${electronicSignature.totalSigners} Signed`}
        />
    );
};

LastAction.propTypes = {
    contractDocument: PropTypes.shape({
        status: PropTypes.string.isRequired,
        electronicSignature: PropTypes.shape({
            currentSigners: PropTypes.number.isRequired,
            totalSigners: PropTypes.number.isRequired,
            status: PropTypes.string.isRequired,
            events: PropTypes.arrayOf(PropTypes.object),
        }),
    }).isRequired,
};
