import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FieldArray } from 'redux-form';

import { DEFAULT_VALUE, INPUT_DATA, VALUE } from '@og-pro/shared-config/questionnaires';
import { shouldHideQuestionLogicItem } from '@og-pro/shared-config/questionLogics/utils';
import { hasDefaultValue, getDefaultValue } from '@og-pro/shared-config/upfrontQuestions';

import { QuestionnaireDisplayList } from '../../Questionnaire/QuestionnaireDisplayList';

export class UpfrontQuestionsResponseForm extends PureComponent {
    static propTypes = {
        change: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        formKey: PropTypes.string.isRequired,
        responsePath: PropTypes.string,
        showValidation: PropTypes.bool,
    };

    static defaultProps = {
        disabled: false,
    };

    handleConditionalQuestionChange = (parentUpfrontQuestion, fields) => {
        const { change } = this.props;

        fields.forEach((member, index, fieldsRef) => {
            const upfrontQuestion = fieldsRef.get(index);

            // When the value of a parent condition question changes we need to update the
            // child questions
            if (parentUpfrontQuestion.conditionalSubQuestionIds.includes(upfrontQuestion.id)) {
                // 1. Compute `isHiddenByLogic` and set as form value so form updates in real time
                const isHiddenByLogic = shouldHideQuestionLogicItem(
                    upfrontQuestion.questionLogic,
                    parentUpfrontQuestion
                );
                change(`${member}.isHiddenByLogic`, isHiddenByLogic);

                // 2. If child question was previously shown and will continue to show, do not reset answer
                if (!upfrontQuestion.isHiddenByLogic && !isHiddenByLogic) {
                    return;
                }

                // 3. Wipe any previous input values or set back to default value
                let inputData = null;
                if (hasDefaultValue(upfrontQuestion)) {
                    inputData = {
                        ...pick(upfrontQuestion[INPUT_DATA], [DEFAULT_VALUE]),
                        [VALUE]: getDefaultValue(upfrontQuestion),
                    };
                }
                change(`${member}.${INPUT_DATA}`, inputData);
            }
        });
    };

    renderQuestionnaire = (props) => {
        const { disabled, fields, responsePath, showValidation } = props;

        const upfrontQuestions = fields.map((member, index, fieldsRef) => {
            const upfrontQuestion = fieldsRef.get(index);

            let onChange;
            if ((upfrontQuestion.conditionalSubQuestionIds || []).length > 0) {
                onChange = () => {
                    // Take off event loop so that `change` event can be reflected in form values
                    // (need to perform this update with fresh form values from after the change)
                    setTimeout(() => {
                        // Get current form value for parent upfront question
                        const parentUpfrontQuestion = fieldsRef.get(index);
                        this.handleConditionalQuestionChange(parentUpfrontQuestion, fieldsRef);
                    });
                };
            }

            return {
                ...upfrontQuestion,
                fieldName: member,
                onChange,
            };
        });

        return (
            <QuestionnaireDisplayList
                disabled={disabled}
                isResponseForm
                questionnaires={upfrontQuestions}
                responsePath={responsePath}
                showValidation={showValidation}
            />
        );
    };

    render() {
        const { disabled, formKey, responsePath, showValidation } = this.props;

        return (
            <FieldArray
                component={this.renderQuestionnaire}
                disabled={disabled}
                name={formKey}
                responsePath={responsePath}
                showValidation={showValidation}
            />
        );
    }
}
