import { Box, Button, Dialog, ReduxFormTextField, Typography } from '@og-pro/ui';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, change, formValueSelector, getFormValues } from 'redux-form';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { mdiSitemapOutline } from '@mdi/js';

import MdiIcon from '@mdi/react';

import { useDispatch, useSelector } from 'react-redux';

import {
    createCustomFieldFormName,
    customFieldFields,
} from '@og-pro/shared-config/customFormService/customField';

import { capitalDesignTokens } from '@opengov/capital-mui-theme';

import { Link } from 'react-router-dom';

import { CreateCustomFieldContext } from '../context';
import { useLoadCustomField } from '../../../../../lib/customFormService/useLoadCustomField';
import { getRequestTypes } from '../../../../../selectors/govApp';
import { getRequestTypesPath } from '../../selectors';

const { ID } = customFieldFields;

const BulkAddOptionsModal = ({ open, handleClose, handleAddOptions }) => {
    const dispatch = useDispatch();
    const formValues =
        useSelector((state) => getFormValues(createCustomFieldFormName)(state)) || {};

    const bulkAddOptionsFieldName = 'bulkAddOptions';

    function handleAddOptionsClick() {
        handleAddOptions(formValues[bulkAddOptionsFieldName]);

        dispatch(change(createCustomFieldFormName, bulkAddOptionsFieldName, ''));
    }

    return (
        <Dialog
            closeButton
            dialogTitle="Bulk Add Options to this Dropdown Field"
            fullWidth
            onClose={handleClose}
            open={open}
        >
            <Box>
                <Box sx={{ pt: 1, pb: 2 }}>
                    <Typography variant="bodyDefault">
                        Use this to copy and paste a list of options for this dropdown. Each option
                        must be on a separate line or separated by a comma.
                    </Typography>
                </Box>
                <Box sx={{ pb: 1.5 }}>
                    <Field
                        component={ReduxFormTextField}
                        fullWidth
                        label="List of Options"
                        multiline
                        name={bulkAddOptionsFieldName}
                    />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, pb: 1, pt: 3 }}>
                    <Button color="secondary" onClick={handleClose} variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        disabled={!formValues[bulkAddOptionsFieldName]}
                        onClick={handleAddOptionsClick}
                        variant="contained"
                    >
                        Add Options
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
};

BulkAddOptionsModal.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func.isRequired,
    handleAddOptions: PropTypes.func.isRequired,
};

const ConditionalOptionUsedList = ({ customFormsUsingOption }) => {
    const requestTypes = useSelector(getRequestTypes);
    const requestTypePath = useSelector(getRequestTypesPath);

    const usedRequestTypes = customFormsUsingOption
        .map((customForm) => {
            const requestType = requestTypes.find((elem) => elem.id === customForm.clientFormId);
            return requestType;
        })
        .filter((elem) => elem !== undefined);

    return (
        <Box
            sx={{
                pl: 2,
                borderLeft: `4px solid ${capitalDesignTokens.semanticColors.border.secondary}`,
            }}
        >
            <Typography
                color={capitalDesignTokens.semanticColors.foreground.secondary}
                sx={{
                    mb: 0.5,
                }}
                variant="h6"
            >
                <MdiIcon path={mdiSitemapOutline} size={0.5} /> Conditional fields are attached to
                this option in the following Request Types
            </Typography>
            <ul
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: capitalDesignTokens.foundations.units.unitHalf,
                    'padding-inline-start': capitalDesignTokens.foundations.units.unit3,
                    marginBottom: '0px', // Override bootstrap <ul> margin styling
                }}
            >
                {usedRequestTypes.map((requestType) => {
                    return (
                        <li key={requestType.id}>
                            <Typography
                                component={Link}
                                target="_blank"
                                to={`${requestTypePath}/${requestType.id}`}
                                variant="bodySmall"
                            >
                                {requestType.name || 'Untitled'}
                            </Typography>
                        </li>
                    );
                })}
            </ul>
        </Box>
    );
};

ConditionalOptionUsedList.propTypes = {
    customFormsUsingOption: PropTypes.arrayOf(
        PropTypes.shape({
            clientFormId: PropTypes.number.isRequired,
        })
    ),
};

export const OptionsField = ({ fields = [], showBulkAddButton = false, disabled }) => {
    const [openBulkAddOptionsModal, setOpenBulkAddOptionsModal] = useState(false);
    const { showFormValidation } = useContext(CreateCustomFieldContext);
    /**
     * @type {number | undefined} customFieldId could be undefined if we are not viewing an existing field
     */
    const customFieldId = useSelector((state) =>
        formValueSelector(createCustomFieldFormName)(state, ID)
    );
    const { data } = useLoadCustomField(customFieldId);

    let customFormsUsingField = [];
    if (customFieldId && data) {
        customFormsUsingField = data.getFormsUsingConditionalField;
    }

    const handleAddOptionClick = () => {
        fields.push('');
    };

    const handleBulkAddOptionsClick = () => {
        setOpenBulkAddOptionsModal(true);
    };

    const handleCloseBulkAddOptionsModal = () => {
        setOpenBulkAddOptionsModal(false);
    };

    const handleRemoveOptionClick = (fieldIndex) => {
        if (fields.length === 1) {
            return;
        }

        fields.remove(fieldIndex);
    };

    const convertBulkAddOptions = (bulkAddOptions) => {
        if (!bulkAddOptions) {
            return;
        }

        // options can be comma separated or on separate lines
        const options = bulkAddOptions.split(/[\r?\n|,]+/);

        if (options.length === 0) {
            return;
        }

        options.forEach((option) => {
            if (!option) {
                return;
            }

            fields.push(option);
        });
    };

    const handleAddBulkOptions = (bulkAddOptionsString) => {
        convertBulkAddOptions(bulkAddOptionsString);
        handleCloseBulkAddOptionsModal();
    };

    return (
        <>
            <Box display="flex" flexDirection="column" gap={2}>
                {fields.map((fieldName, index) => {
                    const optionValue = fields.get(index);
                    const customFormsUsingOption = customFormsUsingField.filter(
                        (customForm) => customForm.operandValue === optionValue
                    );
                    const optionDisabled = disabled || customFormsUsingOption.length > 0;

                    return (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                            }}
                        >
                            <Box sx={{ display: 'flex' }}>
                                <Field
                                    component={ReduxFormTextField}
                                    disabled={optionDisabled}
                                    fullWidth
                                    label={`Option ${index + 1} ${index === 0 ? '*' : ''}`}
                                    name={fieldName}
                                    showValidation={showFormValidation}
                                />
                                {!optionDisabled && fields.length > 1 && (
                                    <Box
                                        sx={{
                                            height: '100%',
                                            alignItems: 'start',
                                            display: 'flex',
                                            paddingTop: 3,
                                        }}
                                    >
                                        <Button
                                            color="secondary"
                                            onClick={() => handleRemoveOptionClick(index)}
                                            sx={{
                                                paddingTop: 0.5,
                                                paddingBottom: 0.5,
                                                paddingLeft: 1,
                                                paddingRight: 1,
                                            }}
                                            variant="text"
                                        >
                                            <DeleteIcon fontSize="small" />
                                        </Button>
                                    </Box>
                                )}
                            </Box>
                            {!!customFormsUsingOption.length && (
                                <ConditionalOptionUsedList
                                    customFormsUsingOption={customFormsUsingOption}
                                />
                            )}
                        </Box>
                    );
                })}
                {!disabled ? (
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <Button
                            color="primary"
                            onClick={handleAddOptionClick}
                            startIcon={<AddIcon />}
                            variant="outlined"
                        >
                            Add Another Option
                        </Button>
                        {showBulkAddButton && (
                            <Button
                                color="secondary"
                                onClick={handleBulkAddOptionsClick}
                                startIcon={<ExitToAppIcon />}
                                variant="outlined"
                            >
                                Bulk Add
                            </Button>
                        )}
                    </Box>
                ) : null}
            </Box>
            <BulkAddOptionsModal
                disabled={disabled}
                handleAddOptions={(bulkAddOptionsString) =>
                    handleAddBulkOptions(bulkAddOptionsString)
                }
                handleClose={handleCloseBulkAddOptionsModal}
                open={openBulkAddOptionsModal}
            />
        </>
    );
};

OptionsField.propTypes = {
    fields: PropTypes.arrayOf(PropTypes.string),
    showBulkAddButton: PropTypes.bool,
    disabled: PropTypes.bool,
};
