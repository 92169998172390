import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { destroy, isDirty } from 'redux-form';

import {
    BID_BOND,
    CONTAINS_PRICING,
    DATA,
    DOWNLOAD,
    EXTERNAL_FORM,
    INPUT_DATA,
    IS_PUBLIC,
    IS_REQUIRED,
    IS_TITLE,
    MULTIPLE_CHOICE,
    NOTARIZE,
    OPTIONS,
    PROMPT,
    RAW_PROMPT,
    TYPE,
} from '@og-pro/shared-config/questionnaires';

import { PAGE_ONE, PAGE_TWO, confirmExitMessage } from './constants';
import { getFormDataJS, getModalOptionsJS, getQuestionTypeJS } from './selectors';
import { QuestionnaireForm } from './QuestionnaireForm';
import { form } from './QuestionnaireForm/constants';
import { QuestionnaireTypeSelect } from '../../../../QuestionnaireTypeSelect';
import {
    hideQuestionnaireCreateModal,
    setQuestionnaireModalData,
} from '../../../../../actions/questionnaire';
import { isTinyMceModalOpenSelector } from '../../../../../containers/App/selectors';

const mapStateToProps = (state) => {
    return {
        formData: getFormDataJS(state),
        isEditForm: state.questionnaire.get('isEditFormModal'),
        isFormDirty: isDirty(form)(state),
        modalOptions: getModalOptionsJS(state),
        page: state.questionnaire.get('createModalPage') || PAGE_ONE,
        questionType: getQuestionTypeJS(state),
        showModal: state.questionnaire.get('showCreateModal'),
        isTinyMceModalOpen: isTinyMceModalOpenSelector(state),
    };
};

const mapDispatchToProps = {
    destroyForm: destroy,
    hideModal: hideQuestionnaireCreateModal,
    setData: setQuestionnaireModalData,
};

// @connect
class ConnectedQuestionnaireCreateModal extends Component {
    static propTypes = {
        addToBottomOnly: PropTypes.bool,
        allowDefaultValue: PropTypes.bool,
        destroyForm: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        formatTypes: PropTypes.array,
        formData: PropTypes.object,
        hideModal: PropTypes.func.isRequired,
        includeContainsPricingOption: PropTypes.bool,
        isEditForm: PropTypes.bool,
        isFormDirty: PropTypes.bool.isRequired,
        modalOptions: PropTypes.shape({
            insertAfterQuestionId: PropTypes.number,
            omitFormatTypes: PropTypes.bool,
            onComplete: PropTypes.func,
            onEdit: PropTypes.func,
            tagOptions: PropTypes.array,
            templateVariableOptions: PropTypes.array,
        }).isRequired,
        onSubmit: PropTypes.func.isRequired,
        page: PropTypes.number.isRequired,
        questionnaireDisplayName: PropTypes.string.isRequired,
        questionType: PropTypes.shape({
            type: PropTypes.string.isRequired,
        }),
        questionTypes: PropTypes.array,
        requireByDefault: PropTypes.bool,
        setData: PropTypes.func.isRequired,
        showModal: PropTypes.bool.isRequired,
        isTinyMceModalOpen: PropTypes.bool.isRequired,
        useRawPrompt: PropTypes.bool,
        validateBidBond: PropTypes.bool,
    };

    backHandler = (formData) => {
        const { page, setData } = this.props;
        setData(formData, page - 1);
    };

    hideModal = (options = {}) => {
        const { destroyForm, isFormDirty, hideModal } = this.props;
        const { force } = options;

        // Confirm exit if form is dirty and not forcing hide
        // eslint-disable-next-line no-alert
        if (force || !isFormDirty || window.confirm(confirmExitMessage)) {
            hideModal();
            destroyForm(form);
        }
    };

    selectQuestionType = (questionnaire) => {
        const { formData, requireByDefault, useRawPrompt } = this.props;
        const questionTypeChanged = get(formData, TYPE) !== questionnaire[TYPE];

        const data = {
            [IS_TITLE]: false,
            [DATA]: questionTypeChanged ? null : formData[DATA], // reset data if question type changed
            [INPUT_DATA]: questionTypeChanged ? null : formData[INPUT_DATA], // reset default data if question type changed
        };

        const promptField = useRawPrompt ? RAW_PROMPT : PROMPT;
        if (questionnaire.type === EXTERNAL_FORM) {
            data[promptField] =
                '<p>Once you start your proposal you will be asked to complete a DocuSign form.</p>';
        } else if (questionnaire.type === DOWNLOAD) {
            data[promptField] = '<p>Please download the below documents, complete, and upload.</p>';
        } else if (questionnaire.type === NOTARIZE) {
            data[promptField] =
                '<p>Please download the below documents, complete and have notarized. An online notarization option will be provided for you when responding.</p>';
        } else if (questionnaire.type === BID_BOND) {
            data[promptField] =
                '<p>Please enter your Bid Bond information from Surety2000 below.</p>';
        } else if (questionnaire.type === MULTIPLE_CHOICE && !data[DATA]) {
            data[DATA] = {
                [OPTIONS]: [undefined, undefined],
            };
        }

        if (requireByDefault && formData[IS_REQUIRED] === undefined) {
            data[IS_REQUIRED] = true;
        }

        this.selectData(questionnaire, data);
    };

    selectFormatType = (questionType) => {
        this.selectData(questionType, {
            [CONTAINS_PRICING]: false,
            [IS_PUBLIC]: false,
            [IS_REQUIRED]: false,
            [IS_TITLE]: true,
            [DATA]: null,
        });
    };

    selectData = (questionType, extraData) => {
        const { formData, page, setData } = this.props;
        setData(
            {
                ...formData,
                [TYPE]: questionType[TYPE],
                ...extraData,
            },
            page + 1
        );
    };

    submitHandler = (data, additionalOptions) => {
        const {
            isEditForm,
            modalOptions,
            modalOptions: { onComplete, onEdit },
            onSubmit,
        } = this.props;

        // Choose the handler function based on form type
        const submitHandler = isEditForm ? onEdit : onSubmit;
        const options = {
            ...modalOptions,
            ...additionalOptions,
        };
        Promise.resolve(submitHandler(data, options)).then((questionnaire) => {
            if (onComplete) {
                onComplete(questionnaire);
            }
        });
        this.hideModal({ force: true });
    };

    renderBody() {
        const {
            addToBottomOnly,
            allowDefaultValue,
            disabled,
            formatTypes,
            formData,
            includeContainsPricingOption,
            isEditForm,
            modalOptions: {
                insertAfterQuestionId,
                omitFormatTypes,
                tagOptions,
                templateVariableOptions,
            },
            page,
            questionType,
            questionTypes,
            useRawPrompt,
            validateBidBond,
        } = this.props;

        switch (page) {
            case PAGE_ONE:
                return (
                    <QuestionnaireTypeSelect
                        formatTypes={formatTypes}
                        omitFormatTypes={omitFormatTypes}
                        onFormatSelect={this.selectFormatType}
                        onQuestionSelect={this.selectQuestionType}
                        questionTypes={questionTypes}
                    />
                );
            case PAGE_TWO:
                return (
                    <QuestionnaireForm
                        addToBottomOnly={addToBottomOnly}
                        allowDefaultValue={allowDefaultValue}
                        backHandler={this.backHandler}
                        disabled={disabled}
                        includeContainsPricingOption={includeContainsPricingOption}
                        initialValues={formData}
                        insertAfterQuestionId={insertAfterQuestionId}
                        isEditForm={isEditForm}
                        onSubmit={this.submitHandler}
                        questionType={questionType}
                        tagOptions={tagOptions}
                        templateVariableOptions={templateVariableOptions}
                        useRawPrompt={useRawPrompt}
                        validateBidBond={validateBidBond}
                    />
                );
            default:
                return null;
        }
    }

    render() {
        const { isEditForm, showModal, isTinyMceModalOpen, questionnaireDisplayName } = this.props;

        const modalTitle = `${isEditForm ? 'Edit' : 'Create'} ${questionnaireDisplayName}`;

        return (
            <Modal
                bsSize="lg"
                enforceFocus={!isTinyMceModalOpen}
                onHide={this.hideModal}
                show={showModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.renderBody()}</Modal.Body>
            </Modal>
        );
    }
}

export const QuestionnaireCreateModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedQuestionnaireCreateModal);
