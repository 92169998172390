import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Box } from '@og-pro/ui';

import { ProcuratedBadge } from '../ProcuratedBadge';

export const ProcuratedVendorCell = ({ expandable, hideLink, hideLogo, params }) => {
    const styles = require('./index.scss');

    const name = params?.data?.name || params?.data?.organization_name || params?.data?.legalName;
    const logo = params?.data?.logo;

    const hideRating = params?.data?.isPublic;
    const vendorRating = params?.data?.rating;

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' || event.code === 'Space') {
            if (params?.colDef.onCellClicked) {
                params.colDef.onCellClicked(params);
            }
        }
    };

    useEffect(() => {
        params.eGridCell.addEventListener('keypress', handleKeyPress);
        return () => {
            params.eGridCell.removeEventListener('keypress', handleKeyPress);
        };
    }, []);

    if (!name) {
        return null;
    }

    if (process.env.SERVER) {
        return name;
    }

    return expandable ? (
        <Box pt={0.5}>
            {!hideLogo && (
                <img
                    alt={`${name} logo`}
                    className={styles.logo}
                    src={logo || 'https://assets.procurement.opengov.com/assets/no-logo.png'}
                />
            )}
            <span className={`${hideLink ? '' : 'pseudoLink'} ${styles.vendorName}`}>{name}</span>
            {hideRating ? null : (
                <ProcuratedBadge badgeInHover ratingFromParent={vendorRating || null} />
            )}
        </Box>
    ) : (
        <div className={styles.vendorDataCell}>
            {!hideLogo && (
                <img
                    alt={`${name} logo`}
                    className={styles.logo}
                    src={logo || 'https://assets.procurement.opengov.com/assets/no-logo.png'}
                />
            )}
            <span className={`${hideLink ? '' : 'pseudoLink'} ${styles.vendorName}`}>{name}</span>
            {hideRating ? null : (
                <ProcuratedBadge badgeInHover ratingFromParent={vendorRating || null} />
            )}
        </div>
    );
};

ProcuratedVendorCell.propTypes = {
    expandable: PropTypes.bool,
    hideLink: PropTypes.bool,
    hideLogo: PropTypes.bool,
    params: PropTypes.object,
};
