import PropTypes from 'prop-types';
import React from 'react';

import { subsectionTypeNames } from '@og-pro/shared-config/subsections';

import { IntroductionTitle } from './IntroductionTitle';
import { SectionIntro } from '../SectionIntro';
import { HtmlContent } from '../../../HtmlContent/HtmlContent';

const { BACKGROUND } = subsectionTypeNames;

export const Background = ({
    description,
    isDocx,
    isEditable,
    itemNumber,
    project: { background },
    projectSection,
    projectSubsection: { id, title },
    sectionDescActions,
    showComments,
    tagOptions,
    templateVariableOptions,
}) => {
    if (!background) {
        return null;
    }

    return (
        <div className="introduction-subsection" id={`project-field-project_subsection_id.${id}`}>
            <div className="row">
                <div className="col-xs-12">
                    <IntroductionTitle
                        isDocx={isDocx}
                        itemNumber={itemNumber}
                        projectSection={projectSection}
                        showComments={showComments}
                        subsectionType={BACKGROUND}
                        title={title}
                    />
                </div>
            </div>
            <SectionIntro
                {...sectionDescActions}
                isDocx={isDocx}
                isEditable={isEditable}
                isSubsection
                projectSectionId={projectSection.id}
                projectSubsectionId={id}
                sectionDescription={description[id]}
                tagOptions={tagOptions}
                templateVariableOptions={templateVariableOptions}
            />
            <div className="row">
                <div className="col-xs-12">
                    <HtmlContent
                        className="introduction-description article"
                        content={background}
                    />
                </div>
            </div>
        </div>
    );
};

Background.propTypes = {
    description: PropTypes.object.isRequired,
    isDocx: PropTypes.bool,
    isEditable: PropTypes.bool,
    itemNumber: PropTypes.string.isRequired,
    project: PropTypes.shape({
        background: PropTypes.string,
    }).isRequired,
    projectSection: PropTypes.object.isRequired,
    projectSubsection: PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
    }).isRequired,
    sectionDescActions: PropTypes.object,
    showComments: PropTypes.bool,
    tagOptions: PropTypes.array,
    templateVariableOptions: PropTypes.array,
};
