import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { UserProfilePicture } from '../../UserProfilePicture/UserProfilePicture';
import { CommentHeader } from './CommentHeader';

export class Comment extends PureComponent {
    static propTypes = {
        comment: PropTypes.string.isRequired,
        commentDate: PropTypes.string.isRequired,
        user: PropTypes.object.isRequired,
        isSelected: PropTypes.bool.isRequired,
    };

    render() {
        const { comment, commentDate, user, isSelected } = this.props;
        const styles = require('./Comment.scss');
        const commentContainerStyle = isSelected
            ? `${styles.commentContainer} ${styles.selectedComment}`
            : styles.commentContainer;

        return (
            <div className={commentContainerStyle}>
                <div className={styles.commentPicture}>
                    <UserProfilePicture showTooltip user={user} />
                </div>
                <div className={styles.commentContent}>
                    <CommentHeader commentDate={commentDate} name={user.displayName} />
                    <div>{comment}</div>
                </div>
            </div>
        );
    }
}
