import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { SectionDescriptionForm } from './SectionDescriptionForm';
import { form } from './SectionDescriptionForm/constants';
import { Button } from '../../Button';
import { EditIcon } from '../../EditIcon';
import { HtmlContent } from '../../HtmlContent/HtmlContent';

export class SectionIntro extends PureComponent {
    static propTypes = {
        isDocx: PropTypes.bool,
        isEditable: PropTypes.bool,
        isSubsection: PropTypes.bool,
        projectSectionId: PropTypes.number.isRequired,
        projectSubsectionId: PropTypes.number.isRequired,
        sectionDescription: PropTypes.shape({
            addError: PropTypes.string,
            deleteError: PropTypes.string,
            deleting: PropTypes.bool,
            description: PropTypes.string,
            id: PropTypes.number,
            rawDescription: PropTypes.string,
            showForm: PropTypes.bool,
            updateError: PropTypes.string,
        }),
        sectionDescriptionCreate: PropTypes.func,
        sectionDescriptionDelete: PropTypes.func,
        sectionDescriptionToggleForm: PropTypes.func,
        sectionDescriptionUpdate: PropTypes.func,
        tagOptions: PropTypes.array,
        templateVariableOptions: PropTypes.array,
    };

    static defaultProps = {
        isDocx: false,
        isEditable: false,
        isSubsection: false,
        sectionDescription: {},
        sectionDescriptionCreate: undefined,
        sectionDescriptionDelete: undefined,
        sectionDescriptionToggleForm: undefined,
        sectionDescriptionUpdate: undefined,
        tagOptions: undefined,
        templateVariableOptions: undefined,
    };

    componentDidUpdate(prevProps) {
        const {
            projectSectionId: prevProjectSectionId,
            projectSubsectionId: prevProjectSubsectionId,
            sectionDescription: { showForm },
        } = prevProps;

        const { projectSectionId, projectSubsectionId, sectionDescriptionToggleForm } = this.props;

        /**
         * The idea is to hide an open form whenever the SectionIntro is
         * navigated away from. Sometimes that means the component will unmount
         * (which is handled below), other times it will just update the
         * component, which is what is checked for here.
         */
        if (
            showForm &&
            (prevProjectSectionId !== projectSectionId ||
                prevProjectSubsectionId !== projectSubsectionId)
        ) {
            sectionDescriptionToggleForm(false, prevProjectSectionId, prevProjectSubsectionId);
        }
    }

    componentWillUnmount() {
        // Hide form if it is open (see componentDidUpdate for details)
        if (this.props.sectionDescription.showForm) {
            this.toggleFormHandler(false);
        }
    }

    get addFormProps() {
        const {
            projectSectionId,
            projectSubsectionId,
            sectionDescription,
            sectionDescriptionCreate,
        } = this.props;

        return {
            form: `${form}${projectSectionId}${projectSubsectionId}`,
            isEditForm: false,
            onSubmit: sectionDescriptionCreate(projectSectionId, projectSubsectionId),
            submitServerError: sectionDescription.addError,
        };
    }

    get editFormProps() {
        const { sectionDescription, sectionDescriptionDelete, sectionDescriptionUpdate } =
            this.props;

        return {
            deleting: sectionDescription.deleting,
            form: `${form}${sectionDescription.id}`,
            handleDelete: () => sectionDescriptionDelete(sectionDescription),
            isEditForm: true,
            initialValues: { rawDescription: sectionDescription.rawDescription },
            onSubmit: sectionDescriptionUpdate(sectionDescription),
            submitServerError: sectionDescription.updateError || sectionDescription.deleteError,
        };
    }

    handleAddOptionalDescriptionClick = () => {
        this.toggleFormHandler(true);
    };

    handleEditClick = () => {
        this.toggleFormHandler(true);
    };

    handleToggleFormClose = () => {
        this.toggleFormHandler(false);
    };

    toggleFormHandler = (showForm) => {
        const { projectSectionId, projectSubsectionId, sectionDescriptionToggleForm } = this.props;

        return sectionDescriptionToggleForm(showForm, projectSectionId, projectSubsectionId);
    };

    renderEmptySection() {
        const { isSubsection } = this.props;

        return (
            <div
                className={
                    isSubsection ? 'empty-subsection-description' : 'empty-section-description'
                }
            />
        );
    }

    renderAddSection() {
        const { isEditable, isSubsection } = this.props;

        if (!isEditable) {
            return this.renderEmptySection();
        }

        return (
            <Button
                bsStyle="link"
                className={isSubsection ? 'add-subsection-description' : 'add-section-description'}
                onClick={this.handleAddOptionalDescriptionClick}
                qaTag="sectionIntro-addOptionalDescription"
            >
                Add optional description to {isSubsection ? 'subsection' : 'section'}
            </Button>
        );
    }

    renderForm() {
        const {
            isSubsection,
            sectionDescription: { id },
            tagOptions,
            templateVariableOptions,
        } = this.props;

        const formProps = id ? this.editFormProps : this.addFormProps;

        return (
            <SectionDescriptionForm
                className={isSubsection ? 'subsection-description' : 'section-description'}
                closeForm={this.handleToggleFormClose}
                {...formProps}
                tagOptions={tagOptions}
                templateVariableOptions={templateVariableOptions}
            />
        );
    }

    render() {
        const {
            isDocx,
            isEditable,
            isSubsection,
            sectionDescription: { description, showForm },
        } = this.props;

        if (showForm && !isDocx) return this.renderForm();
        if (!description) return this.renderAddSection();

        return (
            <div
                className={classnames(
                    'section-description-text',
                    isSubsection ? 'subsection-description' : 'section-description'
                )}
            >
                <HtmlContent content={description} />
                <EditIcon onClick={this.handleEditClick} show={isEditable} />
            </div>
        );
    }
}
