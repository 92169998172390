import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { DraftReviewToggle } from '../DraftReviewToggle/DraftReviewToggle';
import { Label } from '../InputText/Label';
import { trackEvent } from '../../helpers';

export const ProjectModeToggle = (props) => {
    const navigate = useNavigate();

    const toggleHandler = (e) => {
        const switchToDraftMode = e.target.checked;

        const { writingPath, reviewPath } = props;
        const currentRoute = switchToDraftMode ? writingPath : reviewPath;
        let nextRoute = switchToDraftMode ? reviewPath : writingPath;

        trackEvent('Project mode toggle', { switchToDraftMode, currentRoute, nextRoute });

        if (props.section && !nextRoute.includes('document-editor')) {
            nextRoute += `?section=${props.section}`;
        }

        return navigate(nextRoute);
    };

    const styles = require('./ProjectModeToggle.scss');

    return (
        <div className={props.className}>
            <Label>
                <span className={`${styles.text} hidden-sm`}>Edit</span>
                <DraftReviewToggle checked={!props.checked} onChange={toggleHandler} />
                <span className={`${styles.text} hidden-sm`}>Preview</span>
            </Label>
        </div>
    );
};

ProjectModeToggle.propTypes = {
    checked: PropTypes.bool.isRequired,
    writingPath: PropTypes.string.isRequired,
    reviewPath: PropTypes.string.isRequired,
    className: PropTypes.string,
    section: PropTypes.string,
};
