import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Button } from '../Button';
import { CategoryCodeListItem } from './CategoryCodeListItem';

export class CategoryCodesLongForm extends PureComponent {
    static propTypes = {
        codes: PropTypes.array.isRequired,
        limit: PropTypes.number,
        maxCategoryTextLength: PropTypes.number,
        noCategoriesText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        showCodes: PropTypes.bool,
    };

    static defaultProps = {
        limit: 2, // Use null to specify no limit
        maxCategoryTextLength: 100,
        showCodes: true,
    };

    constructor(props) {
        super(props);
        this.state = { showAll: false };
    }

    handleClick = () => {
        this.setState({ showAll: true });
    };

    render() {
        const { codes, limit, maxCategoryTextLength, noCategoriesText, showCodes } = this.props;
        const {
            longFormList,
            longFormListItemNoBorder,
            extraCategories,
        } = require('./CategoryCodes.scss');

        // Ensure more than 1 item are being hidden
        const imposedLimit = limit ? limit + 1 : codes.length;
        const hasLimit = imposedLimit < codes.length;
        const showAll = this.state.showAll || !hasLimit;
        const displayCodes = showAll ? codes : codes.slice(0, limit);

        const hasCodes = codes.length > 0;
        const hiddenCategoryCount = codes.length - limit;

        return (
            <ul className={`list-unstyled ${longFormList}`}>
                {!hasCodes && <li>{noCategoriesText}</li>}
                {displayCodes.map((category, index) => (
                    <CategoryCodeListItem
                        {...category}
                        key={category.id}
                        maxCategoryTextLength={maxCategoryTextLength}
                        noBorder={index === displayCodes.length - 1 && showAll}
                        showCode={showCodes}
                    />
                ))}
                {!showAll && (
                    <li className={longFormListItemNoBorder}>
                        <span className={extraCategories}>
                            and {hiddenCategoryCount} other categories...
                        </span>
                        &nbsp;
                        <Button
                            bsStyle="link"
                            onClick={this.handleClick}
                            qaTag="categoryCodes-showAll"
                            zeroPadding
                        >
                            show all
                        </Button>
                    </li>
                )}
            </ul>
        );
    }
}
