import classnames from 'classnames';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as UUIDv4 } from 'uuid';

import { VendorDocumentUploadForm } from './VendorDocumentUploadForm';
import { Dropzone } from '../../Dropzone';
import { OutlineButton } from '../../OutlineButton';
import { Well } from '../../Well/Well';
import { universalUploadFile } from '../../../actions/attachments';
import { createVendorContractAttachment } from '../../../actions/contracts';
import { getContractJS } from '../../../containers/selectors';
import { cleanFileName } from '../../../helpers';

export const VendorPendingDocumentsUpload = () => {
    const [filesToUpload, setFilesToUpload] = useState([]);
    const [errorFiles, setErrorFiles] = useState([]);

    const contract = useSelector(getContractJS);
    const dispatch = useDispatch();

    const onDropHandler = (files, rejectedFiles) => {
        if (!files) {
            return null;
        }

        setErrorFiles(rejectedFiles?.length > 0 ? rejectedFiles : []);

        // Add new files to state
        setFilesToUpload((prevFilesToUpload) => {
            return prevFilesToUpload.concat(
                files.map((file) => {
                    const { name, extension } = cleanFileName(file && file.name);
                    return {
                        extension,
                        file,
                        title: name,
                        uuid: UUIDv4(),
                    };
                })
            );
        });
    };

    const removeHandler = (uuid) => {
        setFilesToUpload((prevFilesToUpload) =>
            prevFilesToUpload.filter((fileData) => fileData.uuid !== uuid)
        );
    };

    const onUpload = (fileToUpload, rawFormData) => {
        const formData = {
            ...rawFormData,
            tags: rawFormData.tags && [rawFormData.tags],
        };

        const { extension, file, uuid } = fileToUpload;

        const updateItem = (uploadData) => {
            setFilesToUpload((prevFilesToUpload) =>
                prevFilesToUpload.map((fileUpload) => {
                    if (fileUpload.uuid === uuid) {
                        return {
                            ...fileUpload,
                            uploadData: {
                                ...fileUpload.uploadData,
                                ...uploadData,
                            },
                        };
                    }
                    return fileUpload;
                })
            );
        };

        const filename = extension ? `${formData.title}.${extension}` : formData.title;

        const uploadData = {
            file,
            filename,
            error: null,
            progress: 0,
            uuid,
        };
        updateItem(uploadData);

        const options = {
            attachmentData: formData,
            filename,
            onSuccess: (attachmentData) => {
                return dispatch(createVendorContractAttachment(contract.id, attachmentData));
            },
        };
        const progressHandler = (progress) => updateItem({ progress });

        return dispatch(
            universalUploadFile(`/contracts/${contract.id}/s3`, file, progressHandler, options)
        )
            .then(() => {
                progressHandler(100);
                removeHandler(uuid);
            })
            .catch((error) => {
                updateItem({ error: error.message || 'Unknown error occurred' });
            });
    };

    const styles = require('./VendorPendingDocumentsUpload.scss');

    return (
        <>
            <Dropzone
                containerClassName={classnames(
                    styles.dropzone,
                    filesToUpload.length > 0 && styles.hasFiles
                )}
                dropzoneID="vendorPendingDocumentsUpload"
                fileRejectionErrors={errorFiles.length > 0 ? errorFiles : null}
                onDrop={onDropHandler}
            >
                <OutlineButton bsStyle="primary" qaTag="vendorPendingDocumentsUpload-uploadFiles">
                    <i className="fa fa-plus" /> Upload Files
                </OutlineButton>
            </Dropzone>
            {filesToUpload.map((fileData) => (
                <Well key={fileData.uuid}>
                    <VendorDocumentUploadForm
                        form={`vendorDocumentUpload${fileData.uuid}`}
                        initialValues={{ title: fileData.title }}
                        onSubmit={(formData) => onUpload(fileData, formData)}
                        removeHandler={() => removeHandler(fileData.uuid)}
                        uploadData={fileData.uploadData}
                        uuid={fileData.uuid}
                    />
                </Well>
            ))}
        </>
    );
};
