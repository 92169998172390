import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { hideVendorProfileModal, loadVendor } from '../../actions/vendorProfile';
import { Button } from '../Button';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { LoadingError } from '../LoadingError/LoadingError';
import { VendorProfile } from '../VendorProfile';
import { loadPublicVendor } from '../../actions/publicVendor';
import { getUserJS, isVendorAdminUser } from '../../containers/selectors';
import { getVendorJS } from './selectors';

export const VendorProfileModal = () => {
    const { governmentCode } = useParams();

    const dispatch = useDispatch();

    const user = useSelector(getUserJS);
    const isVendorAdmin = useSelector(isVendorAdminUser);
    const showModal = useSelector((state) => state.vendorProfile.get('showVendorModal'));
    const vendorId = useSelector((state) => state.vendorProfile.get('vendorId'));

    const loadError = useSelector((state) => state.publicVendor.get('loadVendorError'));
    const loading = useSelector((state) => state.publicVendor.get('loadingVendor'));
    const vendor = useSelector((state) =>
        !isVendorAdmin ? state.publicVendor.get('vendor')?.toJS() : getVendorJS(state)
    );

    useEffect(() => {
        if (!vendorId || !showModal) return;

        if (!isVendorAdmin && governmentCode) {
            dispatch(loadPublicVendor(vendorId, governmentCode));
        }

        if (isVendorAdmin) {
            dispatch(loadVendor(vendorId));
        }
    }, [dispatch, vendorId, user?.government?.code, showModal]);

    const renderBody = () => {
        if (loading) return <LoadingSpinner />;
        if (loadError) return <LoadingError error={loadError} />;
        if (!vendor) return null;
        return <VendorProfile publicView={!isVendorAdmin} vendor={vendor} />;
    };

    return (
        <div>
            <Modal onHide={() => dispatch(hideVendorProfileModal())} show={showModal}>
                <Modal.Body>
                    {renderBody()}
                    <div className="text-center">
                        <Button
                            onClick={() => dispatch(hideVendorProfileModal())}
                            qaTag="connectedVendorProfileModal-done"
                        >
                            Done
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};
