import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { HtmlContent } from '../HtmlContent/HtmlContent';
import { getHtmlBlurb, shouldHtmlBeTruncated } from '../../utils';

export class HtmlBlurb extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        content: PropTypes.string,
        contentClassName: PropTypes.string,
        determineHtml: PropTypes.bool,
        maxLength: PropTypes.number.isRequired,
        showMore: PropTypes.bool,
    };

    static defaultProps = {
        className: '',
        contentClassName: '',
        determineHtml: false,
    };

    constructor(props) {
        super(props);
        this.state = { showAll: false };
    }

    get useBlurb() {
        const { content, maxLength } = this.props;

        const { showAll } = this.state;

        return !showAll && shouldHtmlBeTruncated(content, maxLength);
    }

    showAll = () => this.setState({ showAll: true });

    renderContent() {
        const { content, contentClassName, determineHtml, maxLength } = this.props;

        const staticContent = this.useBlurb ? getHtmlBlurb(content, maxLength) : content;

        return (
            <HtmlContent
                className={contentClassName}
                content={staticContent}
                determineHtml={determineHtml}
            />
        );
    }

    render() {
        const { className, showMore } = this.props;
        const showAllButton = showMore && this.useBlurb;

        return (
            <div className={className}>
                {this.renderContent()}
                {showAllButton && (
                    <span className="pseudoLink" onClick={this.showAll}>
                        Show all <i className="fa fa-caret-down" />
                    </span>
                )}
            </div>
        );
    }
}
