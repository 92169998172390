import PropTypes from 'prop-types';
import React from 'react';

import { TemplateListBody } from './TemplateListBody';
import { TemplateListNav } from '../TemplateListNav';
import { Main } from '../../Main';

export const TemplateList = ({
    createError,
    hasMultipleTypes,
    hasTemplates,
    location,
    navItems,
    routingHandler,
    selectedNavItem,
    SelectFilter,
    TemplateCreateButton,
    templateItems,
    title,
}) => {
    return (
        <Main>
            <TemplateListNav
                SelectFilter={SelectFilter}
                TemplateCreateButton={TemplateCreateButton}
                createError={createError}
                location={location}
                navItems={navItems}
                selectedNavItem={selectedNavItem}
                title={title}
            />
            <TemplateListBody
                TemplateCreateButton={TemplateCreateButton}
                hasMultipleTypes={hasMultipleTypes}
                hasTemplates={hasTemplates}
                routingHandler={routingHandler}
                templateItems={templateItems}
            />
        </Main>
    );
};

TemplateList.propTypes = {
    createError: PropTypes.string,
    hasMultipleTypes: PropTypes.bool,
    hasTemplates: PropTypes.bool,
    location: PropTypes.object.isRequired,
    navItems: PropTypes.arrayOf(PropTypes.object).isRequired,
    routingHandler: PropTypes.func.isRequired,
    selectedNavItem: PropTypes.object,
    SelectFilter: PropTypes.node,
    TemplateCreateButton: PropTypes.func.isRequired,
    templateItems: PropTypes.arrayOf(
        PropTypes.shape({
            created_at: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
            template: PropTypes.shape({
                icon: PropTypes.string,
                title: PropTypes.string,
                updated_at: PropTypes.string.isRequired,
            }).isRequired,
            type: PropTypes.string,
            updated_at: PropTypes.string.isRequired,
            user: PropTypes.shape({
                displayName: PropTypes.string.isRequired,
            }),
        })
    ).isRequired,
    title: PropTypes.string.isRequired,
};
