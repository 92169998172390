import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { bidBondQuestionnaireFieldNamesDict, DATA } from '@og-pro/shared-config/questionnaires';

import { GenericFormBuilder } from '../../SharedFormBuilder';
import { InputText } from '../../../../InputText';
import { MultipleChoiceInput } from '../../../../MultipleChoiceInput';
import { bidBondSecuritySelectOptions } from '../constants';

const { BID_BOND_PROJECT_ID, BID_BOND_SECURITY, BID_BOND_SECURITY_IS_PERCENTAGE } =
    bidBondQuestionnaireFieldNamesDict;

export const BidBondFormBuilder = ({
    disabled,
    form,
    isTitleEditingDisabled,
    tagOptions,
    templateVariableOptions,
    useRawPrompt,
}) => {
    return (
        <GenericFormBuilder
            disabled={disabled}
            form={form}
            isTitleEditingDisabled={isTitleEditingDisabled}
            tagOptions={tagOptions}
            templateVariableOptions={templateVariableOptions}
            useRawPrompt={useRawPrompt}
        >
            <Field
                component={InputText}
                disabled={disabled}
                help="The Project or Contract ID that will be used to create the bid bond."
                label="Project ID"
                name={`${DATA}.${BID_BOND_PROJECT_ID}`}
                showValidation
                type="text"
            />
            <Field
                component={MultipleChoiceInput}
                disabled={disabled}
                help="Whether the bid security is a percentage or fixed dollar amount."
                label="Percentage or Amount"
                name={`${DATA}.${BID_BOND_SECURITY_IS_PERCENTAGE}`}
                options={bidBondSecuritySelectOptions}
                showValidation
            />
            <Field
                component={InputText}
                disabled={disabled}
                help="The percentage or fixed dollar amount for the bid security."
                label="Bid Security"
                name={`${DATA}.${BID_BOND_SECURITY}`}
                showValidation
                type="number"
            />
        </GenericFormBuilder>
    );
};

BidBondFormBuilder.propTypes = {
    disabled: PropTypes.bool,
    form: PropTypes.string.isRequired,
    isTitleEditingDisabled: PropTypes.bool,
    tagOptions: PropTypes.array,
    templateVariableOptions: PropTypes.array,
    useRawPrompt: PropTypes.bool,
};

BidBondFormBuilder.defaultProps = {
    tagOptions: undefined,
    templateVariableOptions: undefined,
    useRawPrompt: false,
};
