import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field } from 'redux-form';

import { fieldNames } from '../constants';
import { Button } from '../../../../Button';
import { InputText } from '../../../../InputText';
import { SearchSelect } from '../../../../SearchSelect/SearchSelect';
import { maskNumberWithCommas } from '../../../../../Forms/maskers';
import { dollarString } from '../../../../../Forms/normalizers';
import { MaskedInputText } from '../../../../../hocs';

const { AMOUNT, TAG_ID } = fieldNames;

export class ContractBudgetAllocations extends Component {
    static propTypes = {
        disabled: PropTypes.bool,
        fields: PropTypes.object.isRequired,
        fiscalYearSelectOptions: PropTypes.array.isRequired,
    };

    MaskedBudgetInput = MaskedInputText(InputText);

    render() {
        const { disabled, fiscalYearSelectOptions, fields } = this.props;

        if (fields.length === 0) {
            return null;
        }

        const usedTags = [];
        fields.forEach((field, index) => {
            const budgetAllocation = fields.get(index);
            const tagId = budgetAllocation[TAG_ID];
            if (tagId) {
                usedTags.push(tagId);
            }
        });

        return fields.map((field, index) => {
            const budgetAllocation = fields.get(index);

            const options = fiscalYearSelectOptions.filter((option) => {
                if (budgetAllocation[TAG_ID] === option.value) {
                    return true;
                }
                return !usedTags.includes(option.value);
            });

            return (
                <div className="row" key={index}>
                    <div className="col-sm-5">
                        <Field
                            aria-label="Select Fiscal Year"
                            component={SearchSelect}
                            disabled={disabled}
                            name={`${field}.${TAG_ID}`}
                            noOptionsMessage={
                                'Your organization has not created any fiscal years yet. ' +
                                'Admin users can add fiscal year tags through the admin ' +
                                'interface.'
                            }
                            options={options}
                            placeholder="Fiscal Year"
                        />
                    </div>
                    <div className="col-sm-6">
                        <Field
                            aria-label="Enter Amount"
                            component={this.MaskedBudgetInput}
                            disabled={disabled}
                            hasFeedback={false}
                            inputGroupPrefix="$"
                            mask={maskNumberWithCommas}
                            name={`${field}.${AMOUNT}`}
                            normalizer={dollarString}
                            placeholder="Enter Amount"
                            type="text"
                        />
                    </div>
                    <div className="col-sm-1">
                        <Button
                            aria-label="Remove Contract Amount"
                            bsStyle="link"
                            onClick={() => fields.remove(index)}
                            style={{ marginTop: 5 }}
                            zeroPadding
                        >
                            <i className="fa fa-lg fa-times text-danger" />
                        </Button>
                    </div>
                </div>
            );
        });
    }
}
