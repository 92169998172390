import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Badge } from '../../Badge';
import { getHideClosedProjectsSettings } from '../../../containers/GovApp/App/selectors';
import { HiddenFromPublicBadge } from './HiddenFromPublicBadge';

export const ProjectListItemBadgeGroup = (props) => {
    const { hidePublicClosedProjectsLength, hidePublicClosedProjectsUnit } = useSelector(
        getHideClosedProjectsSettings
    );

    const {
        project: {
            hasSealedBid,
            isPrivate,
            isPublicBidPricingResult,
            isPublicBidResult,
            template: { omitDocx },
        },
    } = props;

    return (
        <div>
            {(isPublicBidPricingResult || isPublicBidResult) && (
                <Badge icon="bullhorn" tooltipText="Vendor Responses Public" />
            )}
            {isPrivate && <Badge icon="lock" tooltipText="Private Bid" />}
            {hasSealedBid && <Badge icon="envelope" tooltipText="Using Sealed Bid Process" />}
            {!omitDocx && <Badge icon="file-text" tooltipText="Doc Builder" />}
            <HiddenFromPublicBadge
                hidePublicClosedProjectsLength={hidePublicClosedProjectsLength}
                hidePublicClosedProjectsUnit={hidePublicClosedProjectsUnit}
                project={props.project}
            />
        </div>
    );
};

ProjectListItemBadgeGroup.propTypes = {
    project: PropTypes.shape({
        hasSealedBid: PropTypes.bool.isRequired,
        isPrivate: PropTypes.bool.isRequired,
        isPublicBidPricingResult: PropTypes.bool.isRequired,
        isPublicBidResult: PropTypes.bool.isRequired,
        template: PropTypes.shape({
            omitDocx: PropTypes.bool.isRequired,
        }).isRequired,
    }).isRequired,
};
