import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { proposalDocumentFieldNames } from './constants';
import { ProposalDocumentsItem } from './ProposalDocumentsItem';
import { ListError } from '../ListError';
import { Button } from '../../../Button';

const { IS_REQUIRED, ORDER_BY_ID } = proposalDocumentFieldNames;

export class ProposalDocumentsList extends PureComponent {
    static propTypes = {
        fields: PropTypes.object.isRequired,
        meta: PropTypes.object.isRequired,
        disabled: PropTypes.bool.isRequired,
        showFormErrors: PropTypes.bool.isRequired,
    };

    componentDidMount() {
        // Add an empty item if no items in array
        if (this.props.fields.length === 0) return this.addField();
    }

    get styles() {
        return require('./ProposalDocumentsList.scss');
    }

    addField = () => {
        const { fields } = this.props;
        const lastItem = fields.get(fields.length - 1);
        this.props.fields.push({
            isRequired: get(lastItem, IS_REQUIRED, false),
            orderById: get(lastItem, ORDER_BY_ID, 0) + 1,
        });
    };

    removeField = (index) => {
        return this.props.fields.remove(index);
    };

    render() {
        const { fields, meta, disabled, showFormErrors } = this.props;

        const ProposalDocuments = fields.map((member, index) => {
            return (
                <ProposalDocumentsItem
                    arrayName={member}
                    disabled={disabled}
                    index={index}
                    key={index}
                    remove={this.removeField}
                    showFormErrors={showFormErrors}
                />
            );
        });

        return (
            <div>
                <ListError meta={meta} />
                {ProposalDocuments}
                <div className="text-center">
                    <Button
                        className={this.styles.addBtn}
                        disabled={disabled}
                        onClick={this.addField}
                    >
                        <i className="fa fa-plus" /> Add Document
                    </Button>
                </div>
            </div>
        );
    }
}
