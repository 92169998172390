import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Panel } from 'react-bootstrap';

import { Button } from '../../Button';
import { Nav } from '../../Nav';
import { NavItem } from '../../Nav/NavItem';
import { ProjectProfile } from '../../ProjectProfile';
import { SubscribeButton } from '../../SubscribeButton/SubscribeButton';
import { EventsRsvpButton } from './EventsRsvpButton';

const DEFAULT_HELP_CENTER_URL =
    'https://opengov.my.site.com/support/s/topic/0TO8X000000Mhi0WAC/vendor-guides';

export class ProjectNavbar extends PureComponent {
    static propTypes = {
        applyToProject: PropTypes.func,
        children: PropTypes.node.isRequired,
        navItems: PropTypes.arrayOf(
            PropTypes.shape({
                active: PropTypes.bool,
                className: PropTypes.string,
                indexRoute: PropTypes.bool,
                link: PropTypes.string.isRequired,
                title: PropTypes.string.isRequired,
            })
        ).isRequired,
        noBidProject: PropTypes.func,
        owner: PropTypes.bool,
        project: PropTypes.shape({
            government: PropTypes.shape({
                code: PropTypes.string.isRequired,
                hasSourcing: PropTypes.bool,
                helpCenterUrl: PropTypes.string,
                organization: PropTypes.shape({
                    logo: PropTypes.string,
                    name: PropTypes.string,
                }).isRequired,
            }).isRequired,
            isLibrary: PropTypes.bool,
            status: PropTypes.string,
            title: PropTypes.string,
        }).isRequired,
        publicUrl: PropTypes.string,
        showApply: PropTypes.bool,
        showTimer: PropTypes.bool,
        subscribed: PropTypes.bool.isRequired,
        timezone: PropTypes.string.isRequired,
        user: PropTypes.object,
    };

    get buttons() {
        const buttons = [];

        if (this.props.showApply) {
            buttons.push(this.renderApplyButtons());
        }

        return buttons;
    }

    get styles() {
        return require('./index.scss');
    }

    renderApplyButtons() {
        const { applyToProject, noBidProject, project, timezone, user } = this.props;

        return (
            <>
                <Button bsSize="sm" bsStyle="success" onClick={applyToProject}>
                    <i className="fa fa-pencil" /> Draft Response
                </Button>
                &nbsp;&nbsp;
                <EventsRsvpButton project={project} timezone={timezone} user={user} />
                &nbsp;&nbsp;
                <Button bsSize="sm" onClick={noBidProject}>
                    <i className="fa fa-ban" /> No Bid
                </Button>
            </>
        );
    }

    renderHelpButton() {
        const {
            project: {
                government: { helpCenterUrl },
            },
        } = this.props;

        return (
            <div className="pull-left no-print">
                <Button
                    bsStyle="link"
                    href={helpCenterUrl || DEFAULT_HELP_CENTER_URL}
                    target="_blank"
                >
                    <i className="fa fa-question-circle" />
                    &nbsp; Visit Help Center
                </Button>
            </div>
        );
    }

    renderPortalButton() {
        const {
            project: {
                government: {
                    code,
                    hasSourcing,
                    organization: { name },
                },
            },
        } = this.props;

        if (!hasSourcing) {
            return null;
        }

        return (
            <div className="pull-right no-print">
                <Button bsStyle="link" to={`/portal/${code}`}>
                    <i className="fa fa-external-link" />
                    &nbsp;
                    {name} Portal
                </Button>
            </div>
        );
    }

    renderProjectProfile() {
        const { project, publicUrl, showTimer, subscribed, timezone } = this.props;

        const status = project.status;

        const baseProjectProfileProps = {
            project,
            title: project.title,
            imageUrl: project.government.organization.logo,
            portalUrl: `/portal/${project.government.code}`,
            status,
            timezone,
        };

        if (project.isLibrary) {
            return <ProjectProfile {...baseProjectProfileProps} />;
        }

        const subscribeButton = <SubscribeButton project={project} />;

        return (
            <ProjectProfile
                {...baseProjectProfileProps}
                buttons={this.buttons}
                isPublicView
                publicUrl={publicUrl}
                showShareButtons
                showTimer={showTimer}
                subscribeButton={subscribeButton}
                subscribed={subscribed}
            />
        );
    }

    render() {
        const { children, navItems } = this.props;

        const navigationItems = navItems.map((item) => (
            <NavItem
                className={classnames(item.className, item.active && 'active')}
                key={`${item.title}:${item.link}`}
                onlyActiveOnIndex={item.indexRoute}
                qaTag={`navbar-${item.title}`}
                to={item.link}
                {...(item.onClick ? { onClick: item.onClick } : {})}
            >
                {item.title}
            </NavItem>
        ));

        return (
            <>
                {this.renderHelpButton()}
                {this.renderPortalButton()}
                <div className="clearfix" />
                {this.renderProjectProfile()}
                <Panel className="print-panel">
                    <Panel.Body>
                        <Nav
                            bsStyle="tabs"
                            className={`${this.styles.projectNav} no-print`}
                            justified={navItems.length <= 6}
                        >
                            {navigationItems}
                        </Nav>
                        <div className={this.styles.childContainer}>{children}</div>
                    </Panel.Body>
                </Panel>
            </>
        );
    }
}
