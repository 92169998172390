import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Field } from 'redux-form';

import { PROMPT, RAW_PROMPT } from '@og-pro/shared-config/questionnaires';

import { RichTextInput } from '../../../RichTextInput';

export class Prompt extends PureComponent {
    static propTypes = {
        disabled: PropTypes.bool,
        tagOptions: PropTypes.array,
        templateVariableOptions: PropTypes.array,
        useRawPrompt: PropTypes.bool,
    };

    static defaultProps = {
        tagOptions: undefined,
        templateVariableOptions: undefined,
        useRawPrompt: false,
    };

    render() {
        const { disabled, tagOptions, templateVariableOptions, useRawPrompt } = this.props;

        return (
            <Field
                component={RichTextInput}
                disabled={disabled}
                help="Provide instructions for responding (optional)"
                label="Prompt"
                minRows={2}
                name={useRawPrompt ? RAW_PROMPT : PROMPT}
                placeholder="Enter prompt"
                tagOptions={tagOptions}
                templateVariableOptions={templateVariableOptions}
            />
        );
    }
}
