import PropTypes from 'prop-types';
import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { compose } from 'redux';
import { FormControl, FormGroup } from 'react-bootstrap';
import { Box } from '@og-pro/ui';

import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE_LIMIT } from '@og-pro/shared-config/contracts';

import { ListView } from './ListView';
import { TableView } from './TableView';
import { NoContracts } from './NoContracts';
import { Pagination } from '../Pagination/Pagination';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { LoadingError } from '../LoadingError/LoadingError';
import { ZeroState } from '../ZeroState';
import { loadContracts } from '../../actions/contracts';
import { menuActionHandler } from '../../actions/govProjects';
import connectData from '../../containers/ConnectData';
import {
    getContractsJS,
    getUserJS,
    isContractAdminUser,
    isDepartmentContractAdminUser,
} from '../../containers/selectors';
import { CREATE_CONTRACT_FROM_PROJECT } from '../../constants/menuActions';
import { conditionalPropCheck } from '../../utils';
import { dataTypesDict } from '../../constants';
import { storeLastFilter } from '../../actions/auth';
import { getUserDefaultPageSize } from '../../containers/GovApp/GovernmentContractsListNav/selectors';

const { BOOLEAN, STRING } = dataTypesDict;
const PAGE_SIZE_OPTIONS = [5, 10, 20, 25, 50, 100];

const mapStateToProps = (state, props) => {
    const projectId = props.params.projectId
        ? Number.parseInt(props.params.projectId, 10)
        : undefined;

    return {
        contracts: getContractsJS(state),
        contractsCount: state.contracts.get('contractsCount'),
        loadError: state.contracts.get('loadContractsError'),
        loading: state.contracts.get('loadingContracts'),
        projectId,
        // We use the list view within a project, the table view otherwise
        useProjectListView: !!projectId,
        user: getUserJS(state),
        userDefaultPageSize: getUserDefaultPageSize(state),
    };
};

const mapDispatchToProps = {
    dispatchStoreLastFilter: storeLastFilter,
};

// @withRouter
// @connect
class ConnectedContractList extends Component {
    static propTypes = {
        contracts: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                project_id: PropTypes.number,
            })
        ).isRequired,
        contractsCount: PropTypes.number.isRequired,
        defaultSorted: PropTypes.arrayOf(
            PropTypes.shape({
                id: conditionalPropCheck(STRING, ({ props }) => !props.isPublicView),
                desc: conditionalPropCheck(BOOLEAN, ({ props }) => !props.isPublicView),
            })
        ),
        executeSearch: PropTypes.func.isRequired,
        filtered: PropTypes.bool,
        fullWidth: PropTypes.bool,
        gridButtons: PropTypes.array,
        hidePagination: PropTypes.bool,
        isContractAdmin: PropTypes.bool,
        isPublicView: PropTypes.bool,
        loadError: PropTypes.string,
        loading: PropTypes.bool.isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
            query: PropTypes.shape({
                page: PropTypes.string,
                limit: PropTypes.string,
            }).isRequired,
        }).isRequired,
        menuActionHandler: PropTypes.func,
        params: PropTypes.shape({
            // Used by @connect
            projectId: PropTypes.string,
        }).isRequired,
        projectId: PropTypes.number,
        resetDefaultSorted: PropTypes.func.isRequired,
        useProjectListView: PropTypes.bool.isRequired,
        user: PropTypes.shape({
            lastFilter: PropTypes.shape({
                contract: PropTypes.object,
            }),
        }),
        userDefaultPageSize: PropTypes.number,
    };

    static defaultProps = {
        filtered: false,
        loadError: undefined,
        isPublicView: false,
    };

    constructor(props) {
        super(props);

        const { location, userDefaultPageSize } = props;

        const {
            query: { limit },
        } = location;

        const pageSizeFromQueryParams = limit;
        let pageSize = userDefaultPageSize ?? DEFAULT_PAGE_SIZE_LIMIT;
        if (pageSizeFromQueryParams) {
            pageSize = Number.parseInt(pageSizeFromQueryParams, 10);
        }

        this.state = {
            pageSize,
        };
    }

    get paginationPage() {
        const {
            location: {
                query: { page },
            },
        } = this.props;

        return page && !Number.isNaN(Number.parseFloat(page)) ? parseInt(page, 10) : DEFAULT_PAGE;
    }

    get paginationPages() {
        const { pageSize } = this.state;
        const { contractsCount } = this.props;

        return Math.ceil(contractsCount / pageSize);
    }

    get styles() {
        return require('./index.scss');
    }

    addContract = () => {
        const { projectId } = this.props;

        this.props.menuActionHandler(CREATE_CONTRACT_FROM_PROJECT, { id: projectId });
    };

    pageResults = (page) => {
        const { pageSize } = this.state;
        const { executeSearch } = this.props;

        // Example - If there are 500 contracts, and the page size is set to 10, there are 50 pages with 10 contracts each.
        // If the user is currently on page 9, and they switch the page size to 100, then they should be brought back to page 1,
        // since there are now 5 pages of 100 contracts. Page 9 does not exist in this case, and we want to avoid the user seeing no results.
        const shouldMoveUserToFirstPage = page > this.paginationPages;
        const newPage = shouldMoveUserToFirstPage ? 1 : page;

        executeSearch({ page: newPage, limit: pageSize });
    };

    renderPageSizeSelect() {
        const { pageSize } = this.state;
        const { pageSizeSelect } = this.styles;

        const onPageSizeChange = async (event) => {
            const newPageSize = Number.parseInt(event.target.value, 10);

            this.setState({ pageSize: newPageSize }, () => this.pageResults(this.paginationPage));
        };

        return (
            <FormGroup bsSize="small" className={pageSizeSelect} controlId="pageSizeSelect">
                <FormControl
                    aria-label="Contract list page size"
                    componentClass="select"
                    id="pageSizeSelect"
                    onChange={onPageSizeChange}
                    value={pageSize}
                >
                    {PAGE_SIZE_OPTIONS.map((pageSizeOption, idx) => (
                        <option key={idx} value={pageSizeOption}>{`${pageSizeOption} rows`}</option>
                    ))}
                </FormControl>
            </FormGroup>
        );
    }

    renderPagination(displayText) {
        const { pageSize } = this.state;
        const {
            contractsCount,
            loading,
            location: {
                query: { page },
            },
        } = this.props;

        // Render only the page size select dropdown if there is only one page of results
        if (contractsCount <= pageSize) {
            return this.renderPageSizeSelect();
        }
        if (loading && !page) {
            return null;
        }

        const { pagination, flexPaginationContainer, paginationTextRightSide } = this.styles;

        return (
            <div className="row">
                <div className={`col-sm-12 ${flexPaginationContainer}`}>
                    <Pagination
                        activePage={this.paginationPage}
                        className={pagination}
                        items={this.paginationPages}
                        maxButtons={4}
                        next={this.paginationPage !== this.paginationPages}
                        onSelect={this.pageResults}
                        prev={this.paginationPage !== 1}
                    />
                    {displayText && (
                        <div className={paginationTextRightSide}>
                            {(this.paginationPage - 1) * pageSize + 1}-
                            {this.paginationPage * pageSize}
                            &nbsp;of&nbsp;
                            {contractsCount}&nbsp;Contracts
                        </div>
                    )}
                    {this.renderPageSizeSelect()}
                </div>
            </div>
        );
    }

    render() {
        const { pageSize } = this.state;
        const {
            contracts,
            defaultSorted,
            executeSearch,
            hidePagination,
            filtered,
            fullWidth,
            gridButtons,
            isContractAdmin,
            loadError,
            loading,
            isPublicView,
            useProjectListView,
        } = this.props;

        if (loading) {
            return <LoadingSpinner />;
        }
        if (loadError) {
            return <LoadingError error={loadError} />;
        }

        if (contracts.length === 0 && useProjectListView) {
            const buttonText = (
                <span>
                    <i className="fa fa-plus" /> Add Contract
                </span>
            );
            return (
                <ZeroState
                    buttonClickHandler={isPublicView ? undefined : this.addContract}
                    buttonProps={
                        isContractAdmin
                            ? {}
                            : { disabled: true, tooltip: 'Only contract admins can add contracts' }
                    }
                    buttonText={isPublicView ? undefined : buttonText}
                    title="No contracts have been added yet"
                />
            );
        }

        let ListViewComponent = TableView;

        if (useProjectListView) {
            ListViewComponent = ListView;
        }

        if (contracts.length === 0) {
            if (filtered) {
                return (
                    <ListViewComponent
                        buttons={gridButtons}
                        contracts={contracts}
                        executeSearch={executeSearch}
                        fullWidth={fullWidth}
                        isPublicView={isPublicView}
                        noDataText={
                            <div style={{ textAlign: 'center' }}>
                                <div>
                                    <strong>No results found</strong>
                                </div>
                                <div>No contracts were found for the current filters.</div>
                            </div>
                        }
                        resetDefaultSorted={this.props.resetDefaultSorted}
                        showPagination={false}
                    />
                );
            }

            return <NoContracts isPublic={isPublicView} />;
        }

        return (
            <div className="row">
                <div className="col-xs-12">
                    <div className="col-sm-12">
                        {isPublicView && (
                            <div style={{ marginBottom: '16px', textAlign: 'center' }}>
                                <h3>Contracts Portal</h3>
                            </div>
                        )}
                        <ListViewComponent
                            buttons={gridButtons}
                            contracts={contracts}
                            defaultSorted={defaultSorted}
                            executeSearch={executeSearch}
                            fullWidth={fullWidth}
                            isPublicView={isPublicView}
                            pageSize={pageSize}
                            resetDefaultSorted={this.props.resetDefaultSorted}
                            showPagination={isPublicView}
                        />
                        {!isPublicView && !hidePagination && (
                            <Box
                                className={
                                    useProjectListView
                                        ? 'col-sm-offset-1 col-sm-10 col-md-offset-2 col-md-8'
                                        : undefined
                                }
                                marginTop={1}
                                px={useProjectListView ? 0.5 : undefined}
                            >
                                {this.renderPagination(true)}
                            </Box>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export const ContractList = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedContractList);

const fetchPublicData = (getState, dispatch, location, params) => {
    const options = {
        publicView: true,
    };

    if (params.governmentCode) {
        options.governmentCode = params.governmentCode;
    }

    if (params.projectId) {
        // We are in the context of a project, so filter the contracts for the project
        options.projectId = Number.parseInt(params.projectId, 10);
    }

    return dispatch(loadContracts(options));
};

// NOTE: we can't use decorators below because they don't seem to play nice with not being after the
// previously defined component, so we use the standard HOC style instead.

const PublicContractList = (props) => {
    return <ContractList isPublicView {...props} />;
};
ContractList.Public = connectData(fetchPublicData)(React.memo(PublicContractList));

// Government just using the standard `ContractList` this file should really be cleaned up
ContractList.Government = ContractList;

const ProjectContractList = (props) => {
    useEffect(() => {
        props.loadContracts({
            projectId: Number.parseInt(props.params.projectId, 10),
            limit: 200,
        });
    }, [props]);

    return <ContractList hidePagination {...props} />;
};
ProjectContractList.propTypes = {
    loadContracts: PropTypes.func.isRequired,
    menuActionHandler: PropTypes.func.isRequired,
    params: PropTypes.shape({
        projectId: PropTypes.string.isRequired,
    }).isRequired,
};
ContractList.Project = compose(
    withRouter,
    connect(
        (state, props) => {
            return {
                isContractAdmin: isContractAdminUser(state) || isDepartmentContractAdminUser(state),
                projectId: Number.parseInt(props.params.projectId, 10),
            };
        },
        {
            loadContracts,
            menuActionHandler,
        }
    )
)(ProjectContractList);
