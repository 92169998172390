import { omit } from 'lodash';
import { formValueSelector } from 'redux-form';
import { createSelector } from 'reselect';

import { buildMap } from '@og-pro/shared-config/helpers';

import { pseudoSectionTypeNames, sectionTypeNames } from '@og-pro/shared-config/sections';

import { subsectionTypeNames } from '@og-pro/shared-config/subsections';

import { timelineDates } from '@og-pro/shared-config/timelines';

import { form, fieldNames, projectFieldsToOmit } from './constants';
import { isPseudoSectionType } from './utils';
import { getWritingProjectJS } from '../sharedSelectors';
import { deserializeProjectDates } from '../../../helpers';
import { numberStringToInteger } from '../../../utils';

import { isPurchaseWithoutIntroduction } from '../../../helpers/projectSections';

const { DIVIDER, PRICING, SCOPE, TERMS, TEXT_AREA } = sectionTypeNames;

const { BODY } = subsectionTypeNames;

const { DOCUMENT_SETUP, GLOBAL_DATA, OVERVIEW, SUMMARY_BACKGROUND_TIMELINE } =
    pseudoSectionTypeNames;

const { CRITERIA, EVALUATION_PHASES, QUESTIONNAIRES } = fieldNames;

const formSelector = formValueSelector(form);

export const isLoadingInitialData = (state) => {
    return (
        !!state.projectCreate.get('loading') ||
        !!state.projectCreate.get('loadingBuilderSections') ||
        !!state.approvals.get('loading') ||
        !!state.govComments.get('loading')
    );
};

export const getLoadInitialDataError = (state) => {
    return (
        state.projectCreate.get('loadError') ||
        state.projectCreate.get('loadBuilderSectionsError') ||
        state.approvals.get('loadError') ||
        state.govComments.get('loadError')
    );
};

const getSuggestedContent = (state) => state.admin.get('suggestedContent');
const getBuilderSections = (state) => state.projectCreate.get('builderSections');

export const getProjectSectionQueryParam = (state, props) => {
    // the overrideSelectedProjectSectionId is used by the sdv2 project editor
    // to identify the active section in the helpModal
    if (
        props.overrideSelectedProjectSectionId &&
        !Number.isNaN(parseInt(props.overrideSelectedProjectSectionId, 10))
    ) {
        return props.overrideSelectedProjectSectionId;
    }

    return props.location.query.section;
};

export const getCriteriaFormValues = (state) => formSelector(state, CRITERIA) || {};
export const getEvaluationPhases = (state) => formSelector(state, EVALUATION_PHASES) || [];
export const getQuestionnaires = (state) => formSelector(state, QUESTIONNAIRES) || [];

export const getProjectJS = getWritingProjectJS;

export const isConfirmationPage = createSelector(
    [getProjectSectionQueryParam],
    (projectSectionQueryParam) => {
        return projectSectionQueryParam === 'confirmation';
    }
);

/**
 * Deserializers are in place to manage server-side rendering and setting up dates.
 *
 * This deserializer's main function is to make date adjustments to incoming data from the server
 * to map it directly to the form. The deserializer converts dates from UTC strings to date objects
 * in the government's timezone.
 *
 * This allows us to cleanly call initialize form with data in the same data structure that the form
 * expects, which makes it extremely easy to set initial values for redux-forms.
 */
export const getDeserializedProject = createSelector([getProjectJS], (project) => {
    if (!project) return null;

    // redux-forms submits all initial project passed in. Omit the fields we do
    // not want to be manipulated, so they will not be part of the submit project
    const cleansedProjectData = omit(project, projectFieldsToOmit);

    // Convert project dates from UTC strings to the user's time as date objects
    const { timezone } = project.government.organization;
    const projectDates = deserializeProjectDates(cleansedProjectData, timelineDates, timezone);

    return {
        ...cleansedProjectData,
        ...projectDates,
    };
});

const getSuggestedContentJS = createSelector([getSuggestedContent], (rawContent) => {
    if (rawContent) {
        return rawContent.toJS();
    }
    return [];
});

export const getSuggestedScopeJS = createSelector([getSuggestedContentJS], (content) => {
    return content.filter((item) => item.section_type === SCOPE);
});

export const getSuggestedPricingJS = createSelector([getSuggestedContentJS], (content) => {
    return content.filter((item) => item.section_type === PRICING);
});

export const getWritingSections = createSelector([getProjectJS], (project) => {
    if (!project) {
        return [];
    }

    const writingSections = [
        {
            id: 1,
            section_type: project.isDocBuilder ? OVERVIEW : GLOBAL_DATA,
            shortName: project.isDocBuilder ? 'Overview' : 'Info',
            title: project.isDocBuilder ? 'Overview' : 'Project Information',
        },
    ];

    if (project.upfrontQuestions.length > 0) {
        writingSections.push({
            id: 2,
            section_type: DOCUMENT_SETUP,
            shortName: 'Setup',
            title: 'Document Setup',
        });
    }

    const hasNoIntroduction = isPurchaseWithoutIntroduction(project.type, project.projectSections);

    if (hasNoIntroduction) {
        writingSections.push({
            id: 3,
            section_type: SUMMARY_BACKGROUND_TIMELINE,
            shortName: 'Summary, Background, Timeline',
            title: 'Summary, Background & Timeline',
        });
    }

    const sectionsWithTermsReview = [TERMS, TEXT_AREA];
    const projectWritingSections = project.projectSections.filter((projectSection) => {
        if (projectSection.isHidden || projectSection.isHiddenByLogic) {
            return false;
        }

        if (
            projectSection.isWritingForm ||
            (project.useSectionDividers && projectSection.section_type === DIVIDER) // Dividers need to be included in the writing form sections even though they don't have content
        ) {
            return true;
        }

        // Add any non-writing sections that have items that need to be reviewed
        if (sectionsWithTermsReview.includes(projectSection.section_type)) {
            const { projectSubsections } = projectSection;
            const criteriaKey = `${projectSection.id}_${projectSubsections[0].id}`;
            return (project.criteria[criteriaKey] || []).some((criteria) => {
                return criteria.needsReview && !criteria.isHiddenByLogic;
            });
        }

        return false;
    });

    return writingSections.concat(projectWritingSections);
});

export const getSDv2WritingSections = createSelector([getWritingSections], (writingSections) => {
    return writingSections.filter((section) => {
        return ![OVERVIEW, GLOBAL_DATA, DOCUMENT_SETUP, SUMMARY_BACKGROUND_TIMELINE].includes(
            section.section_type
        );
    });
});

export const getSectionRoutes = createSelector(
    [getWritingSections, (state, projectWritingPath) => projectWritingPath],
    (projectSections, writingPath) => {
        return projectSections.map((projectSection) => ({
            ...projectSection,
            path: `${writingPath}?section=${projectSection.id}`,
        }));
    }
);

export const getProjectSection = createSelector(
    [getWritingSections, getProjectSectionQueryParam],
    (projectSections, projectSectionQueryParam) => {
        const projectSectionId = numberStringToInteger(projectSectionQueryParam);
        const projectSection = projectSections.find((section) => section.id === projectSectionId);
        if (!projectSection) {
            return projectSections[0];
        }
        return projectSection;
    }
);

export const isPseudoSectionPage = createSelector([getProjectSection], (projectSection) => {
    return !!projectSection && isPseudoSectionType(projectSection.section_type);
});

export const getProjectSubsectionsMap = createSelector(
    [getProjectSection, isPseudoSectionPage],
    (projectSection, isPseudoSection) => {
        if (projectSection && !isPseudoSection) {
            return buildMap(projectSection.projectSubsections, 'subsection_type');
        }
        return {};
    }
);

export const getCurrentCriteriaValues = createSelector(
    [getCriteriaFormValues, getProjectSection, getProjectSubsectionsMap],
    (criteriaValues, projectSection, projectSubsectionsMap) => {
        const criteriaKey = `${projectSection.id}_${projectSubsectionsMap[BODY].id}`;
        const criteria = criteriaValues[criteriaKey] || [];

        if (!projectSection.isWritingForm) {
            // For non-writing form sections only show the items that need to be reviewed
            return criteria.filter((item) => item.needsReview);
        }

        return criteria;
    }
);

const getScopeProjectSection = createSelector([getWritingSections], (projectSections) => {
    return projectSections.find((projectSection) => projectSection.section_type === SCOPE);
});

const getScopeProjectSubsection = createSelector(
    [getScopeProjectSection],
    (scopeProjectSection) => {
        if (scopeProjectSection) {
            return scopeProjectSection.projectSubsections.find(
                (sub) => sub.subsection_type === BODY
            );
        }
    }
);

// Selector for getting scope criteria values
export const getScopeCriteriaValues = createSelector(
    [getCriteriaFormValues, getScopeProjectSection, getScopeProjectSubsection],
    (criteriaValues, scopeProjectSection, scopeProjectSubsection) => {
        if (scopeProjectSection) {
            return criteriaValues[`${scopeProjectSection.id}_${scopeProjectSubsection.id}`] || [];
        }
        return [];
    }
);

export const getBuilderSectionsJS = createSelector([getBuilderSections], (rawBuilderSections) => {
    if (rawBuilderSections) {
        return rawBuilderSections.toJS();
    }
    return {};
});

export const getBuilderSection = createSelector(
    [getBuilderSectionsJS, getProjectSection],
    (builderSectionsMap, projectSection) => {
        if (builderSectionsMap && projectSection) {
            return builderSectionsMap[projectSection.id];
        }
    }
);
