import copy from 'copy-to-clipboard';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import {
    ALLOW_MULTI_SELECT,
    BID_BOND,
    CONFIRMATION,
    CUSTOM_VARIABLE,
    DATA,
    DATA_VERIFY,
    DOWNLOAD,
    EXTERNAL_FORM,
    FILE_UPLOAD,
    LONG_ANSWER,
    MULTIPLE_CHOICE,
    NOTARIZE,
    QUESTIONNAIRE_RESPONSE,
    SECTION_HEADER,
    SHORT_ANSWER,
    YES_NO,
    RESPONSE_DATA,
    VALUE,
} from '@og-pro/shared-config/questionnaires';

import { formatLicenseURL, getRawQuestionnaireResponse } from './utils';
import { BidBondResponseDisplay, ResponseDisplayPrompt } from './components';
import { FileUploadAttachment } from '../FileUploadAttachment';
import { HtmlBlurb } from '../HtmlBlurb';
import { HtmlContent } from '../HtmlContent/HtmlContent';

export class QuestionnaireResponseDisplay extends PureComponent {
    static propTypes = {
        containsPricing: PropTypes.bool,
        data: PropTypes.object,
        isDocx: PropTypes.bool,
        isPricingSealed: PropTypes.bool,
        questionnaireResponse: PropTypes.shape({
            attachment: PropTypes.object,
            data: PropTypes.shape({
                value: PropTypes.any,
            }),
            id: PropTypes.number,
            isFailed: PropTypes.oneOfType([PropTypes.bool, PropTypes.instanceOf(null)]),
        }),
        responsePath: PropTypes.string,
        responseOnly: PropTypes.bool,
        truncateLength: PropTypes.number,
        truncateResponse: PropTypes.bool,
        type: PropTypes.string.isRequired,
    };

    static defaultProps = {
        responsePath: `${QUESTIONNAIRE_RESPONSE}.${RESPONSE_DATA}`,
        truncateLength: 800,
    };

    get styles() {
        return require('./QuestionnaireResponseDisplay.scss');
    }

    renderResponse() {
        const {
            containsPricing,
            data,
            isDocx,
            isPricingSealed,
            questionnaireResponse,
            responseOnly,
            responsePath,
            truncateLength = 800,
            truncateResponse,
            type,
        } = this.props;

        // Do not show response to questions that contain pricing when pricing is sealed
        if (isPricingSealed && containsPricing) {
            return (
                <p>
                    <i className="fa fa-lock" />
                    &nbsp;
                    <em>Pricing information has not been unsealed yet</em>
                </p>
            );
        }

        const { attachmentItem, htmlContent, paragraph } = this.styles;

        switch (type) {
            case BID_BOND: {
                return (
                    <BidBondResponseDisplay
                        isDocx={isDocx}
                        questionnaireResponse={questionnaireResponse}
                        responseOnly={responseOnly}
                    />
                );
            }
            case CONFIRMATION: {
                const rawResponse = get(this.props, `${responsePath}.${VALUE}`);
                const textResponse = getRawQuestionnaireResponse(this.props);
                const icon = rawResponse ? 'check-square-o' : 'square-o';
                return (
                    <p className={paragraph}>
                        <i className={`fa fa-${icon}`} /> {textResponse}
                    </p>
                );
            }
            case CUSTOM_VARIABLE:
            case SHORT_ANSWER:
            case YES_NO: {
                const response = getRawQuestionnaireResponse(this.props);
                if (!response) return null;

                return <p className={paragraph}>{response}</p>;
            }
            case DATA_VERIFY: {
                const response = getRawQuestionnaireResponse(this.props);
                if (!response) return null;

                const url = formatLicenseURL(data && data.value, response);
                return (
                    <p className={paragraph}>
                        {response}
                        {url && (
                            <>
                                <br />
                                <a
                                    href={url}
                                    onClick={() => copy(response.trim())}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    Click to Verify
                                </a>
                                &nbsp;
                                <small>
                                    <em>Value will be copied to clipboard</em>
                                </small>
                            </>
                        )}
                    </p>
                );
            }
            case MULTIPLE_CHOICE: {
                const allowMultiSelect = get(this.props, [DATA, ALLOW_MULTI_SELECT]);
                const response = getRawQuestionnaireResponse(this.props);

                if (allowMultiSelect) {
                    const multiSelectResponses = Array.isArray(response) ? response : [];
                    if (multiSelectResponses.length === 0) {
                        return null;
                    }

                    return multiSelectResponses.map((multiSelectResponse, index) => {
                        return (
                            <p className={paragraph} key={index}>
                                {multiSelectResponse}
                            </p>
                        );
                    });
                }

                if (!response) {
                    return null;
                }

                return <p className={paragraph}>{response}</p>;
            }
            case DOWNLOAD:
            case EXTERNAL_FORM:
            case FILE_UPLOAD:
            case NOTARIZE: {
                // NOTE: needs to be updated to work with dynamic `responsePath`
                const attachments = get(questionnaireResponse, 'attachments') || [];
                if (attachments.length === 0) return null;

                return (
                    <div>
                        {attachments.map((attachment) => {
                            if (isDocx) {
                                return <div key={attachment.path}>{attachment.filename}</div>;
                            }

                            return (
                                <FileUploadAttachment
                                    attachment={attachment}
                                    className={attachmentItem}
                                    key={attachment.path}
                                />
                            );
                        })}
                    </div>
                );
            }
            case LONG_ANSWER: {
                const response = get(this.props, `${responsePath}.${VALUE}`);
                if (!response) {
                    return null;
                }

                if (truncateResponse && !isDocx) {
                    return (
                        <HtmlBlurb
                            content={response}
                            contentClassName={htmlContent}
                            maxLength={truncateLength}
                            showMore
                        />
                    );
                }

                return <HtmlContent className={htmlContent} content={response} />;
            }
            default:
                return null;
        }
    }

    render() {
        const { responseOnly, type } = this.props;

        if (responseOnly) {
            return this.renderResponse();
        }

        if (type === SECTION_HEADER) {
            const { title } = this.styles;
            return <ResponseDisplayPrompt {...this.props} titleClassName={title} />;
        }

        return (
            <ResponseDisplayPrompt {...this.props} canHaveResponse>
                {this.renderResponse()}
            </ResponseDisplayPrompt>
        );
    }
}
