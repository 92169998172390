import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Panel } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Outlet } from 'react-router-dom';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { Box, TabsNavigation } from '@og-pro/ui';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';

import { getNavItems } from './selectors';
import { isContractAdminForContract, isContractEditor } from '../selectors';
import { getContractJS, isRetentionAdminUser } from '../../selectors';
import connectData from '../../ConnectData';
import { ContractHeader, LoadingError, LoadingSpinner, PageTitle } from '../../../components';
import {
    createContractPublicAuditLog,
    loadContract,
    updateContract,
} from '../../../actions/contracts';
import { showConfirmationModal, showConfirmationSimpleModal } from '../../../actions/confirmation';
import { TOGGLE_CONTRACT_PUBLIC_PRIVATE } from '../../../constants/menuActions';

function fetchData(getState, dispatch, location, params) {
    const contractId = Number.parseInt(params.contractId, 10);

    // Only load the contract if it is not the current contract or hasn't been loaded yet
    const shouldLoadContract =
        getState().contracts.getIn(['contract', 'id']) !== contractId ||
        !getState().contracts.get('loadedContract');

    if (shouldLoadContract) {
        return dispatch(loadContract(contractId));
    }
}

const mapStateToProps = (state, props) => {
    return {
        contract: getContractJS(state),
        isContainedByProject: !!props.params.projectId,
        isContractAdmin: isContractAdminForContract(state),
        isEditor: isContractEditor(state),
        isRetentionAdmin: isRetentionAdminUser(state),
        loadContractError: state.contracts.get('loadContractError'),
        loadingContract: !!state.contracts.get('loadingContract'),
        navItems: getNavItems(state, props),
        updatingContract: state.contracts.get('updatingContract'),
    };
};

const mapDispatchToProps = {
    showConfirmationModal,
    showConfirmationSimpleModal,
    createContractPublicAuditLog,
    updateContract,
};

// @connectData
// @connect
class ConnectedContractNav extends Component {
    static propTypes = {
        createContractPublicAuditLog: PropTypes.func.isRequired,
        contract: PropTypes.shape({
            id: PropTypes.number.isRequired,
        }),
        isContainedByProject: PropTypes.bool.isRequired,
        isContractAdmin: PropTypes.bool.isRequired,
        isEditor: PropTypes.bool.isRequired,
        isRetentionAdmin: PropTypes.bool.isRequired,
        loadContractError: PropTypes.string,
        loadingContract: PropTypes.bool.isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
        }).isRequired,
        navItems: PropTypes.arrayOf(
            PropTypes.shape({
                indexRoute: PropTypes.bool,
                link: PropTypes.string.isRequired,
                path: PropTypes.string,
                title: PropTypes.string.isRequired,
            })
        ).isRequired,
        router: PropTypes.shape({
            push: PropTypes.func.isRequired,
        }).isRequired,
        showConfirmationModal: PropTypes.func.isRequired,
        showConfirmationSimpleModal: PropTypes.func.isRequired,
        updateContract: PropTypes.func.isRequired,
        updatingContract: PropTypes.bool,
    };

    handlePublicChange = (e) => {
        const {
            contract: { id: contractId },
        } = this.props;

        if (e.target.checked) {
            return this.props.showConfirmationSimpleModal(
                async () => {
                    try {
                        await this.props.updateContract(contractId, { isPublic: true });
                        await this.props.createContractPublicAuditLog(contractId, {
                            comment: 'Contract record public visibility enabled',
                            isPublic: true,
                        });
                    } catch (error) {
                        console.error(error);
                    }
                },
                {
                    bsStyle: 'warning',
                    btnText: 'Make Public',
                    icon: 'globe',
                    text: 'Are you sure you want this contract record to be publicly viewable?',
                }
            );
        }

        this.props.showConfirmationModal(TOGGLE_CONTRACT_PUBLIC_PRIVATE, {
            isPublic: e.target.checked,
            contractId,
        });
    };

    render() {
        const {
            contract,
            isContainedByProject,
            isContractAdmin,
            isEditor,
            isRetentionAdmin,
            loadContractError,
            loadingContract,
            location,
            navItems,
            router,
            updatingContract,
        } = this.props;

        if (loadingContract) {
            return <LoadingSpinner />;
        }

        if (loadContractError || !contract) {
            return <LoadingError error={loadContractError} />;
        }

        const onClick = (nextRoute = null) => {
            if (nextRoute) {
                router.push(nextRoute);
            }
        };

        const tabs = navItems.map((item) => ({
            ...item,
            qaTag: `contract-${item.label}`,
        }));
        const { pathname } = location;
        const segments = pathname.split('/').filter(Boolean);
        const lastSegment = segments.at(-1); // last segment
        const secondLastSegment = segments.length > 2 ? segments.at(-3) : null; // for nested routes
        const activeTab =
            tabs.find((tab) => lastSegment && tab.route.includes(lastSegment)) ??
            (secondLastSegment && tabs.find((tab) => tab.route.includes(secondLastSegment))) ??
            tabs[0];

        const contractComponent = (
            <>
                <ContractHeader
                    contract={contract}
                    handlePublicChange={this.handlePublicChange}
                    isContainedByProject={isContainedByProject}
                    isContractAdmin={isContractAdmin}
                    isEditor={isEditor}
                    isRetentionAdmin={isRetentionAdmin}
                    updatingContract={updatingContract}
                />
                <Box
                    sx={{
                        borderBottom: `1px solid ${capitalDesignTokens.foundations.colors.gray200}`,
                        mb: 3,
                    }}
                >
                    <TabsNavigation
                        defaultTab={activeTab}
                        onClick={onClick}
                        redirectFn={router.push}
                        tabs={tabs}
                    />
                </Box>
                <Box sx={{ minHeight: '400px' }}>
                    <Outlet />
                </Box>
            </>
        );

        if (isContainedByProject) {
            return contractComponent;
        }

        return (
            <Panel>
                <h1 className="visually-hidden">Contract Details</h1>
                <PageTitle title="Contract Details" />
                <Panel.Body>{contractComponent}</Panel.Body>
            </Panel>
        );
    }
}

export const ContractNav = compose(
    connectData(fetchData),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedContractNav);
