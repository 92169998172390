import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { HtmlContent } from '../../../HtmlContent/HtmlContent';

export const Prompt = ({ className = '', isOGThemeEnabledForComponents = false, prompt }) => {
    if (!prompt) {
        return null;
    }

    const styles = require('./index.scss');

    return (
        <HtmlContent
            className={classnames('prompt-container', {
                [className]: !!className,
                [styles.prompt]: isOGThemeEnabledForComponents,
            })}
            content={prompt}
        />
    );
};

Prompt.propTypes = {
    className: PropTypes.string,
    isOGThemeEnabledForComponents: PropTypes.bool,
    prompt: PropTypes.string,
};
