import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { AddFollowersForm } from './AddFollowersForm/AddFollowersForm';
import { form } from './AddFollowersForm/constants';
import { UserProfileList } from '../../UserProfileList';

export class CommentFollowers extends PureComponent {
    static propTypes = {
        canComment: PropTypes.bool.isRequired,
        followers: PropTypes.array.isRequired,
        users: PropTypes.array.isRequired,
        threadId: PropTypes.number.isRequired,
        showForm: PropTypes.bool,
        addFollowersError: PropTypes.bool,
        addFollowersHandler: PropTypes.func.isRequired,
        showFollowersFormHandler: PropTypes.func.isRequired,
        hideFollowersFormHandler: PropTypes.func.isRequired,
    };

    renderAddFollowersForm() {
        const {
            addFollowersError,
            addFollowersHandler,
            canComment,
            followers,
            hideFollowersFormHandler,
            threadId,
            users,
        } = this.props;

        const styles = require('./Followers.scss');

        const initialFollowerIds = followers.map((user) => {
            return { label: user.displayName, value: user.id };
        });

        return (
            <AddFollowersForm
                canComment={canComment}
                cancelHandler={hideFollowersFormHandler}
                className={styles.followersForm}
                form={`${form}${threadId}`}
                initialValues={{ followerIds: initialFollowerIds }}
                onSubmit={addFollowersHandler}
                submitServerError={addFollowersError}
                users={users}
            />
        );
    }

    renderFollowersList() {
        const { followers, showFollowersFormHandler } = this.props;

        return (
            <UserProfileList
                addHandler={showFollowersFormHandler}
                listText="Followers"
                users={followers}
            />
        );
    }

    render() {
        const { showForm } = this.props;
        const styles = require('./Followers.scss');

        const Follower = showForm ? this.renderAddFollowersForm() : this.renderFollowersList();

        return <div className={styles.followersContainer}>{Follower}</div>;
    }
}
