import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '../../../Button';

export const RemindButton = ({
    approval: { reminded, reminding },
    approval,
    block,
    className,
    isActiveReviewStep,
    isRejected,
    reminderHandler,
    reviewStepId,
    showModal,
}) => {
    const handleClick = (reReview) => {
        showModal('reviewerReminder', { ...approval, reminderHandler, reReview, reviewStepId });
    };

    if (reminded) {
        return (
            <span className="text-info">
                <i className="fa fa-envelope" /> Reminder Email Sent!
            </span>
        );
    }

    if (isRejected) {
        return (
            <Button
                block={block}
                bsSize="sm"
                className={className}
                disabled={reminding}
                onClick={() => handleClick(true)}
                qaTag="approvalsDashboard-requestAnotherReview"
            >
                <span className="text-info">
                    <i className="fa fa-pencil" /> Request Another Review
                </span>
            </Button>
        );
    }

    return (
        <Button
            block={block}
            bsSize="sm"
            className={className}
            disabled={!isActiveReviewStep || reminding}
            onClick={() => handleClick(false)}
            qaTag="approvalsDashboard-sendReminder"
        >
            <span className="text-info">
                <i className="fa fa-bell" /> Send Reminder
            </span>
        </Button>
    );
};

RemindButton.propTypes = {
    approval: PropTypes.shape({
        id: PropTypes.number.isRequired,
        reminded: PropTypes.bool,
        reminding: PropTypes.bool,
    }).isRequired,
    block: PropTypes.bool,
    className: PropTypes.string,
    isActiveReviewStep: PropTypes.bool.isRequired,
    isRejected: PropTypes.bool,
    reminderHandler: PropTypes.func.isRequired,
    reviewStepId: PropTypes.number.isRequired,
    showModal: PropTypes.func.isRequired,
};
