import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { ProposalAttachmentItem } from '../ProposalAttachmentItem';
import { ProposalSectionTitle } from '../ProposalSectionTitle';

export class DocumentsSection extends PureComponent {
    static propTypes = {
        excludeDocs: PropTypes.bool,
        isDocx: PropTypes.bool,
        printButton: PropTypes.node,
        proposal: PropTypes.shape({
            proposalDocuments: PropTypes.arrayOf(
                PropTypes.shape({
                    attachments: PropTypes.arrayOf(
                        PropTypes.shape({
                            id: PropTypes.number.isRequired,
                        })
                    ),
                    id: PropTypes.number,
                    isRequired: PropTypes.bool,
                    key: PropTypes.string,
                    title: PropTypes.string,
                })
            ),
            questionnaires: PropTypes.array,
        }).isRequired,
    };

    static defaultProps = {
        excludeDocs: false,
        printButton: undefined,
    };

    get styles() {
        if (this.props.isDocx) {
            return {};
        }
        return require('../../index.scss');
    }

    renderAttachments = (doc) => {
        // For "Other Documents" no ID is available, which is why the `key` property is used instead
        return (
            <div className={this.styles.sectionItem} key={doc.id || doc.key}>
                <div className="row">
                    <div className={`col-md-5 ${this.styles.label}`}>
                        {doc.title}
                        {doc.isRequired && ' (Required)'}
                    </div>
                    <ul className="col-md-7 list-unstyled">
                        {doc.attachments.length === 0 ? (
                            <li className={this.styles.italicized}>No documents included</li>
                        ) : (
                            doc.attachments.map((attachment) => (
                                <ProposalAttachmentItem key={attachment.id} {...attachment} />
                            ))
                        )}
                    </ul>
                </div>
            </div>
        );
    };

    render() {
        const {
            excludeDocs,
            isDocx,
            printButton,
            proposal: { proposalDocuments, questionnaires },
        } = this.props;

        // Proposal documents will be viewable if there are no questionnaires
        if (excludeDocs || questionnaires.length > 0) {
            return null;
        }

        return (
            <div className={this.styles.section}>
                <ProposalSectionTitle
                    isDocx={isDocx}
                    printButton={printButton}
                    title="RESPONSE DOCUMENTS"
                />
                {proposalDocuments.map(this.renderAttachments)}
            </div>
        );
    }
}
