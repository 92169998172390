import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { reduxForm, Field } from 'redux-form';

import { form, fieldNames } from './constants';
import { CategorySearchInput } from '../../../CategorySelect/CategorySearchInput';
import { LoadingButton, SearchSelect } from '../../../../components';
import { statePostalOptions } from '../../../../Forms/options';

const { CATEGORIES, STATES } = fieldNames;

const formConfig = {
    form,
};

class ConnectedOpenBidsForm extends PureComponent {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        searching: PropTypes.bool,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const { handleSubmit, searching } = this.props;

        return (
            <form onSubmit={handleSubmit}>
                <Field
                    component={CategorySearchInput}
                    disabled={searching}
                    disallowCustomCategories
                    name={CATEGORIES}
                    stacked
                />
                <Field
                    aria-label="Select states"
                    className={this.styles.stateSelect}
                    component={SearchSelect}
                    disabled={searching}
                    hasFeedback={false}
                    isMulti
                    name={STATES}
                    options={statePostalOptions}
                    overrideFeedback
                    placeholder="Select States..."
                    qaTag="openBidsForm-selectStates"
                />
                <LoadingButton
                    block
                    bsStyle="primary"
                    className={this.styles.loadingButton}
                    disabled={searching}
                    icon="fa-search"
                    loading={searching}
                    qaTag="openBidsForm-search"
                    text="Search"
                    type="submit"
                />
            </form>
        );
    }
}

export const OpenBidsForm = reduxForm(formConfig)(ConnectedOpenBidsForm);
