import React, { useMemo, useState } from 'react';
import {
    Autocomplete,
    Box,
    Button,
    createFilterOptions,
    List,
    Paper,
    Typography,
} from '@og-pro/ui';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { fieldStatusesDict } from '@og-pro/shared-config/customFormService/customField';

import { createCustomFieldDisplayData } from '../../../CustomFieldsLibrary/CustomFieldsModal/createCustomFieldDisplayData';
import { useListCustomFields } from '../../../../../../lib/customFormService/useListCustomFields';
import { SelectCustomFieldAutocompleteOptions } from './SelectCustomFieldAutocompleteOptions';
import { SelectCustomFieldAutocompleteInput } from './SelectCustomFieldAutocompleteInput';
import { inColumnsStyle, overflowdedBoxStyle } from './styles';
import { useCustomFormEditor } from './CustomFormEditorContext';

const { PUBLISHED } = fieldStatusesDict;

const CustomFieldLookupPaper = ({ children }) => (
    <Paper>
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>{children}</List>
    </Paper>
);

CustomFieldLookupPaper.propTypes = {
    children: PropTypes.node,
};

export const SelectCustomFieldForm = ({
    variant = 'large',
    selectFn,
    conditionalFieldIndex = 0,
}) => {
    const { fieldsOrder, setShowAddFieldForm } = useCustomFormEditor();
    const filterOptions = createFilterOptions({
        stringify: (option) => `${option.name} ${option.shortTitle}`,
    });
    const { data: listCustomFieldsData } = useListCustomFields();
    const customFieldDefinitions = listCustomFieldsData.getAllCustomFields;

    const options = useMemo(() => {
        return customFieldDefinitions
            .filter((field) => field.status === PUBLISHED)
            .filter((customField) => !fieldsOrder.includes(customField.id))
            .map(({ name, id, fieldType, shortTitle }) => {
                const { icon, label } = createCustomFieldDisplayData[fieldType];
                return {
                    name,
                    label,
                    id,
                    icon,
                    shortTitle,
                };
            });
    }, [customFieldDefinitions, fieldsOrder]);

    const [selectedOption, setSelectedOption] = useState(null);

    const styles = {
        small: {
            title: 'h4',
            outerBoxStyle: {},
        },
        large: {
            outerBoxStyle: overflowdedBoxStyle.highlighted,
            title: 'h3',
        },
    }[variant];

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, ...styles.outerBoxStyle }}>
            <Box>
                <Box sx={inColumnsStyle}>
                    <Typography variant={styles.title}>Add a Field to this Section</Typography>
                    {variant === 'large' && (
                        <Button
                            color="secondary"
                            onClick={(e) => {
                                setShowAddFieldForm(false);
                                e.stopPropagation();
                            }}
                            qaTag="customFieldOption-cancelFieldAdd"
                            startIcon={<CloseIcon />}
                            variant="text"
                        >
                            Cancel
                        </Button>
                    )}
                </Box>
                <Typography color="secondary">
                    Fields are created by your organization’s admin. Don’t see a field you need?
                    Fields can be created in the custom fields tab.
                </Typography>
            </Box>
            <Autocomplete
                PaperComponent={CustomFieldLookupPaper}
                filterOptions={filterOptions}
                getOptionLabel={(option) => option.name}
                onChange={(e, value) => {
                    setSelectedOption(value);
                    if (value) {
                        selectFn(value.id, conditionalFieldIndex);
                    } else {
                        selectFn('', conditionalFieldIndex);
                    }
                    e.stopPropagation();
                }}
                options={options}
                renderInput={(params) => <SelectCustomFieldAutocompleteInput params={params} />}
                renderOption={(props, option) => (
                    <SelectCustomFieldAutocompleteOptions {...props} option={option} />
                )}
                value={selectedOption}
            />
        </Box>
    );
};

SelectCustomFieldForm.propTypes = {
    variant: PropTypes.oneOf(['small', 'large']),
    selectFn: PropTypes.func.isRequired,
    conditionalFieldIndex: PropTypes.number,
};
