import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { hideSuggestedContentModal } from '../../actions/app';
import { SuggestionList } from '../SuggestionList/SuggestionList';

export const SuggestedContentModal = ({ sowProps, pricingProps }) => {
    const dispatch = useDispatch();
    const showModal = useSelector((state) => state.app.get('showSuggestedContentModal'));
    const hideModal = () => dispatch(hideSuggestedContentModal());

    if (!showModal) {
        return null;
    }

    return (
        <Modal onHide={hideModal} show>
            <Modal.Header closeButton>
                <Modal.Title className="text-center">Suggested Content</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {(!pricingProps || !!sowProps.suggestedItems.length) && (
                    <SuggestionList {...sowProps} />
                )}

                {pricingProps && (
                    <div style={{ marginTop: '16px' }}>
                        <SuggestionList {...pricingProps} />
                    </div>
                )}
            </Modal.Body>
        </Modal>
    );
};

SuggestedContentModal.propTypes = {
    sowProps: PropTypes.object.isRequired,
    pricingProps: PropTypes.object,
};
