import moment from 'moment-timezone/builds/moment-timezone-with-data-1970-2030';
import PropTypes from 'prop-types';
import React from 'react';

import { Well } from '../../../../Well/Well';

export const PostInformationSection = ({
    isDocx,
    project: { hasSealedBid, isPrivate, postedAt, showBids, showBidsWithPricing },
    timezone,
}) => {
    if (isDocx) {
        return null;
    }

    return (
        <Well className="internal-information-section">
            <h4 className="text-info internal-information-title">
                <i className="fa fa-bullhorn" /> Post Information
            </h4>
            <div className="dl-horizontal">
                <dl className="internal-information-dl-list">
                    <dt>Posted At:</dt>
                    <dd>{postedAt ? moment.tz(postedAt, timezone).format('llll') : 'N/A'}</dd>
                    <dt>Sealed Bid Process:</dt>
                    <dd>
                        {hasSealedBid
                            ? `Yes (Bids ${showBids ? 'Unsealed' : 'Sealed'} / Pricing ${
                                  showBidsWithPricing ? 'Unsealed' : 'Sealed'
                              })`
                            : 'No'}
                    </dd>
                    <dt>Private Bid:</dt>
                    <dd>{isPrivate ? 'Yes' : 'No'}</dd>
                </dl>
            </div>
        </Well>
    );
};

PostInformationSection.propTypes = {
    isDocx: PropTypes.bool,
    project: PropTypes.shape({
        hasSealedBid: PropTypes.bool.isRequired,
        isPrivate: PropTypes.bool.isRequired,
        postedAt: PropTypes.string,
        showBids: PropTypes.bool.isRequired,
        showBidsWithPricing: PropTypes.bool.isRequired,
    }).isRequired,
    timezone: PropTypes.string.isRequired,
};

PostInformationSection.defaultProps = {
    isDocx: false,
};
