import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { TextField } from '@og-pro/ui';
import React from 'react';
import PropTypes from 'prop-types';

export const SelectCustomFieldAutocompleteInput = ({ params }) => {
    return (
        <TextField
            {...params}
            InputProps={{
                ...params.InputProps,
                startAdornment: (
                    <InputAdornment position="start" sx={{ ml: 1 }}>
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
            label="Search for a Field"
            sx={{
                p: 0, // override MUI `p: 1`
            }}
        />
    );
};

SelectCustomFieldAutocompleteInput.propTypes = {
    params: PropTypes.object,
};
