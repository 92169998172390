import { browserHistory } from '@og-pro-migration-tools/react-router';
import { omit } from 'lodash';

import { projectFilterTypesDict } from '@og-pro/shared-config/projects';

import { proposalStatusesDict } from '@og-pro/shared-config/proposals';

import { showLoginModal, hideLoginModal } from './auth';
import { showVendorAccountModal } from './adminVendor';
import {
    hideConfirmationModal,
    showConfirmationModal,
    showConfirmationModalError,
    showConfirmationSimpleModal,
    updatingConfirmationModal,
} from './confirmation';
import { showSnackbar } from './notification';
import { subscribe as subscribeToProject } from './subscriptions';
import { NO_BID_NEW_PROPOSAL } from '../constants/proposalActions';

import { getIncompleteRequiredVendorDatumKeysJS } from '../containers/selectors';

const { DRAFT } = proposalStatusesDict;

export const LOAD = 'public/projects/LOAD';
export const LOAD_SUCCESS = 'public/projects/LOAD_SUCCESS';
export const LOAD_FAIL = 'public/projects/LOAD_FAIL';

export function loadProject(projId) {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD });

        return client
            .get(`/project/${projId}`)
            .then((result) => {
                // Returning the dispatch here so that `redirectFromOldProjectPathToPortalProjectPath`
                // in `routeHooks` can safely call `getState`
                return dispatch({ type: LOAD_SUCCESS, result });
            })
            .catch((error) => {
                if (error?.status === 401) {
                    const message =
                        'This is a private project. You must be logged in and have been invited in order to see it. Please log in';

                    return dispatch({ type: LOAD_FAIL, error: { ...error, message } });
                }

                dispatch({ type: LOAD_FAIL, error });
            });
    };
}

export function loadProjectPlanholders(projectId) {
    return (dispatch, getState, client) => {
        return client.get(`/project/${projectId}/planholders`);
    };
}

export const GOV_SUBSCRIBE = 'public/projects/GOV_SUBSCRIBE';
export const GOV_SUBSCRIBE_SUCCESS = 'public/projects/GOV_SUBSCRIBE_SUCCESS';
export const GOV_SUBSCRIBE_FAIL = 'public/projects/GOV_SUBSCRIBE_FAIL';
export const HIDE_GOV_SUBSCRIBE_FAIL = 'public/projects/HIDE_GOV_SUBSCRIBE_FAIL';

export function subscribeToGov(govId, user, opts = {}) {
    return (dispatch, getState, client) => {
        function loginHandler(loggedinUser) {
            dispatch(hideLoginModal());
            // Reattempt to subscribe after logging in
            return dispatch(subscribeToGov(govId, loggedinUser, opts));
        }

        // Prompt to login if user is not logged in
        if (!user) {
            return dispatch(
                showLoginModal(
                    'You must be logged in to subscribe to government projects. ' +
                        'Please log in or create an account.',
                    loginHandler
                )
            );
        }

        // Prompt to login if not a vendor
        if (!user.isVendor) {
            return dispatch(
                showLoginModal(
                    'You must be logged in as a vendor to subscribe to updates. ' +
                        'Please log in or create a vendor account.',
                    loginHandler
                )
            );
        }

        // Prompt to complete vendor account info
        if (
            (getIncompleteRequiredVendorDatumKeysJS(getState()).length > 0 ||
                !user.organization.isVendorComplete) &&
            !opts.force
        ) {
            return dispatch(
                showVendorAccountModal({
                    onComplete: () => {
                        const updatedUser = getState().auth.get('user').toJS();
                        return dispatch(
                            subscribeToGov(govId, updatedUser, { ...opts, force: true })
                        );
                    },
                })
            );
        }

        dispatch({ type: GOV_SUBSCRIBE });
        return client
            .post(`/government/${govId}/subscribe`)
            .then((result) => {
                dispatch({ type: GOV_SUBSCRIBE_SUCCESS, result });
                if (opts.onSuccess) {
                    opts.onSuccess(result);
                }
            })
            .catch((error) => {
                dispatch({ type: GOV_SUBSCRIBE_FAIL, error });
                // Error can happen. Remove after some time.
                setTimeout(() => dispatch({ type: HIDE_GOV_SUBSCRIBE_FAIL }), 5000);
            });
    };
}

export const SHOW_APPLY_MODAL = 'public/projects/SHOW_APPLY_MODAL';
export const HIDE_APPLY_MODAL = 'public/projects/HIDE_APPLY_MODAL';

export function showApplyModal() {
    return { type: SHOW_APPLY_MODAL };
}

export function hideApplyModal() {
    return { type: HIDE_APPLY_MODAL };
}

export const CREATE_PROPOSAL = 'public/projects/CREATE_PROPOSAL';
export const CREATE_PROPOSAL_SUCCESS = 'public/projects/CREATE_PROPOSAL_SUCCESS';
export const CREATE_PROPOSAL_FAIL = 'public/projects/APPLY_FAIL';

function createProposal(projectId, user) {
    return (dispatch, getState, client) => {
        const data = {
            project_id: projectId,
        };
        dispatch({ type: CREATE_PROPOSAL });
        return client
            .post('/proposal', { data })
            .then((proposal) => {
                dispatch(hideApplyModal());
                dispatch({ type: CREATE_PROPOSAL_SUCCESS });

                const { vendor_id: vendorId, id, project } = proposal;
                const nextRoute = `/vendors/${vendorId}/proposals/${id}/edit`;
                browserHistory.push(nextRoute);

                const govId = project.government_id;
                const hasSubscription = user.governmentSubscriptions.some((subscription) => {
                    return subscription.government_id === govId;
                });
                // Prompt user to subscribe to government if they have not previously
                if (!hasSubscription) {
                    dispatch(
                        showConfirmationSimpleModal(
                            () => dispatch(subscribeToGov(govId, user, { force: true })),
                            {
                                bsStyle: 'success',
                                btnText: 'Subscribe',
                                icon: 'rss',
                                text:
                                    'Would you like to be notified when new projects are issued ' +
                                    'from this organization?',
                                title: 'Subscribe For New Project Updates',
                            }
                        )
                    );
                }
            })
            .catch((error) => {
                dispatch({ type: CREATE_PROPOSAL_FAIL, error });
            });
    };
}

export function createNoBidProposal(projectId, noBidData) {
    return (dispatch, getState, client) => {
        const data = {
            ...noBidData,
            project_id: projectId,
        };
        dispatch(updatingConfirmationModal());
        return client
            .post('/proposal', { data })
            .then(() => {
                dispatch(hideConfirmationModal());
                dispatch(showSnackbar('"No Bid" Submitted'));
            })
            .catch((error) => {
                dispatch(showConfirmationModalError(error.message));
            });
    };
}

export const APPLY = 'public/projects/APPLY';
export const APPLY_SUCCESS = 'public/projects/APPLY_SUCCESS';
export const APPLY_FAIL = 'public/projects/APPLY_FAIL';

export function applyToProject(projectId, user, opts = {}) {
    return (dispatch, getState, client) => {
        function loginHandler(loggedinUser) {
            dispatch(hideLoginModal());
            // Reattempt to apply after logging in
            return dispatch(applyToProject(projectId, loggedinUser, opts));
        }

        // Prompt to login if user is not logged in
        if (!user) {
            return dispatch(
                showLoginModal(
                    'You must be logged in to draft responses. Please log in or create an account.',
                    loginHandler
                )
            );
        }

        // Prompt to login if not a vendor
        if (!user.isVendor) {
            return dispatch(
                showLoginModal(
                    'You must be logged in as a vendor to draft responses. ' +
                        'Please log in or create a vendor account.',
                    loginHandler
                )
            );
        }

        // Prompt to complete vendor account info
        if (
            (getIncompleteRequiredVendorDatumKeysJS(getState()).length > 0 ||
                !user.organization.isVendorComplete) &&
            !opts.force
        ) {
            return dispatch(
                showVendorAccountModal({
                    onComplete: () => {
                        const updatedUser = getState().auth.get('user').toJS();
                        return dispatch(
                            applyToProject(projectId, updatedUser, {
                                ...opts,
                                force: true,
                            })
                        );
                    },
                })
            );
        }

        dispatch({ type: APPLY });
        dispatch(showApplyModal());
        return client
            .get(`/proposal/project/${projectId}`)
            .then((proposals) => {
                // If no proposal exists create one
                if (proposals.length === 0 && opts.noBid) {
                    dispatch(hideApplyModal());
                    return dispatch(showConfirmationModal(NO_BID_NEW_PROPOSAL, { projectId }));
                }
                if (proposals.length === 0) {
                    return dispatch(createProposal(projectId, user));
                }

                // If proposal provide route to the proposal
                const proposal = proposals[0];
                const { vendor_id: vendorId } = user;
                const proposalPath = proposal.status === DRAFT ? '/edit' : '';
                const route = `/vendors/${vendorId}/proposals/${proposal.id}${proposalPath}`;
                dispatch({ type: APPLY_SUCCESS, route });
            })
            .catch((error) => {
                dispatch({ type: APPLY_FAIL, error });
            });
    };
}

export const SHOW_DOWNLOAD_MODAL = 'public/projects/SHOW_DOWNLOAD_MODAL';
export const HIDE_DOWNLOAD_MODAL = 'public/projects/HIDE_DOWNLOAD_MODAL';

export function showDownloadModal() {
    return { type: SHOW_DOWNLOAD_MODAL };
}

export function hideDownloadModal() {
    return { type: HIDE_DOWNLOAD_MODAL };
}

export const DOWNLOAD = 'public/projects/DOWNLOAD';
export const DOWNLOAD_SUCCESS = 'public/projects/DOWNLOAD_SUCCESS';
export const DOWNLOAD_SUCCESS_QUEUED = 'public/projects/DOWNLOAD_SUCCESS_QUEUED';
export const DOWNLOAD_FAIL = 'public/projects/DOWNLOAD_FAIL';

export function downloadAttachments(project, isSubscribed, attachments = []) {
    return (dispatch, getState, client) => {
        // Prompt to login if user is not logged in
        const user = getState().auth.get('user');
        if (!user) {
            const loginHandler = () => {
                dispatch(hideLoginModal());
                // Reattempt to apply after logging in
                return dispatch(downloadAttachments(project, isSubscribed, attachments));
            };

            return dispatch(
                showLoginModal(
                    'You must be logged in to download documents. Please log in ' +
                        'or create an account.',
                    loginHandler
                )
            );
        }

        const processDownload = () => {
            dispatch(showDownloadModal());
            dispatch({ type: DOWNLOAD });

            const attachmentIds = attachments.map((attach) => attach.id);
            const data = { attachments: attachmentIds };
            return client
                .post(`/project/${project.id}/download`, { data })
                .then((result) => {
                    if (result === null) {
                        // File processing was offloaded to queue, download link will be emailed
                        dispatch({ type: DOWNLOAD_SUCCESS_QUEUED });
                        return;
                    }

                    // Download is immediately available, link is provided
                    dispatch({ type: DOWNLOAD_SUCCESS, result: result.downloadUrl });
                })
                .catch((error) => {
                    dispatch({ type: DOWNLOAD_FAIL, error });
                });
        };

        // Process download immediately if user is already subscribed to project updates
        if (isSubscribed) {
            return processDownload();
        }

        // Prompt user to subscribe to project before processing download
        dispatch(
            showConfirmationSimpleModal(
                () => {
                    setTimeout(() => {
                        dispatch(
                            subscribeToProject(project, false, {
                                force: true,
                                onComplete: () => processDownload(),
                            })
                        );
                    });
                },
                {
                    bsStyle: 'success',
                    btnText: 'Follow Project',
                    icon: 'rss',
                    onCancel: processDownload,
                    text: 'Would you like to receive updates on this project?',
                    title: 'Subscribe To Project Updates',
                }
            )
        );
    };
}

export const SEARCH_PROJECTS = 'public/projects/SEARCH_PROJECTS';
export const SEARCH_PROJECTS_SUCCESS = 'public/projects/SEARCH_PROJECTS_SUCCESS';
export const SEARCH_PROJECT_LINE_ITEMS_SUCCESS =
    'public/projects/SEARCH_PROJECT_LINE_ITEMS_SUCCESS';
export const SEARCH_PROJECTS_FAIL = 'public/projects/SEARCH_PROJECTS_FAIL';
export const RESET_SEARCH_PROJECTS = 'public/projects/RESET_SEARCH_PROJECTS';

export function searchGovProjects(govCode, isLineItemSearch, requestData = {}) {
    return (dispatch, getState, client) => {
        dispatch({ type: SEARCH_PROJECTS });

        if (isLineItemSearch) {
            const categoriesFilter = requestData.filters?.find(
                ({ type }) => type === projectFilterTypesDict.CATEGORIES
            );
            const data = {
                ...omit(requestData, ['filters', 'page', 'limit', 'sortField', 'sortDirection']),
                categories: categoriesFilter ? categoriesFilter.value : [],
                governmentCode: govCode,
                searchPriceItems: true,
            };
            return client
                .post('/project/list', { data })
                .then((result) => {
                    dispatch({ type: SEARCH_PROJECT_LINE_ITEMS_SUCCESS, result });
                })
                .catch((error) => dispatch({ type: SEARCH_PROJECTS_FAIL, error }));
        }

        return client
            .post(`/government/${govCode}/project/public`, { data: requestData })
            .then((result) => {
                dispatch({ type: SEARCH_PROJECTS_SUCCESS, result });
            })
            .catch((error) => dispatch({ type: SEARCH_PROJECTS_FAIL, error }));
    };
}

export function resetGovProjectsSearch() {
    return { type: RESET_SEARCH_PROJECTS };
}

export const LOAD_GOV = 'public/projects/LOAD_GOV';
export const LOAD_GOV_SUCCESS = 'public/projects/LOAD_GOV_SUCCESS';
export const LOAD_GOV_FAIL = 'public/projects/LOAD_GOV_FAIL';

export function loadGov(govCode) {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD_GOV });
        return client
            .get(`/government/${govCode}`)
            .then((result) => dispatch({ type: LOAD_GOV_SUCCESS, result }))
            .catch((error) => dispatch({ type: LOAD_GOV_FAIL, error }));
    };
}

export const LOAD_GOVS = 'public/projects/LOAD_GOVS';
export const LOAD_GOVS_SUCCESS = 'public/projects/LOAD_GOVS_SUCCESS';
export const LOAD_GOVS_FAIL = 'public/projects/LOAD_GOVS_FAIL';

export function loadGovs() {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD_GOVS });
        return client
            .get('/government')
            .then((result) => dispatch({ type: LOAD_GOVS_SUCCESS, result }))
            .catch((error) => dispatch({ type: LOAD_GOVS_FAIL, error }));
    };
}
