import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Typography, IconButton, Switch } from '@og-pro/ui';
import { Download as DownloadIcon, Edit as EditIcon } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import { useSearchParams } from 'react-router-dom';

import { AgGridReactLegacy, HelpIcon } from '../../../../components';
import { getContractJS } from '../../../selectors';
import { showConfirmationSimpleModal } from '../../../../actions/confirmation';
import { createContractPublicAuditLog, updateContract } from '../../../../actions/contracts';
import { CollapsableBox } from './CollapsableBox';
import { UploadAttachmentButton } from './UploadAttachmentButton';
import { EditAttachment } from './EditAttachment';
import { DocumentTypesMenu } from './DocumentTypesMenu';
import AdjustableTextRenderer from './AdjustableTextRenderer';

export const AssociatedAttachments = () => {
    const styles = require('../index.scss');
    const contract = useSelector(getContractJS);
    const dispatch = useDispatch();
    const [attachmentBeingEdited, setAttachmentBeingEdited] = useState(null);
    const [rows, setRows] = useState(contract.attachments);
    const gridApiRef = useRef(null);

    const [searchParams, setSearchParams] = useSearchParams({
        docTypes: '',
    });

    const docTypesIds = useMemo(() => {
        const docTypes = searchParams.get('docTypes');
        return docTypes ? docTypes.split(',').map((type) => parseInt(type, 10)) : [];
    }, [searchParams]);

    useEffect(() => {
        if (docTypesIds.length > 0) {
            const newRows = contract.attachments.filter((row) => {
                return docTypesIds.includes(row.tags[0]?.id);
            });
            setRows(newRows);
        } else {
            setRows(contract.attachments);
        }
    }, [contract.attachments, docTypesIds]);

    const onDocTypesSelect = (selectedCertification) => {
        const currentIndex = docTypesIds.indexOf(selectedCertification);
        const newSelected = [...docTypesIds];
        if (currentIndex === -1) {
            newSelected.push(selectedCertification);
        } else {
            newSelected.splice(currentIndex, 1);
        }
        searchParams.set('docTypes', newSelected.join(','));
        setSearchParams(searchParams);
    };

    const onClearAll = (e) => {
        e.stopPropagation();
        searchParams.delete('docTypes');
        setSearchParams(searchParams);
        setRows(contract.attachments);
    };

    const handleGridReady = (params) => {
        gridApiRef.current = params.api;
    };

    const DateCellRenderer = (params) =>
        params.value !== null
            ? new Date(params.value).toLocaleDateString('en-us', { timeZone: 'UTC' })
            : null;

    const refreshGrid = () => {
        gridApiRef.current.refreshCells({ force: true });
    };

    const onEditFinish = () => {
        setAttachmentBeingEdited(null);
        refreshGrid();
    };

    const handleUpdateContract = async (updatedAttachment) => {
        await dispatch(updateContract(contract.id, { attachments: [updatedAttachment] }));
        refreshGrid();
    };

    const handleCreateAuditLog = (attachment, isPublicTarget) => {
        dispatch(
            createContractPublicAuditLog(contract.id, {
                attachmentId: attachment.id,
                comment: `Contract document was set to ${isPublicTarget ? 'public' : 'private'}`,
                isPublic: isPublicTarget,
            })
        );
    };

    const handleOnChangeToggle = useCallback((e, params) => {
        const attachment = params.data;
        const isPublicTarget = e.target.checked;
        const updatedAttachment = {
            ...attachment,
            contractAttachment: {
                ...attachment.contractAttachment,
                isPublic: isPublicTarget,
            },
            tags: attachment.tags[0]?.id || null,
        };

        const modalData = isPublicTarget
            ? {
                  bsStyle: 'warning',
                  btnText: 'Make Public',
                  icon: 'globe',
                  text: `Are you sure you want ${attachment.filename} to be Public?`,
              }
            : {
                  bsStyle: 'danger',
                  btnText: 'Make Private',
                  icon: 'lock',
                  text: `Are you sure you want ${attachment.filename} to be Private?`,
              };

        dispatch(
            showConfirmationSimpleModal(async () => {
                await handleUpdateContract(updatedAttachment);
                handleCreateAuditLog(attachment, isPublicTarget);
            }, modalData)
        );
    }, []);

    const columns = [
        {
            field: 'type',
            flex: 1.2,
            headerName: 'Document Type',
            cellRendererFramework: (params) => params.data.tags.map((tag) => tag.name).join(', '),
        },
        {
            flex: 2.5,
            headerName: 'File Name',
            cellRendererFramework: (params) => AdjustableTextRenderer(params.data.filename),
            wrapText: true,
        },
        {
            field: 'created_at',
            flex: 1,
            headerName: 'Date Added',
            cellRendererFramework: DateCellRenderer,
        },
        {
            flex: 1.5,
            headerComponentFramework: () => (
                <div className="ag-cell-label-container flex">
                    <HelpIcon
                        className={styles.tooltip}
                        tooltip="Public Documents will be accessible to the awarded vendor and to the general public. Non-public documents will remain private to internal staff only."
                        useOpenGovStyle
                    />
                    <div className="ag-header-cell-text">Display</div>
                </div>
            ),
            cellRendererFramework: (params) => (
                <Box alignItems="center" display="flex">
                    <Box>
                        <Switch
                            checked={params.data.contractAttachment?.isPublic}
                            color="primary"
                            onChange={(e) => handleOnChangeToggle(e, params)}
                            qaTag="contractDocuments-toggleIsPublic"
                            size="small"
                        />
                    </Box>
                    <Box sx={{ ml: 1 }}>
                        <Typography
                            sx={{
                                color: capitalDesignTokens.foundations.colors.gray1000,
                            }}
                            variant="small"
                        >
                            Public
                        </Typography>
                    </Box>
                </Box>
            ),
        },
        {
            flex: 2,
            headerName: 'Notes',
            wrapText: true,
            cellRendererFramework: (params) => (
                <Box
                    onClick={(e) => {
                        e.stopPropagation();
                        setAttachmentBeingEdited(params.data);
                    }}
                    sx={{
                        display: 'block',
                        cursor: 'pointer',
                        minWidth: '100px',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {AdjustableTextRenderer(params.data.contractAttachment.notes)}
                </Box>
            ),
        },
        {
            cellClass: styles.iconCells,
            cellRendererFramework: (params) => (
                <Box alignItems="center" display="flex" justifyContent="flex-end" pt={0.5}>
                    <Box px={0.5}>
                        <IconButton
                            component="a"
                            download
                            href={params.data?.url}
                            qaTag="contractDocuments-downloadAttachment"
                            size="small"
                        >
                            <DownloadIcon fontSize="small" />
                        </IconButton>
                    </Box>
                    <Box px={0.5}>
                        <IconButton qaTag="contractDocuments-editAttachment" size="small">
                            <EditIcon
                                fontSize="small"
                                onClick={() => {
                                    setAttachmentBeingEdited(params.data);
                                }}
                            />
                        </IconButton>
                    </Box>
                </Box>
            ),
            flex: 1,
        },
    ];

    const defaultColDef = {
        editable: false,
        headerClass: styles.header,
        cellClass: styles.cell,
        suppressMenu: true,
        resizable: true,
    };

    return (
        <Box>
            <EditAttachment attachment={attachmentBeingEdited} onFinish={onEditFinish} />
            <CollapsableBox
                actions={
                    <Box alignItems="center" display="flex" flexDirection="row" gap={1} mt={1}>
                        {contract.attachments.length > 0 && (
                            <>
                                <Typography
                                    sx={{
                                        color: capitalDesignTokens.foundations.colors.gray800,
                                        fontWeight:
                                            capitalDesignTokens.foundations.typography.fontWeight
                                                .medium,
                                        fontSize:
                                            capitalDesignTokens.foundations.typography.fontSize
                                                .bodySmall,
                                    }}
                                >
                                    Filter by:
                                </Typography>
                                <DocumentTypesMenu
                                    handleToggle={onDocTypesSelect}
                                    onClearAll={onClearAll}
                                    selected={docTypesIds}
                                />
                            </>
                        )}

                        <UploadAttachmentButton onUploadSuccess={refreshGrid} />
                    </Box>
                }
                subtitle={
                    <Box sx={{ ml: -3 }}>
                        Upload and manage all other document types, along with approved documents
                        from vendors.
                    </Box>
                }
                title="Associated Files"
            >
                {!rows.length && <Typography fontStyle="italic">No associated files</Typography>}

                {!!rows.length && (
                    <AgGridReactLegacy
                        columns={columns}
                        defaultColDef={defaultColDef}
                        domLayout="autoHeight"
                        getRowNodeId={(data) => data.id}
                        hideSideBar
                        onGridReady={handleGridReady}
                        rowHeight={52}
                        rows={rows}
                        useOpenGovStyle
                    />
                )}
            </CollapsableBox>
        </Box>
    );
};
