import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Button } from '../Button';
import { ButtonGroup } from '../ButtonGroup/ButtonGroup';

export class ToggleButtonBox extends PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        className: PropTypes.string,
        isLeftActive: PropTypes.bool,
        leftButtonIcon: PropTypes.string.isRequired,
        leftButtonText: PropTypes.string.isRequired,
        rightButtonIcon: PropTypes.string.isRequired,
        rightButtonText: PropTypes.string.isRequired,
        toggleHandler: PropTypes.func.isRequired,
    };

    static defaultProps = {
        className: '',
    };

    handleLeftClick = () => {
        const { toggleHandler } = this.props;

        toggleHandler(true);
    };

    handleRightClick = () => {
        const { toggleHandler } = this.props;

        toggleHandler(false);
    };

    renderToggleButtons() {
        const { isLeftActive, leftButtonText, leftButtonIcon, rightButtonText, rightButtonIcon } =
            this.props;
        const styles = require('./ToggleButtonBox.scss');

        const LeftButton = (
            <Button
                active={isLeftActive}
                className={styles.toggleButton}
                onClick={this.handleLeftClick}
            >
                <i className={`fa ${leftButtonIcon}`} /> {leftButtonText}
            </Button>
        );

        const RightButton = (
            <Button
                active={!isLeftActive}
                className={styles.toggleButton}
                onClick={this.handleRightClick}
            >
                <i className={`fa ${rightButtonIcon}`} /> {rightButtonText}
            </Button>
        );

        return (
            <ButtonGroup className={styles.buttonGroup}>
                {LeftButton}
                {RightButton}
            </ButtonGroup>
        );
    }

    render() {
        const styles = require('./ToggleButtonBox.scss');
        return (
            <div className={`${styles.toggleBox} ${this.props.className}`}>
                {this.renderToggleButtons()}
                {this.props.children}
            </div>
        );
    }
}
