import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';
import { reduxForm, Field } from 'redux-form';

import { IS_VENDOR_COMPLETE } from './constants';
import { formConfig } from '../constants';
import { ProposalSectionButtons } from '../ProposalSectionButtons';
import { Button } from '../../../Button';
import { VendorProfile } from '../../../VendorProfile';
import { COMPANY_PROFILE } from '../../../ProposalCreateNav/constants';

// @reduxForm
class ConnectedProposalCompanyProfile extends Component {
    static propTypes = {
        dirty: PropTypes.bool,
        disabled: PropTypes.bool,
        // eslint-disable-next-line react/no-unused-prop-types
        form: PropTypes.string.isRequired, // Needed for @reduxForm above
        onSave: PropTypes.func.isRequired,
        sections: PropTypes.array.isRequired,
        showValidation: PropTypes.bool,
        showVendorAccountModal: PropTypes.func.isRequired,
        updateError: PropTypes.string,
        vendor: PropTypes.object.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    showVendorAccountModal = () =>
        this.props.showVendorAccountModal({
            immediatelyDisplayForm: true,
        });

    renderError = ({ meta, showValidation }) => {
        if (!meta.error) {
            return null;
        }

        return (
            <Alert bsStyle={showValidation ? 'danger' : 'warning'} className="text-center">
                <Button bsStyle="primary" onClick={this.showVendorAccountModal}>
                    <i className="fa fa-edit" /> Complete Profile
                </Button>
                <div className={`text-danger ${this.styles.incompleteProfileText}`}>
                    <strong>
                        <i className="fa fa-exclamation-triangle" /> Your Company Profile is
                        incomplete!
                    </strong>
                </div>
                <div>You must complete your company profile before submitting your response.</div>
            </Alert>
        );
    };

    render() {
        const { dirty, disabled, onSave, sections, showValidation, updateError, vendor } =
            this.props;

        return (
            <div>
                <Field
                    component={this.renderError}
                    name={IS_VENDOR_COMPLETE}
                    showValidation={showValidation}
                />
                <VendorProfile noTitle showEdit vendor={vendor} />
                <ProposalSectionButtons
                    disabled={disabled}
                    isFormDirty={dirty}
                    onSave={onSave}
                    section={COMPANY_PROFILE}
                    sections={sections}
                    updateError={updateError}
                />
            </div>
        );
    }
}

export const ProposalCompanyProfile = reduxForm(formConfig)(ConnectedProposalCompanyProfile);
