import { last, times } from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Panel } from 'react-bootstrap';
import { Draggable } from 'react-beautiful-dnd';
import { v4 as UUIDv4 } from 'uuid';
import { Box, Typography } from '@og-pro/ui';

import {
    customColumnsData,
    defaultColumnData,
    priceTableFieldNames,
    COMMENT,
    DESCRIPTION,
    DISCOUNT,
    DISCOUNT_ONLY,
    IS_HEADER_ROW,
    LINE_ITEM,
    NO_BID,
    QUANTITY,
    TAXABLE,
    TOTAL_COST,
    UNIT_PRICE,
    UNIT_TO_MEASURE,
} from '@og-pro/shared-config/priceTables';

import { LUMP_SUM } from '../../constants';
import { priceTableItemFieldNames } from '../../../constants';
import { AgGridReactLegacy, CDSButton, DragIcon } from '../../../../../../components';
import { suppressKeyboardEvent } from '../../../../../../helpers/agGrid';
import { getMaxNumberFromList } from '../../../../../../utils';
import {
    AddPriceItemButton,
    Legend,
    OptionsModal,
    OptionsTitle,
    PriceTableButtons,
} from '../components';
import {
    PANEL_BODY_STYLES,
    PANEL_STYLES,
    AUTO_HEIGHT_ROW_THRESHOLD,
    CONTAINER_STYLE,
} from '../constants';
import {
    generateCellStyle,
    getFormattedPriceTableDataForGrid,
    getRowNodeId,
    processCellFromClipboard,
    unitPricePercentageFormatter,
} from '../helpers';
import { currencyFormatter } from '../../../../../../helpers';

const { COLUMN_ORDER } = priceTableFieldNames;

/**
 * @typedef PriceItem
 * @property {string} description The value for the description
 * @property {number} [discount] The value for the percentage discount
 * @property {number} id The ID of the price item
 * @property {string} lineItem The value for the line item
 * @property {number} [quantity] The value for the quantity
 * @property {number} [unitPrice] The value for the unit price
 * @property {string} [unitToMeasure] The value for the unit to measure
 */

const { ORDER_BY_ID } = priceTableItemFieldNames;

const SALES_TAX_ROW = [{ [DESCRIPTION]: 'Sales Tax', isSummaryRow: true }];
const SUMMARY_ROW = [{ [DESCRIPTION]: 'Total', isSummaryRow: true }];

export class StandardPriceTableBuilder extends PureComponent {
    static propTypes = {
        change: PropTypes.func.isRequired,
        copyPriceTable: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        draggableIndex: PropTypes.number.isRequired,
        draggableName: PropTypes.string.isRequired,
        fields: PropTypes.shape({
            insert: PropTypes.func.isRequired,
            move: PropTypes.func.isRequired,
            name: PropTypes.string.isRequired,
            push: PropTypes.func.isRequired,
            remove: PropTypes.func.isRequired,
            swap: PropTypes.func.isRequired,
        }).isRequired,
        priceTable: PropTypes.shape({
            columnOrder: PropTypes.array.isRequired,
            description: PropTypes.string,
            hasComment: PropTypes.bool,
            hasDiscount: PropTypes.bool,
            hasNoBid: PropTypes.bool,
            hasPercentage: PropTypes.bool,
            hasQuantity: PropTypes.bool,
            omitLineItem: PropTypes.bool,
            specifyQuantity: PropTypes.bool,
            hasTotalRow: PropTypes.bool,
            hasSalesTaxRow: PropTypes.bool,
            specifyUnitPrice: PropTypes.bool,
            priceItems: PropTypes.arrayOf(
                PropTypes.shape({
                    [IS_HEADER_ROW]: PropTypes.bool,
                    [LINE_ITEM]: PropTypes.string,
                    [DESCRIPTION]: PropTypes.string,
                    [DISCOUNT]: PropTypes.number,
                    [DISCOUNT_ONLY]: PropTypes.bool,
                    id: PropTypes.number,
                    [ORDER_BY_ID]: PropTypes.number.isRequired,
                    [QUANTITY]: PropTypes.number,
                    [TAXABLE]: PropTypes.bool,
                    [UNIT_PRICE]: PropTypes.number,
                    [UNIT_TO_MEASURE]: PropTypes.string,
                })
            ).isRequired,
            title: PropTypes.string,
        }).isRequired,
        priceTableArrayName: PropTypes.string.isRequired,
        removeTable: PropTypes.func.isRequired,
        salesTax: PropTypes.number,
        showHelpModal: PropTypes.func.isRequired,
        showValidation: PropTypes.bool,
        useOpenGovStyle: PropTypes.bool,
    };

    static defaultProps = {
        disabled: false,
        showValidation: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            columnsMoved: false,
            defaultColDef: {
                cellStyle: this.getCellStyle,
                editable: this.isEditable,
                headerComponent: 'priceTableHeader',
                sortable: false,
                suppressMenu: true,
            },
            dragFromIndex: null,
            dragging: false,
            showOptionsModal: false,
            showTableSettingsPage: true,
            stateKey: 1,
        };
    }

    get styles() {
        return require('../index.scss');
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.priceTable.specifyUnitPrice !== this.props.priceTable.specifyUnitPrice ||
            prevProps.priceTable.specifyQuantity !== this.props.priceTable.specifyQuantity
        ) {
            this.state.gridApi.redrawRows();
        }
    }

    unitPriceCurrencyFormatter = (data) => currencyFormatter(data, { use4FractionDigits: true });

    getCellStyle = (params) => {
        const {
            priceTable: { hasQuantity, omitLineItem, specifyQuantity },
            showValidation,
        } = this.props;

        return generateCellStyle(
            {
                hasQuantity,
                omitLineItem,
                showValidation,
                specifyQuantity,
            },
            params
        );
    };

    generateColDefs = () => {
        const {
            priceTable,
            priceTable: {
                columnOrder,
                hasComment,
                hasDiscount,
                hasPercentage,
                hasQuantity,
                hasNoBid,
                hasSalesTaxRow,
                hasTotalRow,
                omitLineItem,
                specifyUnitPrice,
                specifyQuantity,
            },
            salesTax,
        } = this.props;

        const columnDefinitions = {
            [LINE_ITEM]: {
                cellEditorSelector: (params) => {
                    if (params.data.isHeaderRow) {
                        return 'agLargeTextCellEditor';
                    }
                    return 'textEditor';
                },
                cellEditorParams: (params) => {
                    if (params.data.isHeaderRow) {
                        return { maxLength: 3000 };
                    }
                    return { maxLength: 64 };
                },
                colSpan: (params) => {
                    if (params.data.isHeaderRow) {
                        return Infinity;
                    }
                    return 1;
                },
                valueGetter: (params) => {
                    if (params.data.isHeaderRow) {
                        return params.data[DESCRIPTION];
                    }
                    return params.data[LINE_ITEM];
                },
                valueSetter: this.handleCellValueChanged,
                field: LINE_ITEM,
                headerComponentParams: {
                    isRequired: true,
                    tooltipText: 'All cells in this column must have a value',
                },
                headerName: priceTable[defaultColumnData[LINE_ITEM].headerField],
                hide: omitLineItem,
                width: 100,
                rowDrag: !omitLineItem,
                suppressKeyboardEvent,
            },
            [DESCRIPTION]: {
                autoHeight: true,
                cellEditor: 'agLargeTextCellEditor',
                cellEditorParams: {
                    maxLength: 3000,
                    rows: 4,
                },
                field: DESCRIPTION,
                flex: 1,
                headerComponentParams: {
                    isRequired: true,
                    tooltipText: 'All cells in this column must have a value',
                },
                headerName: priceTable[defaultColumnData[DESCRIPTION].headerField],
                minWidth: 200,
                rowDrag: omitLineItem, // Legacy projects don't have the line item col so we need the drag pad here in that case
                suppressKeyboardEvent,
            },
            [QUANTITY]: {
                cellEditor: 'numericEditor',
                field: QUANTITY,
                headerComponentParams: {
                    isRequired: specifyQuantity,
                    tooltipText: specifyQuantity
                        ? 'All cells in this column must have a value'
                        : 'Optionally provide values in this column. Empty cells will be filled in by the vendor.',
                },
                headerName: priceTable[defaultColumnData[QUANTITY].headerField],
                hide: !hasQuantity,
                suppressKeyboardEvent,
                width: 100,
            },
            [UNIT_TO_MEASURE]: {
                cellEditor: 'textEditor',
                cellEditorParams: {
                    maxLength: 64,
                },
                field: UNIT_TO_MEASURE,
                headerComponentParams: {
                    isRequired: true,
                    tooltipText: 'All cells in this column must have a value',
                },
                headerName: priceTable[defaultColumnData[UNIT_TO_MEASURE].headerField],
                suppressKeyboardEvent,
                width: 140,
            },
            [DISCOUNT_ONLY]: {
                cellRendererSelector: (params) => {
                    return {
                        component: 'checkboxRenderer',
                        params: {
                            readOnly: params.data.isSummaryRow,
                        },
                    };
                },
                cellStyle: { textAlign: 'center' },
                field: DISCOUNT_ONLY,
                headerComponentParams: {
                    tooltipText: 'Select if row will only be a discount (disables unit cost)',
                },
                headerName: priceTable[defaultColumnData[DISCOUNT_ONLY].headerField],
                hide: !hasDiscount || hasTotalRow,
                suppressNavigable: true,
                width: 125,
            },
            [TAXABLE]: {
                cellRendererSelector: (params) => {
                    return {
                        component: 'checkboxRenderer',
                        params: {
                            readOnly: params.data.isSummaryRow,
                        },
                    };
                },
                cellStyle: { textAlign: 'center' },
                field: TAXABLE,
                headerComponentParams: {
                    tooltipText: 'Select which items will be taxable',
                },
                headerName: priceTable[defaultColumnData[TAXABLE].headerField],
                hide: !salesTax || !hasSalesTaxRow,
                suppressNavigable: true,
                width: 95,
            },
            [UNIT_PRICE]: {
                cellEditor: 'numericEditor',
                editable: (params) => {
                    if (params.data.discountOnly) {
                        return false;
                    }
                    return !!specifyUnitPrice;
                },
                field: UNIT_PRICE,
                headerComponentParams: {
                    icon: specifyUnitPrice ? undefined : 'fa-lock',
                    tooltipText: specifyUnitPrice
                        ? 'Optionally provide values in this column. Empty cells will be filled in by the vendor.'
                        : 'Cells in this column are reserved for the vendor to complete',
                },
                headerName: priceTable[defaultColumnData[UNIT_PRICE].headerField],
                suppressKeyboardEvent: specifyUnitPrice ? suppressKeyboardEvent : undefined,
                suppressNavigable: !specifyUnitPrice,
                valueFormatter: hasPercentage
                    ? unitPricePercentageFormatter
                    : this.unitPriceCurrencyFormatter,
                width: 150,
            },
            [DISCOUNT]: {
                editable: false,
                field: DISCOUNT,
                headerComponentParams: {
                    icon: 'fa-lock',
                    tooltipText: 'Cells in this column are reserved for the vendor to complete',
                },
                headerName: priceTable[defaultColumnData[DISCOUNT].headerField],
                hide: !hasDiscount,
                suppressNavigable: true,
                width: 130,
            },
            [TOTAL_COST]: {
                editable: false,
                field: TOTAL_COST,
                headerComponentParams: {
                    icon: 'fa-lock',
                    tooltipText: 'Cells in this column are reserved for the vendor to complete',
                },
                headerName: priceTable[defaultColumnData[TOTAL_COST].headerField],
                hide: !hasQuantity,
                suppressNavigable: true,
                width: 100,
            },
            [NO_BID]: {
                editable: false,
                field: NO_BID,
                headerComponentParams: {
                    icon: 'fa-lock',
                    tooltipText: 'Cells in this column are reserved for the vendor to complete',
                },
                headerName: priceTable[defaultColumnData[NO_BID].headerField],
                hide: !hasNoBid,
                suppressNavigable: true,
                width: 85,
            },
        };

        if (hasComment) {
            columnDefinitions[COMMENT] = {
                editable: false,
                field: COMMENT,
                headerComponentParams: {
                    icon: 'fa-lock',
                    tooltipText: 'Cells in this column are reserved for the vendor to complete',
                },
                headerName: priceTable[defaultColumnData[COMMENT].headerField],
                suppressNavigable: true,
                width: 100,
            };
        }

        customColumnsData.forEach((columnData) => {
            columnDefinitions[columnData.priceItemField] = {
                cellEditor: 'textEditor',
                cellEditorParams: {
                    maxLength: 510,
                },
                field: columnData.priceItemField,
                headerName: priceTable[columnData.headerField],
                headerComponentParams: {
                    tooltipText:
                        'Optionally provide values in this column. Empty cells can be filled in by the vendor.',
                },
                hide: !priceTable[columnData.includedField],
                suppressKeyboardEvent,
                width: 100,
            };
        });

        return columnOrder.map((columnIdentifier) => columnDefinitions[columnIdentifier]);
    };

    /**
     * https://www.ag-grid.com/javascript-grid-context-menu/#configuring-the-context-menu
     *
     * @param {object} params The event parameters
     * @param {object} params.node The node representing the cell that was clicked on
     * @param {number} params.node.rowIndex The index of the row that was clicked on
     * @return {array} The list of context menu items
     */
    getContextMenuItems = (params) => {
        const {
            fields,
            priceTable: { priceItems },
        } = this.props;

        const index = params.node.rowIndex;

        return [
            'copy',
            {
                name: 'Insert Before',
                action: () => {
                    this.handleInsertPriceItem(index);
                },
            },
            {
                name: 'Insert Section Header Before',
                action: () => {
                    this.handleInsertPriceItem(index, true);
                },
            },
            {
                name: 'Insert After',
                action: () => {
                    this.handleInsertPriceItem(index + 1);
                },
            },
            {
                name: 'Insert Section Header Row After',
                action: () => {
                    this.handleInsertPriceItem(index + 1, true);
                },
            },
            {
                name: 'Move Down',
                action: () => {
                    const isLastItem = index === priceItems.length - 1;

                    if (!isLastItem) {
                        this.swapFields(index, index + 1);
                    }
                },
            },
            {
                name: 'Move Up',
                action: () => {
                    const isFirstItem = index === 0;

                    if (!isFirstItem) {
                        this.swapFields(index, index - 1);
                    }
                },
            },
            {
                name: 'Remove',
                action: () => {
                    fields.remove(index);
                    this.handleDomLayoutChange(priceItems.length, -1);
                },
            },
        ];
    };

    getPinnedBottomRow = () => {
        const {
            priceTable: { hasSalesTaxRow, hasTotalRow },
            salesTax,
        } = this.props;

        if (hasTotalRow && salesTax && hasSalesTaxRow) {
            return [...SALES_TAX_ROW, ...SUMMARY_ROW];
        }

        if (hasTotalRow && (!salesTax || !hasSalesTaxRow)) {
            return SUMMARY_ROW;
        }

        return undefined;
    };

    /**
     * Given an `onCellValueChanged` event from the underlying AgGridReact, inputs the new value
     * into the appropriate price item form field.
     *
     * @param {object} event The `onCellValueChanged` event from the underlying AgGridReact
     */
    handleCellValueChanged = (event) => {
        const { change, fields, priceTable } = this.props;

        // Find index of priceItem that had the cell value change
        const priceItemIndex = priceTable.priceItems.findIndex((priceItem) => {
            const priceItemId = priceItem.id || priceItem.newPriceItemUuid;
            return priceItemId === event.data.id;
        });

        if (priceItemIndex !== -1) {
            const columnFieldOfUpdatedCell = event.column.colDef.field;

            // Header row description is edited by the LINE_ITEM column
            if (event.data.isHeaderRow) {
                change(`${fields.name}[${priceItemIndex}].${DESCRIPTION}`, event.newValue);
            } else {
                change(
                    `${fields.name}[${priceItemIndex}].${columnFieldOfUpdatedCell}`,
                    event.newValue
                );
            }
        }

        if (event.column.colDef.field === DESCRIPTION) {
            this.state.gridApi.resetRowHeights();
        }

        if (event.column.colDef.field === DISCOUNT_ONLY) {
            if (event.newValue) {
                change(`${fields.name}[${priceItemIndex}].${UNIT_PRICE}`, null);
            }
            this.state.gridApi.redrawRows(event.node);
        }
    };

    /*
     * Add a new priceItem to the end of the table.
     */
    handleAddPriceItem = (numItems = 1, isHeaderRow) => {
        const {
            fields,
            priceTable: { priceItems },
        } = this.props;

        const basePriceItem = this.generateNewPriceItem(isHeaderRow);

        times(numItems).forEach((index) => {
            const newPriceItemToAdd = {
                ...basePriceItem,
                newPriceItemUuid: UUIDv4(),
                [ORDER_BY_ID]: basePriceItem[ORDER_BY_ID] + index,
            };
            fields.push(newPriceItemToAdd);
        });

        this.handleDomLayoutChange(priceItems.length, numItems);
    };

    handleColumnChange = () => {
        this.state.gridApi.redrawRows();
    };

    handleDomLayoutChange = (originalCount, newNum) => {
        const newCount = originalCount + newNum;
        if (
            (originalCount <= AUTO_HEIGHT_ROW_THRESHOLD && newCount > AUTO_HEIGHT_ROW_THRESHOLD) ||
            (originalCount > AUTO_HEIGHT_ROW_THRESHOLD && newCount <= AUTO_HEIGHT_ROW_THRESHOLD)
        ) {
            this.setState((prevState) => ({ stateKey: prevState.stateKey + 1 }));
        }
    };

    handleGridReady = (params) => {
        this.setState({ gridApi: params.api });
    };

    /**
     * Add a new priceItem at a specific index in the table.
     *
     * @param {number} atIndex When specified, adds the new priceItem at the specified index
     * @param {boolean} isHeaderRow When specified, indicates the new price item is a header row
     */
    handleInsertPriceItem = (atIndex, isHeaderRow) => {
        const {
            change,
            fields,
            priceTable: { priceItems },
        } = this.props;

        // Update the order of all existing items to ready for insertion of new price item row
        priceItems.forEach((priceItem, index) => {
            const newOrderById = index + (index >= atIndex ? 2 : 1);
            change(`${fields.name}[${index}].${ORDER_BY_ID}`, newOrderById);
        });

        const priceItemBeforeInsert = priceItems[atIndex - 1];
        const newPriceItemToInsert = {
            ...this.generateNewPriceItem(isHeaderRow, priceItemBeforeInsert),
            [ORDER_BY_ID]: atIndex + 1,
        };

        fields.insert(atIndex, newPriceItemToInsert);

        this.handleDomLayoutChange(priceItems.length, 1);
    };

    handleOptionsToggleClick = () => {
        this.setState((prevState) => {
            return {
                showOptionsModal: !prevState.showOptionsModal,
                showTableSettingsPage: true,
            };
        });
    };

    toggleShowTableSettingsPage = () => {
        this.setState((prevState) => {
            return {
                showTableSettingsPage: !prevState.showTableSettingsPage,
            };
        });
    };

    handleColumnMoved = (params) => {
        // Column moving does not work correctly when `columnOrder` is changed and new column defs
        // are re-rendered. As a workaround, state is set here when columns change and when the
        // column moving has been stopped the change is stored in `redux-form` by the
        // `handleDragStopped` function
        this.setState({
            columnsMoved: true,
            tempColumnOrder: params.columnApi.getColumnState().map((col) => {
                // When moving columns, column state can get a "_1" appended to it like "description_1".
                // Seems like an `ag-grid` bug, this is just a workaround to find the correct column ID.
                return col.colId.replace(/_\d+$/, '');
            }),
        });
    };

    handleRowDragEnter = (event) => {
        // `onRowDragEnter` can fire multiple times before `onRowDragEnd`.
        // We only want to capture the index from the initial `onRowDragEnter` event.
        if (!this.state.dragging) {
            this.setState({
                dragFromIndex: event.overIndex,
                dragging: true,
            });
        }
    };

    handleRowDragEnd = (event) => {
        const { overIndex: dragToIndex } = event;

        const { change, fields, priceTable } = this.props;

        const { dragFromIndex } = this.state;

        // Reset state for next drag event handling
        this.setState({
            dragFromIndex: null,
            dragging: false,
        });

        // No-op if index didn't change
        if (dragFromIndex === dragToIndex) {
            return;
        }

        // Build map to keep track of original indexes before move
        const oldIndexMap = {};
        priceTable.priceItems.forEach((priceItem, index) => {
            const priceItemId = priceItem.id || priceItem.newPriceItemUuid;
            oldIndexMap[priceItemId] = index;
        });

        // Arrange price items into new order
        const newPriceItems = priceTable.priceItems.slice();
        const priceItemToMove = newPriceItems[dragFromIndex];
        newPriceItems.splice(dragFromIndex, 1);
        newPriceItems.splice(dragToIndex, 0, priceItemToMove);

        // Reassign `ORDER_BY_ID` numbers to each price item
        newPriceItems.forEach((priceItem, index) => {
            const priceItemId = priceItem.id || priceItem.newPriceItemUuid;
            const oldIndex = oldIndexMap[priceItemId];
            change(`${fields.name}[${oldIndex}].${ORDER_BY_ID}`, index + 1);
        });

        // Rearrange the fields in redux form
        fields.move(dragFromIndex, dragToIndex);
    };

    // Called on any drag event in the grid. We only care about drag stopped events when the drag
    // caused the columns to move.
    handleDragStopped = () => {
        const { change, priceTableArrayName } = this.props;

        const { columnsMoved, tempColumnOrder } = this.state;

        if (columnsMoved) {
            this.setState({
                columnsMoved: false,
            });
            change(`${priceTableArrayName}.${COLUMN_ORDER}`, tempColumnOrder);
        }
    };

    generateNewPriceItem = (isHeaderRow, lastRow) => {
        const {
            priceTable: { priceItems },
        } = this.props;

        const baseNewItem = {
            newPriceItemUuid: UUIDv4(),
            [ORDER_BY_ID]: getMaxNumberFromList(priceItems, ORDER_BY_ID) + 1,
            [IS_HEADER_ROW]: isHeaderRow,
        };

        const lastItem = lastRow ?? last(priceItems);

        const newItem =
            !lastItem || isHeaderRow
                ? baseNewItem
                : {
                      ...baseNewItem,
                      [UNIT_TO_MEASURE]: lastItem[UNIT_TO_MEASURE],
                      [QUANTITY]: lastItem[UNIT_TO_MEASURE] === LUMP_SUM ? 1 : undefined,
                  };

        return newItem;
    };

    isEditable = (row) => {
        if (row.data.isSummaryRow) {
            return false;
        }
        return true;
    };

    swapFields = (index1, index2) => {
        const {
            fields,
            change,
            priceTable: { priceItems },
        } = this.props;

        change(`${fields.name}[${index1}].${ORDER_BY_ID}`, priceItems[index2][ORDER_BY_ID]);
        change(`${fields.name}[${index2}].${ORDER_BY_ID}`, priceItems[index1][ORDER_BY_ID]);

        fields.swap(index1, index2);
    };

    render() {
        const {
            change,
            copyPriceTable,
            disabled,
            draggableIndex,
            draggableName,
            fields,
            priceTable,
            priceTable: { description, priceItems, title },
            priceTableArrayName,
            removeTable,
            salesTax,
            showHelpModal,
            showValidation,
            useOpenGovStyle,
        } = this.props;

        const { defaultColDef, showOptionsModal, showTableSettingsPage, stateKey } = this.state;

        const sharedComponents = (
            <>
                <OptionsModal
                    arrayName={priceTableArrayName}
                    change={change}
                    columnChangeHandler={this.handleColumnChange}
                    disabled={disabled}
                    fields={fields}
                    optionsToggleHandler={this.handleOptionsToggleClick}
                    priceTable={priceTable}
                    salesTax={salesTax}
                    showOptionsModal={showOptionsModal}
                    showTableSettingsPage={showTableSettingsPage}
                    showValidation={showValidation}
                    toggleShowTableSettingsPage={this.toggleShowTableSettingsPage}
                />
                <AgGridReactLegacy
                    autoHeightMaxRows={AUTO_HEIGHT_ROW_THRESHOLD}
                    columns={this.generateColDefs()}
                    containerStyle={CONTAINER_STYLE}
                    defaultColDef={defaultColDef}
                    getContextMenuItems={this.getContextMenuItems}
                    getRowNodeId={getRowNodeId}
                    hideSideBar
                    key={stateKey}
                    onCellValueChanged={this.handleCellValueChanged}
                    onColumnMoved={this.handleColumnMoved}
                    onDragStopped={this.handleDragStopped}
                    onGridReady={this.handleGridReady}
                    onRowDragEnd={this.handleRowDragEnd}
                    onRowDragEnter={this.handleRowDragEnter}
                    pinnedBottomRow={this.getPinnedBottomRow()}
                    processCellFromClipboard={processCellFromClipboard}
                    rowDragManaged
                    rows={getFormattedPriceTableDataForGrid(priceItems)}
                    suppressMovableColumns={false}
                />
            </>
        );

        if (useOpenGovStyle) {
            return (
                <Draggable
                    draggableId={draggableName}
                    index={draggableIndex}
                    isDragDisabled={disabled}
                    key={draggableName}
                >
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.draggableProps}>
                            <Box className={this.styles.container}>
                                <Box className={this.styles.item}>
                                    <Box className={this.styles.header}>
                                        <Box mr={1}>
                                            <DragIcon
                                                disabled={disabled}
                                                dragHandleProps={provided.dragHandleProps}
                                            />
                                        </Box>
                                        <Box mr={2}>
                                            <Typography variant="h3">
                                                {title || 'Untitled Table'}
                                            </Typography>
                                            {description && <p>{description}</p>}
                                        </Box>
                                        <Box display="flex" flex={1} justifyContent="flex-end">
                                            <Box mr={2}>
                                                <CDSButton
                                                    disabled={disabled}
                                                    onClick={this.handleOptionsToggleClick}
                                                    qaTag="priceTable-tableSettings"
                                                    size="small"
                                                    variant="text"
                                                >
                                                    <i className="fa fa-cog" /> Table Settings
                                                </CDSButton>
                                            </Box>
                                            <Box mr={2}>
                                                <CDSButton
                                                    disabled={disabled}
                                                    onClick={copyPriceTable}
                                                    qaTag="priceTable-copyTable"
                                                    size="small"
                                                    variant="text"
                                                >
                                                    <i className="fa fa-copy" /> Copy Table
                                                </CDSButton>
                                            </Box>
                                            <Box>
                                                <CDSButton
                                                    className={this.styles.destructiveButton}
                                                    disabled={disabled}
                                                    onClick={removeTable}
                                                    qaTag="priceTable-copyTable"
                                                    size="small"
                                                    variant="text"
                                                >
                                                    <i className="fa fa-trash" /> Delete Table
                                                </CDSButton>{' '}
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box>{sharedComponents}</Box>
                                </Box>
                                <Box px={3}>
                                    <AddPriceItemButton
                                        disabled={disabled}
                                        onClick={this.handleAddPriceItem}
                                        useOpenGovStyle
                                    />
                                </Box>
                            </Box>
                        </div>
                    )}
                </Draggable>
            );
        }

        return (
            <Draggable
                draggableId={draggableName}
                index={draggableIndex}
                isDragDisabled={disabled}
                key={draggableName}
            >
                {(provided) => (
                    <div ref={provided.innerRef} {...provided.draggableProps}>
                        <Panel defaultExpanded style={PANEL_STYLES}>
                            <Panel.Body style={PANEL_BODY_STYLES}>
                                <div className={this.styles.tableHeader}>
                                    <DragIcon
                                        containerClassName={classnames(
                                            'pull-left',
                                            this.styles.dragIcon
                                        )}
                                        disabled={disabled}
                                        dragHandleProps={provided.dragHandleProps}
                                    />
                                    <OptionsTitle
                                        description={description}
                                        optionsToggleHandler={this.handleOptionsToggleClick}
                                        showHelpModal={showHelpModal}
                                        title={title}
                                    />
                                    <div className="row">
                                        <div className="col-xs-4">
                                            <Legend showValidation={showValidation} />
                                        </div>
                                        <div className="col-xs-8 text-right">
                                            <PriceTableButtons
                                                copyPriceTable={copyPriceTable}
                                                disabled={disabled}
                                                handleOptionsToggleClick={
                                                    this.handleOptionsToggleClick
                                                }
                                                removeTable={removeTable}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {sharedComponents}
                                <AddPriceItemButton
                                    disabled={disabled}
                                    onClick={this.handleAddPriceItem}
                                />
                            </Panel.Body>
                        </Panel>
                    </div>
                )}
            </Draggable>
        );
    }
}
