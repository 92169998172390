import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Tooltip } from '../Tooltip';
import { scoreColor, scoreText } from '../../helpers';

export class CriteriaScore extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        comment: PropTypes.string,
        criteriaScore: PropTypes.number,
        isTotal: PropTypes.bool,
        isUnsubmittedScore: PropTypes.bool,
        onClick: PropTypes.func,
        rankedScore: PropTypes.number,
        scoringCriteria: PropTypes.shape({
            maxScore: PropTypes.number.isRequired,
            scoringMethod: PropTypes.number,
            title: PropTypes.string.isRequired,
        }).isRequired,
        showPercentageTotals: PropTypes.bool,
        showTitle: PropTypes.bool,
        titleText: PropTypes.string,
        totalScorePercentage: PropTypes.number,
        usesRankedScoring: PropTypes.bool,
    };

    renderWithTooltip() {
        const { comment } = this.props;

        return (
            <Tooltip placement="bottom" tooltip={comment}>
                {this.renderScore()}
            </Tooltip>
        );
    }

    renderScore() {
        const { className, onClick, showTitle, titleText, ...props } = this.props;

        return (
            <div className={classnames(`bg-${scoreColor(props)}`, className)} onClick={onClick}>
                {showTitle && (titleText || <span>{props.scoringCriteria.title}:&nbsp;</span>)}
                {scoreText(props)}
            </div>
        );
    }

    render() {
        return this.props.comment ? this.renderWithTooltip() : this.renderScore();
    }
}
