import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@og-pro/ui';

import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';

import { downloadRequisitionsListCSVFile } from '../../../../../actions/requisitions';
import { useQueryParam } from '../../../../../hooks';
import { getRequisitionsSearchResults } from '../selectors';
import { getUserJS } from '../../../../selectors';

export const RequisitionsListExport = (props) => {
    const dispatch = useDispatch();

    const [params] = useQueryParam();

    const requisitions = useSelector(getRequisitionsSearchResults);
    const { organization } = useSelector(getUserJS);

    const [isExportingToCSV, setIsExportingToCSV] = useState(false);

    const exportToCSVHandler = () => {
        setIsExportingToCSV(true);

        dispatch(downloadRequisitionsListCSVFile(params, organization.timezone)).finally(() =>
            setIsExportingToCSV(false)
        );
    };

    return (
        <Button
            color="secondary"
            disabled={isExportingToCSV || requisitions.length === 0}
            onClick={exportToCSVHandler}
            qaTag="requisitionsDashboard-export"
            startIcon={<IosShareOutlinedIcon />}
            variant="outlined"
            {...props}
        >
            Export
        </Button>
    );
};
