import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from '@og-pro-migration-tools/react-router';

import { BasicProjectOverview } from '../BasicProjectOverview/BasicProjectOverview';
import { PostQuestionnaire } from '../PostQuestionnaire';
import { ProjectProposalDocuments } from '../ProjectProposalDocuments/ProjectProposalDocuments';
import { mapStateToProps as projectPostCreateMapStateToProps } from '../mapProps';
import { extendSimpleWizardForm } from '../../../../hocs';

const mapStateToProps = (state, props) => {
    return {
        ...projectPostCreateMapStateToProps(state, props),
        // Set to true so form will be loaded without regard for form initialization
        // The subcomponent will do the check for form initialization
        initialized: true,
    };
};

/**
 * Component simply acts to route between the initial form to render as part
 * of the project post create flow
 */

// @connect
// @extendSimpleWizardForm
class ConnectedProjectPostCreateIndex extends Component {
    static propTypes = {
        hasQuestionnaire: PropTypes.bool,
        project: PropTypes.shape({
            isPostOnly: PropTypes.bool,
        }).isRequired,
        tagOptions: PropTypes.array,
        templateVariableOptions: PropTypes.array,
    };

    static defaultProps = {
        hasQuestionnaire: false,
        tagOptions: undefined,
    };

    render() {
        const { hasQuestionnaire, project, tagOptions } = this.props;

        if (project.isPostOnly) {
            return <BasicProjectOverview {...this.props} tagOptions={tagOptions} />;
        }

        if (hasQuestionnaire) {
            return <PostQuestionnaire {...this.props} />;
        }

        return <ProjectProposalDocuments {...this.props} />;
    }
}

export const ProjectPostCreateIndex = compose(
    withRouter,
    connect(mapStateToProps),
    extendSimpleWizardForm()
)(ConnectedProjectPostCreateIndex);
