import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Dropdown } from '../../../Dropdown';
import { MenuItem } from '../../../MenuItem/MenuItem';

export class ProposalDropdownMenu extends PureComponent {
    static propTypes = {
        deleteProposal: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        noBidProposal: PropTypes.func.isRequired,
        showFormValidationHandler: PropTypes.func,
    };

    render() {
        const { deleteProposal, disabled, noBidProposal, showFormValidationHandler } = this.props;

        return (
            <Dropdown
                bsSize="small"
                disabled={disabled}
                id="proposal-create-dropdown-menu"
                pullRight
                qaTag="proposalEdit-proposalCreateMenu"
            >
                <Dropdown.Toggle>Options</Dropdown.Toggle>
                <Dropdown.Menu>
                    <MenuItem onClick={showFormValidationHandler} qaTag="proposalCreate-showErrors">
                        <i className="fa fa-fw fa-exclamation-triangle" /> Show Form Errors
                    </MenuItem>
                    <MenuItem onClick={noBidProposal} qaTag="proposalCreate-noBidProject">
                        <i className="fa fa-fw fa-ban" /> No Bid Project
                    </MenuItem>
                    <MenuItem onClick={deleteProposal} qaTag="proposalCreate-deleteProposal">
                        <i className="fa fa-fw fa-trash" /> Delete Response
                    </MenuItem>
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}
