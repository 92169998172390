import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getFormNames, getFormSyncErrors } from 'redux-form';
import { Box } from '@og-pro/ui';

import { ContentBlock } from '../../../ContentBlock';

const getBlockError = ({ errors, formErrors }) => {
    if (!errors || !formErrors) {
        return null;
    }

    const sectionErrors = get(formErrors, errors.key);

    if (errors.fields.some((field) => sectionErrors && sectionErrors[field])) {
        return 'Some of the fields in this list item are empty or invalid. Please add content, edit or delete the list item.';
    }

    return null;
};

export const CriteriaItemV2 = ({
    actions,
    description,
    disabled = false,
    errors,
    hideDragIcon = false,
    isDragging = false,
    manualNumber,
    title,
    useManualNumbering = false,
    showValidation,
}) => {
    const [form] = useSelector((state) => getFormNames()(state));
    const formErrors = useSelector((state) => getFormSyncErrors(form)(state));
    const styles = require('./index.scss');

    const blockError = getBlockError({ errors, formErrors });

    return (
        <>
            <ContentBlock
                className={styles.layout}
                error={showValidation && blockError}
                withActions
            >
                <ContentBlock.Main disabled={disabled} hideInputsBorders>
                    {useManualNumbering && (
                        <Box mb={2}>
                            <div className={styles.label}>Line Item Number</div>
                            <span className={styles.manualNumber}>{manualNumber}</span>
                        </Box>
                    )}
                    <Box className={styles.titleContainer} mb={2} pb={2}>
                        <div className={styles.label} id={`${title.props.name}-label`}>
                            Title
                        </div>
                        {title}
                    </Box>

                    {description}
                </ContentBlock.Main>
                <ContentBlock.ActionSidebar>
                    {/*
                     * To make react-beautiful-dnd happy, the drag icon must exist.
                     * To accomplish that we will hide it with CSS using the `hidden` prop when we don't want to allow dragging.
                     *
                     * This is why it differs from the conditional rendering of other buttons below.
                     */}
                    <ContentBlock.ButtonGroup hidden={useManualNumbering || hideDragIcon}>
                        {!!actions.moveUp && (
                            <ContentBlock.Button>{actions.moveUp}</ContentBlock.Button>
                        )}
                        <ContentBlock.Button>{actions.drag}</ContentBlock.Button>
                        {!!actions.moveDown && (
                            <ContentBlock.Button>{actions.moveDown}</ContentBlock.Button>
                        )}
                    </ContentBlock.ButtonGroup>
                    {actions.questionLogic && (
                        <ContentBlock.ButtonGroup>
                            <ContentBlock.Button>{actions.questionLogic}</ContentBlock.Button>
                        </ContentBlock.ButtonGroup>
                    )}
                    {actions.addReviewInstructions && (
                        <ContentBlock.ButtonGroup>
                            <ContentBlock.Button>
                                {actions.addReviewInstructions}
                            </ContentBlock.Button>
                        </ContentBlock.ButtonGroup>
                    )}
                    {actions.comments && (
                        <ContentBlock.ButtonGroup>
                            <ContentBlock.Button>{actions.comments}</ContentBlock.Button>
                        </ContentBlock.ButtonGroup>
                    )}
                    {actions.delete && (
                        <ContentBlock.ButtonGroup>
                            <ContentBlock.Button>{actions.delete}</ContentBlock.Button>
                        </ContentBlock.ButtonGroup>
                    )}
                </ContentBlock.ActionSidebar>
            </ContentBlock>
            {!isDragging && actions.insert}
        </>
    );
};

CriteriaItemV2.propTypes = {
    actions: PropTypes.shape({
        comments: PropTypes.node,
        delete: PropTypes.node,
        drag: PropTypes.node.isRequired,
        insert: PropTypes.node,
        questionLogic: PropTypes.node,
        addReviewInstructions: PropTypes.node,
        moveDown: PropTypes.node,
        moveUp: PropTypes.node,
    }),
    description: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
    errors: PropTypes.shape({
        key: PropTypes.string.isRequired,
        fields: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
    hideDragIcon: PropTypes.bool,
    isDragging: PropTypes.bool.isRequired,
    manualNumber: PropTypes.node.isRequired,
    showValidation: PropTypes.bool,
    title: PropTypes.node.isRequired,
    useManualNumbering: PropTypes.bool.isRequired,
};
