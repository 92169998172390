import React, { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

export const CreateCustomFieldContext = createContext({
    showFormValidation: false,
    setShowFormValidation: () => {},
    formErrorsCount: 0,
    setFormErrorsCount: () => {},
});

export const CreateCustomFieldProvider = ({ children }) => {
    const [showFormValidation, setShowFormValidation] = useState(false);
    const [formErrorsCount, setFormErrorsCount] = useState(0);

    const value = useMemo(() => {
        return {
            showFormValidation,
            setShowFormValidation,
            formErrorsCount,
            setFormErrorsCount,
        };
    }, [showFormValidation, setShowFormValidation, formErrorsCount, setFormErrorsCount]);

    return (
        <CreateCustomFieldContext.Provider value={value}>
            {children}
        </CreateCustomFieldContext.Provider>
    );
};

CreateCustomFieldProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
