import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';

import { IS_ADDENDUM_CONFIRMED } from './constants';
import { formConfig } from '../constants';
import { ProposalSectionButtons } from '../ProposalSectionButtons';

import { Checkbox } from '../../../Checkbox/Checkbox';
import { Well } from '../../../Well/Well';
import { ZeroState } from '../../../ZeroState';
import { ADDENDA } from '../../../ProposalCreateNav/constants';
import { AddendumsConfirmFormSection } from '../../AddendumsConfirmFormSection';

// @reduxForm
class ConnectedProposalAddendumsConfirm extends Component {
    static propTypes = {
        change: PropTypes.func.isRequired,
        confirmAddendum: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        dirty: PropTypes.bool,
        onSave: PropTypes.func.isRequired,
        proposalFormData: PropTypes.shape({
            addendums: PropTypes.array.isRequired,
        }).isRequired,
        sections: PropTypes.array.isRequired,
        showValidation: PropTypes.bool,
        updateError: PropTypes.string,
    };

    static defaultProps = {
        disabled: false,
        updateError: undefined,
    };

    get styles() {
        return require('./index.scss');
    }

    renderAcknowledgement() {
        const { showValidation } = this.props;

        const text =
            'I agree to update this response to acknowledge any addenda received in the future ' +
            'even if this response has already been submitted.';

        return (
            <div className="row">
                <div className="col-md-10 col-md-offset-1">
                    <Well>
                        <Field
                            component={Checkbox}
                            formGroupClass={this.styles.checkboxForm}
                            inline
                            name={IS_ADDENDUM_CONFIRMED}
                            showValidation={showValidation}
                            text={text}
                        />
                    </Well>
                </div>
            </div>
        );
    }

    render() {
        const {
            change,
            confirmAddendum,
            dirty,
            disabled,
            onSave,
            proposalFormData: { addendums },
            sections,
            showValidation,
            updateError,
        } = this.props;

        return (
            <div>
                {this.renderAcknowledgement()}
                {addendums.length === 0 && <ZeroState title="No addenda have been issued yet" />}
                <AddendumsConfirmFormSection
                    change={change}
                    confirmAddendum={confirmAddendum}
                    disabled={disabled}
                    showValidation={showValidation}
                />
                <ProposalSectionButtons
                    disabled={disabled}
                    isFormDirty={dirty}
                    onSave={onSave}
                    section={ADDENDA}
                    sections={sections}
                    updateError={updateError}
                />
            </div>
        );
    }
}

export const ProposalAddendumsConfirm = reduxForm(formConfig)(ConnectedProposalAddendumsConfirm);
