import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { Button } from '../../../Button';
import { Checkbox } from '../../../Checkbox/Checkbox';
import { InputText } from '../../../InputText';
import { proposalDocumentFieldNames } from './constants';

const { TITLE, IS_REQUIRED } = proposalDocumentFieldNames;

export class ProposalDocumentsItem extends PureComponent {
    static propTypes = {
        index: PropTypes.number.isRequired,
        arrayName: PropTypes.string.isRequired,
        disabled: PropTypes.bool.isRequired,
        showFormErrors: PropTypes.bool.isRequired,
        remove: PropTypes.func.isRequired,
    };

    get styles() {
        return require('./ProposalDocumentsItem.scss');
    }

    handleRemoveClick = () => {
        const { index, remove } = this.props;

        remove(index);
    };

    render() {
        const { arrayName, disabled, showFormErrors } = this.props;

        return (
            <div className="row">
                <div className="col-xs-6 col-sm-7 col-md-8">
                    <Field
                        aria-label="Document Title"
                        component={InputText}
                        disabled={disabled}
                        hasFeedback={false}
                        label="Title"
                        name={`${arrayName}.${TITLE}`}
                        placeholder="Document Title (ex: Pricing Form)"
                        qaTag={`${arrayName}-${TITLE}`}
                        showValidation={showFormErrors}
                        type="text"
                    />
                </div>
                <div className="col-xs-6 col-sm-5 col-md-4" style={{ marginTop: 20 }}>
                    <Field
                        component={Checkbox}
                        disabled={disabled}
                        formGroupClass={this.styles.checkbox}
                        name={`${arrayName}.${IS_REQUIRED}`}
                        qaTag={`${arrayName}-${IS_REQUIRED}`}
                        text="Required?"
                    />
                    <Button
                        aria-label="Remove Button"
                        bsStyle="link"
                        className={this.styles.removeBtn}
                        disabled={disabled}
                        onClick={this.handleRemoveClick}
                        qaTag="proposalDocumentsItem-deleteItem"
                    >
                        <i className="fa fa-times fa-2x text-danger" />
                    </Button>
                </div>
            </div>
        );
    }
}
