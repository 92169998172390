import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { v4 as UUIDv4 } from 'uuid';
import classnames from 'classnames';

import { AttachmentUploaderForm } from './AttachmentUploaderForm';
import { form as attachmentUploaderForm } from './AttachmentUploaderForm/constants';
import { UploadProgress } from '../UploadProgress';
import { StyledDropzone } from '../Dropzone';
import { FileUploadAttachment } from '../FileUploadAttachment';
import { universalUploadFile } from '../../actions/attachments';
import { cleanFileName } from '../../helpers';

const mapDispatchToProps = {
    uploadHandler: universalUploadFile,
};

// @connect
class ConnectedAttachmentUploader extends Component {
    static propTypes = {
        allowSingleAttachmentOnly: PropTypes.bool, // Use if only one attachment should be allowed
        attachments: PropTypes.array, // Optional if attachments should be displayed
        attachmentUploadData: PropTypes.object, // Data to be included with every uploaded file
        deleteHandler: PropTypes.func, // Deletes existing attachments
        disabled: PropTypes.bool,
        dropzoneOptions: PropTypes.object, // Options to pass to `react-dropzone`
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]), // Label to display above uploader
        noLabel: PropTypes.bool,
        onSuccess: PropTypes.func, // What to do with the files after they have been uploaded to S3
        preventParallelUpload: PropTypes.bool, // Use when uploading multiple files at once could cause a race condition
        s3GetUrl: PropTypes.string.isRequired, // Internal API to get the S3 URL from
        skipForm: PropTypes.bool, // Skips the form and starts the upload on drop
        showDates: PropTypes.bool, // Show dates on the created attachments
        uploadHandler: PropTypes.func.isRequired,
        useOpenGovStyle: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            forms: [],
            uploads: [],
        };
    }

    get styles() {
        return require('./index.scss');
    }

    removeFormFile = (uuid) => () => {
        this.setState((prevState) => {
            return {
                forms: prevState.forms.filter((form) => form.uuid !== uuid),
            };
        });
    };

    attachmentFormHandler = (uuid) => (data) => {
        const formData = this.state.forms.find((form) => form.uuid === uuid);

        this.removeFormFile(uuid)();

        let filename;
        if (data.title) {
            filename = formData.extension ? `${data.title}.${formData.extension}` : data.title;
        }

        const fileData = {
            attachmentData: data,
            file: formData.file,
            filename,
        };

        this.uploadHandler([fileData]);
    };

    onDropHandler = (files) => {
        if (!files) {
            return null;
        }

        if (this.props.skipForm) {
            const filesData = files.map((file) => {
                return { file };
            });
            return this.uploadHandler(filesData);
        }

        // Set initial form state
        this.setState((prevState) => {
            return {
                forms: prevState.forms.concat(
                    files.map((file) => {
                        const { name, extension } = cleanFileName(file && file.name);
                        return {
                            extension,
                            file,
                            title: name,
                            uuid: UUIDv4(),
                        };
                    })
                ),
            };
        });
    };

    uploadHandler = (filesData) => {
        if (!filesData) {
            return null;
        }

        const { attachmentUploadData, onSuccess, s3GetUrl, uploadHandler } = this.props;

        filesData.forEach((fileData) => {
            fileData.uuid = UUIDv4();
        });

        // Set initial state for starting upload
        this.setState((prevState) => {
            return {
                uploads: prevState.uploads.concat(
                    filesData.map((fileData) => {
                        return {
                            error: null,
                            filename: fileData.filename || fileData.file.name,
                            progress: 0,
                            uuid: fileData.uuid,
                        };
                    })
                ),
            };
        });

        const onProgress = (uuid) => (progress) => {
            this.setState((prevState) => {
                return {
                    uploads: prevState.uploads.map((upload) => {
                        if (upload.uuid !== uuid) {
                            return upload;
                        }
                        return {
                            ...upload,
                            progress,
                        };
                    }),
                };
            });
        };

        return Promise.all(
            filesData.map((fileData) => {
                const progressHandler = onProgress(fileData.uuid);
                const options = {
                    attachmentData: {
                        ...attachmentUploadData,
                        ...fileData.attachmentData,
                    },
                    filename: fileData.filename,
                    onSuccess,
                };
                return uploadHandler(s3GetUrl, fileData.file, progressHandler, options)
                    .then(() => {
                        progressHandler(100);
                        this.setState((prevState) => {
                            return {
                                uploads: prevState.uploads.filter((upload) => {
                                    return upload.uuid !== fileData.uuid;
                                }),
                            };
                        });
                    })
                    .catch((error) => {
                        this.setState((prevState) => {
                            return {
                                uploads: prevState.uploads.map((upload) => {
                                    if (upload.uuid !== fileData.uuid) {
                                        return upload;
                                    }
                                    return {
                                        ...upload,
                                        error: error.message || 'Unknown error occurred',
                                    };
                                }),
                            };
                        });
                    });
            })
        );
    };

    renderUploadList() {
        const { uploads, useOpenGovStyle } = this.state;

        return uploads.map((upload) => {
            return (
                <UploadProgress
                    className={this.styles.uploadProgressItem}
                    error={upload.error}
                    filename={upload.filename}
                    key={upload.uuid}
                    progress={upload.progress}
                    useOpenGovStyle={useOpenGovStyle}
                />
            );
        });
    }

    renderFormList() {
        const { forms } = this.state;

        return forms.map((formData) => {
            return (
                <AttachmentUploaderForm
                    fileExtension={formData.extension}
                    form={`${attachmentUploaderForm}${formData.uuid}`}
                    initialValues={{ title: formData.title }}
                    key={formData.uuid}
                    onSubmit={this.attachmentFormHandler(formData.uuid)}
                    removeHandler={this.removeFormFile(formData.uuid)}
                />
            );
        });
    }

    renderUploadBlock() {
        const {
            allowSingleAttachmentOnly,
            attachments,
            disabled,
            dropzoneOptions,
            label,
            noLabel,
            preventParallelUpload,
        } = this.props;

        const { uploads } = this.state;

        if (allowSingleAttachmentOnly && (attachments || []).length > 0) {
            return null;
        }

        const multi = !preventParallelUpload && !allowSingleAttachmentOnly;

        // In the non-multiple case we want to make sure not to be loading 2 files at once to
        // prevent race conditions
        const hideUploader = !multi && uploads.length > 0;

        return (
            <div>
                {!hideUploader && (
                    <div
                        className={classnames(
                            'attachment-uploader-component-container',
                            this.styles.dropzoneContainer
                        )}
                    >
                        <StyledDropzone
                            disabled={disabled}
                            label={noLabel ? '' : label || 'Attachment'}
                            multiple={multi}
                            onDrop={this.onDropHandler}
                            {...dropzoneOptions}
                        />
                    </div>
                )}
                {this.renderUploadList()}
                {this.renderFormList()}
            </div>
        );
    }

    renderExistingAttachments() {
        const { attachments, deleteHandler, disabled, showDates, useOpenGovStyle } = this.props;

        return (attachments || []).map((attachment, i) => (
            <FileUploadAttachment
                attachment={attachment}
                deleteHandler={deleteHandler}
                disabled={disabled}
                isLast={i === attachments.length - 1}
                key={attachment.path}
                requireConfirmation
                showDate={showDates}
                useListItemStyle
                useOpenGovStyle={useOpenGovStyle}
            />
        ));
    }

    render() {
        return (
            <div>
                {this.renderExistingAttachments()}
                {this.renderUploadBlock()}
            </div>
        );
    }
}

export const AttachmentUploader = connect(null, mapDispatchToProps)(ConnectedAttachmentUploader);
