import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { projectTypesDict } from '@og-pro/shared-config/projects';
import { FLAGS, useFlags } from '@og-pro/launch-darkly/client';

import { getSDv2WritingSections } from '../../selectors';
import {
    BaseSectionsNavBackNextButtons,
    BaseSectionsNavBackButton,
    BaseSectionsNavNextButton,
} from '../../../../../components/SDv2';
import { projectSectionsToNavSections } from '../../../../../components/SDv2/helpers';
import { ProjectCreateV2NavContext, ProjectCreateV2FunctionsContext } from '../context';

export const ProjectCreateV2DocumentEditorBackNextButtons = () => {
    const projectSections = useSelector(getSDv2WritingSections);
    const { activeSectionId, setActiveSectionId } = useContext(ProjectCreateV2NavContext);
    const { buildRouteFunction, initiateSneakyUpdate, project } = useContext(
        ProjectCreateV2FunctionsContext
    );
    const { type, useManualNumbering } = project;
    const sections = projectSectionsToNavSections({
        projectSections,
        active: activeSectionId,
        setActive: (i) => {
            initiateSneakyUpdate();
            setActiveSectionId(i);
        },
        useManualNumbering,
        hideEmptyDividers: true,
    });
    const contractPackageCompilerEnabled = useFlags(FLAGS.ENABLE_CONTRACT_PACKAGE_COMPILER);

    return (
        <BaseSectionsNavBackNextButtons
            firstSectionComponent={
                <BaseSectionsNavBackButton
                    onClick={() => initiateSneakyUpdate(buildRouteFunction('project-properties'))}
                >
                    {type === projectTypesDict.CONTRACT ? '' : 'Project'} Properties
                </BaseSectionsNavBackButton>
            }
            lastSectionComponent={
                <BaseSectionsNavNextButton
                    onClick={() => initiateSneakyUpdate(buildRouteFunction('attachments'))}
                >
                    {type === projectTypesDict.CONTRACT && contractPackageCompilerEnabled
                        ? 'Exhibits'
                        : 'Attachments'}
                </BaseSectionsNavNextButton>
            }
            sections={sections}
        />
    );
};
