import React from 'react';
import PropTypes from 'prop-types';

import { ContentBlock } from '../../SDv2/ContentBlock';
import { Button } from '../../Button';

const ActionSideBarForTextBlocks = ({ trashAction }) => {
    return (
        <ContentBlock.ActionSidebar>
            <ContentBlock.ButtonGroup>
                <ContentBlock.Button>
                    <Button bsStyle="link" onClick={trashAction}>
                        <i aria-hidden="true" className="fa fa-trash" />
                        <span className="visually-hidden">Trash Icon for text block</span>
                    </Button>
                </ContentBlock.Button>
            </ContentBlock.ButtonGroup>
        </ContentBlock.ActionSidebar>
    );
};

ActionSideBarForTextBlocks.propTypes = {
    trashAction: PropTypes.func,
};

export default ActionSideBarForTextBlocks;
