import { camelCase } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Checkbox } from 'react-bootstrap';
import { connect } from 'react-redux';
import { change as reduxFormChange, Field, formValueSelector } from 'redux-form';

import { contactFieldNames, mappedUserFields } from '@og-pro/shared-config/contacts';

import { CANADA_COUNTRY_CODE, UNITED_STATES_COUNTRY_CODE } from '@og-pro/shared-config/global';

import { InputText } from '../../../InputText';
import { SearchSelect } from '../../../SearchSelect/SearchSelect';
import { zipCodeString } from '../../../../Forms/normalizers';
import {
    canadianProvinceOptions,
    countryCodeOptions,
    statePostalOptions,
} from '../../../../Forms/options';

const { ADDRESS_1, ADDRESS_2, CITY, COUNTRY_CODE, STATE, ZIP_CODE } = contactFieldNames;

const mapStateToProps = (state, props) => {
    const { form } = props;

    const fieldNames = props.fieldNames || mappedUserFields;
    const countryCodeField = fieldNames[COUNTRY_CODE];

    const selector = formValueSelector(form);
    const countryCode = selector(state, countryCodeField);

    return {
        fieldNames,
        isCanadianAddress: countryCode === CANADA_COUNTRY_CODE,
        isInternationalAddress: countryCode && countryCode !== UNITED_STATES_COUNTRY_CODE,
    };
};

const mapDispatchToProps = {
    change: reduxFormChange,
};

// @connect
class ConnectedAddressFormSection extends Component {
    static propTypes = {
        autoFocus: PropTypes.bool,
        change: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        fieldNames: PropTypes.object.isRequired,
        form: PropTypes.string.isRequired,
        fullSentencePlaceholder: PropTypes.bool,
        hasOptionalHelpText: PropTypes.bool,
        hasOptionalStar: PropTypes.bool,
        hasRequiredStar: PropTypes.bool,
        isCanadianAddress: PropTypes.bool,
        isInternationalAddress: PropTypes.bool,
        labelPrefix: PropTypes.string,
        labelPrefixAddress: PropTypes.string,
        optional: PropTypes.bool,
        showErrorFields: PropTypes.bool,
        showRequiredFieldsText: PropTypes.bool,
        showValidation: PropTypes.bool,
    };

    static defaultProps = {
        autoFocus: false,
        disabled: false,
        fullSentencePlaceholder: false,
        hasOptionalHelpText: false,
        hasOptionalStar: false,
        hasRequiredStar: false,
        isCanadianAddress: false,
        isInternationalAddress: false,
        labelPrefixAddress: '',
        optional: false,
        showErrorFields: false,
        showRequiredFieldsText: true,
        showValidation: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            showCountryField: props.isInternationalAddress,
        };
        this.simplePlaceholder = props.optional ? 'Optional' : 'Required';
    }

    get styles() {
        return require('./index.scss');
    }

    get fieldLabels() {
        const { isCanadianAddress, isInternationalAddress, labelPrefix, labelPrefixAddress } =
            this.props;

        const labelPrefixFormatted = labelPrefix ? labelPrefix.concat(' ') : '';
        const internationalState = isCanadianAddress
            ? `${labelPrefixFormatted}Province`
            : `${labelPrefixFormatted}State / Province / Region`;
        const internationalZipCode = isCanadianAddress
            ? `${labelPrefixFormatted}Postal Code`
            : `${labelPrefixFormatted}Zip / Postal Code`;

        const labelPrefixFormattedAddress = labelPrefixAddress
            ? labelPrefixAddress.concat(' ')
            : '';

        return {
            address1Label: `${labelPrefixFormattedAddress ?? labelPrefixFormatted}Address Line 1`,
            address2Label: `${labelPrefixFormattedAddress ?? labelPrefixFormatted}Address Line 2`,
            cityLabel: `${labelPrefixFormatted}City`,
            countryLabel: `${labelPrefixFormatted}Country`,
            stateLabel: isInternationalAddress
                ? internationalState
                : `${labelPrefixFormatted}State`,
            zipCodeLabel: isInternationalAddress
                ? internationalZipCode
                : `${labelPrefixFormatted}Zip Code`,
        };
    }

    toggleInternational = (e) => {
        const { checked } = e.target;

        const { change, fieldNames, form } = this.props;

        if (!checked) {
            change(form, fieldNames[COUNTRY_CODE], UNITED_STATES_COUNTRY_CODE);
        }

        this.setState({ showCountryField: checked });
    };

    formatQaTag = (tag) => {
        const { labelPrefix } = this.props;
        return camelCase(`${labelPrefix || ''} ${tag}`);
    };

    renderStateField() {
        const {
            disabled,
            fieldNames,
            fullSentencePlaceholder,
            hasRequiredStar,
            isCanadianAddress,
            isInternationalAddress,
            labelPrefix,
            optional,
            showValidation,
        } = this.props;

        const { stateLabel } = this.fieldLabels;

        if (isCanadianAddress) {
            return (
                <Field
                    component={SearchSelect}
                    disabled={disabled}
                    isClearable={optional}
                    label={`${stateLabel}${hasRequiredStar ? '*' : ''}`}
                    name={fieldNames[STATE]}
                    options={canadianProvinceOptions}
                    placeholder={
                        fullSentencePlaceholder ? 'Select your province' : this.simplePlaceholder
                    }
                    showValidation={showValidation}
                />
            );
        }

        if (isInternationalAddress) {
            return (
                <Field
                    component={InputText}
                    disabled={disabled}
                    hasFeedback={false}
                    label={`${stateLabel}${hasRequiredStar ? '*' : ''}`}
                    name={fieldNames[STATE]}
                    placeholder={
                        fullSentencePlaceholder ? 'Enter your state' : this.simplePlaceholder
                    }
                    qaTag={`addressForm-international${labelPrefix || ''}State}`}
                    showValidation={showValidation}
                    type="text"
                />
            );
        }

        return (
            <Field
                component={SearchSelect}
                disabled={disabled}
                isClearable={optional}
                label={`${stateLabel}${hasRequiredStar ? '*' : ''}`}
                name={fieldNames[STATE]}
                options={statePostalOptions}
                placeholder={fullSentencePlaceholder ? 'Select your state' : this.simplePlaceholder}
                qaTag={`addressForm-${this.formatQaTag('state')}`}
                showValidation={showValidation}
            />
        );
    }

    render() {
        const {
            autoFocus,
            disabled,
            fieldNames,
            fullSentencePlaceholder,
            hasOptionalHelpText,
            hasOptionalStar,
            hasRequiredStar,
            isInternationalAddress,
            optional,
            showErrorFields,
            showRequiredFieldsText = true,
            showValidation,
        } = this.props;

        const { showCountryField } = this.state;

        const { address1Label, address2Label, cityLabel, countryLabel, zipCodeLabel } =
            this.fieldLabels;

        return (
            <>
                {showRequiredFieldsText && <p>All fields marked with * are required</p>}
                <Field
                    autoFocus={autoFocus}
                    component={InputText}
                    disabled={disabled}
                    hasFeedback={false}
                    label={`${address1Label}${hasRequiredStar ? '*' : ''}`}
                    name={fieldNames[ADDRESS_1]}
                    placeholder={
                        fullSentencePlaceholder
                            ? 'Enter your first address line'
                            : this.simplePlaceholder
                    }
                    qaTag={`addressForm-${this.formatQaTag(ADDRESS_1)}`}
                    showValidation={showValidation}
                    type="text"
                />
                <Field
                    component={InputText}
                    disabled={disabled}
                    hasFeedback={false}
                    help={!optional && hasOptionalHelpText ? '*optional field' : undefined}
                    label={`${address2Label}${!optional && hasOptionalStar ? '*' : ''}`}
                    name={fieldNames[ADDRESS_2]}
                    placeholder={
                        fullSentencePlaceholder
                            ? 'Enter your second address line'
                            : this.simplePlaceholder
                    }
                    qaTag={`addressForm-${this.formatQaTag(ADDRESS_2)}`}
                    showValidation={showValidation}
                    type="text"
                />
                <Field
                    component={InputText}
                    disabled={disabled}
                    hasFeedback={false}
                    label={`${cityLabel}${hasRequiredStar ? '*' : ''}`}
                    name={fieldNames[CITY]}
                    placeholder={
                        fullSentencePlaceholder ? 'Enter your city' : this.simplePlaceholder
                    }
                    qaTag={`addressForm-${this.formatQaTag(CITY)}`}
                    showValidation={showValidation}
                    type="text"
                />
                <div className="row">
                    <div className="col-xs-6">{this.renderStateField()}</div>
                    <div className="col-xs-6">
                        <Field
                            component={InputText}
                            disabled={disabled}
                            hasFeedback={false}
                            label={`${zipCodeLabel}${hasRequiredStar ? '*' : ''}`}
                            name={fieldNames[ZIP_CODE]}
                            normalize={isInternationalAddress ? undefined : zipCodeString}
                            placeholder={
                                fullSentencePlaceholder
                                    ? `Enter your ${zipCodeLabel}`
                                    : this.simplePlaceholder
                            }
                            qaTag={`addressForm-${this.formatQaTag(ZIP_CODE)}`}
                            showValidation={showValidation}
                            type="text"
                        />
                    </div>
                </div>
                {showCountryField && (
                    <Field
                        component={SearchSelect}
                        disabled={disabled}
                        isClearable={optional}
                        label={`${countryLabel}${hasRequiredStar ? '*' : ''}`}
                        name={fieldNames[COUNTRY_CODE]}
                        options={countryCodeOptions}
                        placeholder={
                            fullSentencePlaceholder ? 'Select your country' : this.simplePlaceholder
                        }
                        showValidation={showValidation}
                    />
                )}
                <Checkbox
                    checked={showCountryField}
                    className={this.styles.checkbox}
                    onChange={this.toggleInternational}
                >
                    Outside the United States?
                </Checkbox>
                {showErrorFields && (
                    <div className={`help-block ${this.styles.formError}`} role="alert">
                        Your form has errors. Please fix them and submit again.
                    </div>
                )}
            </>
        );
    }
}

export const AddressFormSection = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedAddressFormSection);
