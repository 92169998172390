import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button } from '../Button';

export class OutlineButton extends PureComponent {
    static propTypes = {
        bsStyle: PropTypes.oneOf(['danger', 'info', 'primary', 'royal', 'success', 'warning'])
            .isRequired,
        className: PropTypes.string,
        disabled: PropTypes.bool,
        invertHover: PropTypes.bool,
    };

    static defaultProps = {
        invertHover: true,
    };

    render() {
        const { bsStyle, className, invertHover, ...props } = this.props;
        const styles = require('./index.scss');

        const computedClassName = classNames(
            styles[bsStyle],
            { [styles.invertHover]: invertHover && !this.props.disabled },
            className
        );

        return <Button className={computedClassName} {...props} />;
    }
}
