import { RESET, RESET_REFRESH_DIFF, RESET_REVISION_DIFF } from './types';

export function resetRevisions() {
    return { type: RESET };
}

export function resetRefreshRevisionHtmlDiff() {
    return { type: RESET_REFRESH_DIFF };
}

export function resetRevisionDiff() {
    return { type: RESET_REVISION_DIFF };
}
