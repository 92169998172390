import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field } from 'redux-form';

import { removeDiscount, removeItem } from '../helpers';
import {
    DECIMAL_PLACES,
    DESCRIPTION_OF_PRICE_ITEM_MAX_LENGTH,
    discountOptions,
    UNIT_OF_MEASURE_MAX_LENGTH,
    UNIT_PRICE_DECIMAL_PLACES,
} from '../constants';
import { fieldNames } from '../../../../constants';
import { requisitionsCreateFormValueSelector } from '../../../../selectors';
import { qaTagPageName } from '../../../../../constants';
import { priceItemFieldNames } from '../../../../../../constants';
import { MaskedInputText } from '../../../../../../../../hocs';
import { Button, Checkbox, InputText, SearchSelect } from '../../../../../../../../components';
import { maskNumberWithCommas, noOp } from '../../../../../../../../Forms/maskers';
import {
    dollarString,
    integerString,
    limitTextLength,
} from '../../../../../../../../Forms/normalizers';
import { currencyFormatter } from '../../../../../../../../helpers';
import { showVendorAssignmentModal } from '../../../../../../../../actions/requisitions';
import { getRequisitionEndsInPurchaseOrder } from '../../../../../../../../selectors/govApp';

const { VENDORS } = fieldNames;
const { DESCRIPTION, DISCOUNT, DISCOUNT_TYPE, QUANTITY, TAXABLE, UNIT_PRICE, UNIT_TO_MEASURE } =
    priceItemFieldNames;

export const LineItemDetails = ({
    change,
    disabled,
    extendedPrice,
    fields,
    fieldName,
    index,
    isApprovalView,
    isDraft,
    showFormValidation,
    setFieldValues,
    showVendorSelection,
    tax,
}) => {
    const dispatch = useDispatch();
    const styles = require('./index.scss');

    const MaskedInput = useCallback(MaskedInputText(InputText), []);

    const isDisabled = !!disabled;
    const isDisabledOutsideOfDraft = isDisabled || !isDraft;

    const vendors = useSelector((state) => requisitionsCreateFormValueSelector(state, VENDORS));
    const priceItem = fields.get(index);
    const { clientVendorAssignmentUuid } = priceItem;
    const vendorIndex = vendors?.findIndex(
        (vendor) => vendor.vendorAssignmentUuid === clientVendorAssignmentUuid
    );

    const endsInPurchaseOrder = useSelector(getRequisitionEndsInPurchaseOrder);

    const [showDiscount, setShowDiscount] = useState(priceItem.discount);

    const renderLineItemHeader = () => (
        <div className={styles.lineItemHeader}>
            <span className={styles.lineItemTitle}>Line Item {index + 1}</span>
            {!isApprovalView && fields.length > 1 && (
                <Button
                    className={classNames(styles.unstyledButton, styles.removeButton)}
                    disabled={isDisabledOutsideOfDraft}
                    onClick={() => removeItem(change, dispatch, fields, index, vendors)}
                    qaTag={`${qaTagPageName}-removeLineItem`}
                >
                    <i className="fa fa-close" />
                    &nbsp;Remove Line Item
                </Button>
            )}
            {isApprovalView && showVendorSelection && vendors.length > 1 && (
                <Button
                    bsStyle="link"
                    disabled={isDisabled}
                    onClick={() =>
                        dispatch(
                            showVendorAssignmentModal({
                                index,
                            })
                        )
                    }
                    qaTag={`${qaTagPageName}-chooseAVendor`}
                >
                    {clientVendorAssignmentUuid
                        ? `Purchasing from Vendor ${vendorIndex + 1}`
                        : 'Choose a Vendor'}
                </Button>
            )}
        </div>
    );

    const normalizeDescription = limitTextLength(DESCRIPTION_OF_PRICE_ITEM_MAX_LENGTH);

    const renderLineItemDetails = () => (
        <div className={styles.mainLine}>
            <div className={styles.description}>
                <Field
                    characterLimit={DESCRIPTION_OF_PRICE_ITEM_MAX_LENGTH}
                    component={InputText}
                    disabled={isDisabled}
                    hasFeedback={false}
                    label="Description *"
                    name={`${fieldName}.${DESCRIPTION}`}
                    normalize={normalizeDescription}
                    qaTag={`requisitionCreate-priceItem-${DESCRIPTION}`}
                    showValidation={showFormValidation}
                    useOpenGovStyle
                />
            </div>
            <div className={styles.quantity}>
                <Field
                    component={MaskedInput}
                    disabled={isDisabledOutsideOfDraft}
                    hasFeedback={false}
                    label="Quantity *"
                    mask={noOp}
                    name={`${fieldName}.${QUANTITY}`}
                    normalizer={integerString}
                    qaTag={`requisitionCreate-priceItem-${QUANTITY}`}
                    showValidation={showFormValidation}
                    type="number"
                    useOpenGovStyle
                />
            </div>
            <div className={styles.unitOfMeasure}>
                <Field
                    component={InputText}
                    disabled={isDisabled}
                    hasFeedback={false}
                    label="Unit of Measure *"
                    name={`${fieldName}.${UNIT_TO_MEASURE}`}
                    normalize={limitTextLength(
                        endsInPurchaseOrder ? UNIT_OF_MEASURE_MAX_LENGTH : 64
                    )}
                    qaTag={`requisitionCreate-priceItem-${UNIT_TO_MEASURE}`}
                    showValidation={showFormValidation}
                    useOpenGovStyle
                />
            </div>
            <div className={styles.unitPrice}>
                <Field
                    component={MaskedInput}
                    disabled={isDisabledOutsideOfDraft}
                    hasFeedback={false}
                    inputGroupPrefix="$"
                    label="Unit Price *"
                    mask={(value) => maskNumberWithCommas(value, UNIT_PRICE_DECIMAL_PLACES)}
                    name={`${fieldName}.${UNIT_PRICE}`}
                    normalizer={(value) =>
                        dollarString(value, {
                            maximumFractionDigits: UNIT_PRICE_DECIMAL_PLACES,
                        })
                    }
                    qaTag={`requisitionCreate-priceItem-${UNIT_PRICE}`}
                    showValidation={showFormValidation}
                    style={{ zIndex: 0 }}
                    useOpenGovStyle
                />
            </div>
            {showDiscount && (
                <div className={styles.discount}>
                    <Field
                        className={styles.discountInput}
                        component={MaskedInput}
                        disabled={isDisabledOutsideOfDraft}
                        hasFeedback={false}
                        label={
                            <>
                                Discount&nbsp;
                                <Button
                                    className={classNames(
                                        styles.subText,
                                        styles.unstyledButton,
                                        styles.removeDiscount
                                    )}
                                    disabled={isDisabledOutsideOfDraft}
                                    onClick={() =>
                                        removeDiscount(index, setFieldValues, setShowDiscount)
                                    }
                                    qaTag={`${qaTagPageName}-removeDiscount`}
                                >
                                    <i className="fa fa-close" />
                                </Button>
                            </>
                        }
                        mask={maskNumberWithCommas}
                        name={`${fieldName}.${DISCOUNT}`}
                        normalizer={(value) =>
                            dollarString(value, {
                                maximumFractionDigits: DECIMAL_PLACES,
                            })
                        }
                        qaTag={`requisitionCreate-priceItem-${DISCOUNT}%`}
                        useOpenGovStyle
                    />
                    <Field
                        component={SearchSelect}
                        components={{
                            IndicatorSeparator: () => null,
                            DropdownIndicator: () => <i className="fa fa-caret-down" />,
                        }}
                        disabled={isDisabledOutsideOfDraft}
                        hasFeedback={false}
                        isSearchable={false}
                        name={`${fieldName}.${DISCOUNT_TYPE}`}
                        onChange={() => {
                            dispatch(change(`${fieldName}.${DISCOUNT}`, null));
                        }}
                        options={discountOptions}
                        qaTag="requisitionCreate-priceItem-discountDropdown"
                        selectClassName={styles.discountTypeSelect}
                    />
                </div>
            )}
            {tax && (
                <div className={styles.tax}>
                    <Field
                        className={styles.checkboxItem}
                        component={Checkbox}
                        disabled={isDisabledOutsideOfDraft}
                        label="Tax"
                        name={`${fieldName}.${TAXABLE}`}
                        qaTag={`requisitionCreate-priceItem-${TAXABLE}`}
                        showValidation={showFormValidation}
                        useOpenGovStyle
                    />
                </div>
            )}
            <div className={styles.total}>
                <div className="form-group">
                    <div
                        className="control-label"
                        id="Extended Price"
                        style={{ whiteSpace: 'nowrap', marginTop: '2px' }}
                    >
                        Total
                    </div>
                    <div className={styles.budgetCheck}>
                        {currencyFormatter(
                            { value: extendedPrice },
                            { maximumFractionDigits: DECIMAL_PLACES }
                        )}
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div className={styles.lineItemDetails}>
            {renderLineItemHeader()}
            {renderLineItemDetails()}
            {!showDiscount && (
                <Button
                    className={classNames(styles.unstyledButton, styles.addButton)}
                    disabled={isDisabledOutsideOfDraft}
                    onClick={() => setShowDiscount(true)}
                    qaTag={`${qaTagPageName}-addDiscount`}
                >
                    <i className="fa fa-plus" /> Add Discount
                </Button>
            )}
        </div>
    );
};

LineItemDetails.propTypes = {
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    extendedPrice: PropTypes.number,
    fields: PropTypes.object.isRequired,
    fieldName: PropTypes.string.isRequired,
    isApprovalView: PropTypes.bool,
    isDraft: PropTypes.bool,
    index: PropTypes.number.isRequired,
    showFormValidation: PropTypes.bool,
    showVendorSelection: PropTypes.bool,
    setFieldValues: PropTypes.func.isRequired,
    tax: PropTypes.number,
};
