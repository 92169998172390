import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Button } from '../../Button';

export class ConfirmationButton extends PureComponent {
    static propTypes = {
        updating: PropTypes.bool.isRequired,
        disabled: PropTypes.bool,
        updatingText: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        icon: PropTypes.string,
        bsStyle: PropTypes.string,
        onClick: PropTypes.func.isRequired,
    };

    static defaultProps = {
        bsStyle: 'success',
    };

    render() {
        const { updating, updatingText, text, icon, onClick, disabled, bsStyle } = this.props;
        const btnText = updating ? updatingText : text;
        const iconType = updating ? 'fa-spin fa-spinner' : icon;
        return (
            <Button bsSize="lg" bsStyle={bsStyle} disabled={updating || disabled} onClick={onClick}>
                {icon && <i className={`fa fa-lg ${iconType}`} />} {btnText}
            </Button>
        );
    }
}
