import { Backspace as BackspaceIcon } from '@mui/icons-material';
import { Box, Button, Typography } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { InputText, RichTextInput } from '../../../../../../../../components';

export const AiImportSubsectionList = ({ disabled, fields, isTextArea, showValidation, uuid }) => {
    return (
        <Box>
            {fields.map((member, index) => {
                return (
                    <Box className="row" key={member} sx={{ paddingTop: 2 }}>
                        <Box className="col-xs-1 text-right" sx={{ paddingTop: 1 }}>
                            <Typography sx={{ marginBottom: 2 }}>
                                {isTextArea ? 'Content' : `Item ${index + 1}`}
                            </Typography>
                            {fields.length > 1 && (
                                <Button
                                    color="error"
                                    onClick={() => fields.remove(index)}
                                    qaTag={`aiImporter-item${index}Remove`}
                                    startIcon={<BackspaceIcon />}
                                    sx={{ padding: 0 }}
                                    variant="text"
                                >
                                    Remove
                                </Button>
                            )}
                        </Box>
                        <Box className="col-xs-11">
                            {!isTextArea && (
                                <Field
                                    component={InputText}
                                    disabled={disabled}
                                    hasFeedback={false}
                                    name={`${member}.title`}
                                    qaTag={`aiImporter-item${index}Title`}
                                    showValidation={showValidation}
                                    type="text"
                                    useOpenGovStyle
                                />
                            )}
                            <Field
                                component={RichTextInput}
                                disabled={disabled}
                                hasFeedback={false}
                                id={`${uuid}-${member}-description`}
                                name={`${member}.description`}
                                qaTag={`aiImporter-item${index}Description`}
                                showValidation={showValidation}
                                useOpenGovStyle
                            />
                        </Box>
                    </Box>
                );
            })}
        </Box>
    );
};

AiImportSubsectionList.propTypes = {
    disabled: PropTypes.bool.isRequired,
    fields: PropTypes.object.isRequired,
    isTextArea: PropTypes.bool.isRequired,
    showValidation: PropTypes.bool,
    uuid: PropTypes.string.isRequired,
};
