import PropTypes from 'prop-types';
import classnames from 'classnames';
import React, { PureComponent } from 'react';
import { Panel } from 'react-bootstrap';

import { Nav } from '../../Nav';
import { NavItem } from '../../Nav/NavItem';
import { ProjectMenuDropdown } from '../../ProjectMenuDropdown';
import { ProjectProfile } from '../../ProjectProfile';
import { SubscribeButton } from '../../SubscribeButton/SubscribeButton';

export class ProjectNavbar extends PureComponent {
    static propTypes = {
        actionHandler: PropTypes.func.isRequired,
        children: PropTypes.node.isRequired,
        dashboardPath: PropTypes.string,
        isBuilder: PropTypes.bool,
        isEditor: PropTypes.bool.isRequired,
        isGlobalEditor: PropTypes.bool.isRequired,
        isOwner: PropTypes.bool.isRequired,
        isIntake: PropTypes.bool,
        isSourcing: PropTypes.bool,
        navItems: PropTypes.arrayOf(
            PropTypes.shape({
                active: PropTypes.bool,
                className: PropTypes.string,
                indexRoute: PropTypes.bool,
                link: PropTypes.string.isRequired,
                title: PropTypes.string.isRequired,
            })
        ).isRequired,
        project: PropTypes.object.isRequired,
        publicUrl: PropTypes.string,
        showComments: PropTypes.bool,
        showShareButtons: PropTypes.bool,
        subscribed: PropTypes.bool.isRequired,
        timezone: PropTypes.string.isRequired,
    };

    get styles() {
        return require('./ProjectNavbar.scss');
    }

    renderProjectProfile() {
        const {
            actionHandler,
            dashboardPath,
            isBuilder,
            isEditor,
            isGlobalEditor,
            isOwner,
            isIntake,
            isSourcing,
            project,
            publicUrl,
            showComments,
            showShareButtons,
            subscribed,
            timezone,
        } = this.props;

        const status = project.status;

        const baseProjectProfileProps = {
            imageUrl: project.government.organization.logo,
            project,
            status,
            timezone,
            title: project.title,
        };

        if (project.isLibrary) {
            return <ProjectProfile {...baseProjectProfileProps} />;
        }

        const subscribeButton = <SubscribeButton isGovernment project={project} />;

        const menuComponent = (
            <ProjectMenuDropdown
                actionHandler={actionHandler}
                bsSize="sm"
                isBuilder={isBuilder}
                isGlobalEditor={isGlobalEditor}
                isIntake={isIntake}
                isLibrary={project.isLibrary}
                isOwner={isOwner}
                isSourcing={isSourcing}
                project={project}
                showComments={showComments}
                subscribed={subscribed}
            />
        );

        return (
            <ProjectProfile
                {...baseProjectProfileProps}
                dashboardPath={dashboardPath}
                isEditor={isEditor}
                menuComponent={menuComponent}
                publicUrl={publicUrl}
                showBidDetails={project.hasSealedBid}
                showShareButtons={showShareButtons}
                subscribeButton={subscribeButton}
                subscribed={subscribed}
            />
        );
    }

    render() {
        const { children, navItems } = this.props;

        const NavigationItems = navItems.map((item) => (
            <NavItem
                className={classnames(item.className, { active: item.active })}
                key={item.title}
                onlyActiveOnIndex={item.indexRoute}
                qaTag={`project-${item.title}`}
                to={item.link}
            >
                {item.title}
            </NavItem>
        ));

        return (
            <div>
                {this.renderProjectProfile()}
                <Panel className="print-panel">
                    <Panel.Body>
                        <Nav
                            bsStyle="tabs"
                            className={`${this.styles.projectNav} no-print`}
                            justified
                        >
                            {NavigationItems}
                        </Nav>
                        <div className={this.styles.childContainer}>{children}</div>
                    </Panel.Body>
                </Panel>
            </div>
        );
    }
}
