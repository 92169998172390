import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { subsectionTypeNames } from '@og-pro/shared-config/subsections';

import { ProjectDetailTitle } from '../Title';
import { SectionIntro } from '../SectionIntro';
import { QuestionnaireDisplayList } from '../../../Questionnaire/QuestionnaireDisplayList';

const { BODY } = subsectionTypeNames;

export class QuestionnaireList extends PureComponent {
    static propTypes = {
        description: PropTypes.shape({
            [BODY]: PropTypes.object,
        }).isRequired,
        isDocx: PropTypes.bool,
        isEditable: PropTypes.bool,
        isRevisionsDiff: PropTypes.bool,
        projectSection: PropTypes.object.isRequired,
        projectSubsectionsMap: PropTypes.object.isRequired,
        questionnaires: PropTypes.array.isRequired,
        sectionDescActions: PropTypes.object,
        showComments: PropTypes.bool,
        tagOptions: PropTypes.array,
        templateVariableOptions: PropTypes.array,
        title: PropTypes.string,
        useManualNumbering: PropTypes.bool,
        useSectionDividers: PropTypes.bool,
    };

    static defaultProps = {
        isDocx: false,
        isEditable: false,
        sectionDescActions: undefined,
        showComments: false,
        tagOptions: undefined,
        templateVariableOptions: undefined,
        title: undefined,
    };

    render() {
        const {
            description,
            isDocx,
            isEditable,
            isRevisionsDiff,
            projectSection,
            projectSubsectionsMap,
            questionnaires,
            sectionDescActions,
            showComments,
            tagOptions,
            templateVariableOptions,
            title,
            useManualNumbering,
            useSectionDividers,
        } = this.props;

        const projectSubsectionId = projectSubsectionsMap[BODY].id;

        return (
            <div className="col-xs-12 col-md-offset-1 col-md-10 questionnaire-section">
                <ProjectDetailTitle
                    isDocx={isDocx}
                    projectSection={projectSection}
                    projectSubsectionId={projectSubsectionId}
                    showComments={showComments}
                    title={title}
                    useManualNumbering={useManualNumbering}
                    useSectionDividers={useSectionDividers}
                />
                <SectionIntro
                    {...sectionDescActions}
                    isDocx={isDocx}
                    isEditable={isEditable}
                    projectSectionId={projectSection.id}
                    projectSubsectionId={projectSubsectionId}
                    sectionDescription={description[projectSubsectionId]}
                    tagOptions={tagOptions}
                    templateVariableOptions={templateVariableOptions}
                />
                <div style={{ paddingLeft: isDocx ? 0 : 15 }}>
                    <QuestionnaireDisplayList
                        isDocx={isDocx}
                        isRevisionsDiff={isRevisionsDiff}
                        questionnaires={questionnaires}
                        useManualNumbering={useManualNumbering}
                        useSectionDividers={useSectionDividers}
                    />
                </div>
            </div>
        );
    }
}
