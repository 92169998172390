import { debounce } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, FieldArray, formValueSelector } from 'redux-form';
import { v4 as UUIDv4 } from 'uuid';
import { Box } from '@og-pro/ui';

import {
    durationTypes,
    durationTypesDict,
    termUnits,
    termUnitsDict,
} from '@og-pro/shared-config/contracts';

import { durationSelectOptions } from './constants';
import { fieldNames, form } from '../constants';
import { Button } from '../../../Button';
import { DateTimePicker } from '../../../DateTime/DateTimePicker';
import { Label } from '../../../InputText/Label';
import { NumberWidget } from '../../../NumberWidget/NumberWidget';
import { OutlineButton } from '../../../OutlineButton';
import { SearchSelect } from '../../../SearchSelect/SearchSelect';
import { Well } from '../../../Well/Well';
import { showConfirmationSimpleModal } from '../../../../actions/confirmation';

const {
    CONTRACT_RENEWALS,
    DURATION_TYPE,
    END_DATE,
    INITIAL_TERM_LENGTH,
    INITIAL_TERM_LENGTH_UNIT,
    START_DATE,
} = fieldNames;

const { TERM_BASED } = durationTypesDict;

const { YEARS } = termUnitsDict;

const termUnitOptions = termUnits.map((termUnit) => {
    return {
        label: termUnit,
        value: termUnit,
    };
});

const selector = formValueSelector(form);

const mapStateToProps = (state) => {
    return {
        [DURATION_TYPE]: selector(state, DURATION_TYPE),
        [INITIAL_TERM_LENGTH]: selector(state, INITIAL_TERM_LENGTH),
        [INITIAL_TERM_LENGTH_UNIT]: selector(state, INITIAL_TERM_LENGTH_UNIT),
        [START_DATE]: selector(state, START_DATE),
    };
};

const mapDispatchToProps = {
    showConfirmationSimpleModal,
};

// @connect
class ConnectedContractTermAndRenewals extends Component {
    static propTypes = {
        change: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        [DURATION_TYPE]: PropTypes.oneOf(durationTypes),
        [INITIAL_TERM_LENGTH]: PropTypes.number,
        [INITIAL_TERM_LENGTH_UNIT]: PropTypes.oneOf(termUnits),
        showConfirmationSimpleModal: PropTypes.func.isRequired,
        [START_DATE]: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
        timezone: PropTypes.string.isRequired,
    };

    static defaultProps = {
        disabled: false,
        [DURATION_TYPE]: undefined,
        [INITIAL_TERM_LENGTH]: undefined,
        [INITIAL_TERM_LENGTH_UNIT]: undefined,
        [START_DATE]: undefined,
    };

    constructor(props) {
        super(props);

        this.debouncedHandleInitialTermLengthChange = debounce(
            this.handleInitialTermLengthChange,
            750
        );
    }

    get styles() {
        return require('./index.scss');
    }

    addNewRenewal = (fields) => {
        fields.push({
            length: 1,
            lengthUnit: YEARS,
            localCreatingUUID: UUIDv4(),
            selected: false,
        });
    };

    handleDurationTypeChange = (event, newValue, oldValue) => {
        const { change } = this.props;

        if (newValue === oldValue) {
            return;
        }

        if (newValue !== TERM_BASED) {
            // Ensure these are nulled out when we save the form if they aren't needed
            change(INITIAL_TERM_LENGTH, null);
            change(INITIAL_TERM_LENGTH_UNIT, null);
            change(CONTRACT_RENEWALS, []);
        }
    };

    handleInitialTermLengthChange = (event, newValue, oldValue) => {
        const {
            change,
            [INITIAL_TERM_LENGTH_UNIT]: initialTermLengthUnit,
            [START_DATE]: startDate,
        } = this.props;

        if (newValue === oldValue) {
            return;
        }

        if (initialTermLengthUnit) {
            this.props.showConfirmationSimpleModal(
                () => {
                    const newEndDate = moment(startDate)
                        .add(newValue, initialTermLengthUnit)
                        .subtract(1, 'day')
                        .toDate();
                    change(END_DATE, newEndDate);
                },
                {
                    bsStyle: 'primary',
                    btnText: 'Update End Date',
                    cancelText: 'No Thanks',
                    icon: 'calendar',
                    iconSize: '',
                    text: 'Would you like to update the contract end date based on your change?',
                    title: 'Update End Date?',
                }
            );
        }
    };

    handleInitialTermLengthUnitChange = (event, newValue, oldValue) => {
        const {
            change,
            [INITIAL_TERM_LENGTH]: initialTermLength,
            [START_DATE]: startDate,
        } = this.props;

        if (newValue === oldValue) {
            return;
        }

        if (initialTermLength) {
            this.props.showConfirmationSimpleModal(
                () => {
                    const newEndDate = moment(startDate)
                        .add(initialTermLength, newValue)
                        .subtract(1, 'day')
                        .toDate();
                    change(END_DATE, newEndDate);
                },
                {
                    bsStyle: 'primary',
                    btnText: 'Update End Date',
                    cancelText: 'No Thanks',
                    icon: 'calendar',
                    iconSize: '',
                    text: 'Would you like to update the contract end date based on your change?',
                    title: 'Update End Date?',
                }
            );
        }
    };

    handleStartDateChange = (event, newValue, oldValue) => {
        const {
            change,
            [DURATION_TYPE]: durationType,
            [INITIAL_TERM_LENGTH]: initialTermLength,
            [INITIAL_TERM_LENGTH_UNIT]: initialTermLengthUnit,
        } = this.props;

        if (newValue === oldValue || durationType !== TERM_BASED) {
            return;
        }

        if (initialTermLength && initialTermLengthUnit) {
            this.props.showConfirmationSimpleModal(
                () => {
                    const newEndDate = moment(newValue)
                        .add(initialTermLength, initialTermLengthUnit)
                        .subtract(1, 'day')
                        .toDate();

                    change(END_DATE, newEndDate);
                },
                {
                    bsStyle: 'primary',
                    btnText: 'Update End Date',
                    cancelText: 'No Thanks',
                    icon: 'calendar',
                    iconSize: '',
                    text: 'Would you like to update the contract end date based on your change?',
                    title: 'Update End Date?',
                }
            );
        }
    };

    renderRenewals = ({ fields }) => {
        const { disabled } = this.props;

        return (
            <>
                <div className="row">
                    <div className="col-sm-4">
                        <Label label="Renewal Options" />
                    </div>
                    <div className="col-sm-8 text-right">
                        <OutlineButton
                            bsSize="xs"
                            bsStyle="primary"
                            disabled={disabled}
                            onClick={() => this.addNewRenewal(fields)}
                        >
                            <i className="fa fa-plus" />
                            &nbsp;Renewal Option
                        </OutlineButton>
                    </div>
                </div>
                <div className={this.styles.renewalsContainer}>
                    {fields.length === 0 && (
                        <div className={this.styles.noRenewalsText}>
                            No renewals have been added yet.
                        </div>
                    )}
                    {fields.map((fieldName, index) => {
                        const renewalData = fields.get(index);

                        return (
                            <Box
                                display="flex"
                                key={renewalData.id || renewalData.localCreatingUUID}
                                mb={2}
                            >
                                <Box ml={3} mr={2} pt={4} textAlign="right">
                                    Option {index + 1}:
                                </Box>
                                <Box flex={1} mr={1}>
                                    <Field
                                        component={NumberWidget}
                                        disabled={disabled}
                                        label="Length"
                                        min={1}
                                        name={`${fieldName}.length`}
                                        placeholder="Length"
                                    />
                                </Box>
                                <Box flex={1.5} mr={1}>
                                    <Field
                                        component={SearchSelect}
                                        disabled={disabled}
                                        label="Unit"
                                        name={`${fieldName}.lengthUnit`}
                                        options={termUnitOptions}
                                        placeholder="Unit"
                                    />
                                </Box>
                                {!disabled && !renewalData.selected && (
                                    <Box flex={1} pt={3}>
                                        <Button
                                            aria-label="Remove Button"
                                            bsStyle="link"
                                            disabled={disabled}
                                            onClick={() => fields.remove(index)}
                                            qaTag="contractTermAndRenewals-remove"
                                        >
                                            <i className="fa fa-times fa-lg text-danger" />
                                        </Button>
                                    </Box>
                                )}
                            </Box>
                        );
                    })}
                </div>
            </>
        );
    };

    render() {
        const {
            disabled,
            [DURATION_TYPE]: durationType,
            [START_DATE]: startDate,
            timezone,
        } = this.props;

        const termBased = durationType === TERM_BASED;

        return (
            <Well>
                <div className="row">
                    <div className="col-xs-12">
                        <Field
                            component={DateTimePicker}
                            disabled={disabled}
                            horizontal
                            label="Start Date"
                            name={START_DATE}
                            onChange={this.handleStartDateChange}
                            timezone={timezone}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <Field
                            backspaceRemovesValue={false}
                            component={SearchSelect}
                            disabled={disabled}
                            label="What triggers the end of this contract?"
                            name={DURATION_TYPE}
                            onChange={this.handleDurationTypeChange}
                            options={durationSelectOptions}
                            placeholder="Select Type..."
                        />
                    </div>
                </div>
                {termBased && (
                    <>
                        {!startDate && (
                            <div className="text-danger">
                                Start date must be selected before setting initial term
                            </div>
                        )}
                        <div className="row">
                            <div className={`col-sm-3 ${this.styles.initialTermLengthColumn}`}>
                                <Field
                                    component={NumberWidget}
                                    disabled={disabled || !startDate}
                                    label="Initial Term"
                                    min={1}
                                    name={INITIAL_TERM_LENGTH}
                                    onChange={this.debouncedHandleInitialTermLengthChange}
                                    placeholder="Term"
                                />
                            </div>
                            <div className={`col-sm-3 ${this.styles.initialTermLengthUnitColumn}`}>
                                <Field
                                    component={SearchSelect}
                                    disabled={disabled || !startDate}
                                    label="Unit"
                                    name={INITIAL_TERM_LENGTH_UNIT}
                                    onChange={this.handleInitialTermLengthUnitChange}
                                    options={termUnitOptions}
                                    placeholder="Unit"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <FieldArray
                                    component={this.renderRenewals}
                                    name={CONTRACT_RENEWALS}
                                />
                            </div>
                        </div>
                    </>
                )}
                <div className="row">
                    <div className="col-xs-12">
                        <Field
                            allowEmpty
                            component={DateTimePicker}
                            disabled={disabled}
                            horizontal
                            label="End Date"
                            name={END_DATE}
                            timezone={timezone}
                        />
                    </div>
                </div>
            </Well>
        );
    }
}

export const ContractTermAndRenewals = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedContractTermAndRenewals);
