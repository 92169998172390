import { Box, ErrorBanner, Grid, NoSsr, Typography } from '@og-pro/ui';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getFormSyncErrors, getFormValues } from 'redux-form';

import { createMockRequisition } from '@og-pro/shared-config/requisitions/mocks';

import { statusTypes } from '@og-pro/shared-config/requestTypes';

import { DEFAULT_SECTION, fieldNames, form, sectionNames } from './constants';
import { SettingsStickySidebar } from './SettingsStickySidebar';
import { useRequestTypeActions } from '../RequestTypeActionsContext';
import { bodyContainer, formContainer } from '../styles';
import { getRequestType } from '../../../../../selectors/govApp';
import { RequestTypeFormDisplay } from './RequestTypeFormDisplay';
import { loadMockRequisition } from '../../../../../actions/requisitions';

const {
    PURCHASE_DETAILS_MODE,
    CREATE_NEW_CUSTOM_FORM,
    DELETE_CUSTOM_FORM,
    CUSTOM_FORM_ID,
    SECTION_NAME_ADDITIONAL,
    SECTION_NAME_ATTACHMENT,
    SECTION_NAME_CUSTOM_FORM,
    SECTION_NAME_GENERAL,
    SECTION_NAME_PURCHASE,
    SECTION_NAME_VENDOR,
} = fieldNames;

export const RequestTypeForm = () => {
    const requestType = useSelector(getRequestType);
    const requestTypeFormValues = useSelector(getFormValues(form));
    const showCustomForm = useMemo(() => {
        if (requestTypeFormValues[DELETE_CUSTOM_FORM]) {
            return false;
        }

        if (requestTypeFormValues[CREATE_NEW_CUSTOM_FORM]) {
            return true;
        }

        return !!requestTypeFormValues[CUSTOM_FORM_ID];
    }, [
        requestTypeFormValues[CUSTOM_FORM_ID],
        requestTypeFormValues[CREATE_NEW_CUSTOM_FORM],
        requestTypeFormValues[DELETE_CUSTOM_FORM],
    ]);
    const purchaseDetailsModeFormValue = requestTypeFormValues[PURCHASE_DETAILS_MODE];
    const { disabled, formErrorsCount, showFormValidation, selectedSection, setSelectedSection } =
        useRequestTypeActions();

    const dispatch = useDispatch();
    const formErrors = useSelector((state) => getFormSyncErrors(form)(state));

    useEffect(() => {
        dispatch(loadMockRequisition({ requestType: { ...requestTypeFormValues } }));
    }, [requestTypeFormValues]);

    const showErrorBanner = showFormValidation && formErrorsCount > 0;
    const isPublished = requestType.status === statusTypes.PUBLISHED;

    const scrollToSection = (section) => {
        const element = document.getElementById(section);
        if (element) {
            const elementPosition = element.getBoundingClientRect().top + window.scrollY;

            window.scrollTo({
                top: elementPosition,
                behavior: 'smooth',
            });
        }
    };

    const jumpToErrorFn = () => {
        const firstErrorKey = Object.keys(formErrors)[0];
        // This can be hard-coded because section nams are the only fields with validation.
        // In the future, a mapping of request type field name to section would make this more extensible.

        let sectionName;
        switch (firstErrorKey) {
            case SECTION_NAME_ADDITIONAL:
                sectionName = sectionNames.ADDITIONAL_INFORMATION;
                break;
            case SECTION_NAME_ATTACHMENT:
                sectionName = sectionNames.ATTACHMENTS;
                break;
            case SECTION_NAME_CUSTOM_FORM:
                sectionName = sectionNames.CUSTOM_FORM;
                break;
            case SECTION_NAME_GENERAL:
                sectionName = sectionNames.GENERAL_INFORMATION;
                break;
            case SECTION_NAME_PURCHASE:
                sectionName = sectionNames.PURCHASE_DETAILS;
                break;
            case SECTION_NAME_VENDOR:
                sectionName = sectionNames.VENDOR_SELECTION;
                break;
            default:
                sectionName = null;
        }

        if (sectionName !== null) {
            setSelectedSection(sectionName);
            scrollToSection(sectionName);
        }
    };

    return (
        <NoSsr>
            <Box sx={{ ...bodyContainer, paddingY: 2 }}>
                <Typography variant="h2">{requestType.name || 'Untitled'} Form</Typography>
            </Box>
            <Box sx={formContainer}>
                <Grid container spacing={3}>
                    {showErrorBanner && (
                        <Grid item xs={12}>
                            <ErrorBanner
                                errorCount={formErrorsCount}
                                jumpToErrorFn={jumpToErrorFn}
                                title={
                                    isPublished
                                        ? 'This Request Type Cannot Be Saved Yet'
                                        : 'This Request Type Cannot Be Published Yet'
                                }
                            />
                        </Grid>
                    )}
                    <Grid item md={8} xs={12}>
                        <RequestTypeFormDisplay
                            customFormId={requestType.customFormId}
                            initialValues={createMockRequisition({
                                requestType: { ...requestTypeFormValues },
                                purchaseDetailsMode: purchaseDetailsModeFormValue,
                            })}
                            showCustomForm={showCustomForm}
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <SettingsStickySidebar
                            disabled={disabled}
                            onClose={() => setSelectedSection(DEFAULT_SECTION)}
                            section={selectedSection}
                            showCustomForm={showCustomForm}
                        />
                    </Grid>
                </Grid>
            </Box>
        </NoSsr>
    );
};
