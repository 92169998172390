import { get } from 'lodash';

import {
    DESCRIPTION,
    LINE_ITEM,
    QUANTITY,
    UNIT_PRICE,
    UNIT_TO_MEASURE,
} from '@og-pro/shared-config/priceTables';

import { percentFormatter } from '../../../../../helpers';
import { numberParser } from '../../../../../components/AgGridReactLegacy/utils';

/**
 * Gets styles for a cell within a PriceTableBuilder component.
 *
 * @param {object} options The set of options to config cell style
 * @param {object} params The callback params from underlying AgGridReact
 * @param {object} params.data The data of the node the cell represents
 * @param {boolean} [params.data.isSummaryRow] If the row is the totals row
 * @param {object} params.node The node representing the cell
 * @param {boolean} params.node.rowPinned If the row is pinned to the grid or not
 * @return {object} The style object for the cell
 */
export const generateCellStyle = (options, params) => {
    const { hasQuantity, omitLineItem, specifyQuantity, showValidation } = options;

    const styles = {
        backgroundColor: 'transparent',
        lineHeight: '18px',
        paddingBottom: '4px',
        paddingTop: '4px',
        whiteSpace: 'pre-wrap',
    };

    const emptyValue = params.value === null || params.value === undefined || params.value === '';

    const requiredCol =
        params.data.isHeaderRow ||
        (params.colDef.field === LINE_ITEM && !omitLineItem) ||
        params.colDef.field === DESCRIPTION ||
        params.colDef.field === UNIT_TO_MEASURE ||
        (params.colDef.field === QUANTITY && hasQuantity && specifyQuantity);

    const { editable } = params.colDef;
    const isEditable = typeof editable === 'function' ? editable(params) : editable;

    if (isEditable && !params.data.isSummaryRow && emptyValue && requiredCol) {
        styles.backgroundColor = showValidation ? '#f2dede' : '#FDFDD8';
    } else if (params.data.isHeaderRow) {
        styles.fontWeight = 'bold';
        styles.backgroundColor = '#eee';
    } else if (!isEditable && !params.data.isSummaryRow) {
        styles.backgroundColor = '#edefed';
        styles.opacity = '0.65';
        styles.cursor = 'not-allowed';
        styles.background =
            'repeating-linear-gradient(-45deg, #edefed, #edefed 5px, #dce0e0 5px, #dce0e0 10px)';
    }

    if (params.node.rowPinned && params.node.data.description === 'Total') {
        styles.fontWeight = 'bold';
    }

    if (params.colDef.field === DESCRIPTION) {
        // Add support for multi-line descriptions
        styles.whiteSpace = 'pre-wrap';
        styles.lineHeight = '1.5em';
    }

    return styles;
};

/**
 * Get price items from the price table in the correct format for AgGridReact.
 *
 * @param {object} priceItems List of price items associated with a price table
 * @return {PriceItem[]} The price items in the proper format
 */
export const getFormattedPriceTableDataForGrid = (priceItems) => {
    return priceItems.map((priceItem) => {
        return {
            ...priceItem,
            id: priceItem.id || priceItem.newPriceItemUuid,
        };
    });
};

/**
 * Given a row of data from the grid, get a unique ID for it.
 *
 * @param {object} data The raw data in this row from `getFormattedPriceTableDataForGrid`
 * @return {number} The unique id of the row in the price table
 */
export const getRowNodeId = (data) => {
    if (data.isSummaryRow) {
        return 'summaryRow';
    }

    return data.id;
};

// Prevents strings from being pasted into cells
export const processCellFromClipboard = (params) => {
    const { cellEditorParams, field } = params.column.colDef;

    // Do not allow pasting into summary row
    if (params.node.data.isSummaryRow) {
        return null;
    }

    if (field === QUANTITY || field === UNIT_PRICE) {
        return numberParser(params, 'value');
    }

    // Prevent pasted value from exceeding max length if specified
    const maxLength = get(cellEditorParams, 'maxLength');

    if (maxLength && typeof params.value === 'string' && params.value.length > maxLength) {
        return params.value.slice(0, maxLength);
    }

    return params.value;
};

export const unitPricePercentageFormatter = (data) => percentFormatter(data);
