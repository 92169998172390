const { listToDict } = require('@og-pro/shared-config/helpers');

export const fieldNames = listToDict([
    'aiEnabled',
    'questionnaireConditionalEnabled',
    'hidePublicClosedProjectsLength',
    'hidePublicClosedProjectsUnit',
    'internallyPublicContracts',
    'usesContractEvaluationReminders',
]);
export const form = 'adminSettingsForm';
