import { reduce } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import { connect } from 'react-redux';

import { getAttachmentSections } from './selectors';
import { Button } from '../../Button';
import { SectionTitle } from '../../SectionTitle/SectionTitle';
import { downloadAttachments } from '../../../actions/publicProject';
import { isSubscribedToProject } from '../../../containers/selectors';

const mapStateToProps = (state, props) => {
    return {
        attachmentSections: getAttachmentSections(props),
        isSubscribed: isSubscribedToProject(state),
    };
};

const mapDispatchToProps = {
    downloadAttachments,
};

// @connect
class ConnectedDownloadsList extends Component {
    static propTypes = {
        attachmentSections: PropTypes.array.isRequired,
        downloadAttachments: PropTypes.func.isRequired,
        isSubscribed: PropTypes.bool,
        project: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = { attachmentIds: {} };
    }

    get styles() {
        return require('./DownloadsList.scss');
    }

    get selectedAttachments() {
        const { attachmentIds } = this.state;
        return reduce(
            attachmentIds,
            (list, attachment) => {
                if (attachment) {
                    list.push(attachment);
                }
                return list;
            },
            []
        );
    }

    selectAttachment = (attachment) => {
        this.setState((prevState) => {
            const { attachmentIds } = prevState;
            if (attachmentIds[attachment.id]) {
                return {
                    attachmentIds: reduce(
                        attachmentIds,
                        (obj, attachmentItem) => {
                            if (attachment.id !== attachmentItem.id) {
                                obj[attachmentItem.id] = attachmentItem;
                            }
                            return obj;
                        },
                        {}
                    ),
                };
            }
            return {
                attachmentIds: {
                    ...attachmentIds,
                    [attachment.id]: attachment,
                },
            };
        });
    };

    selectAll = () => {
        const { attachmentSections } = this.props;

        this.setState({
            attachmentIds: attachmentSections.reduce((obj, attachmentSection) => {
                attachmentSection.attachments.forEach((attachment) => {
                    obj[attachment.id] = attachment;
                });
                return obj;
            }, {}),
        });
    };

    download = () => {
        const { isSubscribed, project } = this.props;

        return this.props.downloadAttachments(project, isSubscribed, this.selectedAttachments);
    };

    renderDownloadsList() {
        const { attachmentSections } = this.props;

        if (attachmentSections.length === 0) {
            return (
                <div className="text-center text-muted">
                    <h4>
                        <em>No documents to download</em>
                    </h4>
                </div>
            );
        }

        const AttachmentSections = attachmentSections.map((section, idx) => (
            <div className="row" key={idx}>
                <div className="col-sm-offset-2 col-sm-8">
                    <ListGroup>{this.renderSectionStatus(section)}</ListGroup>
                </div>
            </div>
        ));

        return (
            <div>
                <div className="text-center">
                    <Button bsStyle="link" onClick={this.selectAll}>
                        Select All
                    </Button>
                </div>
                {AttachmentSections}
                <div className="text-center">
                    <Button
                        bsSize="lg"
                        bsStyle="success"
                        disabled={this.selectedAttachments.length === 0}
                        onClick={this.download}
                    >
                        <i className="fa fa-download" /> Download Selected Items
                    </Button>
                </div>
            </div>
        );
    }

    renderSectionStatus({ title, attachments }) {
        const {
            project: {
                government: {
                    organization: { timezone },
                },
            },
        } = this.props;
        const { attachmentIds } = this.state;

        const Attachments = attachments.map((attachment) => {
            const shouldDownload = !!attachmentIds[attachment.id];
            const listStyle = shouldDownload ? 'success' : undefined;
            const icon = shouldDownload ? 'check-circle' : 'circle-thin';
            return (
                <ListGroupItem
                    bsStyle={listStyle}
                    key={attachment.id}
                    onClick={() => this.selectAttachment(attachment)}
                >
                    <div className={this.styles.listItem}>
                        <div className={this.styles.iconContainer}>
                            <i className={`fa fa-${icon} fa-fw fa-lg`} />
                        </div>
                        <div className={this.styles.fileContainer}>
                            <div>{attachment.name}</div>
                            <div className={`text-muted ${this.styles.fileDate}`}>
                                {moment.tz(attachment.created_at, timezone).format('lll')}
                            </div>
                        </div>
                    </div>
                </ListGroupItem>
            );
        });

        return (
            <div>
                <h4 className={this.styles.attachmentSectionTitle}>{title}:</h4>
                {Attachments}
            </div>
        );
    }

    render() {
        return (
            <div className={`row ${this.styles.container}`}>
                <div className="col-xs-12 col-sm-offset-1 col-sm-10">
                    <div className="text-center">
                        <SectionTitle
                            info="Select the documents you would like to download"
                            title="Project Documents Download"
                        />
                    </div>
                    {this.renderDownloadsList()}
                </div>
            </div>
        );
    }
}

export const DownloadsList = connect(mapStateToProps, mapDispatchToProps)(ConnectedDownloadsList);
