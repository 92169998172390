import React from 'react';

import { startCase } from 'lodash';

import {
    DEFAULT_NUMBER_OF_REQUESTS_PERIODS,
    getRequisitionStatusData,
} from '@og-pro/shared-config/requisitions';

import { Box, Button, Typography } from '@og-pro/ui';

import { Close as CloseIcon } from '@mui/icons-material';

import { ACTIVE_REQUESTS_STATUSES } from '@og-pro/sourcing-db-models/requisitions/constants/requisitions';

import { useQueryParam } from '../../../../../../hooks';
import { activeRequestsColors, numberOfRequestsColors } from '../../Visualizations/constants';
import { chartInteractionParamsDict } from '../../Visualizations/helpers/chartInteractions';

const { OLDER_THAN, STATUS, TYPE } = chartInteractionParamsDict;

const colorDotStyles = (valueColor) => ({
    borderRadius: '100%',
    minHeight: '18px',
    minWidth: '18px',
    backgroundColor: valueColor,
});

const showChartInteractionLabel = (params) => {
    if (params[OLDER_THAN]) {
        const valueIndex = DEFAULT_NUMBER_OF_REQUESTS_PERIODS.findIndex(
            (period) => period === params[OLDER_THAN]
        );
        const valueColor = numberOfRequestsColors[valueIndex];

        return (
            <>
                <Box sx={colorDotStyles(valueColor)} />
                <Typography variant="h3">Requests Older Than: {params[OLDER_THAN]} Days</Typography>
            </>
        );
    }

    if (params[STATUS]) {
        const valueIndex = ACTIVE_REQUESTS_STATUSES.findIndex(
            (period) => period === params[STATUS]
        );
        const valueColor = activeRequestsColors[valueIndex];

        return (
            <>
                <Box sx={colorDotStyles(valueColor)} />

                <Typography variant="h3">
                    Requests In {getRequisitionStatusData(params[STATUS]).longName} Status
                </Typography>
            </>
        );
    }

    if (params[TYPE]) {
        return <Typography variant="h3">{startCase(params[TYPE])} Requests</Typography>;
    }

    return null;
};

export const ChartSelection = () => {
    const [params, , setMultipleQueryParams] = useQueryParam();

    const closeChartSelection = () => {
        setMultipleQueryParams({
            [OLDER_THAN]: null,
            [STATUS]: null,
            [TYPE]: null,
        });
    };

    return (
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                gap: 2,
            }}
        >
            {showChartInteractionLabel(params)}
            <Button
                color="secondary"
                onClick={closeChartSelection}
                qaTag="requisitionsDashboard-closeChartSelection"
                startIcon={<CloseIcon />}
                variant="outlined"
            >
                Close Chart Selection
            </Button>
        </Box>
    );
};
