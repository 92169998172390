import { ReduxFormTextFieldNumber } from '@og-pro/ui';
import React from 'react';
import { Field } from 'redux-form';

import { AttachMoneyOutlined, PercentOutlined } from '@mui/icons-material';
import {
    customFieldFields,
    instructionsModes,
    numberKinds,
} from '@og-pro/shared-config/customFormService/customField';
import PropTypes from 'prop-types';

const { NAME, NUMBER_KIND, INSTRUCTIONS_TEXT, INSTRUCTIONS_MODE } = customFieldFields;
const { TOOLTIP, DESCRIPTION } = instructionsModes;

export const NumberPreview = ({ customFieldDefinition }) => {
    const instructionsText = customFieldDefinition[INSTRUCTIONS_TEXT];
    const numberKind = customFieldDefinition[NUMBER_KIND];

    const getIcons = (kind) => {
        switch (kind) {
            case numberKinds.PERCENTAGE:
                return {
                    endIcon: <PercentOutlined />,
                };
            case numberKinds.CURRENCY:
                return {
                    startIcon: <AttachMoneyOutlined />,
                };
            default:
                return {};
        }
    };

    const { startIcon, endIcon } = getIcons(numberKind);

    return (
        <Field
            InputProps={{
                readOnly: true,
            }}
            component={ReduxFormTextFieldNumber}
            description={
                customFieldDefinition[INSTRUCTIONS_MODE] === DESCRIPTION
                    ? instructionsText
                    : undefined
            }
            endIcon={endIcon}
            label={customFieldDefinition[NAME] || 'Your title will go here'}
            name="number_PREVIEW"
            startIcon={startIcon}
            sx={{
                width: '100%',
            }}
            tooltip={
                customFieldDefinition[INSTRUCTIONS_MODE] === TOOLTIP ? instructionsText : undefined
            }
        />
    );
};

NumberPreview.propTypes = {
    customFieldDefinition: PropTypes.object,
};
