import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { options } from './constants';
import { MultipleChoiceInput } from '../../../../MultipleChoiceInput';

export class YesNoResponseForm extends PureComponent {
    static propTypes = {
        disabled: PropTypes.bool,
        input: PropTypes.object.isRequired,
        isOGThemeEnabledForComponents: PropTypes.bool,
        meta: PropTypes.object.isRequired,
        showValidation: PropTypes.bool,
    };

    render() {
        const { disabled, input, meta, showValidation, isOGThemeEnabledForComponents } = this.props;

        return (
            <MultipleChoiceInput
                disabled={disabled}
                input={input}
                meta={meta}
                options={options}
                showValidation={showValidation}
                useOpenGovStyle={isOGThemeEnabledForComponents}
            />
        );
    }
}
