import { upperCase } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { ListGroupItem } from 'react-bootstrap';

import { checklistStatusClass } from '../helpers/statusHelper';
import { LinkContainer } from '../LinkContainer/LinkContainer';
import { StatusLabel } from '../StatusLabel';
import { ChecklistProgressBar } from '../ChecklistProgressBar';
import { UserProfilePicture } from '../UserProfilePicture/UserProfilePicture';

export const ChecklistsListItem = ({
    checklist: {
        id,
        lastUpdater,
        questionnaires,
        status,
        template: { title },
        updated_at: updatedAt,
    },
    contractChecklistsPath,
}) => {
    const styles = require('./ChecklistsListItem.scss');

    return (
        <LinkContainer key={id} to={`${contractChecklistsPath}/${id}`}>
            <ListGroupItem>
                <div className="row">
                    <div className={`col-sm-4 ${styles.col}`}>
                        {title}
                        <br />
                        <StatusLabel bsStyle={checklistStatusClass(status)}>
                            {upperCase(status)}
                        </StatusLabel>
                    </div>
                    <div className={`col-sm-4 ${styles.col}`}>
                        <ChecklistProgressBar questionnaires={questionnaires} />
                    </div>
                    <div className={`col-sm-4 ${styles.col}`}>
                        <UserProfilePicture
                            className={styles.profilePicture}
                            horizontal
                            user={lastUpdater}
                        />
                        <div className={styles.lastUpdaterText}>
                            {lastUpdater.displayName}
                            <br />
                            <small>
                                <em>{moment(updatedAt).fromNow()}</em>
                            </small>
                        </div>
                    </div>
                </div>
            </ListGroupItem>
        </LinkContainer>
    );
};

ChecklistsListItem.propTypes = {
    checklist: PropTypes.shape({
        id: PropTypes.number.isRequired,
        lastUpdater: PropTypes.shape({
            displayName: PropTypes.string.isRequired,
        }).isRequired,
        questionnaires: PropTypes.array.isRequired,
        status: PropTypes.string.isRequired,
        template: PropTypes.shape({
            title: PropTypes.string.isRequired,
        }).isRequired,
        updated_at: PropTypes.string.isRequired,
    }).isRequired,
    contractChecklistsPath: PropTypes.string.isRequired,
};
