import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field, FieldArray, reduxForm } from 'redux-form';

import { getAddendumText } from '@og-pro/shared-config/addendums';

import { AddendumDiffField } from './AddendumDiffField';
import { fieldNames, form } from './constants';
import { validate } from './validate';
import { FileUploadAttachment } from '../../FileUploadAttachment';
import { InputText } from '../../InputText';
import { RichTextInput } from '../../RichTextInput';
import { Attachable } from '../Attachable';
import { Button } from '../../Button';
import { OutlineButton } from '../../OutlineButton';

const { ATTACHMENTS, DESCRIPTION, DIFF, TITLE } = fieldNames;

const formConfig = {
    form,
    validate,
};

// @reduxForm
class ConnectedAddendumForm extends Component {
    static propTypes = {
        array: PropTypes.shape({
            push: PropTypes.func.isRequired,
        }).isRequired,
        closeForm: PropTypes.func.isRequired,
        deleteHandler: PropTypes.func,
        disabled: PropTypes.bool,
        handleSubmit: PropTypes.func.isRequired,
        hasAutoAddenda: PropTypes.bool.isRequired,
        isCreateForm: PropTypes.bool,
        isNotice: PropTypes.bool,
        isReleased: PropTypes.bool,
        projectId: PropTypes.number.isRequired,
        serverError: PropTypes.string,
    };

    get addendumText() {
        return getAddendumText({ isNotice: this.props.isNotice });
    }

    uploadHandler = (fileData) => {
        const { array } = this.props;

        const { attachment, signedData } = fileData;

        const attachmentData = {
            bucket: signedData.bucket,
            // Specifying created_at to avoid scenario where multiple attachments uploaded together get the same date
            // and thus it can't be reliably used for sorting
            created_at: new Date(),
            filename: signedData.filename,
            fileExtension: attachment.fileExtension,
            path: signedData.key,
            title: attachment.title,
        };

        array.push(ATTACHMENTS, attachmentData);
    };

    renderAttachments = ({ disabled, fields }) => {
        return fields.map((member, index) => {
            const attachment = fields.get(index);
            return (
                <FileUploadAttachment
                    attachment={attachment}
                    deleteHandler={() => fields.remove(index)}
                    disabled={disabled}
                    key={attachment.path}
                    useListItemStyle
                />
            );
        });
    };

    render() {
        const {
            closeForm,
            deleteHandler,
            disabled,
            handleSubmit,
            hasAutoAddenda,
            isCreateForm,
            isNotice,
            isReleased,
            projectId,
            serverError,
        } = this.props;

        return (
            <div>
                {isNotice && (
                    <Field
                        component={InputText}
                        disabled={disabled}
                        hasFeedback={false}
                        help="Enter a title for the Notice"
                        label="Notice Title"
                        name={TITLE}
                        placeholder="Project Award"
                        qaTag="addendumForm-title"
                        type="text"
                    />
                )}
                <Field
                    component={RichTextInput}
                    disabled={disabled}
                    help={`Enter a description or instructions for viewing the ${this.addendumText.lower}`}
                    label={`${this.addendumText.upper} Description (optional)`}
                    minRows={2}
                    name={DESCRIPTION}
                    placeholder="Enter text"
                    qaTag="addendumForm-description"
                />
                {hasAutoAddenda && !isNotice && <Field component={AddendumDiffField} name={DIFF} />}
                <Attachable
                    disabled={disabled}
                    hideAppendixLetter
                    onUploadComplete={this.uploadHandler}
                    projectId={projectId}
                />
                <FieldArray
                    component={this.renderAttachments}
                    disabled={disabled}
                    name={ATTACHMENTS}
                />
                <div className="text-right">
                    <Button
                        bsSize="sm"
                        bsStyle="link"
                        onClick={closeForm}
                        qaTag="addendumForm-cancel"
                    >
                        Cancel
                    </Button>
                    &nbsp;
                    {!isCreateForm && !isReleased && (
                        <OutlineButton
                            bsSize="sm"
                            bsStyle="danger"
                            onClick={deleteHandler}
                            qaTag="addendumForm-delete"
                        >
                            Delete
                        </OutlineButton>
                    )}
                    &nbsp;
                    <Button
                        bsStyle="primary"
                        disabled={disabled}
                        onClick={handleSubmit}
                        qaTag="addendumForm-submit"
                    >
                        {isCreateForm ? `Save New ${this.addendumText.upper}` : 'Save'}
                    </Button>
                    {serverError && <div className="error-block">{serverError}</div>}
                </div>
            </div>
        );
    }
}

export const AddendumForm = reduxForm(formConfig)(ConnectedAddendumForm);
