import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { HelpBlock } from '../../../HelpBlock/HelpBlock';

export class Public extends PureComponent {
    static propTypes = {
        isPublic: PropTypes.bool,
    };

    render() {
        const { isPublic } = this.props;

        if (!isPublic) {
            return null;
        }

        return (
            <HelpBlock className="public-container">
                <em>
                    <strong>Please Note:</strong>&nbsp;Responses to this question may be publicly
                    displayed after the due date has passed.
                </em>
            </HelpBlock>
        );
    }
}
