import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'redux';

import {
    disabledConsensusToolip,
    disabledPublishBidResultsTooltip,
    disabledPublishToolip,
    form,
    IS_CONSENSUS_PUBLIC,
    IS_PUBLIC,
    publishBidResultsTooltip,
    publishConsensusTooltip,
    publishTooltip,
} from './constants';
import { showConfirmationSimpleModal } from '../../../actions/confirmation';
import { updateEvaluation } from '../../../actions/evaluations';
import { govUpdateProject } from '../../../actions/govProjects';
import { Button, HelpIcon, Toggle } from '../../../components';

const mapStateToProps = (state, props) => {
    return {
        initialValues: {
            [IS_CONSENSUS_PUBLIC]: get(props, `project.evaluation.${IS_CONSENSUS_PUBLIC}`),
            [IS_PUBLIC]: get(props, `project.evaluation.${IS_PUBLIC}`),
        },
        updateError: state.evaluations.get('updateError'),
        updating: state.govProjects.get('updateLoading') || state.evaluations.get('updating'),
    };
};

const mapDispatchToProps = {
    showConfirmationSimpleModal,
    updateEvaluation,
    updateProject: govUpdateProject,
};

const formConfig = {
    enableReinitialize: true,
    form,
};

// @connect
// @reduxForm
class ConnectedSelectedVendorGovernmentActions extends PureComponent {
    static propTypes = {
        finalizeAwardHandler: PropTypes.func.isRequired,
        isAwardPending: PropTypes.bool.isRequired,
        isEditor: PropTypes.bool.isRequired,
        project: PropTypes.shape({
            evaluation: PropTypes.shape({
                hasConsensusEvaluation: PropTypes.bool.isRequired,
                isConsensusPublic: PropTypes.bool.isRequired,
                isPublic: PropTypes.bool.isRequired,
                type: PropTypes.string.isRequired,
            }),
            id: PropTypes.number.isRequired,
            isPublicBidResult: PropTypes.bool.isRequired,
        }).isRequired,
        showConfirmationSimpleModal: PropTypes.func.isRequired,
        updateEvaluation: PropTypes.func.isRequired,
        updateProject: PropTypes.func.isRequired,
        updateError: PropTypes.string,
        updating: PropTypes.bool.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    // NOTE: Currently not used - left here in case we want to add
    // Will need to update API to allow evaluation editor permissions or limit to sourcing editors
    // 4/15/22: In case this does get added, note that Bid results has been split into two settings:
    // `isPublicBidResult` and `isPublicBidPricingResult`. See PR: https://github.com/OpenGov/pro-app/pull/1778
    toggleBidResultsPublic = (e, isPublicBidResult) => {
        const { project, updateProject } = this.props;

        // Prevent toggle from changing
        e.preventDefault();

        const updateData = { isPublicBidResult };

        const updateFunction = () => {
            updateProject(project, updateData, {
                endpoint: `/project/${project.id}/post/bid-results`,
                snackbarMessage: `Bid Results ${isPublicBidResult ? 'Published' : 'Hidden'}`,
            });
        };

        return this.props.showConfirmationSimpleModal(updateFunction, {
            bsStyle: project.isPublicBidResult ? 'warning' : 'primary',
            btnText: `${project.isPublicBidResult ? 'Hide' : 'Show'} Pricing Information`,
            icon: project.isPublicBidResult ? 'ban' : 'check',
            text: `Are you sure you want to publicly ${
                project.isPublicBidResult ? 'hide' : 'display'
            } vendor pricing information?`,
        });
    };

    toggleEvaluationPublic = (e, isPublic) => {
        const {
            project: { id, evaluation },
        } = this.props;

        // Prevent toggle from changing
        e.preventDefault();

        const updateData = {
            evaluation: { isPublic },
            updateOptions: {
                forceEdit: true,
            },
        };

        const updateFunction = () => {
            this.props.updateEvaluation(id, updateData, {
                notify: true,
                notifyDisplay: `Selected Vendor ${isPublic ? 'Published' : 'Hidden'}`,
            });
        };

        return this.props.showConfirmationSimpleModal(updateFunction, {
            bsStyle: evaluation.isPublic ? 'warning' : 'primary',
            btnText: `${evaluation.isPublic ? 'Hide' : 'Show'} Selected Vendor(s)`,
            icon: evaluation.isPublic ? 'ban' : 'check',
            text: `Are you sure you want to publicly ${
                evaluation.isPublic ? 'hide' : 'display'
            } the selected vendor(s)?`,
        });
    };

    toggleConsensusPublic = (e, isConsensusPublic) => {
        const {
            project: { id, evaluation },
        } = this.props;

        // Prevent toggle from changing
        e.preventDefault();

        const updateData = {
            evaluation: { isConsensusPublic },
            updateOptions: {
                forceEdit: true,
            },
        };

        const updateFunction = () => {
            this.props.updateEvaluation(id, updateData, {
                notify: true,
                notifyDisplay: `Consensus Scorecard ${isConsensusPublic ? 'Published' : 'Hidden'}`,
            });
        };

        return this.props.showConfirmationSimpleModal(updateFunction, {
            bsStyle: evaluation.isConsensusPublic ? 'warning' : 'primary',
            btnText: `${evaluation.isConsensusPublic ? 'Hide' : 'Show'} Consensus Scorecard`,
            icon: evaluation.isConsensusPublic ? 'ban' : 'check',
            text: `Are you sure you want to publicly ${
                evaluation.isConsensusPublic ? 'hide' : 'display'
            } the Consensus Scorecard?`,
        });
    };

    // NOTE: Currently not used - left here in case we want to add
    renderPublicBidResultsDisplayLabel() {
        const { isEditor } = this.props;

        return (
            <span>
                Publicly display vendor pricing information for each response?
                <HelpIcon
                    tooltip={isEditor ? publishBidResultsTooltip : disabledPublishBidResultsTooltip}
                />
            </span>
        );
    }

    renderPublicDisplayLabel() {
        const { isEditor } = this.props;

        return (
            <span>
                Publicly display the selected vendor(s)?
                <HelpIcon tooltip={isEditor ? publishTooltip : disabledPublishToolip} />
            </span>
        );
    }

    renderConsensusDisplayLabel() {
        const { isEditor } = this.props;

        return (
            <span>
                Publicly display consensus scorecard?
                <HelpIcon tooltip={isEditor ? publishConsensusTooltip : disabledConsensusToolip} />
            </span>
        );
    }

    render() {
        const {
            finalizeAwardHandler,
            project: {
                evaluation: { hasConsensusEvaluation },
            },
            isAwardPending,
            isEditor,
            updateError,
            updating,
        } = this.props;

        return (
            <div className="text-center">
                {isAwardPending && isEditor && (
                    <div className={this.styles.finalizeButton}>
                        <Button bsSize="lg" bsStyle="success" onClick={finalizeAwardHandler}>
                            <i className="fa fa-check-square-o" /> Finalize Award
                        </Button>
                    </div>
                )}
                <div className={this.styles.publicToggles}>
                    <Field
                        component={Toggle}
                        disabled={updating || !isEditor}
                        label={this.renderPublicDisplayLabel()}
                        labelInline
                        name={IS_PUBLIC}
                        onChange={this.toggleEvaluationPublic}
                    />
                    {hasConsensusEvaluation && (
                        <Field
                            component={Toggle}
                            disabled={updating || !isEditor}
                            label={this.renderConsensusDisplayLabel()}
                            labelInline
                            name={IS_CONSENSUS_PUBLIC}
                            onChange={this.toggleConsensusPublic}
                        />
                    )}
                </div>
                {updateError && <div className="error-block">{updateError}</div>}
            </div>
        );
    }
}

export const SelectedVendorGovernmentActions = compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig)
)(ConnectedSelectedVendorGovernmentActions);
