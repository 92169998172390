import PropTypes from 'prop-types';
import React from 'react';
import { ListGroupItem } from 'react-bootstrap';

import { Button } from '../../../../Button';

export const IncompleteSection = ({
    disabled,
    formSection,
    incompleteSectionInstructions,
    meta: { valid },
    openSectionHandler,
    sectionNumber,
}) => {
    if (valid) {
        return null;
    }

    const styles = require('./index.scss');

    return (
        <ListGroupItem>
            <div className="pull-right">
                <Button
                    bsStyle="link"
                    disabled={disabled}
                    onClick={() => openSectionHandler(sectionNumber, true)}
                    qaTag="templateReviewSection-openStep"
                    zeroPadding
                >
                    Open step
                </Button>
            </div>
            <span className={`fa-stack text-muted ${styles.incompleteIcon}`}>
                <i className="fa fa-circle fa-stack-2x" />
                <strong className="fa-stack-1x fa-inverse">{sectionNumber}</strong>
            </span>
            {incompleteSectionInstructions[formSection]}
        </ListGroupItem>
    );
};

IncompleteSection.propTypes = {
    disabled: PropTypes.bool,
    formSection: PropTypes.string.isRequired,
    incompleteSectionInstructions: PropTypes.object.isRequired,
    meta: PropTypes.shape({
        valid: PropTypes.bool.isRequired,
    }).isRequired,
    openSectionHandler: PropTypes.func.isRequired,
    sectionNumber: PropTypes.number.isRequired,
};
