import PropTypes from 'prop-types';
import React from 'react';

import { Button } from '../Button';
import { HoverButton } from '../HoverButton';

export const RsvpButton = ({
    isRsvpEnded,
    projectId,
    rsvp,
    rsvpLoginHandler,
    rsvpingVendor,
    timelineId,
    user,
    vendorRsvpHandler,
}) => {
    const styles = require('./RsvpButton.scss');
    const data = timelineId ? { timelineId } : { isPreProposalRsvp: true };
    const isGovUser = user && !user.isVendor;
    const getTooltipText = () => {
        if (isRsvpEnded) {
            return 'The RSVP period for this event has closed';
        }

        if (isGovUser) {
            return 'Only vendors can RSVP to events';
        }

        return null;
    };

    if (!user) {
        return (
            <Button
                bsSize="sm"
                disabled={isRsvpEnded}
                onClick={rsvpLoginHandler}
                tooltip={getTooltipText()}
            >
                {isRsvpEnded ? 'RSVPs Closed' : 'Log in to RSVP'}
            </Button>
        );
    }

    if (rsvp) {
        return (
            <HoverButton
                bsSize="sm"
                bsStyle="success"
                className={styles.rsvpBtn}
                disabled={isRsvpEnded || isGovUser || rsvpingVendor}
                hoverChildren={
                    <span>
                        <i className="fa fa-user-times" /> Remove RSVP
                    </span>
                }
                hoverStyle="danger"
                onClick={() => vendorRsvpHandler(projectId, rsvp.id)}
                outlineButton
                tooltip={getTooltipText()}
            >
                <i className="fa fa-check" /> Attending
            </HoverButton>
        );
    }

    return (
        <HoverButton
            bsSize="sm"
            className={styles.rsvpBtn}
            disabled={isRsvpEnded || isGovUser || rsvpingVendor}
            hoverChildren={
                <span>
                    <i className="fa fa-user-plus" /> Attend
                </span>
            }
            hoverStyle="success"
            onClick={() => vendorRsvpHandler(projectId, null, data)}
            tooltip={getTooltipText()}
        >
            <i className="fa fa-user-times" /> Not Attending
        </HoverButton>
    );
};

RsvpButton.propTypes = {
    isRsvpEnded: PropTypes.bool.isRequired,
    projectId: PropTypes.number.isRequired,
    rsvp: PropTypes.shape({
        id: PropTypes.number,
    }),
    rsvpLoginHandler: PropTypes.func.isRequired,
    rsvpingVendor: PropTypes.bool.isRequired,
    timelineId: PropTypes.number,
    user: PropTypes.shape({
        isVendor: PropTypes.bool,
    }),
    vendorRsvpHandler: PropTypes.func.isRequired,
};
