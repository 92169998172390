import PropTypes from 'prop-types';
import React from 'react';

import { StatusLabel } from '../../StatusLabel';

export const ActionLabel = ({ isActionable }) => {
    const styles = require('./index.scss');

    return (
        <div>
            <StatusLabel bsStyle={isActionable ? 'primary' : 'muted'}>
                {isActionable && <i className={`fa fa-star ${styles.starIcon}`} />}
                Action
            </StatusLabel>
        </div>
    );
};

ActionLabel.propTypes = {
    isActionable: PropTypes.bool,
};
