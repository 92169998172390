import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import { NavItem } from '../../Nav/NavItem';

export const HelpNav = () => {
    const supportLink = (
        <a href="mailto:procurement-support@opengov.com">procurement-support@opengov.com</a>
    );

    const knowledgeBaseLink = (
        <a
            href="https://opengov.my.site.com/support/services/auth/sso/OpenGov_Unified_Login"
            rel="noreferrer noopener"
            target="_blank"
        >
            Help Center
        </a>
    );
    const supportMessage = (
        <Popover id="popover-help-nav" title="Help Center">
            <p>Visit our online {knowledgeBaseLink} for answers to common questions.</p>
            <p>Or contact us at {supportLink} for help at any time!</p>
        </Popover>
    );
    return (
        <OverlayTrigger overlay={supportMessage} placement="bottom" rootClose trigger="click">
            <NavItem aria-label="Help Center" className="hidden-xs help-btn" qaTag="govNavbar-help">
                <i className="fa fa-lg fa-question-circle-o" />
            </NavItem>
        </OverlayTrigger>
    );
};
