import { showSnackbar } from './notification';
import { LOAD_DIFF_SUCCESS } from './revisions/types';
import { emitProjectSocket } from './utils';
import { resourceManager } from '../helpers';

export const CREATE_ADDENDUM = 'addendums/CREATE_ADDENDUM';
export const CREATE_ADDENDUM_FAIL = 'addendums/CREATE_ADDENDUM_FAIL';
export const CREATE_ADDENDUM_SUCCESS = 'addendums/CREATE_ADDENDUM_SUCCESS';

export const DELETE_ADDENDUM = 'addendums/DELETE_ADDENDUM';
export const DELETE_ADDENDUM_FAIL = 'addendums/DELETE_ADDENDUM_FAIL';
export const DELETE_ADDENDUM_SUCCESS = 'addendums/DELETE_ADDENDUM_SUCCESS';

export const LOAD_ADDENDUM_PROPOSALS = 'addendums/LOAD_ADDENDUM_PROPOSALS';
export const LOAD_ADDENDUM_PROPOSALS_SUCCESS = 'addendums/LOAD_ADDENDUM_PROPOSALS_SUCCESS';

export const LOAD_ADDENDUMS = 'addendums/LOAD_ADDENDUMS';
export const LOAD_ADDENDUMS_FAIL = 'addendums/LOAD_ADDENDUMS_FAIL';
export const LOAD_ADDENDUMS_SUCCESS = 'addendums/LOAD_ADDENDUMS_SUCCESS';

export const UPDATE_ADDENDUM = 'addendums/UPDATE_ADDENDUM';
export const UPDATE_ADDENDUM_FAIL = 'addendums/UPDATE_ADDENDUM_FAIL';
export const UPDATE_ADDENDUM_SUCCESS = 'addendums/UPDATE_ADDENDUM_SUCCESS';

export function createAddendum(projectId, addendumData, options) {
    return resourceManager({
        method: 'post',
        url: `/project/${projectId}/addendums`,
        requestOptions: { data: addendumData },
        onStart: ({ dispatch }) => dispatch({ type: CREATE_ADDENDUM }),
        onSuccess: ({ context, result, dispatch }) => {
            const createAction = { type: CREATE_ADDENDUM_SUCCESS, result };
            dispatch(emitProjectSocket(projectId, createAction));
            dispatch(showSnackbar(`${result.titleDisplay} created`));
            if (context.onSuccess) {
                return context.onSuccess(result);
            }
        },
        onFailure: ({ context, error, dispatch }) => {
            dispatch({ type: CREATE_ADDENDUM_FAIL, error });
            if (context.rethrowError) {
                throw error;
            }
        },
        context: options,
    });
}

export function deleteAddendum(projectId, addendumId) {
    return resourceManager({
        method: 'del',
        url: `/project/${projectId}/addendums/${addendumId}`,
        onStart: ({ dispatch }) => dispatch({ type: DELETE_ADDENDUM, addendumId }),
        onSuccess: ({ result, dispatch }) => {
            const deleteAction = { type: DELETE_ADDENDUM_SUCCESS, addendumId, result };
            dispatch(emitProjectSocket(projectId, deleteAction));
            dispatch(showSnackbar(`${result.titleDisplay} deleted`));
        },
        onFailure: ({ error, dispatch }) =>
            dispatch({ type: DELETE_ADDENDUM_FAIL, addendumId, error }),
    });
}

export function loadAddendumDiff(projectId, addendumId) {
    return resourceManager({
        method: 'get',
        url: `/project/${projectId}/addendums/${addendumId}/diff`,
        onSuccess: ({ result, dispatch }) => {
            const { html } = result;
            if (html) {
                dispatch({ type: LOAD_DIFF_SUCCESS, result: { diff: html } });
            }
            return html;
        },
    });
}

export function loadAddendumProposals(projectId) {
    return resourceManager({
        method: 'get',
        url: `/project/${projectId}/addendums/proposals`,
        onStart: ({ dispatch }) => dispatch({ type: LOAD_ADDENDUM_PROPOSALS }),
        onSuccess: ({ result, dispatch }) => {
            return dispatch({ type: LOAD_ADDENDUM_PROPOSALS_SUCCESS, result });
        },
        onFailure: () => {},
    });
}

export function loadAddendums(projectId, query) {
    return resourceManager({
        method: 'get',
        url: `/project/${projectId}/addendums`,
        requestOptions: { params: query },
        onStart: ({ dispatch }) => dispatch({ type: LOAD_ADDENDUMS }),
        onSuccess: ({ result, dispatch }) => dispatch({ type: LOAD_ADDENDUMS_SUCCESS, result }),
        onFailure: ({ error, dispatch }) => dispatch({ type: LOAD_ADDENDUMS_FAIL, error }),
    });
}

export function releaseAddendum(projectId, addendumId) {
    return resourceManager({
        method: 'post',
        url: `/project/${projectId}/addendums/${addendumId}/release`,
        onStart: ({ dispatch }) => dispatch({ type: UPDATE_ADDENDUM, addendumId }),
        onSuccess: ({ result, dispatch }) => {
            const updateAction = { type: UPDATE_ADDENDUM_SUCCESS, addendumId, result };
            dispatch(emitProjectSocket(projectId, updateAction));
            dispatch(showSnackbar(`${result.titleDisplay} released!`));
        },
        onFailure: ({ error, dispatch }) =>
            dispatch({ type: UPDATE_ADDENDUM_FAIL, addendumId, error }),
    });
}

export function updateAddendum(projectId, addendumId, addendumData, onSuccess) {
    return resourceManager({
        method: 'put',
        url: `/project/${projectId}/addendums/${addendumId}`,
        requestOptions: { data: addendumData },
        onStart: ({ dispatch }) => dispatch({ type: UPDATE_ADDENDUM, addendumId }),
        onSuccess: ({ result, dispatch }) => {
            const updateAction = { type: UPDATE_ADDENDUM_SUCCESS, addendumId, result };
            dispatch(emitProjectSocket(projectId, updateAction));
            dispatch(showSnackbar(`${result.titleDisplay} saved`));
            onSuccess(result);
        },
        onFailure: ({ error, dispatch }) =>
            dispatch({ type: UPDATE_ADDENDUM_FAIL, addendumId, error }),
    });
}
