/* eslint no-use-before-define: ["error", { "functions": false }] */

// NOTE: every callback should dispatch at least a single action describing what happened
// NOTE: if your callback calls another function which _could_ return something (such as a Promise),
// don't forget to return the call to it so that our promise chain isn't broken.
// NOTE: if you create a callback that calls another callback, you're probably doing something that
// doesn't match the pattern of how we use actions. Do it in the reducer via the action.

import { pick } from 'lodash';

import { showNotification } from './notification';
import { deserializeFilterQuery, resourceManager, trackEvent } from '../helpers';
import { exportArrayToCSV } from '../utils';
import {
    hideConfirmationModal,
    showConfirmationModalError,
    updatingConfirmationModal,
} from './confirmation';

export const PROJECTS_LIST_LIMIT = 20;

export const LOAD_PROJECTS = 'projects/LOAD_PROJECTS';
export const LOAD_PROJECTS_FAIL = 'projects/LOAD_PROJECTS_FAIL';
export const LOAD_PROJECTS_SUCCESS = 'projects/LOAD_PROJECTS_SUCCESS';
export const LOAD_CLEANUP_DOCUMENTS = 'projects/LOAD_CLEANUP_DOCUMENTS';
export const LOAD_CLEANUP_DOCUMENTS_FAIL = 'projects/LOAD_CLEANUP_DOCUMENTS_FAIL';
export const LOAD_CLEANUP_DOCUMENTS_SUCCESS = 'projects/LOAD_CLEANUP_DOCUMENTS_SUCCESS';
export const LOAD_EXPIRED = 'projects/LOAD_EXPIRED';
export const LOAD_EXPIRED_FAIL = 'projects/LOAD_EXPIRED_FAIL';
export const LOAD_EXPIRED_SUCCESS = 'projects/LOAD_EXPIRED_SUCCESS';
export const LOAD_RECENTLY_CLOSED = 'projects/LOAD_RECENTLY_CLOSED';
export const LOAD_RECENTLY_CLOSED_FAIL = 'projects/LOAD_RECENTLY_CLOSED_FAIL';
export const LOAD_RECENTLY_CLOSED_SUCCESS = 'projects/LOAD_RECENTLY_CLOSED_SUCCESS';
export const DELETE_DOCUMENTS = 'projects/DELETE_DOCUMENTS';
export const DELETE_DOCUMENTS_SUCCESS = 'projects/DELETE_DOCUMENTS_SUCCESS';
export const DELETE_DOCUMENTS_FAIL = 'projects/DELETE_DOCUMENTS_FAIL';
export const DELETE_EXPIRED = 'projects/DELETE_EXPIRED';
export const DELETE_EXPIRED_SUCCESS = 'projects/DELETE_EXPIRED_SUCCESS';
export const DELETE_EXPIRED_FAIL = 'projects/DELETE_EXPIRED_FAIL';
export const LOAD_PROJECT_LINE_ITEM_CONTRACT_INFO = 'projects/LOAD_PROJECT_LINE_ITEM_CONTRACT_INFO';
export const LOAD_PROJECT_LINE_ITEM_CONTRACT_INFO_SUCCESS =
    'projects/LOAD_PROJECT_LINE_ITEM_CONTRACT_INFO_SUCCESS';
export const LOAD_PROJECT_LINE_ITEM_CONTRACT_INFO_FAILURE =
    'projects/LOAD_PROJECT_LINE_ITEM_CONTRACT_INFO_FAILURE';
export const ASSIGN_POLICIES = 'projects/ASSIGN_POLICIES';
export const ASSIGN_POLICIES_FAIL = 'projects/ASSIGN_POLICIES_FAIL';
export const ASSIGN_POLICIES_SUCCESS = 'projects/ASSIGN_POLICIES_SUCCESS';

const generateLoadProjectsOptions = ({ context }) => {
    const data = {
        // Adds deserialized `page` and `filter` keys
        ...deserializeFilterQuery(context),
        limit: PROJECTS_LIST_LIMIT,
        // Filter supported parameters from context object
        ...pick(context, [
            'exportData',
            'governmentCode',
            'isDocBuilder',
            'isIntake',
            'publicView',
            'quickSearchQuery',
            'scope',
            'sort',
            'sortDirection',
            'status',
        ]),
    };

    // Add numeric IDs to parameters if present in context object
    if (Array.isArray(context.ids)) {
        data.ids = context.ids.map((id) => Number.parseInt(id, 10));
    }

    return { data };
};

const onLoadProjectsFailure = ({ error, dispatch, context }) => {
    if (context.exportData) {
        return dispatch(
            showNotification(`Error exporting data: ${error.message}`, { type: 'danger' })
        );
    }
    dispatch({ type: LOAD_PROJECTS_FAIL, error });
};

const onLoadProjectsStart = ({ dispatch, context }) => {
    if (context.exportData) {
        return dispatch(
            showNotification(
                "Exporting your data, this may take a few moments. You'll be prompted to download your file once it's ready.",
                { type: 'info', duration: 60000 }
            )
        );
    }
    dispatch({ type: LOAD_PROJECTS });
};

const onLoadProjectsSuccess = ({ result, dispatch, context }) => {
    if (context.exportData) {
        dispatch(
            showNotification('Export complete! You should be prompted to download your file.', {
                duration: 5000,
            })
        );
        return exportArrayToCSV(result, {
            fileName: 'projects_export',
            headers: true,
            timestamp: true,
        });
    }
    dispatch({ type: LOAD_PROJECTS_SUCCESS, result: result.projects, count: result.count });
};

const onLoadCleanupDocumentsFailure = ({ error, dispatch }) => {
    dispatch({ type: LOAD_CLEANUP_DOCUMENTS_FAIL, error });
};

const onLoadCleanupDocumentsStart = ({ dispatch }) => {
    dispatch({ type: LOAD_CLEANUP_DOCUMENTS });
};

const onLoadCleanupDocumentsSuccess = ({ result, dispatch }) => {
    dispatch({
        type: LOAD_CLEANUP_DOCUMENTS_SUCCESS,
        result: result.projects,
        count: result.count,
    });
};

const onDeleteDocumentsStart = ({ context, dispatch }) => {
    if (context.modal) {
        dispatch(updatingConfirmationModal());
    }
    dispatch({ type: DELETE_DOCUMENTS });
};

const onDeleteDocumentsSuccess = ({ context, result, dispatch }) => {
    trackEvent('Records Retention: Document Clean Up');
    if (context.modal) {
        dispatch(hideConfirmationModal());
    }
    dispatch({ type: DELETE_DOCUMENTS_SUCCESS, result });
};

const onDeleteDocumentsFailure = ({ context, error, dispatch }) => {
    if (context.modal) {
        dispatch(showConfirmationModalError(error.message));
    }
    dispatch({ type: DELETE_DOCUMENTS_FAIL, error });
};

const onLoadExpiredStart = ({ dispatch }) => {
    dispatch({ type: LOAD_EXPIRED });
};

const onLoadExpiredSuccess = ({ result, dispatch }) => {
    dispatch({
        type: LOAD_EXPIRED_SUCCESS,
        result: result.records,
        count: result.count,
    });
};

const onDeleteExpiredStart = ({ context, dispatch }) => {
    if (context.modal) {
        dispatch(updatingConfirmationModal());
    }
    dispatch({ type: DELETE_EXPIRED });
};

const onDeleteExpiredSuccess = ({ context, result, dispatch }) => {
    trackEvent('Records Retention: Expiration Date Passed');
    if (context.modal) {
        dispatch(hideConfirmationModal());
    }
    dispatch({ type: DELETE_EXPIRED_SUCCESS, result });
};

const onDeleteExpiredFailure = ({ context, error, dispatch }) => {
    if (context.modal) {
        dispatch(showConfirmationModalError(error.message));
    }
    dispatch({ type: DELETE_EXPIRED_FAIL, error });
};

const onLoadExpiredFailure = ({ error, dispatch }) => {
    dispatch({ type: LOAD_EXPIRED_FAIL, error });
};

const onLoadRecentlyClosedStart = ({ dispatch }) => {
    const type = LOAD_RECENTLY_CLOSED;
    dispatch({ type });
};

const onLoadRecentlyClosedSuccess = ({ result, dispatch }) => {
    const type = LOAD_RECENTLY_CLOSED_SUCCESS;
    dispatch({
        type,
        result: result.records,
        count: result.count,
    });
};

const onLoadRecentlyClosedFailure = ({ error, dispatch }) => {
    const type = LOAD_RECENTLY_CLOSED_FAIL;
    dispatch({ type, error });
};

const onAssignPoliciesFailure = ({ error, dispatch }) => {
    dispatch({ type: ASSIGN_POLICIES_FAIL, error });
};

const onAssignPoliciesStart = ({ dispatch }) => {
    dispatch({ type: ASSIGN_POLICIES });
};

const onAssignPoliciesSuccess = ({ result, dispatch }) => {
    trackEvent('Records Retention: Policy Assignment');
    dispatch({
        type: ASSIGN_POLICIES_SUCCESS,
        result,
    });
    dispatch(loadRecentlyClosed());
    dispatch(loadExpired()); // load expired records in case any of these records are now expired
};

/**
 * Delete the list of projects.
 * @param {object} [options={}] The set of options to pass to the request context
 * @param {object[]} [options.projects] List of projects to be deleted
 * @param {string} [options.storeLocation] They key in the store that the projects should be deleted from
 * @return {Promise<object[]>} The fetched projects
 */
export function deleteProjects(options = {}) {
    return resourceManager({
        method: 'del',
        url: '/project/list',
        requestOptions: {
            data: {
                ...options,
            },
        },
        onStart: onDeleteDocumentsStart,
        onSuccess: onDeleteDocumentsSuccess,
        onFailure: onDeleteDocumentsFailure,
        context: options,
    });
}

/**
 * Load the list of projects.
 * @param {object} [options={}] The set of options to pass to the request context
 * @param {string} [options.governmentCode] A governmentCode to filter the list by
 * @param {object[]} [options.filters] A list of additional filters to filter the list by
 * @param {boolean} [options.isDocBuilder] Whether to filter for docBuilder projects
 * @param {boolean} [options.publicView] Filters the list based on if the projects are public
 * @param {string} [options.storeLocation] They key in the store that the projects should be stored under
 * @return {Promise<object[]>} The fetched projects
 */
export function loadProjects(options = {}) {
    return resourceManager({
        method: 'post',
        url: '/project/list',
        requestOptions: generateLoadProjectsOptions,
        onStart: onLoadProjectsStart,
        onSuccess: onLoadProjectsSuccess,
        onFailure: onLoadProjectsFailure,
        context: options,
    });
}

/**
 * Load the list of documents and intakes eligible for purging.
 * @param {object} [options={}] The set of options to pass to the request context
 * @return {Promise<object[]>} The fetched projects
 */
export function loadCleanupDocuments(options = {}) {
    return resourceManager({
        method: 'get',
        url: '/project/cleanup-list',
        onStart: onLoadCleanupDocumentsStart,
        onSuccess: onLoadCleanupDocumentsSuccess,
        onFailure: onLoadCleanupDocumentsFailure,
        context: options,
    });
}

export function loadLineItemContractInfo(data) {
    return resourceManager({
        method: 'post',
        url: '/project/line-item-contract-info',
        requestOptions: { data },
        onStart: ({ dispatch }) => dispatch({ type: LOAD_PROJECT_LINE_ITEM_CONTRACT_INFO }),
        onSuccess: ({ dispatch, result }) =>
            dispatch({
                type: LOAD_PROJECT_LINE_ITEM_CONTRACT_INFO_SUCCESS,
                result,
            }),
        onFailure: ({ dispatch, error }) =>
            dispatch({
                type: LOAD_PROJECT_LINE_ITEM_CONTRACT_INFO_FAILURE,
                error,
            }),
    });
}

/**
 * Load the list of documents, intakes, and contracts whose retention dates have expired
 * @param {object} [options={}] The set of options to pass to the request context
 * @return {Promise<object[]>} The fetched documents, intakes, and contracts
 */
export function loadExpired(options = {}) {
    return resourceManager({
        method: 'get',
        url: '/retention-admin/expired',
        onStart: onLoadExpiredStart,
        onSuccess: onLoadExpiredSuccess,
        onFailure: onLoadExpiredFailure,
        context: options,
    });
}

/**
 * Delete a selection of documents, intakes, and contracts whose retention dates have expired
 * @param {object} [options={}] The set of options to pass to the request context
 * @return {Promise<object>} The deleted documents, intakes, and contracts
 */
export function deleteExpired(options = {}) {
    return resourceManager({
        method: 'del',
        url: '/retention-admin/expired',
        requestOptions: {
            data: {
                ...options,
            },
        },
        onStart: onDeleteExpiredStart,
        onSuccess: onDeleteExpiredSuccess,
        onFailure: onDeleteExpiredFailure,
        context: options,
    });
}

/**
 * Load the list of documents, intakes, and contracts that have been recently closed
 * @param {object} [options={}] The set of options to pass to the request context
 * @return {Promise<object[]>} The fetched documents, intakes, and contracts
 */
export function loadRecentlyClosed(options = {}) {
    return resourceManager({
        method: 'get',
        url: '/retention-admin/code-assignment',
        onStart: onLoadRecentlyClosedStart,
        onSuccess: onLoadRecentlyClosedSuccess,
        onFailure: onLoadRecentlyClosedFailure,
        context: options,
    });
}

/**
 * Assign retention policies to a given set of contracts and projects.
 * @param {*} policies The set of contracts and projects, with desired policies
 * @returns {Promise<object[]>} The updated contracts and projects.
 */
export function assignPolicies(policies) {
    const formattedPolicies = policies.map((policy) => ({
        id: policy.id,
        recordType: policy.recordType,
        retentionCodeId: policy.retentionPolicy.selectedPolicy,
    }));

    return resourceManager({
        method: 'put',
        url: '/retention-admin/code-assignment',
        requestOptions: { data: formattedPolicies },
        onStart: onAssignPoliciesStart,
        onSuccess: onAssignPoliciesSuccess,
        onFailure: onAssignPoliciesFailure,
    });
}
