import React from 'react';
import PropTypes from 'prop-types';
import { AgGridReact, Box } from '@og-pro/ui';
import { DeleteOutlined as DeleteOutlinedIcon } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { tokens } from '@opengov/capital-style';

import { ProcuratedVendorCell } from '../../../../components';
import { showVendorProfileModal } from '../../../../actions/vendorProfile';
import { addOrRemoveVendors } from '../../../../actions/vendorList';

const ProcuratedCell = (params) => {
    return (
        <ProcuratedVendorCell
            hideLogo
            params={{
                ...params,
                data: {
                    ...params.data.organization,
                    rating: params.data.rating,
                },
            }}
        />
    );
};

const DeleteIconCell = () => {
    return (
        <DeleteOutlinedIcon
            style={{
                width: 18,
                height: 18,
                marginLeft: -5,
                marginTop: 16,
                cursor: 'pointer',
                color: tokens.colors.colorGray800,
            }}
        />
    );
};

export const VendorListTable = ({ governmentId, vendorList }) => {
    const dispatch = useDispatch();

    const columns = [
        {
            field: 'organization.name',
            headerName: 'Vendor Legal Name & Rating (Powered by Procurated)',
            flex: 8.5,
            onCellClicked: (params) => {
                dispatch(showVendorProfileModal(params.data.organization.vendor.id));
            },
            cellRenderer: ProcuratedCell,
        },
        {
            field: 'displayName',
            headerName: 'Contact',
            flex: 5.5,
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 5.5,
        },
        {
            field: 'organization.phoneComplete',
            headerName: 'Company Phone',
            flex: 5,
        },
        {
            cellRenderer: DeleteIconCell,
            onCellClicked: (params) => {
                const pendingUserId = params.data.isPendingUser && params.data.id;
                dispatch(
                    addOrRemoveVendors({
                        actionType: 'remove',
                        governmentId,
                        pendingUserIds: pendingUserId ? [pendingUserId] : [],
                        opts: {
                            email: params.data.email,
                            listName: vendorList.name,
                            useOpenGovStyle: true,
                            userName: params.data.displayName,
                            vendorName: params.data.organization && params.data.organization.name,
                        },
                        searchParams: null,
                        userIds: pendingUserId ? [] : [params.data.id],
                        vendorListId: vendorList.id,
                    })
                );
            },
            flex: 1,
        },
    ];

    const defaultColDef = {
        sortable: true,
        resizable: false,
        suppressHeaderMenuButton: true,
    };

    const rows = vendorList?.listedUsers?.concat(
        vendorList.listedPendingUsers.map((user) => ({ ...user, isPendingUser: true }))
    );

    return (
        <Box mb={3} mt={1}>
            <AgGridReact
                columnDefs={columns}
                defaultColDef={defaultColDef}
                domLayout="autoHeight"
                pagination
                paginationPageSize={20}
                paginationPageSizeSelector={false}
                rowData={rows}
            />
        </Box>
    );
};

VendorListTable.propTypes = {
    governmentId: PropTypes.number.isRequired,
    vendorList: PropTypes.object.isRequired,
};
