import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Button } from '../Button';

export class BackButton extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        text: PropTypes.string.isRequired,
        to: PropTypes.string.isRequired,
    };

    render() {
        const { className, to, text } = this.props;

        const styles = require('./BackButton.scss');

        return (
            <div className={classnames(styles.backBtn, className)}>
                <Button bsSize="sm" to={to}>
                    <i className="fa fa-angle-left" /> {text}
                </Button>
            </div>
        );
    }
}
