import React from 'react';
import PropTypes from 'prop-types';
import { Field, formValueSelector } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { projectTypesDict } from '@og-pro/shared-config/projects';

import { isProjectIdRequired } from '../../../../../../selectors';
import {
    getProjectAutoNumber,
    incrementProjectAutoNumber,
} from '../../../../../../../../actions/project/create/projectCreate';
import { showSnackbar } from '../../../../../../../../actions/notification';
import { showConfirmationSimpleModal } from '../../../../../../../../actions/confirmation';
import { limitTextLength } from '../../../../../../../../Forms/normalizers';
import { CDSButton, InputText } from '../../../../../../../../components';
import { fieldNames } from '../constants';

const { FINANCIAL_ID } = fieldNames;

export const ProjectId = ({
    change,
    disabled,
    form,
    project: { id: projectId, isIntake, type },
    showValidation,
}) => {
    const dispatch = useDispatch();
    const formSelector = formValueSelector(form);
    const hasFinancialIdValue = useSelector((state) => !!formSelector(state, FINANCIAL_ID));
    const projectIdRequired = useSelector(isProjectIdRequired);
    const title = type === projectTypesDict.CONTRACT ? 'Document' : 'Project';
    const projectIdHelp =
        projectIdRequired && isIntake
            ? 'This will be required once request becomes a solicitation'
            : '';

    const styles = require('./ProjectId.scss');

    const autoNumberProject = () => {
        const incrementAutoNumber = () => {
            return dispatch(incrementProjectAutoNumber(projectId))
                .then((autoNumber) => {
                    change(FINANCIAL_ID, autoNumber.nextNumber);
                })
                .catch((error) => {
                    dispatch(
                        showSnackbar(`Could not insert Auto Number: ${error.message}`, {
                            isError: true,
                        })
                    );
                });
        };

        return dispatch(getProjectAutoNumber(projectId))
            .then((autoNumber) => {
                dispatch(
                    showConfirmationSimpleModal(incrementAutoNumber, {
                        bsStyle: 'primary',
                        btnText: 'Assign Auto Number',
                        icon: 'chevron-circle-down',
                        text: (
                            <>
                                Next auto number is:
                                <br key="break1" />
                                <strong key="key">{autoNumber.nextNumber}</strong>
                                <br key="break2" />
                                <br key="break3" />
                                Are you sure you want to assign this number to this{' '}
                                {title.toLowerCase()}?
                            </>
                        ),
                    })
                );
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(`Could not get Auto Number: ${error.message}`, {
                        isError: true,
                    })
                );
            });
    };

    return (
        <div className={styles.projectIdContainer}>
            <div className={styles.projectIdField}>
                <Field
                    component={InputText}
                    disabled={disabled}
                    hasFeedback={false}
                    help={`Identification number or code for the ${title.toLowerCase()}. ${projectIdHelp}`}
                    label={`${title} ID ${projectIdRequired && !isIntake ? '' : ' (optional)'}`}
                    name={FINANCIAL_ID}
                    normalize={limitTextLength(128)}
                    qaTag="projectInformationForm-projectId"
                    showValidation={showValidation}
                    type="text"
                    useOpenGovStyle
                />
            </div>
            <div className={styles.autoNumberButton}>
                <CDSButton
                    disabled={hasFinancialIdValue || disabled}
                    onClick={autoNumberProject}
                    qaTag="projectInformationForm-getAutoNumber"
                    tooltip={
                        hasFinancialIdValue
                            ? `${title} ID already in use. Delete ${title} ID to get an auto number.`
                            : undefined
                    }
                    variant="secondary"
                >
                    <i className="fa fa-magic" /> Get Auto Number
                </CDSButton>
            </div>
        </div>
    );
};

ProjectId.propTypes = {
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    form: PropTypes.string.isRequired,
    project: PropTypes.shape({
        id: PropTypes.number.isRequired,
        isIntake: PropTypes.bool.isRequired,
        type: PropTypes.string.isRequired,
    }).isRequired,
    showValidation: PropTypes.bool,
};
