import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { OutlineButton } from '../OutlineButton';
import { getDashboardPath } from '../../containers/GovApp/selectors';

export const CreateFormNotEditable = (props) => {
    const params = useParams();
    const dashboardPath = useSelector((state) => getDashboardPath(state, { params }));

    const styles = require('./index.scss');

    const { text } = props;

    return (
        <div className={`${styles.container} text-info`}>
            <div className={styles.icon}>
                <i className="fa fa-check-square-o fa-2x" />
            </div>
            <div>{text}</div>
            <div className={styles.button}>
                <OutlineButton bsSize="lg" bsStyle="info" to={dashboardPath}>
                    Go to Project Dashboard
                </OutlineButton>
            </div>
        </div>
    );
};

CreateFormNotEditable.propTypes = {
    text: PropTypes.string.isRequired,
};
