import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@og-pro/ui';
import { visuallyHidden } from '@mui/utils';

import { CDSButton } from '../../../../components/SDv2/CDSButtons/CDSButton';

// This should not be imported directly. Instead, use the `CommentIcon` component with the `useOpenGovStyle` prop.
export const CommentIconOpenGovStyle = ({
    className,
    clickHandler,
    iconOnly,
    qaTag,
    threads = [],
}) => {
    const threadCount = threads.length;

    const renderButtonText = () => {
        if (iconOnly) {
            if (threadCount) {
                return (
                    <>
                        <br />
                        {threadCount}
                    </>
                );
            }
            return null;
        }

        return (
            <>
                &nbsp;
                {threadCount || 'Add'} {`Comment${threadCount > 1 ? 's' : ''}`}
            </>
        );
    };

    return (
        <CDSButton
            className={className}
            noPadding
            onClick={clickHandler}
            qaTag={qaTag}
            size="small"
            variant="text"
        >
            <i className="fa fa-comments" />
            {renderButtonText()}
            <Box sx={visuallyHidden}>View Comments</Box>
        </CDSButton>
    );
};

CommentIconOpenGovStyle.propTypes = {
    className: PropTypes.string,
    clickHandler: PropTypes.func.isRequired,
    iconOnly: PropTypes.bool,
    qaTag: PropTypes.string.isRequired,
    threads: PropTypes.array,
};
