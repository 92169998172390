import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ReactAvatarEditor from 'react-avatar-editor';
import { Modal } from 'react-bootstrap';
import { Slider } from '@og-pro/ui';

import { Button } from '../Button';
import { Dropzone } from '../Dropzone';
import { Label } from '../InputText/Label';

const BYTES_PER_MEGABYTE = 1048576;
const DEFAULT_MAX_FILE_SIZE = 2;
const INITIAL_STATE = {
    image: null,
    position: { x: 0.5, y: 0.5 },
    scale: 1,
};

export class ImageUploadModal extends PureComponent {
    static propTypes = {
        allowEditing: PropTypes.bool,
        disabled: PropTypes.bool,
        existingImagePreviewData: PropTypes.shape({
            alt: PropTypes.string,
            src: PropTypes.string.isRequired,
        }),
        maxFileSizeMb: PropTypes.number,
        onCancel: PropTypes.func.isRequired,
        onHide: PropTypes.func.isRequired,
        showModal: PropTypes.bool.isRequired,
        updateImage: PropTypes.func.isRequired,
        uploadImageError: PropTypes.string,
    };

    static defaultProps = {
        allowEditing: false,
        disabled: false,
        maxFileSizeMb: DEFAULT_MAX_FILE_SIZE,
        uploadImageError: undefined,
    };

    constructor(props) {
        super(props);

        this.state = INITIAL_STATE;
    }

    get styles() {
        return require('./index.scss');
    }

    componentWillUnmount() {
        if (this.objectUrl) {
            // NOTE: We need to manually clear these otherwise the browser will keep them around
            // until the window is closed or reloaded
            URL.revokeObjectURL(this.objectUrl);
        }
    }

    addEditorRef = (editor) => {
        if (editor) {
            this.editor = editor;
        }
    };

    handleCancelClick = () => {
        const { onCancel } = this.props;

        onCancel();
    };

    handleDrop = (files) => {
        this.setState({ image: files[0] });
    };

    handleDropAccepted = () => {
        this.setState({ uploadError: false });
    };

    handleDropRejected = () => {
        this.setState({ uploadError: true });
    };

    handlePositionChange = (position) => {
        this.setState({ position });
    };

    handleSaveClick = () => {
        const { allowEditing, updateImage } = this.props;

        if (allowEditing) {
            const img = this.editor.getImageScaledToCanvas().toDataURL('image/png');

            this.hideModal();
            updateImage(img);
        } else {
            const reader = new FileReader();

            reader.addEventListener(
                'load',
                () => {
                    this.hideModal();
                    // NOTE: unlike when we `allowEditing`, this will return the data url in the
                    // format that the image was uploaded in. If we wanted to force all images to be
                    // PNG, we could take this image, load it into a canvas, and then extract is the
                    // same as above by calling `canvas.toDataURL('image/png')`
                    updateImage(reader.result);
                },
                false
            );

            reader.readAsDataURL(this.state.image);
        }
    };

    handleScale = (e, value) => {
        this.setState({ scale: value });
    };

    hideModal = () => {
        const { onHide } = this.props;

        this.setState(INITIAL_STATE, () => {
            onHide();
        });
    };

    renderUploader() {
        const { existingImagePreviewData, maxFileSizeMb } = this.props;

        let alt;
        let src;

        if (existingImagePreviewData) {
            alt = existingImagePreviewData.alt
                ? existingImagePreviewData.alt
                : 'Existing Image Preview';

            src = existingImagePreviewData.src;
        }

        if (this.state.image) {
            this.objectUrl = URL.createObjectURL(this.state.image);
            src = this.objectUrl;
        }

        const uploadDropzoneID = 'UploadImage';
        const accept = {
            'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
        };

        return (
            <>
                <Label htmlFor={uploadDropzoneID} label="Upload Image" />
                <div>
                    <Dropzone
                        accept={accept}
                        activeClassName={this.styles.activeDropBox}
                        className={
                            this.state.image ? this.styles.dropBoxWithPreview : this.styles.dropBox
                        }
                        dropzoneID={uploadDropzoneID}
                        maxSize={maxFileSizeMb * BYTES_PER_MEGABYTE}
                        multiple={false}
                        onDrop={this.handleDrop}
                        onDropAccepted={this.handleDropAccepted}
                        onDropRejected={this.handleDropRejected}
                    >
                        {this.state.image ? (
                            <img alt={alt} className={this.styles.imagePreview} src={src} />
                        ) : (
                            <div className={this.styles.dropArea}>
                                <p>
                                    Drag and drop an image here or click to select a file to upload.
                                </p>
                                <p>
                                    <i className="fa fa-2x fa-file-photo-o text-primary" />
                                </p>
                            </div>
                        )}
                    </Dropzone>
                    {this.state.uploadError && (
                        <p className="text-danger">
                            File could not be uploaded. Please ensure the image is less than{' '}
                            {maxFileSizeMb} MBs.
                        </p>
                    )}
                    <p>
                        <em>Max file size is {maxFileSizeMb} MBs</em>
                    </p>
                </div>
            </>
        );
    }

    renderEditor() {
        const { image, position, scale } = this.state;

        return (
            <>
                <Dropzone
                    className={this.styles.editorContainer}
                    disableClick
                    disabled
                    multiple={false}
                    onDrop={this.handleDrop}
                >
                    <div>
                        <ReactAvatarEditor
                            image={image}
                            onPositionChange={this.handlePositionChange}
                            position={position}
                            ref={this.addEditorRef}
                            scale={scale}
                        />
                    </div>
                </Dropzone>
                <div>
                    <div className={this.styles.editorSection}>
                        Zoom:
                        <br />
                        <Slider
                            max={3}
                            min={1}
                            onChange={this.handleScale}
                            step={0.01}
                            sx={{ mb: 2, mt: 1 }}
                            value={scale}
                        />
                    </div>
                </div>
            </>
        );
    }

    render() {
        const { allowEditing, disabled, showModal, uploadImageError } = this.props;

        let body;
        if (allowEditing && this.state.image) {
            body = this.renderEditor();
        } else {
            body = this.renderUploader();
        }

        return (
            <Modal onHide={this.hideModal} show={showModal}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">Upload Photo</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    {body}
                    <Button onClick={this.handleCancelClick}>
                        <i className="fa fa-ban" /> Cancel
                    </Button>
                    {this.state.image && (
                        <div>
                            <Button
                                bsStyle="primary"
                                className={this.styles.editorSectionButton}
                                disabled={disabled}
                                onClick={this.handleSaveClick}
                            >
                                Save
                            </Button>
                        </div>
                    )}
                    {uploadImageError && <div className="error-block">{uploadImageError}</div>}
                </Modal.Body>
            </Modal>
        );
    }
}
