import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@mui/material';

import { RequisitionsList } from './List/RequisitionsList';
import { LoadingError, LoadingSpinner } from '../../../../components';
import { dashboardRequisitionsSearch } from '../../../../actions/requisitionsDashboard';
import { useIsMounted, useQueryParam } from '../../../../hooks';
import { RequisitionsListHeader } from './List/RequisitionsListHeader';
import { RequisitionListFilters } from './List/Filters';
import { getRequisitionsSearchResults, getSearchError } from './selectors';
import { RequisitionsListPagination } from './List/RequisitionsListPagination';

export const RequisitionsDashboardBody = () => {
    const dispatch = useDispatch();

    const isMounted = useIsMounted();
    const searchError = useSelector(getSearchError);
    const requisitions = useSelector(getRequisitionsSearchResults);

    const [params] = useQueryParam();

    useEffect(() => {
        if (Object.keys(params).length === 0) {
            return;
        }

        dispatch(dashboardRequisitionsSearch(params));
    }, [JSON.stringify(params)]);

    if (!isMounted()) {
        return <LoadingSpinner useOpenGovStyle />;
    }

    if (searchError || !requisitions) {
        return <LoadingError error={searchError} useOpenGovStyle />;
    }

    return (
        <Box sx={{ height: '100%' }}>
            <RequisitionsListHeader />
            <Box
                sx={(theme) => ({
                    background: theme.palette.common.white,
                    borderTop: `1px solid ${theme.palette.grey[300]}`,
                    width: 'calc(100% + 48px)', // 24px padding on each side
                    marginLeft: '-24px', // offset the padding
                    marginRight: '-24px', // offset the padding
                    flexGrow: 1,
                    height: '100%',
                })}
            >
                <Box sx={{ px: { xs: 0, sm: 3 } }}>
                    <RequisitionListFilters />
                    <RequisitionsList requisitions={requisitions} />
                    <RequisitionsListPagination shownItemsCount={requisitions.length} />
                </Box>
            </Box>
        </Box>
    );
};
