import { sectionTypeNames } from '@og-pro/shared-config/sections';

export const validate = (values) => {
    const errors = {};

    const isTextArea = values.projectSectionType === sectionTypeNames.TEXT_AREA;

    if (!values.title?.trim()) {
        errors.title = 'Section title is required';
    } else if (values.title.length > 255) {
        errors.title = 'Section title must be less than 255 characters';
    }

    errors.items = values.items.map((item) => {
        const itemError = {};

        if (!isTextArea && !item.title?.trim()) {
            itemError.title = 'Item title is required';
        } else if (item.title && item.title.length > 255) {
            itemError.title = 'Item title must be less than 255 characters';
        }

        if (!item.description?.trim()) {
            itemError.description = 'Item description is required';
        }

        return itemError;
    });

    return errors;
};
