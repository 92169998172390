import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Dropzone } from '../Dropzone';
import { Label } from '../../InputText/Label';

export class StyledDropzone extends PureComponent {
    static propTypes = {
        accept: PropTypes.object,
        children: PropTypes.node,
        className: PropTypes.string,
        defaultRejectMessage: PropTypes.string,
        dropzoneClassName: PropTypes.string,
        isDragActiveClassName: PropTypes.string,
        dropzoneContainerClassName: PropTypes.string,
        icons: PropTypes.arrayOf(PropTypes.string), // custom prop - specifies custom icons in dropzone
        label: PropTypes.string,
        labelId: PropTypes.string, // Used to associate `htmlFor` on `Label` with aria-labelledby on `Dropzone`
        multiple: PropTypes.bool,
        onDrop: PropTypes.func,
        onDropAccepted: PropTypes.func,
        onDropRejected: PropTypes.func,
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]), // custom prop - specifies custom text to display in dropzone
        useCustomDropValidation: PropTypes.bool, // custom prop - used to bypass default drop error handling
    };

    static defaultProps = {
        defaultRejectMessage: 'File could not be uploaded',
        icons: ['file-pdf-o', 'file-photo-o', 'file-word-o'],
        text: 'Drop some files here or click to select files to upload.',
        useCustomDropValidation: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            error: null,
        };
    }

    onDropAccepted = (...args) => {
        const { onDropAccepted, useCustomDropValidation } = this.props;

        // Clear any errors unless using custom validation
        if (!useCustomDropValidation) {
            this.setState({ error: null });
        }

        if (onDropAccepted) {
            onDropAccepted(...args);
        }
    };

    onDropRejected = (...args) => {
        const { defaultRejectMessage, multiple, onDropRejected, useCustomDropValidation } =
            this.props;

        const [fileRejections] = args;

        let error = defaultRejectMessage;

        // Handle case where multiple files are uploaded in a dropzone where multiple not allowed
        // NOTE: `multiple` is true by default
        if (!multiple && fileRejections && fileRejections.length > 1) {
            error = 'Please upload a single file at a time';
        }

        // Set an error message unless using custom validation
        if (!useCustomDropValidation) {
            if (fileRejections.length > 0) {
                error = fileRejections;
            }

            this.setState({ error });
        }

        if (onDropRejected) {
            onDropRejected(...args);
        }
    };

    render() {
        const {
            accept,
            children,
            className,
            dropzoneClassName,
            dropzoneContainerClassName,
            isDragActiveClassName,
            icons,
            label,
            labelId,
            onDrop,
            text,
            ...props
        } = this.props;

        const { dropBox, dropIcons, dropText, labelContainer } = require('./index.scss');

        const fileIcons = icons.map((icon) => <i className={`fa fa-lg fa-${icon}`} key={icon} />);

        const { error } = this.state;

        const dropzoneID = (label || labelId)?.replace(/ /g, '');

        return (
            <div className={className}>
                <Label className={labelContainer} htmlFor={dropzoneID}>
                    {label && <span>{label}</span>}
                </Label>

                <Dropzone
                    {...props}
                    accept={accept}
                    className={classnames(dropBox, { [dropzoneClassName]: !!dropzoneClassName })}
                    containerClassName={dropzoneContainerClassName}
                    dropzoneID={dropzoneID}
                    fileRejectionErrors={error}
                    isDragActiveClassName={isDragActiveClassName}
                    onDrop={onDrop}
                    onDropAccepted={this.onDropAccepted}
                    onDropRejected={this.onDropRejected}
                >
                    {!children ? (
                        <div className="text-center">
                            <div className={dropText}>{text}</div>
                            <div className={dropIcons}>{fileIcons}</div>
                        </div>
                    ) : (
                        children
                    )}
                </Dropzone>
            </div>
        );
    }
}
