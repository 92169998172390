import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import { ApproveForm } from '../ApproveForm/ApproveForm';

export class ApproveModalBody extends PureComponent {
    static propTypes = {
        approved: PropTypes.bool.isRequired,
        note: PropTypes.string,
        submitError: PropTypes.string,
        approveHandler: PropTypes.func.isRequired,
        hideModal: PropTypes.func.isRequired,
        title: PropTypes.string,
    };

    renderApproveSuccess() {
        const { hideModal, title } = this.props;
        const styles = require('../ApprovalsModal.scss');

        return (
            <div className="text-center text-success">
                <i className="fa fa-3x fa-check-square-o" />
                <h3>Approved!</h3>
                <div className={styles.confirmationInfo}>
                    Thank you for reviewing this {title.toLowerCase()} :)
                </div>
                <Button onClick={hideModal}>Close</Button>
            </div>
        );
    }

    render() {
        const { approveHandler, approved, note, hideModal, submitError, title } = this.props;

        return approved ? (
            this.renderApproveSuccess()
        ) : (
            <ApproveForm
                hideModal={hideModal}
                initialValues={{ comment: note }}
                onSubmit={approveHandler}
                submitError={submitError}
                title={title}
            />
        );
    }
}
