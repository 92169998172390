import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Panel } from 'react-bootstrap';

import { AgGridReactLegacy } from '../AgGridReactLegacy';
import { AgGridReactPanelHeading } from '../AgGridReactPanelHeading';
import { Button } from '../Button';
import { currencyFormatter } from '../../helpers';

export class VendorBidTabulationTotalsTable extends PureComponent {
    static propTypes = {
        auctionMaxFractionDigits: PropTypes.number,
        sortAscending: PropTypes.bool.isRequired,
        toggleSortAscending: PropTypes.func.isRequired,
        vendors: PropTypes.arrayOf(
            PropTypes.shape({
                city: PropTypes.string,
                proposalId: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                state: PropTypes.string,
                total: PropTypes.shape({
                    value: PropTypes.number.isRequired,
                    noBid: PropTypes.bool.isRequired,
                }),
            })
        ).isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {};
    }

    get styles() {
        return require('./index.scss');
    }

    generateColDef = (vendor, i) => {
        const { auctionMaxFractionDigits } = this.props;
        const headerTooltip =
            vendor.city && vendor.state
                ? `${vendor.city}, ${vendor.state}`
                : 'No city or state provided';
        const currencyFormatterOpts = auctionMaxFractionDigits
            ? { maximumFractionDigits: auctionMaxFractionDigits, useSameMinAndMax: true }
            : { use4FractionDigits: true };
        return {
            autoHeight: true,
            // Ag-Grid doesn't like non-string keys
            cellClass: ['4FractionCurrency'], // Used exclusively for Excel export styles
            field: vendor.proposalId.toString(),
            headerName: `#${i + 1} ${vendor.name}`,
            headerTooltip,
            sortable: false,
            suppressColumnsToolPanel: true,
            suppressMenu: true,
            suppressMovable: true,
            valueFormatter: (params) => currencyFormatter(params, currencyFormatterOpts),
        };
    };

    generateColDefs() {
        return this.props.vendors.map(this.generateColDef);
    }

    getRowData() {
        return this.props.vendors.reduce(
            (rows, vendor) => {
                rows[0].rowId = 'vendorTotals';
                rows[0][vendor.proposalId] = vendor.total.noBid ? 'No Bid' : vendor.total.value;
                return rows;
            },
            [{}]
        );
    }

    getRowNodeId(row) {
        return row.rowId;
    }

    handleGridReady = (params) => {
        this.setState({ gridApi: params.api });
    };

    /**
     * Callback for transforming header data when it is being exported to Excel.
     *
     * @param {object} params The `processCellCallback` callback params
     * @return {any} The value of the header, transformed if necessary
     */
    handleProcessHeader = (params) => {
        // NOTE: this is a patch around https://github.com/ag-grid/ag-grid/issues/2742
        return params.column.colDef.headerName.replace(/&+/g, 'and');
    };

    renderTotalsSortButton = () => {
        const { toggleSortAscending, sortAscending } = this.props;

        return [
            <Button
                id="total-sort-toggle"
                key="total-sort-toggle"
                onClick={toggleSortAscending}
                tooltip={
                    sortAscending
                        ? 'Click to sort totals highest to lowest'
                        : 'Click to sort totals lowest to highest'
                }
            >
                <i
                    className={sortAscending ? 'fa fa-sort-numeric-desc' : 'fa fa-sort-numeric-asc'}
                />
                &nbsp;{sortAscending ? 'Sort By Highest' : 'Sort By Lowest'}
            </Button>,
        ];
    };

    render() {
        const { gridApi } = this.state;

        return (
            <Panel className={this.styles.panel}>
                <Panel.Body className={this.styles.panelBody}>
                    <AgGridReactPanelHeading
                        buttons={this.renderTotalsSortButton()}
                        gridApi={gridApi}
                        processHeaderCallback={this.handleProcessHeader}
                        title="Selected Vendor Totals"
                    />
                    <div className={this.styles.gridWrapper}>
                        <AgGridReactLegacy
                            applyColumnDefOrder
                            columns={this.generateColDefs()}
                            domLayout="autoHeight"
                            getRowNodeId={this.getRowNodeId}
                            hideSideBar
                            onGridReady={this.handleGridReady}
                            rowStyle={{ border: 'none' }}
                            rows={this.getRowData()}
                        />
                    </div>
                </Panel.Body>
            </Panel>
        );
    }
}
