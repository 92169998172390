import React from 'react';
import { Field, reduxForm } from 'redux-form';

import { form, options, VIEW } from './constants';
import { SearchSelect } from '../../SearchSelect/SearchSelect';

const formConfig = {
    enableReinitialize: true,
    form,
};

const ConnectedChecklistViewSelectForm = () => {
    const styles = require('./index.scss');

    return (
        <div>
            <label className={styles.label} htmlFor="searchSelect">
                View
            </label>
            <Field
                blurInputOnSelect
                component={SearchSelect}
                formClassName={styles.selectFormClass}
                id="searchSelect"
                isSearchable={false}
                name={VIEW}
                options={options}
                overrideFeedback
            />
        </div>
    );
};

export const ChecklistViewSelectForm = reduxForm(formConfig)(ConnectedChecklistViewSelectForm);
