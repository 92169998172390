import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Panel } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { Outlet } from 'react-router-dom';
import { compose } from 'redux';
import { withFlags, FLAGS } from '@og-pro/launch-darkly/client';

import { CLEANUP_DOCUMENTS, EXPIRED, RECENTLY_CLOSED } from '../constants';
import {
    getCleanupDocumentsJS,
    getExpiredJS,
    getNavItems,
    getRecentlyClosedJS,
} from '../selectors';
import { getUserJS } from '../../../selectors';
import { Button, UnderlineNav } from '../../../../components';
import {
    loadCleanupDocuments,
    loadExpired,
    loadRecentlyClosed,
} from '../../../../actions/projects';
import { loadGovernmentRetentionCodes, loadDeletionHistory } from '../../../../actions/retention';
import { exportArrayToCSV } from '../../../../utils';
import { NoDeletionHistoryModal } from './NoDeletionHistoryModal';
import { showSnackbar } from '../../../../actions/notification';

const mapStateToProps = (state, props) => {
    return {
        cleanupDocuments: getCleanupDocumentsJS(state),
        expired: getExpiredJS(state),
        navItems: getNavItems(state, props),
        recentlyClosed: getRecentlyClosedJS(state),
        user: getUserJS(state),
    };
};

const ConnectedRecordsRetentionNav = (props) => {
    const dispatch = useDispatch();
    const showEnhancements = props.getFlag(FLAGS.RECORDS_RETENTION_ENHANCEMENTS);
    const [showNoDeletionHistoryModal, setShowNoDeletionHistoryModal] = useState(false);

    const { location, navItems, user } = props;

    // These are used indirectly by accessing `props[status]`
    // Added these so the PropType linter doesn't complain
    // eslint-disable-next-line no-unused-vars
    const { cleanupDocuments, expired, recentlyClosed } = props;

    useEffect(() => {
        dispatch(loadCleanupDocuments());

        dispatch(loadExpired());

        dispatch(loadRecentlyClosed());

        dispatch(
            loadGovernmentRetentionCodes({
                governmentId: user.government_id,
            })
        );
    }, [dispatch, user]);

    const routeIsManage = location.pathname.includes('manage');

    let activeTab;
    if (location.pathname.includes('documents')) {
        activeTab = CLEANUP_DOCUMENTS;
    } else if (location.pathname.includes('expired')) {
        activeTab = EXPIRED;
    } else {
        activeTab = RECENTLY_CLOSED;
    }

    const styles = require('./index.scss');

    const exportDeletionHistory = () => {
        dispatch(
            loadDeletionHistory({
                onSuccess: ({ result }) => {
                    if (result.length > 0) {
                        exportArrayToCSV(result, {
                            fileName: 'record_deletion_history_export',
                            headers: true,
                            timestamp: true,
                        });
                    } else {
                        // If no deletion history is available, show a modal to the user
                        setShowNoDeletionHistoryModal(true);
                    }
                },
                onFailure: () => {
                    dispatch(showSnackbar('Error loading deletion history', { isError: true }));
                },
            })
        );
    };

    const renderHeader = () => (
        <div className={styles.heading}>
            <div className={styles.title}>Records Retention Administration</div>
            <div className={styles.buttonContainer}>
                {showEnhancements ? (
                    <Button
                        onClick={exportDeletionHistory}
                        qaTag="recordsRetention-exportDeletionHistory"
                    >
                        <i className="fa fa-download" /> Export Deletion History
                    </Button>
                ) : null}
                <Button
                    className={styles.policyButton}
                    qaTag="recordsRetention-managePolicies"
                    to={`/governments/${user.government_id}/retention-admin/manage`}
                >
                    <i className="fa fa-gear" /> Manage Policies
                </Button>
            </div>
        </div>
    );
    const renderNavToManage = () => {
        return (
            <div>
                <Button
                    bsStyle="link"
                    className={styles.backButton}
                    qaTag="recordsRetention-back"
                    to={`/governments/${user.government_id}/retention-admin/documents`}
                >
                    <i className={`fa fa-angle-left fa-lg ${styles.backArrow}`} />
                    Back to Records Retention Administration
                </Button>
                <div className={styles.heading}>
                    <div className={styles.title}>Manage Policies</div>
                </div>
            </div>
        );
    };
    const renderNavItems = () =>
        navItems.map((item) => {
            const { name, status, to } = item;
            const count = props[status] ? props[status].length : 0;

            return (
                <UnderlineNav.NavItem
                    className={classNames({
                        active: status === activeTab,
                    })}
                    key={status}
                    to={`/governments/${user.government_id}/retention-admin${to}`}
                >
                    {name}&nbsp;
                    <span className={styles.counter}>({count})</span>
                </UnderlineNav.NavItem>
            );
        });

    return (
        <div className={styles.panel}>
            <NoDeletionHistoryModal
                onHide={() => setShowNoDeletionHistoryModal(false)}
                show={showNoDeletionHistoryModal}
            />
            <Panel className={showEnhancements ? 'col-xs-11' : 'col-xs-10'}>
                <Panel.Body className={styles.container}>
                    {routeIsManage ? renderNavToManage() : renderHeader()}
                    <div className={styles.nav}>
                        <UnderlineNav>{!routeIsManage && renderNavItems()}</UnderlineNav>
                    </div>
                    <Outlet />
                </Panel.Body>
            </Panel>
        </div>
    );
};

ConnectedRecordsRetentionNav.propTypes = {
    cleanupDocuments: PropTypes.array.isRequired,
    expired: PropTypes.array.isRequired,
    getFlag: PropTypes.func.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }),
    navItems: PropTypes.array.isRequired,
    recentlyClosed: PropTypes.array.isRequired,
    user: PropTypes.shape({
        government_id: PropTypes.number.isRequired,
    }).isRequired,
};

export const RecordsRetentionNav = compose(
    withFlags(),
    withRouter,
    connect(mapStateToProps)
)(ConnectedRecordsRetentionNav);
