import { FLAGS, useFlags } from '@og-pro/launch-darkly/client';
import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { RequestTypeHeader } from './RequestTypeHeader';
import { RequestTypeActionsProvider } from '../RequestTypeActionsContext';
import connectData from '../../../../ConnectData';
import { loadRequestType } from '../../../../../actions/requestTypes';
import { LoadingError, LoadingSpinner, Main, NotFound, PageTitle } from '../../../../../components';
import { getRequestType } from '../../../../../selectors/govApp';
import { withRequestTypeForm } from '../RequestTypeForm/RequestTypeFormHOC';

const fetchData = (getState, dispatch, location, params) => {
    const requestTypeId = Number.parseInt(params.requestTypeId, 10);
    return dispatch(loadRequestType(requestTypeId));
};

// Even if the Request Type form is not displayed on other subtabs of the RequestTypeNav,
// the Header with the Save button is still shown,
// so we need to persist local state across the subtabs
const WrappedOutlet = withRequestTypeForm()(Outlet);

const ConnectedRequestTypeNav = () => {
    const requestType = useSelector(getRequestType);
    const isLoading = useSelector((state) => state.requestTypes.get('loadingItem'));
    const loadingError = useSelector((state) => state.requestTypes.get('loadItemError'));

    const enableCustomFields = useFlags(FLAGS.ENABLE_CUSTOM_FIELDS);

    // Avoid user access to this page if the feature is disabled
    if (!enableCustomFields) {
        return <NotFound />;
    }

    if (isLoading) {
        return <LoadingSpinner useOpenGovStyle />;
    }

    if (loadingError) {
        return <LoadingError error={loadingError} useOpenGovStyle />;
    }

    return (
        <Main>
            <RequestTypeActionsProvider requestType={requestType}>
                <PageTitle title="Request Type Editor" />
                <RequestTypeHeader requestType={requestType} />
                <WrappedOutlet />
            </RequestTypeActionsProvider>
        </Main>
    );
};

export const RequestTypeNav = connectData(fetchData)(ConnectedRequestTypeNav);
