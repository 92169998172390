import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '../../Button';

export const ResolvedToggle = ({ clickHandler, count, showResolved }) => {
    const commentCount = count && count > 0 ? `${count} ` : '';
    const buttonText = showResolved
        ? `Hide ${commentCount}Resolved Comments`
        : `View ${commentCount}Resolved Comments`;

    return (
        <Button bsStyle="link" onClick={clickHandler} qaTag="resolvedToggle" zeroPadding>
            {buttonText}
        </Button>
    );
};

ResolvedToggle.propTypes = {
    clickHandler: PropTypes.func.isRequired,
    count: PropTypes.number,
    showResolved: PropTypes.bool,
};
