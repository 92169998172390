import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { ProjectOverview } from '../ProjectOverview/ProjectOverview';
import { ProposalDocuments } from '../ProposalDocuments/ProposalDocuments';
import { EvaluationCriteria } from '../EvaluationCriteria';
import { mapStateToProps as evaluationCreateMapStateToProps } from '../mapProps';
import { extendSimpleWizardForm } from '../../../../hocs';

const mapStateToProps = (state) => {
    return {
        ...evaluationCreateMapStateToProps(state),
        // Set to true so form will be loaded without regard for form initialization
        // The subcomponent will do the check for form initialization
        initialized: true,
    };
};

/**
 * Component simply acts to route between the initial form to render as part
 * of the evaluation create flow
 */

// @connect
// @extendSimpleWizardForm
class ConnectedEvaluationCreateIndex extends Component {
    static propTypes = {
        hasQuestionnaire: PropTypes.bool.isRequired,
        project: PropTypes.object.isRequired,
    };

    render() {
        if (this.props.project.isEvaluationOnly) {
            return <ProjectOverview {...this.props} />;
        }
        if (this.props.hasQuestionnaire) {
            return <EvaluationCriteria {...this.props} />;
        }
        return <ProposalDocuments {...this.props} />;
    }
}

export const EvaluationCreateIndex = compose(
    connect(mapStateToProps),
    extendSimpleWizardForm()
)(ConnectedEvaluationCreateIndex);
