import { AccountNumberInput as CdsAccountNumberInput } from '@opengov/components-account-number-input';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { useAccountNumberLookup } from './useAccountNumberLookup';
import { HelpBlock, Label } from '../../components';

export const fmsAccountComponentClass = 'fms-account-component';

export const AccountNumberInput = (props) => {
    const styles = require('./AccountNumberInput.scss');

    const {
        coaConfigLookupData,
        disabled,
        error,
        helpIcon,
        helpIconClick,
        helpIconNode = 'Look for accounts in your financial management system',
        label,
        input,
        isExpenseAccount = true,
        onAccountChange,
        inputKey,
        accountCompressedFormatted,
        accountDescription,
        onClear,
        showValidation,
    } = props;
    const [partialAccountNumber, setPartialAccountNumber] = useState({});

    const accountNumberValRef = useRef(accountCompressedFormatted || '');

    const {
        data: accountNumberLookupData,
        isError: hasErrorLoadingAccountLookupData,
        error: accountNumberLookupError,
        isRefetching,
    } = useAccountNumberLookup(partialAccountNumber);

    const handleChange = (value) => {
        input.onChange(value.account_compressed);
        setPartialAccountNumber({ ...value, placeholderData: accountNumberLookupData });
    };

    const handleOnClear = () => {
        input.onChange('');
        setPartialAccountNumber({});
        if (typeof onClear === 'function') {
            onClear();
        }
    };

    useEffect(() => {
        if (accountNumberLookupError) {
            onClear?.();
        }
    }, [accountNumberLookupError]);

    useEffect(() => {
        if (accountNumberLookupData?.searchDistinctAccountSegments[0]?.account_compressed) {
            onAccountChange(accountNumberLookupData?.searchDistinctAccountSegments[0]);
        }
    }, [accountNumberLookupData?.searchDistinctAccountSegments]);

    const renderAccountNumberInputInput = () => {
        const data = hasErrorLoadingAccountLookupData
            ? accountNumberLookupError.response.errors[0].message
            : accountNumberLookupData?.searchDistinctAccountSegments;

        if (data && coaConfigLookupData && coaConfigLookupData.glCoaSettings.length === 0) {
            return (
                <HelpBlock className={styles.formError}>
                    <i className="fa fa-exclamation-triangle" />
                    There was an error loading your settings. Please contact your Finance
                    administrator.
                </HelpBlock>
            );
        }

        // Render account number input when accountNumberLookup and coaConfigLookup have loaded
        if (data && coaConfigLookupData?.glCoaSettings?.length > 0) {
            return (
                <CdsAccountNumberInput
                    {...input}
                    accountNumberVal={accountNumberValRef.current}
                    className={classNames(fmsAccountComponentClass, {
                        [styles.inputError]: error && showValidation,
                        'has-error': error && showValidation,
                    })}
                    coaConfig={coaConfigLookupData.glCoaSettings}
                    data={data}
                    inputId={`account-number-${inputKey}`}
                    onChange={handleChange}
                    onClear={handleOnClear}
                    readonly={disabled}
                    showInfoIcon={false}
                    showLabel={false}
                    showSearchIcon={false}
                />
            );
        }

        // Render a fake input with the current account data when there is an existing value and
        // accountNumberLookup or coaConfigLookup are still loading
        if (input.value) {
            return (
                <input
                    {...input}
                    className={styles.cachedAccountNumber}
                    disabled
                    value={accountCompressedFormatted}
                />
            );
        }

        // Render loading state (accountNumberLookup or coaConfigLookup are still loading)
        return (
            <div className={styles.loadingAccountData}>
                <i className="fa fa-fw fa-spinner fa-spin" /> Loading account number data...
            </div>
        );
    };

    function renderAccountInformation() {
        const accountSegments = accountNumberLookupData?.searchDistinctAccountSegments;

        if (accountSegments?.length === 1 && accountSegments[0].description) {
            return (
                <div className={styles.accountDescription}>{accountSegments[0].description}</div>
            );
        }

        if (isRefetching && !accountDescription) {
            return (
                <div className={styles.accountDescription}>
                    <i className="fa fa-spin fa-spinner" /> Loading
                </div>
            );
        }

        if (accountDescription) {
            return <div className={styles.accountDescription}>{accountDescription}</div>;
        }
    }

    function renderNonExpenseAccountInformation() {
        if (isExpenseAccount) {
            return null;
        }

        return (
            <HelpBlock
                className={classNames(styles.formError, styles.nonExpenseAccountInformation)}
            >
                <i className="fa fa-exclamation-triangle" />
                Budget check is not available because this is not an expense account.
            </HelpBlock>
        );
    }

    function renderErrorBlock() {
        // errors will come from the AccountNumberInput component with a partial account number
        return (
            error &&
            showValidation &&
            !hasErrorLoadingAccountLookupData && // Don't show additional errors if there are errors loading account lookup data
            !partialAccountNumber.account_compressed_partial && (
                <HelpBlock className={styles.formError}>
                    <i className="fa fa-exclamation-triangle" />
                    {error}
                </HelpBlock>
            )
        );
    }

    return (
        <div className={styles.accountNumberInput}>
            <Label
                helpIcon={helpIcon}
                helpIconClick={helpIconClick}
                helpIconNode={helpIconNode}
                label={label}
            />
            {renderAccountNumberInputInput()}
            {renderAccountInformation()}
            {renderNonExpenseAccountInformation()}
            {renderErrorBlock()}
        </div>
    );
};

AccountNumberInput.propTypes = {
    coaConfigLookupData: PropTypes.shape({
        glCoaSettings: PropTypes.array.isRequired,
    }),
    disabled: PropTypes.bool,
    error: PropTypes.string,
    input: PropTypes.shape({
        onChange: PropTypes.func.isRequired,
        value: PropTypes.string.isRequired,
    }).isRequired,
    helpIcon: PropTypes.bool,
    helpIconClick: PropTypes.func,
    helpIconNode: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    label: PropTypes.string,
    inputKey: PropTypes.string.isRequired,
    isExpenseAccount: PropTypes.bool,
    onAccountChange: PropTypes.func.isRequired,
    accountCompressedFormatted: PropTypes.string,
    accountDescription: PropTypes.string,
    onClear: PropTypes.func,
    showValidation: PropTypes.bool,
};
