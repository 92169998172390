import PropTypes from 'prop-types';
import React from 'react';

import { Add as AddIcon } from '@mui/icons-material';
import { Button } from '@og-pro/ui';

export const FlagsCreateButton = ({ onClick }) => {
    return (
        <Button
            color="primary"
            onClick={onClick}
            qaTag="flagAdmin-create"
            startIcon={<AddIcon />}
            variant="contained"
        >
            Add Flag
        </Button>
    );
};

FlagsCreateButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};
