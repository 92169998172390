import classnames from 'classnames';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { proposalEvaluationStatuses } from '@og-pro/shared-config/proposalEvaluations';

import { VendorCell } from './VendorCell';
import { ReportDocxTable } from '../ReportDocxTable';
import { DataTable } from '../DataTable/DataTable';
import { CriteriaScoreCell, CriteriaScoreDocx, ScoringCriteriaCell } from '../EvaluationTableCells';
import { formatCSVExportScorePercentage, roundNumber } from '../../helpers';

const { COMPLETE } = proposalEvaluationStatuses;

const MAX_ROWS = 20;
const MINIMUM_OVERFLOW_ROWS_FOR_PAGINATION = 2;

export class EvaluatorScoresTable extends Component {
    static propTypes = {
        className: PropTypes.string,
        createRef: PropTypes.func,
        evaluator: PropTypes.shape({
            displayName: PropTypes.string.isRequired,
        }),
        isConsensusView: PropTypes.bool,
        isDocx: PropTypes.bool,
        proposals: PropTypes.arrayOf(
            PropTypes.shape({
                note: PropTypes.string,
                proposalEvaluation: PropTypes.shape({
                    submittedProposalCriteriaScores: PropTypes.arrayOf(
                        PropTypes.shape({
                            comment: PropTypes.string,
                            score: PropTypes.number,
                        })
                    ).isRequired,
                }).isRequired,
                totalScore: PropTypes.number,
                totalScoreByPoints: PropTypes.number,
            })
        ).isRequired,
        scoringCriteria: PropTypes.array.isRequired,
        showComments: PropTypes.bool,
        showCriteriaDescriptions: PropTypes.bool,
        showEvaluationScorecard: PropTypes.func,
        showPercentageTotals: PropTypes.bool,
        showUnsubmittedScores: PropTypes.bool,
        totalWeight: PropTypes.number.isRequired,
        useLandscape: PropTypes.bool,
    };

    get styles() {
        return require('./index.scss');
    }

    totalScoringCriteriaData = {
        maxScore: 100,
        title: 'Total Score',
    };

    isUnsubmittedScore = (proposal) => {
        const { showUnsubmittedScores } = this.props;

        return !!showUnsubmittedScores && get(proposal, 'proposalEvaluation.status') !== COMPLETE;
    };

    render() {
        const {
            className,
            createRef,
            evaluator,
            isConsensusView,
            isDocx,
            proposals,
            scoringCriteria,
            showComments,
            showCriteriaDescriptions,
            showEvaluationScorecard,
            showPercentageTotals,
            totalWeight,
            useLandscape,
        } = this.props;

        const excludedLabelStyle = {
            backgroundColor: '#777',
            fontWeight: 'bold',
            color: '#fff',
            textAlign: 'center',
        };

        const vendorColumn = {
            Header: (
                <div className="data-table-text-wrap">
                    <strong>Vendor</strong>
                    {showEvaluationScorecard && (
                        <em>
                            <br />
                            Click row to open scorecard
                        </em>
                    )}
                </div>
            ),
            id: 'vendor-name-column',
            accessor: (row) => row.companyName || 'Unnamed Company',
            Cell: (props) => (
                <VendorCell
                    isUnsubmittedScorecard={this.isUnsubmittedScore(props.original)}
                    proposal={props.original}
                    value={props.value}
                />
            ),
            docxHtml: ({ datum, value }) => (
                <div>
                    {value}
                    {datum.isExcluded && <div style={excludedLabelStyle}>Excluded</div>}
                </div>
            ), // Used for docx table
        };

        const criteriaColumns = scoringCriteria.map((scoringCriterium, idx) => ({
            Header: (
                <ScoringCriteriaCell
                    scoringCriteria={scoringCriterium}
                    separateLines
                    showDescription={showCriteriaDescriptions}
                    totalWeight={totalWeight}
                />
            ),
            id: `scoring-criteria-column-${idx}`,
            accessor: (row) => {
                return this.isUnsubmittedScore(row)
                    ? get(row, `proposalEvaluation.orderedProposalCriteriaScores[${idx}].score`)
                    : get(row, `proposalEvaluation.submittedProposalCriteriaScores[${idx}].score`);
            },
            Cell: (props) => (
                <CriteriaScoreCell
                    comment={get(
                        props,
                        `original.proposalEvaluation.submittedProposalCriteriaScores[${idx}].comment`
                    )}
                    isUnsubmittedScore={this.isUnsubmittedScore(props.original)}
                    scoringCriteria={scoringCriterium}
                    showComments={showComments}
                    value={props.value}
                />
            ),
            // eslint-disable-next-line react/prop-types
            DocxCell: ({ value }) => (
                <CriteriaScoreDocx
                    criteriaScore={roundNumber(value, 2)}
                    scoringCriteria={scoringCriterium}
                />
            ), // Used for docx table
        }));

        const totalColumn = {
            Header: (
                <div className="data-table-text-wrap">
                    <strong>Total Score</strong>
                    {!showPercentageTotals && (
                        <strong>
                            <br />
                            (Max Score {totalWeight})
                        </strong>
                    )}
                </div>
            ),
            accessor: showPercentageTotals
                ? 'proposalEvaluation.totalScore'
                : 'proposalEvaluation.totalScoreByPoints',
            Cell: (props) => (
                <CriteriaScoreCell
                    comment={get(props, 'original.proposalEvaluation.note')}
                    isTotal
                    showComments={showComments}
                    showPercentageTotals={showPercentageTotals}
                    totalScorePercentage={get(props, 'original.proposalEvaluation.totalScore')}
                    value={props.value}
                />
            ),
            // eslint-disable-next-line react/prop-types
            DocxCell: ({ datum, value }) => (
                <CriteriaScoreDocx
                    criteriaScore={roundNumber(value, 1)}
                    isTotal
                    scoringCriteria={this.totalScoringCriteriaData}
                    showPercentageTotals={showPercentageTotals}
                    style={{ fontWeight: 'bold' }}
                    totalScorePercentage={get(datum, 'proposalEvaluation.totalScore')}
                />
            ), // Used for docx table
        };

        const columns = [vendorColumn, ...criteriaColumns, totalColumn];

        const usePagination = proposals.length > MAX_ROWS + MINIMUM_OVERFLOW_ROWS_FOR_PAGINATION;

        const formatDataForCSVExport = (data) => {
            const headers = [
                'Vendor',
                ...scoringCriteria.map((criteria) => criteria.title),
                'Total Score',
            ];

            const rows = data.map((dataRow) => {
                return [
                    dataRow[vendorColumn.id],
                    ...criteriaColumns.map((column) => dataRow[column.id]),
                    formatCSVExportScorePercentage(
                        dataRow[totalColumn.accessor],
                        showPercentageTotals
                    ),
                ];
            });

            if (showComments) {
                headers.push(...scoringCriteria.map((criteria) => `${criteria.title} Comments`));
                rows.forEach((row, rowIndex) => {
                    const dataRow = data[rowIndex];
                    const commentData = criteriaColumns.map((col, colIndex) => {
                        return (
                            get(
                                dataRow,
                                `_original.proposalEvaluation.submittedProposalCriteriaScores[${colIndex}].comment`
                            ) || ''
                        );
                    });
                    row.push(...commentData);
                });
            }

            return [headers].concat(rows);
        };

        if (isDocx) {
            return (
                <ReportDocxTable columns={columns} data={proposals} useLandscape={useLandscape} />
            );
        }

        return (
            <DataTable
                className={classnames('-striped', '-highlight', className)}
                columns={columns}
                csvExportOptions={{
                    fileName: isConsensusView
                        ? 'Consensus Scorecard'
                        : `${evaluator.displayName}'s Evaluations`,
                    getFormattedCSVData: formatDataForCSVExport,
                    headers: true,
                }}
                data={proposals}
                defaultPageSize={usePagination ? MAX_ROWS : proposals.length}
                getTrProps={(state, rowInfo) => {
                    // Only add table row props when evaluation scorecard can be shown
                    if (!rowInfo || !showEvaluationScorecard || rowInfo.original.isExcluded) {
                        return {};
                    }
                    return {
                        className: this.styles.vendorRow,
                        onClick: showEvaluationScorecard(rowInfo.original),
                    };
                }}
                ref={createRef}
                showPagination={usePagination}
            />
        );
    }
}
