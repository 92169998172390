import { List as ListIcon, Search as SearchIcon } from '@mui/icons-material';

import Icon from '@mdi/react';
import { mdiArchive } from '@mdi/js';

import { Box, Button, InputAdornment, TextField } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React from 'react';

import { RequestTypeCreateButton } from './RequestTypeCreateButton';
import { qaTagName } from '../constants';

export const RequestTypesMenu = ({ setSearchValue, setShowArchived, showArchived }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: {
                    xs: 'flex-start',
                    sm: 'center',
                },
                flexDirection: {
                    xs: 'column',
                    sm: 'row',
                },
                gap: {
                    xs: 1,
                    sm: 0,
                },
            }}
        >
            <TextField
                InputProps={{
                    startAdornment: (
                        <InputAdornment aria-label="search icon" position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
                aria-label="Search Request Types"
                onChange={(e) => setSearchValue(e.target.value)}
                placeholder="Search by Name"
                sx={{
                    margin: 0,
                    padding: 0,
                    '& .MuiInputBase-root': {
                        margin: 0,
                    },
                    minWidth: '40%',
                    width: {
                        xs: '100%',
                        sm: 'auto',
                    },
                }}
                type="search"
            />
            <Box
                sx={{
                    display: 'flex',
                    gap: 1,
                    flexDirection: {
                        xs: 'column',
                        sm: 'row',
                    },
                }}
            >
                <Button
                    color="secondary"
                    onClick={() => setShowArchived(!showArchived)}
                    qaTag={`${qaTagName}-archive`}
                    startIcon={showArchived ? <ListIcon /> : <Icon path={mdiArchive} size={1} />}
                    sx={{ marginRight: 1 }}
                    variant="text"
                >
                    View {showArchived ? 'Active' : 'Archived'}
                </Button>
                <RequestTypeCreateButton />
            </Box>
        </Box>
    );
};

RequestTypesMenu.propTypes = {
    setSearchValue: PropTypes.func.isRequired,
    setShowArchived: PropTypes.func.isRequired,
    showArchived: PropTypes.bool.isRequired,
};
