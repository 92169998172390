import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { reduxForm, Field } from 'redux-form';

import { form, fieldNames } from './constants';
import { validate } from './validate';
import { QuestionLogo } from '../QuestionLogo';
import { Button } from '../../../../Button';
import { InputText } from '../../../../InputText';
import { OutlineButton } from '../../../../OutlineButton';

const { SUBJECT, DESCRIPTION } = fieldNames;

const formConfig = {
    form,
    validate,
};

// @reduxForm
class ConnectedEditQuestionForm extends PureComponent {
    static propTypes = {
        canRelease: PropTypes.bool,
        cancelHandler: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        handleSubmit: PropTypes.func.isRequired,
        image: PropTypes.string.isRequired,
        organization: PropTypes.string,
        rejectQuestionHandler: PropTypes.func.isRequired,
        submitError: PropTypes.string,
    };

    get styles() {
        return require('../shared.scss');
    }

    render() {
        const {
            canRelease,
            cancelHandler,
            disabled,
            handleSubmit,
            image,
            organization,
            rejectQuestionHandler,
            submitError,
        } = this.props;

        return (
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <QuestionLogo entityName={organization} imageSrc={image} />
                    <div className={`col-xs-12 ${this.styles.commentContainer}`}>
                        <Field
                            component={InputText}
                            disabled={disabled}
                            hasFeedback={false}
                            label="Subject (optional)"
                            name={SUBJECT}
                            placeholder="Add a subject (optional)"
                            qaTag="connectedEditQuestionForm-subject"
                            type="text"
                        />
                        <Field
                            component={InputText}
                            disabled={disabled}
                            hasFeedback={false}
                            label="Question"
                            minRows={2}
                            name={DESCRIPTION}
                            placeholder="Enter question"
                            qaTag="connectedEditQuestionForm-question"
                            type="textarea"
                        />
                        {submitError && <p className="text-danger">{submitError}</p>}
                        <div className="text-right">
                            <Button
                                bsSize="sm"
                                bsStyle="link"
                                disabled={disabled}
                                onClick={cancelHandler}
                                qaTag="connectedEditQuestionForm-cancel"
                            >
                                Cancel
                            </Button>
                            <OutlineButton
                                bsSize="sm"
                                bsStyle="danger"
                                disabled={!canRelease || disabled}
                                onClick={rejectQuestionHandler}
                                tooltip={
                                    canRelease
                                        ? undefined
                                        : 'Only sourcing editors and admins can exclude questions'
                                }
                            >
                                <i className="fa fa-ban" /> Exclude
                            </OutlineButton>
                            &nbsp;&nbsp;
                            <Button
                                bsSize="sm"
                                bsStyle="primary"
                                disabled={disabled}
                                qaTag="connectedEditQuestionForm-update"
                                type="submit"
                            >
                                <i className="fa fa-pencil" /> Update Question
                            </Button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

export const EditQuestionForm = reduxForm(formConfig)(ConnectedEditQuestionForm);
