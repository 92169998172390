import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';

import { Well } from '../../../Well/Well';

export const NoBidWell = ({ proposal: { noBidReason, submittedAt } }) => {
    const styles = require('./shared.scss');

    return (
        <Well>
            <h4 className="text-center text-warning">
                <i className="fa fa-ban" /> No Bid
            </h4>
            <p className={styles.proposalSubmittedInfo}>
                &quot;No Bid&quot; submitted on {moment(submittedAt).format('lll')} for the
                following reason:
                <br />
                <em>{noBidReason}</em>
            </p>
        </Well>
    );
};

NoBidWell.propTypes = {
    proposal: PropTypes.shape({
        noBidReason: PropTypes.string.isRequired,
        submittedAt: PropTypes.instanceOf(Date).isRequired,
    }).isRequired,
};
