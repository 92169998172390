import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { AddendumDiffFormModal } from '../AddendumDiffFormModal';
import { Button } from '../../../Button';
import { OutlineButton } from '../../../OutlineButton';

export class AddendumDiffField extends PureComponent {
    static propTypes = {
        input: PropTypes.shape({
            onChange: PropTypes.func.isRequired,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        }).isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            showAddendumDiffModal: false,
            showAddendumDiffForm: false,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    submitHandler = (data) => {
        const {
            input: { onChange },
        } = this.props;

        onChange(data);
    };

    renderField() {
        const {
            input: { onChange, value },
        } = this.props;

        if (!value) {
            return (
                <OutlineButton
                    block
                    bsStyle="info"
                    onClick={() => {
                        this.setState({
                            showAddendumDiffModal: true,
                            showAddendumDiffForm: true,
                        });
                    }}
                    qaTag="addendumDiff-add"
                >
                    <i className="fa fa-random" /> Add Diff to Addendum
                </OutlineButton>
            );
        }

        return (
            <div>
                <Button
                    active
                    block
                    bsStyle="info"
                    className={this.styles.includedDiffButton}
                    onClick={() => {
                        this.setState({
                            showAddendumDiffModal: true,
                            showAddendumDiffForm: false,
                        });
                    }}
                    qaTag="addendumDiff-included"
                >
                    <i className="fa fa-random" /> Version Diff Included
                </Button>
                <div className={this.styles.diffActionButtons}>
                    <Button
                        bsSize="sm"
                        bsStyle="link"
                        onClick={() => {
                            this.setState({
                                showAddendumDiffModal: true,
                                showAddendumDiffForm: true,
                            });
                        }}
                        qaTag="addendumDiff-edit"
                        zeroPadding
                    >
                        <i className="fa fa-pencil" /> Edit diff
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button
                        bsSize="sm"
                        bsStyle="link"
                        onClick={() => onChange(null)}
                        qaTag="addendumDiff-remove"
                        zeroPadding
                    >
                        <span className="text-danger">
                            <i className="fa fa-trash-o" /> Remove diff
                        </span>
                    </Button>
                </div>
            </div>
        );
    }

    render() {
        const {
            input: { value },
        } = this.props;

        const { showAddendumDiffForm, showAddendumDiffModal } = this.state;

        return (
            <div className={this.styles.container}>
                {this.renderField()}
                {showAddendumDiffModal && (
                    <AddendumDiffFormModal
                        diff={value || undefined}
                        hideModal={() => {
                            this.setState({
                                showAddendumDiffModal: false,
                                showAddendumDiffForm: false,
                            });
                        }}
                        includeForm={showAddendumDiffForm}
                        onSubmit={this.submitHandler}
                    />
                )}
            </div>
        );
    }
}
