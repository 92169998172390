import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { attachmentTypesDict } from '@og-pro/shared-config/attachments';

import { criteriaInstructionsDict } from '@og-pro/shared-config/criteria';

import { fieldNames } from '../../constants';
import { Button } from '../../../../Button';
import { RichTextInput } from '../../../../RichTextInput';
import { ProjectAttachmentBlock } from '../../../ProjectAttachmentBlock';
import { RadioGroup } from '../../../../RadioGroup/RadioGroup';

const { RAW_DESCRIPTION, IS_REVIEWED } = fieldNames;

const { ATTACHMENT, RADIO, SELECT } = criteriaInstructionsDict;

export class ReviewContent extends PureComponent {
    static propTypes = {
        arrayName: PropTypes.string.isRequired,
        change: PropTypes.func.isRequired,
        descriptionInput: PropTypes.object.isRequired,
        descriptionMeta: PropTypes.object.isRequired,
        disabled: PropTypes.bool,
        formName: PropTypes.string.isRequired,
        instructionData: PropTypes.string,
        instructionType: PropTypes.string,
        isReviewed: PropTypes.bool.isRequired,
        onOptionSelect: PropTypes.func.isRequired,
        projectId: PropTypes.number.isRequired,
        showValidation: PropTypes.bool,
        tagOptions: PropTypes.array,
        templateVariableOptions: PropTypes.array,
    };

    static defaultProps = {
        disabled: false,
        instructionData: undefined,
        instructionType: undefined,
        showValidation: false,
        tagOptions: undefined,
        templateVariableOptions: undefined,
    };

    constructor(props) {
        super(props);

        this.state = {
            selectedOption: false,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    get inputData() {
        const { instructionData } = this.props;
        return instructionData ? JSON.parse(instructionData) : {};
    }

    optionSelector = (option) => {
        const { arrayName, change, onOptionSelect } = this.props;

        change(`${arrayName}.${RAW_DESCRIPTION}`, option);
        change(`${arrayName}.${IS_REVIEWED}`, true);

        this.setState(() => ({
            selectedOption: true,
        }));

        onOptionSelect();
    };

    renderOptionalAttachmentBlock() {
        const { includeAttachment } = this.inputData;

        if (!includeAttachment) {
            return null;
        }

        return (
            <div className={this.styles.includeAttachment}>{this.renderAttachmentBlock(true)}</div>
        );
    }

    renderAttachmentBlock(optional = false) {
        const { disabled, formName, projectId } = this.props;

        const text = optional ? 'Please add any needed attachments' : 'Please add the attachment';

        return (
            <div>
                <div className={this.styles.attachmentInstructions}>{text}</div>
                <ProjectAttachmentBlock
                    disabled={disabled}
                    form={formName}
                    label="Attachments"
                    projectId={projectId}
                    showNotification
                    uploadAttachmentType={attachmentTypesDict.OTHER}
                />
            </div>
        );
    }

    renderSelectBlock() {
        const { options } = this.inputData;
        const selectOptions = options || [];
        const colWidth = Math.floor(12 / (selectOptions.length || 1));

        const SelectInstructions = selectOptions.map((option, idx) => (
            <p className={`col-xs-${colWidth} text-center`} key={idx}>
                {option.instructions}
            </p>
        ));
        const SelectButtons = selectOptions.map((option, idx) => (
            <div className={`col-xs-${colWidth}`} key={idx}>
                <Button
                    block
                    bsStyle="success"
                    disabled={this.props.disabled}
                    onClick={() => this.optionSelector(option.value)}
                >
                    Select Option
                </Button>
            </div>
        ));

        return (
            <div>
                <div className="row">{SelectInstructions}</div>
                <div className="row">{SelectButtons}</div>
                {this.renderSelectedDescription()}
            </div>
        );
    }

    renderRadioBlock() {
        const radioData = this.inputData;
        const radioOptions = (radioData.options || []).map((option) => ({
            name: option.name,
            value: option.value,
        }));

        return (
            <div>
                <RadioGroup
                    disabled={this.props.disabled}
                    input={this.props.descriptionInput}
                    label={radioData.label}
                    meta={this.props.descriptionMeta}
                    name="description"
                    onChange={this.optionSelector}
                    options={radioOptions}
                    showValidation={this.props.showValidation}
                />
                {this.renderSelectedDescription()}
            </div>
        );
    }

    renderSelectedDescription() {
        const { selectedOption } = this.state;
        return (
            <div className={this.styles.selectedDescription}>
                {this.props.isReviewed || selectedOption ? (
                    this.renderTextAreaBlock()
                ) : (
                    <>
                        <div className="text-center">
                            <em>Please select an option from above</em>
                        </div>
                        {this.renderOptionalAttachmentBlock()}
                    </>
                )}
            </div>
        );
    }

    renderTextAreaBlock() {
        const {
            descriptionInput,
            descriptionMeta,
            disabled,
            showValidation,
            tagOptions,
            templateVariableOptions,
        } = this.props;

        return (
            <>
                <RichTextInput
                    disabled={disabled}
                    input={descriptionInput}
                    label="Selected Option:"
                    meta={descriptionMeta}
                    showValidation={showValidation}
                    tagOptions={tagOptions}
                    templateVariableOptions={templateVariableOptions}
                />
                {this.renderOptionalAttachmentBlock()}
            </>
        );
    }

    render() {
        switch (this.props.instructionType) {
            case ATTACHMENT:
                return this.renderAttachmentBlock();
            case RADIO:
                return this.renderRadioBlock();
            case SELECT:
                return this.renderSelectBlock();
            default:
                return this.renderTextAreaBlock();
        }
    }
}
