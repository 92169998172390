import { Warning as WarningIcon } from '@mui/icons-material';
import { Box, Card, CardContent, Chip, Grid, Typography } from '@og-pro/ui';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import React from 'react';
import PropTypes from 'prop-types';

import { ContractDisplay } from './ContractDisplay';
import { labelStyle, customStyles } from './styles';
import { VendorUserDisplay } from './VendorUserDisplay';
import { hasInvalidVendorAddress } from '../../../../helpers';
import { VendorInfo } from '../../../../../../../../components';

export const DisplayVendorCard = ({
    contract,
    endsInPurchaseOrder,
    hasMultipleVendors,
    index,
    isPoCreationStep,
    loadingVendorData,
    proUser,
    proVendor,
    vendor,
}) => {
    const {
        associatedLineItems,
        attachments,
        commentToVendor,
        deliveryCodeText,
        paymentTermsText,
        purchaseOrderType,
        vendorAssignedNo,
        vendorEmail,
    } = vendor;

    const styles = require('../../../index.scss');

    const hasInvalidAddress = endsInPurchaseOrder && hasInvalidVendorAddress(vendor);

    const Divider = (
        <Box borderTop={`1px solid ${capitalDesignTokens.semanticColors.border.primary}`} />
    );

    return (
        <Card>
            <CardContent>
                <Grid container spacing={3} sx={customStyles}>
                    {hasMultipleVendors && (
                        <Grid item xs={12}>
                            <Typography variant="h4">Vendor {index + 1}</Typography>
                        </Grid>
                    )}
                    <Grid item xs={6}>
                        <Typography {...labelStyle}>Vendor</Typography>
                        <VendorInfo
                            associatedVendorData={proVendor}
                            hasInvalidAddress={hasInvalidAddress}
                            invalidAddressColor={isPoCreationStep ? 'error.main' : 'warning.main'}
                            loading={loadingVendorData}
                            vendor={vendor}
                        />
                    </Grid>
                    {!!contract && (
                        <Grid item xs={6}>
                            <ContractDisplay contract={contract} />
                        </Grid>
                    )}
                    <>
                        <Grid item xs={12}>
                            {Divider}
                        </Grid>
                        <Grid item xs={6}>
                            <VendorUserDisplay
                                loading={loadingVendorData}
                                proUser={proUser}
                                vendorEmail={vendorEmail}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography {...labelStyle}>Vendor Number</Typography>
                            <Typography>{vendorAssignedNo}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography {...labelStyle}>Delivery Code</Typography>
                            <Typography>{deliveryCodeText}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography {...labelStyle}>Payment Terms</Typography>
                            <Typography>{paymentTermsText}</Typography>
                        </Grid>
                        {endsInPurchaseOrder && (
                            <>
                                <Grid item xs={12}>
                                    {Divider}
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h4">For the Purchase Order</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography {...labelStyle} marginBottom={0.5}>
                                        Comment to Vendor
                                    </Typography>
                                    <Typography
                                        color="secondary"
                                        marginBottom={1}
                                        variant="bodySmall"
                                    >
                                        This will appear as text on the Purchase Order.
                                    </Typography>
                                    <Typography>{commentToVendor || 'None'}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography {...labelStyle}>Purchase Order Type</Typography>
                                    <Typography>{purchaseOrderType}</Typography>
                                </Grid>
                            </>
                        )}
                        {hasMultipleVendors && (
                            <Grid item xs={6}>
                                <Typography {...labelStyle}>Associated Line Items</Typography>
                                <Box display="flex" gap={1}>
                                    {associatedLineItems.length > 0
                                        ? associatedLineItems.map((associatedLineItem) => (
                                              <Chip
                                                  key={associatedLineItem.value}
                                                  label={associatedLineItem.label}
                                                  variant="outlined"
                                              />
                                          ))
                                        : 'None'}
                                </Box>
                            </Grid>
                        )}
                        {attachments && attachments.length > 0 && (
                            <Grid item xs={12}>
                                <Typography {...labelStyle}>
                                    Attachment(s) to the Purchase Order
                                </Typography>
                                <div className={styles.attachmentList}>
                                    {attachments.map((attachment) => {
                                        return (
                                            <div
                                                className={styles.attachmentItem}
                                                key={attachment.url}
                                            >
                                                <a
                                                    href={attachment.url}
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                >
                                                    <i className="fa fa-file-text-o" />{' '}
                                                    {attachment.title || attachment.filename}
                                                </a>
                                            </div>
                                        );
                                    })}
                                </div>
                            </Grid>
                        )}
                    </>
                    {hasInvalidAddress && (
                        <Grid item xs={12}>
                            <Box alignItems="center" display="flex">
                                <WarningIcon
                                    color={isPoCreationStep ? 'error' : 'warning'}
                                    fontSize="inherit"
                                />
                                <Typography
                                    color={isPoCreationStep ? 'error.main' : 'warning.main'}
                                    variant="bodySmall"
                                >
                                    <b>Vendor Address Required:</b>{' '}
                                    {isPoCreationStep
                                        ? 'An address must be added to this vendor’s profile in your accounting system to create a Purchase Order.'
                                        : 'You may proceed at this point, but an address must be added to this vendor’s profile in your accounting system to create a Purchase Order later.'}
                                </Typography>
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </CardContent>
        </Card>
    );
};

DisplayVendorCard.propTypes = {
    contract: PropTypes.object,
    endsInPurchaseOrder: PropTypes.bool,
    hasMultipleVendors: PropTypes.bool,
    index: PropTypes.number.isRequired,
    isPoCreationStep: PropTypes.bool,
    loadingVendorData: PropTypes.bool,
    proUser: PropTypes.shape({
        email: PropTypes.string.isRequired,
        fullName: PropTypes.string.isRequired,
    }),
    proVendor: PropTypes.object,
    vendor: PropTypes.shape({
        associatedLineItems: PropTypes.array.isRequired,
        attachments: PropTypes.array.isRequired,
        commentToVendor: PropTypes.string,
        deliveryCodeText: PropTypes.string.isRequired,
        paymentTermsText: PropTypes.string.isRequired,
        purchaseOrderType: PropTypes.string.isRequired,
        vendorAssignedNo: PropTypes.string,
        vendorEmail: PropTypes.string.isRequired,
        vendorId: PropTypes.number.isRequired,
        vendorName: PropTypes.string.isRequired,
    }).isRequired,
};
