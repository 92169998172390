export const dashboardInstructions = (builderDisplayName) => ({
    admin:
        'Use this dashboard to manage the status of your approvals.\n' +
        `You can view when your approver first viewed the ${builderDisplayName.toLowerCase()} ` +
        'and send them reminder emails.\n' +
        `You may also approve the ${builderDisplayName.toLowerCase()} for any approver ` +
        'that is unable to do so themselves or remove the approver if their approval is no ' +
        'longer necessary.',
    closed:
        `${builderDisplayName} approvals are closed. The ${builderDisplayName.toLowerCase()} ` +
        'is not in a reviewable state.',
    draft:
        `${builderDisplayName} approvals are on hold. The ${builderDisplayName.toLowerCase()} ` +
        'has not been submitted for review.\n' +
        `Once the ${builderDisplayName.toLowerCase()} has been submitted for review, ` +
        'approvals will be available.',
    nonAdmin: `Use this dashboard to track the status of ${builderDisplayName.toLowerCase()} approvals.`,
    adminInvite:
        'Each approver will be notified by email that their ' +
        'approval has been requested.\n' +
        `Once all approvers have approved, the ${builderDisplayName.toLowerCase()} can be ` +
        'finalized using the "Finalize" button at the bottom of the page.',
});

export const dashboardInviteInfo = (builderDisplayName) =>
    'Each approver will be notified by email that their approval has been requested.\n' +
    `Once all approvers have approved, the ${builderDisplayName.toLowerCase()} can be finalized ` +
    'using the "Finalize" button at the bottom of the page.';

export const publishExplanation = (builderDisplayName) =>
    `All approvers must approve the ${builderDisplayName.toLowerCase()} before it can be finalized.`;

export const contractPackagePublishExplanation = () =>
    'Finalizing your contract packet means that we will consolidate your document with your exhibits, creating a single PDF file.';

export const contractPacketReviewersMessage = () =>
    'All reviewers must approve before you can finalize your contract packet.';
