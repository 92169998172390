import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { v4 as UUIDv4 } from 'uuid';

import { offsetUnits, offsetUnitsDict } from '@og-pro/shared-config/notifications';

import { fieldNames } from './constants';
import { validate } from './validate';
import { Button } from '../../Button';
import { HelpIcon } from '../../InputText/HelpIcon';
import { NumberWidget } from '../../NumberWidget/NumberWidget';
import { SearchSelect } from '../../SearchSelect/SearchSelect';

const offsetUnitOptions = offsetUnits.map((offsetUnit) => {
    return {
        label: offsetUnit,
        value: offsetUnit,
    };
});

const { NOTIFICATIONS } = fieldNames;

const formConfig = {
    enableReinitialize: true,
    validate,
};

// @connect
// @reduxForm
class ConnectedContractMilestoneNotificationsForm extends Component {
    static propTypes = {
        contractMilestone: PropTypes.shape({
            id: PropTypes.number.isRequired, // Used by the selectors
            date: PropTypes.string.isRequired,
            name: PropTypes.string,
            notificationsUpdateError: PropTypes.string,
            notificationsUpdating: PropTypes.bool,
        }).isRequired,
        dirty: PropTypes.bool,
        disabled: PropTypes.bool,
        handleSubmit: PropTypes.func.isRequired, // From @reduxForm
        isEditor: PropTypes.bool,
    };

    static defaultProps = {
        dirty: false,
        disabled: false,
        isEditor: false,
    };

    get styles() {
        return require('./index.scss');
    }

    addNewNotification = (fields) => {
        const {
            contractMilestone: { date },
        } = this.props;

        fields.push({
            dateOfEvent: date,
            localCreatingUUID: UUIDv4(),
            offset: 1,
            offsetUnit: offsetUnitsDict.MONTHS,
            type: 'contractMilestone',
        });
    };

    renderNotifications = ({ fields }) => {
        const {
            contractMilestone: { name, notificationsUpdateError, notificationsUpdating },
            dirty,
            disabled,
            isEditor,
        } = this.props;

        return (
            <div>
                <div className="row">
                    <div className="col-sm-12 text-muted" style={{ marginBottom: '20px' }}>
                        {name === 'Annual Evaluation' ? (
                            <>
                                Notifications will be sent on the following times before the
                                anniversary of the start date.
                                <HelpIcon
                                    style={{ color: 'grey' }}
                                    tooltip={
                                        <div>
                                            For example, if the start date is Jan 1, 2025, and the
                                            notification is set to 6 months, OpenGov will send a
                                            notification on July 1, 2025. If the contract is active
                                            until 2027, OpenGov will send this notification again on
                                            July 1, 2026.
                                        </div>
                                    }
                                />
                            </>
                        ) : (
                            `Notifications will be sent on the following times before the ${name.toLowerCase()}.
                                  `
                        )}
                    </div>
                </div>

                {fields.length === 0 && (
                    <div className="text-muted">
                        <em>No notifications have been added for this milestone.</em>
                    </div>
                )}
                {fields.map((fieldName, index) => {
                    const notificationData = fields.get(index);

                    return (
                        <div
                            className="row"
                            key={notificationData.id || notificationData.localCreatingUUID}
                        >
                            <div className="col-sm-5">
                                <Field
                                    aria-label="Enter an offset value"
                                    component={NumberWidget}
                                    disabled={disabled || notificationsUpdating}
                                    hideLabel
                                    label="Date Quantity"
                                    min={1}
                                    name={`${fieldName}.offset`}
                                    placeholder="Enter Value"
                                />
                            </div>
                            <div className="col-sm-5">
                                <Field
                                    aria-label="Select an offset unit value"
                                    component={SearchSelect}
                                    disabled={disabled || notificationsUpdating}
                                    name={`${fieldName}.offsetUnit`}
                                    options={offsetUnitOptions}
                                    placeholder="Select a Value"
                                />
                            </div>
                            {isEditor && (
                                <div className="col-sm-2">
                                    <Button
                                        aria-label="Remove Button"
                                        bsStyle="link"
                                        onClick={() => fields.remove(index)}
                                        qaTag="contractMilestoneNotificationsForm-remove"
                                    >
                                        <i className="fa fa-times fa-lg text-danger" />
                                    </Button>
                                </div>
                            )}
                        </div>
                    );
                })}
                {isEditor && (
                    <div className="text-right">
                        {(fields.length || dirty) && (
                            <Button
                                bsStyle="primary"
                                disabled={disabled || notificationsUpdating}
                                type="submit"
                            >
                                Save
                            </Button>
                        )}
                        &nbsp;
                        <Button
                            bsStyle="link"
                            disabled={disabled || notificationsUpdating}
                            onClick={() => this.addNewNotification(fields)}
                        >
                            <i className="fa fa-plus" />
                            &nbsp;Add Notification
                        </Button>
                        {notificationsUpdateError && (
                            <div className="error-block">{notificationsUpdateError}</div>
                        )}
                    </div>
                )}
            </div>
        );
    };

    render() {
        const { handleSubmit } = this.props;

        return (
            <form onSubmit={handleSubmit}>
                <FieldArray component={this.renderNotifications} name={NOTIFICATIONS} />
            </form>
        );
    }
}

export const ContractMilestoneNotificationsForm = reduxForm(formConfig)(
    ConnectedContractMilestoneNotificationsForm
);
