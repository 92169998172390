import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Panel } from 'react-bootstrap';
import { connect } from 'react-redux';
import { change, Field, FieldArray, formValueSelector } from 'redux-form';

import {
    DATA,
    DEFAULT_VALUE,
    INPUT_DATA,
    ALLOW_MULTI_SELECT,
    OPTIONS,
} from '@og-pro/shared-config/questionnaires';

import { GenericFormBuilder } from '../../SharedFormBuilder';
import { Button } from '../../../../Button';
import { Checkbox } from '../../../../Checkbox/Checkbox';
import { InputText } from '../../../../InputText';
import { OutlineButton } from '../../../../OutlineButton';
import { QuestionnaireMultipleChoiceFormContext } from '../../../../contexts';

const mapStateToProps = (state, props) => {
    return {
        defaultIndex: formValueSelector(props.form)(state, `${INPUT_DATA}.${DEFAULT_VALUE}`),
    };
};

const mapDispatchToProps = {
    changeForm: change,
};

export class ConnectedMultipleChoiceFormBuilder extends Component {
    static propTypes = {
        allowDefaultValue: PropTypes.bool,
        changeForm: PropTypes.func.isRequired,
        defaultIndex: PropTypes.number,
        disabled: PropTypes.bool,
        form: PropTypes.string.isRequired,
        includeContainsPricingOption: PropTypes.bool,
        isTitleEditingDisabled: PropTypes.bool,
        questionnaireId: PropTypes.number,
        tagOptions: PropTypes.array,
        templateVariableOptions: PropTypes.array,
        useRawPrompt: PropTypes.bool,
    };

    static defaultProps = {
        tagOptions: undefined,
        templateVariableOptions: undefined,
        useRawPrompt: false,
    };

    static contextType = QuestionnaireMultipleChoiceFormContext;

    constructor(props) {
        super(props);

        this.state = {
            newlyAddedOptionsByIndexSet: new Set(),
        };
    }

    get styles() {
        return require('./index.scss');
    }

    makeDefault = (indexOrNull) => {
        const { changeForm, form } = this.props;

        changeForm(form, `${INPUT_DATA}.${DEFAULT_VALUE}`, indexOrNull);
    };

    renderMultipleChoiceItems = ({
        defaultIndex,
        isEditingDisabled,
        isEditingDisabledBySet,
        fields,
        meta,
    }) => {
        const { allowDefaultValue, disabled } = this.props;
        const { newlyAddedOptionsByIndexSet } = this.state;
        const { error } = meta;

        const editingDisabled = isEditingDisabled || isEditingDisabledBySet;

        return (
            <Panel>
                <Panel.Body>
                    {isEditingDisabledBySet && (
                        <p className="text-warning text-center">
                            <i className="fa fa-exclamation-triangle" /> Warning
                            <br />
                            Existing multiple choice options cannot be edited
                            <br />
                            when question is used for automation logic
                        </p>
                    )}
                    {fields.map((member, index) => {
                        const isDefault = index === defaultIndex;
                        const isNewlyAddedOption = newlyAddedOptionsByIndexSet.has(index);
                        const isEditingExistingDisabled = editingDisabled && !isNewlyAddedOption;
                        return (
                            <React.Fragment key={index}>
                                <div className="pull-right">
                                    {allowDefaultValue && (
                                        <Button
                                            bsStyle="link"
                                            className={this.styles.defaultButton}
                                            disabled={disabled}
                                            onClick={() =>
                                                this.makeDefault(isDefault ? null : index)
                                            }
                                            zeroPadding
                                        >
                                            <i className={`fa fa-star${isDefault ? '' : '-o'}`} />{' '}
                                            Default
                                        </Button>
                                    )}
                                    <Button
                                        aria-label="Remove Button"
                                        bsStyle="link"
                                        disabled={fields.length <= 2 || isEditingExistingDisabled}
                                        onClick={() => fields.remove(index)}
                                        zeroPadding
                                    >
                                        <i className="fa fa-lg fa-times text-danger" />
                                    </Button>
                                </div>
                                <Field
                                    component={InputText}
                                    disabled={isEditingExistingDisabled || disabled}
                                    hasFeedback={false}
                                    label={`Option ${index + 1}`}
                                    name={member}
                                    placeholder={`Enter Option ${index + 1}`}
                                    type="text"
                                />
                            </React.Fragment>
                        );
                    })}
                    <div className="text-right">
                        <OutlineButton
                            bsSize="sm"
                            bsStyle="info"
                            disabled={disabled}
                            invertHover={false}
                            onClick={() => {
                                this.setState({
                                    newlyAddedOptionsByIndexSet: newlyAddedOptionsByIndexSet.add(
                                        fields.length
                                    ),
                                });
                                fields.push();
                            }}
                        >
                            <i className="fa fa-plus" /> Option
                        </OutlineButton>
                    </div>
                    {error && <div className="error-block text-center">{error}</div>}
                </Panel.Body>
            </Panel>
        );
    };

    render() {
        const {
            defaultIndex,
            disabled,
            form,
            includeContainsPricingOption,
            isTitleEditingDisabled,
            questionnaireId,
            tagOptions,
            templateVariableOptions,
            useRawPrompt,
        } = this.props;

        const isEditingDisabledSet = this.context?.isEditingDisabledSet;
        const isEditingDisabled = this.context?.isEditingDisabled;

        let isEditingDisabledBySet = false;
        if (isEditingDisabledSet && questionnaireId) {
            isEditingDisabledBySet = isEditingDisabledSet.has(questionnaireId);
        }

        return (
            <GenericFormBuilder
                disabled={disabled}
                form={form}
                includeContainsPricingOption={includeContainsPricingOption}
                isTitleEditingDisabled={isTitleEditingDisabled}
                tagOptions={tagOptions}
                templateVariableOptions={templateVariableOptions}
                useRawPrompt={useRawPrompt}
            >
                <h5 className={this.styles.multipleChoiceHeader}>Multiple Choice Options</h5>
                <FieldArray
                    component={this.renderMultipleChoiceItems}
                    defaultIndex={defaultIndex}
                    isEditingDisabled={isEditingDisabled}
                    isEditingDisabledBySet={isEditingDisabledBySet}
                    name={`${DATA}.${OPTIONS}`}
                />
                <Field
                    component={Checkbox}
                    disabled={disabled}
                    name={`${DATA}.${ALLOW_MULTI_SELECT}`}
                    text="Allow multiple selections"
                />
            </GenericFormBuilder>
        );
    }
}

export const MultipleChoiceFormBuilder = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedMultipleChoiceFormBuilder);
