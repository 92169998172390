import PropTypes from 'prop-types';

export const SearchSelectPropTypes = {
    commentData: PropTypes.object,
    commentIcon: PropTypes.bool,
    disabled: PropTypes.bool,
    formClassName: PropTypes.string,
    input: PropTypes.shape({
        onBlur: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        value: PropTypes.any.isRequired,
    }).isRequired,
    isMulti: PropTypes.bool,
    label: PropTypes.string,
    labelClassName: PropTypes.string,
    menuStyle: PropTypes.object,
    meta: PropTypes.shape({
        error: PropTypes.string,
        touched: PropTypes.bool.isRequired,
        warning: PropTypes.string,
    }).isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            options: PropTypes.array, // When a grouped list of options is passed
            value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
            message: PropTypes.string, // When a message is passed to be displayed on hover
        })
    ),
    qaTag: PropTypes.string,
    selectClassName: PropTypes.string,
    showValidation: PropTypes.bool,
    styles: PropTypes.shape({
        control: PropTypes.object,
        menu: PropTypes.object,
    }),

    // Custom defined props (not part of react-select API)
    beforeClearOnBlur: PropTypes.func,
    clearOnBlur: PropTypes.bool,
    help: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    hideDisabledMenuOptions: PropTypes.bool,
    inlineButton: PropTypes.node,
    isMultiSimpleValue: PropTypes.bool, // uses array with simple values instead of standard array of options
    noMarginBottom: PropTypes.bool,
    onChangeWithOption: PropTypes.func,
    overrideFeedback: PropTypes.bool,
    rawValue: PropTypes.bool,
    showInviteUserHelp: PropTypes.bool,
    useNullWhenUndefined: PropTypes.bool,
    loadOptions: PropTypes.func,
    useOpenGovStyle: PropTypes.bool,
    // this is the dom element where the menu is going to be attached to
    // when using the search select in material ui dialogs, you might get a scenario
    // where the dialog size changes when opening the menu. This prevents that change.
    menuPortalTarget: PropTypes.object,
};
