import React from 'react';

import { ArrayCellRenderer } from '../../AgGridReactLegacy/renderers';

export const CategoriesCellRenderer = (params) => (
    <ArrayCellRenderer
        data={params.data}
        tooltipTitle={params.value?.map((category) => <div key={category}>{category}</div>) || []}
        value={params.value}
    />
);
