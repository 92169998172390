import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormGroup, HelpBlock } from 'react-bootstrap';

import { StarsDisplay } from '../StarsDisplay';

export class StarsInput extends PureComponent {
    static propTypes = {
        disabled: PropTypes.bool,
        help: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        input: PropTypes.object.isRequired,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        meta: PropTypes.object.isRequired,
        overrideFeedback: PropTypes.bool,
        showValidation: PropTypes.bool,
        starsCount: PropTypes.number,
        starSize: PropTypes.number,
    };

    static defaultProps = {
        disabled: false,
        label: undefined,
        overrideFeedback: false,
        showValidation: false,
        starsCount: 5,
        starSize: 1,
    };

    starSelectHandler = (number) => {
        const {
            input: { onChange },
        } = this.props;

        return onChange(number);
    };

    render() {
        const {
            disabled,
            help,
            input: { name, value },
            label,
            meta: { error, touched, warning },
            overrideFeedback,
            showValidation,
            starsCount,
            starSize,
        } = this.props;

        // Only display touched validation on error/warning states
        const displayValidation = touched || showValidation;

        let bsStyle;
        let helpText = help;
        if (overrideFeedback) {
            // bootstrap won't accept default here
            bsStyle = null;
        } else if (displayValidation && error) {
            bsStyle = 'error';
            helpText = error;
        } else if (displayValidation && warning) {
            bsStyle = 'warning';
            helpText = warning;
        } else if (showValidation) {
            bsStyle = 'success';
        }

        return (
            <FormGroup controlId={`form-group-${name}`} validationState={bsStyle}>
                <div>
                    <b>{label}</b>
                </div>
                <div>
                    <StarsDisplay
                        disabled={disabled}
                        onSelect={this.starSelectHandler}
                        size={starSize}
                        starsCount={starsCount}
                        value={value || null}
                    />
                </div>
                {helpText && <HelpBlock>{helpText}</HelpBlock>}
            </FormGroup>
        );
    }
}
