import { find, get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { useNavigate, useParams } from 'react-router-dom';

import { contractStatusesDict } from '@og-pro/shared-config/contracts';

import { QUANTITY, UNIT_PRICE } from '@og-pro/shared-config/priceTables';

import HighlightedText from './HighlightedText';
import PriceItemAwardInfo from './PriceItemAwardInfo';
import { Button } from '../Button';
import { PriceTable } from '../PriceTable';
import { suppressKeyboardEvent } from '../../helpers/agGrid';

const { ACTIVE } = contractStatusesDict;

const getAwardData = (contracts, proposal) => {
    const proposalId = get(proposal, 'id');
    const companyName = get(proposal, 'companyName');

    const contract = find(contracts, (contractToMatch) => {
        return (
            (!!proposal && contractToMatch.proposal_id === proposalId) ||
            get(contractToMatch, 'contractParty.vendor.id') === get(proposal, 'vendor.id')
        );
    });

    return {
        companyName,
        contract,
        proposalId,
    };
};

const CollapsiblePriceTable = ({
    displayAwardsColumn,
    descriptionFilter,
    evaluation,
    headerClassName,
    isLoadingContractInfo,
    isPublicView,
    lineItemContracts,
    lineItemPriceTables,
    priceTable,
    projectId,
}) => {
    const { governmentCode, governmentId } = useParams();
    const navigate = useNavigate();
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [stateKey, setStateKey] = useState(0);

    useEffect(() => {
        setStateKey((prevState) => ++prevState);
    }, [lineItemPriceTables]);

    const getAwardInfoFromCellData = useCallback(
        ({ priceItemId }) => {
            if (get(evaluation, 'isLineItemAward')) {
                const currentLineItemPriceItem = find(lineItemPriceTables.priceItems, {
                    id: priceItemId,
                });

                const currentVendorPriceItems =
                    get(currentLineItemPriceItem, 'vendorPriceItems') || [];
                return currentVendorPriceItems.map((priceItem) => {
                    return getAwardData(lineItemContracts, get(priceItem, 'proposal'));
                });
            }

            // else isLowestPrice
            const selectedProposals = get(evaluation, 'selectedProposals') || [];
            return selectedProposals.map((proposal) => {
                return getAwardData(lineItemContracts, proposal);
            });
        },
        [lineItemContracts, lineItemPriceTables]
    );

    const getAdditionalColumns = useCallback(() => {
        if (!displayAwardsColumn || !lineItemPriceTables) {
            return;
        }

        const hasNoData = isLoadingContractInfo || !lineItemPriceTables;
        return [
            ...(isPublicView
                ? []
                : [
                      {
                          // Available quantity column
                          orderIndex: 2,
                          data: {
                              autoHeight: true,
                              cellClass: ['text-right'],
                              cellClassRules: {
                                  // Used exclusively for Excel export styles
                                  headerRow: (params) => params.data.isHeaderRow,
                              },
                              field: 'Available Quantity',
                              width: 90,
                              suppressKeyboardEvent,
                              cellRenderer: ({ data }) => {
                                  const currentPriceItem = find(lineItemPriceTables.priceItems, {
                                      id: data.priceItemId,
                                  });

                                  const purchaseOrders =
                                      get(currentPriceItem, 'purchaseOrders') || [];
                                  const orderedQuantity = purchaseOrders.reduce(
                                      (quantity, purchaseOrder) =>
                                          quantity + get(purchaseOrder, 'quantity', 0),
                                      0
                                  );

                                  const available = data.quantity - orderedQuantity;
                                  const el = document.createElement('div');
                                  el.innerHTML = available;

                                  if (available < 0) {
                                      el.classList.add('bg-danger', 'text-danger');
                                  }

                                  return el;
                              },
                          },
                      },
                  ]),
            {
                // Vendor + contract status column
                data: {
                    autoHeight: true,
                    cellClass: ['wrapText'], // Used exclusively for Excel export styles
                    cellClassRules: {
                        // Used exclusively for Excel export styles
                        headerRow: (params) => params.data.isHeaderRow,
                    },
                    field: 'Awards',
                    flex: 1,
                    minWidth: 150,
                    suppressKeyboardEvent,
                    onCellClicked: ({ data, event }) => {
                        const contractAttribute = event.target.getAttribute('data-contract-id');
                        const proposalAttribute = event.target.getAttribute('data-proposal-id');

                        if (hasNoData || (!contractAttribute && !proposalAttribute)) {
                            return;
                        }

                        const awardInfo = getAwardInfoFromCellData(data);
                        if (awardInfo.length === 0 || !awardInfo[0].proposalId) {
                            return;
                        }

                        const awardInfoWithContract = awardInfo.find(
                            (info) =>
                                info.contract &&
                                info.contract.id === parseInt(contractAttribute, 10)
                        );
                        const contract = get(awardInfoWithContract, 'contract');
                        const isActiveContract = get(contract, 'status') === ACTIVE;

                        if (isPublicView) {
                            const publicProjectPath = `/portal/${governmentCode}/projects/${projectId}`;
                            if (isActiveContract && contract.isPublic) {
                                return navigate(`${publicProjectPath}/contracts/${contract.id}`);
                            }
                            return navigate(`${publicProjectPath}/selected-vendor`);
                        }

                        // Link active contracts to spend management form with line item pre-selected
                        if (isActiveContract) {
                            const contractPath = `/governments/${governmentId}/projects/${projectId}/contracts/${contract.id}/spend-management`;
                            navigate({
                                pathname: contractPath,
                                search: `?modal=true&line-item=${data.priceItemId}`,
                            });

                            return;
                        }

                        // Link line items with no or inactive contracts to appropriate evaluation page
                        const proposalId = parseInt(proposalAttribute, 10);
                        const evaluationBasePath = `/governments/${governmentId}/projects/${projectId}/evaluation`;
                        if (get(evaluation, 'isLineItemAward')) {
                            navigate({
                                pathname: `${evaluationBasePath}/line-item-award/vendors`,
                                search: `?proposal=${proposalId}`,
                            });

                            return;
                        }

                        // else isLowestPrice
                        navigate(`${evaluationBasePath}/bid-tabulations`);
                    },
                    cellRenderer: ({ data }) => {
                        if (hasNoData) {
                            return;
                        }

                        const awardInfo = getAwardInfoFromCellData(data);

                        const component = React.createElement(
                            React.Fragment,
                            {},
                            awardInfo.map(({ companyName, contract, proposalId }, i) =>
                                companyName
                                    ? React.createElement(PriceItemAwardInfo, {
                                          key: i,
                                          contract,
                                          label: companyName,
                                          proposalId,
                                      })
                                    : null
                            )
                        );

                        const el = document.createElement('div');
                        el.innerHTML = renderToStaticMarkup(component);
                        return el;
                    },
                },
            },
        ];
    }, [lineItemPriceTables, displayAwardsColumn, getAwardInfoFromCellData]);

    const handleCollapseToggle = (ev) => {
        ev.preventDefault();
        setIsCollapsed(!isCollapsed);
    };

    const descriptionCellRenderer = ({ data }) => {
        if (data.isHeaderRow) {
            return data.description;
        }

        const component = React.createElement(HighlightedText, {
            highlight: descriptionFilter,
            text: data.description,
        });

        const el = document.createElement('div');
        el.innerHTML = renderToStaticMarkup(component);
        return el;
    };

    const { priceItems } = priceTable;
    const filteredPriceItems = priceItems.filter(({ description }) =>
        description.toLowerCase().includes(descriptionFilter.toLowerCase())
    );

    const numLineItems = priceItems.filter(({ isHeaderRow }) => !isHeaderRow).length;

    return (
        <>
            <PriceTable
                additionalColumns={getAdditionalColumns()}
                columnVisibility={{
                    [QUANTITY]: true,
                    [UNIT_PRICE]: false,
                }}
                containerStyle={{ width: '100%' }}
                descriptionCellRenderer={descriptionCellRenderer}
                headerClassName={headerClassName}
                hideHeaderButtons
                hideSideBar
                key={`${priceTable.id}.${stateKey}`}
                priceTable={{
                    ...priceTable,
                    priceItems: isCollapsed ? filteredPriceItems : priceItems,
                }}
                readOnly
            />
            {priceItems.length - filteredPriceItems.length > 0 && (
                <Button bsSize="sm" onClick={handleCollapseToggle}>
                    {isCollapsed
                        ? `Show all ${numLineItems} line items`
                        : 'Hide unmatched line items'}
                </Button>
            )}
        </>
    );
};

CollapsiblePriceTable.propTypes = {
    displayAwardsColumn: PropTypes.bool,
    descriptionFilter: PropTypes.string.isRequired,
    evaluation: PropTypes.object,
    headerClassName: PropTypes.string,
    isLoadingContractInfo: PropTypes.bool,
    isPublicView: PropTypes.bool,
    lineItemContracts: PropTypes.arrayOf(
        PropTypes.shape({
            contractParty: PropTypes.shape({
                vendor: PropTypes.shape({
                    id: PropTypes.number.isRequired,
                }),
            }),
            proposal_id: PropTypes.number,
        })
    ),
    lineItemPriceTables: PropTypes.shape({
        priceItems: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
            })
        ).isRequired,
    }),
    priceTable: PropTypes.shape({
        id: PropTypes.number.isRequired,
        priceItems: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                description: PropTypes.string.isRequired,
            })
        ).isRequired,
    }).isRequired,
    projectId: PropTypes.number.isRequired,
};

export default CollapsiblePriceTable;
