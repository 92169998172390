import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Button } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';

import { fieldNames } from './constants';
import { validate } from './validate';
import { InputText } from '../../../InputText';
import { RichTextInput } from '../../../RichTextInput';

const { RAW_DESCRIPTION, TITLE } = fieldNames;

const formConfig = {
    validate,
};

// @reduxForm
class ConnectedCriteriaItemForm extends PureComponent {
    static propTypes = {
        autoFocus: PropTypes.bool,
        className: PropTypes.string,
        closeForm: PropTypes.func.isRequired,
        handleDelete: PropTypes.func,
        handleSubmit: PropTypes.func.isRequired,
        isTextArea: PropTypes.bool,
        pristine: PropTypes.bool,
        submitServerError: PropTypes.string,
        submitting: PropTypes.bool,
        tagOptions: PropTypes.array,
        templateVariableOptions: PropTypes.array,
        updating: PropTypes.bool,
    };

    static defaultProps = {
        autoFocus: false,
        className: undefined,
        handleDelete: undefined,
        pristine: false,
        submitServerError: undefined,
        submitting: false,
        tagOptions: undefined,
        templateVariableOptions: undefined,
        updating: false,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const {
            autoFocus,
            className,
            closeForm,
            handleDelete,
            handleSubmit,
            isTextArea,
            pristine,
            submitServerError,
            submitting,
            tagOptions,
            templateVariableOptions,
            updating,
        } = this.props;

        return (
            <form className={className} onSubmit={handleSubmit}>
                {!isTextArea && (
                    <Field
                        component={InputText}
                        disabled={submitting || updating}
                        hasFeedback={false}
                        name={TITLE}
                        placeholder="Enter a title for the item"
                        qaTag="criteriaItemForm-title"
                        type="text"
                    />
                )}
                <Field
                    autoFocus={autoFocus}
                    component={RichTextInput}
                    disabled={submitting || updating}
                    name={RAW_DESCRIPTION}
                    placeholder="Enter a description for the item"
                    tagOptions={tagOptions}
                    templateVariableOptions={templateVariableOptions}
                    toolbarPlacement="bottom"
                />
                {submitServerError && (
                    <div className={`text-danger ${this.styles.errorMsg}`}>{submitServerError}</div>
                )}
                <div className="text-center">
                    <Button
                        bsStyle="primary"
                        className={this.styles.submitButton}
                        disabled={pristine || submitting || updating}
                        type="submit"
                    >
                        {submitting ? 'Updating' : 'Update'}
                    </Button>
                    <Button disabled={submitting || updating} onClick={closeForm}>
                        Cancel
                    </Button>
                    {handleDelete && !isTextArea && (
                        <Button
                            className={this.styles.deleteButton}
                            disabled={submitting || updating}
                            onClick={handleDelete}
                        >
                            <i className="fa fa-lg fa-trash-o" /> Delete
                        </Button>
                    )}
                </div>
            </form>
        );
    }
}

export const CriteriaItemForm = reduxForm(formConfig)(ConnectedCriteriaItemForm);
