import PropTypes from 'prop-types';
import React from 'react';
import { TextareaAutosize } from '@og-pro/ui';

export const Textarea = ({ minRows = 4, ...props }) => {
    return <TextareaAutosize minRows={minRows} {...props} />;
};

Textarea.propTypes = {
    minRows: PropTypes.number,
};
