import { capitalDesignTokens } from '@opengov/capital-mui-theme';

const baseOverflowdedBoxStyle = {
    pl: 4,
    pr: 4,
    py: 2,
};

export const overflowdedBoxStyle = {
    highlighted: {
        backgroundColor: '#F7F9FE',
        border: `1px solid ${capitalDesignTokens.semanticColors.foreground.action}`,
        ...baseOverflowdedBoxStyle,
    },
    readOnly: baseOverflowdedBoxStyle,
};

export const indentedBlockStyle = {
    ml: 3,
    pl: 2,
    mb: 2,
    borderLeft: `4px solid ${capitalDesignTokens.foundations.colors.brand100}`,
};
export const inColumnsStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
};
