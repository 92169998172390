import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getFormValues, isValid } from 'redux-form';

import { getDocumentVersionsJS } from './selectors';
import { diffFieldNames } from '../constants';
import {
    CURRENT_VERSION,
    fieldNames as versionCompareFieldNames,
    form as versionCompareForm,
} from '../../VersionCompareForm/constants';
import { Button } from '../../../Button';
import { LoadingError } from '../../../LoadingError/LoadingError';
import { LoadingSpinner } from '../../../LoadingSpinner/LoadingSpinner';
import { RevisionDiff } from '../../../connected/RevisionDiff';
import { VersionCompareForm } from '../../VersionCompareForm';
import { loadAndCompareRevisions, loadRevisions } from '../../../../actions/revisions';
import { getProjectJS } from '../../../../containers/GovApp/selectors';

const { VERSION_1, VERSION_2 } = versionCompareFieldNames;

const { DOCUMENT_VERSION_1_ID, DOCUMENT_VERSION_2_ID, HTML } = diffFieldNames;

const mapStateToProps = (state) => {
    return {
        documentVersions: getDocumentVersionsJS(state),
        formValues: getFormValues(versionCompareForm)(state) || {},
        loadError: state.revisions.get('error'),
        loading: state.revisions.get('loading'),
        project: getProjectJS(state),
        revisionHtmlDiff: state.revisions.get('revisionHtmlDiff'),
        valid: isValid(versionCompareForm)(state),
    };
};

const mapDispatchToProps = {
    loadAndCompareRevisions,
    loadRevisions,
};

// @connect
class ConnectedAddendumDiffFormModal extends PureComponent {
    static propTypes = {
        diff: PropTypes.object,
        documentVersions: PropTypes.array.isRequired,
        formValues: PropTypes.object.isRequired,
        hideModal: PropTypes.func.isRequired,
        loadError: PropTypes.string,
        loading: PropTypes.bool.isRequired,
        loadAndCompareRevisions: PropTypes.func.isRequired,
        loadRevisions: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
        project: PropTypes.object.isRequired,
        valid: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            diffHtml: null,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    componentDidMount() {
        const { project } = this.props;

        return this.props.loadRevisions(project.id).then(() => {
            return this.loadRevisionDiff();
        });
    }

    getInitialDiffVersion = (versionId) => {
        const { diff } = this.props;
        const value = get(diff, versionId);
        if (value === null) {
            return CURRENT_VERSION;
        }
        if (value) {
            return value;
        }
    };

    getInitialDiffVersion1 = () => {
        const { documentVersions } = this.props;
        return this.getInitialDiffVersion(DOCUMENT_VERSION_1_ID) || documentVersions[0].id;
    };

    getInitialDiffVersion2 = () => {
        return this.getInitialDiffVersion(DOCUMENT_VERSION_2_ID) || CURRENT_VERSION;
    };

    setHtmlDiff = (loadDiffResponse) => {
        if (loadDiffResponse && loadDiffResponse.diff) {
            this.setState({ diffHtml: loadDiffResponse.diff });
        }
    };

    addDiff = () => {
        const { formValues, hideModal, onSubmit } = this.props;

        const version1 = formValues[VERSION_1];
        const version2 = formValues[VERSION_2];

        const diff = {
            [DOCUMENT_VERSION_1_ID]: version1 && version1 !== CURRENT_VERSION ? version1 : null,
            [DOCUMENT_VERSION_2_ID]: version2 && version2 !== CURRENT_VERSION ? version2 : null,
            [HTML]: this.state.diffHtml,
        };

        onSubmit(diff);
        hideModal();
    };

    loadRevisionDiff = () => {
        const { documentVersions, project } = this.props;

        const version1 = this.getInitialDiffVersion1();
        const version2 = this.getInitialDiffVersion2();

        const versionsToFetch = [version1, version2].map((versionValue) => {
            if (versionValue === CURRENT_VERSION) {
                return project;
            }
            const documentVersion = documentVersions.find((v) => v.id === versionValue);
            return documentVersion.project_audit_id;
        });

        this.setState({ diffHtml: null });
        this.props
            .loadAndCompareRevisions(project.id, ...versionsToFetch, { isPublic: true })
            .then(this.setHtmlDiff);
    };

    renderBody() {
        const { documentVersions, loadError, loading } = this.props;

        if (loading) {
            return <LoadingSpinner text="Loading document versions..." />;
        }

        if (loadError) {
            return <LoadingError error={loadError} />;
        }

        if (documentVersions.length === 0) {
            return <LoadingError error="No versions to compare" />;
        }

        const version1 = this.getInitialDiffVersion1();
        const version2 = this.getInitialDiffVersion2();

        return (
            <>
                <div className={this.styles.versionCompareForm}>
                    <VersionCompareForm
                        initialValues={{
                            [VERSION_1]: version1,
                            [VERSION_2]: version2,
                        }}
                        isPublic
                        onSuccess={this.setHtmlDiff}
                    />
                </div>
                <RevisionDiff
                    noSticky
                    revisionDiffClassName={this.styles.revisionDiff}
                    revisionSearchClassName={this.styles.revisionSearch}
                />
            </>
        );
    }

    renderFooter() {
        const { hideModal, valid } = this.props;

        const { diffHtml } = this.state;

        return (
            <>
                <Button onClick={hideModal}>Cancel</Button>
                &nbsp;&nbsp;
                <Button
                    bsSize="lg"
                    bsStyle="success"
                    disabled={!valid || !diffHtml}
                    onClick={this.addDiff}
                >
                    Add Diff to Addendum <i className="fa fa-lg fa-angle-double-right" />
                </Button>
            </>
        );
    }

    render() {
        const { hideModal } = this.props;

        return (
            <Modal bsSize="lg" onHide={hideModal} show>
                <Modal.Body className={this.styles.modalBody}>{this.renderBody()}</Modal.Body>
                <Modal.Footer>{this.renderFooter()}</Modal.Footer>
            </Modal>
        );
    }
}

export const AddendumDiffFormModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedAddendumDiffFormModal);
