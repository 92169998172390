import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Well } from '../../../../Well/Well';
import { currencyFormatter } from '../../../../../helpers';

export class InternalInformationSection extends PureComponent {
    static propTypes = {
        isDocx: PropTypes.bool,
        project: PropTypes.shape({
            budget: PropTypes.shape({
                amount: PropTypes.number,
                description: PropTypes.string,
                identifier: PropTypes.string,
            }).isRequired,
        }).isRequired,
    };

    static defaultProps = {
        isDocx: false,
    };

    render() {
        const {
            isDocx,
            project: {
                budget: { amount, description, identifier },
            },
        } = this.props;

        if (isDocx) {
            return null;
        }

        return (
            <Well className="internal-information-section">
                <h3 className="text-info internal-information-title">
                    <i className="fa fa-lock" /> Information for Internal Use Only
                </h3>
                <div className="dl-horizontal">
                    <dl className="internal-information-dl-list">
                        <dt>Budget Amount:</dt>
                        <dd>
                            {amount || amount === 0 ? (
                                currencyFormatter({ value: amount })
                            ) : (
                                <em>Not provided</em>
                            )}
                        </dd>
                        <dt>Budget Description:</dt>
                        <dd>{description || <em>Not provided</em>}</dd>
                        <dt>Budget Account ID:</dt>
                        <dd>{identifier || <em>Not provided</em>}</dd>
                    </dl>
                </div>
            </Well>
        );
    }
}
