import { statusTypes } from '@og-pro/shared-config/requestTypes';
import PropTypes from 'prop-types';
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getFormValues } from 'redux-form';

import { DEFAULT_SECTION, form, sectionNames } from './RequestTypeForm/constants';
import { getRequestTypesPath } from '../selectors';
import {
    copyRequestType as copyRequestTypeAction,
    deleteRequestType as deleteRequestTypeAction,
    updateRequestType as updateRequestTypeAction,
} from '../../../../actions/requestTypes';
import { showSnackbar } from '../../../../actions/notification';
import { CustomFormEditorProvider } from './RequestTypeForm/CustomFieldsEditor/CustomFormEditorContext';

const RequestTypeActionsContext = createContext();

/**
 * Custom hook to handle request type actions.
 *
 * @returns {Object} An object containing:
 * - `archiveRequestType` (function): Function to archive the request type.
 * - `copyError` (string|null): Error message if copying fails.
 * - `copyRequestType` (function): Function to copy the request type.
 * - `deleteError` (string|null): Error message if deleting fails.
 * - `deleteRequestType` (function): Function to delete the request type.
 * - `disabled` (boolean): Indicates if any action is in process.
 * - `error` (string|null): Error message if any actions fail.
 * - `formErrorsCount` (number): Cached count of errors when the form was last validated. Only reset when `setFormErrorsCount` is called again.
 * - `isCopying` (boolean): Indicates if the copy operation is in progress.
 * - `isDeleting` (boolean): Indicates if the delete operation is in progress.
 * - `isUpdating` (boolean): Indicates if an update operation is in progress.
 * - `publishRequestType` (function): Function to publish the request type.
 * - `saveRequestType` (function): Function to save the request type.
 * - `showValidationError` (boolean): Indicates if form input validation errors should be shown - only when saving a published field (or publishing for the first time)
 * - `selectedSection` (string): State indicating which section is selected, including the state where no section is selected
 * - `setFormErrorsCount` (function): Setter for `formErrorsCount`
 * - `setSelectedSection` (function): Setter for `selectedSection`
 * - `setShowValidationError` (function): Setter for `showValidationError`
 * - `unpublishRequestType` (function): Function to unarchive the request type.
 * - `updateError` (string|null): Error message if updating fails.
 */
export const useRequestTypeActions = () => {
    return useContext(RequestTypeActionsContext);
};

export const RequestTypeActionsProvider = ({ children, requestType }) => {
    const [selectedSection, setSelectedSection] = useState(DEFAULT_SECTION);

    const [isCopying, setIsCopying] = useState(false);
    const [copyError, setCopyError] = useState(null);

    const [isUpdating, setIsUpdating] = useState(false);
    const [updateError, setUpdateError] = useState(null);

    const [isDeleting, setIsDeleting] = useState(false);
    const [deleteError, setDeleteError] = useState(null);

    const [showFormValidation, setShowFormValidation] = useState(false);
    const [formErrorsCount, setFormErrorsCount] = useState(0);

    const formValues = useSelector(getFormValues(form));
    const requestTypesPath = useSelector(getRequestTypesPath);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const copyRequestType = useCallback(async () => {
        setIsCopying(true);
        setCopyError(null);

        try {
            const copiedRequestType = await dispatch(copyRequestTypeAction(requestType.id));
            setIsCopying(false);
            navigate(`${requestTypesPath}/${copiedRequestType.id}`);
        } catch (e) {
            setIsCopying(false);
            setCopyError(e?.message);
        }
    }, [requestType, dispatch, navigate, requestTypesPath]);

    const deleteRequestType = useCallback(async () => {
        setIsDeleting(true);
        setDeleteError(null);

        try {
            await dispatch(deleteRequestTypeAction(requestType.id));
            setIsDeleting(false);
            navigate(requestTypesPath);
        } catch (e) {
            setIsDeleting(false);
            setDeleteError(e?.message);
        }
    }, [requestType, dispatch, navigate, requestTypesPath]);

    const updateRequestType = useCallback(
        async (additionalData, successMessage = 'Request type updated') => {
            setIsUpdating(true);
            setUpdateError(null);

            const data = { ...formValues, ...additionalData };

            try {
                await dispatch(updateRequestTypeAction(requestType.id, data));
                setIsUpdating(false);
                dispatch(showSnackbar(successMessage));
            } catch (e) {
                setIsUpdating(false);
                setUpdateError(e?.message);
            }
        },
        [formValues, requestType, dispatch]
    );

    const saveRequestType = useCallback(() => updateRequestType(), [updateRequestType]);
    const saveRequestTypeName = useCallback(
        (name) => updateRequestType({ name }),
        [updateRequestType]
    );
    const publishRequestType = useCallback(
        () => updateRequestType({ status: statusTypes.PUBLISHED }, 'Request type set live!'),
        [updateRequestType]
    );
    const archiveRequestType = useCallback(
        () => updateRequestType({ status: statusTypes.ARCHIVED }, 'Request type has been archived'),
        [updateRequestType]
    );
    const unpublishRequestType = useCallback(
        () =>
            updateRequestType(
                { status: statusTypes.DRAFT },
                `Request type has been ${requestType.status === statusTypes.PUBLISHED ? 'unpublished' : 'restored'}`
            ),
        [updateRequestType, requestType]
    );

    const disabled = isCopying || isDeleting || isUpdating;
    const error = copyError || deleteError || updateError;

    const memoizedValue = useMemo(
        () => ({
            archiveRequestType,
            copyError,
            copyRequestType,
            deleteError,
            deleteRequestType,
            disabled,
            error,
            formErrorsCount,
            isCopying,
            isDeleting,
            isUpdating,
            publishRequestType,
            selectedSection,
            setFormErrorsCount,
            setSelectedSection,
            setShowFormValidation,
            showFormValidation,
            saveRequestType,
            saveRequestTypeName,
            unpublishRequestType,
            updateError,
        }),
        [
            archiveRequestType,
            copyError,
            copyRequestType,
            deleteError,
            deleteRequestType,
            disabled,
            error,
            formErrorsCount,
            isCopying,
            isDeleting,
            isUpdating,
            publishRequestType,
            selectedSection,
            setFormErrorsCount,
            setSelectedSection,
            saveRequestType,
            saveRequestTypeName,
            setShowFormValidation,
            showFormValidation,
            unpublishRequestType,
            updateError,
        ]
    );

    return (
        <RequestTypeActionsContext.Provider value={memoizedValue}>
            <CustomFormEditorProvider
                customFormId={requestType.customFormId}
                isSectionSelected={selectedSection === sectionNames.CUSTOM_FORM}
                setIsSectionSelected={() => setSelectedSection(sectionNames.CUSTOM_FORM)}
            >
                {children}
            </CustomFormEditorProvider>
        </RequestTypeActionsContext.Provider>
    );
};

RequestTypeActionsProvider.propTypes = {
    children: PropTypes.node,
    requestType: PropTypes.shape({
        id: PropTypes.number.isRequired,
        status: PropTypes.string.isRequired,
        // Could be null when a custom fields section is added for the first time
        customFormId: PropTypes.number,
    }).isRequired,
};
