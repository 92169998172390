import { browserHistory } from '@og-pro-migration-tools/react-router';
import rollbar from '@og-pro/rollbar/client';
import { projectTypesDict } from '@og-pro/shared-config/projects';

import {
    hideConfirmationModal,
    showConfirmationModal,
    showConfirmationModalError,
    updatingConfirmationModal,
} from './confirmation';
import { loadContracts } from './contracts';
import { showExportModal } from './exportProject';
import { loadAndShowComments, toggleCommentsDisplay } from './govComments';
import { showInviteModal } from './govPermissions';
import { showNotification, showSnackbar } from './notification';
import { showProjectCreateModal } from './project/create/projectCreate';
import { showPostConfirmationModal, showShareLinkModal } from './projectPost';
import { subscribe, unsubscribe } from './subscriptions';
import { showExpandedSupplierNetworkModal, showPreInviteModal } from './govProjects/preInvite';
import { emitProjectSocket, reloadThrottler } from './utils';
import * as menuActions from '../constants/menuActions';
import { trackEvent } from '../helpers';

export const SHOW_INSTRUCTIONS_MODAL = 'gov/projects/SHOW_INSTRUCTIONS_MODAL';
export const HIDE_INSTRUCTIONS_MODAL = 'gov/projects/HIDE_INSTRUCTIONS_MODAL';

export function showInstructionsModal(instructionType, instructionData = {}) {
    return { type: SHOW_INSTRUCTIONS_MODAL, instructionType, instructionData };
}

export function hideInstructionsModal() {
    return { type: HIDE_INSTRUCTIONS_MODAL };
}

export const SHOW_PUBLIC_DISPLAY_OPTIONS_MODAL = 'gov/projects/SHOW_PUBLIC_DISPLAY_OPTIONS_MODAL';
export const HIDE_PUBLIC_DISPLAY_OPTIONS_MODAL = 'gov/projects/HIDE_PUBLIC_DISPLAY_OPTIONS_MODAL';

export const SHOW_EDIT_TIMELINES_MODAL = 'gov/projects/SHOW_EDIT_TIMELINES_MODAL';
export const HIDE_EDIT_TIMELINES_MODAL = 'gov/projects/HIDE_EDIT_TIMELINES_MODAL';

function showPublicDisplayOptionsModal() {
    return { type: SHOW_PUBLIC_DISPLAY_OPTIONS_MODAL };
}

export function hideEditTimelinesModal() {
    return { type: HIDE_EDIT_TIMELINES_MODAL };
}

export function hidePublicDisplayOptionsModal() {
    return { type: HIDE_PUBLIC_DISPLAY_OPTIONS_MODAL };
}

export const LOAD = 'gov/projects/LOAD';
export const LOAD_SUCCESS = 'gov/projects/LOAD_SUCCESS';
export const LOAD_FAIL = 'gov/projects/LOAD_FAIL';

export function govLoadProject(projectId) {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD });
        return client
            .get(`/project/${projectId}/government`)
            .then((result) => {
                dispatch({ type: LOAD_SUCCESS, result });
                return dispatch(loadAndShowComments(result));
            })
            .catch((error) => dispatch({ type: LOAD_FAIL, error }));
    };
}

export const RESET_FORCE_RELOAD = 'gov/projects/RESET_FORCE_RELOAD';

export function resetForceReloadProject() {
    return { type: RESET_FORCE_RELOAD };
}

const shouldThrottleProjectReload = reloadThrottler();

export function reloadProject(projectId) {
    return (dispatch, getState, client) => {
        const lastUpdated = getState().govProjects.getIn(['selectedProject', 'updated_at']);

        // In theory, this value should always exist, but check just to be sure to prevent an
        // infinite loop
        if (!lastUpdated) {
            rollbar.info(`No last updated at date on project ${projectId}`, {
                location: window?.location?.href,
            });
            return;
        }

        const shouldThrottle = shouldThrottleProjectReload(projectId);
        if (shouldThrottle) {
            rollbar.info(`Stopping reload on project ${projectId}`, {
                location: window?.location?.href,
            });
            return;
        }

        return client
            .get(`/project/${projectId}`)
            .then((result) => {
                // Reload the project if an update has transpired
                if (result.updated_at !== lastUpdated) {
                    dispatch(
                        showSnackbar('Received New Updates', {
                            dismissAfter: 3500,
                        })
                    );
                    return dispatch(govLoadProject(projectId));
                }
            })
            .catch(() => {}); // Catch error to avoid it being unhandled, but don't need to do anything with it
    };
}

export const UPDATE = 'gov/projects/UPDATE';
export const UPDATE_SUCCESS = 'gov/projects/UPDATE_SUCCESS';
export const UPDATE_FAIL = 'gov/projects/UPDATE_FAIL';

/**
 * Updates a project outside of the project create forms. When needing to update projects from
 * within a project form use the update function in `actions/project/create/projectCreate.js`
 * @param {number} project project to update
 * @param {object} data Update data
 * @param {object} [opts={}] Options object
 * @param {string} [opts.endpoint] Use a different endpoint than the standard project update
 * @param {boolean} [opts.modal] Used when update is performed within the confirmation modal
 * @param {function} [opts.onComplete] Function to run after successful save
 * @param {string} [opts.snackbarMessage] Snackbar message to display on update success
 * @return {function} Redux decorated function
 */
export function govUpdateProject(project, data, opts = {}) {
    return (dispatch, getState, client) => {
        dispatch({ type: UPDATE });
        if (opts.modal) {
            dispatch(updatingConfirmationModal());
        }
        const title = project.type === projectTypesDict.CONTRACT ? 'Document' : 'Project';
        const endpoint = opts.endpoint || `/project/${project.id}`;
        return client
            .put(endpoint, { data })
            .then((result) => {
                const updateAction = { type: UPDATE_SUCCESS, result };
                dispatch(emitProjectSocket(project.id, updateAction));
                if (opts.modal) {
                    dispatch(hideConfirmationModal());
                    dispatch(showNotification(`${title} successfully updated!`));
                }
                if (opts.snackbarMessage) {
                    dispatch(showSnackbar(opts.snackbarMessage));
                }
                if (opts.onComplete) {
                    return opts.onComplete(result);
                }
            })
            .catch((error) => {
                dispatch({ type: UPDATE_FAIL, error });
                const errorMessage = `${title} could not be updated. ${error.message}`;
                if (opts.rethrowError) {
                    throw error;
                }
                if (opts.modal) {
                    dispatch(showConfirmationModalError(errorMessage));
                }
                if (opts.snackbarMessage) {
                    dispatch(showSnackbar(errorMessage, { isError: true }));
                }
            });
    };
}

export function govDeleteProject(project) {
    return (dispatch, getState, client) => {
        dispatch(updatingConfirmationModal());

        const title = project.type === projectTypesDict.CONTRACT ? 'Document' : 'Project';
        return client
            .del(`/project/${project.id}`)
            .then((result) => {
                dispatch(hideConfirmationModal());
                const govId = result.government_id;

                // If project had a intake instead route to the intake page
                if (result.intake_id) {
                    // The project was updated, not deleted
                    trackEvent(`${title} Status Update`, {
                        projectId: project.id,
                        oldStatus: project.status,
                        newStatus: result.status,
                    });
                    dispatch(showSnackbar(`Reopened ${title} Request`));
                    browserHistory.push(
                        `/governments/${govId}/projects/${result.intake_id}/manage`
                    );
                } else {
                    // The project was deleted
                    trackEvent(`${title} Deleted`, {
                        projectId: project.id,
                        status: project.status,
                    });
                    dispatch(showNotification(`${title} successfully deleted`));
                    const isContractDocument = project.type === projectTypesDict.CONTRACT;
                    const path = isContractDocument ? 'contracts' : 'projects';
                    browserHistory.push(`/governments/${govId}/${path}`);
                }
            })
            .catch((error) => {
                dispatch(
                    showConfirmationModalError(`${title} could not be deleted: ${error.message}`)
                );
            });
    };
}

export const LOAD_PROJECT_ASSOCIATIONS = 'gov/projects/LOAD_PROJECT_ASSOCIATIONS';
export const LOAD_PROJECT_ASSOCIATIONS_SUCCESS = 'gov/projects/LOAD_PROJECT_ASSOCIATIONS_SUCCESS';
export const LOAD_PROJECT_ASSOCIATIONS_FAIL = 'gov/projects/LOAD_PROJECT_ASSOCIATIONS_FAIL';

export function loadAssociatedProjects(projectId) {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD_PROJECT_ASSOCIATIONS });
        return client
            .get(`/project/${projectId}/project-associations`)
            .then((result) => {
                dispatch({ type: LOAD_PROJECT_ASSOCIATIONS_SUCCESS, result });
            })
            .catch((error) => {
                dispatch({ type: LOAD_PROJECT_ASSOCIATIONS_FAIL, error });
            });
    };
}

export const SHOW_VENDOR_DISCOVERY_MODAL = 'gov/projects/SHOW_VENDOR_DISCOVERY_MODAL';
export const HIDE_VENDOR_DISCOVERY_MODAL = 'gov/projects/HIDE_VENDOR_DISCOVERY_MODAL';

export function showVendorDiscoveryModal() {
    return { type: SHOW_VENDOR_DISCOVERY_MODAL };
}

export function hideVendorDiscoveryModal() {
    return { type: HIDE_VENDOR_DISCOVERY_MODAL };
}

export function menuActionHandler(actionType, project, opts) {
    return (dispatch) => {
        const { id, government_id: govId } = project;

        const projectPath = `/governments/${govId}/projects/${id}`;
        switch (actionType) {
            case menuActions.EDIT: {
                const editRoute = `${projectPath}/builder/create`;
                return browserHistory.push(editRoute);
            }
            case menuActions.EDIT_POST: {
                const sourcingUrl = `${projectPath}/sourcing/create`;
                return browserHistory.push(sourcingUrl);
            }
            case menuActions.EXPORT:
                return dispatch(showExportModal());
            case menuActions.PREVIEW:
                return dispatch(showExportModal('preview'));
            case menuActions.SUBSCRIBE:
                return dispatch(subscribe(project, true));
            case menuActions.UNSUBSCRIBE:
                return dispatch(unsubscribe(project, true));
            case menuActions.TOGGLE_COMMENTS:
                return dispatch(toggleCommentsDisplay(id));
            case menuActions.INVITE_COLLABORATORS:
                return dispatch(showInviteModal(id));
            case menuActions.PUBLISH:
                return dispatch(showPostConfirmationModal());
            case menuActions.COPY_LINK:
                return dispatch(showShareLinkModal(project.id, project.government.code));
            case menuActions.ASSOCIATED_PROJECT:
                return dispatch(showProjectCreateModal({ associatedProject: project, steps: [2] }));
            case menuActions.EDIT_PRE_INVITE_LIST:
                return dispatch(showPreInviteModal(id));
            case menuActions.AWARD_PROJECT_FROM_PENDING:
            case menuActions.CLOSE_PROJECT:
            case menuActions.CONSENSUS_EVALUATION:
            case menuActions.COPY_DOCUMENT:
            case menuActions.CREATE_CONTRACT_FROM_INTAKE:
            case menuActions.CREATE_CONTRACT_FROM_PROJECT:
            case menuActions.CREATE_INTAKE_FROM_PROJECT:
            case menuActions.DELETE:
            case menuActions.DELETE_EVALUATION:
            case menuActions.DELETE_POST:
            case menuActions.DELETE_INTAKE:
            case menuActions.END_AUCTION_REJECT_RESULTS:
            case menuActions.EVALUATE:
            case menuActions.FINALIZE:
            case menuActions.FINALIZE_AWARD:
            case menuActions.FINALIZE_CONTRACT_PACKAGE:
            case menuActions.PAUSE_PROJECT:
            case menuActions.POST:
            case menuActions.RELEASE_EVALUATION:
            case menuActions.REOPEN_BUILDER:
            case menuActions.REOPEN_CLOSED_PROJECT:
            case menuActions.REOPEN_CLOSED_INTAKE:
            case menuActions.REOPEN_DRAFT_STATUS:
            case menuActions.REOPEN_PROJECT_INTAKE:
            case menuActions.INTAKE_INCOMPLETE:
            case menuActions.RESEAL_BIDS:
            case menuActions.RETRACT_POST:
            case menuActions.SKIP_TO_NEXT_STAGE:
            case menuActions.SUBMIT_INTAKE_FOR_REVIEW:
            case menuActions.UNAWARD_PROJECT:
            case menuActions.UNFINALIZE:
            case menuActions.UNSEAL_BIDS:
            case menuActions.VENDOR_FOLLOW:
                return dispatch(showConfirmationModal(actionType, { project, ...opts }));
            case menuActions.VENDOR_DISCOVERY:
                return dispatch(showVendorDiscoveryModal());
            case menuActions.NEW_PROJECT_DOC_BUILDER:
                return dispatch(
                    showProjectCreateModal({
                        createData: {
                            project_folder_id: project.id,
                        },
                        isDocBuilder: true,
                        steps: [2],
                    })
                );
            case menuActions.PUBLIC_DISPLAY_OPTIONS:
                return dispatch(showPublicDisplayOptionsModal());
            case menuActions.TOGGLE_EDIT_TIMELINES_MODAL:
                return dispatch({ type: SHOW_EDIT_TIMELINES_MODAL });
            case menuActions.TOGGLE_SHOW_EXPANDED_SUPPLIER_NETWORK_MODAL:
                return dispatch(showExpandedSupplierNetworkModal());
            default:
                return null;
        }
    };
}

export function shouldLoadProject(state, projectId) {
    if (state.govProjects.get('loadingSelected')) return false;
    if (!state.govProjects.get('selectedProject')) return true;
    return state.govProjects.getIn(['selectedProject', 'id']) !== projectId;
}

export const RESET = 'gov/projects/RESET';

export function resetProject() {
    return { type: RESET };
}

export function updateProjectSections(projectId, data) {
    return (dispatch, getState, client) => {
        return client.put(`/project/${projectId}/project-sections`, { data }).then((result) => {
            const updateAction = { type: UPDATE_SUCCESS, result };
            dispatch(emitProjectSocket(projectId, updateAction));
            dispatch(showSnackbar('Project Sections Updated'));
        });
    };
}

export function copyProjectSection(projectId, projectSectionId) {
    return (dispatch, getState, client) => {
        const data = { projectSectionId };
        return client
            .post(`/project/${projectId}/project-sections/copy`, { data })
            .then((result) => {
                const updateAction = { type: UPDATE_SUCCESS, result };
                dispatch(emitProjectSocket(projectId, updateAction));
                dispatch(showSnackbar('Project Section Copied!'));
            });
    };
}

export const LOAD_PROJECT_DOCUMENTS = 'gov/projects/LOAD_PROJECT_DOCUMENTS';
export const LOAD_PROJECT_DOCUMENTS_SUCCESS = 'gov/projects/LOAD_PROJECT_DOCUMENTS_SUCCESS';
export const LOAD_PROJECT_DOCUMENTS_FAIL = 'gov/projects/LOAD_PROJECT_DOCUMENTS_FAIL';

export function loadProjectDocuments(projectId) {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD_PROJECT_DOCUMENTS });
        return Promise.all([
            dispatch(loadContracts({ projectId, rethrowError: true })),
            client.get(`/project/${projectId}/doc-builders`),
        ])
            .then(([contracts, docBuilders]) => {
                dispatch({ type: LOAD_PROJECT_DOCUMENTS_SUCCESS, contracts, docBuilders });
            })
            .catch((error) => dispatch({ type: LOAD_PROJECT_DOCUMENTS_FAIL, error }));
    };
}

export const LOAD_PROJECT_STATUS_HISTORY = 'gov/projects/LOAD_PROJECT_STATUS_HISTORY';
export const LOAD_PROJECT_STATUS_HISTORY_SUCCESS =
    'gov/projects/LOAD_PROJECT_STATUS_HISTORY_SUCCESS';
export const LOAD_PROJECT_STATUS_HISTORY_FAIL = 'gov/projects/LOAD_PROJECT_STATUS_HISTORY_FAIL';

export function loadProjectStatusHistory(projectId) {
    return (dispatch, getState, client) => {
        dispatch({ type: LOAD_PROJECT_STATUS_HISTORY });
        return client
            .get(`/project/${projectId}/status-history`)
            .then((result) => dispatch({ type: LOAD_PROJECT_STATUS_HISTORY_SUCCESS, result }))
            .catch((error) => dispatch({ type: LOAD_PROJECT_STATUS_HISTORY_FAIL, error }));
    };
}

export function unsealProposalsPricing(projectId, proposalIds) {
    return (dispatch, getState, client) => {
        const data = { proposalIds };
        return client.post(`/project/${projectId}/proposal/unseal-pricing`, { data });
    };
}

export function reopenClosedProject(projectId) {
    return (dispatch, getState, client) => {
        dispatch(updatingConfirmationModal());
        return client
            .put(`/project/${projectId}/reopen`)
            .then((result) => {
                const updateAction = { type: UPDATE_SUCCESS, result };
                dispatch(emitProjectSocket(projectId, updateAction));
                dispatch(hideConfirmationModal());
                dispatch(
                    showSnackbar(`Project Re-Opened to "${result.status.toUpperCase()}" status!`)
                );
            })
            .catch((error) => {
                dispatch(showConfirmationModalError(error.message));
            });
    };
}

export const GET_PROPOSAL_VIEWER_AGREEMENT_DATA = 'gov/projects/GET_PROPOSAL_VIEWER_AGREEMENT_DATA';
export const GET_PROPOSAL_VIEWER_AGREEMENT_DATA_FAIL =
    'gov/projects/GET_PROPOSAL_VIEWER_AGREEMENT_DATA_FAIL';
export const GET_PROPOSAL_VIEWER_AGREEMENT_DATA_SUCCESS =
    'gov/projects/GET_PROPOSAL_VIEWER_AGREEMENT_DATA_SUCCESS';

export function getProposalViewerAgreementData(projectId) {
    return (dispatch, getState, client) => {
        dispatch({ type: GET_PROPOSAL_VIEWER_AGREEMENT_DATA });
        return client
            .get(`/project/${projectId}/agreement`)
            .then((result) => {
                dispatch({ type: GET_PROPOSAL_VIEWER_AGREEMENT_DATA_SUCCESS, result });
            })
            .catch((error) => {
                dispatch({ type: GET_PROPOSAL_VIEWER_AGREEMENT_DATA_FAIL, error });
            });
    };
}

export const SUBMIT_PROPOSAL_VIEWER_AGREEMENT = 'gov/projects/SUBMIT_PROPOSAL_VIEWER_AGREEMENT';

export function submitProposalViewerAgreement(projectId) {
    return (dispatch, getState, client) => {
        return client.post(`/project/${projectId}/agreement`).then((result) => {
            const action = { type: SUBMIT_PROPOSAL_VIEWER_AGREEMENT, result };
            dispatch(emitProjectSocket(projectId, action, 'Response viewer agreement accepted'));
        });
    };
}

export function getSubscribersToNotify(projectId) {
    return (dispatch, getState, client) => {
        return client.get(`/project/${projectId}/vendors/subscribers-to-notify`);
    };
}

export const LOAD_PROJECT_REQ_RELATIONS_SUCCESS = 'gov/projects/LOAD_PROJECT_REQ_RELATIONS_SUCCESS';
export const CREATE_PROJECT_REQ_RELATIONS_SUCCESS =
    'gov/projects/CREATE_PROJECT_REQ_RELATIONS_SUCCESS';

export function loadProjectReqRelations(projectId) {
    return (dispatch, getState, client) => {
        return client
            .get(`/project/${projectId}/requisitions`)
            .then((result) => dispatch({ type: LOAD_PROJECT_REQ_RELATIONS_SUCCESS, result }));
    };
}

export function addRequisitionToProject(projectId, requisitionIds) {
    return (dispatch, getState, client) => {
        return client
            .post(`/project/${projectId}/requisitions`, { data: { requisitionIds } })
            .then((result) => {
                dispatch(showSnackbar(`${result.length} requests added!`));
                dispatch({ type: CREATE_PROJECT_REQ_RELATIONS_SUCCESS, result });
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(`Request could not be added: ${error.message}`, {
                        isError: true,
                    })
                );
            });
    };
}
