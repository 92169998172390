import {
    ArrowForwardIos as ArrowForwardIosIcon,
    Cancel as CancelIcon,
    Upload as UploadIcon,
} from '@mui/icons-material';
import { projectTypesDict } from '@og-pro/shared-config/projects';
import { defaultSectionConfigsMap, sectionTypeNames } from '@og-pro/shared-config/sections';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Typography } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Field, FieldArray, formValueSelector, reduxForm } from 'redux-form';

import { AiImportSubsectionList } from './AiImportSubsectionList';
import { validate } from './validate';
import { TemplateEditV2FunctionsContext } from '../../../../context';
import {
    InputText,
    SearchSelect,
    SearchSelectIconOption,
} from '../../../../../../../../components';

// @reduxForm
const ConnectedAiImportSectionForm = ({
    disabled: disabledProp,
    form,
    handleSubmit,
    importing,
    removeSection,
    submitFailed,
    uuid,
}) => {
    const { templateProject } = useContext(TemplateEditV2FunctionsContext);

    const title = useSelector((state) => formValueSelector(form)(state, 'title'));
    const items = useSelector((state) => formValueSelector(form)(state, 'items'));
    const projectSectionType = useSelector((state) =>
        formValueSelector(form)(state, 'projectSectionType')
    );

    const disabled = disabledProp || importing;
    const isTextArea = projectSectionType === sectionTypeNames.TEXT_AREA;

    const projectSectionTypeOptions = useMemo(() => {
        const isPurchase = templateProject.type === projectTypesDict.PURCHASE;
        const allowableSpecialSections = isPurchase
            ? [[sectionTypeNames.SCOPE], [sectionTypeNames.EVALUATION_PHASE]]
            : [];

        return [
            [sectionTypeNames.TERMS],
            [sectionTypeNames.TEXT_AREA, items.length > 1],
            ...allowableSpecialSections,
        ].map(([sectionType, isDisabled]) => ({
            icon: defaultSectionConfigsMap[sectionType].icon,
            isDisabled,
            label: defaultSectionConfigsMap[sectionType].title,
            value: sectionType,
        }));
    }, [items.length, templateProject.type]);

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ArrowForwardIosIcon sx={{ fontSize: 'medium', paddingRight: 0.5 }} />}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                    <Typography variant="h4">{title || 'Untitled'}</Typography>
                    <Typography variant="bodySmall">
                        Section Type: {defaultSectionConfigsMap[projectSectionType]?.title} &bull;
                        Items: {items.length}
                    </Typography>
                </Box>
                <Box
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    sx={{ display: 'flex', gap: 1 }}
                >
                    <Box sx={{ width: 185 }}>
                        <Field
                            component={SearchSelect}
                            components={{
                                Option: SearchSelectIconOption,
                            }}
                            disabled={disabled}
                            isSearchable={false}
                            name="projectSectionType"
                            noMarginBottom
                            options={projectSectionTypeOptions}
                            qaTag="aiImporter-projectSectionType"
                            styles={{ menu: { zIndex: 2 } }}
                            useOpenGovStyle
                        />
                    </Box>
                    <Button
                        color="error"
                        disabled={disabled}
                        onClick={() => {
                            removeSection();
                        }}
                        qaTag="aiImporter-rejectSection"
                        startIcon={<CancelIcon />}
                    >
                        Reject Section
                    </Button>
                    <Button
                        color="primary"
                        disabled={disabled}
                        onClick={handleSubmit}
                        qaTag="aiImporter-importSection"
                        startIcon={<UploadIcon />}
                    >
                        Import Section
                    </Button>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <Box className="row">
                    <Box className="col-xs-1 text-right" sx={{ paddingTop: 1 }}>
                        <Typography>Section Title</Typography>
                    </Box>
                    <Box className="col-xs-11">
                        <Field
                            component={InputText}
                            disabled={disabled}
                            hasFeedback={false}
                            name="title"
                            qaTag="aiImporter-title"
                            showValidation={submitFailed}
                            type="text"
                            useOpenGovStyle
                        />
                    </Box>
                </Box>
                <FieldArray
                    component={AiImportSubsectionList}
                    disabled={disabled}
                    isTextArea={isTextArea}
                    name="items"
                    showValidation={submitFailed}
                    uuid={uuid}
                />
            </AccordionDetails>
        </Accordion>
    );
};

ConnectedAiImportSectionForm.propTypes = {
    disabled: PropTypes.bool.isRequired,
    form: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    importing: PropTypes.bool.isRequired,
    removeSection: PropTypes.func.isRequired,
    submitFailed: PropTypes.bool,
    uuid: PropTypes.string.isRequired,
};

export const AiImportSectionForm = reduxForm({ validate })(ConnectedAiImportSectionForm);
