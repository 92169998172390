import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Box, Typography } from '@og-pro/ui';
import { EVENT_TYPES, DOCUSIGN_EVENT_TYPES } from '@og-pro/shared-config/electronicSignatures';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';
import { projectStatusesDict } from '@og-pro/shared-config/projects';

import { StatusCellRenderer } from '../StatusCellRenderer';
import {
    Pencil as PencilIcon,
    Sign as SignIcon,
    Person as PersonIcon,
    File as FileIcon,
    Download as DownloadIcon,
    Warning as WarningIcon,
} from './Icons';

const { colors } = capitalDesignTokens.foundations;

const STATUS_ICONS = {
    [projectStatusesDict.DRAFT]: <PencilIcon />,
    [projectStatusesDict.FINAL]: <FileIcon />,
    [projectStatusesDict.REVIEW]: <PersonIcon />,
    attachment: <DownloadIcon />,
};

const LineItemLayout = ({ icon, content, date }) => {
    return (
        <Box alignItems="center" display="flex">
            <Box alignItems="center" display="flex" justifyContent="center" mr={2}>
                {icon}
            </Box>
            <Box>
                <Typography>{content}</Typography>
                {!!date && (
                    <Typography sx={{ color: colors.gray700 }} variant="body2">
                        on {moment(date).format('MM/DD/YY [at] h:mm A')}
                    </Typography>
                )}
            </Box>
        </Box>
    );
};

LineItemLayout.propTypes = {
    date: PropTypes.string,
    icon: PropTypes.node.isRequired,
    content: PropTypes.node.isRequired,
};

export const StatusHistoryLineItem = ({ item }) => {
    switch (item.type) {
        case EVENT_TYPES.CREATED:
            return (
                <LineItemLayout
                    content={
                        <>
                            Contract packet has been&nbsp;
                            <Box component="span" sx={{ color: colors.purple700, fontWeight: 600 }}>
                                Sent for Signature
                            </Box>
                            &nbsp;by {item?.data?.user?.fullName || 'user'}
                        </>
                    }
                    date={item.date}
                    icon={<SignIcon />}
                />
            );
        case EVENT_TYPES.CANCELLED:
            return (
                <LineItemLayout
                    content={
                        <>
                            Contract packet has been&nbsp;
                            <Box component="span" sx={{ color: colors.ruby700, fontWeight: 600 }}>
                                Canceled
                            </Box>
                            &nbsp;by {item.user?.fullName || 'user'}
                        </>
                    }
                    date={item.date}
                    icon={<SignIcon />}
                />
            );
        case EVENT_TYPES.READY:
            return (
                <LineItemLayout
                    content={
                        <>
                            Contract packet is&nbsp;
                            <Box component="span" sx={{ color: colors.pear700, fontWeight: 600 }}>
                                Ready to be Signed
                            </Box>
                        </>
                    }
                    date={item.date}
                    icon={<SignIcon />}
                />
            );
        case EVENT_TYPES.ERRORED: {
            return (
                <LineItemLayout
                    content={
                        <>
                            Contract packet&nbsp;
                            <Box component="span" sx={{ color: colors.ruby700, fontWeight: 600 }}>
                                Failed to Send for Signature
                            </Box>
                        </>
                    }
                    date={item.date}
                    icon={<WarningIcon />}
                />
            );
        }
        case DOCUSIGN_EVENT_TYPES.RECIPIENT_DELIVERED: {
            const signer = item.data.signers.find((s) => s.recipientId === item.recipientId);

            return (
                <LineItemLayout
                    content={
                        <>Contract packet was delivered to {signer ? signer.name : 'a signer'}.</>
                    }
                    date={item.date}
                    icon={<SignIcon />}
                />
            );
        }
        case DOCUSIGN_EVENT_TYPES.RECIPIENT_COMPLETED: {
            const signer = item.data.signers.find((s) => s.recipientId === item.recipientId);
            const signed = item.data.signers.filter((s) => s.status === 'completed');

            return (
                <LineItemLayout
                    content={
                        <>
                            Contract packet was signed by {signer ? signer.name : 'a signer'}
                            .&nbsp;
                            <Box component="span" sx={{ color: colors.purple700, fontWeight: 600 }}>
                                {signed.length}/{item.data.signers.length} Signed
                            </Box>
                        </>
                    }
                    date={item.date}
                    icon={<SignIcon />}
                />
            );
        }
        case DOCUSIGN_EVENT_TYPES.ENVELOPE_COMPLETED: {
            return (
                <LineItemLayout
                    content={
                        <>
                            Contract packet has been&nbsp;
                            <Box
                                component="span"
                                sx={{ color: colors.cerulean800, fontWeight: 600 }}
                            >
                                Fully Signed
                            </Box>
                        </>
                    }
                    date={item.date}
                    icon={<SignIcon />}
                />
            );
        }
        case 'audit':
            return (
                <LineItemLayout
                    content={
                        <>
                            Contract document has changed to{' '}
                            {StatusCellRenderer({ value: item.status })} by &nbsp;
                            {item.user?.fullName || 'user'}
                        </>
                    }
                    date={item.date}
                    icon={STATUS_ICONS[item.status] || <PencilIcon />}
                />
            );
        case 'attachment':
            return (
                <LineItemLayout
                    content={
                        <>
                            A new version of the Contract Packet document has been generated.{' '}
                            <a download href={item.data.url}>
                                Download here
                            </a>
                            .
                        </>
                    }
                    date={item.date}
                    icon={STATUS_ICONS[item.type] || <PencilIcon />}
                />
            );
        default:
            return null;
    }
};

StatusHistoryLineItem.propTypes = {
    item: PropTypes.shape({
        type: PropTypes.string.isRequired,
        data: PropTypes.shape({
            signers: PropTypes.arrayOf(PropTypes.object),
            user: PropTypes.shape({ fullName: PropTypes.string.isRequired }),
            url: PropTypes.string,
        }),
        recipientId: PropTypes.string,
        date: PropTypes.string.isRequired,
        user: PropTypes.shape({ fullName: PropTypes.string.isRequired }),
        status: PropTypes.string,
    }).isRequired,
};
