import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Typography } from '@og-pro/ui';
import { tokens } from '@opengov/capital-style';

import { ReviewChecklistExpandableSection } from './ExpandableSection';
import { ReviewChecklistSection } from './Section';

export const ReviewChecklistSectionsContainer = ({
    buildRoute,
    childSectionProps = {},
    hideContainer,
    hideStatus,
    sections,
    route,
    title,
}) => {
    const styles = require('./index.scss');

    const navigate = useNavigate();

    return (
        <Box className={hideContainer ? undefined : styles.sectionsContainer}>
            {title && (
                <Typography
                    className={styles.section}
                    pb={1}
                    style={{ fontWeight: tokens.typography.fontWeightRegular }}
                    variant="h4"
                >
                    {title}
                </Typography>
            )}
            {sections.map((section, i) => {
                if (section.children && section.children.length) {
                    return (
                        <ReviewChecklistExpandableSection
                            incompleteFields={
                                section.children.filter((c) => c.showValidationError).length
                            }
                            key={i}
                            last={i === sections.length - 1}
                            section={section}
                        >
                            {section.children.map((child, ii) => {
                                return (
                                    <ReviewChecklistSection
                                        containerProps={childSectionProps}
                                        hideStatus={hideStatus}
                                        isChild
                                        key={ii}
                                        last={
                                            i === sections.length - 1 &&
                                            ii === section.children.length - 1
                                        }
                                        onClick={() =>
                                            navigate(
                                                route ||
                                                    `${buildRoute(
                                                        'document-editor'
                                                    )}?activeSection=${child.index}`
                                            )
                                        }
                                        section={child}
                                    />
                                );
                            })}
                        </ReviewChecklistExpandableSection>
                    );
                }

                return (
                    <ReviewChecklistSection
                        containerProps={childSectionProps}
                        hideStatus={hideStatus}
                        isChild={title !== undefined}
                        key={i}
                        last={i === sections.length - 1}
                        onClick={() =>
                            navigate(
                                `${route || buildRoute('document-editor')}?activeSection=${section.index}`
                            )
                        }
                        section={section}
                    />
                );
            })}
        </Box>
    );
};

ReviewChecklistSectionsContainer.propTypes = {
    buildRoute: PropTypes.func.isRequired,
    childSectionProps: PropTypes.object,
    hideContainer: PropTypes.bool,
    hideStatus: PropTypes.bool,
    route: PropTypes.string,
    sections: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            children: PropTypes.array,
        })
    ).isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
