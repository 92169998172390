import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { ProgressBar as ProgressBarBS } from 'react-bootstrap';
import { bootstrapUtils } from 'react-bootstrap/lib/utils';

import { AriaLabelSetter } from '../../helpers';

// Custom bootstrap progress bar styles. Allows passing these strings as `bsStyle` values.
bootstrapUtils.addStyle(ProgressBarBS, 'royal');
bootstrapUtils.addStyle(ProgressBarBS, 'primary');
bootstrapUtils.addStyle(ProgressBarBS, 'success-cds');
bootstrapUtils.addStyle(ProgressBarBS, 'success-dark');
bootstrapUtils.addStyle(ProgressBarBS, 'success-light');
bootstrapUtils.addStyle(ProgressBarBS, 'info-dark');
bootstrapUtils.addStyle(ProgressBarBS, 'info-light');
bootstrapUtils.addStyle(ProgressBarBS, 'warning-dark');
bootstrapUtils.addStyle(ProgressBarBS, 'warning-light');
bootstrapUtils.addStyle(ProgressBarBS, 'danger-dark');
bootstrapUtils.addStyle(ProgressBarBS, 'danger-light');

export const ProgressBar = (props) => {
    const {
        ariaLabel,
        className,
        footerLabel,
        noBottomMargin,
        useOpenGovStyle,
        useAriaLabelSetter = true,
        ...rest
    } = props;

    const styles = require('./index.scss');

    const content = (
        <ProgressBarBS
            className={classnames(className, {
                [styles.noBottomMargin]: noBottomMargin,
                [styles.openGovStyle]: useOpenGovStyle,
            })}
            {...rest}
        />
    );

    return (
        <>
            {useAriaLabelSetter ? (
                <AriaLabelSetter ariaLabel={ariaLabel} query=".progress-bar">
                    {content}
                </AriaLabelSetter>
            ) : (
                content
            )}
            {!!footerLabel && <div className={styles.footerLabel}>{footerLabel}</div>}
        </>
    );
};

ProgressBar.propTypes = {
    ariaLabel: PropTypes.string,
    className: PropTypes.string,
    footerLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    noBottomMargin: PropTypes.bool,
    useAriaLabelSetter: PropTypes.bool,
    useOpenGovStyle: PropTypes.bool,
};
