import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useParams, useLocation, Link } from 'react-router-dom';
import { Box, Tab, Tabs, Typography } from '@og-pro/ui';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';

import { isEmbedded } from '../../../helpers';
import { getGovernmentJS } from '../selectors';
import { useRouteMatch } from '../../hooks/routerHooks';

const {
    semanticColors: { background, border },
} = capitalDesignTokens;

export const GovPortalMain = () => {
    const params = useParams();
    const location = useLocation();

    const { displayPublicVendorInformation } = useSelector(getGovernmentJS);

    const { governmentCode } = params;

    const isEmbed = isEmbedded(location);

    const portalPath = `/portal/${isEmbed ? 'embed/' : ''}${governmentCode}`;

    const routeMatch = useRouteMatch([
        `${portalPath}${isEmbed ? '/project-list' : ''}`,
        `${portalPath}/calendar`,
        `${portalPath}/vendors`,
    ]);
    const currentTab = routeMatch?.pathnameBase;

    return (
        <Box
            backgroundColor={background.primary}
            border={`1px solid ${border.secondary}`}
            display="flex"
            flexDirection="column"
        >
            <Box
                borderBottom={`1px solid ${border.secondary}`}
                display="flex"
                flexDirection="column"
                gap={2}
                p={3}
                pb={0}
            >
                {!isEmbed && (
                    <Box>
                        <Typography variant="h1">Procurement Portal</Typography>
                    </Box>
                )}
                <Tabs value={currentTab}>
                    <Tab
                        component={Link}
                        key="projects-list"
                        label="Projects"
                        style={{
                            color: capitalDesignTokens.semanticColors.foreground.action,
                            outline: 'none',
                            textDecoration: 'none',
                        }}
                        to={`${portalPath}${isEmbed ? '/project-list' : ''}`}
                        value={`${portalPath}${isEmbed ? '/project-list' : ''}`}
                    />
                    <Tab
                        component={Link}
                        key="calendar"
                        label="Calendar"
                        style={{
                            color: capitalDesignTokens.semanticColors.foreground.action,
                            outline: 'none',
                            textDecoration: 'none',
                        }}
                        to={`${portalPath}/calendar`}
                        value={`${portalPath}/calendar`}
                    />
                    {displayPublicVendorInformation && (
                        <Tab
                            component={Link}
                            key="vendors"
                            label="Vendors"
                            style={{
                                color: capitalDesignTokens.semanticColors.foreground.action,
                                outline: 'none',
                                textDecoration: 'none',
                            }}
                            to={`${portalPath}/vendors`}
                            value={`${portalPath}/vendors`}
                        />
                    )}
                </Tabs>
            </Box>
            <Box p={3}>
                <Outlet />
            </Box>
        </Box>
    );
};
