import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'redux';

import { form, SHOW_PLANHOLDERS } from './constants';
import { Toggle } from '../../../../../components';
import { showConfirmationSimpleModal } from '../../../../../actions/confirmation';
import { govUpdateProject } from '../../../../../actions/govProjects';

const mapStateToProps = (state, props) => {
    return {
        initialValues: {
            [SHOW_PLANHOLDERS]: props.project.showPlanholders,
        },
        updating: state.govProjects.get('updateLoading'),
    };
};

const mapDispatchToProps = {
    govUpdateProject,
    showConfirmationSimpleModal,
};

const formConfig = {
    enableReinitialize: true,
    form,
};

// @connect
// @reduxForm
class ConnectedPlanholdersToggleForm extends Component {
    static propTypes = {
        govUpdateProject: PropTypes.func.isRequired,
        project: PropTypes.shape({
            id: PropTypes.number.isRequired,
            showPlanholders: PropTypes.bool.isRequired,
        }).isRequired,
        showConfirmationSimpleModal: PropTypes.func.isRequired,
        updating: PropTypes.bool,
    };

    get styles() {
        return require('./index.scss');
    }

    togglePlanholdersDisplay = (e, value) => {
        const { project } = this.props;
        const { id: projectId, showPlanholders } = project;
        // Prevent toggle from changing
        e.preventDefault();

        const data = { [SHOW_PLANHOLDERS]: value };

        const updateFunction = () => {
            this.props.govUpdateProject(project, data, {
                endpoint: `/project/${projectId}/post/planholders`,
                snackbarMessage: 'Followers list updated',
            });
        };
        return this.props.showConfirmationSimpleModal(updateFunction, {
            bsStyle: showPlanholders ? 'warning' : 'primary',
            btnText: `${showPlanholders ? 'Hide' : 'Show'} Followers`,
            icon: showPlanholders ? 'ban' : 'check',
            text: `Are you sure you want to publicly ${
                showPlanholders ? 'hide' : 'display'
            } followers list?`,
        });
    };

    render() {
        const { updating } = this.props;

        return (
            <Field
                component={Toggle}
                disabled={updating}
                formClassName={this.styles.publicFollowersToggle}
                label="Publicly Display Followers List?"
                labelInline
                labelInlineTogglePosition="right"
                name={SHOW_PLANHOLDERS}
                onChange={this.togglePlanholdersDisplay}
            />
        );
    }
}

export const PlanholdersToggleForm = compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig)
)(ConnectedPlanholdersToggleForm);
