import PropTypes from 'prop-types';
import React from 'react';
import { components } from 'react-select';

import { UserProfilePicture } from '../UserProfilePicture/UserProfilePicture';

// Used to add user profile pictures to the `SearchSelect` component options
export const SearchSelectUserOption = (props) => {
    const {
        data: { user, label },
        userKey,
    } = props;

    const userData = userKey ? props.data[userKey] : user;

    return (
        <components.Option {...props}>
            <UserProfilePicture horizontal user={userData} />
            &nbsp;&nbsp;
            {label}
        </components.Option>
    );
};

SearchSelectUserOption.propTypes = {
    data: PropTypes.shape({
        label: PropTypes.string.isRequired,
        user: PropTypes.object,
    }).isRequired,
    userKey: PropTypes.string,
};
