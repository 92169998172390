import React from 'react';

import { Well } from '../../../Well/Well';

export const ExcludedWell = () => {
    const styles = require('./shared.scss');

    return (
        <Well>
            <h4 className="text-center text-muted">
                <i className="fa fa-minus-circle" /> Response Excluded From Evaluation
            </h4>
            <p className={styles.proposalSubmittedInfo}>
                <em>
                    Response was excluded during the evaluation process and was not part of the
                    final evaluation
                </em>
            </p>
        </Well>
    );
};
