import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Tooltip } from '../../../Tooltip';

export class PriceTableHeader extends PureComponent {
    static propTypes = {
        displayName: PropTypes.string.isRequired,
        icon: PropTypes.string,
        isRequired: PropTypes.bool,
        tooltipText: PropTypes.string,
    };

    get styles() {
        return require('./index.scss');
    }

    renderContent() {
        const { displayName, icon, isRequired } = this.props;

        return (
            <div className={this.styles.headerWrapper}>
                {displayName}
                {isRequired && <span className="text-danger">*</span>}
                {icon && (
                    <>
                        &nbsp;
                        <i className={`fa ${icon}`} />
                    </>
                )}
            </div>
        );
    }

    render() {
        const { tooltipText } = this.props;

        if (tooltipText) {
            return (
                <Tooltip placement="bottom" tooltip={tooltipText}>
                    {this.renderContent()}
                </Tooltip>
            );
        }

        return this.renderContent();
    }
}
