import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Field } from 'redux-form';

import { TITLE } from '@og-pro/shared-config/questionnaires';

import { InputText } from '../../../InputText';

export class Title extends PureComponent {
    static propTypes = {
        disabled: PropTypes.bool,
        label: PropTypes.string,
        placeholder: PropTypes.string,
    };

    static defaultProps = {
        label: 'Question',
        placeholder: 'Ask a question',
    };

    render() {
        const { disabled, label, placeholder } = this.props;

        return (
            <Field
                component={InputText}
                disabled={disabled}
                label={label}
                name={TITLE}
                placeholder={placeholder}
                type="text"
            />
        );
    }
}
