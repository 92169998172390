import { round } from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import React, { PureComponent } from 'react';

import { ProgressBar } from '../ProgressBar/ProgressBar';

export class UploadProgress extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        error: PropTypes.string,
        filename: PropTypes.string.isRequired,
        progress: PropTypes.number,
        useOpenGovStyle: PropTypes.bool,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const { progress, filename, error, className, useOpenGovStyle } = this.props;

        return (
            <div className={classnames(className, { [this.styles.openGovStyle]: useOpenGovStyle })}>
                <div className="pull-right">{round(progress || 0)}%</div>
                <div className={this.styles.filename}>
                    <p>
                        {filename}
                        {error && ' '}
                        {error && <i className="fa fa-exclamation-triangle text-danger" />}
                    </p>
                </div>
                <ProgressBar
                    bsStyle="royal"
                    className={this.styles.progressBar}
                    now={progress}
                    useOpenGovStyle={useOpenGovStyle}
                />
                {error && <div className={this.styles.attachmentError}>{error}</div>}
            </div>
        );
    }
}
