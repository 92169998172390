import PropTypes from 'prop-types';
import moment from 'moment';
import React from 'react';

import { HtmlContent } from '../HtmlContent/HtmlContent';
import { LoadingError } from '../LoadingError/LoadingError';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';

export const ViewEmailNotificationModalBody = ({ emailData, loadError, loading }) => {
    if (loading) {
        return <LoadingSpinner />;
    }

    if (loadError || !emailData) {
        return <LoadingError error={loadError} />;
    }

    const renderFormattedEmailAddress = (emailUser) => {
        if (!emailUser.name) {
            return emailUser.email;
        }

        return (
            <>
                {emailUser.name} &lt;{emailUser.email}&gt;
            </>
        );
    };

    const { createdAt, from, html, subject, to } = emailData;

    return (
        <>
            <div>
                <strong>Date:</strong> {moment(createdAt).format('llll')}
            </div>
            <div>
                <strong>To:</strong> {renderFormattedEmailAddress(to)}
            </div>
            <div>
                <strong>From:</strong> {renderFormattedEmailAddress(from)}
            </div>
            <div>
                <strong>Subject:</strong> {subject}
            </div>
            <br />
            <HtmlContent content={html} />
        </>
    );
};

ViewEmailNotificationModalBody.propTypes = {
    emailData: PropTypes.shape({
        createdAt: PropTypes.string.isRequired,
        from: PropTypes.shape({
            email: PropTypes.string.isRequired,
            name: PropTypes.string,
        }).isRequired,
        html: PropTypes.string.isRequired,
        subject: PropTypes.string.isRequired,
        to: PropTypes.shape({
            email: PropTypes.string.isRequired,
            name: PropTypes.string,
        }).isRequired,
    }),
    loadError: PropTypes.string,
    loading: PropTypes.bool.isRequired,
};
