import PropTypes from 'prop-types';
import React from 'react';

import {
    LoadingError,
    LoadingSpinner,
    PlanholdersList as PlanholdersListComponent,
    ZeroState,
} from '../../../components';

export const PlanholdersList = ({
    error,
    isLoading,
    planholders,
    project,
    showSubmittedProposals,
}) => {
    if (isLoading) {
        return <LoadingSpinner />;
    }

    if (error) {
        return <LoadingError error={error} />;
    }

    if (planholders.length === 0) {
        return (
            <ZeroState
                title={
                    project.showPlanholders
                        ? 'No followers yet'
                        : 'Followers are not publicly available'
                }
            />
        );
    }

    return (
        <>
            <PlanholdersListComponent
                planholders={planholders}
                showSubmittedProposals={showSubmittedProposals}
            />
            {!project.showBids && showSubmittedProposals && (
                <p
                    className="text-muted text-center"
                    style={{ fontStyle: 'italic', marginTop: 15 }}
                >
                    Response submitted column will populate once bid is unsealed
                </p>
            )}
        </>
    );
};

PlanholdersList.propTypes = {
    error: PropTypes.string,
    isLoading: PropTypes.bool.isRequired,
    planholders: PropTypes.array.isRequired,
    project: PropTypes.shape({
        showBids: PropTypes.bool.isRequired,
        showPlanholders: PropTypes.bool.isRequired,
    }).isRequired,
    showSubmittedProposals: PropTypes.bool.isRequired,
};
