import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Checkbox } from 'react-bootstrap';
import { connect } from 'react-redux';
import { change, Field, formValueSelector } from 'redux-form';

import { DATA, MAX_LENGTH } from '@og-pro/shared-config/questionnaires';

import { GenericFormBuilder } from '../../SharedFormBuilder';
import { NumberWidget } from '../../../../NumberWidget/NumberWidget';

const mapStateToProps = (state, props) => {
    return {
        hasMaxLength: !!formValueSelector(props.form)(state, `${DATA}.${MAX_LENGTH}`),
    };
};

const mapDispatchToProps = {
    changeForm: change,
};

export class ConnectedShortAnswerFormBuilder extends Component {
    static propTypes = {
        changeForm: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        form: PropTypes.string.isRequired,
        hasMaxLength: PropTypes.bool.isRequired,
        maxLengthDefault: PropTypes.number.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            showMaxLength: props.hasMaxLength,
        };
    }

    toggleMaxLength = (e) => {
        const { changeForm, form, maxLengthDefault } = this.props;

        // Set to max length to default value or clear it if unchecking box
        const maxLength = e.target.checked ? maxLengthDefault : null;
        changeForm(form, `${DATA}.${MAX_LENGTH}`, maxLength);
        this.setState((prevState) => ({ showMaxLength: !prevState.showMaxLength }));
    };

    render() {
        const { disabled, showMaxLength } = this.state;

        return (
            <GenericFormBuilder {...this.props}>
                <Checkbox
                    checked={showMaxLength}
                    disabled={disabled}
                    onChange={this.toggleMaxLength}
                >
                    Limit length of vendor response
                </Checkbox>
                {showMaxLength && (
                    <Field
                        component={NumberWidget}
                        disabled={disabled}
                        label="Maximum character limit"
                        min={1}
                        name={`${DATA}.${MAX_LENGTH}`}
                        normalize={this.normalizeWeightInput}
                    />
                )}
            </GenericFormBuilder>
        );
    }
}

export const ShortAnswerFormBuilder = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedShortAnswerFormBuilder);
