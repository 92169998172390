export const labelStyle = {
    component: 'label',
    display: 'block',
    fontWeight: 'medium',
    marginBottom: 1,
    variant: 'bodyDefault',
};

export const customStyles = {
    padding: 1,
};
