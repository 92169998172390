import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Nav } from '../Nav';
import { NavItem } from '../Nav/NavItem';

export class UnderlineNav extends PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        className: PropTypes.string,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const { children, className, ...props } = this.props;

        return (
            <Nav {...props} bsStyle="pills" className={classnames(this.styles.navItems, className)}>
                {children}
            </Nav>
        );
    }
}

UnderlineNav.NavItem = NavItem;
