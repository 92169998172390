import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { sectionTypeNames } from '@og-pro/shared-config/sections';
import { projectTypesDict } from '@og-pro/shared-config/projects';

import { getBuilderSectionsJS, getProjectJS, getSDv2WritingSections } from '../../../selectors';
import { ProjectCreateSectionMapper } from '../../../ProjectCreateSectionMapper';
import { ProjectCreateV2NavContext, ProjectCreateV2FunctionsContext } from '../../context';
import { ProjectCreateHelpModal } from '../../../ProjectCreateHelpModal';

export const ProjectCreateV2DocumentEditorSectionsAdapter = () => {
    const project = useSelector(getProjectJS);
    const projectSections = useSelector(getSDv2WritingSections);
    const builderSectionsMap = useSelector(getBuilderSectionsJS);
    const shouldShowHelpModal = useSelector((state) => state.projectCreate.get('showHelpModal'));
    const { activeSectionId } = useContext(ProjectCreateV2NavContext);
    const { location, params, initiateSneakyUpdate } = useContext(ProjectCreateV2FunctionsContext);

    const selectedSection = projectSections[activeSectionId];
    // TODO: move styles to another location
    const styles = require('../../components/shared.scss');

    if (!selectedSection || !selectedSection.id) {
        return null;
    }
    const isContractProject = project?.type !== projectTypesDict.CONTRACT;

    // https://opengovinc.atlassian.net/browse/PRO-7297
    // These areas should never show default instructions text
    const hideGenericDefaultInstructions =
        [sectionTypeNames.TERMS, sectionTypeNames.TEXT_AREA].includes(
            selectedSection.section_type
        ) && builderSectionsMap[selectedSection.id].instructions === null;

    const hideContractAttachmentDefaultInstructions =
        isContractProject &&
        selectedSection.section_type === sectionTypeNames.ATTACHMENTS &&
        builderSectionsMap[selectedSection.id].instructions === null;

    const showModal =
        shouldShowHelpModal &&
        !hideGenericDefaultInstructions &&
        !hideContractAttachmentDefaultInstructions;

    return (
        <div className={styles.container}>
            <ProjectCreateSectionMapper
                isOGThemeEnabledForComponents
                location={location}
                overrideSelectedProjectSectionId={selectedSection.id}
                params={params}
                sneakyUpdate={initiateSneakyUpdate}
            />
            {showModal && (
                <ProjectCreateHelpModal overrideSelectedProjectSectionId={selectedSection.id} />
            )}
        </div>
    );
};
