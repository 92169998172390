import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, FieldArray, formValueSelector, reduxForm } from 'redux-form';

import { fieldNames, form } from './constants';
import { ContractBudgetAllocations } from './ContractBudgetAllocations';
import { validate } from './validate';
import { Button } from '../../../Button';
import { InputText } from '../../../InputText';
import { LoadingError } from '../../../LoadingError/LoadingError';
import { LoadingSpinner } from '../../../LoadingSpinner/LoadingSpinner';
import { Well } from '../../../Well/Well';
import { loadContractBudget, updateContractBudget } from '../../../../actions/contracts';
import { getFiscalYearSelectOptions } from '../../../../containers/selectors';
import { maskNumberWithCommas } from '../../../../Forms/maskers';
import { dollarString } from '../../../../Forms/normalizers';
import { currencyFormatter } from '../../../../helpers';
import { MaskedInputText } from '../../../../hocs';

const { AMOUNT, BUDGET_ALLOCATIONS } = fieldNames;

const mapStateToProps = (state) => {
    const parsedAmount = Number.parseFloat(formValueSelector(form)(state, AMOUNT));
    const amountFormValue = Number.isNaN(parsedAmount) ? null : parsedAmount;
    return {
        amountFormValue,
        budgetAllocationsValue: formValueSelector(form)(state, BUDGET_ALLOCATIONS) || [],
        fiscalYearSelectOptions: getFiscalYearSelectOptions(state),
    };
};

const mapDispatchToProps = {
    loadContractBudget,
    updateContractBudget,
};

const formConfig = {
    form,
    validate,
};

// @connect
// @reduxForm
class ConnectedContractBudgetFormModal extends Component {
    static propTypes = {
        amountFormValue: PropTypes.number,
        array: PropTypes.object.isRequired,
        budgetAllocationsValue: PropTypes.array.isRequired,
        contractId: PropTypes.number.isRequired,
        fiscalYearSelectOptions: PropTypes.array.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        hideModal: PropTypes.func.isRequired,
        initialize: PropTypes.func.isRequired,
        loadContractBudget: PropTypes.func.isRequired,
        updateContractBudget: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            loadError: null,
            loading: false,
            updateError: null,
            updating: false,
        };
    }

    componentDidMount() {
        const { contractId, initialize } = this.props;

        this.setState({ loading: true, loadError: null });
        this.props
            .loadContractBudget(contractId)
            .then((result) => {
                initialize(result);
                this.setState({ loading: false });
            })
            .catch((error) => {
                this.setState({ loading: false, loadError: error.message });
            });
    }

    MaskedBudgetInput = MaskedInputText(InputText);

    addAllocation = () => {
        const { array } = this.props;

        array.push(BUDGET_ALLOCATIONS, {});
    };

    submitHandler = (data) => {
        const { contractId, hideModal } = this.props;

        this.setState({ updating: true, updateError: null });
        this.props
            .updateContractBudget(contractId, data)
            .then(() => {
                hideModal();
            })
            .catch((error) => {
                this.setState({ updating: false, updateError: error.message });
            });
    };

    renderBody() {
        const {
            amountFormValue,
            budgetAllocationsValue,
            fiscalYearSelectOptions,
            handleSubmit,
            hideModal,
        } = this.props;

        const { loadError, loading, updateError, updating } = this.state;

        if (loading) {
            return <LoadingSpinner />;
        }

        if (loadError) {
            return <LoadingError error={loadError} />;
        }

        let amountRemaining = amountFormValue;
        const hasAmount = !!amountFormValue || amountFormValue === 0;
        if (hasAmount) {
            const amountAllocated = budgetAllocationsValue.reduce((sum, budgetAllocation) => {
                const parsedAmount = Number.parseFloat(budgetAllocation.amount);
                const amount = Number.isNaN(parsedAmount) ? 0 : parsedAmount;
                return sum + (amount || 0);
            }, 0);
            amountRemaining -= amountAllocated;
        }

        const onFormSubmit = (e) => {
            handleSubmit(this.submitHandler);
            e.preventDefault();
        };

        return (
            <form onSubmit={onFormSubmit}>
                <Field
                    component={this.MaskedBudgetInput}
                    disabled={updating}
                    hasFeedback={false}
                    inputGroupPrefix="$"
                    label="Total Contract Amount"
                    mask={maskNumberWithCommas}
                    name={AMOUNT}
                    normalizer={dollarString}
                    placeholder="Enter Amount"
                    type="text"
                />
                <Well>
                    <div>
                        <b>Contract Amounts by Fiscal Year</b>
                    </div>
                    <FieldArray
                        component={ContractBudgetAllocations}
                        disabled={updating}
                        fiscalYearSelectOptions={fiscalYearSelectOptions}
                        name={BUDGET_ALLOCATIONS}
                    />
                    {budgetAllocationsValue.length > 0 && (
                        <div className={classnames(amountRemaining < 0 && 'text-danger')}>
                            <b>Amount Remaining: {currencyFormatter({ value: amountRemaining })}</b>
                        </div>
                    )}
                    <div>
                        <Button
                            bsSize="sm"
                            disabled={!hasAmount || updating}
                            onClick={this.addAllocation}
                            tooltip={
                                hasAmount
                                    ? undefined
                                    : 'Please enter the total contract amount before adding years'
                            }
                        >
                            <i className="fa fa-plus" /> Add Year
                        </Button>
                    </div>
                </Well>
                <div className="text-right">
                    <Button onClick={hideModal}>Cancel</Button>
                    &nbsp;
                    <Button bsStyle="primary" onClick={handleSubmit(this.submitHandler)}>
                        <i className="fa fa-check" /> Save
                    </Button>
                    {updateError && <div className="error-block">{updateError}</div>}
                </div>
            </form>
        );
    }

    render() {
        const { hideModal } = this.props;

        return (
            <Modal onHide={hideModal} show>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Total Contract Amount</Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.renderBody()}</Modal.Body>
            </Modal>
        );
    }
}

export const ContractBudgetFormModal = compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig)
)(ConnectedContractBudgetFormModal);
