import PropTypes from 'prop-types';
import React from 'react';
import {
    getAnchorString,
    getDateSignedAnchorString,
} from '@og-pro/shared-config/electronicSignatures';
import { fieldTypes } from '@og-pro/shared-config/signatures';

import { signaturesBlocksToColumns } from '../../helpers/signatures';

const DataLine = ({ styles = {}, value }) => (
    <td
        style={{
            borderBottom: '1px solid black',
            paddingTop: '8px',
            ...styles,
        }}
    >
        <p style={{ marginBottom: 0 }}>{value}</p>
    </td>
);

DataLine.propTypes = {
    styles: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

const SignatureField = ({ blockId, field }) => {
    switch (field.type) {
        case fieldTypes.STAMP: {
            return <DataLine styles={{ paddingTop: field.value }} value={<>&nbsp;</>} />;
        }
        case fieldTypes.DATE:
            return <DataLine value={new Date(field.value).toLocaleDateString()} />;
        case fieldTypes.SIGNATURE:
            return (
                <DataLine
                    value={<span style={{ color: 'white' }}>{getAnchorString(blockId)}</span>}
                />
            );
        case fieldTypes.DATE_SIGNED:
            return (
                <DataLine
                    value={
                        <span style={{ color: 'white' }}>{getDateSignedAnchorString(blockId)}</span>
                    }
                />
            );
        default:
            return <DataLine value={field.value} />;
    }
};

SignatureField.propTypes = {
    blockId: PropTypes.number,
    field: PropTypes.shape({
        label: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
    }).isRequired,
};

const SignatureBlock = ({ block }) => {
    if (block.styling === 'header') {
        return (
            <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                    __html: block.fields[0].value,
                }}
            />
        );
    }

    return (
        <table
            style={{
                border: 'none',
                marginBottom: '45px',
                width: '46%',
            }}
        >
            {block.fields.length > 0 ? (
                <>
                    {block.fields.map((field) => {
                        return (
                            <React.Fragment key={field.label}>
                                <tr>
                                    <SignatureField blockId={block.id} field={field} />
                                </tr>
                                <tr>
                                    <td style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                        {field.label?.toUpperCase() || ''}
                                    </td>
                                </tr>
                            </React.Fragment>
                        );
                    })}
                </>
            ) : null}
        </table>
    );
};

SignatureBlock.propTypes = {
    block: PropTypes.shape({
        id: PropTypes.number.isRequired,
        fields: PropTypes.array.isRequired,
        styling: PropTypes.oneOf(['header', 'default']).isRequired,
    }).isRequired,
};

export const SignatureDocx = ({ pageBreakBefore = false, signatures }) => {
    const filteredSignatures = signatures.filter((s) => !s.isHiddenByLogic);

    if (filteredSignatures.length === 0) {
        return null;
    }

    // NOTE: if changing this, check `getProjectSignatureHtml` in `packages/sourcing-db-models/helpers/project/template_utils.js` as well.
    // a server version of this rendering used for variables
    return (
        <>
            {filteredSignatures.map((rawSignature) => {
                const signature = signaturesBlocksToColumns(rawSignature);

                const left = signature.signaturesBlocks[0]?.filter((b) => !b.isHiddenByLogic);
                const right = signature.signaturesBlocks[1]?.filter((b) => !b.isHiddenByLogic);
                const maxLength = Math.max(left?.length || 0, right?.length || 0);

                return (
                    <>
                        {pageBreakBefore && <p style={{ breakBefore: 'page' }}>&nbsp;</p>}
                        {!!signature.introduction && (
                            <div style={{ marginBottom: '16px' }}>
                                {/* eslint-disable-next-line react/no-danger */}
                                <div dangerouslySetInnerHTML={{ __html: signature.introduction }} />
                            </div>
                        )}
                        {[...Array(maxLength).keys()].map((index) => {
                            const leftBlock = left ? left[index] : null;
                            const rightBlock = right ? right[index] : null;

                            return (
                                <table key={index} style={{ border: 'none', width: '100%' }}>
                                    <tr>
                                        <td>
                                            {!!leftBlock && <SignatureBlock block={leftBlock} />}
                                        </td>
                                        <td style={{ width: '2%' }}>&nbsp;</td>
                                        <td>
                                            {!!rightBlock && <SignatureBlock block={rightBlock} />}
                                        </td>
                                    </tr>
                                </table>
                            );
                        })}
                        {signature.blankBlocks.map((block, i) => {
                            return (
                                <div
                                    key={i}
                                    style={{
                                        fontSize: '12px',
                                        fontWeight: 'bold',
                                        marginBottom: block.value,
                                        marginTop: '32px',
                                        paddingLeft: '10px',
                                    }}
                                >
                                    NOTARY &nbsp;
                                </div>
                            );
                        })}
                        {!!signature.footer && (
                            <div style={{ marginTop: '32px' }}>
                                {/* eslint-disable-next-line react/no-danger */}
                                <div dangerouslySetInnerHTML={{ __html: signature.footer }} />
                            </div>
                        )}
                    </>
                );
            })}
        </>
    );
};

SignatureDocx.propTypes = {
    pageBreakBefore: PropTypes.bool,
    signatures: PropTypes.array.isRequired,
};
