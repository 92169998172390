import { truncate } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Box, Typography } from '@og-pro/ui';

import { AttachmentListItemForm } from './AttachmentListItemForm';

import { getFileExtensionIcon } from '../../../helpers';
import { DragIcon } from '../../DragIcon';
import { CDSButton } from '../../SDv2/CDSButtons/CDSButton';
import { Button } from '../../Button';

export class AttachmentListItem extends PureComponent {
    static propTypes = {
        appendixOptions: PropTypes.array.isRequired,
        arrayName: PropTypes.string.isRequired,
        attachment: PropTypes.shape({
            appendixId: PropTypes.string,
            filename: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
        }).isRequired,
        disabled: PropTypes.bool,
        dragHandleProps: PropTypes.any,
        hideAppendixLetter: PropTypes.bool,
        isOGThemeEnabledForComponents: PropTypes.bool,
        questionLogicIcon: PropTypes.node,
        remove: PropTypes.func.isRequired,
        typeReassignmentButton: PropTypes.node,
    };

    constructor(props) {
        super(props);
        this.state = { showForm: false };
    }

    get styles() {
        return require('./AttachmentListItem.scss');
    }

    get nameAndExtension() {
        const {
            attachment: { appendixId, filename, title },
        } = this.props;

        const fileNameSplit = filename.split('.');
        const extension = fileNameSplit[fileNameSplit.length - 1].toLowerCase();

        // Attachment `name` cannot be used since the attachment may not be saved yet
        const name = appendixId && title ? `${appendixId} - ${title}` : title || filename;

        return { name, extension };
    }

    handleCloseClick = () => {
        this.setState({ showForm: false });
    };

    handleEditClick = () => {
        this.setState({ showForm: true });
    };

    renderForm() {
        const { name, extension } = this.nameAndExtension;

        return (
            <AttachmentListItemForm
                appendixOptions={this.props.appendixOptions}
                arrayName={this.props.arrayName}
                closeForm={this.handleCloseClick}
                disabled={this.props.disabled}
                dragHandleProps={this.props.dragHandleProps}
                extension={extension}
                hideAppendixLetter={this.props.hideAppendixLetter}
                isOGThemeEnabledForComponents={this.props.isOGThemeEnabledForComponents}
                name={name}
                remove={() => {
                    this.props.remove();
                    this.handleCloseClick();
                }}
                url={this.props.attachment.url}
            />
        );
    }

    render() {
        if (this.state.showForm) {
            return this.renderForm();
        }

        const {
            attachment: { url },
            disabled,
            dragHandleProps,
            isOGThemeEnabledForComponents,
            questionLogicIcon,
            remove,
            typeReassignmentButton,
        } = this.props;

        const { name, extension } = this.nameAndExtension;
        const fileIcon = getFileExtensionIcon(extension);

        if (isOGThemeEnabledForComponents) {
            return (
                <Box className={this.styles.attachmentContainer}>
                    <Box alignItems="center" display="flex">
                        {dragHandleProps && (
                            <Box>
                                <DragIcon
                                    className={this.styles.dragIcon}
                                    disabled={disabled}
                                    dragHandleProps={dragHandleProps}
                                    useSpan
                                />
                            </Box>
                        )}
                        <Box flex={1}>
                            <Typography className={this.styles.link} component="a" href={url}>
                                <i className={`fa fa-${fileIcon}`} />{' '}
                                {truncate(name, { length: 100 })}
                            </Typography>
                        </Box>
                        <Box alignItems="center" display="flex">
                            {!!typeReassignmentButton && typeReassignmentButton}
                            {!!questionLogicIcon && (
                                <Box className={this.styles.automationsButton}>
                                    {questionLogicIcon}
                                </Box>
                            )}
                            <Box>
                                <CDSButton
                                    onClick={this.handleEditClick}
                                    qaTag="attachmentListItem-edit"
                                    size="small"
                                    variant="text"
                                >
                                    <i className="fa fa-edit" /> Edit
                                </CDSButton>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            );
        }

        return (
            <div>
                {dragHandleProps && (
                    <DragIcon
                        className={this.styles.dragIcon}
                        disabled={disabled}
                        dragHandleProps={dragHandleProps}
                        useSpan
                    />
                )}
                <Button
                    bsStyle="link"
                    className={this.styles.removeIcon}
                    disabled={disabled}
                    onClick={remove}
                >
                    <i className="fa fa-lg fa-times text-danger" />
                </Button>
                <Button
                    bsStyle="link"
                    className={this.styles.editIcon}
                    disabled={disabled}
                    onClick={this.handleEditClick}
                >
                    <i className="fa fa-lg fa-pencil" />
                </Button>
                <a href={url}>{truncate(name, { length: 100 })}</a>
                <i className={`fa fa-lg fa-${fileIcon} ${this.styles.fileIcon} pull-right`} />
                {!!questionLogicIcon && (
                    <div className={this.styles.questionLogicIcon}>{questionLogicIcon}</div>
                )}
            </div>
        );
    }
}
