import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormGroup } from 'react-bootstrap';
import { Field } from 'redux-form';

import { DateTimePicker } from '../../DateTime/DateTimePicker';
import { HelpBlock } from '../../HelpBlock/HelpBlock';

export const ExpectedPurchaseOrderDateField = ({
    className,
    disabled,
    hasLoadingError,
    isLoading,
    onChange,
    name,
    openFiscalPeriodsRange,
    showErrorIcon,
    ...props
}) => {
    const styles = require('./index.scss');
    const isInputDisabled = isLoading || hasLoadingError;

    const helpText = isLoading ? 'Loading fiscal periods...' : 'Error loading fiscal periods';

    return (
        <FormGroup
            controlId={`form-group-${name}`}
            validationState={hasLoadingError ? 'error' : undefined}
        >
            <Field
                className={classNames(
                    styles.datePicker,
                    isInputDisabled && styles.withHelpBlock,
                    className
                )}
                component={DateTimePicker}
                disabled={isInputDisabled || disabled}
                name={name}
                onChange={onChange}
                props={{
                    min: openFiscalPeriodsRange && openFiscalPeriodsRange.start,
                    max: openFiscalPeriodsRange && openFiscalPeriodsRange.end,
                }}
                showValidation={!isInputDisabled}
                time={false}
                useOpenGovStyle
                {...props}
            />
            {isInputDisabled && (
                <HelpBlock>
                    <span className={classNames(hasLoadingError && 'text-danger')}>
                        {hasLoadingError && showErrorIcon && (
                            <>
                                <i className="fa fa-chain-broken" />
                                &nbsp;
                            </>
                        )}
                        {helpText}
                    </span>
                </HelpBlock>
            )}
        </FormGroup>
    );
};

ExpectedPurchaseOrderDateField.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    hasLoadingError: PropTypes.bool,
    isLoading: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    openFiscalPeriodsRange: PropTypes.shape({
        end: PropTypes.instanceOf(Date).isRequired,
        start: PropTypes.instanceOf(Date).isRequired,
    }),
    showErrorIcon: PropTypes.bool,
};
