import { some } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ListGroup, ButtonGroup } from 'react-bootstrap';

import { SuggestionListItem } from './SuggestionListItem';
import { SuggestionListMultiItem } from './SuggestionListMultiItem';
import { SuggestionSearch } from './SuggestionSearch';
import { getMaxNumberFromList } from '../../utils';
import { RouterLink } from '../RouterLink/RouterLink';

export class SuggestionList extends PureComponent {
    static propTypes = {
        addCriteria: PropTypes.func,
        criteria: PropTypes.array,
        description: PropTypes.string,
        disabled: PropTypes.bool,
        govId: PropTypes.number.isRequired,
        hasMultiItems: PropTypes.bool,
        hide: PropTypes.bool,
        isReverseAuction: PropTypes.bool,
        onClick: PropTypes.func,
        options: PropTypes.array,
        sectionType: PropTypes.string.isRequired,
        subsectionType: PropTypes.string.isRequired,
        suggestedItems: PropTypes.array.isRequired,
    };

    static defaultProps = {
        addCriteria: undefined,
        criteria: [],
        description: undefined,
        hasMultiItems: false,
        hide: false,
        onClick: undefined,
        options: undefined,
    };

    constructor(props) {
        super(props);

        this.state = {
            search: '',
            showAll: false,
        };
    }

    MAX_ITEMS = 8;

    MINIMUM_OVERFLOW_ITEMS = 2;

    handleShowAllClick = () => {
        this.setState({ showAll: true });
    };

    isFiltered = (item) => {
        return (
            item.title.toUpperCase().indexOf(this.state.search) === -1 &&
            item.description.toUpperCase().indexOf(this.state.search) === -1
        );
    };

    renderDescription() {
        const { description } = this.props;

        if (!description) return null;

        const styles = require('./SuggestionList.scss');
        return <div className={styles.description}>{description}</div>;
    }

    renderSearch() {
        const { disabled, suggestedItems } = this.props;

        if (suggestedItems.length < 3) {
            return null;
        }

        return (
            <li className="list-group-item">
                <SuggestionSearch
                    disabled={disabled}
                    onChange={(e) => this.setState({ search: e.target.value.toUpperCase() })}
                />
            </li>
        );
    }

    renderShowAll() {
        return (
            <li
                className="list-group-item text-center pseudoLink"
                onClick={this.handleShowAllClick}
            >
                Show all <i className="fa fa-angle-down" />
            </li>
        );
    }

    renderSuggestionListItems() {
        const { criteria, disabled, suggestedItems } = this.props;

        const nextOrderById = getMaxNumberFromList(criteria, 'orderById') + 1;

        let counter = 0;
        const limit = this.MAX_ITEMS + this.MINIMUM_OVERFLOW_ITEMS;
        const SuggestedItems = [];
        suggestedItems.forEach((suggestion, idx) => {
            const hasTitleOccurrence = some(criteria, (item) => {
                return item.sharedId === suggestion.sharedId;
            });

            // Let counter get one above limit
            if (counter > limit && !this.state.showAll) return null;
            if (this.isFiltered(suggestion)) return null;
            counter++;
            SuggestedItems.push(
                <SuggestionListItem
                    {...this.props}
                    disabled={disabled}
                    isUsed={hasTitleOccurrence}
                    key={idx}
                    nextOrderById={nextOrderById}
                    suggestion={suggestion}
                />
            );
        });

        const showAll = this.state.showAll || SuggestedItems.length <= limit;
        const DisplayItems = showAll ? SuggestedItems : SuggestedItems.slice(0, this.MAX_ITEMS);

        return (
            <ListGroup>
                {this.renderSearch()}
                {DisplayItems}
                {!showAll && this.renderShowAll()}
            </ListGroup>
        );
    }

    renderMultiSuggestionListItems() {
        const { disabled, isReverseAuction, options, suggestedItems } = this.props;

        let counter = 0;
        const limit = this.MAX_ITEMS + this.MINIMUM_OVERFLOW_ITEMS;
        const SuggestedItems = [];
        suggestedItems.forEach((suggestion, idx) => {
            // Let counter get one above limit
            if (counter > limit && !this.state.showAll) return null;
            if (this.isFiltered(suggestion)) return null;
            counter++;

            SuggestedItems.push(
                <SuggestionListMultiItem
                    disabled={disabled}
                    isReverseAuction={isReverseAuction}
                    key={idx}
                    options={options}
                    suggestion={suggestion}
                />
            );
        });

        const showAll = this.state.showAll || SuggestedItems.length <= limit;
        const DisplayItems = showAll ? SuggestedItems : SuggestedItems.slice(0, this.MAX_ITEMS);

        return (
            <ButtonGroup block vertical>
                {this.renderSearch()}
                {DisplayItems}
                {!showAll && this.renderShowAll()}
            </ButtonGroup>
        );
    }

    render() {
        const { govId, hasMultiItems, hide, suggestedItems } = this.props;

        if (hide) return null;

        if (suggestedItems.length === 0) {
            return (
                <div className="text-muted text-center">
                    <h5>No suggested items have been added yet.</h5>
                    <br />
                    <RouterLink
                        qaTag="suggestionList-adminSuggestedContent"
                        to={`/governments/${govId}/admin/content`}
                    >
                        Click here to go to Suggested Content in Admin to add some.
                    </RouterLink>
                </div>
            );
        }

        const SuggestionListItems = hasMultiItems
            ? this.renderMultiSuggestionListItems()
            : this.renderSuggestionListItems();

        return (
            <div>
                {this.renderDescription()}
                {SuggestionListItems}
            </div>
        );
    }
}
