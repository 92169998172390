import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Modal } from 'react-bootstrap';

import { Button } from '../Button';
import { CDSButton } from '../SDv2/CDSButtons/CDSButton';
import { StandardDocument } from '../StandardDocument';

export class StandardDocumentModalButton extends PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        className: PropTypes.string,
        standardDocument: PropTypes.shape({
            title: PropTypes.string.isRequired,
        }).isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    constructor(props) {
        super(props);

        this.state = { showModal: false };
    }

    hideModalHandler = () => this.setState({ showModal: false });

    showModalHandler = () => this.setState({ showModal: true });

    renderModal() {
        const { standardDocument } = this.props;

        return (
            <Modal onHide={this.hideModalHandler} show>
                <Modal.Body>
                    <StandardDocument standardDocument={standardDocument} />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.hideModalHandler} qaTag="standardDocumentModal-done">
                        Done
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    render() {
        const { children, className } = this.props;

        const { showModal } = this.state;

        return (
            <>
                <CDSButton
                    className={classnames(this.styles.container, className)}
                    noPadding
                    onClick={this.showModalHandler}
                    qaTag="evaluations-agreementAccepted"
                    size="small"
                    variant="text"
                >
                    {children}
                </CDSButton>
                {showModal && this.renderModal()}
            </>
        );
    }
}
