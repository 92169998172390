import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { Box } from '@mui/material';

import { Typography } from '@og-pro/ui';

import { getPriceItemsSummary } from '@og-pro/shared-config/priceTables/requisitionUtils';

import { fieldNames } from '../../constants';
import { requisitionsCreateFormValueSelector } from '../../selectors';
import { getGovernmentSalesTax } from '../../../../../../selectors/govApp';
import { currencyFormatter } from '../../../../../../helpers';

export const PurchaseDetailsTotal = () => {
    const { PRICE_ITEMS, PRICE_TABLE } = fieldNames;
    const sharedStyles = require('../../shared.scss');

    const priceItems = useSelector((state) =>
        requisitionsCreateFormValueSelector(state, `${PRICE_TABLE}.${PRICE_ITEMS}`)
    );
    const tax = useSelector(getGovernmentSalesTax);

    const { total } = useMemo(() => getPriceItemsSummary(priceItems, tax), [priceItems, tax]);

    return (
        <div className={sharedStyles.approvalContainer}>
            <Box>
                <Typography variant="h4">Total Budget Amount</Typography>
                <Typography>{currencyFormatter({ value: total })}</Typography>
            </Box>
        </div>
    );
};
