import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field, FieldArray } from 'redux-form';
import { v4 as UUIDv4 } from 'uuid';

import { fieldNames } from '../constants';
import { Button } from '../../../Button';
import { DateTimePicker } from '../../../DateTime/DateTimePicker';
import { InputText } from '../../../InputText';
import { OutlineButton } from '../../../OutlineButton';
import { limitTextLength } from '../../../../Forms/normalizers';

const { CONTRACT_MILESTONES } = fieldNames;

export class ContractMilestones extends Component {
    static propTypes = {
        disabled: PropTypes.bool,
        timezone: PropTypes.string.isRequired,
    };

    static defaultProps = {
        disabled: false,
    };

    get styles() {
        return require('./index.scss');
    }

    normalizeNameLength = limitTextLength(256);

    addNewMilestone = (fields) => {
        fields.unshift({
            date: new Date(),
            localCreatingUUID: UUIDv4(),
            name: 'New Milestone',
        });
    };

    renderMilestones = ({ fields }) => {
        const { disabled, timezone } = this.props;

        return (
            <div>
                <div className={this.styles.addMilestoneWrapper}>
                    <OutlineButton
                        bsStyle="primary"
                        disabled={disabled}
                        onClick={() => this.addNewMilestone(fields)}
                    >
                        <i className="fa fa-plus" />
                        &nbsp;New Milestone
                    </OutlineButton>
                </div>
                {fields.map((fieldName, index) => {
                    const milestoneData = fields.get(index);

                    if (
                        milestoneData.isStartDate ||
                        milestoneData.isEndDate ||
                        milestoneData.isInsuranceExpiration
                    ) {
                        return null;
                    }

                    return (
                        <div
                            className="row"
                            key={milestoneData.id || milestoneData.localCreatingUUID}
                        >
                            <div className="col-xs-6">
                                <Field
                                    component={InputText}
                                    disabled={disabled}
                                    hasFeedback={false}
                                    name={`${fieldName}.name`}
                                    normalize={this.normalizeNameLength}
                                    placeholder="Enter Text"
                                    type="text"
                                />
                            </div>
                            <div className="col-xs-5">
                                <Field
                                    component={DateTimePicker}
                                    disabled={disabled}
                                    horizontal
                                    name={`${fieldName}.date`}
                                    timezone={timezone}
                                />
                            </div>
                            {!disabled && (
                                <div className="col-xs-1">
                                    <Button
                                        aria-label="Remove Button"
                                        bsStyle="link"
                                        disabled={disabled}
                                        onClick={() => fields.remove(index)}
                                        qaTag="questionnaireList-remove"
                                    >
                                        <i className="fa fa-times fa-lg text-danger" />
                                    </Button>
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    };

    render() {
        return <FieldArray component={this.renderMilestones} name={CONTRACT_MILESTONES} />;
    }
}
