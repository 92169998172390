import { Box } from '@og-pro/ui';
import classnames from 'classnames';
import { omit } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { withRouter } from '@og-pro-migration-tools/react-router';
import Sticky from 'react-stickynode';
import { compose } from 'redux';
import { getFormValues, reduxForm, Field } from 'redux-form';
import { createSearchParams, Outlet } from 'react-router-dom';

import { proposalStatusesDict } from '@og-pro/shared-config/proposals';
import { sectionTypeNames } from '@og-pro/shared-config/sections';

import { SUBMIT } from './constants';
import { ProposalCreateNavSync } from './ProposalCreateNavSync';
import { Nav } from '../Nav';
import { RouteLeaveWarning } from '../RouteLeaveWarning';
import { UserListPopover } from '../UserListPopover';
import { ZeroState } from '../ZeroState';
import { ProposalCreateNavItem } from './ProposalCreateNavItem';
import { getConnectedClients, getSections, isVendorProfileComplete } from './selectors';
import { IS_VENDOR_COMPLETE } from '../Forms/ProposalCreateForm/ProposalCompanyProfile/constants';
import {
    proposalCreateValidate as validate,
    proposalCreateWarn as warn,
} from '../Forms/ProposalCreateForm/validate';
import { SDv2ErrorBanner } from '../SDv2';
import { updateProposal as govUpdateProposal } from '../../actions/govProposals';
import { updateProposal as vendUpdateProposal } from '../../actions/vendProposals';
import { form as governmentProposalCreateForm } from '../../containers/GovApp/ProposalReviewNav/GovernmentProposalCreate/constants';
import {
    getProjectJS,
    getProposalJS as getGovAppProposalJS,
} from '../../containers/GovApp/selectors';
import { getPublicProjectJS } from '../../containers/selectors';
import { form as proposalCreateForm } from '../../containers/VendorApp/ProposalCreate/constants';
import { getMappedProposalJS as getVendorAppProposalJS } from '../../containers/VendorApp/selectors';
import { SCREEN_XS_MAX } from '../../constants/mediaQuery';
import { FIXED_TOOLBAR_ADJUSTMENT_HEIGHT } from '../../constants/styles';

const mapStateToProps = (state, props) => {
    return {
        formValues: getFormValues(props.form)(state),
        initialValues: props.proposal,
        sections: getSections(props),
        useFullWidthView: state.vendProposals.get('useFullWidthView'),
    };
};

const formConfig = {
    validate,
    warn,
};

// @withRouter
// @connect
// @reduxForm
class ConnectedProposalCreateNav extends Component {
    static propTypes = {
        change: PropTypes.func.isRequired, // from @reduxForm
        connectedClients: PropTypes.array,
        dirty: PropTypes.bool.isRequired,
        // Used by @reduxForm
        form: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
        formValues: PropTypes.object.isRequired,
        hasToolbar: PropTypes.bool,
        initialize: PropTypes.func.isRequired,
        isVendorComplete: PropTypes.bool,
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
            query: PropTypes.shape({
                search: PropTypes.string,
                section: PropTypes.string,
            }).isRequired,
        }).isRequired,
        proposal: PropTypes.shape({
            id: PropTypes.number.isRequired,
            priceTables: PropTypes.array.isRequired,
            status: PropTypes.string.isRequired,
        }).isRequired,
        project: PropTypes.shape({
            projectSections: PropTypes.array.isRequired,
        }).isRequired,
        sections: PropTypes.arrayOf(
            PropTypes.shape({
                query: PropTypes.string.isRequired,
                title: PropTypes.string.isRequired,
            })
        ).isRequired,
        shouldUpdateFormValues: PropTypes.bool.isRequired,
        // Used by `getSections` selector
        skipCompanyProfile: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
        // Used by `validate` function
        skipQuestionnaireValidation: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
        updateProposal: PropTypes.func,
        useFullWidthView: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            activeKey: this.calculateActiveKey(),
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        /**
         * When the proposal is saved we want to reinitialize the form to use the new proposal
         * values. This will reinitialize the form with the updated values every time the proposal
         * is saved.
         */
        if (this.shouldReinitializeForm(nextProps)) {
            this.props.initialize(nextProps.proposal);
        }

        /**
         * Slight hack: validation does not run when the `isVendorComplete` prop is updated.
         * The `change` action dispatcher is used to kick off a validation, even though the value
         * of the changed field is not used in validation or elsewhere.
         */
        if (this.props.isVendorComplete !== nextProps.isVendorComplete) {
            this.props.change(IS_VENDOR_COMPLETE, nextProps.isVendorComplete);
        }
    }

    componentDidUpdate() {
        const newActiveKey = this.calculateActiveKey();

        if (newActiveKey !== this.state.activeKey) {
            this.setState({ activeKey: newActiveKey });
        }
    }

    get styles() {
        return require('./index.scss');
    }

    calculateActiveKey = () => {
        const activeSectionIndex = this.props.sections.findIndex(
            (section) => section.query === this.props.location.query.section
        );

        return activeSectionIndex > -1 ? activeSectionIndex : 0;
    };

    scrollToSectionTop = () => {
        const targetEl = document.getElementById('proposal-section-container');
        if (targetEl) {
            targetEl.scrollIntoView();
            const pageYOffset = window.pageYOffset;
            if (pageYOffset && this.props.hasToolbar) {
                // Offset scroll position to account for fixed navbar
                window.scroll(0, pageYOffset - FIXED_TOOLBAR_ADJUSTMENT_HEIGHT);
            }
        }
    };

    shouldReinitializeForm = (nextProps) => {
        const { proposal: currentFormData } = this.props;
        const { proposal: nextFormData } = nextProps;

        /**
         * Form should be reinitialized anytime the proposal data has changed due to a save event.
         * Form data can also change due to an upload event, but in those cases we do not want
         * to reinitialize the form, which is why we check the `shouldUpdateFormValues` prop,
         * which is updated only on saves.
         */
        return (
            nextFormData !== currentFormData &&
            !this.props.shouldUpdateFormValues &&
            nextProps.shouldUpdateFormValues
        );
    };

    saveHandler = () => {
        const { formValues, proposal } = this.props;
        return this.props.updateProposal(proposal.id, formValues, { notify: true });
    };

    renderNavItems() {
        const {
            dirty,
            location: {
                pathname,
                query: { section: sectionQueryParam },
            },
            project,
            sections,
        } = this.props;

        return sections.map((section, idx) => {
            const { query } = section;
            let { title } = section;
            const titleForQaTag = title; // otherwise the end2end fails
            if (query === sectionTypeNames.PRICING || query === sectionTypeNames.QUESTIONNAIRE) {
                const projectSection = project.projectSections.find(
                    (s) => s.section_type === query
                );

                if (projectSection) {
                    title = projectSection.title;
                }
            }

            const route = {
                pathname,
                search: createSearchParams({
                    section: query,
                }).toString(),
            };

            const isIndexActive = !sectionQueryParam && idx === 0;

            return (
                <Field
                    component={ProposalCreateNavItem}
                    eventKey={idx}
                    hasStatusNode={query === SUBMIT}
                    isFormDirty={dirty}
                    isIndexActive={isIndexActive}
                    key={query}
                    name={`sections.${query}`}
                    route={route}
                    saveHandler={this.saveHandler}
                    title={title}
                    titleForQaTag={titleForQaTag}
                />
            );
        });
    }

    renderNav(noSticky) {
        const { change, connectedClients, hasToolbar, initialize, proposal, useFullWidthView } =
            this.props;

        const hasMultipleUsers = connectedClients?.length > 1;
        const offset = hasToolbar ? FIXED_TOOLBAR_ADJUSTMENT_HEIGHT : 10;

        if (proposal && proposal.status !== proposalStatusesDict.DRAFT) {
            return (
                <ZeroState
                    info="This proposal has already been submitted and cannot be edited."
                    title="Proposal Submitted"
                />
            );
        }

        return (
            <div className={`row ${this.styles.container}`} id="proposal-create-nav-container">
                <div className="col-md-3 col-sm-4 col-xs-12">
                    <Sticky
                        bottomBoundary="#proposal-create-nav-container"
                        enabled={!noSticky}
                        top={offset}
                    >
                        {({ status }) => (
                            <div
                                className={classnames(
                                    this.styles.pills,
                                    useFullWidthView && this.styles.hiddenPills
                                )}
                            >
                                <Nav
                                    activeKey={this.state.activeKey}
                                    bsStyle="pills"
                                    onClick={
                                        status === Sticky.STATUS_FIXED && !noSticky
                                            ? this.scrollToSectionTop
                                            : undefined
                                    }
                                    stacked
                                >
                                    {this.renderNavItems()}
                                </Nav>
                            </div>
                        )}
                    </Sticky>
                </div>
                <div
                    className={classnames('col-xs-12', !useFullWidthView && 'col-md-9 col-sm-8')}
                    id="proposal-section-container"
                >
                    <RouteLeaveWarning blockingValue={this.props.dirty} />
                    <ProposalCreateNavSync
                        change={change}
                        initialize={initialize}
                        proposal={proposal}
                    />
                    {hasMultipleUsers && (
                        <Box marginBottom={2}>
                            <SDv2ErrorBanner
                                description={
                                    <>
                                        You may see updates as they save their changes (and they
                                        will see your saved changes as well).{' '}
                                        <UserListPopover
                                            popoverId="connected-user-list"
                                            title={
                                                <div className="text-center">
                                                    People Viewing Response
                                                </div>
                                            }
                                            users={connectedClients}
                                        >
                                            <span className="pseudoLink">
                                                See Who&apos;s Editing
                                            </span>
                                        </UserListPopover>
                                    </>
                                }
                                hasLargeIcon
                                title="Multiple people are editing this response"
                                variant="warning"
                            />
                        </Box>
                    )}
                    <Outlet />
                </div>
            </div>
        );
    }

    render() {
        return (
            <Media query={`(max-width: ${SCREEN_XS_MAX}px)`}>
                {(matches) => this.renderNav(matches)}
            </Media>
        );
    }
}

export const ProposalCreateNav = compose(
    withRouter,
    connect(mapStateToProps),
    reduxForm(formConfig)
)(ConnectedProposalCreateNav);

// NOTE: we can't use decorators below because they don't seem to play nice with not being after the
// previously defined component, so we use the standard HOC style instead.
const Vendor = (props) => {
    return <ProposalCreateNav form={proposalCreateForm} {...props} />;
};
ProposalCreateNav.Vendor = connect(
    (state) => {
        return {
            connectedClients: getConnectedClients(state),
            isVendorComplete: isVendorProfileComplete(state),
            project: getPublicProjectJS(state),
            proposal: omit(getVendorAppProposalJS(state), ['project']),
            shouldUpdateFormValues: state.vendProposals.get('updatedProposal'),
        };
    },
    { updateProposal: vendUpdateProposal }
)(Vendor);

const Government = (props) => {
    return <ProposalCreateNav form={governmentProposalCreateForm} {...props} />;
};
ProposalCreateNav.Government = connect(
    (state) => {
        return {
            hasToolbar: true,
            project: getProjectJS(state),
            proposal: omit(getGovAppProposalJS(state), ['project']),
            shouldUpdateFormValues: state.govProposals.get('updated'),
            skipCompanyProfile: true,
            skipQuestionnaireValidation: true,
        };
    },
    { updateProposal: govUpdateProposal }
)(Government);
