import React, { PureComponent } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import { QuestionForm } from './QuestionForm';
import { Button } from '../../../Button';
import { HelpBlock } from '../../../HelpBlock/HelpBlock';
import { Well } from '../../../Well/Well';

export class PublicQuestionSidebar extends PureComponent {
    static propTypes = {
        allowSubstitutionRequests: PropTypes.bool.isRequired,
        auctionQADeadline: PropTypes.string,
        closeForm: PropTypes.func.isRequired,
        hasDeadlinePassed: PropTypes.bool.isRequired,
        isOpen: PropTypes.bool.isRequired,
        isPosting: PropTypes.bool,
        isReverseAuctionView: PropTypes.bool,
        postingError: PropTypes.string,
        projectId: PropTypes.number,
        qaDeadline: PropTypes.string,
        questionHandler: PropTypes.func.isRequired,
        showForm: PropTypes.bool.isRequired,
        showQuestionForm: PropTypes.func.isRequired,
        substitutionRequestFormUrl: PropTypes.string,
        timezone: PropTypes.string,
        user: PropTypes.object,
    };

    get styles() {
        return require('./shared.scss');
    }

    get questionInstructions() {
        const {
            auctionQADeadline,
            hasDeadlinePassed,
            isOpen,
            isReverseAuctionView,
            qaDeadline,
            timezone,
            user,
        } = this.props;

        if (hasDeadlinePassed) {
            return 'The question submission deadline has passed. No more questions can be submitted.';
        }
        if (isOpen) {
            const loginText = '\n\nYou must be logged in to your account to ask a question.';
            const deadlineText = `\n\nQuestions are due ${moment(qaDeadline).from(moment())}.`;
            const auctionDeadlineText =
                isReverseAuctionView &&
                `\n\nQ&A for the auction will close at ${moment
                    .tz(auctionQADeadline, timezone)
                    .format('LLL')}`;
            const infoDisplayText = isReverseAuctionView
                ? 'Your name and contact information will be displayed anonymously to the agency and all others viewing the project until the auction ends.'
                : 'Your name and contact information will only be viewable by the agency. It will be displayed anonymously to all others viewing the project questions.';

            return (
                <span>
                    Question & Answer {isReverseAuctionView ? 'for reverse auction ' : ''}is open.
                    {infoDisplayText}
                    {!user && loginText}
                    {!isReverseAuctionView && qaDeadline && <strong>{deadlineText}</strong>}
                    {isReverseAuctionView && <strong>{auctionDeadlineText}</strong>}
                </span>
            );
        }
        return 'Question and answer is closed. No more questions can be submitted.';
    }

    renderQuestionForm() {
        const {
            allowSubstitutionRequests,
            closeForm,
            isPosting,
            postingError,
            projectId,
            questionHandler,
            substitutionRequestFormUrl,
        } = this.props;

        return (
            <div>
                <QuestionForm
                    allowSubstitutionRequests={allowSubstitutionRequests}
                    closeForm={closeForm}
                    onSubmit={questionHandler}
                    posting={isPosting}
                    projectId={projectId}
                    submitError={postingError}
                    substitutionRequestFormUrl={substitutionRequestFormUrl}
                />
                <HelpBlock>
                    <i className="text-info fa fa-info-circle" /> Please submit each question
                    individually. Your question{' '}
                    {allowSubstitutionRequests
                        ? 'or request for substitution (along with attachments) '
                        : ''}
                    will be publicly published. For questions, your name and company information
                    will be anonymized.
                </HelpBlock>
            </div>
        );
    }

    renderInstructions() {
        const { hasDeadlinePassed, isOpen, showQuestionForm } = this.props;

        return (
            <div className="no-print">
                <Well>
                    <article>{this.questionInstructions}</article>
                </Well>
                {isOpen && !hasDeadlinePassed && (
                    <Button block bsStyle="primary" onClick={showQuestionForm}>
                        <i className="fa fa-fw fa-send" /> Ask Question
                    </Button>
                )}
            </div>
        );
    }

    render() {
        return (
            <div className={this.styles.publicSidebar}>
                {this.props.showForm ? this.renderQuestionForm() : this.renderInstructions()}
            </div>
        );
    }
}
