import { buildQaTag, componentNames } from '@og-pro/ui';
import classnames from 'classnames';
import React, { PureComponent } from 'react';
import { Button as ButtonBS } from 'react-bootstrap';
import { bootstrapUtils } from 'react-bootstrap/lib/utils';

import { buttonPropTypes } from './constants';
import { LinkContainer } from '../LinkContainer/LinkContainer';
import { Tooltip } from '../Tooltip';

bootstrapUtils.addStyle(ButtonBS, 'opengov');

export class Button extends PureComponent {
    static propTypes = buttonPropTypes;

    static defaultProps = {
        className: undefined,
        tooltipPlacement: 'bottom',
    };

    get styles() {
        return require('./Button.scss');
    }

    renderBasicButton() {
        const { className, exact, qaTag, to, tooltip, tooltipPlacement, zeroPadding, ...props } =
            this.props;

        const tagWithComponentName = buildQaTag(qaTag, componentNames.BUTTON);

        return (
            <ButtonBS
                className={classnames(
                    tooltip && props.disabled && this.styles.disabledButtonTooltip,
                    zeroPadding && this.styles.zeroPadding,
                    className
                )}
                data-qa={tagWithComponentName}
                {...props}
            />
        );
    }

    renderButton() {
        const { block, disabled, tooltip, tooltipPlacement } = this.props;

        if (tooltip) {
            return (
                <Tooltip
                    blockComponent={block}
                    delayShow={300}
                    disabled={disabled}
                    placement={tooltipPlacement}
                    tooltip={tooltip}
                >
                    {this.renderBasicButton()}
                </Tooltip>
            );
        }

        return this.renderBasicButton();
    }

    render() {
        const { active, disabled, exact, to } = this.props;

        if (to && !disabled) {
            return (
                <LinkContainer active={active} exact={exact} isButton to={to}>
                    {this.renderButton()}
                </LinkContainer>
            );
        }

        return this.renderButton();
    }
}
