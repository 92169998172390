const {
    statusTypes,
    desiredDeliveryDateOptionValues,
    purchaseDetailsModeOptions,
} = require('@og-pro/shared-config/requisitions');

exports.updatableGeneralFields = (requestType) => {
    const generalFields = [
        'descriptionOfRequest',
        'exceptionNote',
        'expectedPurchaseOrderDate',
        'fiscalPeriod',
        'fiscal_period_tag_id',
        'fiscalPeriodObject',
        'requestor_id',
        'summary',
    ];

    if (requestType.showBackground) {
        generalFields.push('background');
    }
    if (requestType.showCategories) {
        generalFields.push('categories');
    }
    if (requestType.desiredDeliveryDate !== desiredDeliveryDateOptionValues.HIDDEN) {
        generalFields.push('desiredDeliveryDate');
    }
    if (requestType.purchaseDetailsMode === purchaseDetailsModeOptions.ANY) {
        generalFields.push('purchaseDetailsMode');
    }

    return generalFields;
};

exports.updatablePriceTableFields = (requestType) => {
    if (requestType.hideSectionPurchase) {
        return [];
    }
    return ['priceTable'];
};

exports.updatableAttachmentFields = (requestType) => {
    if (requestType.hideSectionAttachment) {
        return [];
    }
    return ['attachments'];
};

exports.updatablePriceItemFieldsWhenInReview = [
    'custom1',
    'custom2',
    'description',
    'id', // `id` is needed to match to existing items
];

exports.updatableAccountSplitPriceItemFieldsWhenInReview = [
    'accountNumber',
    'accountObject',
    'accountExternalId',
];

exports.updatableVendorFields = (requestType) => {
    if (requestType.hideSectionVendor) {
        return [];
    }
    return ['additionalVendorNotes', 'purchaseOrderType', 'vendors', 'vendorSelectionState'];
};

exports.updatableFlagResponseFields = () => ['flagResponses'];

exports.updatableCustomFormFields = () => ['customFormData'];

exports.updatableExceptionRequestFields = () => ['exception_sequence_id'];

exports.updatableFields = (requestType) => [
    ...exports.updatableGeneralFields(requestType),
    ...exports.updatablePriceTableFields(requestType),
    ...exports.updatableVendorFields(requestType),
    ...exports.updatableFlagResponseFields(),
    ...exports.updatableCustomFormFields(),
    ...exports.updatableExceptionRequestFields(),
    ...exports.updatableAttachmentFields(requestType),
];

exports.getRequiredFieldsOnCreate = (endsInPurchaseOrder) => [
    'review_group_id',
    'template_request_type_id',
    ...(endsInPurchaseOrder
        ? ['fiscalPeriodObject', 'expectedPurchaseOrderDate']
        : ['fiscal_period_tag_id']),
];

exports.getRequiredFieldsOnSubmit = (endsInPurchaseOrder, requestType) => [
    'creator_id',
    'endsInPurchaseOrder',
    'financialSystemIntegration',
    'identifier',
    'purchaseDetailsMode',
    'request_type_id',
    'requestor_id',
    'review_group_id',
    'status',
    'template_request_type_id',
    ...(requestType.requireDescriptionOfRequest ? ['descriptionOfRequest'] : []),
    ...(requestType.requireSummary ? ['summary'] : []),
    ...(requestType.hideSectionVendor ? [] : ['vendorSelectionState']),
    ...(requestType.desiredDeliveryDate === desiredDeliveryDateOptionValues.REQUIRED
        ? ['desiredDeliveryDate']
        : []),
    ...(endsInPurchaseOrder
        ? ['expectedPurchaseOrderDate', 'fiscalPeriodObject', 'fiscalPeriod']
        : ['fiscal_period_tag_id']),
];

exports.requiredVendorFields = ['vendorId', 'vendorName'];

exports.ACTIVE_REQUESTS_STATUSES = [
    statusTypes.DRAFT_REJECTED,
    statusTypes.REVIEW,
    statusTypes.REVIEW_REJECTED,
];

const getRequisitionAttachmentBucket = () => {
    if (__PROD__ || __SANDBOX__ || __DEMO__) {
        return `og-pro-${process.env.NODE_ENV}-req-attach-use1`;
    }
    return `og-pro-${process.env.NODE_ENV}-req-attach-usw2`;
};

exports.REQ_ATTACHMENT_BUCKET = getRequisitionAttachmentBucket();
