import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Media from 'react-media';
import Sticky from 'react-stickynode';

import { projectStatusesDict } from '@og-pro/shared-config/projects';

import {
    GovQuestionSidebar,
    GovQuestionStatusFilterForm,
    PublicQuestionSidebar,
    QuestionForm,
    QuestionList,
    ReleaseQuestionResponsesModal,
} from './components';
import { getFilteredQuestionsJS } from './selectors';
import { Button } from '../../Button';
import { ButtonGroup } from '../../ButtonGroup/ButtonGroup';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import { LoadingError } from '../../LoadingError/LoadingError';
import { NoItems } from '../../NoItems';
import * as questionActions from '../../../actions/questions';
import { SCREEN_XS_MAX } from '../../../constants/mediaQuery';
import { FIXED_TOOLBAR_ADJUSTMENT_HEIGHT } from '../../../constants/styles';
import { ReportsModalButton } from '../../../containers/GovApp/ReportsModal';
import { exportArrayToCSV } from '../../../utils';

const { REVERSE_AUCTION } = projectStatusesDict;

const mapStateToProps = (state, props) => {
    return {
        filteredQuestions: getFilteredQuestionsJS(state, props),
        loaded: state.questions.get('loaded'),
        loadError: state.questions.get('loadError'),
        loading: state.questions.get('loading'),
        postError: state.questions.get('postError'),
        posting: state.questions.get('posting'),
        selectedNumber: props.selectedNumber && Number.parseInt(props.selectedNumber, 10),
        shouldShowQuestionForm: state.questions.get('shouldShowQuestionForm'),
        showReleaseQuestionResponsesModal: state.questions.get('showReleaseResponsesModal'),
    };
};

const mapDispatchToProps = questionActions;

// @connect
class ConnectedQuestionAnswer extends Component {
    static propTypes = {
        canEdit: PropTypes.bool,
        canRelease: PropTypes.bool,
        excludedQuestions: PropTypes.array, // eslint-disable-line react/no-unused-prop-types
        filteredQuestions: PropTypes.array.isRequired,
        hideQuestionForm: PropTypes.func.isRequired,
        hideSidebar: PropTypes.bool,
        isGovUser: PropTypes.bool,
        isOpen: PropTypes.bool,
        isReverseAuctionView: PropTypes.bool,
        loaded: PropTypes.bool.isRequired,
        loadError: PropTypes.string,
        loading: PropTypes.bool.isRequired,
        pathname: PropTypes.string.isRequired,
        postError: PropTypes.string,
        posting: PropTypes.bool.isRequired,
        postQuestion: PropTypes.func.isRequired,
        postResponse: PropTypes.func.isRequired,
        project: PropTypes.shape({
            allowSubstitutionRequests: PropTypes.bool.isRequired,
            auctionEndDate: PropTypes.string,
            auctionExtensionCount: PropTypes.number,
            government: PropTypes.shape({
                organization: PropTypes.shape({
                    timezone: PropTypes.string.isRequired,
                }).isRequired,
            }).isRequired,
            id: PropTypes.number.isRequired,
            qaDeadline: PropTypes.string,
            status: PropTypes.string.isRequired,
            template: PropTypes.shape({
                isReverseAuction: PropTypes.bool.isRequired,
                substitutionRequestFormUrl: PropTypes.string,
            }),
            timelineConfig: PropTypes.shape({
                auctionExtensionGracePeriod: PropTypes.number,
                auctionExtensionTime: PropTypes.number,
                hasAuctionExtension: PropTypes.bool,
                hasQaDeadline: PropTypes.bool,
            }),
        }).isRequired,
        questions: PropTypes.array.isRequired,
        resetQuestions: PropTypes.func.isRequired,
        selectedNumber: PropTypes.number,
        shouldShowQuestionForm: PropTypes.bool.isRequired,
        showQuestionForm: PropTypes.func.isRequired,
        showReleaseQuestionResponsesModal: PropTypes.bool.isRequired,
        showReleaseResponsesModal: PropTypes.func.isRequired,
        toggleEditQuestionForm: PropTypes.func.isRequired,
        toggleEditResponseForm: PropTypes.func.isRequired,
        user: PropTypes.object,
    };

    componentWillUnmount() {
        this.props.resetQuestions();
    }

    get styles() {
        return require('./QuestionAnswer.scss');
    }

    get showRegularAndAuctionQuestions() {
        const {
            isReverseAuctionView,
            project: {
                auctionEndDate,
                template: { isReverseAuction },
            },
        } = this.props;

        if (isReverseAuctionView) {
            return false;
        }

        if (isReverseAuction && moment().isAfter(auctionEndDate)) {
            return true;
        }

        return false;
    }

    get unfilteredQuestions() {
        const { isReverseAuctionView, questions } = this.props;

        // The reverse auction view only shows auction questions
        if (isReverseAuctionView) {
            return questions.filter((q) => q.isAuctionQuestion);
        }

        // Regular views show all questions when auction has ended
        if (this.showRegularAndAuctionQuestions) {
            return questions;
        }

        return questions.filter((q) => !q.isAuctionQuestion);
    }

    get filteredQuestions() {
        const { isReverseAuctionView, filteredQuestions } = this.props;

        // The reverse auction view only shows auction questions
        if (isReverseAuctionView) {
            return filteredQuestions.filter((q) => q.isAuctionQuestion);
        }

        // Regular views show all questions when auction has ended
        if (this.showRegularAndAuctionQuestions) {
            return filteredQuestions;
        }

        return filteredQuestions.filter((q) => !q.isAuctionQuestion);
    }

    get auctionQADeadline() {
        const {
            auctionEndDate,
            auctionExtensionCount,
            timelineConfig: {
                auctionExtensionTime,
                auctionExtensionGracePeriod,
                hasAuctionExtension,
            },
        } = this.props.project;

        if (hasAuctionExtension) {
            return moment(auctionEndDate)
                .subtract(
                    auctionExtensionGracePeriod + auctionExtensionCount * auctionExtensionTime,
                    'minutes'
                )
                .toISOString();
        }

        return auctionEndDate;
    }

    handleExportCSVClick = () => {
        let maxQuestionComments = 0;

        let rows = this.unfilteredQuestions.map((question) => {
            const row = [question.number, question.subject];

            if (question.questionComments.length > maxQuestionComments) {
                maxQuestionComments = question.questionComments.length;
            }

            question.questionComments.forEach((questionComment) => {
                row.push(
                    questionComment.user.displayName,
                    moment(questionComment.created_at).format('lll'),
                    questionComment.description
                );
            });

            return row;
        });

        // 2 for the index and subject, and then each questionComment has 3 columns
        const maxRowLength = 2 + maxQuestionComments * 3;

        // To ensure we have a valid CSV, every row needs the same number of values
        rows = rows.map((row) => {
            const rowLength = row.length;
            if (rowLength !== maxRowLength) {
                for (let i = 0; i < maxRowLength - rowLength; i++) {
                    row.push(undefined);
                }
            }

            return row;
        });

        const headers = ['Question Number', 'Question Subject'];
        for (let i = 0; i < maxQuestionComments; i++) {
            if (i === 0) {
                headers.push('Question Poster', 'Question Timestamp', 'Question Body');
            } else {
                headers.push('Response Poster', 'Response Timestamp', 'Response Body');
            }
        }

        exportArrayToCSV([headers].concat(rows), { fileName: 'Question & Answer', headers: true });
    };

    /*
     * This logic would usually belong in a selector, but we intentionally calculate it on every
     * render since the current time will change and we'd like to recalculate as often as possible.
     */
    hasDeadlinePassed = () => {
        const {
            isReverseAuctionView,
            project: {
                qaDeadline,
                timelineConfig: { hasQaDeadline },
                status,
            },
        } = this.props;

        if (isReverseAuctionView) {
            return moment().isAfter(this.auctionQADeadline) || status !== REVERSE_AUCTION;
        }

        if (hasQaDeadline && qaDeadline) {
            return moment().isAfter(qaDeadline);
        }

        return false;
    };

    questionHandler = (data) => {
        const { project } = this.props;
        this.props.postQuestion(project.id, {
            ...data,
            isAuctionQuestion: project.status === REVERSE_AUCTION,
        });
    };

    responseHandler = (type, questionId, questionCommentId, message) => (data) => {
        const { postResponse, project } = this.props;

        postResponse(type, project.id, questionId, questionCommentId, data, { message });
    };

    renderActionButtons() {
        const {
            canEdit,
            hideQuestionForm,
            isReverseAuctionView,
            postError,
            posting,
            project: {
                allowSubstitutionRequests,
                id,
                template: { substitutionRequestFormUrl },
            },
            showQuestionForm,
            shouldShowQuestionForm,
        } = this.props;

        if (isReverseAuctionView) {
            return null;
        }

        return (
            <>
                <div className="text-right">
                    {!shouldShowQuestionForm && (
                        <Button
                            bsSize="sm"
                            bsStyle="link"
                            disabled={!canEdit}
                            onClick={showQuestionForm}
                            qaTag="questionAnswer-addVendorQuestions"
                            tooltip={
                                canEdit
                                    ? undefined
                                    : 'Only sourcing collaborators can add questions'
                            }
                        >
                            <i className="fa fa-plus" />
                            &nbsp;Add Vendor Question
                        </Button>
                    )}
                    <ButtonGroup bsSize="sm">
                        <ReportsModalButton type="questionAnswerReport" />
                        <Button
                            onClick={this.handleExportCSVClick}
                            qaTag="questionAnswer-exportToCsv"
                        >
                            <i className="fa fa-download" /> Export to CSV
                        </Button>
                    </ButtonGroup>
                    <GovQuestionStatusFilterForm formClassName={this.styles.filterForm} />
                </div>
                {shouldShowQuestionForm && (
                    <div className={`row ${this.styles.govQuestionFormContainer}`}>
                        <div className="col-sm-offset-1 col-sm-10 col-lg-offset-2 col-lg-8">
                            <QuestionForm
                                allowSubstitutionRequests={allowSubstitutionRequests}
                                closeForm={hideQuestionForm}
                                onSubmit={this.questionHandler}
                                posting={posting}
                                projectId={id}
                                submitError={postError}
                                substitutionRequestFormUrl={substitutionRequestFormUrl}
                            />
                        </div>
                    </div>
                )}
            </>
        );
    }

    renderGovQuestionAnswer() {
        const {
            canEdit,
            canRelease,
            isReverseAuctionView,
            pathname,
            project: {
                government: {
                    organization: { timezone },
                },
            },
            selectedNumber,
            showReleaseQuestionResponsesModal,
            showReleaseResponsesModal,
            toggleEditQuestionForm,
            toggleEditResponseForm,
            user,
        } = this.props;

        if (this.unfilteredQuestions.length === 0) {
            return (
                <>
                    {this.renderActionButtons()}
                    <NoItems
                        header="No questions submitted yet"
                        subheader="Once a vendor submits a question, it will be displayed here"
                    />
                </>
            );
        }

        return (
            <div className="row">
                <div className="col-sm-4 hidden-xs">
                    <Sticky
                        bottomBoundary="#question-answer-list"
                        top={FIXED_TOOLBAR_ADJUSTMENT_HEIGHT}
                    >
                        <GovQuestionSidebar
                            canRelease={canRelease}
                            pathname={pathname}
                            questions={this.unfilteredQuestions}
                            selectedNumber={selectedNumber}
                            showReleaseResponsesModal={showReleaseResponsesModal}
                        />
                    </Sticky>
                </div>
                <div className="col-sm-8" id="question-answer-list">
                    {this.renderActionButtons()}
                    <QuestionList
                        canEdit={canEdit}
                        canRelease={canRelease}
                        isGovUser
                        isReverseAuctionView={isReverseAuctionView}
                        pathname={pathname}
                        questions={this.filteredQuestions}
                        responseHandler={this.responseHandler}
                        selectedNumber={selectedNumber}
                        showRegularAndAuctionQuestions={this.showRegularAndAuctionQuestions}
                        showReleaseResponsesModal={showReleaseResponsesModal}
                        timezone={timezone}
                        toggleEditQuestionForm={toggleEditQuestionForm}
                        toggleEditResponseForm={toggleEditResponseForm}
                        user={user}
                    />
                </div>
                {showReleaseQuestionResponsesModal && (
                    <ReleaseQuestionResponsesModal questions={this.unfilteredQuestions} />
                )}
            </div>
        );
    }

    renderPublicQuestionAnswer() {
        return (
            <Media query={`(max-width: ${SCREEN_XS_MAX}px)`}>
                {(matches) => this.renderPublic(matches)}
            </Media>
        );
    }

    renderPublic(noSticky) {
        const {
            isOpen,
            isReverseAuctionView,
            hideSidebar,
            postError,
            posting,
            project,
            project: {
                government: {
                    organization: { timezone },
                },
                template: { isReverseAuction },
            },
            selectedNumber,
            shouldShowQuestionForm,
            toggleEditQuestionForm,
            user,
        } = this.props;

        const hasDeadlinePassed = this.hasDeadlinePassed();
        const isReverseAuctionOpen = isReverseAuction && isReverseAuctionView && !hasDeadlinePassed;

        return (
            <div className="row">
                {!hideSidebar && (
                    <div className="col-sm-4">
                        <Sticky bottomBoundary="#question-answer-list" enabled={!noSticky}>
                            <PublicQuestionSidebar
                                allowSubstitutionRequests={project.allowSubstitutionRequests}
                                auctionQADeadline={this.auctionQADeadline}
                                closeForm={this.props.hideQuestionForm}
                                hasDeadlinePassed={hasDeadlinePassed}
                                isOpen={isOpen || isReverseAuctionOpen}
                                isPosting={posting}
                                isReverseAuctionView={isReverseAuctionView}
                                postingError={postError}
                                projectId={project.id}
                                qaDeadline={project.qaDeadline}
                                questionHandler={this.questionHandler}
                                showForm={shouldShowQuestionForm}
                                showQuestionForm={this.props.showQuestionForm}
                                substitutionRequestFormUrl={
                                    project.template.substitutionRequestFormUrl
                                }
                                timezone={timezone}
                                user={user}
                            />
                        </Sticky>
                    </div>
                )}
                <div className={hideSidebar ? 'col-sm-12' : 'col-sm-8'} id="question-answer-list">
                    <QuestionList
                        hasDeadlinePassed={hasDeadlinePassed}
                        isOpen={isOpen || isReverseAuctionOpen}
                        questions={this.unfilteredQuestions}
                        responseHandler={this.responseHandler}
                        selectedNumber={selectedNumber}
                        showRegularAndAuctionQuestions={this.showRegularAndAuctionQuestions}
                        timezone={timezone}
                        toggleEditQuestionForm={toggleEditQuestionForm}
                        user={user}
                    />
                </div>
            </div>
        );
    }

    render() {
        const { isGovUser, loaded, loadError, loading, project } = this.props;

        // Check loaded for vendor Q&A. Project needs to be loaded before
        // loading the questions, so display spinner until questions loaded.
        if (loadError) return <LoadingError error={loadError} />;
        if (loading || !project || !loaded) return <LoadingSpinner />;

        return isGovUser ? this.renderGovQuestionAnswer() : this.renderPublicQuestionAnswer();
    }
}

export const QuestionAnswer = connect(mapStateToProps, mapDispatchToProps)(ConnectedQuestionAnswer);
