import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Field, FieldArray, reduxForm } from 'redux-form';

import { Checkbox } from '../../Checkbox/Checkbox';

const formConfig = {
    form: 'evaluationComparisonQuestionnaireSelectForm',
};

class ConnectedQuestionnaireSelectForm extends PureComponent {
    static propTypes = {
        arrayName: PropTypes.string.isRequired,
        change: PropTypes.func.isRequired,
        questionnaires: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                prompt: PropTypes.string,
                questionnaireResponses: PropTypes.arrayOf(PropTypes.object).isRequired,
                title: PropTypes.string.isRequired,
                type: PropTypes.string.isRequired,
            })
        ).isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    selectAll = (fields) => (value) => {
        fields.map((name) => {
            return this.props.change(name, value);
        });
    };

    renderFields = ({ fields }) => {
        const { questionnaires } = this.props;

        const questionnaireCheckboxes = fields.map((name, index) => {
            const questionnaire = questionnaires[index];
            return (
                <Field
                    className={this.styles.checkbox}
                    component={Checkbox}
                    formGroupClass={this.styles.checkboxFormGroup}
                    key={index}
                    name={name}
                    text={questionnaire.title}
                />
            );
        });

        return (
            <>
                <Field
                    className={this.styles.checkbox}
                    component={Checkbox}
                    formGroupClass={this.styles.checkboxFormGroup}
                    name="selectAll"
                    onChange={this.selectAll(fields)}
                    text={<em>Select All/None</em>}
                />
                {questionnaireCheckboxes}
            </>
        );
    };

    render() {
        const { arrayName } = this.props;

        return (
            <form className={this.styles.container}>
                <h5>Select Questions to Compare:</h5>
                <FieldArray component={this.renderFields} name={arrayName} />
            </form>
        );
    }
}

export const QuestionnaireSelectForm = reduxForm(formConfig)(ConnectedQuestionnaireSelectForm);
