const { listToDict } = require('../helpers');

exports.contractStatuses = ['active', 'ended', 'upcoming'];

exports.contractStatusesDict = listToDict(exports.contractStatuses);

exports.contractStatusesTextMap = {
    [exports.contractStatusesDict.ACTIVE]: 'Active',
    [exports.contractStatusesDict.ENDED]: 'Ended',
    [exports.contractStatusesDict.UPCOMING]: 'Upcoming',
};

exports.contractFilterTypes = [
    'budgetAmount',
    'contractId',
    'cooperative',
    'department',
    'endDate',
    'hasVendor',
    'ids',
    'piggyback',
    'procurementContact',
    'public',
    'rebid',
    'startDate',
    'status',
    'tag',
    'termType',
    'title',
    'vendorAssignedNo',
    'vendorName',
    'retention_code_id',
];

exports.contractFilterTypesDict = listToDict(exports.contractFilterTypes);

exports.contractDateFilterTypes = ['after', 'before', 'between'];

exports.contractDateFilterTypesDict = listToDict(exports.contractDateFilterTypes);

exports.contractCostFilterTypes = ['above', 'below'];

exports.contractCostFilterTypesDict = listToDict(exports.contractCostFilterTypes);

exports.contractPermissions = ['creator', 'edit', 'view'];

exports.contractPermissionsDict = listToDict(exports.contractPermissions);

exports.contractStates = ['draft', 'published'];

exports.contractStatesDict = listToDict(exports.contractStates);

exports.durationTypes = [
    'completion', // The contract ends when the project is completed (ex. the road is built)
    'ongoing', // The contract has no determined end date (ex. gardening services)
    'termBased', // The contract has a set duration (possibly with options to extend)
];

exports.durationTypesDict = listToDict(exports.durationTypes);

exports.durationTextMap = {
    [exports.durationTypesDict.COMPLETION]: 'Upon Completion',
    [exports.durationTypesDict.ONGOING]: 'Continuous',
    [exports.durationTypesDict.TERM_BASED]: 'Limited Term',
};

exports.termUnits = ['years', 'months', 'weeks', 'days'];

exports.termUnitsDict = listToDict(exports.termUnits);

exports.contractInsuranceFormFieldsDict = {
    APPROVED_DATE: 'approvedDate',
    EXPIRATION_DATE: 'expirationDate',
    REQUESTED_DATE: 'requestedDate',
    TAG_ID: 'tag_id',
};

exports.DEFAULT_PAGE_SIZE_LIMIT = 20;
exports.DEFAULT_PAGE = 1;

exports.contractSortFields = [
    'contractParty.companyName',
    'title',
    'contractId',
    'isCooperative',
    'budget.issued',
    'budget.amount',
    'departmentName',
    'contactDisplayName',
    'procurementDisplayName',
    'durationType',
    'contractRenewals',
    'endDate',
];

exports.contractSortFieldsDict = listToDict(exports.contractSortFields);

let bucket;

if (__PROD__) {
    bucket = 'government-contracts';
} else {
    bucket = `${process.env.NODE_ENV}-government-contracts`;
}

exports.bucket = bucket;

exports.defaultContractPermission = exports.contractPermissionsDict.VIEW;

exports.PERMISSION_RANKS = {
    [exports.contractPermissionsDict.CREATOR]: 2,
    [exports.contractPermissionsDict.EDIT]: 1,
    [exports.contractPermissionsDict.VIEW]: 0,
};
