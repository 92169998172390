import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal, ListGroup, ListGroupItem } from 'react-bootstrap';
import { connect } from 'react-redux';

import { getAdminUsersJS } from './selectors';
import { hideAdminUsersModal } from '../../../actions/admin';
import { UserProfilePicture } from '../../UserProfilePicture/UserProfilePicture';
import { ZeroState } from '../../ZeroState';

const mapStateToProps = (state, props) => {
    return {
        adminUsers: getAdminUsersJS(state, props),
        show: state.admin.get('showAdminUsersModal'),
    };
};

const mapDispatchToProps = {
    hideModal: hideAdminUsersModal,
};

// @connect
class ConnectedAdminUserListModal extends Component {
    static propTypes = {
        adminUsers: PropTypes.arrayOf(
            PropTypes.shape({
                displayName: PropTypes.string.isRequired,
                email: PropTypes.string.isRequired,
                id: PropTypes.number.isRequired,
            })
        ).isRequired,
        hideModal: PropTypes.func.isRequired,
        // Used by the selector
        permissionType: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
        show: PropTypes.bool.isRequired,
    };

    componentWillUnmount() {
        const { hideModal, show } = this.props;

        if (show) {
            hideModal();
        }
    }

    get styles() {
        return require('./index.scss');
    }

    render() {
        const { adminUsers, hideModal, show } = this.props;

        if (!show) {
            return null;
        }

        const AdminUsers = adminUsers.map((admin) => (
            <ListGroupItem key={admin.id}>
                <UserProfilePicture
                    className={this.styles.userProfilePicture}
                    horizontal
                    user={admin}
                />
                <div className={this.styles.adminName}>
                    <strong>{admin.displayName}</strong>
                    <br />
                    <em>{admin.email}</em>
                </div>
            </ListGroupItem>
        ));

        return (
            <Modal onHide={hideModal} show>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">Admin User List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ListGroup>
                        {adminUsers.length === 0 ? (
                            <ZeroState title="No admin users found" />
                        ) : (
                            AdminUsers
                        )}
                    </ListGroup>
                </Modal.Body>
            </Modal>
        );
    }
}

export const AdminUserListModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedAdminUserListModal);
