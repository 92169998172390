import PropTypes from 'prop-types';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { NavItem } from '../../Nav/NavItem';

export const ProposalCreateNavItem = ({
    eventKey,
    hasStatusNode,
    isFormDirty,
    isIndexActive,
    meta: { valid },
    route,
    saveHandler,
    title,
    titleForQaTag,
}) => {
    const navigate = useNavigate();
    const { search } = useLocation();
    const styles = require('./index.scss');

    const handleClick = () => {
        if (isFormDirty) {
            saveHandler();
        }
        navigate(route);
    };

    const getStatusNode = () => {
        // Display the status node regardless of whether section is valid
        if (hasStatusNode) {
            return (
                <span
                    className={`pull-right label label-${valid ? 'success' : 'warning'} ${
                        styles.statusLabel
                    }`}
                >
                    {valid ? 'Ready' : 'Incomplete'}
                </span>
            );
        }

        // Only show the check mark if the section is valid
        if (!valid) {
            return null;
        }

        return (
            <i
                aria-hidden="true"
                className={`fa fa-check-circle fa-lg pull-right ${styles.checkIcon}`}
            />
        );
    };

    const isActive = search?.replace('?', '') === route.search || isIndexActive;

    return (
        <NavItem
            className={isActive ? 'active' : undefined}
            eventKey={eventKey}
            onClick={handleClick}
            qaTag={`proposalCreate-${titleForQaTag}`}
        >
            {getStatusNode()}
            {eventKey + 1}. {title}
        </NavItem>
    );
};

ProposalCreateNavItem.propTypes = {
    eventKey: PropTypes.number.isRequired,
    hasStatusNode: PropTypes.bool.isRequired,
    isFormDirty: PropTypes.bool.isRequired,
    isIndexActive: PropTypes.bool.isRequired,
    meta: PropTypes.shape({
        valid: PropTypes.bool,
    }).isRequired,
    route: PropTypes.object.isRequired,
    saveHandler: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    titleForQaTag: PropTypes.string.isRequired,
};
