import { TaskAlt as TaskAltIcon, Refresh as RefreshIcon } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@og-pro/ui';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { form } from './constants';
import { AiImportSectionForm } from './AiImportSectionForm';
import { ZeroState } from '../../../../../../../../components';
import { showSnackbar } from '../../../../../../../../actions/notification';

export const AiImporterSections = ({
    disabled,
    filename,
    importSection,
    onCancel,
    removeSection,
    sections,
}) => {
    const [importing, setImporting] = useState(false);
    const dispatch = useDispatch();

    const handleImport = (uuid) => async (data) => {
        try {
            setImporting(true);
            await importSection(uuid, data);
            dispatch(showSnackbar(`Imported "${data.title}"!`));
        } catch (error) {
            dispatch(showSnackbar(error.message, { isError: true }));
        } finally {
            setImporting(false);
        }
    };

    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingBottom: 1 }}>
                <Box>
                    <Typography>Imported Template:</Typography>
                    <Typography sx={{ fontStyle: 'italic' }} variant="bodySmall">
                        {filename}
                    </Typography>
                </Box>
                <Box>
                    <Button
                        disabled={disabled}
                        onClick={onCancel}
                        qaTag="aiImporter-restart-import"
                        startIcon={<RefreshIcon />}
                    >
                        Restart Import
                    </Button>
                </Box>
            </Box>
            {sections.length === 0 && (
                <Box
                    component={ZeroState}
                    info="Upload another template file or edit the imported sections using the template editor"
                    title={
                        <>
                            <TaskAltIcon fontSize="inherit" /> No more sections to import!
                        </>
                    }
                    useOpenGovStyle
                />
            )}
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                {sections.map((section) => {
                    return (
                        <AiImportSectionForm
                            disabled={disabled}
                            form={`${form}-${section.uuid}`}
                            importing={importing}
                            initialValues={section}
                            key={section.uuid}
                            onSubmit={handleImport(section.uuid)}
                            removeSection={() => removeSection(section.uuid)}
                            uuid={section.uuid}
                        />
                    );
                })}
            </Box>
        </Box>
    );
};

AiImporterSections.propTypes = {
    disabled: PropTypes.bool.isRequired,
    filename: PropTypes.string.isRequired,
    importSection: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    removeSection: PropTypes.func.isRequired,
    sections: PropTypes.array.isRequired,
};
