import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useSelector } from 'react-redux';

import { FLAGS, useFlags } from '@og-pro/launch-darkly/client';
import { projectTypesDict } from '@og-pro/shared-config/projects';

import { SearchSelect } from '../../SearchSelect/SearchSelect';
import { SearchSelectIconOption } from '../../SearchSelectIconOption';
import { SearchSelectIconValue } from '../../SearchSelectIconValue';
import { SearchSelectCustomControlWithIcon } from '../../SearchSelect/CustomControlSearchIcon';
import { SDv2AddSectionSectionContainer } from './SectionContainer';
import { CDSButton } from '../CDSButtons';
import {
    getAvailableSectionsToAdd,
    getAvailableTemplateSectionsToAdd,
    isEvaluationSection,
} from '../helpers';
import { SHARED_SECTION_TEMP_FIELDNAME } from '../../../containers/GovApp/TemplateAdmin/forms/constants';
import { getDeserializedTemplateProject } from '../../../containers/GovApp/TemplateAdmin/TemplateEdit/selectors';

const CollapsableIcon = ({ open }) => {
    if (open) {
        return (
            <span>
                <i className="fa fa-chevron-down fa-fw" />
                &nbsp;
            </span>
        );
    }

    return (
        <span>
            <i className="fa fa-chevron-right fa-fw" />
            &nbsp;
        </span>
    );
};
CollapsableIcon.propTypes = { open: PropTypes.bool.isRequired };

export const SDv2AddSection = ({
    allowSectionDividers,
    collapsable,
    forceOpen,
    isDocBuilder,
    isIntake,
    onAddSection,
    onCancel,
    projectSections,
    enableSharedSections,
    templateSections,
}) => {
    const enablePackageCompiler = useFlags(FLAGS.ENABLE_CONTRACT_PACKAGE_COMPILER);
    const templateProject = useSelector(getDeserializedTemplateProject);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (forceOpen) {
            setOpen(true);
        }

        if (!collapsable) {
            setOpen(true);
        }
    }, [forceOpen, collapsable]);

    const toggleCollapse = () => {
        if (collapsable) {
            setOpen(!open);
        }
    };

    const usedSections = projectSections.map((s) => s.section_type);
    const usedTemplateSections = projectSections.filter((s) => s.isTemplate).map((s) => s.sharedId);
    const hasEvaluation = projectSections.some(isEvaluationSection);
    const { general, special } = getAvailableSectionsToAdd({
        isIntake,
        allowSectionDividers,
        usedSections,
        isDocBuilder,
        renameAttachmentsAsExhibits: enablePackageCompiler,
    });
    const templateSectionOptions = getAvailableTemplateSectionsToAdd({
        templateSections,
        isIntake,
        usedTemplateSections,
        usedSections,
        isDocBuilder,
        hasEvaluation,
    });

    const styles = require('./index.scss');

    if (general.length + special.length === 0) {
        return null;
    }

    return (
        <div className={styles.container}>
            <div className={classnames(styles.header, { [styles.collapsableHeader]: collapsable })}>
                <CDSButton
                    noPadding
                    onClick={() => toggleCollapse()}
                    qaTag="templateEditor-addSectionButton"
                    variant="text"
                >
                    {collapsable && <CollapsableIcon open={open} />}Add a Section
                </CDSButton>
            </div>
            <div
                className={classnames(styles.sectionsContainer, {
                    [styles.visible]: open,
                    [styles.sectionContainerCollapsable]: collapsable,
                })}
            >
                <SDv2AddSectionSectionContainer
                    onAddSection={onAddSection}
                    sectionTypes={general}
                    subtitle="Can be used multiple times."
                    title="General Use Section"
                />
                {templateProject.type !== projectTypesDict.CONTRACT && (
                    <SDv2AddSectionSectionContainer
                        onAddSection={onAddSection}
                        sectionTypes={special}
                        subtitle="Can be used once per document."
                        title="Special Use Section"
                    />
                )}
                {enableSharedSections && (
                    <div className={styles.sectionContainer}>
                        <h5 className={styles.subheader}>Shared Sections</h5>
                        <div className={styles.subheaderHelp}>
                            Content created by your organization.
                        </div>
                        <div className={styles.sectionType}>
                            <SearchSelect
                                components={{
                                    Option: SearchSelectIconOption,
                                    SingleValue: SearchSelectIconValue,
                                    Control: SearchSelectCustomControlWithIcon,
                                }}
                                input={{
                                    value: '',
                                    onChange: (value) => {
                                        const templateSection = templateSections.find(
                                            (ts) => ts.id === value
                                        );

                                        onAddSection(templateSection, true);
                                    },
                                    onBlur: () => {},
                                }}
                                menuStyle={{ position: 'relative !important' }}
                                meta={{ error: null, touched: false, warning: null }}
                                name={SHARED_SECTION_TEMP_FIELDNAME}
                                noOptionsMessage={() => 'No shared sections available'}
                                openMenuOnFocus
                                options={templateSectionOptions}
                                overrideFeedback
                                placeholder="Search Shared Section"
                                styles={{
                                    indicatorsContainer: { display: 'none' },
                                }}
                                useNullWhenUndefined
                            />
                        </div>
                    </div>
                )}
                {onCancel && (
                    <div className={styles.cancelContainer}>
                        <CDSButton
                            onClick={() => onCancel()}
                            qaTag="templateEditor-addSectionCancel"
                            variant="secondary"
                        >
                            Cancel
                        </CDSButton>
                    </div>
                )}
            </div>
        </div>
    );
};

SDv2AddSection.propTypes = {
    allowSectionDividers: PropTypes.bool,
    collapsable: PropTypes.bool,
    isDocBuilder: PropTypes.bool,
    isIntake: PropTypes.bool,
    forceOpen: PropTypes.bool,
    enableSharedSections: PropTypes.bool,
    onAddSection: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    projectSections: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    templateSections: PropTypes.arrayOf(PropTypes.shape({})),
};
