import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { stringToHslColor } from '@og-pro/shared-config/helpers';

import { Tooltip } from '../Tooltip';

export class UserProfilePicture extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        defaultIcon: PropTypes.string,
        horizontal: PropTypes.bool,
        showTooltip: PropTypes.bool,
        tooltipPlacement: PropTypes.string,
        user: PropTypes.shape({
            avatarUrl: PropTypes.string,
            displayName: PropTypes.string.isRequired,
            email: PropTypes.string,
            initials: PropTypes.string,
        }).isRequired,
    };

    static defaultProps = {
        defaultIcon: 'fa-user-o',
        horizontal: false,
        tooltipPlacement: 'bottom',
    };

    get styles() {
        return require('./UserProfilePicture.scss');
    }

    renderTooltip() {
        const {
            user: { displayName },
            tooltipPlacement,
        } = this.props;

        return (
            <Tooltip delayShow={500} placement={tooltipPlacement} tooltip={displayName}>
                {this.renderProfilePicture()}
            </Tooltip>
        );
    }

    renderProfilePicture() {
        const {
            className,
            defaultIcon,
            horizontal,
            showTooltip,
            user: { avatarUrl, displayName, email, initials },
        } = this.props;

        const hslColor = stringToHslColor(email);
        const sharedStyles = {
            backgroundColor: hslColor,
            display: horizontal ? 'inline-block' : undefined,
            cursor: showTooltip ? 'pointer' : undefined,
        };

        if (avatarUrl) {
            return (
                <div
                    className={classnames(this.styles.avatarContainer, className)}
                    style={{ ...sharedStyles, backgroundImage: `url(${avatarUrl})` }}
                    title={`${displayName} profile picture`}
                >
                    &nbsp;
                </div>
            );
        }

        return (
            <div
                className={classnames(this.styles.pictureContainer, className)}
                style={sharedStyles}
                title={`${displayName} profile picture`}
            >
                <b>{initials || <i className={classnames('fa', defaultIcon)} />}</b>
            </div>
        );
    }

    render() {
        return this.props.showTooltip ? this.renderTooltip() : this.renderProfilePicture();
    }
}
