import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Field } from 'redux-form';

import { sectionTypeNames } from '@og-pro/shared-config/sections';

import { subsectionTypeNames } from '@og-pro/shared-config/subsections';

import { projectFormProps, fieldNames, form } from '../constants';
import { CommentIcon } from '../../CommentIcon/CommentIcon';
import { Main, RichTextInput } from '../../../../components';
import { LegacyIntroductionForm } from '../../../../components/SDv2';
import { Timeline } from '../../../../components/GovApp';

import { INTRODUCTION_VARIABLES } from '../../constants';
import { buildTabRoute } from '../../../../helpers';

const { INTRODUCTION } = sectionTypeNames;
const { BACKGROUND, SUMMARY, TIMELINE } = subsectionTypeNames;
const { RAW_BACKGROUND, RAW_SUMMARY } = fieldNames;

export class Intro extends PureComponent {
    static propTypes = { ...projectFormProps, isOGThemeEnabledForComponents: PropTypes.bool };

    componentDidMount() {
        const { builderSection, markBuilderSectionVisited } = this.props;

        // Display help modal if Intro section has custom instructions
        // (modal only gets shown when truthy value is passed to `markBuilderSectionVisited`)
        const helpModalData = get(builderSection, 'instructions') ? {} : undefined;
        markBuilderSectionVisited(helpModalData);
    }

    formName = INTRODUCTION;

    get styles() {
        return require('./Intro.scss');
    }

    get filteredVariableOptions() {
        const options = this.props.templateVariableOptions;
        if (options) {
            return options.filter((opt) => opt.label !== INTRODUCTION_VARIABLES);
        }
        return [];
    }

    handleBackgroundHelpIconClick = () => {
        const { showHelpModal } = this.props;

        showHelpModal({ type: 'background' });
    };

    handleSummaryHelpIconClick = () => {
        const { showHelpModal } = this.props;

        showHelpModal({ type: 'summary' });
    };

    render() {
        const {
            array,
            change,
            disabled,
            isOGThemeEnabledForComponents,
            location,
            project,
            projectSection,
            projectSubsectionsMap,
            showComments,
            showFormErrors,
            tagOptions,
            timezone,
        } = this.props;

        const {
            backgroundField,
            displayBackgroundComment,
            displaySummaryComment,
            displayTimelineComment,
            summaryField,
            timelineContainer,
            timelineGroup,
            titleText,
        } = this.styles;

        const backgroundProjectSubsectionTitle = get(projectSubsectionsMap[BACKGROUND], 'title');
        const summaryProjectSubsectionTitle = get(projectSubsectionsMap[SUMMARY], 'title');
        const timelineProjectSubsectionTitle = get(projectSubsectionsMap[TIMELINE], 'title');

        if (isOGThemeEnabledForComponents) {
            const buildRoute = buildTabRoute(location);

            return (
                <LegacyIntroductionForm
                    {...this.props}
                    hideTimeline={project.isIntake}
                    isReverseAuction={project.template.isReverseAuction}
                    links={{
                        summaryAndBackground: `${buildRoute('project-properties')}?activeSection=2`,
                        timeline: `${buildRoute('project-properties')}?activeSection=3`,
                    }}
                />
            );
        }

        return (
            <Main className="row">
                <div className="col-xs-12">
                    <Field
                        className={summaryField}
                        commentData={{
                            projectSection,
                            subsectionType: SUMMARY,
                            hideClassName: displaySummaryComment,
                        }}
                        commentIcon={showComments}
                        component={RichTextInput}
                        disabled={disabled}
                        help="Write a summary. Keep it short and snappy. Limit it to 1 to 3 paragraphs"
                        helpIcon
                        helpIconClick={this.handleSummaryHelpIconClick}
                        label={summaryProjectSubsectionTitle}
                        name={RAW_SUMMARY}
                        placeholder="Enter Text"
                        showValidation={showFormErrors}
                        tagOptions={tagOptions}
                        templateVariableOptions={this.filteredVariableOptions}
                        toolbarPlacement="bottom"
                    />
                    <Field
                        className={backgroundField}
                        commentData={{
                            projectSection,
                            subsectionType: BACKGROUND,
                            hideClassName: displayBackgroundComment,
                        }}
                        commentIcon={showComments}
                        component={RichTextInput}
                        disabled={disabled}
                        help={
                            'Add some background info. This is optional - Just ' +
                            "leave it blank if you don't need it"
                        }
                        helpIcon
                        helpIconClick={this.handleBackgroundHelpIconClick}
                        label={`${backgroundProjectSubsectionTitle} (optional)`}
                        minRows={3}
                        name={RAW_BACKGROUND}
                        placeholder="Enter Text (optional)"
                        showValidation={showFormErrors}
                        tagOptions={tagOptions}
                        templateVariableOptions={this.filteredVariableOptions}
                        toolbarPlacement="bottom"
                    />
                    {!project.isIntake && (
                        <div className={timelineContainer}>
                            <label className={titleText}>{timelineProjectSubsectionTitle}</label>
                            <CommentIcon
                                hideClassName={displayTimelineComment}
                                iconLeft
                                projectSection={projectSection}
                                show={showComments}
                                subsectionType={TIMELINE}
                            />
                            <p>
                                Please fill in the dates below to meet your project needs. You may
                                also edit the timline configuration to add, reorder, and remove
                                timeline dates.
                            </p>
                            <Timeline
                                array={array}
                                change={change}
                                className={timelineGroup}
                                disabled={disabled}
                                form={form}
                                project={project}
                                showValidation={showFormErrors}
                                timezone={timezone}
                            />
                        </div>
                    )}
                </div>
            </Main>
        );
    }
}
