import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';

import { fieldNamesDict, form } from './constants';
import { getDataJS } from '../selectors';
import { Dialog } from '../../../Dialog';
import { SearchSelect } from '../../../SearchSelect/SearchSelect';
import { hideInsertTemplateVariableModal } from '../../../../actions/app';

const { VALUE } = fieldNamesDict;

const modalProps = { stateKey: 'insertTemplateVariableModalData' };

const mapStateToProps = (state) => {
    return {
        ...getDataJS(state, modalProps),
        showModal: state.app.get('showInsertTemplateVariableModal'),
    };
};

const mapDispatchToProps = {
    hideInsertTemplateVariableModal,
};

const formConfig = {
    form,
};

// @connect
// @reduxForm
class ConnectedInsertTemplateVariableModal extends Component {
    static propTypes = {
        hideInsertTemplateVariableModal: PropTypes.func.isRequired,
        onHide: PropTypes.func,
        onInsert: PropTypes.func.isRequired,
        options: PropTypes.arrayOf(
            PropTypes.shape({
                // Uses the `group` options for `react-select`
                label: PropTypes.string.isRequired,
                options: PropTypes.arrayOf(
                    PropTypes.shape({
                        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
                    })
                ),
            })
        ).isRequired,
        showModal: PropTypes.bool,
    };

    static defaultProps = {
        onHide: undefined,
        showModal: false,
    };

    get styles() {
        return require('./index.scss');
    }

    handleHide = () => {
        const { onHide } = this.props;

        if (onHide) {
            onHide();
        }

        this.props.hideInsertTemplateVariableModal();
    };

    handleInsertSubmit = (option) => {
        const { onInsert } = this.props;

        const { label, value } = option || {};

        if (value === undefined || value === '' || value === null) {
            return;
        }

        onInsert(label, value);

        this.props.hideInsertTemplateVariableModal();
    };

    render() {
        const { options, showModal } = this.props;

        if (!showModal) {
            return null;
        }

        return (
            <Dialog
                allowOverflowedContent
                classes={{
                    scrollPaper: this.styles.scrollPaper,
                }}
                fullWidth
                maxWidth="sm"
                onClose={this.handleHide}
                title="Insert Template Variable"
            >
                <Field
                    component={SearchSelect}
                    label="Select the Variable to Insert"
                    menuIsOpen
                    name={VALUE}
                    onChangeWithOption={this.handleInsertSubmit}
                    options={options}
                    overrideFeedback
                    placeholder="Select a variable"
                />
            </Dialog>
        );
    }
}

export const InsertTemplateVariableModal = compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig)
)(ConnectedInsertTemplateVariableModal);
