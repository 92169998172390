import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { arrayPush, change, FieldArray } from 'redux-form';
import { connect } from 'react-redux';
import { v4 as UUIDv4 } from 'uuid';

import { fieldNames } from './constants';
import { Attachable } from '../Attachable';
import { AttachmentList } from '../../connected';

const { ATTACHMENTS } = fieldNames;

const mapDispatchToProps = {
    arrayPush,
    change,
};

// @connect
class ConnectedProjectAttachmentBlock extends Component {
    static propTypes = {
        allowedReassignmentTypes: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string,
                value: PropTypes.string,
            })
        ),
        arrayPush: PropTypes.func.isRequired,
        attachmentListLabel: PropTypes.string,
        change: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        emptyListTitle: PropTypes.string,
        emptyListSubtitle: PropTypes.string,
        form: PropTypes.string.isRequired,
        formKey: PropTypes.string,
        hideAppendixLetter: PropTypes.bool,
        hideNoAttachments: PropTypes.bool,
        isOGThemeEnabledForComponents: PropTypes.bool,
        label: PropTypes.string,
        listAttachmentExcludeType: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.arrayOf(PropTypes.string),
        ]),
        listAttachmentType: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.arrayOf(PropTypes.string),
        ]),
        projectId: PropTypes.number.isRequired,
        renderQuestionLogicIcon: PropTypes.func,
        triggerUpdate: PropTypes.func,
        showNotification: PropTypes.bool,
        uploadAttachmentType: PropTypes.string.isRequired,
    };

    uploadAttachment = (fileData) => {
        const { form, uploadAttachmentType } = this.props;

        const {
            attachment: { appendixId, title },
            signedData: { bucket, filename, key, signedGetUrl },
        } = fileData;

        this.props.arrayPush(form, ATTACHMENTS, {
            appendixId,
            bucket,
            filename,
            path: key,
            sharedId: UUIDv4(),
            title,
            type: uploadAttachmentType,
            url: signedGetUrl,
        });
    };

    render() {
        const {
            allowedReassignmentTypes,
            attachmentListLabel,
            disabled,
            emptyListSubtitle,
            emptyListTitle,
            form,
            formKey,
            hideAppendixLetter,
            hideNoAttachments,
            isOGThemeEnabledForComponents,
            label,
            listAttachmentType,
            listAttachmentExcludeType,
            projectId,
            renderQuestionLogicIcon,
            showNotification,
            triggerUpdate,
        } = this.props;

        return (
            <div>
                <Attachable
                    disabled={disabled}
                    form={form}
                    formKey={formKey}
                    hideAppendixLetter={hideAppendixLetter}
                    isOGThemeEnabledForComponents={isOGThemeEnabledForComponents}
                    label={label}
                    onUploadComplete={this.uploadAttachment}
                    projectId={projectId}
                    showNotification={showNotification}
                />
                <FieldArray
                    allowedReassignmentTypes={allowedReassignmentTypes}
                    attachmentType={listAttachmentType}
                    change={this.props.change}
                    component={AttachmentList}
                    disabled={disabled}
                    emptyListSubtitle={emptyListSubtitle}
                    emptyListTitle={emptyListTitle}
                    excludeAttachmentType={listAttachmentExcludeType}
                    form={form}
                    hideAppendixLetter={hideAppendixLetter}
                    hideNoAttachments={hideNoAttachments}
                    isOGThemeEnabledForComponents={isOGThemeEnabledForComponents}
                    label={attachmentListLabel}
                    name={ATTACHMENTS}
                    renderQuestionLogicIcon={renderQuestionLogicIcon}
                    triggerUpdate={triggerUpdate}
                />
            </div>
        );
    }
}

export const ProjectAttachmentBlock = connect(
    null,
    mapDispatchToProps
)(ConnectedProjectAttachmentBlock);
