import PropTypes from 'prop-types';
import React from 'react';

import { SearchSelectMultiLabelOption } from '../SearchSelectMultiLabelOption';

export const RequisitionsSearchOption = (props) => {
    const {
        data: {
            requisition: { descriptionOfRequest, identifier },
        },
    } = props;

    return (
        <SearchSelectMultiLabelOption
            {...props}
            label={descriptionOfRequest || 'Untitled Request'}
            secondaryLabel={identifier}
        />
    );
};

RequisitionsSearchOption.propTypes = {
    data: PropTypes.shape({
        requisition: PropTypes.shape({
            descriptionOfRequest: PropTypes.string,
            identifier: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};
