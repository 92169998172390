import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getChecklistConnectedClients, getProjectConnectedClients } from './selectors';
import { UserListPopover } from '../../UserListPopover';
import { isOpenGovAdmin } from '../../../containers/App/selectors';

const mapStateToProps = (state, props) => {
    return {
        clients: props.isChecklist
            ? getChecklistConnectedClients(state)
            : getProjectConnectedClients(state),
        isOpenGovAdmin: isOpenGovAdmin(state),
    };
};

// @connect
class ConnectedConnectedClients extends Component {
    static propTypes = {
        block: PropTypes.bool,
        className: PropTypes.string,
        clients: PropTypes.arrayOf(
            PropTypes.shape({
                displayName: PropTypes.string.isRequired,
            })
        ).isRequired,
        isChecklist: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
        isOpenGovAdmin: PropTypes.bool.isRequired,
        resourceName: PropTypes.string,
        showOrganization: PropTypes.bool,
    };

    static defaultProps = {
        resourceName: 'Project',
    };

    get styles() {
        return require('./index.scss');
    }

    get clientCount() {
        const { clients } = this.props;

        // OpenGov admins are not reported by the sync server, so add one to the count
        return this.props.isOpenGovAdmin ? clients.length + 1 : clients.length;
    }

    renderConnectedClients() {
        const { className, clients, showOrganization, resourceName } = this.props;
        const usersText = this.clientCount === 2 ? 'User' : 'Users';
        return (
            <UserListPopover
                popoverId="connected-user-list"
                showOrganization={showOrganization}
                title={<div className="text-center">Users Viewing {resourceName}</div>}
                users={clients}
            >
                <div className={classnames(this.styles.counter, className)}>
                    +{this.clientCount - 1} {usersText}
                </div>
            </UserListPopover>
        );
    }

    render() {
        const { block } = this.props;

        if (this.clientCount <= 1) {
            return null;
        }

        if (block) {
            return (
                <div className={this.styles.blockContainer}>{this.renderConnectedClients()}</div>
            );
        }

        return this.renderConnectedClients();
    }
}

export const ConnectedClients = connect(mapStateToProps)(ConnectedConnectedClients);
