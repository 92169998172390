import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PlanholdersList } from './PlanholdersList';
import { getProjectJS } from '../selectors';
import { loadProjectPlanholders } from '../../../actions/publicProject';
import { SectionTitle } from '../../../components';

export const Planholders = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [planholders, setPlanholders] = useState([]);

    const dispatch = useDispatch();

    const project = useSelector(getProjectJS);

    const loadPlanholders = async (projectId) => {
        setIsLoading(true);
        setError(null);
        try {
            const loadedPlanholders = await dispatch(loadProjectPlanholders(projectId));
            setPlanholders(loadedPlanholders);
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (project.showPlanholders) {
            loadPlanholders(project.id);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const showSubmittedProposals = !project.government.hidePublicProposalSubmit;

    return (
        <>
            <SectionTitle
                info="List of the users who are following updates on this project"
                title="Followers List"
            />
            <PlanholdersList
                error={error}
                isLoading={isLoading}
                planholders={planholders}
                project={project}
                showSubmittedProposals={showSubmittedProposals}
            />
        </>
    );
};
