import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Button } from '../../../Button';
import { OutlineButton } from '../../../OutlineButton';

export class GovQuestionApprovalMenu extends PureComponent {
    static propTypes = {
        canEdit: PropTypes.bool,
        canRelease: PropTypes.bool,
        editHandler: PropTypes.func.isRequired,
        rejectQuestionHandler: PropTypes.func.isRequired,
        updateError: PropTypes.string,
        updating: PropTypes.bool,
        updateQuestionHandler: PropTypes.func.isRequired,
    };

    get styles() {
        return require('./shared.scss');
    }

    approveQuestion = () => {
        const { updateQuestionHandler } = this.props;

        updateQuestionHandler({ isApproved: true });
    };

    render() {
        const { canEdit, canRelease, editHandler, rejectQuestionHandler, updating, updateError } =
            this.props;

        return (
            <div className={`row ${this.styles.approvalMenu}`}>
                <div className="col-md-4">
                    {canEdit && (
                        <Button
                            bsSize="sm"
                            bsStyle="link"
                            disabled={updating}
                            onClick={editHandler}
                        >
                            <i className="fa fa-pencil" /> Edit Question
                        </Button>
                    )}
                </div>
                <div className={`col-md-8 text-right ${this.styles.approvalMenu}`}>
                    <OutlineButton
                        bsSize="sm"
                        bsStyle="danger"
                        disabled={!canRelease || updating}
                        onClick={rejectQuestionHandler}
                        tooltip={
                            canRelease
                                ? undefined
                                : 'Only sourcing editors and admins can exclude questions'
                        }
                    >
                        <i className="fa fa-ban" /> Exclude
                    </OutlineButton>
                    &nbsp;&nbsp;
                    <Button
                        bsSize="sm"
                        bsStyle="success"
                        disabled={!canRelease || updating}
                        onClick={this.approveQuestion}
                        tooltip={
                            canRelease
                                ? undefined
                                : 'Only sourcing editors and admins can approve questions'
                        }
                    >
                        <i className="fa fa-check" /> Approve
                    </Button>
                </div>
                <div className={`col-xs-12 text-right text-muted ${this.styles.approvalMenu}`}>
                    <small>
                        <em>Vendor will be notified when question is approved or excluded</em>
                    </small>
                </div>
                {updateError && <div className="col-xs-12 error-block">{updateError}</div>}
            </div>
        );
    }
}
