import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { withRouter } from '@og-pro-migration-tools/react-router';
import classnames from 'classnames';

import { subsectionTypeNames } from '@og-pro/shared-config/subsections';

import { Timeline } from '../../../../../../../components/GovApp';
import { TemplateSectionDescriptionForm } from '../TemplateSectionDescriptionForm';
import { fieldNames } from '../../constants';
import { Label, RichTextInput, Toggle } from '../../../../../../../components';
import { LegacyIntroductionForm } from '../../../../../../../components/SDv2';
import { INTRODUCTION_VARIABLES } from '../../../../../constants';
import { OGThemeContext } from '../../../../../ogThemeProvider';
import { buildTabRoute } from '../../../../../../../helpers';

const {
    BACKGROUND,
    HIDE_CONTACT,
    HIDE_CONTACT_ADDRESS,
    HIDE_PROCUREMENT_CONTACT,
    HIDE_TIMELINE,
    SUMMARY,
} = fieldNames;

const {
    BACKGROUND: BACKGROUND_SECTION_DESCRIPTION,
    CONTACT: CONTACT_SECTION_DESCRIPTION,
    SUMMARY: SUMMARY_SECTION_DESCRIPTION,
    TIMELINE: TIMELINE_SECTION_DESCRIPTION,
} = subsectionTypeNames;

const mapStateToProps = (state, props) => {
    const {
        projectSection: { projectSubsections },
    } = props;

    return {
        backgroundSubsectionId: projectSubsections.find(
            (sub) => sub.subsection_type === BACKGROUND_SECTION_DESCRIPTION
        ).id,
        contactSubsectionId: projectSubsections.find(
            (sub) => sub.subsection_type === CONTACT_SECTION_DESCRIPTION
        ).id,
        summarySubsectionId: projectSubsections.find(
            (sub) => sub.subsection_type === SUMMARY_SECTION_DESCRIPTION
        ).id,
        timelineSubsectionId: projectSubsections.find(
            (sub) => sub.subsection_type === TIMELINE_SECTION_DESCRIPTION
        ).id,
    };
};

// @connect
class ConnectedTemplateIntroductionForm extends PureComponent {
    static propTypes = {
        array: PropTypes.object.isRequired,
        backgroundSubsectionId: PropTypes.number.isRequired,
        backgroundContent: PropTypes.string,
        change: PropTypes.func.isRequired,
        contactSubsectionId: PropTypes.number.isRequired,
        disabled: PropTypes.bool,
        form: PropTypes.string.isRequired,
        isIntake: PropTypes.bool,
        isReverseAuction: PropTypes.bool,
        location: PropTypes.object,
        projectSection: PropTypes.shape({
            id: PropTypes.number.isRequired,
        }).isRequired,
        showFormValidation: PropTypes.bool,
        summaryContent: PropTypes.string,
        summarySubsectionId: PropTypes.number.isRequired,
        tagOptions: PropTypes.array,
        templateVariableOptions: PropTypes.array,
        timelineSubsectionId: PropTypes.number.isRequired,
    };

    static contextType = OGThemeContext;

    get filteredVariableOptions() {
        const options = this.props.templateVariableOptions;
        if (options) {
            return options.filter((opt) => opt.label !== INTRODUCTION_VARIABLES);
        }
        return [];
    }

    get styles() {
        return require('./index.scss');
    }

    renderProjectSubsectionDescription = (projectSubsectionId) => {
        const {
            change,
            disabled,
            form,
            projectSection: { id: projectSectionId },
            showFormValidation,
            tagOptions,
            templateVariableOptions,
        } = this.props;
        const { isOGThemeEnabledForComponents } = this.context;

        return (
            <div
                className={classnames({
                    [`row ${this.styles.sectionDescription}`]: !isOGThemeEnabledForComponents,
                })}
            >
                <div
                    className={classnames({
                        'col-xs-offset-1 col-xs-10': !isOGThemeEnabledForComponents,
                    })}
                >
                    <TemplateSectionDescriptionForm
                        change={change}
                        disabled={disabled}
                        form={form}
                        projectSectionId={projectSectionId}
                        projectSubsectionId={projectSubsectionId}
                        showFormValidation={showFormValidation}
                        tagOptions={tagOptions}
                        templateVariableOptions={templateVariableOptions}
                    />
                </div>
            </div>
        );
    };

    render() {
        const {
            array,
            backgroundSubsectionId,
            change,
            contactSubsectionId,
            disabled,
            form,
            isIntake,
            location,
            showFormValidation,
            summarySubsectionId,
            tagOptions,
            timelineSubsectionId,
        } = this.props;
        const { isOGThemeEnabledForComponents } = this.context;

        if (isOGThemeEnabledForComponents) {
            const buildRoute = buildTabRoute(location);

            return (
                <LegacyIntroductionForm
                    {...this.props}
                    hideTimeline={isIntake}
                    links={{
                        summaryAndBackground: `${buildRoute('process-information')}?section=1`,
                        timeline: `${buildRoute('process-information')}?section=2`,
                    }}
                    renderBackgroundComments={() =>
                        this.renderProjectSubsectionDescription(backgroundSubsectionId)
                    }
                    renderContactComments={() =>
                        this.renderProjectSubsectionDescription(contactSubsectionId)
                    }
                    renderSummaryComments={() =>
                        this.renderProjectSubsectionDescription(summarySubsectionId)
                    }
                    renderTimelineComments={() =>
                        this.renderProjectSubsectionDescription(timelineSubsectionId)
                    }
                    showContactAddress
                />
            );
        }

        return (
            <div className="row">
                <div className="col-md-offset-2 col-md-8">
                    <div className="text-center">
                        <Label label="Summary Section" />
                    </div>
                    {this.renderProjectSubsectionDescription(summarySubsectionId)}
                    <Field
                        component={RichTextInput}
                        disabled={disabled}
                        minRows={2}
                        name={SUMMARY}
                        placeholder="Enter Text (optional)"
                        qaTag={`templateIntroduction-${SUMMARY}`}
                        showValidation={showFormValidation}
                        tagOptions={tagOptions}
                        templateVariableOptions={this.filteredVariableOptions}
                        toolbarPlacement="bottom"
                    />
                    <div className={this.styles.introSubsection}>
                        <Label label="Background Section" />
                    </div>
                    {this.renderProjectSubsectionDescription(backgroundSubsectionId)}
                    <Field
                        component={RichTextInput}
                        disabled={disabled}
                        minRows={2}
                        name={BACKGROUND}
                        placeholder="Enter Text (optional)"
                        qaTag={`templateIntroduction-${BACKGROUND}`}
                        showValidation={showFormValidation}
                        tagOptions={tagOptions}
                        templateVariableOptions={this.filteredVariableOptions}
                        toolbarPlacement="bottom"
                    />

                    <div className={this.styles.introSubsection}>
                        <Label label="Contact Section" />
                    </div>
                    {this.renderProjectSubsectionDescription(contactSubsectionId)}
                    <Field
                        component={Toggle}
                        disabled={disabled}
                        inverseToggleDisplay
                        label="Display Project Contact"
                        labelInline
                        labelInlineTogglePosition="left"
                        name={HIDE_CONTACT}
                        qaTag={`templateIntroduction-${HIDE_CONTACT}`}
                    />
                    <Field
                        component={Toggle}
                        disabled={disabled}
                        inverseToggleDisplay
                        label="Display Procurement Contact"
                        labelInline
                        labelInlineTogglePosition="left"
                        name={HIDE_PROCUREMENT_CONTACT}
                        qaTag={`templateIntroduction-${HIDE_PROCUREMENT_CONTACT}`}
                    />
                    <Field
                        component={Toggle}
                        disabled={disabled}
                        inverseToggleDisplay
                        label="Display Contact Address"
                        labelInline
                        labelInlineTogglePosition="left"
                        name={HIDE_CONTACT_ADDRESS}
                        qaTag={`templateIntroduction-${HIDE_CONTACT_ADDRESS}`}
                    />
                    <div className={this.styles.introSubsection}>
                        <Label label="Timeline Section" />
                    </div>
                    {this.renderProjectSubsectionDescription(timelineSubsectionId)}
                    <Field
                        component={Toggle}
                        disabled={disabled}
                        inverseToggleDisplay
                        label="Display Timeline"
                        labelInline
                        labelInlineTogglePosition="left"
                        name={HIDE_TIMELINE}
                        qaTag={`templateIntroduction-${HIDE_TIMELINE}`}
                    />
                    <Timeline
                        array={array}
                        change={change}
                        disabled={disabled}
                        form={form}
                        isTemplate
                        showValidation={showFormValidation}
                    />
                </div>
            </div>
        );
    }
}

export const TemplateIntroductionForm = connect(mapStateToProps)(
    withRouter(ConnectedTemplateIntroductionForm)
);
