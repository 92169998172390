import React from 'react';
import { Box, Typography } from '@og-pro/ui';

const AdjustableTextRenderer = (children) => (
    <Box display="flex" flexDirection="column" justifyContent="center" sx={{ height: '100%' }}>
        <Typography
            sx={{
                overflowWrap: 'break-word',
                wordWrap: 'break-word',
                display: '-webkit-box', // Use a flexbox-like display for truncating
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2, // Limit to 2 lines
                overflow: 'hidden', // Hide overflow text
                textOverflow: 'ellipsis', // Add ellipses for truncated text
            }}
        >
            {children}
        </Typography>
    </Box>
);

export default AdjustableTextRenderer;
