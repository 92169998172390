import React, { useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Box, IconButton, Typography } from '@og-pro/ui';
import { Edit as EditIcon } from '@mui/icons-material';
import { statuses } from '@og-pro/shared-config/electronicSignatures';
import { projectStatusesDict } from '@og-pro/shared-config/projects';

import { getContractDocBuilderRoute } from '../../../../helpers';
import { AgGridReactLegacy, LoadingSpinner } from '../../../../components';
import { loadContractAssembledDocuments } from '../../../../actions/contracts';
import { CollapsableBox } from './CollapsableBox';
import { StatusCellRenderer } from './StatusCellRenderer';
import { DocusignActions } from './DocusignActions';
import { CreateContractDocumentButton } from './CreateContractDocumentButton';
import { DownloadDocumentButton } from './DownloadDocumentButton';
import { LastAction } from './LastAction';
import { HistoryButton } from './HistoryButton';
import { ContractDocumentNotesModal } from './ContractDocumentNotesModal';
import { useContractSocket } from '../useContractSocket';
import AdjustableTextRenderer from './AdjustableTextRenderer';

export const AssembledAgreements = () => {
    const { contractId } = useParams();
    const dispatch = useDispatch();
    const gridApiRef = useRef(null);
    const [selectedContractDocument, setSelectedContractDocument] = useState(false);

    const handleGridReady = (params) => {
        gridApiRef.current = params.api;
    };
    const { isFetching, data, refetch } = useQuery({
        queryKey: ['AssembledAgreements', contractId],
        queryFn: () => dispatch(loadContractAssembledDocuments(contractId)),
        retry: false,
        refetchOnWindowFocus: false,
    });

    const unSelectAssembledAgreement = () => setSelectedContractDocument(null);

    const reloadData = async () => {
        await refetch();
        gridApiRef.current.refreshCells({ force: true });
    };

    useContractSocket({ refresh: reloadData });

    const styles = require('../index.scss');

    if (isFetching) {
        return (
            <Box py={4}>
                <LoadingSpinner noPadding size="small" useOpenGovStyle />
            </Box>
        );
    }

    const someHasSignaturesButton = data?.some(
        (c) =>
            c.status === projectStatusesDict.FINAL &&
            c.electronicSignature?.status !== statuses.COMPLETED &&
            c.esignatureEnabled
    );
    const someHasDownloadButton = data?.some(
        (c) =>
            c.electronicSignature?.status === statuses.COMPLETED ||
            (c.status === projectStatusesDict.FINAL &&
                !c.electronicSignature &&
                c.esignatureEnabled)
    );

    const columns = [
        {
            field: 'type',
            flex: 1,
            headerName: 'Document Type',
            cellRendererFramework: () => <>Contract Packet</>,
            minWidth: 160,
        },
        {
            field: 'title',
            flex: 1.5,
            headerName: 'Name',
            cellRendererFramework: (params) => {
                return (
                    <Link to={getContractDocBuilderRoute(params.data)}>
                        {AdjustableTextRenderer(params.value)}
                    </Link>
                );
            },
            minWidth: 300,
        },
        {
            field: 'created_at',
            flex: 1,
            headerName: 'Date Added',
            cellRendererFramework: (params) =>
                params.value !== null
                    ? new Date(params.value).toLocaleDateString('en-us', { timeZone: 'UTC' })
                    : null,
            minWidth: 130,
        },
        {
            field: 'status',
            flex: 1,
            cellRendererFramework: StatusCellRenderer,
            minWidth: 100,
        },
        {
            field: 'lastAction',
            flex: 1.5,
            headerName: 'Last Action',
            cellRendererFramework: (params) => {
                return <LastAction contractDocument={params.data} />;
            },
            minWidth: 205,
        },
        {
            cellClass: styles.notesCell,
            field: 'internalNotes',
            flex: 1,
            headerName: 'Notes',
            cellRendererFramework: (params) => (
                <Box
                    onClick={(e) => {
                        e.stopPropagation();
                        setSelectedContractDocument(params.data);
                    }}
                    sx={{
                        display: 'block',
                        cursor: 'pointer',
                        minWidth: '100px',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {AdjustableTextRenderer(params.value ? params.value : <>&nbsp;</>)}
                </Box>
            ),

            minWidth: 300,
        },
        {
            field: 'actions',
            headerName: '',
            cellClass: styles.iconCells,
            cellRendererFramework: (params) => (
                <Box alignItems="center" display="flex" justifyContent="flex-end" sx={{ pt: 0.5 }}>
                    <Box px={0.5}>
                        <DocusignActions contractDocument={params.data} reloadData={reloadData} />
                    </Box>
                    <DownloadDocumentButton contractDocument={params.data} />
                    <Box px={0.5}>
                        <HistoryButton contractDocument={params.data} />
                    </Box>
                    <Box px={0.5}>
                        <IconButton
                            onClick={() => setSelectedContractDocument(params.data)}
                            qaTag="contractDocuments-editAssembledDocument"
                            size="small"
                        >
                            <EditIcon fontSize="small" />
                        </IconButton>
                    </Box>
                </Box>
            ),
            // flex: 2,
            pinned: 'right',
            resizable: false,
            sortable: false,
            // eslint-disable-next-line
            width: someHasSignaturesButton ? 315 : someHasDownloadButton ? 215 : 142,
        },
    ];

    const defaultColDef = {
        editable: false,
        cellClass: styles.cell,
        headerClass: styles.header,
        suppressMenu: true,
    };
    return (
        <Box>
            <CollapsableBox
                actions={
                    <Box flex={1} textAlign="right">
                        <CreateContractDocumentButton />
                    </Box>
                }
                subtitle={
                    <Box sx={{ ml: -3 }}>Manage contract packets and route for signature.</Box>
                }
                title="Assembled Contract Packets"
            >
                {!data?.length && (
                    <Typography fontStyle="italic">No assembled contract packets</Typography>
                )}
                {!!data?.length && (
                    <Box
                        sx={{
                            '& .ag-pinned-right-header': { borderLeft: 0 },
                            '& .ag-pinned-right-cols-container': {
                                boxShadow: '-4px 0px 0px 0px rgba(19, 21, 23, 0.05)',
                            },
                        }}
                    >
                        <AgGridReactLegacy
                            columns={columns}
                            defaultColDef={defaultColDef}
                            domLayout="autoHeight"
                            getRowNodeId={(d) => d.id}
                            hideSideBar
                            onGridReady={handleGridReady}
                            rowHeight={52}
                            rows={data}
                            useOpenGovStyle
                        />
                    </Box>
                )}
            </CollapsableBox>
            <ContractDocumentNotesModal
                afterSubmit={reloadData}
                contractDocument={selectedContractDocument}
                handleClose={unSelectAssembledAgreement}
            />
        </Box>
    );
};
