import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation, useSearchParams, createSearchParams } from 'react-router-dom';

import { checklistStatusTypes } from '@og-pro/shared-config/checklists';

import { ChecklistDisplay } from './ChecklistDisplay';
import { ChecklistViewSelectForm } from './ChecklistViewSelectForm';
import { RESPONSES_VIEW, VIEW } from './ChecklistViewSelectForm/constants';
import { getFormattedQuestionnaires, getSelectedView } from './selectors';
import { ChecklistProgressBar } from '../ChecklistProgressBar';

const { RELEASED } = checklistStatusTypes;

export const ContractChecklist = ({
    checklist,
    checklist: { questionnaires: rawQuestionnaires, status },
    isGovernmentView,
}) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [searchParams] = useSearchParams();
    const query = Object.fromEntries(searchParams);
    const readOnly = status !== RELEASED;

    const questionnaires = useSelector(() => getFormattedQuestionnaires(rawQuestionnaires));
    const selectedView = useSelector(() => getSelectedView({ query, readOnly }));

    const onViewSelect = ({ view }) => {
        if (view !== selectedView) {
            navigate({
                pathname,
                search: createSearchParams({ ...query, [VIEW]: view }).toString(),
            });
        }
    };

    const styles = require('./index.scss');

    return (
        <>
            <ChecklistProgressBar
                labelClassName={styles.progressBarLabel}
                questionnaires={questionnaires}
            />
            <ChecklistViewSelectForm
                initialValues={{ [VIEW]: selectedView }}
                onChange={onViewSelect}
            />
            <ChecklistDisplay
                checklist={checklist}
                isGovernmentView={isGovernmentView}
                isResponseView={selectedView === RESPONSES_VIEW}
                questionnaires={questionnaires}
                readOnly={readOnly}
            />
        </>
    );
};

ContractChecklist.propTypes = {
    checklist: PropTypes.shape({
        questionnaires: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                questionnaireResponses: PropTypes.arrayOf(PropTypes.object).isRequired,
            })
        ).isRequired,
        status: PropTypes.string.isRequired,
    }).isRequired,
    isGovernmentView: PropTypes.bool,
};
