import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Field } from 'redux-form';

import { Button } from '../../../Button';

/*
 * Component is used for determining whether a proposal can be saved. If certain fields are not
 * valid we do not want to send to server as it will cause database errors.
 */
export class ProposalCreateSaveButton extends PureComponent {
    static propTypes = {
        bsSize: PropTypes.string,
        bsStyle: PropTypes.string,
        disabled: PropTypes.bool,
        icon: PropTypes.string,
        onClick: PropTypes.func.isRequired,
        text: PropTypes.string.isRequired,
        tooltipPlacement: PropTypes.string.isRequired,
    };

    static defaultProps = {
        disabled: false,
    };

    renderButton = ({ bsSize, bsStyle, icon, disabled, meta, onClick, text, tooltipPlacement }) => {
        const { invalid } = meta;

        return (
            <Button
                bsSize={bsSize}
                bsStyle={bsStyle}
                disabled={invalid || disabled}
                onClick={onClick}
                tooltip={invalid ? 'Please fix form errors before saving' : undefined}
                tooltipPlacement={tooltipPlacement}
            >
                {icon && (
                    <>
                        <i className={`fa fa-${icon}`} />
                        &nbsp;
                    </>
                )}
                {text}
            </Button>
        );
    };

    render() {
        const { bsSize, bsStyle, disabled, icon, onClick, text, tooltipPlacement } = this.props;

        return (
            <Field
                bsSize={bsSize}
                bsStyle={bsStyle}
                component={this.renderButton}
                disabled={disabled}
                icon={icon}
                name="invalidSave"
                onClick={onClick}
                text={text}
                tooltipPlacement={tooltipPlacement}
            />
        );
    }
}
