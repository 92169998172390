import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { FileUploadAttachment } from '../FileUploadAttachment';
import { HtmlContent } from '../HtmlContent/HtmlContent';

export class StandardDocument extends PureComponent {
    static propTypes = {
        standardDocument: PropTypes.shape({
            attachment: PropTypes.object,
            text: PropTypes.string,
            title: PropTypes.string.isRequired,
        }).isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    render() {
        const {
            standardDocument: { attachment, text, title },
        } = this.props;

        return (
            <>
                <h4 className={this.styles.title}>{title}</h4>
                {text && (
                    <HtmlContent
                        className={classnames(this.styles.item, this.styles.text, 'show-scrollbar')}
                        content={text}
                    />
                )}
                {attachment && (
                    <div className={this.styles.item}>
                        <FileUploadAttachment attachment={attachment} />
                    </div>
                )}
            </>
        );
    }
}
