import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { EvaluationPhaseDisplayDocx } from './EvaluationPhaseDisplayDocx';
import { EvaluationCriteriaListDisplay } from '../../EvaluationCriteriaListDisplay';

export class EvaluationPhaseDisplay extends PureComponent {
    static propTypes = {
        isDocx: PropTypes.bool,
        isMultiPhase: PropTypes.bool.isRequired,
        scoringCriteria: PropTypes.array.isRequired,
        title: PropTypes.string,
        totalWeight: PropTypes.number.isRequired,
    };

    render() {
        const { isDocx, isMultiPhase, scoringCriteria, title, totalWeight } = this.props;

        // Render differently if docx templating
        if (isDocx) {
            return <EvaluationPhaseDisplayDocx {...this.props} />;
        }

        return (
            <div className="evaluation-phase">
                {isMultiPhase && <h5 className="evaluation-phase-title">{title}</h5>}
                <EvaluationCriteriaListDisplay
                    criteria={scoringCriteria}
                    totalWeight={totalWeight}
                />
            </div>
        );
    }
}
