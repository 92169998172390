import { Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import React, { useEffect } from 'react';

import { capitalDesignTokens } from '@opengov/capital-mui-theme';

import { Button, Typography } from '@og-pro/ui';

import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { change, reduxForm } from 'redux-form';

import {
    ADDITIONAL_INFORMATION,
    ATTACHMENTS,
    CUSTOM_FORM,
    GENERAL_INFORMATION,
    PURCHASE_DETAILS,
    sectionsMap,
    VENDOR_SELECTION,
} from '../../../../../constants/requisitionsCreate';
import { DEFAULT_SECTION, fieldNames, form, sectionNames, sections } from './constants';

import {
    AdditionalInformation,
    GeneralInformation,
    PurchaseDetails,
    VendorSelection,
} from '../../../RequisitionsCreate/RequisitionsCreateForms/FormComponents';
import { RequisitionAttachment } from '../../../RequisitionsCreate/RequisitionsCreateForms/FormComponents/Attachments/RequisitionAttachment';
import { getRequisitionJS } from '../../../../../selectors/govApp';
import { formConfig } from '../../../RequisitionsCreate/RequisitionsCreateForms/form';
import { CustomFieldsEditor } from './CustomFieldsEditor';
import { useSectionNumbering } from './useSectionNumbering';
import { useRequestTypeActions } from '../RequestTypeActionsContext';

const { CREATE_NEW_CUSTOM_FORM, DELETE_CUSTOM_FORM } = fieldNames;

const sectionComponents = {
    [GENERAL_INFORMATION]: <GeneralInformation disabled />,
    [PURCHASE_DETAILS]: <PurchaseDetails disabled isDraft />,
    [VENDOR_SELECTION]: <VendorSelection disabled />,
    [CUSTOM_FORM]: <CustomFieldsEditor />,
    [ADDITIONAL_INFORMATION]: <AdditionalInformation disabled />,
    [ATTACHMENTS]: <RequisitionAttachment disabled enableScanning renderAsDropPanel />,
};

const AddSectionWithCustomFieldsButton = ({ onClick }) => {
    return (
        <Box
            alignItems="center"
            display="flex"
            gap={3}
            justifyContent="center"
            sx={{
                width: '100%',
            }}
        >
            <Box
                sx={{
                    flex: 1,
                    borderBottom: `1px solid ${capitalDesignTokens.foundations.colors.gray600}`,
                }}
            />
            <Button
                color="primary"
                onClick={onClick}
                startIcon={<AddIcon />}
                sx={{
                    paddingX: 2,
                    paddingY: 0,
                    textTransform: 'none',
                }}
                variant="text"
            >
                Add a Section with Custom Fields
            </Button>
            <Box
                sx={{
                    flex: 1,
                    borderBottom: `1px solid ${capitalDesignTokens.foundations.colors.gray600}`,
                }}
            />
        </Box>
    );
};

AddSectionWithCustomFieldsButton.propTypes = {
    onClick: PropTypes.func,
};

// RequestTypeFormDisplay has to be wrapped in reduxForm to get the form values for the section components
// @reduxForm
export const ConnectedRequestTypeFormDisplay = ({ showCustomForm }) => {
    const { selectedSection, setSelectedSection } = useRequestTypeActions();
    const requisition = useSelector(getRequisitionJS);
    const dispatch = useDispatch();
    const { getSectionNumber } = useSectionNumbering(showCustomForm);

    function handleAddSectionWithCustomFieldsClick() {
        dispatch(change(form, CREATE_NEW_CUSTOM_FORM, true));
        dispatch(change(form, DELETE_CUSTOM_FORM, false));
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                // setTimeout is needed to prevent a race condition between this key event and the one in CustomFormEditorContext.jsx that de-selects the focused custom field
                setTimeout(() => {
                    setSelectedSection(DEFAULT_SECTION);
                }, 0);
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {sections
                .filter((section) => section !== sectionNames.FORM_SETTINGS) // Filter out the form settings section from the list since we render it separately
                .filter(
                    (section) =>
                        !requisition.requestType[sectionsMap[section]?.sectionHideKey] ||
                        section === CUSTOM_FORM
                )
                .map((sectionKey) => {
                    const section = sectionsMap[sectionKey];
                    const isSelected = sectionKey === selectedSection;
                    const instructionsText =
                        requisition.requestType[section.sectionInstructionsKey];
                    const showInstructions = isSelected || instructionsText;

                    const component = sectionComponents[sectionKey];
                    if (!component) {
                        return null;
                    }

                    const isCustomForm = sectionKey === CUSTOM_FORM;

                    if (isCustomForm && !showCustomForm) {
                        return (
                            <AddSectionWithCustomFieldsButton
                                key={CUSTOM_FORM}
                                onClick={handleAddSectionWithCustomFieldsClick}
                            />
                        );
                    }

                    function handleSelection() {
                        setSelectedSection(isSelected ? sectionNames.FORM_SETTINGS : sectionKey);
                    }

                    return (
                        <Box
                            id={sectionKey}
                            key={sectionKey}
                            onClick={() => handleSelection()}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                    handleSelection();
                                }
                            }}
                            role="button"
                            sx={{
                                background: capitalDesignTokens.foundations.colors.white,
                                borderRadius: 2,
                                border: `1px solid ${capitalDesignTokens.semanticColors.border.primary}`,
                                borderLeft: isSelected
                                    ? `8px solid ${capitalDesignTokens.foundations.colors.brand100}`
                                    : undefined,
                                display: 'flex',
                                flexDirection: 'column',
                                cursor: 'pointer',
                                ...(sectionKey !== CUSTOM_FORM && {
                                    // Allow the custom fields section to get clicks
                                    '& *:not([tabIndex])': { pointerEvents: 'none' },
                                }),
                            }}
                            tabIndex={0} // Makes the section focusable
                        >
                            <Box
                                sx={{
                                    pb: 3,
                                    borderBottom: `1px solid ${capitalDesignTokens.semanticColors.border.primary}`,
                                    gap: 2,
                                    display: 'flex',
                                    px: 4,
                                    pt: 4,
                                    borderTopLeftRadius:
                                        capitalDesignTokens.foundations.units.unit1,
                                    borderTopRightRadius:
                                        capitalDesignTokens.foundations.units.unit1,
                                    background: isSelected
                                        ? '#F7F9FE'
                                        : capitalDesignTokens.foundations.colors.white,
                                }}
                            >
                                <Box sx={{ marginTop: '6px' }}>
                                    <Box
                                        sx={{
                                            borderRadius: '100%',
                                            background: isSelected
                                                ? capitalDesignTokens.semanticColors.foreground
                                                      .action
                                                : capitalDesignTokens.semanticColors.foreground
                                                      .secondary,
                                            height: '20px',
                                            width: '20px',
                                            color: capitalDesignTokens.foundations.colors.white,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography variant="h5">
                                            {getSectionNumber(sectionKey)}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                    <Typography variant="h2">
                                        {requisition.requestType[section.sectionNameKey] ||
                                            'New Section'}
                                    </Typography>
                                    {showInstructions && (
                                        <Typography
                                            color={
                                                capitalDesignTokens.semanticColors.foreground
                                                    .secondary
                                            }
                                            variant="bodyDefault"
                                        >
                                            {instructionsText ||
                                                'Add instructional text for this section.'}
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    // Custom Form section has highlightable boxes that need the full margin, so allow it to manage its own spacing.
                                    ...(sectionKey !== CUSTOM_FORM && { pt: 4, px: 4 }),
                                }}
                            >
                                {component}
                            </Box>
                        </Box>
                    );
                })}
        </Box>
    );
};

ConnectedRequestTypeFormDisplay.propTypes = {
    showCustomForm: PropTypes.bool,
};

export const RequestTypeFormDisplay = reduxForm({
    form: formConfig.form,
    enableReinitialize: true,
})(ConnectedRequestTypeFormDisplay);
