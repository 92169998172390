import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';

import { fieldsDict, form } from './constants';
import { validate } from './validate';
import { getManagedFilterJS } from '../selectors';
import { Button } from '../../../Button';
import { InputText } from '../../../InputText';
import { LoadingError } from '../../../LoadingError/LoadingError';
import { OutlineButton } from '../../../OutlineButton';
import {
    deleteFilter,
    hideManageSavedFilterModal,
    makeFilterDefault,
    updateFilter,
} from '../../../../actions/auth';
import { showConfirmationSimpleModal } from '../../../../actions/confirmation';

const { NAME } = fieldsDict;

const mapStateToProps = (state) => {
    const filter = getManagedFilterJS(state);

    return {
        error: state.auth.get('manageFilterError'),
        filter,
        initialValues: filter,
        managingFilter: state.auth.get('managingFilter'),
        showModal: state.auth.get('showManageSavedFilterModal'),
    };
};

const mapDispatchToProps = {
    deleteFilter,
    hideModal: hideManageSavedFilterModal,
    makeFilterDefault,
    showConfirmationModal: showConfirmationSimpleModal,
    updateFilter,
};

const formConfig = {
    form,
    validate,
};

// @connect
// @reduxForm
class ConnectedManageSavedFilterModal extends PureComponent {
    static propTypes = {
        deleteFilter: PropTypes.func.isRequired,
        error: PropTypes.string,
        filter: PropTypes.shape({
            id: PropTypes.number.isRequired,
            isTypeDefault: PropTypes.bool,
            name: PropTypes.string.isRequired,
        }).isRequired,
        handleSubmit: PropTypes.func.isRequired,
        hideModal: PropTypes.func.isRequired,
        makeFilterDefault: PropTypes.func.isRequired,
        managingFilter: PropTypes.bool,
        onDelete: PropTypes.func.isRequired,
        showConfirmationModal: PropTypes.func.isRequired,
        showModal: PropTypes.bool,
        updateFilter: PropTypes.func.isRequired,
    };

    static defaultProps = {
        error: undefined,
        managingFilter: false,
        showModal: false,
    };

    handleDefaultChange = () => {
        const {
            filter,
            filter: { id, isTypeDefault },
        } = this.props;

        if (isTypeDefault) {
            this.props.updateFilter(id, {
                ...filter,
                isTypeDefault: false,
            });
        } else {
            this.props.makeFilterDefault(id);
        }
    };

    handleDeleteSavedFilter = () => {
        const {
            filter: { id, name },
            onDelete,
            showConfirmationModal,
        } = this.props;

        showConfirmationModal(() => this.props.deleteFilter(id).then(onDelete), {
            text: `This will delete the filter "${name}". Are you sure?`,
            icon: 'trash-o',
            btnText: 'Delete',
        });
    };

    handleRenameFilterSubmit = (data) => {
        const {
            filter,
            filter: { id },
        } = this.props;

        this.props.updateFilter(id, {
            ...filter,
            name: data[NAME],
        });
    };

    render() {
        const {
            error,
            filter: { isTypeDefault, name },
            handleSubmit,
            hideModal,
            managingFilter,
            showModal,
        } = this.props;

        return (
            <Modal onHide={hideModal} show={showModal}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">Managing Filter: {name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {error && <LoadingError error={error} />}
                    <form onSubmit={handleSubmit(this.handleRenameFilterSubmit)}>
                        <Field
                            component={InputText}
                            disabled={managingFilter}
                            hasFeedback={false}
                            label="Filter Name"
                            name={NAME}
                            placeholder="Enter filter name"
                        />
                        <OutlineButton
                            bsStyle="danger"
                            disabled={managingFilter}
                            onClick={this.handleDeleteSavedFilter}
                        >
                            <i className="fa fa-trash" /> Delete
                        </OutlineButton>
                        <div className="pull-right">
                            <Button disabled={managingFilter} onClick={this.handleDefaultChange}>
                                <i
                                    className={`text-primary fa fa-${
                                        isTypeDefault ? 'star' : 'star-o'
                                    }`}
                                />{' '}
                                {isTypeDefault ? 'Unpin' : 'Pin'}
                            </Button>
                            &nbsp;
                            <Button bsStyle="primary" disabled={managingFilter} type="submit">
                                Save Filter
                            </Button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        );
    }
}

export const ManageSavedFilterModal = compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig)
)(ConnectedManageSavedFilterModal);
