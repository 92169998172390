import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { HelpBlock } from '../../../HelpBlock/HelpBlock';

export class Required extends PureComponent {
    static propTypes = {
        isRequired: PropTypes.bool,
    };

    render() {
        const { isRequired } = this.props;

        if (!isRequired) {
            return null;
        }

        return <HelpBlock className="required-container">*Response required</HelpBlock>;
    }
}
