import { startCase } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import { TemplateStatusLabel } from '../TemplateStatusLabel';
import { UserProfilePicture } from '../../UserProfilePicture/UserProfilePicture';

export const TemplateListItem = ({
    hasMultipleTypes,
    templateItem: {
        created_at: createdAt,
        template: { archived, icon, isPublished, isSpecial, title, updated_at: templateUpdatedAt },
        type,
        user,
        updated_at: objectUpdatedAt,
    },
}) => {
    const styles = require('./index.scss');

    const lastUpdate = moment(templateUpdatedAt).isAfter(objectUpdatedAt)
        ? templateUpdatedAt
        : objectUpdatedAt;

    return (
        <div className="row">
            <div className="col-md-5">
                <span className={styles.templateTitle}>
                    {icon && <i className={`fa fa-fw fa-${icon}`} />}
                    {title || 'Untitled'}
                </span>
                <TemplateStatusLabel
                    className={styles.statusLabel}
                    isArchived={archived}
                    isArchivedLabelStyle="default"
                    isPublished={isPublished}
                    isSpecial={isSpecial}
                />
                {hasMultipleTypes && <div className={styles.typeText}>{startCase(type)}</div>}
            </div>
            <div className="col-md-3 hidden-xs hidden-sm">
                {user && (
                    <>
                        <UserProfilePicture horizontal user={user} />
                        &nbsp;&nbsp;
                        {user.displayName}
                    </>
                )}
            </div>
            <div className="col-md-2 hidden-xs hidden-sm" title={moment(createdAt).format('lll')}>
                {moment(createdAt).format('ll')}
            </div>
            <div className="col-md-2 hidden-xs hidden-sm" title={moment(lastUpdate).format('lll')}>
                {moment(lastUpdate).fromNow()}
            </div>
        </div>
    );
};

TemplateListItem.propTypes = {
    hasMultipleTypes: PropTypes.bool,
    templateItem: PropTypes.shape({
        created_at: PropTypes.string.isRequired,
        template: PropTypes.shape({
            archived: PropTypes.bool,
            icon: PropTypes.string,
            isPublished: PropTypes.bool,
            isSpecial: PropTypes.bool,
            title: PropTypes.string,
            updated_at: PropTypes.string.isRequired,
        }).isRequired,
        type: PropTypes.string,
        updated_at: PropTypes.string.isRequired,
        user: PropTypes.shape({
            displayName: PropTypes.string.isRequired,
        }),
    }).isRequired,
};
