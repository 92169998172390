import { get } from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';

import {
    comingSoonStatuses,
    evaluationProjectStatuses,
    projectStatusesDict,
} from '@og-pro/shared-config/projects/index';

import {
    form,
    COMING_SOON,
    IS_CONSENSUS_PUBLIC,
    IS_EVALUATION_PUBLIC,
    IS_PAUSED,
    IS_PRIVATE,
    REQUIRES_INVITATION,
    IS_PUBLIC_BID_PRICING_RESULT,
    IS_PUBLIC_BID_RESULT,
    IS_PUBLIC_BID_TOTAL_ONLY,
    QA_ENABLED,
    SHOW_PLANHOLDERS,
    vendorPricingDisplayOptions,
} from './constants';
import { showConfirmationSimpleModal } from '../../../../actions/confirmation';
import { updateEvaluation } from '../../../../actions/evaluations';
import { govUpdateProject, hidePublicDisplayOptionsModal } from '../../../../actions/govProjects';
import { Button, HelpIcon, MultipleChoiceInput, Toggle, Tooltip } from '../../../../components';
import {
    helpPrivateBid,
    helpRequiredInvitation,
} from '../../ProjectPostCreate/ProjectTimeline/constants';

function canToggleComingSoon(project) {
    return comingSoonStatuses.includes(project.status);
}

const mapStateToProps = (state, props) => {
    return {
        isPrivate: formValueSelector(form)(state, IS_PRIVATE),
        initialValues: {
            [COMING_SOON]: get(props, ['project', COMING_SOON]),
            [IS_CONSENSUS_PUBLIC]: get(props, ['project', 'evaluation', IS_CONSENSUS_PUBLIC]),
            [IS_EVALUATION_PUBLIC]: get(props, ['project', 'evaluation', IS_EVALUATION_PUBLIC]),
            [IS_PAUSED]: get(props, ['project', IS_PAUSED]),
            [IS_PRIVATE]: get(props, ['project', IS_PRIVATE]),
            [REQUIRES_INVITATION]: get(props, ['project', REQUIRES_INVITATION]),
            [IS_PUBLIC_BID_PRICING_RESULT]: get(props, ['project', IS_PUBLIC_BID_PRICING_RESULT]),
            [IS_PUBLIC_BID_RESULT]: get(props, ['project', IS_PUBLIC_BID_RESULT]),
            [IS_PUBLIC_BID_TOTAL_ONLY]: get(props, ['project', IS_PUBLIC_BID_TOTAL_ONLY]),
            [QA_ENABLED]: get(props, ['project', QA_ENABLED]),
            [SHOW_PLANHOLDERS]: get(props, ['project', SHOW_PLANHOLDERS]),
        },
        updating: state.govProjects.get('updateLoading') || state.evaluations.get('updating'),
    };
};

const mapDispatchToProps = {
    govUpdateProject,
    hideModal: hidePublicDisplayOptionsModal,
    showConfirmationSimpleModal,
    updateEvaluation,
};

const formConfig = {
    enableReinitialize: true,
    form,
};

// @connect
// @reduxForm
class ConnectedProjectPublicDisplayOptionsModal extends Component {
    static propTypes = {
        change: PropTypes.func.isRequired,
        govUpdateProject: PropTypes.func.isRequired,
        hideModal: PropTypes.func.isRequired,
        initialValues: PropTypes.shape({
            [COMING_SOON]: PropTypes.bool,
            [IS_CONSENSUS_PUBLIC]: PropTypes.bool,
            [IS_EVALUATION_PUBLIC]: PropTypes.bool,
            [IS_PAUSED]: PropTypes.bool,
            [IS_PRIVATE]: PropTypes.bool,
            [REQUIRES_INVITATION]: PropTypes.bool,
            [IS_PUBLIC_BID_PRICING_RESULT]: PropTypes.bool,
            [IS_PUBLIC_BID_RESULT]: PropTypes.bool,
            [IS_PUBLIC_BID_TOTAL_ONLY]: PropTypes.bool,
            [QA_ENABLED]: PropTypes.bool,
            [SHOW_PLANHOLDERS]: PropTypes.bool,
        }).isRequired,
        isPrivate: PropTypes.bool,
        project: PropTypes.shape({
            comingSoon: PropTypes.bool,
            evaluation: PropTypes.shape({
                hasConsensusEvaluation: PropTypes.bool.isRequired,
                isConsensusPublic: PropTypes.bool.isRequired,
                isPublic: PropTypes.bool.isRequired,
                type: PropTypes.string.isRequired,
            }),
            id: PropTypes.number.isRequired,
            isPaused: PropTypes.bool.isRequired,
            isPrivate: PropTypes.bool.isRequired,
            requiresInvitation: PropTypes.bool,
            isPublicBidPricingResult: PropTypes.bool.isRequired,
            isPublicBidResult: PropTypes.bool.isRequired,
            qaEnabled: PropTypes.bool.isRequired,
            priceTables: PropTypes.array.isRequired,
            questionnaires: PropTypes.array.isRequired,
            showBids: PropTypes.bool.isRequired,
            showPlanholders: PropTypes.bool.isRequired,
            status: PropTypes.string.isRequired,
            wasPosted: PropTypes.bool.isRequired,
        }).isRequired,
        showConfirmationSimpleModal: PropTypes.func.isRequired,
        updateEvaluation: PropTypes.func.isRequired,
        updating: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            updateVendorResponses: false,
            ...this.props.initialValues,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    get settingsMap() {
        return {
            [COMING_SOON]: {
                label: "Show as 'Coming Soon'",
                getOpts: (projectId, value) => ({
                    endpoint: `/project/${projectId}/post/toggle-coming-soon`,
                    snackbarMessage: `Project title will ${
                        value ? 'show' : 'not show'
                    } in your public portal`,
                }),
            },
            [IS_CONSENSUS_PUBLIC]: {
                label: 'Consensus Scorecard',
                getOpts: (_projectId, value) => ({
                    notify: true,
                    notifyDisplay: `Consensus Scorecard ${value ? 'Published' : 'Hidden'}`,
                    useEvaluation: true,
                }),
            },
            [IS_EVALUATION_PUBLIC]: {
                label: 'Selected Vendor',
                getOpts: (_projectId, value) => ({
                    notify: true,
                    notifyDisplay: `Selected Vendor(s) ${value ? 'Published' : 'Hidden'}`,
                    useEvaluation: true,
                }),
            },
            [IS_PAUSED]: {
                label: 'Put Project on Hold',
                getOpts: (_projectId, value) => ({
                    snackbarMessage: `Project ${value ? 'Put on Hold' : 'Taken off Hold'}`,
                }),
            },
            [IS_PRIVATE]: {
                label: 'Make Private Bid',
                getOpts: (projectId, value) => ({
                    endpoint: `/project/${projectId}/post/toggle-private`,
                    snackbarMessage: `Project set to ${value ? 'private' : 'public'}`,
                }),
            },
            [IS_PUBLIC_BID_PRICING_RESULT]: {
                label: 'Vendor Pricing',
                getOpts: () => ({
                    isVendorResponseUpdate: true,
                }),
            },
            [IS_PUBLIC_BID_RESULT]: {
                label: 'Vendor Responses to Questionnaire',
                getOpts: () => ({
                    isVendorResponseUpdate: true,
                }),
            },
            [IS_PUBLIC_BID_TOTAL_ONLY]: {
                label: 'Vendor Pricing Display Type',
                getOpts: () => ({
                    isVendorResponseUpdate: true,
                }),
            },
            [QA_ENABLED]: {
                label: 'Enable Q&A',
                getOpts: (projectId, value) => ({
                    endpoint: `/project/${projectId}/post/toggle-qa-enabled`,
                    snackbarMessage: `Q&A will ${
                        value ? 'show' : 'not show'
                    } in your public portal`,
                }),
            },
            [REQUIRES_INVITATION]: {
                label: 'Require Invitation to Access Bid',
                getOpts: (projectId, value) => ({
                    snackbarMessage: `Project set to ${
                        value ? '' : 'not '
                    }require invitation to access`,
                }),
            },
            [SHOW_PLANHOLDERS]: {
                label: 'Show Followers List',
                getOpts: (projectId, value) => ({
                    endpoint: `/project/${projectId}/post/planholders`,
                    snackbarMessage: `Followers list will ${
                        value ? 'show' : 'not show'
                    } in your public portal`,
                }),
            },
        };
    }

    get updatedSettingsKeys() {
        const { initialValues } = this.props;
        const settingsKeys = [];

        Object.keys(initialValues).forEach((key) => {
            if (initialValues[key] !== this.state[key]) {
                settingsKeys.push(key);
            }
        });

        return settingsKeys;
    }

    hideModal = () => {
        this.props.hideModal();
    };

    updateHelper = (data, getOpts) => {
        const { project } = this.props;

        const opts = getOpts(project.id, Object.values(data)[0]);

        if (opts.isVendorResponseUpdate) {
            return this.setState({ updateVendorResponses: true });
        }

        if (opts.useEvaluation) {
            const updateData = {
                evaluation: { ...data },
                updateOptions: {
                    forceEdit: true,
                },
            };

            return this.props.updateEvaluation(project.id, updateData, { ...opts });
        }

        return this.props.govUpdateProject(project, data, { ...opts });
    };

    // We batch this update to avoid sending duplicate 'response published' emails
    updateVendorResponseDisplayHelper = () => {
        const { project } = this.props;

        const { isPublicBidPricingResult, isPublicBidResult, isPublicBidTotalOnly } = this.state;

        this.props.govUpdateProject(
            project,
            {
                isPublicBidPricingResult,
                isPublicBidResult,
                isPublicBidTotalOnly,
            },
            {
                endpoint: `/project/${project.id}/post/bid-results`,
                snackbarMessage: project.evaluation
                    ? `Bid Results ${
                          isPublicBidPricingResult || isPublicBidResult ? 'Published' : 'Hidden'
                      }`
                    : 'Bid Results Display Settings Updated',
            }
        );
    };

    saveUpdates = () => {
        const changedSettingsString = this.updatedSettingsKeys.reduce((string, settingKey) => {
            return string + `${this.settingsMap[settingKey].label}\n`; // eslint-disable-line prefer-template
        }, '');

        const text =
            'The following settings have changed:\n' +
            `${changedSettingsString}` +
            'Please confirm you want to save these changes.';

        const updateFunction = () => {
            Promise.all(
                this.updatedSettingsKeys.map((settingKey) =>
                    this.updateHelper(
                        { [settingKey]: this.state[settingKey] },
                        this.settingsMap[settingKey].getOpts
                    )
                )
            ).then(() => {
                if (this.state.updateVendorResponses) {
                    this.updateVendorResponseDisplayHelper();
                }
                this.hideModal();
            });
        };

        return this.props.showConfirmationSimpleModal(updateFunction, {
            bsStyle: 'primary',
            btnText: 'Save',
            icon: 'check',
            text,
        });
    };

    updateShowTotalsOnlyResponse = (value) => {
        return this.setState({ [IS_PUBLIC_BID_TOTAL_ONLY]: value });
    };

    updateToggleValue = (e, value) => {
        return this.setState({ [e.target.name]: value });
    };

    renderForm() {
        const {
            change,
            isPrivate,
            updating,
            project,
            project: {
                evaluation,
                isPublicBidPricingResult,
                isPublicBidResult,
                priceTables,
                questionnaires,
                showBids,
                status,
                wasPosted,
            },
        } = this.props;

        const hasDisplayableBidResults =
            priceTables.length > 0 ||
            questionnaires.some((questionnaire) => questionnaire.isPublic);

        const shouldDisplayVendorResponses =
            (evaluationProjectStatuses.includes(status) || status === projectStatusesDict.CLOSED) &&
            wasPosted &&
            showBids &&
            !isPublicBidResult &&
            !isPublicBidPricingResult;

        return (
            <div className="row">
                <div className="col-xs-12 col-sm-offset-2 col-sm-8">
                    <div className={this.styles.toggleSectionTitle}>
                        <b>Posting Display Options</b>
                    </div>
                    {/* Make Private Bid */}
                    <Field
                        component={Toggle}
                        disabled={updating}
                        label={
                            <>
                                {this.settingsMap[IS_PRIVATE].label}
                                <HelpIcon tooltip={helpPrivateBid} />
                            </>
                        }
                        labelInline
                        labelInlineTogglePosition="left"
                        name={IS_PRIVATE}
                        onChange={(e, value) => {
                            if (!value) {
                                change(REQUIRES_INVITATION, false);

                                this.updateToggleValue(
                                    { target: { name: REQUIRES_INVITATION } },
                                    false
                                );
                            }

                            setTimeout(() => {
                                this.updateToggleValue(e, value);
                            }, 0);
                        }}
                    />
                    {isPrivate && (
                        <Field
                            component={Toggle}
                            disabled={updating}
                            label={this.settingsMap[REQUIRES_INVITATION].label}
                            labelInline
                            name={REQUIRES_INVITATION}
                            onChange={this.updateToggleValue}
                            tooltip={helpRequiredInvitation}
                        />
                    )}

                    {/* Display Question and answer */}
                    <Field
                        component={Toggle}
                        disabled={updating}
                        label={
                            <>
                                {this.settingsMap[QA_ENABLED].label}
                                <HelpIcon tooltip="Enables the question and answer sections for post and bids" />
                            </>
                        }
                        labelInline
                        labelInlineTogglePosition="left"
                        name={QA_ENABLED}
                        onChange={this.updateToggleValue}
                    />
                    {/* Show Followers List */}
                    <Field
                        component={Toggle}
                        disabled={updating}
                        label={
                            <>
                                {this.settingsMap[SHOW_PLANHOLDERS].label}
                                <HelpIcon tooltip="Displays a list of project followers in the public portal" />
                            </>
                        }
                        labelInline
                        labelInlineTogglePosition="left"
                        name={SHOW_PLANHOLDERS}
                        onChange={this.updateToggleValue}
                    />
                    <div className={this.styles.toggleSectionTitle}>
                        <b>Results Display Options</b>
                    </div>
                    {/* Vendor Pricing */}
                    <Field
                        component={Toggle}
                        disabled={!hasDisplayableBidResults || updating}
                        label={
                            <>
                                {this.settingsMap[IS_PUBLIC_BID_PRICING_RESULT].label}
                                <HelpIcon
                                    tooltip={
                                        !hasDisplayableBidResults
                                            ? 'Project has no vendor pricing data to display'
                                            : "Displays vendors' pricing in the 'Results' tab in the public portal. " +
                                              'Note: When enabled, we’ll send an email to notify project followers that bid results are now available.'
                                    }
                                />
                                {hasDisplayableBidResults && shouldDisplayVendorResponses && (
                                    <Tooltip tooltip="Since the bid is unsealed now, vendor pricing should also be published.">
                                        <i
                                            className={classnames(
                                                'fa fa-exclamation-circle',
                                                this.styles.warningCircle
                                            )}
                                        />
                                    </Tooltip>
                                )}
                            </>
                        }
                        labelInline
                        labelInlineTogglePosition="left"
                        name={IS_PUBLIC_BID_PRICING_RESULT}
                        onChange={this.updateToggleValue}
                    />
                    {/* Vendor pricing display type */}
                    {this.state[IS_PUBLIC_BID_PRICING_RESULT] && (
                        <Field
                            component={MultipleChoiceInput}
                            name={IS_PUBLIC_BID_TOTAL_ONLY}
                            onChange={this.updateShowTotalsOnlyResponse}
                            options={vendorPricingDisplayOptions}
                            value={this.state[IS_PUBLIC_BID_TOTAL_ONLY]}
                        />
                    )}
                    {/* Vendor Responses to Questionnaire */}
                    <Field
                        component={Toggle}
                        disabled={!hasDisplayableBidResults || updating}
                        label={
                            <>
                                {this.settingsMap[IS_PUBLIC_BID_RESULT].label}
                                <HelpIcon
                                    tooltip={
                                        !hasDisplayableBidResults
                                            ? 'Project has no vendor response data to display'
                                            : "Displays vendors' questionnaire responses in the 'Results' tab in the public portal. " +
                                              'Note: When enabled, we’ll send an email to notify project followers that bid results are now available.'
                                    }
                                />
                                {hasDisplayableBidResults && shouldDisplayVendorResponses && (
                                    <Tooltip tooltip="Since the bid is unsealed now, vendor responses should also be published.">
                                        <i
                                            className={classnames(
                                                'fa fa-exclamation-circle',
                                                this.styles.warningCircle
                                            )}
                                        />
                                    </Tooltip>
                                )}
                            </>
                        }
                        labelInline
                        labelInlineTogglePosition="left"
                        name={IS_PUBLIC_BID_RESULT}
                        onChange={this.updateToggleValue}
                    />
                    {/* Consensus Scorecard */}
                    {evaluation && evaluation.hasConsensusEvaluation && (
                        <Field
                            component={Toggle}
                            disabled={updating}
                            label={
                                <>
                                    {this.settingsMap[IS_CONSENSUS_PUBLIC].label}
                                    <HelpIcon tooltip="Displays the consensus scorecard in the project's 'Results' tab in the public portal" />
                                </>
                            }
                            labelInline
                            labelInlineTogglePosition="left"
                            name={IS_CONSENSUS_PUBLIC}
                            onChange={this.updateToggleValue}
                        />
                    )}
                    {/* Selected Vendor */}
                    {evaluation && (
                        <Field
                            component={Toggle}
                            disabled={updating}
                            label={
                                <>
                                    {this.settingsMap[IS_EVALUATION_PUBLIC].label}
                                    <HelpIcon tooltip="Displays the selected vendor in the project's 'Results' tab in the public portal" />
                                </>
                            }
                            labelInline
                            labelInlineTogglePosition="left"
                            name={IS_EVALUATION_PUBLIC}
                            onChange={this.updateToggleValue}
                        />
                    )}
                    <div className={this.styles.toggleSectionTitle}>
                        <b>Other Public Display Options</b>
                    </div>
                    {/* Show as Coming Soon */}
                    {canToggleComingSoon(project) && (
                        <Field
                            component={Toggle}
                            disabled={updating}
                            label={
                                <>
                                    {this.settingsMap[COMING_SOON].label}
                                    <HelpIcon tooltip="Displays project title along with 'Coming Soon' in the public portal" />
                                </>
                            }
                            labelInline
                            labelInlineTogglePosition="left"
                            name={COMING_SOON}
                            onChange={this.updateToggleValue}
                        />
                    )}
                    {/* Put project on hold */}
                    <Field
                        component={Toggle}
                        disabled={updating}
                        label={
                            <>
                                {this.settingsMap[IS_PAUSED].label}
                                <HelpIcon
                                    tooltip="Put your project on hold to indicate that is not actively being worked on or progressed.&nbsp;
                                    You will still be able to update the project as needed."
                                />
                            </>
                        }
                        labelInline
                        labelInlineTogglePosition="left"
                        name={IS_PAUSED}
                        onChange={this.updateToggleValue}
                    />
                </div>
            </div>
        );
    }

    render() {
        return (
            <Modal onHide={this.hideModal} show>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">Public Display Options</Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.renderForm()}</Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.hideModal}>Cancel</Button>
                    <Button
                        bsStyle="primary"
                        disabled={this.updatedSettingsKeys.length === 0}
                        onClick={this.saveUpdates}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export const ProjectPublicDisplayOptionsModal = compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig)
)(ConnectedProjectPublicDisplayOptionsModal);
