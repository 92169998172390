import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Modal } from 'react-bootstrap';
import { Field } from 'redux-form';

import { fieldNames } from './constants';
import { InputText } from '../../InputText';
import { Button } from '../../Button';
import { limitTextLength } from '../../../Forms/normalizers';

const { DEPARTMENT_NAME, DEPARTMENT_HEAD, DEPARTMENT_HEAD_TITLE } = fieldNames;

export class DepartmentModal extends PureComponent {
    static propTypes = {
        departmentHeadFieldName: PropTypes.string,
        departmentHeadTitleFieldName: PropTypes.string,
        departmentNameFieldName: PropTypes.string,
        disabled: PropTypes.bool,
        hideModal: PropTypes.func.isRequired,
        showModal: PropTypes.bool.isRequired,
        showValidation: PropTypes.bool,
    };

    static defaultProps = {
        departmentHeadFieldName: DEPARTMENT_HEAD,
        departmentHeadTitleFieldName: DEPARTMENT_HEAD_TITLE,
        departmentNameFieldName: DEPARTMENT_NAME,
    };

    render() {
        const {
            departmentHeadFieldName,
            departmentHeadTitleFieldName,
            departmentNameFieldName,
            disabled,
            hideModal,
            showModal,
            showValidation,
        } = this.props;

        const normalizeTextLength = limitTextLength(128);

        return (
            <Modal onHide={hideModal} show={showModal}>
                <Modal.Body>
                    <Field
                        component={InputText}
                        disabled={disabled}
                        hasFeedback={false}
                        label="Department Name"
                        name={departmentNameFieldName}
                        normalize={normalizeTextLength}
                        showValidation={showValidation}
                        type="text"
                    />
                    <Field
                        component={InputText}
                        disabled={disabled}
                        hasFeedback={false}
                        label="Department Head"
                        name={departmentHeadFieldName}
                        normalize={normalizeTextLength}
                        showValidation={showValidation}
                        type="text"
                    />
                    <Field
                        component={InputText}
                        disabled={disabled}
                        hasFeedback={false}
                        label="Department Head Title"
                        name={departmentHeadTitleFieldName}
                        normalize={normalizeTextLength}
                        showValidation={showValidation}
                        type="text"
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        aria-label="Close Button"
                        onClick={hideModal}
                        qaTag="departmentModal-close"
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
