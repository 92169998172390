import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Typography } from '@og-pro/ui';
import { tokens } from '@opengov/capital-style';

import { SearchSelect } from '../SearchSelect/SearchSelect';
import { SearchSelectUserOption } from '../SearchSelectUserOption';
import { vendorSearchSelectList } from '../../actions/vendorList';

const mapStateToProps = (state) => {
    return {
        governmentId: state.auth.getIn(['user', 'government', 'id']),
    };
};

const mapDispatchToProps = {
    vendorSearchSelectList,
};

// @connect
class ConnectedVendorSearchInput extends PureComponent {
    static propTypes = {
        filterPendingUsers: PropTypes.bool,
        filterPendingUsersWithoutOrganization: PropTypes.bool,
        governmentId: PropTypes.number.isRequired,
        helpText: PropTypes.string,
        includeVendorId: PropTypes.bool,
        isMulti: PropTypes.bool,
        onOptionChange: PropTypes.func,
        optionValueField: PropTypes.string.isRequired,
        selectedVendorOption: PropTypes.shape({
            label: PropTypes.string.isRequired,
            user: PropTypes.shape({
                displayName: PropTypes.string.isRequired,
            }).isRequired,
            value: PropTypes.any.isRequired,
        }),
        vendorSearchSelectList: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.loadVendors = debounce(this.rawLoadVendors, 500);
        this.state = {
            inputValue: '',
            loading: false,
            vendorSelectOptions: [],
        };
    }

    onInputChange = (data, { action }) => {
        const { isMulti } = this.props;

        // Only load vendors when input is changed (skips blur and selection changes)
        if (action === 'input-change') {
            this.loadVendors(data);
        }

        if (isMulti) {
            if (action === 'input-change') {
                this.setState({ inputValue: data });
                return data;
            }
            return this.state.inputValue;
        }

        return data;
    };

    rawLoadVendors = (searchInput) => {
        const {
            filterPendingUsers,
            filterPendingUsersWithoutOrganization,
            governmentId,
            includeVendorId,
            optionValueField,
        } = this.props;

        this.setState({ loading: true });
        return this.props
            .vendorSearchSelectList(governmentId, searchInput, filterPendingUsers, includeVendorId)
            .then((results) => {
                const vendorSelectOptions = results
                    .filter((row) => {
                        if (filterPendingUsers && row.isPendingUser) {
                            return false;
                        }
                        if (filterPendingUsersWithoutOrganization && !row.organizationId) {
                            return false;
                        }
                        return true;
                    })
                    .map((row) => {
                        let label;
                        // row.name: organization name
                        if (row.fullName && row.name) {
                            label = `${row.fullName} (${row.name})`;
                        } else if (row.fullName) {
                            label = row.fullName;
                        } else if (row.email && row.name) {
                            label = `${row.email} (${row.name})`;
                        } else {
                            label = row.email;
                        }

                        const addresses = [row.address, row.billingAddress, row.userAddress];
                        const addressOptions = addresses
                            .filter((address, index) => {
                                // if the address doesn't exist, it will be returned as ",    " due to SQL CONCAT
                                return (
                                    addresses.indexOf(address) === index && address.trim() !== ','
                                );
                            })
                            .map((address) => ({
                                label: address,
                                value: address,
                            }));

                        const user = {
                            displayName: label,
                            email: row.email,
                            addresses: addressOptions,
                            id: row.userId,
                            isPendingUser: row.isPendingUser,
                            organizationId: row.organizationId,
                        };

                        return {
                            label,
                            user,
                            value: row[optionValueField],
                        };
                    });
                this.setState({ loading: false, vendorSelectOptions });
            });
    };

    onChangeWithOption = (data) => {
        const { onOptionChange } = this.props;

        if (onOptionChange) {
            onOptionChange(data);
        }

        // Data will be null when form has been cleared
        if (data === null) {
            this.loadVendors(data);
        }
    };

    render() {
        const {
            filterPendingUsers,
            governmentId,
            helpText,
            includeVendorId,
            optionValueField,
            selectedVendorOption,
            ...props
        } = this.props;

        const { loading, vendorSelectOptions } = this.state;

        let options = vendorSelectOptions;

        // Ensure selected project is in the list of options
        if (selectedVendorOption && props.meta.pristine) {
            options = [selectedVendorOption].concat(vendorSelectOptions);
        }

        return (
            <SearchSelect
                components={{
                    Option: SearchSelectUserOption,
                    LoadingIndicator: () => <i className="fa fa-fw fa-spinner fa-spin" />,
                }}
                help={
                    <Typography sx={{ color: tokens.colors.colorGray800, fontSize: '14px' }}>
                        {helpText}
                    </Typography>
                }
                isLoading={loading}
                noOptionsMessage={() => (loading ? 'Loading...' : 'No results found')}
                onChangeWithOption={this.onChangeWithOption}
                onInputChange={this.onInputChange}
                options={loading ? [] : options}
                placeholder="Search by a vendor or person’s name"
                {...props}
            />
        );
    }
}

export const VendorSearchInput = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedVendorSearchInput);
