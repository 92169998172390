import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { getSectionNumberingString, numberItems } from '@og-pro/shared-config/helpers';

import { QuestionnaireResponseDisplay } from '../QuestionnaireResponseDisplay';
import { QuestionnaireResponseForm } from '../QuestionnaireResponseForm';
import { QuestionnaireDisplay } from '../QuestionnaireDisplay';
import { Button } from '../../Button';
import { QuestionnaireQuestionLogicDisplay } from '../../QuestionLogicDisplay';
import { exportArrayToCSV, stripHtml } from '../../../utils';
import { OGThemeContext } from '../../../containers/GovApp/ogThemeProvider';

export class QuestionnaireDisplayList extends Component {
    static propTypes = {
        canGovUserEdit: PropTypes.bool,
        disabled: PropTypes.bool,
        isDocx: PropTypes.bool,
        isPricingSealed: PropTypes.bool,
        isResponseDisplay: PropTypes.bool,
        isResponseForm: PropTypes.bool,
        isRevisionsDiff: PropTypes.bool,
        projectId: PropTypes.number,
        proposalId: PropTypes.number,
        questionnaires: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                isConditionalSubQuestion: PropTypes.bool,
                isHiddenByLogic: PropTypes.bool,
                isTitle: PropTypes.bool,
                uuid: PropTypes.string,
            }).isRequired
        ).isRequired,
        responsePath: PropTypes.string,
        showExportButton: PropTypes.bool,
        showValidation: PropTypes.bool,
        updateQuestionnaireResponse: PropTypes.func,
        useManualNumbering: PropTypes.bool,
        useRawPrompt: PropTypes.bool,
        useSectionDividers: PropTypes.bool,
    };

    static defaultProps = {
        disabled: false,
        isDocx: false,
        isResponseDisplay: false,
        isResponseForm: false,
        proposalId: undefined,
        useRawPrompt: false,
    };

    static contextType = OGThemeContext;

    get styles() {
        return require('./index.scss');
    }

    handleExportCSVClick = () => {
        const { questionnaires } = this.props;

        const numberedQuestionnaires = numberItems(questionnaires);
        const rows = numberedQuestionnaires
            .filter((questionnaire) => !questionnaire.isHiddenByLogic)
            .map((questionnaire) => {
                const {
                    conditionalSubQuestionNumber,
                    isRequired,
                    prompt,
                    sectionNumber,
                    subsectionNumber,
                    title,
                } = questionnaire;

                return [
                    getSectionNumberingString({
                        sectionNumber,
                        subsectionNumber,
                        subSectionItemNumber: conditionalSubQuestionNumber,
                        useLetterForSubsectionItem: false,
                    }),
                    title,
                    prompt ? stripHtml(prompt) : '', // Prevent "null" value in CSV
                    isRequired,
                ];
            });

        const headers = ['Number', 'Title', 'Prompt', 'Required'];

        exportArrayToCSV([headers].concat(rows), {
            fileName: 'Vendor Questionnaire',
            headers: true,
        });
    };

    renderQuestionnaireItem(questionnaire, sectionData) {
        const {
            canGovUserEdit,
            disabled,
            isDocx,
            isPricingSealed,
            isResponseDisplay,
            isResponseForm,
            isRevisionsDiff,
            projectId,
            proposalId,
            responsePath,
            showValidation,
            updateQuestionnaireResponse,
            useManualNumbering,
            useRawPrompt,
            useSectionDividers,
        } = this.props;
        const { isOGThemeEnabledForComponents } = this.context;

        const { conditionalSubQuestionNumber } = sectionData;

        const sharedQuestionnaireProps = {
            ...questionnaire,
            ...sectionData,
            disabled,
        };

        if (isResponseDisplay) {
            const className = questionnaire.isTitle
                ? 'response-display-title'
                : 'response-display-item';

            return (
                <div
                    className={classnames(
                        'questionnaire-response-item',
                        className,
                        !!conditionalSubQuestionNumber && 'conditional-item'
                    )}
                    key={questionnaire.id}
                >
                    <QuestionnaireResponseDisplay
                        {...sharedQuestionnaireProps}
                        canGovUserEdit={canGovUserEdit}
                        isDocx={isDocx}
                        isPricingSealed={isPricingSealed}
                        projectId={projectId}
                        responsePath={responsePath}
                        truncateResponse
                        updateQuestionnaireResponse={updateQuestionnaireResponse}
                    />
                </div>
            );
        }

        let className = classnames(this.styles.questionnaireItem, {
            [this.styles.questionnaireConditionalItem]: !!conditionalSubQuestionNumber,
            [this.styles.questionnaireItemReducedMargin]:
                (questionnaire?.conditionalSubQuestionIds?.length ||
                    !!conditionalSubQuestionNumber) &&
                !isDocx,
        });

        if (isResponseForm) {
            if (isOGThemeEnabledForComponents) {
                className = classnames(this.styles.sdv2QuestionnaireItem, {
                    [this.styles.sdv2QuestionnaireBoxedItem]: !questionnaire.isTitle,
                    [this.styles.sdv2QuestionnaireChildItem]: !!conditionalSubQuestionNumber,
                });
            }

            return (
                <div className={className} key={questionnaire.id}>
                    <QuestionnaireResponseForm
                        {...sharedQuestionnaireProps}
                        proposalId={proposalId}
                        responsePath={responsePath}
                        showValidation={showValidation}
                    />
                </div>
            );
        }

        const docxStyle = !!conditionalSubQuestionNumber && isDocx ? { paddingLeft: 19 } : {};

        return (
            <div
                className={className}
                key={questionnaire.id || questionnaire.uuid}
                style={docxStyle}
            >
                {!!conditionalSubQuestionNumber && (
                    <QuestionnaireQuestionLogicDisplay
                        isDocx={isDocx}
                        questionLogic={questionnaire.questionLogic}
                        questionnaires={this.props.questionnaires}
                    />
                )}
                <QuestionnaireDisplay
                    {...sharedQuestionnaireProps}
                    isDocx={isDocx}
                    isRevisionsDiff={isRevisionsDiff}
                    useManualNumbering={useManualNumbering}
                    useRawPrompt={useRawPrompt}
                    useSectionDividers={useSectionDividers}
                />
            </div>
        );
    }

    render() {
        const { isDocx, questionnaires, showExportButton } = this.props;

        const numberedQuestionnaires = numberItems(questionnaires);
        const questionnaireItems = numberedQuestionnaires.map((questionnaire) => {
            if (questionnaire.isHiddenByLogic) {
                return null;
            }

            return this.renderQuestionnaireItem(questionnaire, {
                conditionalSubQuestionNumber: questionnaire.conditionalSubQuestionNumber,
                sectionNumber: questionnaire.sectionNumber,
                subsectionNumber: questionnaire.subsectionNumber,
            });
        });

        return (
            <div>
                {showExportButton && !isDocx && (
                    <div className={this.styles.exportToCSVButton}>
                        <Button
                            bsSize="sm"
                            onClick={this.handleExportCSVClick}
                            qaTag="questionnaireDisplayList-button-exportToCsv"
                        >
                            <i className="fa fa-download" /> Export to CSV
                        </Button>
                    </div>
                )}
                {questionnaireItems}
            </div>
        );
    }
}
