import PropTypes from 'prop-types';
import React from 'react';

import { Add as AddIcon } from '@mui/icons-material';
import { Button } from '@og-pro/ui';

export const GroupPositionsCreateButton = ({ onClick }) => {
    return (
        <Button
            color="primary"
            onClick={onClick}
            qaTag="groupPositionAdmin-create"
            startIcon={<AddIcon />}
            variant="contained"
        >
            Add Position
        </Button>
    );
};

GroupPositionsCreateButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};
