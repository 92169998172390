import React from 'react';
import { useSelector } from 'react-redux';

import { VendorPendingDocumentsUpload } from './VendorPendingDocumentsUpload';
import { getPendingContractAttachments } from '../selectors';
import { DisplaySection } from '../../DisplaySection';
import { FileUploadAttachment } from '../../FileUploadAttachment';

export const VendorPendingDocuments = () => {
    const pendingGroupedContractAttachments = useSelector(getPendingContractAttachments);

    return (
        <DisplaySection
            afterItemsElement={<VendorPendingDocumentsUpload />}
            header="PENDING DOCUMENTS"
            items={pendingGroupedContractAttachments.map((attachmentGroup) => {
                return {
                    icon: 'clock-o',
                    label: attachmentGroup[0].tags[0].name,
                    value: attachmentGroup.map((attachment) => {
                        return (
                            <div key={attachment.id}>
                                <FileUploadAttachment
                                    attachment={attachment}
                                    disabled
                                    useListItemStyle
                                />
                            </div>
                        );
                    }),
                };
            })}
            zeroState="No Pending Documents"
        />
    );
};
