import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';

import { proposalStatusesDict } from '@og-pro/shared-config/proposals';

import { Well } from '../../../Well/Well';
import { isAuctionComplete } from './helpers';
import { timezoneAbbreviations } from '../../../../constants';

const { PUBLISHED } = proposalStatusesDict;

export const SubmittedWell = ({
    displayVendorConfirmationWell,
    project: {
        auctionEndDate,
        government: {
            organization: { name, timezone },
        },
        status: projectStatus,
        template: { isReverseAuction },
    },
    proposal: { isGovernmentSubmitted, status, submittedAt, submitter },
}) => {
    if (!submittedAt || status !== PUBLISHED) {
        return null;
    }

    const styles = require('./shared.scss');
    const timezoneText = <>&nbsp;({timezoneAbbreviations[timezone]})</>;

    if (isGovernmentSubmitted && !displayVendorConfirmationWell) {
        return (
            <Well className="no-print">
                <h4 className="text-center text-primary">
                    <i className="fa fa-upload" /> Response Manually Added
                </h4>
                <p className={styles.proposalSubmittedInfo}>
                    The response was manually added on{' '}
                    {moment.tz(submittedAt, timezone).format('lll')}
                    {timezoneText}
                    {submitter && ` by ${submitter.displayName}`}.
                </p>
            </Well>
        );
    }

    if (!displayVendorConfirmationWell) {
        return null;
    }

    const auctionIsComplete = isAuctionComplete(projectStatus);
    const getHeaderText = () => {
        if (isReverseAuction) {
            return `${!auctionIsComplete ? 'Initial ' : ''}Bid`;
        }
        return 'Response';
    };

    return (
        <Well className="no-print">
            <h4 className="text-center text-success">
                <i className="fa fa-check-square-o" /> {getHeaderText()} Submitted
            </h4>
            {isReverseAuction && auctionIsComplete && (
                <p className={styles.proposalSubmittedInfo}>
                    <span className={styles.bolded}>Bid from Reverse Auction:</span> Submitted on{' '}
                    {moment.tz(auctionEndDate, timezone).format('lll')}
                    {timezoneText}
                    {submitter && ` by ${submitter.displayName}`}.
                </p>
            )}
            <p className={styles.proposalSubmittedInfo}>
                {isReverseAuction ? (
                    <span>
                        <span className={styles.bolded}>Initial Bid:</span> Submitted
                    </span>
                ) : (
                    'The response was submitted'
                )}{' '}
                on {moment.tz(submittedAt, timezone).format('lll')}
                {timezoneText}
                {submitter && ` by ${submitter.displayName}`}.
                <br />
                {name} was sent a notification noting your response submission and time.
            </p>
        </Well>
    );
};

SubmittedWell.propTypes = {
    displayVendorConfirmationWell: PropTypes.bool,
    project: PropTypes.shape({
        auctionEndDate: PropTypes.string,
        government: PropTypes.shape({
            organization: PropTypes.shape({
                name: PropTypes.string.isRequired,
                timezone: PropTypes.string.isRequired,
            }),
        }),
        status: PropTypes.string.isRequired,
        template: PropTypes.shape({
            isReverseAuction: PropTypes.bool.isRequired,
        }).isRequired,
    }).isRequired,
    proposal: PropTypes.shape({
        isGovernmentSubmitted: PropTypes.bool.isRequired,
        status: PropTypes.string.isRequired,
        submittedAt: PropTypes.instanceOf(Date),
        submitter: PropTypes.shape({
            displayName: PropTypes.string,
        }),
    }).isRequired,
};
