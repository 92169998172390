import React, { useContext } from 'react';
import { formValueSelector } from 'redux-form';
import { useSelector } from 'react-redux';
import { Box } from '@og-pro/ui';
import { useFlags, FLAGS } from '@og-pro/launch-darkly/client';

import { questionLogicLinkableModelNames } from '@og-pro/shared-config/questionLogics';
import { sectionTypeNames } from '@og-pro/shared-config/sections';
import { projectTypesDict } from '@og-pro/shared-config/projects';

import { TemplateEditV2FunctionsContext } from '../../../context';
import { ATTACHMENTS_TITLES, EXHIBITS_TITLES } from '../../../constants';
import {
    BaseSectionsNavBackNextButtons,
    BaseSectionsNavBackButton,
    BaseSectionsNavNextButton,
    SectionHeaderViewTitleOnly,
} from '../../../../../../../components/SDv2';
import { TemplateAttachmentsForm } from '../../../../forms/ProjectContentForm/components';

import { form } from '../../../../forms/constants';
import { fieldNames as formFieldNames } from '../../../../forms/TemplateForm/constants';

const { ATTACHMENTS } = sectionTypeNames;
const { ATTACHMENT: ATTACHMENT_LINKABLE } = questionLogicLinkableModelNames;
const selector = formValueSelector(form);

export const TemplateEditV2Attachments = () => {
    const contractPackageCompilerEnabled = useFlags(FLAGS.ENABLE_CONTRACT_PACKAGE_COMPILER);
    const {
        disabled,
        buildRouteFunction,
        isIntake,
        updateTemplateIfDirty,
        router,
        showFormValidation,
        projectId,
        tagOptions,
        templateProject,
        templateVariableOptions,
    } = useContext(TemplateEditV2FunctionsContext);
    const useManualNumbering = useSelector((state) =>
        selector(state, formFieldNames.USE_MANUAL_NUMBERING)
    );
    const styles = require('./index.scss');

    const showingSignaturesTab = templateProject?.type === projectTypesDict.CONTRACT;
    const isExhibit =
        templateProject?.type === projectTypesDict.CONTRACT && contractPackageCompilerEnabled;
    const TITLES = isExhibit ? EXHIBITS_TITLES : ATTACHMENTS_TITLES;
    const title = TITLES[0].title;
    return (
        <Box className={styles.container}>
            <Box className={styles.header}>
                <SectionHeaderViewTitleOnly description={TITLES[0].description} title={title} />
            </Box>
            <Box className={styles.content}>
                <TemplateAttachmentsForm
                    disabled={disabled}
                    form={form}
                    hideQuestionLogic={false}
                    hideSupportingAttachments={isIntake}
                    isSharedSectionForm={false}
                    projectId={projectId}
                    projectSection={{
                        id: 0.5,
                        section_type: ATTACHMENTS,
                        title,
                    }}
                    questionLogicLinkable={ATTACHMENT_LINKABLE}
                    showFormValidation={showFormValidation}
                    standalone={isExhibit}
                    tagOptions={tagOptions}
                    templateVariableOptions={templateVariableOptions}
                    useManualNumbering={useManualNumbering}
                    usingFakeSection
                />
            </Box>
            <Box className={styles.footer}>
                <BaseSectionsNavBackNextButtons
                    firstSectionComponent={
                        <BaseSectionsNavBackButton
                            onClick={() => {
                                updateTemplateIfDirty();
                                router.push(buildRouteFunction('document-builder'));
                            }}
                        >
                            Document Editor
                        </BaseSectionsNavBackButton>
                    }
                    lastSectionComponent={
                        showingSignaturesTab ? (
                            <BaseSectionsNavNextButton
                                onClick={() => {
                                    updateTemplateIfDirty();
                                    router.push(buildRouteFunction('signatures'));
                                }}
                            >
                                Signatures
                            </BaseSectionsNavNextButton>
                        ) : (
                            <BaseSectionsNavNextButton
                                onClick={() => {
                                    updateTemplateIfDirty();
                                    router.push(buildRouteFunction('questions-and-automations'));
                                }}
                            >
                                Questions & Automations
                            </BaseSectionsNavNextButton>
                        )
                    }
                    sections={[]}
                />
            </Box>
        </Box>
    );
};
