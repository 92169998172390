import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@og-pro/ui';
import { useParams } from 'react-router-dom';

import { getProjectContracts } from '../../selectors';
import { getUserOrganizationTimezone } from '../../../../selectors';
import { LoadingSpinner, LoadingError } from '../../../../../components';
import { ListView as ContractsListView } from '../../../../../components/ContractList/ListView';
import { loadProjectDocuments } from '../../../../../actions/govProjects';

export const DocumentsList = () => {
    const { projectId } = useParams();
    const dispatch = useDispatch();
    const contracts = useSelector(getProjectContracts);
    const loadError = useSelector((state) => state.govProjects.get('loadProjectDocumentsError'));
    const loading = useSelector((state) => state.govProjects.get('loadingProjectDocuments'));
    const timezone = useSelector(getUserOrganizationTimezone);

    useEffect(() => {
        dispatch(loadProjectDocuments(projectId));
    }, []);

    if (loading) {
        return <LoadingSpinner />;
    }

    if (loadError) {
        return <LoadingError error={loadError} />;
    }

    if (contracts.length === 0) {
        return (
            <Box sx={{ my: 5, textAlign: 'center' }}>
                <Typography color="secondary" variant="h4">
                    No contracts have been added yet
                </Typography>
            </Box>
        );
    }

    return <ContractsListView contracts={contracts} fullWidth timezone={timezone} />;
};
