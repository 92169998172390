import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { ApproveModalBody } from './ApproveModalBody';

export class MarkApprovedModalBody extends PureComponent {
    static propTypes = {
        name: PropTypes.string.isRequired,
        approved: PropTypes.bool.isRequired,
        note: PropTypes.string,
        submitError: PropTypes.string,
        approveHandler: PropTypes.func.isRequired,
        hideModal: PropTypes.func.isRequired,
        title: PropTypes.string,
    };

    renderInfo() {
        const { approved, name, title } = this.props;
        if (approved) return null;
        const styles = require('../ApprovalsModal.scss');
        const lowerTitle = title.toLowerCase();
        return (
            <div className={styles.markApproved}>
                <p className="text-danger">
                    <strong>WARNING: </strong>
                    You are about to approve this {lowerTitle} on behalf of {name}. This action
                    should only be made if the reviewer has approved of the {lowerTitle}, but cannot
                    mark it approved themself.
                </p>
                <p>
                    Upon marking approved, the reviewer will be emailed, so they are aware that you
                    have approved the {lowerTitle} for them. You may also leave a note explaining
                    why you needed to approve the {lowerTitle} for {name}.
                </p>
            </div>
        );
    }

    render() {
        return (
            <div>
                {this.renderInfo()}
                <ApproveModalBody {...this.props} />
            </div>
        );
    }
}
