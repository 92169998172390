import React, { useEffect, useState } from 'react';
import { Field } from 'redux-form';
import { Box } from '@og-pro/ui';

import { customTimelineFieldsDict } from '@og-pro/shared-config/timelines';

import { TIMELINES, TIMELINE_CONFIG } from '../../../../../../containers/GovApp/constants';
import { AttachmentUploader } from '../../../../../AttachmentUploader';
import { CDSButton } from '../../../../../SDv2/CDSButtons/CDSButton';
import { InputText } from '../../../../../InputText';
import { AGENDA_FILE_MODE, PROP_TYPES } from './constants';

const { AGENDA_URL } = customTimelineFieldsDict;

export const AgendaInput = (props) => {
    const [mode, setMode] = useState(null);
    const { change, disabled, project, showValidation, timeline } = props;
    const { agendaUrl, index, preProposalAgendaUrl, preProposalAgendaUrlField, title } = timeline;

    const fieldName = preProposalAgendaUrlField
        ? `${TIMELINE_CONFIG}.${preProposalAgendaUrlField}`
        : `${TIMELINES}.${index}.${AGENDA_URL}`;

    const agendaUrlString = preProposalAgendaUrl || agendaUrl || '';
    const isAgendaUpload = agendaUrlString.includes('government-project.s3.amazonaws');

    const styles = require('../shared.scss');

    useEffect(() => {
        if (agendaUrlString) {
            if (isAgendaUpload) {
                setMode(AGENDA_FILE_MODE.UPLOAD);
            } else {
                setMode(AGENDA_FILE_MODE.MANUAL);
            }
        }
    }, [agendaUrlString, isAgendaUpload]);

    const agendaFileUploadHandler = (agendaFile) => {
        return change(
            fieldName,
            `https://${agendaFile.bucket}.s3.amazonaws.com/${agendaFile.path}`
        );
    };

    const agendaFileDeleteHandler = () => {
        setMode(null);
        return change(fieldName, null);
    };

    if (!mode) {
        return (
            <Box mt={1.5}>
                <CDSButton
                    noPadding
                    onClick={() => setMode(AGENDA_FILE_MODE.MANUAL)}
                    qaTag="projectCreateTimeline-addAgenda"
                    size="small"
                    variant="text"
                >
                    <i className="fa fa-plus" /> Add Agenda
                </CDSButton>
            </Box>
        );
    }

    if (mode === AGENDA_FILE_MODE.MANUAL) {
        return (
            <Box mt={1.5}>
                <Box alignItems="center" display="flex" mb={0.5}>
                    <Box className={styles.label} mr={2}>
                        Agenda URL
                    </Box>
                    <Box className={styles.legend} mr={2}>
                        or
                    </Box>
                    <Box>
                        <CDSButton
                            className={styles.action}
                            noPadding
                            onClick={() => setMode(AGENDA_FILE_MODE.UPLOAD)}
                            qaTag="projectCreateTimeline-uploadAgenda"
                            size="small"
                            variant="text"
                        >
                            <i className="fa fa-upload" /> Upload a file
                        </CDSButton>
                    </Box>
                </Box>
                <Box display="flex">
                    <Box flex={11}>
                        <Field
                            component={InputText}
                            disabled={disabled}
                            hasFeedback={false}
                            name={fieldName}
                            qaTag={`timelineForm-${title}AgendaUrl`}
                            showValidation={showValidation}
                            type="text"
                            useOpenGovStyle
                        />
                    </Box>
                    <Box>
                        <Box ml={1} pt={1}>
                            <CDSButton
                                className={styles.removeLocationButton}
                                onClick={agendaFileDeleteHandler}
                                qaTag="timelineForm-addLocation"
                                size="small"
                                variant="text"
                            >
                                <i className="fa fa-times" />
                            </CDSButton>
                        </Box>
                    </Box>
                </Box>
            </Box>
        );
    }

    return (
        <Box className={styles.agendaContainer} mt={1.5}>
            <Box alignItems="center" display="flex" mb={0.5}>
                <Box className={styles.label} mr={2}>
                    Agenda File
                </Box>
                <Box className={styles.legend} mr={2}>
                    or
                </Box>
                <Box>
                    <CDSButton
                        className={styles.action}
                        noPadding
                        onClick={() => setMode(AGENDA_FILE_MODE.MANUAL)}
                        qaTag="projectCreateTimeline-manualAgenda"
                        size="small"
                        variant="text"
                    >
                        <i className="fa fa-link" /> Link to Agenda
                    </CDSButton>
                </Box>
            </Box>
            <AttachmentUploader
                allowSingleAttachmentOnly
                attachments={
                    isAgendaUpload
                        ? [{ filename: 'Agenda', path: agendaUrlString, url: agendaUrlString }]
                        : []
                }
                deleteHandler={agendaFileDeleteHandler}
                disabled={disabled}
                dropzoneOptions={{
                    text: 'Drop file or click to select file to upload for meeting agenda',
                    children: (
                        <Box>
                            <CDSButton
                                qaTag="projectCreateTimeline-upload"
                                size="small"
                                variant="secondary"
                            >
                                <i className="fa fa-upload" /> Upload a file
                            </CDSButton>
                        </Box>
                    ),
                    dropzoneClassName: styles.dropzone,
                    dropzoneContainerClassName: styles.dropzoneContainer,
                }}
                noLabel
                onSuccess={agendaFileUploadHandler}
                s3GetUrl={`/project/${project.id}/agenda/s3`}
                skipForm
                useOpenGovStyle
            />
        </Box>
    );
};
AgendaInput.propTypes = PROP_TYPES;
