import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactToggle from 'react-toggle';

import { getGroupedContractAttachments } from '../selectors';
import { DisplaySection } from '../../DisplaySection';
import { FileUploadAttachment } from '../../FileUploadAttachment';
import { showConfirmationModal, showConfirmationSimpleModal } from '../../../actions/confirmation';
import { updateContract } from '../../../actions/contracts';
import { TOGGLE_CONTRACT_PUBLIC_PRIVATE } from '../../../constants/menuActions';

export const ContractDocuments = ({
    contract: {
        id,
        government: { hasPublicContractDocuments },
    },
    isAwardedVendor,
    isEditor,
    isPublicView,
}) => {
    const groupedContractAttachments = useSelector(getGroupedContractAttachments);
    const dispatch = useDispatch();
    const styles = require('../index.scss');

    const handlePublicChange = (e, attachment) => {
        const updatedAttachment = {
            ...attachment,
            contractAttachment: {
                isPublic: e.target.checked,
            },
            tags: attachment.tags[0].id || null,
        };

        if (e.target.checked) {
            return dispatch(
                showConfirmationSimpleModal(
                    () => {
                        dispatch(updateContract(id, { attachments: [updatedAttachment] }));
                    },
                    {
                        bsStyle: 'warning',
                        btnText: 'Make Public',
                        icon: 'globe',
                        text: `Are you sure you want ${attachment.filename} to be publicly viewable?`,
                    }
                )
            );
        }

        dispatch(
            showConfirmationModal(TOGGLE_CONTRACT_PUBLIC_PRIVATE, {
                attachments: [updatedAttachment],
                contractId: id,
            })
        );
    };

    if (isPublicView && !isAwardedVendor && !hasPublicContractDocuments) {
        return null;
    }

    const renderDocumentPublicToggle = (attachment) => {
        const { contractAttachment } = attachment;

        return (
            <ReactToggle
                checked={contractAttachment ? contractAttachment.isPublic : false}
                className={styles.checkbox}
                key={attachment.id}
                onChange={(e) => handlePublicChange(e, attachment)}
            />
        );
    };

    return (
        <DisplaySection
            header="CONTRACT DOCUMENTS"
            helpMessage={
                !isPublicView
                    ? `Public Documents will be accessible to the awarded vendor and to the general public. Non-public documents will remain private to internal staff only.`
                    : undefined
            }
            items={groupedContractAttachments.map((attachmentGroup) => {
                return {
                    icon: 'file-text-o',
                    label: attachmentGroup[0].tags[0].name,
                    value: attachmentGroup.map((attachment) => {
                        return (
                            <div className="row" key={attachment.id}>
                                <FileUploadAttachment
                                    attachment={attachment}
                                    className="col-md-8 col-lg-9"
                                    disabled
                                    useListItemStyle
                                />
                                {!isPublicView && (
                                    <>
                                        {isEditor && hasPublicContractDocuments && (
                                            <>
                                                <div className={`col-md-1 ${styles.toggleText}`}>
                                                    <i>Public</i>
                                                </div>
                                                <div className="col-md-3 col-lg-2">
                                                    {renderDocumentPublicToggle(attachment)}
                                                </div>
                                            </>
                                        )}
                                        {!isEditor && attachment.contractAttachment.isPublic && (
                                            <div
                                                className={`col-md-4 col-lg-3 text-muted ${styles.publicLabel}`}
                                            >
                                                <i>Public</i>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        );
                    }),
                };
            })}
            zeroState="No Documents Included"
        />
    );
};

ContractDocuments.propTypes = {
    contract: PropTypes.shape({
        government: PropTypes.shape({
            hasPublicContractDocuments: PropTypes.bool.isRequired,
        }).isRequired,
        id: PropTypes.number.isRequired,
    }).isRequired,
    isAwardedVendor: PropTypes.bool,
    isEditor: PropTypes.bool,
    isPublicView: PropTypes.bool,
};
