import { maxBy } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'redux-form';
import { connect } from 'react-redux';
import { v4 as UUIDv4 } from 'uuid';

import { ORDER_BY_ID, PROMPT, RAW_PROMPT } from '@og-pro/shared-config/questionnaires';

import { showConfirmationSimpleModal } from '../../../actions/confirmation';
import {
    showQuestionnaireCreateModal,
    showQuestionnaireEditModal,
} from '../../../actions/questionnaire';
import { Button } from '../../Button';
import { CDSButton } from '../../SDv2/CDSButtons/CDSButton';
import { CDSButtonGroup } from '../../SDv2/CDSButtons/CDSButtonGroup';
import { QuestionnaireCreateModal, QuestionnaireList } from './components';
import { replaceValueWithRawValue } from '../../../helpers';
import { OGThemeConsumer } from '../../../containers/GovApp/ogThemeProvider';

const mapDispatchToProps = {
    showConfirmationSimpleModal,
    showCreateModal: showQuestionnaireCreateModal,
    showEditModal: showQuestionnaireEditModal,
};

// @connect
class ConnectedQuestionnairesForm extends Component {
    static propTypes = {
        addToBottomOnly: PropTypes.bool,
        allowDefaultValue: PropTypes.bool,
        array: PropTypes.shape({
            push: PropTypes.func.isRequired,
            splice: PropTypes.func.isRequired,
            unshift: PropTypes.func.isRequired,
        }).isRequired,
        change: PropTypes.func.isRequired,
        createBeforeAddingError: PropTypes.string,
        createBeforeAddingHandler: PropTypes.func, // Only use if questionnaire needs to be created when added to form
        disabled: PropTypes.bool,
        formatTypes: PropTypes.array,
        formErrors: PropTypes.object,
        formKey: PropTypes.string.isRequired,
        includeContainsPricingOption: PropTypes.bool,
        isTemplate: PropTypes.bool,
        label: PropTypes.string,
        questionnaireDisplayName: PropTypes.string,
        questionnaires: PropTypes.array.isRequired,
        questionTypes: PropTypes.array,
        renderQuestionLogicIcon: PropTypes.func,
        requireByDefault: PropTypes.bool,
        rerenderOnEveryChange: PropTypes.bool,
        showConfirmationSimpleModal: PropTypes.func.isRequired,
        showCreateModal: PropTypes.func.isRequired,
        showEditModal: PropTypes.func.isRequired,
        showFormErrors: PropTypes.bool,
        tagOptions: PropTypes.array,
        TemplateQuestionAddButton: PropTypes.func,
        templateVariableOptions: PropTypes.array,
        tooltipComponent: PropTypes.node,
        useRawPrompt: PropTypes.bool,
        usesTemplateQuestions: PropTypes.bool,
        validateBidBond: PropTypes.bool,
    };

    static defaultProps = {
        disabled: false,
        formatTypes: undefined,
        label: 'List your questions for the vendor to answer',
        questionnaireDisplayName: 'Vendor Question',
        questionTypes: undefined,
        requireByDefault: false,
        rerenderOnEveryChange: false,
        showFormErrors: false,
        tagOptions: undefined,
        templateVariableOptions: undefined,
        tooltipComponent: undefined,
        useRawPrompt: false,
        usesTemplateQuestions: false,
    };

    get styles() {
        return require('./index.scss');
    }

    getMaxOrderId = () => {
        const { questionnaires } = this.props;
        if (!questionnaires.length) return 0;

        const lastQuestionnaire = maxBy(questionnaires, (questionnaire) => questionnaire.orderById);
        return lastQuestionnaire.orderById || 0;
    };

    addQuestion = (formData, options = {}) => {
        const { array, change, createBeforeAddingHandler, formKey, questionnaires, useRawPrompt } =
            this.props;

        const { addToTop, insertAfterQuestionId, questionnaireDataToAddAfterCreation } = options;

        const handleInsert = (questionnaireData) => {
            let insertAfterIndex;
            questionnaires.forEach((questionnaire, index) => {
                const oldOrderById = questionnaire.orderById;
                if (oldOrderById === insertAfterQuestionId) {
                    insertAfterIndex = index + 1;
                }
                if (oldOrderById > insertAfterQuestionId) {
                    change(`${formKey}[${index}].${ORDER_BY_ID}`, oldOrderById + 1);
                }
            });

            const newQuestionnaire = {
                ...questionnaireData,
                orderById: insertAfterQuestionId + 1,
            };
            array.splice(formKey, insertAfterIndex, 0, newQuestionnaire);
            return newQuestionnaire;
        };

        // If `createBeforeAddingHandler` is specified, we want to create the questionnaire before
        // adding to form
        if (createBeforeAddingHandler) {
            let newQuestionnaireData = {
                ...formData,
                [ORDER_BY_ID]: this.getMaxOrderId() + 1,
            };

            // if using the RAW_PROMPT field instead of PROMPT
            // make sure we populate the PROMPT field which is what the api cares about, otherwise data is lost
            if (useRawPrompt) {
                newQuestionnaireData = replaceValueWithRawValue(
                    newQuestionnaireData,
                    PROMPT,
                    RAW_PROMPT
                );
            }

            // Run create handler and add to form on success
            return createBeforeAddingHandler(newQuestionnaireData).then((questionnaireOrError) => {
                if (!(questionnaireOrError instanceof Error)) {
                    const questionnaireToAdd = {
                        ...questionnaireOrError,
                        ...questionnaireDataToAddAfterCreation,
                    };

                    // if using the RAW_PROMPT field instead of PROMPT
                    // make sure the RAW_PROMPT is filled with the PROMPT information
                    // after the api response, otherwise the field will not show
                    if (useRawPrompt) {
                        questionnaireToAdd[RAW_PROMPT] = questionnaireToAdd[PROMPT];
                    }

                    if (insertAfterQuestionId) {
                        return handleInsert(questionnaireToAdd);
                    }
                    array.push(formKey, questionnaireToAdd);
                    return questionnaireToAdd;
                }
            });
        }

        const newQuestionnaireData = {
            ...formData,
            sharedId: UUIDv4(),
            uuid: UUIDv4(),
        };

        if (addToTop) {
            let orderById = 1;
            questionnaires.forEach((questionnaire, index) => {
                const oldOrderById = questionnaire.orderById;
                if (oldOrderById < orderById) {
                    orderById = oldOrderById;
                }
                change(`${formKey}[${index}].${ORDER_BY_ID}`, oldOrderById + 1);
            });

            const newQuestionnaire = {
                ...newQuestionnaireData,
                orderById,
            };
            array.unshift(formKey, newQuestionnaire);
            return newQuestionnaire;
        }

        if (insertAfterQuestionId) {
            return handleInsert(newQuestionnaireData);
        }

        const newQuestionnaire = {
            ...newQuestionnaireData,
            [ORDER_BY_ID]: this.getMaxOrderId() + 1,
        };
        array.push(formKey, newQuestionnaire);
        return newQuestionnaire;
    };

    editQuestion = (index, data) => {
        const { array, formKey } = this.props;
        array.splice(formKey, index, 1, data);
    };

    showCreateQuestionnaireModal = (modalOptions) => {
        const { showCreateModal, tagOptions, templateVariableOptions } = this.props;

        showCreateModal({}, { tagOptions, templateVariableOptions, ...modalOptions });
    };

    renderAddQuestionButton = (isOGThemeEnabledForComponents) => {
        const { disabled, TemplateQuestionAddButton, questionnaires, questionnaireDisplayName } =
            this.props;
        const hasTemplateQuestionAddButton = !!TemplateQuestionAddButton;

        const baseAddQuestionButtonProps = {
            disabled,
            onClick: () => this.showCreateQuestionnaireModal(),
            qaTag: 'questionnaires-add',
        };

        if (isOGThemeEnabledForComponents) {
            if (questionnaires.filter((q) => !q.isHiddenByLogic).length === 0) {
                return (
                    <CDSButtonGroup>
                        <CDSButton {...baseAddQuestionButtonProps} variant="secondary-alt">
                            <i className="fa fa-plus" /> Add {questionnaireDisplayName}
                        </CDSButton>
                        {hasTemplateQuestionAddButton ? TemplateQuestionAddButton() : null}
                    </CDSButtonGroup>
                );
            }

            return null;
        }

        return (
            <Button
                {...baseAddQuestionButtonProps}
                bsStyle={hasTemplateQuestionAddButton ? 'primary' : undefined}
                className={
                    hasTemplateQuestionAddButton
                        ? this.styles.addMultiBtn
                        : this.styles.addSingleBtn
                }
            >
                <i className="fa fa-plus" /> Add {questionnaireDisplayName}
            </Button>
        );
    };

    renderQuestionnaire = () => {
        const {
            change,
            createBeforeAddingError,
            disabled,
            formErrors,
            formKey,
            isTemplate,
            label,
            renderQuestionLogicIcon,
            // In some parent forms the swap operation does not always cause a re-render, this prop
            // allows us to force re-rendering in those cases.
            // This effects performance, but is okay to use for this form component since the form
            // values rarely change (only on create, delete and order).
            rerenderOnEveryChange,
            showEditModal,
            showFormErrors,
            tagOptions,
            TemplateQuestionAddButton,
            templateVariableOptions,
            tooltipComponent,
            useRawPrompt,
            usesTemplateQuestions,
        } = this.props;

        const hasTemplateQuestionAddButton = !!TemplateQuestionAddButton;

        return (
            <OGThemeConsumer>
                {({ isOGThemeEnabledForComponents }) => (
                    <div className="row" id="questionnaire-form-list">
                        <div className="col-xs-12">
                            {!isOGThemeEnabledForComponents && (
                                <div>
                                    <div className={this.styles.label}>{label}</div>
                                    {tooltipComponent}
                                </div>
                            )}
                            <FieldArray
                                TemplateQuestionAddButton={TemplateQuestionAddButton}
                                change={change}
                                component={QuestionnaireList}
                                disabled={disabled}
                                editHandler={this.editQuestion}
                                formErrors={formErrors}
                                isOGThemeEnabledForComponents={isOGThemeEnabledForComponents}
                                isTemplate={isTemplate}
                                name={formKey}
                                renderQuestionLogicIcon={renderQuestionLogicIcon}
                                rerenderOnEveryChange={rerenderOnEveryChange}
                                showConfirmationSimpleModal={this.props.showConfirmationSimpleModal}
                                showCreateQuestionnaireModal={this.showCreateQuestionnaireModal}
                                showEditModal={showEditModal}
                                showFormErrors={showFormErrors}
                                tagOptions={tagOptions}
                                templateVariableOptions={templateVariableOptions}
                                useRawPrompt={useRawPrompt}
                                usesTemplateQuestions={usesTemplateQuestions}
                            />
                            <div className="text-center" style={{ marginBottom: '16px' }}>
                                {this.renderAddQuestionButton(isOGThemeEnabledForComponents)}
                                {hasTemplateQuestionAddButton &&
                                    !isOGThemeEnabledForComponents &&
                                    TemplateQuestionAddButton()}
                            </div>
                            {createBeforeAddingError && (
                                <div className="text-center error-block">
                                    {createBeforeAddingError}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </OGThemeConsumer>
        );
    };

    render() {
        const {
            addToBottomOnly,
            allowDefaultValue,
            disabled,
            formatTypes,
            includeContainsPricingOption,
            questionnaireDisplayName,
            questionTypes,
            requireByDefault,
            useRawPrompt,
            validateBidBond,
        } = this.props;

        return (
            <>
                {this.renderQuestionnaire()}
                <QuestionnaireCreateModal
                    addToBottomOnly={addToBottomOnly}
                    allowDefaultValue={allowDefaultValue}
                    disabled={disabled}
                    formatTypes={formatTypes}
                    includeContainsPricingOption={includeContainsPricingOption}
                    onSubmit={this.addQuestion}
                    questionTypes={questionTypes}
                    questionnaireDisplayName={questionnaireDisplayName}
                    requireByDefault={requireByDefault}
                    useRawPrompt={useRawPrompt}
                    validateBidBond={validateBidBond}
                />
            </>
        );
    }
}

export const QuestionnairesForm = connect(null, mapDispatchToProps)(ConnectedQuestionnairesForm);
