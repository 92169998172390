import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { NoSsr } from '@mui/material';
import { FLAGS, withFlags } from '@og-pro/launch-darkly/client';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { Box } from '@og-pro/ui';
import { capitalDesignTokens } from '@opengov/capital-mui-theme';

import { AddVendorsToListModal } from '../AddVendorsToList/modal';
import { InviteVendorsToProjectModal } from '../InviteVendorsToProjectModal/modal';
import {
    getShowAddVendorListModal,
    getShowCustomEmailModal,
    getShowInviteVendorListToProjectModal,
    getVendorClassificationTotals,
    getVendorSearchActive,
    getVendorSearchDownloading,
    getVendorSearchError,
    getVendorSearchParams,
} from '../selectors';
import { getPortalUrl } from '../../selectors';
import { SubscribedVendorList } from '../SubscribedVendorList';
import { VendorManagementHeader } from '../VendorManagementHeader';
import connectData from '../../../ConnectData';
import { loadAllCertifications } from '../../../../actions/adminVendor';
import { showVendorProfileModal } from '../../../../actions/vendorProfile';
import {
    blockVendors,
    downloadVendorSearch,
    loadProcuratedRatingsClient,
    showAddVendorsToListModal,
    toggleInviteVendorListToProjectModal,
    vendorSearch,
} from '../../../../actions/vendorList';
import { toggleCustomEmailModal } from '../../../../actions/app';
import { CustomEmailModal, LoadingError, Main, VendorSearchTable } from '../../../../components';
import { searchVendors as vendorSearchOS } from '../../../../actions/vendorSearch';

function fetchData(getState, dispatch) {
    const promises = [];

    if (!getState().adminVendor.get('vendorCertifications')) {
        promises.push(dispatch(loadAllCertifications()));
    }

    return Promise.all(promises);
}

const mapStateToProps = (state, props) => {
    const governmentId = Number.parseInt(props.params.governmentId, 10);
    return {
        addVendorsToListModalOpen: getShowAddVendorListModal(state),
        customEmailModalOpen: getShowCustomEmailModal(state),
        governmentId,
        inviteToProjectModalOpen: getShowInviteVendorListToProjectModal(state),
        loadedVendorCount: state.vendorList.get('vendorCount'),
        portalUrl: getPortalUrl(state),
        vendorClassificationTotals: getVendorClassificationTotals(state),
        vendorSearchError: getVendorSearchError(state),
        vendorSearchDownloading: getVendorSearchDownloading(state),
        searchActive: getVendorSearchActive(state),
        activeSearchParams: getVendorSearchParams(state),
    };
};

const mapDispatchToProps = {
    blockVendors,
    downloadVendorSearch,
    loadProcuratedRatingsClient,
    showAddVendorsToListModal,
    showVendorProfileModal,
    toggleCustomEmailModal,
    toggleInviteVendorListToProjectModal,
    vendorSearch,
    vendorSearchOS,
};

// @connectData
// @connect
class ConnectedVendorSearch extends Component {
    static propTypes = {
        activeSearchParams: PropTypes.any,
        addVendorsToListModalOpen: PropTypes.bool.isRequired,
        blockVendors: PropTypes.func.isRequired,
        customEmailModalOpen: PropTypes.bool.isRequired,
        downloadVendorSearch: PropTypes.func.isRequired,
        getFlag: PropTypes.func.isRequired,
        governmentId: PropTypes.number.isRequired,
        inviteToProjectModalOpen: PropTypes.bool.isRequired,
        loadProcuratedRatingsClient: PropTypes.func.isRequired,
        loadedVendorCount: PropTypes.number,
        portalUrl: PropTypes.string.isRequired,
        searchActive: PropTypes.bool,
        showAddVendorsToListModal: PropTypes.func,
        showVendorProfileModal: PropTypes.func.isRequired,
        toggleCustomEmailModal: PropTypes.func,
        toggleInviteVendorListToProjectModal: PropTypes.func.isRequired,
        vendorSearch: PropTypes.func.isRequired,
        vendorSearchError: PropTypes.bool,
        vendorSearchDownloading: PropTypes.bool,
    };

    get styles() {
        return require('../index.scss');
    }

    constructor(props) {
        super(props);
        this.state = {
            searchParams: {},
            userIds: [],
        };
    }

    addVendorsToList = (userIds) => {
        const { governmentId } = this.props;

        this.setState({ userIds, searchParams: {} }, () => {
            this.props.showAddVendorsToListModal(governmentId);
        });
    };

    addVendorsToListBySearch = (searchParams) => {
        const { governmentId } = this.props;

        this.setState({ searchParams, userIds: [] }, () => {
            this.props.showAddVendorsToListModal(governmentId);
        });
    };

    blockVendors = (userIds, isBlocked, onSuccess) => {
        const { governmentId } = this.props;

        return this.props.blockVendors(governmentId, userIds, isBlocked, onSuccess);
    };

    inviteVendorsToProject = (userIds) => {
        this.setState({ userIds, searchParams: {} }, () => {
            this.props.toggleInviteVendorListToProjectModal();
        });
    };

    inviteVendorsToProjectBySearchParams = (searchParams) => {
        this.setState({ userIds: [], searchParams }, () => {
            this.props.toggleInviteVendorListToProjectModal();
        });
    };

    emailVendors = (userIds) => {
        this.setState({ userIds, searchParams: {} }, () => {
            this.props.toggleCustomEmailModal();
        });
    };

    emailVendorsBySearchParams = (searchParams) => {
        this.setState({ userIds: [], searchParams }, () => {
            this.props.toggleCustomEmailModal();
        });
    };

    renderSubscribedVendorList = (showNewVendorTable) => {
        const {
            activeSearchParams,
            governmentId,
            searchActive,
            vendorSearchDownloading,
            vendorSearchError,
        } = this.props;

        if (vendorSearchError) {
            return <LoadingError />;
        }

        return (
            <Main>
                <Box
                    px={7}
                    sx={{
                        backgroundColor: capitalDesignTokens.semanticColors.background.primary,
                    }}
                >
                    <NoSsr>
                        {showNewVendorTable ? (
                            <VendorSearchTable
                                addVendorsToList={this.addVendorsToList}
                                addVendorsToListBySearch={this.addVendorsToListBySearch}
                                blockVendors={this.blockVendors}
                                emailVendors={this.emailVendors}
                                emailVendorsBySearch={this.emailVendorsBySearchParams}
                                governmentId={governmentId}
                                inviteVendorsToProject={this.inviteVendorsToProject}
                                inviteVendorsToProjectBySearch={
                                    this.inviteVendorsToProjectBySearchParams
                                }
                                searchVendors={vendorSearchOS}
                            />
                        ) : (
                            <SubscribedVendorList
                                activeSearchParams={activeSearchParams}
                                addVendorsToList={this.addVendorsToList}
                                addVendorsToListBySearch={this.addVendorsToListBySearch}
                                blockVendors={this.blockVendors}
                                downloadVendorSearch={this.props.downloadVendorSearch}
                                emailVendors={this.emailVendors}
                                emailVendorsBySearchParams={this.emailVendorsBySearchParams}
                                governmentId={governmentId}
                                includeExpandedData
                                inviteVendorsToProject={this.inviteVendorsToProject}
                                inviteVendorsToProjectBySearchParams={
                                    this.inviteVendorsToProjectBySearchParams
                                }
                                loadProcuratedRatingsClient={this.props.loadProcuratedRatingsClient}
                                searchActive={searchActive}
                                showVendorProfileModal={this.props.showVendorProfileModal}
                                vendorSearch={this.props.vendorSearch}
                                vendorSearchDownloading={vendorSearchDownloading}
                            />
                        )}
                    </NoSsr>
                </Box>
            </Main>
        );
    };

    render() {
        const {
            addVendorsToListModalOpen,
            customEmailModalOpen,
            getFlag,
            governmentId,
            inviteToProjectModalOpen,
            loadedVendorCount,
            portalUrl,
        } = this.props;

        const { searchParams, userIds } = this.state;
        const showNewVendorTable = getFlag(FLAGS.ENABLE_NEW_INTERNAL_VENDOR_MANAGEMENT_TABLE);

        return (
            <div className={this.styles.container}>
                <VendorManagementHeader
                    governmentId={governmentId}
                    portalUrl={portalUrl}
                    showNewVendorTable={showNewVendorTable}
                />
                {this.renderSubscribedVendorList(showNewVendorTable)}
                {addVendorsToListModalOpen && (
                    <AddVendorsToListModal
                        governmentId={governmentId}
                        initialValues={{
                            searchParams,
                            userIds,
                        }}
                        noUserSelection
                        useOpenSearch={showNewVendorTable}
                    />
                )}
                {inviteToProjectModalOpen && (
                    <InviteVendorsToProjectModal
                        initialValues={{
                            searchParams,
                            userIds,
                        }}
                        useOpenSearch={showNewVendorTable}
                    />
                )}
                {customEmailModalOpen && (
                    <CustomEmailModal
                        data={{
                            searchParams,
                            userIds,
                            vendorCount: userIds.length || loadedVendorCount,
                        }}
                        hideModal={this.props.toggleCustomEmailModal}
                        useOpenGovStyle={showNewVendorTable}
                        useOpenSearch={showNewVendorTable}
                    />
                )}
            </div>
        );
    }
}

export const VendorSearch = compose(
    connectData(fetchData),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withFlags()
)(ConnectedVendorSearch);
