import React, { useContext } from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { Field, formValueSelector, getFormSyncErrors } from 'redux-form';

import { form } from '../../../../../forms/constants';
import {
    fieldNames,
    templateFieldNames,
    timelineConfigFieldNames,
} from '../../../../../forms/TemplateForm/constants';
import { limitTextLength } from '../../../../../../../../Forms/normalizers';
import { iconSelectOptions } from '../../../../../../../../constants/templateAdmin';
import {
    AttachmentUploader,
    InputText,
    SearchSelect,
    SearchSelectIconOption,
    SearchSelectIconValue,
    SearchSelectUserOption,
    Toggle,
    CDSButton,
} from '../../../../../../../../components';
import {
    helpPrivateBid,
    helpRequiredInvitation,
} from '../../../../../../ProjectPostCreate/ProjectTimeline/constants';
import { TemplateEditV2FunctionsContext } from '../../../../context';
import { getProcurementClassificationSelectOptions } from '../../../../../../../selectors';
import { getTemplateAdminUserList } from '../../../../../../../../selectors/govApp';
import { usesDocxHeadingFormatters as getUsesDocxHeadingFormatters } from '../../../../../../selectors';

const {
    ALLOW_SUBSTITUTION_REQUESTS,
    HAS_SEALED_BID,
    IS_AUTO_BID_UNSEALED,
    IS_PRIVATE,
    REQUIRES_INVITATION,
    TEMPLATE,
    TIMELINE_CONFIG,
    USE_MANUAL_NUMBERING,
    USE_SECTION_DIVIDERS,
    USER_ID,
} = fieldNames;

const {
    FORCE_SEALED_BID_STATUS,
    ICON,
    IS_GLOBAL_CREATE,
    IS_REVERSE_AUCTION,
    PROCESS_ACRONYM,
    PROCUREMENT_TAG_ID,
    SUBSTITUTION_REQUEST_FORM_URL,
    TITLE,
} = templateFieldNames;
const { HAS_AUCTION_END_DATE, PROPOSAL_DEADLINE_NAME } = timelineConfigFieldNames;

const selector = formValueSelector(form);
const errorsSelector = getFormSyncErrors(form);

export function TemplateEditV2GeneralPropertiesForm() {
    const {
        change,
        disabled,
        isDocBuilder,
        isSpecial,
        showFormValidation,
        projectId,
        toggleUseSectionDividers,
    } = useContext(TemplateEditV2FunctionsContext);
    const substitutionRequestError = useSelector((state) =>
        get(errorsSelector(state), [TEMPLATE, SUBSTITUTION_REQUEST_FORM_URL])
    );
    const isPrivate = useSelector((state) => selector(state, IS_PRIVATE));
    const allowSubstitutionRequests = useSelector((state) =>
        selector(state, ALLOW_SUBSTITUTION_REQUESTS)
    );
    const substitutionRequestFormUrl = useSelector((state) =>
        selector(state, `${TEMPLATE}.${SUBSTITUTION_REQUEST_FORM_URL}`)
    );
    const procurementClassificationSelectOptions = useSelector(
        getProcurementClassificationSelectOptions
    );
    const userOptions = useSelector(getTemplateAdminUserList);
    const usesDocxHeadingFormatters = useSelector(getUsesDocxHeadingFormatters);
    const isSolicitation = !isDocBuilder && !isSpecial;

    const styles = require('../index.scss');

    const reverseAuctionChangeHandler = (e, value) => {
        change(`${TIMELINE_CONFIG}.${HAS_AUCTION_END_DATE}`, value);
        change(`${TEMPLATE}.${FORCE_SEALED_BID_STATUS}`, value);
        change(HAS_SEALED_BID, value);
        change(IS_AUTO_BID_UNSEALED, value);

        change(
            `${TIMELINE_CONFIG}.${PROPOSAL_DEADLINE_NAME}`,
            value ? 'Initial Bid Due' : 'Response Submission Deadline'
        );
    };

    const handleSubstitutionFormUpload = (file) => {
        const formUrl = `https://${file.bucket}.s3.amazonaws.com/${file.path}`;

        change(`${TEMPLATE}.${SUBSTITUTION_REQUEST_FORM_URL}`, formUrl);
    };

    const deleteSubstitutionFormUrl = () => {
        change(`${TEMPLATE}.${SUBSTITUTION_REQUEST_FORM_URL}`, null);
    };

    return (
        <div className={styles.container}>
            <div className={styles.section}>
                <h3>Template Identification</h3>
                <div className={styles.row}>
                    <Field
                        component={InputText}
                        disabled={disabled}
                        hasFeedback={false}
                        label="Template Title*"
                        name={`${TEMPLATE}.${TITLE}`}
                        normalize={limitTextLength(128)}
                        qaTag="templateForm-templateTitle"
                        showValidation={showFormValidation}
                        type="text"
                        useOpenGovStyle
                    />
                </div>
                <div className={styles.row}>
                    <Field
                        component={InputText}
                        disabled={disabled}
                        hasFeedback={false}
                        help="Short hand name for this process."
                        label="Acronym / Abbreviation*"
                        name={`${TEMPLATE}.${PROCESS_ACRONYM}`}
                        normalize={limitTextLength(16)}
                        placeholder="RFP"
                        qaTag={`templateForm-${PROCESS_ACRONYM}`}
                        showValidation={showFormValidation}
                        type="text"
                        useOpenGovStyle
                    />
                </div>
                <div className={styles.row}>
                    <Field
                        component={SearchSelect}
                        disabled={disabled}
                        help="Choose from your tags."
                        isClearable
                        label="Procurement Classification (optional)"
                        name={`${TEMPLATE}.${PROCUREMENT_TAG_ID}`}
                        options={procurementClassificationSelectOptions}
                        qaTag="templateForm-procurementTag"
                        showValidation={showFormValidation}
                        useOpenGovStyle
                    />
                </div>
                {isSolicitation && (
                    <>
                        <div className={styles.row}>
                            <Field
                                component={Toggle}
                                disabled={disabled}
                                label="Reverse Auction"
                                labelInline
                                name={`${TEMPLATE}.${IS_REVERSE_AUCTION}`}
                                onChange={reverseAuctionChangeHandler}
                                qaTag={`templateForm-${IS_REVERSE_AUCTION}`}
                                tooltip="Uses reverse auction bid format"
                                useOpenGovStyle
                            />
                        </div>
                        {usesDocxHeadingFormatters && (
                            <div className={styles.row}>
                                <Field
                                    component={Toggle}
                                    disabled={disabled}
                                    label="Use Section Dividers"
                                    labelInline
                                    name={USE_SECTION_DIVIDERS}
                                    onChange={(e, value) => toggleUseSectionDividers(value)}
                                    qaTag={`templateForm-${USE_SECTION_DIVIDERS}`}
                                    tooltip="Allow section dividers to be added"
                                    useOpenGovStyle
                                />
                            </div>
                        )}
                    </>
                )}

                {!isSpecial && usesDocxHeadingFormatters && (
                    <div className={styles.row}>
                        <Field
                            component={Toggle}
                            disabled={disabled}
                            help="Allow manual numbering to be added to dividers, sections and items."
                            label="Use Manual Numbering"
                            labelInline
                            name={USE_MANUAL_NUMBERING}
                            qaTag={`templateForm-${USE_MANUAL_NUMBERING}`}
                            useOpenGovStyle
                        />
                    </div>
                )}
                <div className={styles.row}>
                    <Field
                        component={SearchSelect}
                        components={{
                            Option: SearchSelectIconOption,
                            SingleValue: SearchSelectIconValue,
                        }}
                        disabled={disabled}
                        help="Visual identifier for the template."
                        label="Icon*"
                        name={`${TEMPLATE}.${ICON}`}
                        options={iconSelectOptions}
                        qaTag="templateForm-templateIcon"
                        showValidation={showFormValidation}
                        useOpenGovStyle
                    />
                </div>
            </div>
            <div className={styles.section}>
                <h3>Template Control and Access</h3>
                <div className={styles.row}>
                    <Field
                        backspaceRemovesValue={false}
                        component={SearchSelect}
                        components={{
                            Option: SearchSelectUserOption,
                        }}
                        disabled={disabled}
                        help="User who owns the template (must be an admin)."
                        label="Owner*"
                        name={USER_ID}
                        options={userOptions}
                        qaTag="templateForm-templateOwner"
                        showValidation={showFormValidation}
                        useOpenGovStyle
                    />
                </div>
                {isSolicitation && (
                    <div className={styles.row}>
                        <Field
                            component={Toggle}
                            disabled={disabled}
                            help="Allows anyone in your organization to create a project using this template. The creator will also be given global edit ability over their created project so they can manage all phases from start to finish."
                            label="Global Creator Access"
                            labelInline
                            name={`${TEMPLATE}.${IS_GLOBAL_CREATE}`}
                            qaTag={`templateForm-${IS_GLOBAL_CREATE}`}
                            useOpenGovStyle
                        />
                    </div>
                )}
            </div>

            {isSolicitation && (
                <div className={styles.section}>
                    <h3>Private Bid Controls</h3>

                    <div className={styles.row}>
                        <Field
                            component={Toggle}
                            disabled={disabled}
                            help={helpPrivateBid}
                            label="Make Private Bid"
                            name={IS_PRIVATE}
                            onChange={(e, value) => {
                                if (!value) {
                                    change(REQUIRES_INVITATION, false);
                                }
                            }}
                            qaTag={`templateForm-${IS_PRIVATE}`}
                            useOpenGovStyle
                        />
                    </div>
                    {isPrivate && (
                        <div className={styles.row}>
                            <Field
                                component={Toggle}
                                disabled={disabled}
                                help={helpRequiredInvitation}
                                label="Require Invitation to Access Bid"
                                name={REQUIRES_INVITATION}
                                qaTag={`templateForm-${REQUIRES_INVITATION}`}
                                useOpenGovStyle
                            />
                        </div>
                    )}
                </div>
            )}

            {isSolicitation && (
                <div className={styles.section}>
                    <h3>Request Substitution Form</h3>
                    <div className={styles.row}>
                        <Field
                            component={Toggle}
                            disabled={disabled}
                            help="Allows vendors to submit requests (ex. brand alternates) until the Q & A period ends."
                            label="Allow Requests for Substitutions?"
                            labelInline
                            name={ALLOW_SUBSTITUTION_REQUESTS}
                            qaTag={`templateForm-${ALLOW_SUBSTITUTION_REQUESTS}`}
                            useOpenGovStyle
                        />
                    </div>

                    {allowSubstitutionRequests && (
                        <>
                            {substitutionRequestFormUrl && (
                                <div className={styles.row}>
                                    <Field
                                        component={InputText}
                                        disabled
                                        name={`${TEMPLATE}.${SUBSTITUTION_REQUEST_FORM_URL}`}
                                        qaTag={`templateForm-${SUBSTITUTION_REQUEST_FORM_URL}`}
                                        type="text"
                                        useOpenGovStyle
                                    />
                                    <a
                                        href={substitutionRequestFormUrl}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        <CDSButton
                                            aria-label="View"
                                            className={styles.subFormButton}
                                            disabled={disabled}
                                            qaTag="templateForm-viewSubstitutionRequestForm"
                                            size="small"
                                            variant="secondary"
                                        >
                                            <i className="fa fa-external-link" /> View
                                        </CDSButton>
                                    </a>
                                    &nbsp;
                                    <CDSButton
                                        aria-label="Remove"
                                        disabled={disabled}
                                        onClick={deleteSubstitutionFormUrl}
                                        qaTag="templateForm-removeSubstitutionRequestForm"
                                        size="small"
                                        variant="destructive"
                                    >
                                        <i className="fa fa-trash" /> Remove
                                    </CDSButton>
                                </div>
                            )}
                            {!substitutionRequestFormUrl && (
                                <div className={styles.row}>
                                    <AttachmentUploader
                                        allowSingleAttachmentOnly
                                        disabled={disabled}
                                        dropzoneOptions={{
                                            icons: [],
                                            dropzoneContainerClassName: classnames(
                                                styles.dropzoneContainer,
                                                {
                                                    [styles.invalidDropzone]:
                                                        substitutionRequestError,
                                                }
                                            ),
                                            dropzoneClassName: styles.dropzone,
                                            text: (
                                                <div
                                                    className={classnames(
                                                        'dropzone-custom-content',
                                                        styles.filesText
                                                    )}
                                                >
                                                    <i className="fa fa-upload" /> Drag a file here
                                                    to upload or{' '}
                                                    <span className={styles.action}>
                                                        click here to select a file
                                                    </span>
                                                </div>
                                            ),
                                        }}
                                        label="Upload Your Organization’s Request for Substitution Form*"
                                        onSuccess={handleSubstitutionFormUpload}
                                        s3GetUrl={`/templates/${projectId}/sub-req-form/s3`}
                                    />
                                    {!!substitutionRequestError && showFormValidation && (
                                        <div className={styles.invalidDropzoneText}>
                                            <i className="fa fa-exclamation-triangle" />{' '}
                                            {substitutionRequestError}
                                        </div>
                                    )}
                                </div>
                            )}
                        </>
                    )}
                </div>
            )}
        </div>
    );
}
