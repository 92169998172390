import React from 'react';
import PropTypes from 'prop-types';

import { Toggle } from '../Toggle/Toggle';

export const DraftReviewToggleV2 = ({ checked, displayOnly, onChange }) => {
    const styles = require('./DraftReviewToggle.scss');

    if (displayOnly) {
        return (
            <Toggle
                className={styles.projectToggle}
                icons={{
                    checked: null,
                    unchecked: null,
                }}
                id="DraftReviewToggle"
            />
        );
    }

    return (
        <Toggle
            input={{
                onBlur: () => {},
                onChange,
                value: checked,
            }}
            labelId="DraftReviewToggle"
            leftLabel="Edit"
            qaTag="projectCreate-draftReviewToggle"
            rightLabel="Preview"
            useOpenGovStyle
        />
    );
};

DraftReviewToggleV2.propTypes = {
    checked: PropTypes.bool,
    displayOnly: PropTypes.bool,
    onChange: PropTypes.func,
};
