import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, CircularProgress, IconButton } from '@og-pro/ui';
import { Download as DownloadIcon } from '@mui/icons-material';
import { projectStatusesDict } from '@og-pro/shared-config/projects';
import { statuses } from '@og-pro/shared-config/electronicSignatures';

import { showSnackbar } from '../../../../actions/notification';
import { downloadElectronicSignatureFile } from '../../../../actions/project/electronicSignatures';
import { downloadAssembledDocumentPacketDocument } from '../../../../actions/contracts';

export const DownloadDocumentButton = ({ contractDocument }) => {
    const dispatch = useDispatch();
    const { contractId } = useParams();
    const [loading, setLoading] = useState(false);

    const download = useCallback(async () => {
        try {
            setLoading(true);
            await dispatch(
                downloadElectronicSignatureFile({
                    projectId: contractDocument.id,
                    electronicSignatureId: contractDocument.electronicSignature.id,
                })
            );
        } catch (e) {
            dispatch(showSnackbar('Failed to download the document', { isError: true }));
        } finally {
            setLoading(false);
        }
    }, [dispatch, contractDocument]);

    const downloadContractPacket = useCallback(async () => {
        try {
            setLoading(true);
            await dispatch(
                downloadAssembledDocumentPacketDocument(contractId, contractDocument.id)
            );
        } catch (e) {
            dispatch(
                showSnackbar(e.message || 'Failed to download the document', { isError: true })
            );
        } finally {
            setLoading(false);
        }
    }, [dispatch, contractDocument]);

    const shouldShowWhenFinal =
        !contractDocument.electronicSignature &&
        contractDocument.status === projectStatusesDict.FINAL;
    const shouldShowWhenSigning =
        contractDocument.electronicSignature?.status === statuses.COMPLETED;

    if (shouldShowWhenFinal || shouldShowWhenSigning) {
        return (
            <Box px={0.5}>
                <IconButton
                    disabled={loading}
                    onClick={() => {
                        if (contractDocument.electronicSignature?.status === statuses.COMPLETED) {
                            return download();
                        }

                        return downloadContractPacket();
                    }}
                    qaTag="contractDocuments-downloadDocument"
                    size="small"
                >
                    {loading ? (
                        <CircularProgress size="small" />
                    ) : (
                        <DownloadIcon fontSize="small" />
                    )}
                </IconButton>
            </Box>
        );
    }

    return null;
};

DownloadDocumentButton.propTypes = {
    contractDocument: PropTypes.shape({
        electronicSignature: PropTypes.shape({
            currentSigners: PropTypes.number,
            id: PropTypes.number.isRequired,
            provider: PropTypes.string,
            providerId: PropTypes.string,
            status: PropTypes.string.isRequired,
            totalSigners: PropTypes.number,
        }),
        id: PropTypes.number.isRequired,
        status: PropTypes.string.isRequired,
    }).isRequired,
};
