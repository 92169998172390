import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Field } from 'redux-form';
import { v4 as UUIDv4 } from 'uuid';

import { instructionDataFieldNames } from '../constants';
import { Button } from '../../../../../Button';
import { DragIcon } from '../../../../../DragIcon';
import { InputText } from '../../../../../InputText';
import { RichTextInput } from '../../../../../RichTextInput';
import { OutlineButton } from '../../../../../OutlineButton';
import { getDndStyle } from '../../../../../../constants/styles';

const { INSTRUCTIONS: INSTRUCTION_DATA_INSTRUCTIONS, VALUE: INSTRUCTION_DATA_VALUE } =
    instructionDataFieldNames;

const MULTIPLE_CHOICE_DROPPABLE_TYPE = 'mutipleChoiceDroppableType';

export class CriteriaNeedsReviewMultipleChoiceItems extends PureComponent {
    static propTypes = {
        disabled: PropTypes.bool,
        fields: PropTypes.object.isRequired,
        meta: PropTypes.object.isRequired,
        optionFieldLabelName: PropTypes.string.isRequired,
        tagOptions: PropTypes.array,
        templateVariableOptions: PropTypes.array,
    };

    constructor() {
        super();

        // Should not be possible to render modal on server, but just in case (`document` won't exist on server)
        if (!process.env.SERVER) {
            this.portal = document.createElement('div');
            this.portal.classList.add('criteria-needs-review-multiple-choice-portal');

            document.body.appendChild(this.portal);
        }

        this.state = {
            richInputTextKey: 1,
        };
    }

    componentWillUnmount() {
        document.body.removeChild(this.portal);
    }

    get styles() {
        return require('./index.scss');
    }

    handleDragEnd = (fields) => (result) => {
        const originLocation = result.source.index;
        const newLocation = result.destination ? result.destination.index : undefined;

        if (newLocation !== undefined && newLocation !== originLocation) {
            fields.move(originLocation, newLocation);
            this.reinitializeRichInputText();
        }
    };

    reinitializeRichInputText = () => {
        this.setState((prevState) => {
            return {
                richInputTextKey: prevState.richInputTextKey + 1,
            };
        });
    };

    renderItem = ({ fields, member, index }) => {
        const { disabled, optionFieldLabelName, tagOptions, templateVariableOptions } = this.props;

        const { richInputTextKey } = this.state;

        return (
            <Draggable draggableId={`multiChoiceItem:${index}`} index={index}>
                {(provided, snapshot) => {
                    const item = (
                        <div className="well" ref={provided.innerRef} {...provided.draggableProps}>
                            <div className="pull-right">
                                <DragIcon
                                    containerClassName={this.styles.dragIcon}
                                    disabled={disabled}
                                    dragHandleProps={provided.dragHandleProps}
                                />
                                <Button
                                    bsStyle="link"
                                    className={this.styles.removeButton}
                                    onClick={() => {
                                        fields.remove(index);
                                        this.reinitializeRichInputText();
                                    }}
                                    zeroPadding
                                >
                                    <i className="fa fa-lg fa-times text-danger" />
                                </Button>
                            </div>
                            <Field
                                component={InputText}
                                hasFeedback={false}
                                label={`Option ${index + 1} Name`}
                                name={`${member}.${optionFieldLabelName}`}
                                type="text"
                            />
                            <Field
                                component={RichTextInput}
                                key={`${richInputTextKey}.${index}`}
                                label={`Option ${index + 1} Value to Insert`}
                                minRows={2}
                                name={`${member}.${INSTRUCTION_DATA_VALUE}`}
                                tagOptions={tagOptions}
                                templateVariableOptions={templateVariableOptions}
                            />
                        </div>
                    );

                    if (snapshot.isDragging) {
                        // Need to create a portal to have drag and drop properly align
                        // https://github.com/atlassian/react-beautiful-dnd/issues/485
                        // https://github.com/atlassian/react-beautiful-dnd/blob/v11.0.5/docs/patterns/using-a-portal.md
                        // https://github.com/atlassian/react-beautiful-dnd/blob/master/stories/src/portal/portal-app.jsx
                        return ReactDOM.createPortal(item, this.portal);
                    }

                    return item;
                }}
            </Draggable>
        );
    };

    render() {
        const {
            fields,
            disabled,
            meta: { error },
            optionFieldLabelName,
        } = this.props;

        return (
            <div>
                <DragDropContext onDragEnd={this.handleDragEnd(fields)}>
                    <Droppable
                        droppableId="criteriaBuilderNeedsReviewMultipleChoice"
                        isDropDisabled={disabled}
                        type={MULTIPLE_CHOICE_DROPPABLE_TYPE}
                    >
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                style={getDndStyle(snapshot)}
                                {...provided.droppableProps}
                            >
                                {fields.map((member, index, fieldsRef) => {
                                    return (
                                        <this.renderItem
                                            fields={fieldsRef}
                                            index={index}
                                            key={index}
                                            member={member}
                                        />
                                    );
                                })}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                {(optionFieldLabelName !== INSTRUCTION_DATA_INSTRUCTIONS || fields.length < 3) && (
                    <div className="text-center">
                        <OutlineButton
                            bsSize="sm"
                            bsStyle="info"
                            invertHover={false}
                            onClick={() =>
                                fields.push({
                                    uuid: UUIDv4(),
                                    [INSTRUCTION_DATA_VALUE]: `Value ${fields.length + 1}`,
                                    [optionFieldLabelName]: `Label ${fields.length + 1}`,
                                })
                            }
                        >
                            Add Option
                        </OutlineButton>
                    </div>
                )}
                {error && <div className="error-block text-center">{error}</div>}
            </div>
        );
    }
}
