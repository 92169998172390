import React from 'react';
import PropTypes from 'prop-types';

import { BasicSectionSettings } from './BasicSectionSettings';
import { FormSettings } from './FormSettings';
import { GeneralSectionSettings } from './GeneralSectionSettings';
import { PurchaseSectionSettings } from './PurchaseSectionSettings';
import { fieldNames, sectionNames } from '../constants';
import { CustomFormSectionSettings } from './CustomFieldsSectionSettings';
import { useSectionNumbering } from '../useSectionNumbering';

const {
    ADDITIONAL_INFORMATION,
    ATTACHMENTS,
    CUSTOM_FORM,
    FORM_SETTINGS,
    GENERAL_INFORMATION,
    PURCHASE_DETAILS,
    VENDOR_SELECTION,
} = sectionNames;
const {
    SECTION_INSTRUCTIONS_ADDITIONAL,
    SECTION_INSTRUCTIONS_ATTACHMENT,
    SECTION_INSTRUCTIONS_VENDOR,
    SECTION_NAME_ADDITIONAL,
    SECTION_NAME_ATTACHMENT,
    SECTION_NAME_VENDOR,
} = fieldNames;

export const SettingsStickySidebar = ({ disabled, onClose, section, showCustomForm }) => {
    const { getSectionNumber } = useSectionNumbering(showCustomForm);
    const sharedSectionProps = {
        disabled,
        onClose,
    };

    const title = `Section ${getSectionNumber(section)}`;

    switch (section) {
        case GENERAL_INFORMATION:
            return <GeneralSectionSettings {...sharedSectionProps} title={title} />;
        case PURCHASE_DETAILS:
            return <PurchaseSectionSettings {...sharedSectionProps} title={title} />;
        case VENDOR_SELECTION:
            return (
                <BasicSectionSettings
                    {...sharedSectionProps}
                    sectionInstructionsField={SECTION_INSTRUCTIONS_VENDOR}
                    sectionNameField={SECTION_NAME_VENDOR}
                    title={title}
                />
            );
        case CUSTOM_FORM:
            return <CustomFormSectionSettings {...sharedSectionProps} title={title} />;
        case ADDITIONAL_INFORMATION:
            return (
                <BasicSectionSettings
                    {...sharedSectionProps}
                    sectionInstructionsField={SECTION_INSTRUCTIONS_ADDITIONAL}
                    sectionNameField={SECTION_NAME_ADDITIONAL}
                    title={title}
                />
            );
        case ATTACHMENTS:
            return (
                <BasicSectionSettings
                    {...sharedSectionProps}
                    sectionInstructionsField={SECTION_INSTRUCTIONS_ATTACHMENT}
                    sectionNameField={SECTION_NAME_ATTACHMENT}
                    title={title}
                />
            );
        case FORM_SETTINGS:
        default:
            return <FormSettings disabled={disabled} />;
    }
};

SettingsStickySidebar.propTypes = {
    disabled: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    section: PropTypes.string,
    showCustomForm: PropTypes.bool,
};
