import React from 'react';
import { useSelector } from 'react-redux';

import { ContractPublicAuditLogTable } from '../../../components/ContractPublicAuditLogTable';
import { getContractPublicAuditLogs } from './selectors';
import { loadContractPublicAuditLogs } from '../../../actions/contracts';
import connectData from '../../ConnectData';
import { LoadingError, LoadingSpinner, Main, SectionTitle } from '../../../components';

function fetchData(getState, dispatch, location, params) {
    const contractId = Number.parseInt(params.contractId, 10);
    return dispatch(loadContractPublicAuditLogs(contractId));
}

const ConnectedContractPublicAuditLog = () => {
    const loading = useSelector((state) => state.contracts.get('loadingPublicAuditLogs'));
    const loadError = useSelector((state) => state.contracts.get('loadPublicAuditLogsError'));
    const publicAuditLogs = useSelector(getContractPublicAuditLogs);

    if (loading) {
        return <LoadingSpinner />;
    }

    if (loadError) {
        return <LoadingError error={loadError} />;
    }

    return (
        <Main>
            <SectionTitle
                info="View logs for when public visibility is enabled or disabled at the contract record or associated files level"
                title="Public Audit Log"
            />
            <ContractPublicAuditLogTable publicAuditLogs={publicAuditLogs} />
        </Main>
    );
};

export const ContractPublicAuditLog = connectData(fetchData)(ConnectedContractPublicAuditLog);
