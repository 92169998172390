import React from 'react';
import { Field, formValueSelector } from 'redux-form';
import { useSelector } from 'react-redux';
import { Box } from '@og-pro/ui';

import { customTimelineFieldsDict } from '@og-pro/shared-config/timelines';

import { TIMELINES } from '../../../../../../containers/GovApp/constants';
import { DateTimePicker } from '../../../../../DateTime/DateTimePicker';
import { PROP_TYPES } from './constants';

const { DATE } = customTimelineFieldsDict;

export const DateInput = (props) => {
    const { change, form, disabled, isTemplate, showValidation, timeline, timezone } = props;
    const formSelector = formValueSelector(form);
    const dateValue = useSelector((state) =>
        formSelector(state, timeline.projectField || `${TIMELINES}.${timeline.index}.${DATE}`)
    );
    const styles = require('../shared.scss');

    // Because we have two fields controlling the same form value, we have to update the time manually
    // to prevent choosing a new time resetting the date to the current date.
    const timeChangeHandler = (value) => {
        const hours = value.getHours();
        const minutes = value.getMinutes();
        // || conditional in case they select the hour first and we have no dateValue set which causes 1969 bug
        const dateToUpdate = new Date(dateValue || value);
        dateToUpdate.setHours(hours);
        dateToUpdate.setMinutes(minutes);
        setTimeout(() => {
            change(timeline.projectField || `${TIMELINES}.${timeline.index}.${DATE}`, dateToUpdate);
        });
    };

    return (
        <Box display="flex" flex={1}>
            <Box flex={1}>
                <Field
                    component={DateTimePicker}
                    disabled={isTemplate || disabled}
                    horizontal
                    name={timeline.projectField || `${TIMELINES}.${timeline.index}.${DATE}`}
                    placeholder="M/D/YYYY"
                    showValidation={showValidation}
                    time={false}
                    timezone={timeline.displayTime ? timezone : undefined}
                    useOpenGovStyle
                />
            </Box>
            <Box display="flex" flex={1}>
                <Box className={styles.legend} ml={1} mr={1} pt={1.5}>
                    {timeline.displayTime ? 'at' : <>&nbsp;</>}
                </Box>
                <Box flex={1}>
                    {timeline.displayTime && (
                        <Field
                            component={DateTimePicker}
                            date={false}
                            dateFormat="h:mma"
                            disabled={isTemplate || disabled}
                            horizontal
                            name={timeline.projectField || `${TIMELINES}.${timeline.index}.${DATE}`}
                            onChange={timeChangeHandler}
                            showValidation={showValidation}
                            time
                            timezone={timeline.displayTime ? timezone : undefined}
                            useOpenGovStyle
                        />
                    )}
                </Box>
            </Box>
        </Box>
    );
};
DateInput.propTypes = PROP_TYPES;
