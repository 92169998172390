import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { AddContentForm } from '../AddContentForm/AddContentForm';
import { editForm, fieldNames } from '../AddContentForm/constants';
import { Button, DisabledRichTextArea } from '../../../../../components';

const { TITLE, DESCRIPTION } = fieldNames;

export class CustomContentItem extends PureComponent {
    static propTypes = {
        item: PropTypes.shape({
            created_at: PropTypes.string.isRequired,
            deleteError: PropTypes.string,
            deleting: PropTypes.bool,
            description: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
            showEditForm: PropTypes.bool,
            title: PropTypes.string.isRequired,
            updateError: PropTypes.string,
        }).isRequired,
        editContentHandler: PropTypes.func.isRequired,
        deleteContentHandler: PropTypes.func.isRequired,
        toggleForm: PropTypes.func.isRequired,
    };

    toggleForm = () => {
        const { item, toggleForm } = this.props;

        return toggleForm(true, item.id);
    };

    deleteItem = () => {
        const { deleteContentHandler, item } = this.props;

        return deleteContentHandler(item.id);
    };

    handleCloseForm = () => {
        const {
            item: { id },
            toggleForm,
        } = this.props;

        toggleForm(false, id);
    };

    renderLoadingIcon() {
        return (
            <div>
                <i className="fa fa-spinner fa-lg fa-spin" />
            </div>
        );
    }

    renderDeleteError() {
        return (
            <div className="text-danger">
                <i className="fa fa-warning" /> Error
            </div>
        );
    }

    renderItem() {
        const { item, editContentHandler } = this.props;

        if (item.showEditForm) {
            return (
                <AddContentForm
                    buttonLoadingText="Updating..."
                    buttonText="Update"
                    closeForm={this.handleCloseForm}
                    form={`${editForm}${item.id}`}
                    hideLabels
                    initialValues={{
                        [TITLE]: item[TITLE],
                        [DESCRIPTION]: item[DESCRIPTION],
                    }}
                    onSubmit={editContentHandler}
                    submitServerError={item.updateError}
                />
            );
        }

        return (
            <span>
                <div className="form-group">
                    <input
                        aria-label="Title"
                        className="form-control"
                        disabled
                        value={item.title}
                    />
                </div>
                <div className="form-group">
                    <DisabledRichTextArea
                        aria-label="Content"
                        minRows={2}
                        value={item.description}
                    />
                </div>
            </span>
        );
    }

    renderActionCol() {
        const { item } = this.props;
        const styles = require('./CustomContent.scss');

        if (item.showEditForm) {
            return null;
        }

        return (
            <span>
                <div className={styles.actionButtons}>
                    <Button
                        bsSize="sm"
                        bsStyle="link"
                        className={styles.editButton}
                        disabled={item.deleting}
                        onClick={this.toggleForm}
                    >
                        <i className="fa fa-pencil lg" /> Edit
                    </Button>
                    <Button
                        bsSize="sm"
                        bsStyle="link"
                        className={styles.deleteButton}
                        disabled={item.deleting}
                        onClick={this.deleteItem}
                    >
                        <i className="fa fa-lg fa-trash-o text-danger" /> Delete
                    </Button>
                </div>
                {item.deleting ? this.renderLoadingIcon() : null}
                {item.deleteError ? this.renderDeleteError() : null}
            </span>
        );
    }

    render() {
        const { item } = this.props;
        const styles = require('./CustomContent.scss');

        return (
            <div className={`row ${styles.rowItem}`}>
                <div className="col-xs-8">{this.renderItem()}</div>
                <div className="col-xs-4 text-center">
                    <div>{moment(item.created_at).format('l')}</div>
                    {this.renderActionCol()}
                </div>
            </div>
        );
    }
}
