import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { compose } from 'redux';

import { sectionTypeNames } from '@og-pro/shared-config/sections';

import { IntroBackgroundHelp } from './IntroBackgroundHelp';
import { IntroSummaryHelp } from './IntroSummaryHelp';
import { ScopeHelp } from './ScopeHelp';
import { getModalDataJS } from './selectors';
import { getBuilderSection, getProjectSection } from '../selectors';
import { Button, HtmlContent } from '../../../../components';
import { hideHelpModal } from '../../../../actions/project/create/projectCreate';

const {
    ATTACHMENTS,
    EVALUATION_CRITERIA,
    EVALUATION_PHASE,
    PRICING,
    QUESTIONNAIRE,
    SCOPE,
    TERMS,
    TEXT_AREA,
} = sectionTypeNames;

const mapStateToProps = (state, props) => {
    return {
        builderSection: getBuilderSection(state, props),
        data: getModalDataJS(state),
        projectSection: getProjectSection(state, props),
        showModal: state.projectCreate.get('showHelpModal'),
    };
};

const mapDispatchToProps = {
    hideModal: hideHelpModal,
};

// @withRouter
// @connect
class ConnectedProjectCreateHelpModal extends PureComponent {
    static propTypes = {
        builderSection: PropTypes.shape({
            instructions: PropTypes.string,
        }).isRequired,
        data: PropTypes.shape({
            hasIncludedItems: PropTypes.bool,
            onClose: PropTypes.func,
            type: PropTypes.string,
        }).isRequired,
        hideModal: PropTypes.func.isRequired,
        projectSection: PropTypes.shape({
            isWritingForm: PropTypes.bool,
            section_type: PropTypes.string,
            title: PropTypes.string.isRequired,
        }).isRequired,
        showModal: PropTypes.bool.isRequired,
        // eslint-disable-next-line react/no-unused-prop-types
        overrideSelectedProjectSectionId: PropTypes.number, // This is used in the `getProjectSectionQueryParam` selector to get the correct `id` for SDv2
    };

    get styles() {
        return require('./index.scss');
    }

    hideModal = () => {
        const {
            data: { onClose },
            hideModal,
        } = this.props;

        if (onClose) {
            onClose();
        }

        hideModal();
    };

    // Only meant for Attachments in Solicitations, not Contracts
    renderAttachmentsContent() {
        return (
            <div>
                <p>
                    Use this section to manage internal and external attached documents. These files
                    can be in a wide variety of formats (PDFs, MS Word Doc, Excel, CSV, PNG, JPG,
                    etc).
                </p>

                <p>
                    If your template is not set to automatically generate a Word Document, then you
                    can upload your project information to the <strong>Main Documents</strong>{' '}
                    upload area. Any file uploaded here will not be assigned an attachment letter to
                    reference. This area is typically used when a solicitation has been created
                    outside OpenGov Procurement and we are using the template to notify/collect
                    vendor responses.
                </p>

                <p>
                    For any <strong>Supporting Attachments</strong> that you wish to include with
                    your solicitation, please use the second upload area. Here, you will be able to
                    upload your file as well as assign a letter to reference (Lettering Sequence
                    Supports up to 208 attachments).
                </p>

                <p>
                    The third <strong>Internal Documents</strong> area gives you a place to add
                    internal-only documentation, such as research artifacts or internal forms that
                    should be associated with the project.
                </p>
            </div>
        );
    }

    renderEvaluationContent() {
        const {
            data: { hasIncludedItems },
        } = this.props;

        return (
            <div>
                <p>
                    In this section you will define the criteria which will be used to evaluate the
                    proposals received. For each criteria item, you will assign a weight and a
                    method for scoring the item.
                </p>
                <p className="text-primary text-center">
                    <i className="fa fa-star-o" />
                    &nbsp;
                    <i className="fa fa-star-o" />
                    &nbsp;
                    <i className="fa fa-star-o" />
                </p>
                <p>
                    {hasIncludedItems
                        ? "Your organization's recommended list of evaluation criteria has " +
                          'already been included. You can add and modify items as needed.'
                        : 'Please add all relevant evaluation criteria to this section.'}
                </p>
            </div>
        );
    }

    renderPricingContent() {
        return (
            <div>
                <p>
                    In this section you will create one or more price tables. Usually one pricing
                    table is sufficient, but for more complex projects it can be helpful to break up
                    pricing into multiple tables. Each table is composed of pricing items that the
                    vendor will respond to by entering a price.
                </p>
                <p>
                    When creating your table, determine whether you want to include a quantity
                    column. You should use this option if you know the exact quantity that should be
                    provided. For projects that are priced on a rate or per-unit basis, you will
                    likely not want to include a quantity column. To adjust these options use the
                    &quot;Table Settings&quot; menu. You can also add and rename columns in table
                    settings.
                </p>
                <p>
                    The table you create will be completed by the vendor as part of their proposal
                    response. The vendor will enter the unit cost and can also provide comments on
                    any item.
                </p>
                <p>
                    <strong>Importing Data:</strong>
                    <br />
                    To import data from a spreadsheet, use the &quot;Show File Upload Options&quot;
                    button and follow the instructions to download the template spreadsheet file,
                    fill it out, and upload back with your price tables data.
                </p>
                <p>
                    If you just want to include some data from an existing spreadsheet, please
                    see&nbsp;
                    <a
                        href="https://opengov.my.site.com/support/s/article/fd63ae60-0a02-4226-9268-351f1e6da1ac"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        this article for instructions
                    </a>{' '}
                    on copy and pasting from a spreadsheet.
                </p>
            </div>
        );
    }

    renderQuestionnaireContent() {
        const {
            data: { hasIncludedItems },
        } = this.props;

        return (
            <div>
                <p>
                    Use this section to create a list of questions that the vendor will answer as
                    part of their response.
                </p>
                <p>
                    You can select the format of the vendor&#39;s response to each question. For
                    example you can request that a vendor select an answer from a set of options or
                    upload a file as their response.
                </p>
                {hasIncludedItems && (
                    <p>
                        Your organization&#39;s recommended list of questions has already been
                        included. You can add and modify questions as needed.
                    </p>
                )}
            </div>
        );
    }

    renderTermsContent() {
        const {
            data: { hasIncludedItems },
            projectSection: { isWritingForm, title },
        } = this.props;

        if (!isWritingForm) {
            return (
                <div>
                    <p>
                        Please review each item in this section. To start reviewing an item, click
                        on the item and follow the instructions to provide your response.
                    </p>
                    <p>
                        Completed items will have a checkmark (
                        <i className="text-success fa fa-check" />) next to the title and be
                        highlighted in green.
                    </p>
                    <p>Please complete every item in this section.</p>
                </div>
            );
        }

        const basicInstructions = `In this section you will create a list of items for the "${title}" section.`;

        if (hasIncludedItems) {
            return (
                <div>
                    <p>{basicInstructions}</p>
                    <p>
                        Your organization&#39;s recommended list of items has already been included
                        for this section.
                    </p>
                    <p>
                        Please review each of the included items. You can also add and modify items
                        as needed.
                    </p>
                </div>
            );
        }

        return (
            <div>
                <p>{basicInstructions}</p>
                <p>Please add all relevant items for the section.</p>
            </div>
        );
    }

    renderTextAreaContent() {
        const {
            data: { hasIncludedItems },
            projectSection: { isWritingForm, title },
        } = this.props;

        if (!isWritingForm) {
            return (
                <div>
                    <p>
                        Please review the content in this section. To start reviewing, click on the
                        item and follow the instructions to provide your response.
                    </p>
                    <p>
                        Once the review has been completed there will be a checkmark (
                        <i className="text-success fa fa-check" />) next to the item and it will
                        also be highlighted in green.
                    </p>
                </div>
            );
        }

        const basicInstructions = `In this section you will provide the content for the "${title}" section.`;

        if (hasIncludedItems) {
            return (
                <div>
                    <p>{basicInstructions}</p>
                    <p>
                        Your organization&#39;s recommended content has already been included as a
                        starting point for this section.
                    </p>
                    <p>Please review the content and add or modify as needed.</p>
                </div>
            );
        }

        return (
            <div>
                <p>{basicInstructions}</p>
                <p>Please provide all relevant content for the section.</p>
            </div>
        );
    }

    renderContent() {
        const {
            builderSection: { instructions: customInstructions },
            projectSection: { section_type: sectionType },
        } = this.props;

        // Render custom instructions if they have been provided
        if (customInstructions) {
            return <HtmlContent content={customInstructions} />;
        }

        // Render default instructions
        switch (sectionType) {
            case ATTACHMENTS:
                return this.renderAttachmentsContent();
            case EVALUATION_CRITERIA:
            case EVALUATION_PHASE:
                return this.renderEvaluationContent();
            case PRICING:
                return this.renderPricingContent();
            case QUESTIONNAIRE:
                return this.renderQuestionnaireContent();
            case TERMS:
                return this.renderTermsContent();
            case TEXT_AREA:
                return this.renderTextAreaContent();
            default:
                return null;
        }
    }

    renderModalBody() {
        const {
            builderSection: { instructions: customInstructions },
            data: { type },
            projectSection: { section_type: sectionType, title },
        } = this.props;

        if (!customInstructions) {
            if (type === 'summary') {
                return <IntroSummaryHelp hide={this.hideModal} />;
            }

            if (type === 'background') {
                return <IntroBackgroundHelp hide={this.hideModal} />;
            }

            if (sectionType === SCOPE) {
                return <ScopeHelp hide={this.hideModal} />;
            }
        }

        return (
            <>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center" id="contained-modal-title-sm">
                        {title} - Instructions
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={this.styles.modalBody}>{this.renderContent()}</Modal.Body>
                <Modal.Footer>
                    <div className="text-center">
                        <Button bsStyle="primary" onClick={this.hideModal}>
                            Done
                        </Button>
                    </div>
                </Modal.Footer>
            </>
        );
    }

    render() {
        const { showModal } = this.props;

        return (
            <Modal
                aria-labelledby="contained-modal-title-sm"
                dialogClassName={this.styles.modal}
                onHide={this.hideModal}
                show={showModal}
            >
                {this.renderModalBody()}
            </Modal>
        );
    }
}

export const ProjectCreateHelpModal = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(ConnectedProjectCreateHelpModal);
