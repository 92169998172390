import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { NavDropdown } from 'react-bootstrap';

import { UserProfilePicture } from '../UserProfilePicture/UserProfilePicture';
import { MenuItem } from '../MenuItem/MenuItem';

export class UserProfileNavItem extends PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        className: PropTypes.string,
        user: PropTypes.shape({
            displayName: PropTypes.string.isRequired,
        }).isRequired,
    };

    static defaultProps = {
        className: '',
    };

    render() {
        const { children, className, user } = this.props;

        const styles = require('./UserProfileNavItem.scss');

        const dropdownTitle = <UserProfilePicture className={styles.userImage} user={user} />;

        return (
            <NavDropdown
                aria-label="Account Navigation Dropdown"
                className={`${className} ${styles.avatarNavItem}`}
                id="account-nav-dropdown"
                title={dropdownTitle}
            >
                <MenuItem header qaTag="navbar-displayName">
                    {user.displayName}
                </MenuItem>
                {children}
            </NavDropdown>
        );
    }
}
