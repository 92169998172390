import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isDirty } from 'redux-form';

import { criteriaInstructionsDict } from '@og-pro/shared-config/criteria';

import { CriteriaNeedsReviewForm } from './CriteriaNeedsReviewForm';
import { form as criteriaNeedsReviewForm } from './CriteriaNeedsReviewForm/constants';
import { getModalData } from './selectors';
import { fieldNames } from '../constants';
import { criteriaNeedsReviewHideModal } from '../../../../actions/templatesAdmin';
import { Dialog } from '../../../Dialog';

const { INSTRUCTION_DATA, INSTRUCTION_TYPE, INSTRUCTIONS, NEEDS_REVIEW } = fieldNames;

const { RADIO, SELECT } = criteriaInstructionsDict;

const mapStateToProps = (state) => {
    return {
        ...getModalData(state),
        isFormDirty: isDirty(criteriaNeedsReviewForm)(state),
        showModal: state.templatesAdmin.get('showCriteriaNeedsReviewModal'),
    };
};

const mapDispatchToProps = {
    hideModal: criteriaNeedsReviewHideModal,
};

// @connect
class ConnectedCriteriaBuilderNeedsReviewModal extends Component {
    static propTypes = {
        arrayName: PropTypes.string,
        change: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        criteria: PropTypes.object,
        hideModal: PropTypes.func.isRequired,
        isFormDirty: PropTypes.bool.isRequired,
        isSharedSectionForm: PropTypes.bool,
        isTextArea: PropTypes.bool,
        showModal: PropTypes.bool.isRequired,
        showValidation: PropTypes.bool,
        sortUsingManualNumbering: PropTypes.func.isRequired,
        tagOptions: PropTypes.array,
        templateVariableOptions: PropTypes.array,
        useManualNumbering: PropTypes.bool,
    };

    get styles() {
        return require('./index.scss');
    }

    hideModal = (opts = {}) => {
        const { hideModal, isFormDirty } = this.props;

        // Confirm exit if form is dirty
        if (
            opts.force ||
            !isFormDirty ||
            window.confirm('You have unsaved edits, are you sure you want to exit?') // eslint-disable-line no-alert
        ) {
            hideModal();
        }
    };

    removeNeedsReview = () => {
        const { arrayName, change } = this.props;

        change(`${arrayName}.${INSTRUCTION_DATA}`, null);
        change(`${arrayName}.${INSTRUCTION_TYPE}`, null);
        change(`${arrayName}.${INSTRUCTIONS}`, null);
        change(`${arrayName}.${NEEDS_REVIEW}`, false);

        this.hideModal({ force: true });
    };

    updateItem = (data) => {
        const { arrayName, change, criteria, sortUsingManualNumbering } = this.props;

        let instructionData = data[INSTRUCTION_DATA];
        if (instructionData) {
            if (data[INSTRUCTION_TYPE] === RADIO || data[INSTRUCTION_TYPE] === SELECT) {
                instructionData = JSON.stringify(instructionData);
            } else if (instructionData.includeAttachment) {
                instructionData = JSON.stringify(pick(instructionData, 'includeAttachment'));
            } else {
                instructionData = null;
            }
        }

        // Hack to get re-rendering to work properly
        setTimeout(() => {
            change(arrayName, {
                ...data,
                [INSTRUCTION_DATA]: instructionData,
                [NEEDS_REVIEW]: true,
            });
            sortUsingManualNumbering(criteria.sharedId, criteria.orderById - 1);
        });

        this.hideModal({ force: true });
    };

    render() {
        // Need to prevent further loading as criteria won't be defined unless showModal is true
        if (!this.props.showModal) return null;

        const {
            criteria,
            disabled,
            isSharedSectionForm,
            isTextArea,
            showValidation,
            tagOptions,
            templateVariableOptions,
            useManualNumbering,
        } = this.props;

        return (
            <Dialog maxWidth="lg" onClose={this.hideModal} title="Instructions for Completing Item">
                <CriteriaNeedsReviewForm
                    disabled={disabled}
                    initialValues={criteria}
                    isSharedSectionForm={isSharedSectionForm}
                    isTextArea={isTextArea}
                    onSubmit={this.updateItem}
                    removeNeedsReview={this.removeNeedsReview}
                    showValidation={showValidation}
                    tagOptions={tagOptions}
                    templateVariableOptions={templateVariableOptions}
                    useManualNumbering={useManualNumbering}
                />
            </Dialog>
        );
    }
}

export const CriteriaBuilderNeedsReviewModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedCriteriaBuilderNeedsReviewModal);
