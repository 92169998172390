import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormGroup, HelpBlock, ListGroup, ListGroupItem } from 'react-bootstrap';

import { HelpIcon } from '../InputText/HelpIcon';
import { Label } from '../InputText/Label';
import { MultiSelectSVGShape, SingleSelectSVGShape } from '../SVGShapes/Inputs';

export class MultipleChoiceInput extends PureComponent {
    static propTypes = {
        disabled: PropTypes.bool,
        help: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        input: PropTypes.shape({
            name: PropTypes.string.isRequired,
            onChange: PropTypes.func.isRequired,
            value: PropTypes.any,
        }).isRequired,
        isMultiSelect: PropTypes.bool,
        label: PropTypes.string,
        meta: PropTypes.shape({
            error: PropTypes.string,
            touched: PropTypes.bool.isRequired,
            warning: PropTypes.string,
        }).isRequired,
        options: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                tooltip: PropTypes.string,
                value: PropTypes.any.isRequired,
            }).isRequired
        ).isRequired,
        showValidation: PropTypes.bool,
        useOpenGovStyle: PropTypes.bool,
    };

    get styles() {
        if (this.props.useOpenGovStyle) {
            return require('./MultipleChoiceInputOpenGovStyle.scss');
        }

        return require('./index.scss');
    }

    clickHandler = (newValue) => {
        const {
            disabled,
            input: { onChange },
            isMultiSelect,
        } = this.props;

        if (!disabled) {
            const value = isMultiSelect ? this.setMultiSelectValues(newValue) : newValue;
            onChange(value);
        }
    };

    setMultiSelectValues = (newValue) => {
        const {
            input: { value },
        } = this.props;

        const currentValues = Array.isArray(value) ? value : [];
        const isValueAlreadyStored = currentValues.includes(newValue);

        if (isValueAlreadyStored) {
            return currentValues.filter((currentValue) => currentValue !== newValue);
        }
        return currentValues.concat(newValue);
    };

    renderOptionIcon(isSelected, disabled) {
        if (this.props.useOpenGovStyle) {
            return (
                <svg
                    className={this.styles.optionIconSVGContainer}
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    {this.props.isMultiSelect ? (
                        <MultiSelectSVGShape
                            className={classnames({
                                [this.styles.optionIconSelectedSquare]: isSelected,
                                [this.styles.optionIconSquare]: !isSelected,
                                [this.styles.disabledOption]: disabled,
                            })}
                            isSelected={isSelected}
                        />
                    ) : (
                        <SingleSelectSVGShape
                            className={classnames({
                                [this.styles.optionIconSelectedOuterCircle]: isSelected,
                                [this.styles.optionIconCircle]: !isSelected,
                                [this.styles.disabledOption]: disabled,
                            })}
                            innerCircleClassName={classnames({
                                [this.styles.optionIconSelectedInnerCircle]: isSelected,
                                [this.styles.disabledOption]: disabled,
                            })}
                            isSelected={isSelected}
                        />
                    )}
                </svg>
            );
        }

        return (
            <span className={this.styles.choiceIcon}>
                <i className={`fa fa-fw fa-lg fa-circle-thin ${this.styles.open}`} />
                <i className={`fa fa-fw fa-lg fa-check-circle ${this.styles.checked}`} />
            </span>
        );
    }

    renderMultipleChoice() {
        const {
            disabled,
            input: { value: inputValue },
            isMultiSelect,
            options,
            useOpenGovStyle,
        } = this.props;

        return options.map(({ label, tooltip, value }) => {
            const isSelected = isMultiSelect
                ? Array.isArray(inputValue) && inputValue.includes(value)
                : value === inputValue;

            return (
                <ListGroupItem
                    className={classnames(
                        disabled && this.styles.disabled,
                        isSelected ? this.styles.selectedItem : this.styles.choiceItem,
                        {
                            [this.styles.optionRow]: useOpenGovStyle,
                        }
                    )}
                    disabled={disabled}
                    key={value}
                    onClick={() => this.clickHandler(value)}
                >
                    {this.renderOptionIcon(isSelected, disabled)}
                    {label}
                    {tooltip && <HelpIcon tooltip={tooltip} />}
                </ListGroupItem>
            );
        });
    }

    render() {
        const {
            help,
            input: { name },
            label,
            meta: { error, touched, warning },
            showValidation,
            useOpenGovStyle,
        } = this.props;

        const displayValidation = touched || showValidation;

        let bsStyle;
        let helpText = help;
        if (displayValidation && error) {
            bsStyle = 'error';
            helpText = error;
        } else if (displayValidation && warning) {
            bsStyle = 'warning';
            helpText = warning;
        } else if (displayValidation && !useOpenGovStyle) {
            bsStyle = 'success';
        }

        if (useOpenGovStyle) {
            return (
                <FormGroup className={this.styles.formGroup} validationState={bsStyle}>
                    {label && (
                        <Label
                            className={classnames(this.styles.label, {
                                [this.styles.noMarginBottom]: !!help,
                            })}
                            label={label}
                        />
                    )}
                    {help && <HelpBlock useOpenGovStyle={useOpenGovStyle}>{help}</HelpBlock>}
                    <ListGroup className={this.styles.noMarginBottom}>
                        {this.renderMultipleChoice()}
                    </ListGroup>
                </FormGroup>
            );
        }

        return (
            <FormGroup controlId={`form-group-${name}`} validationState={bsStyle}>
                <Label label={label} />
                <ListGroup className={this.styles.multipleChoiceContainer}>
                    {this.renderMultipleChoice()}
                </ListGroup>
                {helpText && <HelpBlock>{helpText}</HelpBlock>}
            </FormGroup>
        );
    }
}
