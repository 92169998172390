import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, FieldArray, reduxForm } from 'redux-form';

import { fieldNames, form } from '../constants';
import { ProjectSectionsEditFieldArray } from '../ProjectSectionsEditFieldArray';
import { validate } from '../validate';
import { Button } from '../../Button';
import { LoadingButton } from '../../LoadingButton';
import { Toggle } from '../../Toggle/Toggle';
import { Well } from '../../Well/Well';
import { copyProjectSection, updateProjectSections } from '../../../actions/govProjects';

const {
    IS_DISPLAYED,
    IS_HIDDEN,
    HIDE_CONTACT,
    HIDE_PROCUREMENT_CONTACT,
    HIDE_TIMELINE,
    PROJECT_SECTIONS,
} = fieldNames;

const addIsDisplayed = (projectSection) => {
    return {
        ...projectSection,
        [IS_DISPLAYED]: !projectSection[IS_HIDDEN],
    };
};

const convertToIsHidden = (projectSection) => {
    return {
        ...projectSection,
        [IS_HIDDEN]: !projectSection[IS_DISPLAYED],
    };
};

const mapStateToProps = (state, props) => {
    return {
        initialValues: {
            [HIDE_CONTACT]: !props.project[HIDE_CONTACT],
            [HIDE_PROCUREMENT_CONTACT]: !props.project[HIDE_PROCUREMENT_CONTACT],
            [HIDE_TIMELINE]: !props.project[HIDE_TIMELINE],
            [PROJECT_SECTIONS]: (props.project.projectSections || []).map(addIsDisplayed),
        },
    };
};

const mapDispatchToProps = {
    copyProjectSection,
    updateProjectSections,
};

const formConfig = {
    enableReinitialize: true,
    form,
    validate,
};

// @connect
// @reduxForm
class ConnectedProjectSectionsEditModal extends Component {
    static propTypes = {
        change: PropTypes.func.isRequired,
        copyProjectSection: PropTypes.func.isRequired,
        dirty: PropTypes.bool.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        hideModal: PropTypes.func.isRequired,
        invalid: PropTypes.bool.isRequired,
        pristine: PropTypes.bool.isRequired,
        project: PropTypes.shape({
            id: PropTypes.number.isRequired,
            isDocBuilder: PropTypes.bool.isRequired,
            projectSections: PropTypes.arrayOf(
                PropTypes.shape({
                    isHidden: PropTypes.bool.isRequired,
                })
            ).isRequired,
            useManualNumbering: PropTypes.bool,
            useSectionDividers: PropTypes.bool,
        }).isRequired,
        updateProjectSections: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            saving: false,
            saveError: null,
        };
    }

    get styles() {
        return require('./index.scss');
    }

    copyProjectSection = (projectSectionId) => {
        const { project } = this.props;

        return this.props.copyProjectSection(project.id, projectSectionId);
    };

    hideModal = (opts = {}) => {
        const { dirty, hideModal } = this.props;

        if (
            opts.force ||
            !dirty ||
            window.confirm('You have unsaved edits, are you sure you want to exit?') // eslint-disable-line no-alert
        ) {
            hideModal();
        }
    };

    submitHandler = (data) => {
        const { hideModal, project } = this.props;

        this.setState({
            saveError: null,
            saving: true,
        });

        const mappedData = {
            [PROJECT_SECTIONS]: data[PROJECT_SECTIONS].map(convertToIsHidden),
            [HIDE_CONTACT]: !data[HIDE_CONTACT],
            [HIDE_PROCUREMENT_CONTACT]: !data[HIDE_PROCUREMENT_CONTACT],
            [HIDE_TIMELINE]: !data[HIDE_TIMELINE],
        };

        this.props
            .updateProjectSections(project.id, mappedData)
            .then(() => {
                this.setState({ saving: false });
                hideModal({ force: true });
            })
            .catch((error) => {
                this.setState({
                    saveError: error.message,
                    saving: false,
                });
            });
    };

    renderDisplayOptions() {
        const { saving } = this.state;

        return (
            <Well>
                <div className={this.styles.displayOptionsTitle}>
                    <div>
                        <b>Display Options:</b>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-sm-10 text-right">
                        <Field
                            aria-label="Display Timeline"
                            component={Toggle}
                            disabled={saving}
                            formClassName={this.styles.displayOptionsToggle}
                            label="Display Timeline"
                            labelInline
                            labelInlineTogglePosition="right"
                            name={HIDE_TIMELINE}
                            qaTag="projectSectionsEditModal-displayTimeline"
                        />
                    </div>
                    <div className="col-xs-12 col-sm-10 text-right">
                        <Field
                            aria-label="Display Project Contact"
                            component={Toggle}
                            disabled={saving}
                            formClassName={this.styles.displayOptionsToggle}
                            label="Display Project Contact"
                            labelInline
                            labelInlineTogglePosition="right"
                            name={HIDE_CONTACT}
                            qaTag="projectSectionsEditModal-displayProjectContact"
                        />
                    </div>
                    <div className="col-xs-12 col-sm-10 text-right">
                        <Field
                            aria-label="Display Procurement Contact"
                            component={Toggle}
                            disabled={saving}
                            formClassName={this.styles.displayOptionsToggle}
                            label="Display Procurement Contact"
                            labelInline
                            labelInlineTogglePosition="right"
                            name={HIDE_PROCUREMENT_CONTACT}
                            qaTag="projectSectionsEditModal-displayProcurementContact"
                        />
                    </div>
                </div>
            </Well>
        );
    }

    render() {
        const { change, handleSubmit, invalid, pristine, project } = this.props;

        const { saveError, saving } = this.state;

        return (
            <Modal onHide={this.hideModal} show>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">Edit Project Sections</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(this.submitHandler)}>
                        <div className="row">
                            <div className="col-xs-8 col-sm-offset-1 col-sm-8">
                                <div>
                                    <b>Section Title</b>
                                </div>
                            </div>
                            <div className="col-xs-4 col-sm-3 text-left">
                                <div>
                                    <b>Display</b>
                                </div>
                            </div>
                        </div>
                        <FieldArray
                            change={change}
                            component={ProjectSectionsEditFieldArray}
                            copyProjectSection={this.copyProjectSection}
                            disabled={saving}
                            name={PROJECT_SECTIONS}
                            useManualNumbering={project.useManualNumbering}
                            useSectionDividers={project.useSectionDividers}
                        />
                        {!project.isDocBuilder && (
                            <div className="row">
                                <div className="col-xs-12 col-sm-offset-1 col-sm-10">
                                    {this.renderDisplayOptions()}
                                </div>
                            </div>
                        )}
                        <div className="text-center">
                            <LoadingButton
                                aria-label="Save Changes"
                                bsStyle="primary"
                                disabled={pristine || invalid || saving}
                                icon="fa-pencil"
                                loading={saving}
                                qaTag="projectSectionsEditModal-save"
                                text="Save Changes"
                                type="submit"
                            />
                            &nbsp;&nbsp;
                            <Button
                                aria-label="Close"
                                onClick={this.hideModal}
                                qaTag="projectSectionsEditModal-close"
                            >
                                Close
                            </Button>
                        </div>
                        {saveError && <div className="error-block text-center">{saveError}</div>}
                    </form>
                </Modal.Body>
            </Modal>
        );
    }
}

export const ProjectSectionsEditModal = compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig)
)(ConnectedProjectSectionsEditModal);
